import React, { useEffect, useState } from "react";
import userImage from "../../../images/user-green.png";
import PaymentImage from "../../../images/money-bills-green.png";
import "../CustomerInfo/CustomerInfo.css";
import Edit from "../../../images/Edit.svg";
import DeleteGrey from "../../../images/DeleteGrey.svg";
import Payment from "../../../SVG/Payment.svg";
import MoneyBillsSample from "../../../images/MoneyBillsSample.svg";
import AddGreen from "../../../SVG/AddGreen.svg";
// import Taxinvoice from "../../../SVG/TaxInvoice.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { userActions, landingPageActions, productActions, specialprice, uploadfile, stock } from "../../../_actions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ApiList from "../../../_constants/const.api";
import ApiProvider from "../../../_services/ApiProvider";


const TaxInvoice = (props) => {

  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const store = userData ? userData.user.stores[0].storeCode : "";
  // const [provinceDdOptions, setProvinceDdOptions] = React.useState([]);
  // const [districtDdOptions, setDistrictDdOptions] = React.useState([]);
  // const [subDistrictDdOptions, setSubDistrictDdOptions] = React.useState([]);

  const isNull = (value) => {
    return value === "" || value === undefined || value === null;
  };
  const getBoolean = (value) => {
    return value === "true" || value === true;
  }
  useEffect(() => {
    
  }, [props])

  return (
    <>
      <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 create-order-box-margin p-0">
        <div className="create-order-common-box-shadow mb-4">
          <div className="d-flex align-items-center mb-4">
            <img
              src={Payment}
              className="create-order-top-heading-image m-0 mr-3"
            />
            <h6 className="create-order-box-shadow-top-heading m-0">Payment</h6>
          </div>
          <div className="createOrderCard">
            <div className="row m-0">
              <div className="col-lg-6 p-0 pr-3">
                <div className={props.paymentMode == 'Cash on delivery' ? "createOrderCardRadio-selected d-flex align-items-center" : "createOrderCardRadio d-flex align-items-center"}>
                  <input
                    type="radio"
                    onChange={(e) => {props.handlePaymentModeChanges(e)}}
                    id="cod"
                    value={"Cash on delivery"}
                    checked={props.paymentMode == 'Cash on delivery'}
                    name="payment_mode"
                    className={"m-0 ml-4 cursor-pointer"}
                  />
                  <label for="cod" className="ml-3">
                    Cash on delivery
                  </label>
                </div>
              </div>
              <div className="col-lg-6 p-0 pl-3">
                <div className={props.paymentMode == 'Bank transfer on delivery' ? "createOrderCardRadio-selected d-flex align-items-center" : "createOrderCardRadio d-flex align-items-center"}>
                  <input
                    type="radio"
                    onChange={(e) => {props.handlePaymentModeChanges(e)}}
                    id="ctod"
                    checked={props.paymentMode == 'Bank transfer on delivery'}
                    value={"Bank transfer on delivery"}
                    name="payment_mode"
                    className="m-0 ml-4 cursor-pointer"
                    // sx={{display: "flex", alignItems: "center"}}
                  />
                  <label for="ctod" className="ml-3">
                    Bank transfer on delivery
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!props.showInvoiceForm && !props.editTaxInvoice && isNull(props.taxInvoice) && <div className="create-order-common-box-shadow mb-4">
          <h6 className="create-order-box-shadow-top-heading cursor-pointer m-0"
            onClick={() => {
              props.handleAddInvoice("showInForm")
              props.handleAddInvoice("clearFields")
              props.handleAddInvoice("showInFlag");
            }}
          >
            <img src={AddGreen} />
            &nbsp;&nbsp;&nbsp;&nbsp;Add tax invoice information
          </h6>
        </div>}
        {!isNull(props.taxInvoice) && !props.editTaxInvoice && <div className="create-order-common-box-shadow mb-4">
          <div className="row">
            <div className="col-lg-10 d-flex align-items-center justify-contet-start">
              <img
                src={MoneyBillsSample}
                className="create-order-top-heading-image m-0 mr-3 cursor-pointer"
              />
              <h6 className="create-order-box-shadow-top-heading m-0">
                Tax Invoice Information
              </h6>
            </div>
            <div className="col-lg-2 d-flex align-items-center justify-content-end createOrderCard-edit">
              <p className="cursor-pointer">
                <img src={Edit} onClick={() => props.handleEdit()} />
                <span onClick={() => props.handleEdit()}>Edit</span>
              </p>
            </div>
          </div>
          <div className="createOrderCard">
            <div className="row">
              <div className="col-lg-3">
                <p>
                  <span>Company name</span>
                </p>
              </div>
              <div className="col-lg-9">
                <p>{props.taxInvoiceData[0].value}</p>
              </div>
              <div className="col-lg-3">
                <p>
                  <span>Tax ID</span>
                </p>
              </div>
              <div className="col-lg-9">
                <p>{props.taxInvoiceData[1].value}</p>
              </div>
              <div className="col-lg-3">
                <p>
                  <span>Phone number</span>
                </p>
              </div>
              <div className="col-lg-9">
                <p>{props.taxInvoiceData[2].value}</p>
              </div>
              <div className="col-lg-3">
                <p>
                  <span>Address</span>
                </p>
              </div>
              <div className="col-lg-9">
                <p>
                  {lancode == "th1"
                    ? props.taxInvoiceData[3].value +
                    ", " +
                    props.taxInvoiceData[6].value +
                    ", " +
                    props.taxInvoiceData[5].value +
                    ", " +
                    props.taxInvoiceData[4].value +
                    " " +
                    props.taxInvoiceData[7].value
                    : props.taxInvoiceData[3].value +
                    ", " +
                    props.taxInvoiceData[6].value +
                    ", " +
                    props.taxInvoiceData[5].value +
                    ", " +
                    props.taxInvoiceData[4].value +
                    " " +
                    props.taxInvoiceData[7].value}
                </p>
              </div>
            </div>
          </div>
        </div>}

        {(getBoolean(props.showInvoiceForm) || getBoolean(props.editTaxInvoice)) && getBoolean(props.invoiceFlag) && <div className="create-order-common-box-shadow">
          <div className="row">
            <div className="col-lg-10 d-flex align-items-center justify-contet-start">
              <img
                src={MoneyBillsSample}
                className="create-order-top-heading-image m-0 mr-3"
              />
              <h6 className="create-order-box-shadow-top-heading m-0">
                Tax Invoice Information
              </h6>
            </div>
            {props.editTaxInvoice &&
              <div onClick={() => props.handleDelete("delete")} className="col-lg-2 d-flex align-items-center justify-content-end">
                <img src={DeleteGrey} className="cursor-pointer" />
              </div>
            }

          </div>
          <div className="createOrderCard">
            <div className="row">
              <div className="col-lg-12 createOrderCardRadioWithoutBorder mt-4 mb-4">
                <input
                  type="radio"
                  id="company"
                  name="taxAuthorty"
                  value={"Company"}
                  checked={props.taxAuthority == "Company" ? true : false}
                  onChange={props.handleTaxAuthority}
                  defaultValue="Company"
                  className="mt-0 cursor-pointer"
                />
                <label htmlFor="company" className="ml-2 mr-4">
                  Company
                </label>
                <input
                  type="radio"
                  id="personal"
                  name="taxAuthorty"
                  value={"Personal"}
                  checked={props.taxAuthority == "Personal" ? true : false}
                  onChange={(e) => {
                    props.handleTaxAuthority(e)
                  }
                  }
                  className="mt-0 cursor-pointer"
                />
                <label htmlFor="personal" className="ml-2">
                  Personal
                </label>

              </div>
              <div className="col-lg-12">
                {props.taxAuthority == "Company" ? (
                  <label>Company Name</label>
                ) : (
                  <label>Name</label>
                )}
                <input
                  type="text"
                  value={props.taxInvoiceData[0].value}
                  // placeholder="Sand Studio & Co."
                  onChange={(e) => {
                    props.handleTaxInvoiceChanges(e, 'companyName')
                  }}
                />
                {props.taxInvoiceData[0].error && (
                  <div className="invalid-feedback-tax">
                    {props.taxInvoiceData[0].errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label>Tax ID</label>
                <input
                  type="text"
                  maxlength={props.taxAuthority == "Company" ? 10 : 13}
                  value={props.taxInvoiceData[1].value}
                  onChange={(e) => {
                    props.handleTaxInvoiceChanges(e, 'taxid')
                  }}
                />
                {props.taxInvoiceData[1].error && (
                  <div className="invalid-feedback-tax">
                    {props.taxInvoiceData[1].errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label>Phone number</label>
                <input
                  type="text"
                  maxlength={10}
                  // placeholder="081-355-5039"
                  value={props.taxInvoiceData[2].value}
                  onChange={(e) => {
                    props.handleTaxInvoiceChanges(e, 'phoneNumber')
                  }}
                />
                {props.taxInvoiceData[2].error && (
                  <div className="invalid-feedback-tax">
                    {props.taxInvoiceData[2].errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-12">
                <label>Address</label>
                <input
                  type="text"
                  value={props.taxInvoiceData[3].value}
                  disabled={props.taxInvoiceData[3].disabled}
                  onChange={(e) => {
                    props.handleTaxInvoiceChanges(e, "address")
                  }}
                />
                {props.taxInvoiceData[3].error && (
                  <div className="invalid-feedback-tax">
                    {props.taxInvoiceData[3].errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label>Province</label>
                <select
                  name="options"
                  id="options"
                  value={props.taxInvoiceData[4].value}
                  disabled={props.taxInvoiceData[4].disabled}
                  onChange={(e) => {
                    props.handleOnchangeDd(e, "province");
                    props.handleTaxInvoiceChanges(e, "province")
                  }}
                >
                  <option value="" disabled></option>
                  {props.provinceDdOptions.map((item) => {
                    return <option selected={item.provinceTH == props.taxInvoiceData[4].value} value={item.provinceTH}>{item.provinceTH}</option>
                  })}
                </select>
                {props.taxInvoiceData[4].error && (
                  <div className="invalid-feedback-tax">
                    {props.taxInvoiceData[4].errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label>District</label>
                <select
                  name="options"
                  id="options"
                  value={props.taxInvoiceData[5].value}
                  disabled={props.taxInvoiceData[5].disabled}
                  onChange={(e) => {
                    props.handleOnchangeDd(e, "district");
                    props.handleTaxInvoiceChanges(e, "district")
                  }}
                >
                  <option value="" disabled></option>
                  {props.districtDdOptions.map((item) => {
                    return <option selected={item.districtTH == props.taxInvoiceData[5].value} value={item.districtTH}>{item.districtTH}</option>
                  })}
                </select>
                {props.taxInvoiceData[5].error && (
                  <div className="invalid-feedback-tax">
                    {props.taxInvoiceData[5].errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label>Sub-district</label>
                <select
                  name="options"
                  id="options"
                  value={props.taxInvoiceData[6].value}
                  disabled={props.taxInvoiceData[6].disabled}
                  onChange={(e) => {
                    props.handleOnchangeDd(e, "subdistrict");
                    props.handleTaxInvoiceChanges(e, "subdistrict")
                  }}
                >
                  <option value="" disabled></option>
                  {props.subDistrictDdOptions.map((item, index) => {
                    return <option selected={item.subDistrictTH==props.taxInvoiceData[6].value} value={item.subDistrictTH}>{item.subDistrictTH}</option>
                  })}
                </select>                
                {props.taxInvoiceData[6].error && (
                  <div className="invalid-feedback-tax">
                    {props.taxInvoiceData[6].errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label>Postal code</label>
                <input
                  type="text"
                  maxlength={5}
                  // placeholder="081-355-5039"
                  disabled={true}
                  value={props.taxInvoiceData[7].value}
                  onChange={(e) => {
                    props.handleTaxInvoiceChanges(e)
                  }}
                />
                {props.taxInvoiceData[7].error && (
                  <div className="invalid-feedback-tax">
                    {props.taxInvoiceData[7].errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                <div className="customerCheckoutCardBtn">
                  {<button
                    type={"button"}
                    className="customerCheckoutCardCancelBtn"
                    onClick={() => props.handleDelete("cancel")}
                  >
                    Cancel
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>}

      </div>
    </>
  );
};

export default TaxInvoice;
