import React, { useCallback, CSSPropertie } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Axios from "axios";
import Dropdown from 'react-bootstrap/Dropdown';
import { Helmet } from "react-helmet";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { productActions } from "../_actions";
import "./ViewAllSearchResults.css";
import AllProductBanner from '../images/all-product-top-banner.png';
import Filter from './Filters'
import ProductCards from './ProductCards'
import { REACT_APP_BASE_URL } from "../constant"
import { useRouteMatch, useParams } from 'react-router-dom';
import { Link, useHistory } from "react-router-dom";
import Loading from "../SharedComponent/Loader/Loader";
import '../CommonStyle/mediaQuery.css';
import { FaArrowCircleUp } from "react-icons/fa";
import { Button } from "./Styles";
import like from "../images/like.png";
import promoImage from "../images/promo-banner.png";
import promoBanner from "../images/PromoIconRed.svg";
import addToWhishlist from "../images/AddToCartWhite.svg";
import favoriteWatchList from "../images/WishList.svg";
import NoResultFound from '../images/no-result-found.png';


const ViewAllSearchResults = (props) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  const userInformation = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("authorization");
  const [openSortByDropDown, setOpenSortByDropDown] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [defaultLoad, setDefaultLoad] = useState(true);
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalProduct, setTotalProduct] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productsPerPage] = useState(30);
  const [firstParams, setFirstParams] = useState("");
  const [secondParams, setSecondParams] = useState("");
  const history = useHistory();
  let match = useRouteMatch();
  const [iscartactive, setIscartactive] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [userInfo, setUserInfo] = React.useState(userInformation);
  //const [keywordsearch, setKeywordsearch]=React.useState("");
  const [totalproductcountvalue, setTotalproductcountvalue] = React.useState(0);

  if (props && props.history.location.state.totalproductcountvalue) {
    // setTotalproductcountvalue(props.history.location.state.totalproductcountvalue);
    // setTotalProduct(props.history.location.state.totalproductcountvalue);
  }
  if (props && props.history.location.state.keywordsearch) {
    // setKeywordsearch(props.history.location.state.keywordsearch);
  }

  const isNull = (value) => {
    return value == "" || value == undefined || value == null
  }
  // const { firstParam, secondParam } = useParams();

  let paramValue = match.params.chicken;
  // const passwordReset = params.get("resetpassword");
  const userState = useSelector((state) => state.users);
  const lancode = localStorage.getItem("lancode"); 
  // const url = props.location.pathname;
  // const [firstParam, secondParam] = props.location.pathname.split('/').slice(1);

  const userData = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
  // const products = localStorage.getItem('products');
  const keywordsearch = localStorage.getItem('keywordsearch') ? localStorage.getItem('keywordsearch') : undefined;
  const defaultFilter = {
    brand: [],
    specialProduct: [],
    channel: [],
    categorySlug: [],
    subCategorySlug: [],
    storeCode: userData ? userData.user.stores[0].storeCode : ''
  }
  const [filters, setFilters] = useState(defaultFilter);

  const setPage = (page) => {
    let pages = (page - 1) * 30
    setCurrentPage(pages)
    // setCurrentPage(page)

  }




  React.useEffect(() => {  
    if (!userData) {
      history.push('/login')
    }

    setUserInfo(JSON.parse(localStorage.getItem('user')))
    // var userData = JSON.parse(localStorage.getItem('user'))

    var storeCode = userData !== null ? userData.user.stores[0].storeCode : "";
    var token = !isNull(userData) ? userData.token : "";
    const payload = !isNull(storeCode) ? { "searchItem": keywordsearch, "storeCode": storeCode } : { "searchItem": query }


    // const payload = {
    //   "storeCode": userData && userData.user ? userData.user.stores[0].storeCode : '',
    // }
    // "storeCode": userData && userData.user ? userData.user.stores[0].storeCode : '',

    if (props.pageType === 'viewallsearchresults') {
      getFilters(payload)
      getProducts(payload)

    } else if (props.pageType === 'newproducts') {
      getProducts({ ...payload, specialProduct: ["3"] })
      getFilters({ ...payload, specialProduct: ["3"] })
    }
    else if (props.pageType === 'promoproducts') {
      getProducts({ ...payload, specialProduct: ["2"] })
      getFilters({ ...payload, specialProduct: ["2"] })
    }


  }, [selectedValue, currentPage, userState, isLiked, iscartactive, cartQuantity, keywordsearch]);

  React.useEffect(() => {
    if (!defaultLoad) {
      if (props.pageType === 'viewallsearchresults') {

        if (filters.brand.length == 0 && filters.categorySlug.length == 0 && filters.subCategorySlug.length === 0
          && filters.channel.length === 0 && filters.specialProduct.length === 0) {
          const storeCode = !isNull(userData) ? userData.user.stores[0].storeCode : "";
          const payload = !isNull(storeCode) ? { "searchItem": keywordsearch, "storeCode": storeCode } : { "searchItem": query }
          getProducts(payload)
        } else {
          getProducts(filters)
        }
        const payloadFilter = JSON.parse(JSON.stringify(filters))
        // getFilters({ ...payloadFilter, filter: currentFilter })

      } else if (props.pageType === 'newproducts') {
        getProducts({ ...filters, specialProduct: ["3"] })
        const payloadFilter = JSON.parse(JSON.stringify(filters))
        // getFilters({ ...payloadFilter, filter: currentFilter,specialProduct:["3"] })
      }
      else if (props.pageType === 'promoproducts') {
        getProducts({ ...filters, specialProduct: ["2"] })
        const payloadFilter = JSON.parse(JSON.stringify(filters))
        // getFilters({ ...payloadFilter, filter: currentFilter,specialProduct:["2"] })
      }

    }

  }, [filters, selectedValue]);


  const handleChange = (event, data) => {
    if (event.target.checked) {
      if (!filters[data].includes(event.target.name)) {
        setFilters(filters => ({ ...filters, [data]: [...filters[data], event.target.name] }))
        setCurrentFilter(data)
      }
    } else {
      setFilters(filters => ({ ...filters, [data]: filters[data].filter(day => day !== event.target.name) }));
      setCurrentFilter(data)
    }
    setDefaultLoad(false)
  }


  const getCatSlug = (arrr) => {
    const SubCat = arrr.map(item => item.subCategorySlug);
    return SubCat
  }

  const removeSubCatSlug = (oldArray, removeItems) => {
    const newArray = oldArray.filter((value) => {
      return !removeItems.includes(value)
    })
    return newArray
  }

  const CategoryChange = (event, data, subCat) => {

    if (event.target.checked) {
      if (!filters[data].includes(event.target.name)) {
        setFilters(filters => ({ ...filters, [data]: [...filters[data], event.target.name], subCategorySlug: [...filters["subCategorySlug"], ...getCatSlug(subCat)] }))
        setCurrentFilter(data)
      }
    } else {
      setFilters(filters => ({ ...filters, [data]: filters[data].filter(filData => filData !== event.target.name), subCategorySlug: removeSubCatSlug(filters.subCategorySlug, getCatSlug(subCat)) }));
      setCurrentFilter(data)
    }
    setDefaultLoad(false)
  }



  const resetFilter = (e, filterTYpe) => {
    if (!filterTYpe) {
      setFilters(defaultFilter)
    } else {
      if (filterTYpe === "categorySlug") {
        setFilters(filters => ({ ...filters, categorySlug: [], subCategorySlug: [] }))
      } else {
        setFilters(filters => ({ ...filters, [filterTYpe]: [] }))
      }

    }

  }

  const handleValueSelected = (value) => {
    setSelectedValue(value);
  };

  const handleIsLiked = (value) => {
    setIsLiked(value)
  }

  const handlecarttoggle = (value) => {
    setIscartactive(value)

  }

  const handleupdateQuantity = (value) => {
    setCartQuantity(value)
  }


  const handleValueWithRadio = (value) => {
    setSelectedValue(value);
  }

  const getFilters = (payload) => {
    setLoading(true)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    };
    Axios.post(
      REACT_APP_BASE_URL + "/api/product/filters",
      payload,
      requestOptions
    )
      .then(response => {
        if (response.data.code === 200) {
          setFilterData(response.data.data)
          setLoading(false)

        } else {

        }

      })
      .catch((error) => {
        setLoading(false)
      });
  }

  const getProducts = (payload) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    };
    if (selectedValue === "Price high to low") {
      setLoading(true)
      const sort = { "price": -1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=30&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Price low to high") {
      setLoading(true)
      const sort = { "price": 1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=30&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "A to Z") {
      setLoading(true)
      const sort = { "consumerNameEN": 1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=30&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Z to A") {
      setLoading(true)
      const sort = { "consumerNameEN": -1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=30&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            // setFilterData(response.data.data)
            setLoading(false)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Clear") {
      setLoading(true)
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=30&skip=" + currentPage,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else {
      setLoading(true)
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=30&skip=" + currentPage,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>

          {props.pageType === 'viewallsearchresults' ? 'Betagro All Product' : props.pageType === 'newproducts' ? 'Betagro New Items' : props.pageType === 'promoproducts' ? 'Betagro Promotion' : ''}
        </title>
      </Helmet>
      {/* header-section start from here */}
      <div className="header-section">
        <Header />
      </div>
      {/* header-section end */}
      {loading && (<Loading />)}
      <div className="white-background">
        {/* Top-banner-section start */}
        <div className="all-product-section">

        </div>
        {/* Top-banner-section end */}
        {/* No-result-found-section start from here */}
        {(!productData ||
          productData.length === 0) && (
            <div className="container all-product-inner-section">
              <div className="row">
                <h6 className="all-product-inner-section-heading">{keywordsearch}</h6>
                <h6 className="all-product-inner-section-inner-heading">{t("0productsfound.label")}</h6>
                <div className="no-result-found-main-container">
                  <img src={NoResultFound} className="no-result-found-image" />
                  <h6 className="no-result-found-image-main-heading">{t("NoResultFound.label")}</h6>
                </div>
              </div>
            </div>
          )}
        {/* No-result-found-section end */}
        {/* All-product-section start from here */}
        {productData && productData.result && productData.result.length > 0 && (
          <>
            <div className="container all-product-inner-section">
              <div className="row">
                {/* filter-section start from here */}
                <Filter
                  filterData={filterData}
                  filters={filters}
                  handleChange={handleChange}
                  CategoryChange={CategoryChange}
                  resetFilter={resetFilter}
                  handleValueWithRadio={handleValueWithRadio}
                  currentPage={currentPage}
                  totalProduct={totalProduct}
                  keywordsearch={keywordsearch}
                />
                <div className="col-sm-1"></div>

                {/* filter-section end */}
                {/* prouduct-card-section start from here */}

                <ProductCards
                  keywordsearch={keywordsearch}
                  productData={productData}
                  setPage={setPage}
                  totalProduct={totalProduct}
                  onValueSelected={handleValueSelected}
                  paramValue={paramValue}
                  handleIsLiked={handleIsLiked}
                  handlecarttoggle={handlecarttoggle}
                  handleupdateQuantity={handleupdateQuantity}
                  {...props}
                />

                {/* prouduct-card-section end */}
              </div>
            </div>
          </>
        )}

        {/* All-product-section start from here */}
      </div>
      {/* Recommended-section start from here */}
      {/* <div className="recommended-main-container container-fluid white-background">
        <div className="recommended-inner-section container">
          <div className="row">
            <div className="top-recommended-heading-container">
              <img src={like} className="top-recommended-image" />
              <span className="top-recommended-heading"><h1>{t("Youmayalsolike.label")}</h1></span>
            </div>
          </div>
          <div className="row common-bottom-margin-80">
            <div className="col-sm-3 mt-4">
              <div className="all-recommended-card-shadow">
                <div className="all-product-card-inner">
                  <div className="product-card-image-badge">
                    <img alt="" src={promoBanner} style={{ width: 80 }} />
                  </div>
                  <img src={promoImage} className="img-fluid" />
                  <div className="row">
                    <div className="col-sm-8 col-8">
                      <h6 className="card-product-name">
                        {t("BetagroHygienicChickenBreast.label")}
                      </h6>
                    </div>
                    <div className="col-sm-4 col-4 p-0 m-0">
                      <h6 className="card-product-price">฿ 200.00</h6>
                      <h6 className="card-product-quantity">
                        {t("1kgpack.label")}
                      </h6>
                    </div>
                    <div className="col-sm-12">
                      <div className="promo-desc">
                        <select>
                          <option>{t("Selectitems.label")}</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 text-center">
                        <button className="card-add-to-card-whishlist">
                          <img src={favoriteWatchList} />
                        </button>
                      </div>
                      <div className="col-sm-10">
                        <button className="added-to-card-button">
                          <img src={addToWhishlist} />
                          {t("AddToCart.label")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mt-4">
              <div className="all-recommended-card-shadow">
                <div className="all-product-card-inner">
                  <div className="product-card-image-badge">
                    <img alt="" src={promoBanner} style={{ width: 80 }} />
                  </div>
                  <img src={promoImage} className="img-fluid" />
                  <div className="row">
                    <div className="col-sm-8 col-8">
                      <h6 className="card-product-name">
                        {t("BetagroHygienicChickenBreast.label")}
                      </h6>
                    </div>
                    <div className="col-sm-4 col-4 p-0 m-0">
                      <h6 className="card-product-price">฿ 200.00</h6>
                      <h6 className="card-product-quantity">
                        {t("1kgpack.label")}
                      </h6>
                    </div>
                    <div className="col-sm-12">
                      <div className="promo-desc">
                        <select>
                          <option>{t("Selectitems.label")}</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 text-center">
                        <button className="card-add-to-card-whishlist">
                          <img src={favoriteWatchList} />
                        </button>
                      </div>
                      <div className="col-sm-10">
                        <button className="added-to-card-button">
                          <img src={addToWhishlist} />
                          {t("AddToCart.label")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mt-4">
              <div className="all-recommended-card-shadow">
                <div className="all-product-card-inner">
                  <div className="product-card-image-badge">
                    <img alt="" src={promoBanner} style={{ width: 80 }} />
                  </div>
                  <img src={promoImage} className="img-fluid" />
                  <div className="row">
                    <div className="col-sm-8 col-8">
                      <h6 className="card-product-name">
                        {t("BetagroHygienicChickenBreast.label")}
                      </h6>
                    </div>
                    <div className="col-sm-4 col-4 p-0 m-0">
                      <h6 className="card-product-price">฿ 200.00</h6>
                      <h6 className="card-product-quantity">
                        {t("1kgpack.label")}
                      </h6>
                    </div>
                    <div className="col-sm-12">
                      <div className="promo-desc">
                        <select>
                          <option>{t("Selectitems.label")}</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 text-center">
                        <button className="card-add-to-card-whishlist">
                          <img src={favoriteWatchList} />
                        </button>
                      </div>
                      <div className="col-sm-10">
                        <button className="added-to-card-button">
                          <img src={addToWhishlist} />
                          {t("AddToCart.label")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 mt-4">
              <div className="all-recommended-card-shadow">
                <div className="all-product-card-inner">
                  <div className="product-card-image-badge">
                    <img alt="" src={promoBanner} style={{ width: 80 }} />
                  </div>
                  <img src={promoImage} className="img-fluid" />
                  <div className="row">
                    <div className="col-sm-8 col-8">
                      <h6 className="card-product-name">
                        {t("BetagroHygienicChickenBreast.label")}
                      </h6>
                    </div>
                    <div className="col-sm-4 col-4 p-0 m-0">
                      <h6 className="card-product-price">฿ 200.00</h6>
                      <h6 className="card-product-quantity">
                        {t("1kgpack.label")}
                      </h6>
                    </div>
                    <div className="col-sm-12">
                      <div className="promo-desc">
                        <select>
                          <option>{t("Selectitems.label")}</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 text-center">
                        <button className="card-add-to-card-whishlist">
                          <img src={favoriteWatchList} />
                        </button>
                      </div>
                      <div className="col-sm-10">
                        <button className="added-to-card-button">
                          <img src={addToWhishlist} />
                          {t("AddToCart.label")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Recommended-section end */}
      <div className="footer-section">
        <Footer />
      </div>
      {/* footer-section start end */}
      <Button>
        <FaArrowCircleUp
          onClick={scrollToTop}
          style={{ display: visible ? "inline" : "none" }}
        />
      </Button>
    </>
  )
}

export { ViewAllSearchResults as ViewAllSearchResults };