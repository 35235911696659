import React from 'react';
import { CommonTableHeader } from '../TableHeader/TableHeader';
import { HamburgerTableMenu } from '../HamburgerTableMenu/HamburgerTableMenu';

const ComingSoon = (props) => {
    return <>
        <CommonTableHeader />
        <div className='container-fluid'>
            <div className='row'>
                <HamburgerTableMenu screen={props?.location?.state?.screen}/>
                <div className='col-sm-9'>
                    <div className='coming-soon-main-container'>
                    <div className='coming-soon-inner-container'>
                        <h6 className='coming-soon-main-heading'>Coming Soon</h6>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default ComingSoon