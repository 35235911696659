import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Axios from "axios";
import moment from "moment";
import React, { useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HamburgerTableMenu } from "../OrderList/HamburgerTableMenu/HamburgerTableMenu";
import { CommonTableHeader } from "../OrderList/TableHeader/TableHeader";
import Loading from "../SharedComponent/Loader/Loader";
import { uploadfile } from "../_actions";
import { REACT_APP_BASE_URL } from "../constant";
import gearImage from "../images/gear.png";
import Empty from "./Assets/Images/Empty.png";
import UploadFile from "./Assets/Images/UploadFile.png";
import CircleCheck from "./Assets/SVG/CircleCheck.svg";
import CrossGrey from "./Assets/SVG/CrossGrey.svg";
import MoneyBills from "./Assets/SVG/MoneyBills.svg";
import Reload from "./Assets/SVG/Reload.svg";
import "./UserManagement.css";

const UserManagement = (props) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);
  const hiddenFileInput = useRef(null);
  const hiddenFileInputSale = useRef(null);
  const [filenameuser, setFilenameuser] = React.useState([]);

  const [dataupdated, setDataupdated] = React.useState(false);
  const [wrongFileUploaduser, setWrongFileUploaduser] = React.useState(false);

  const [rightfileuploadeduser, setRightfileuploadeduser] =
    React.useState(false);
  const [rightfileuploadedusersuccess, setRightfileuploadedusersuccess] =
    React.useState(false);
  const [rightfileuploadeduserfailure, setRightfileuploadeduserfailure] =
    React.useState(false);
  const [showprogressuser, setShowprogressuser] = React.useState(false);
  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [tableData, setTableData] = React.useState([]);
  const [totalCountuser, setTotalCountuser] = React.useState(0);
  const [rowsPerPageuser, setRowsPerPageuser] = React.useState(5);
  const [pageuser, setPageuser] = React.useState(0);
  const [currentPageuser, setCurrentPageuser] = React.useState(1);
  const [reasonforfailureuser, setReasonforfailureuser] = React.useState("");
  const [progressuser, setProgressuser] = React.useState(5);
  const [reasonforsuccessuser, setReasonforsuccesseuser] = React.useState("");
  const [dragActiveuser, setDragActiveuser] = React.useState(false);

  const [filenameSale, setFilenameSale] = React.useState([]);
  const [wrongFileUploadSale, setWrongFileUploadSale] = React.useState(false);
  const [rightfileuploadedSale, setRightfileuploadedSale] = React.useState(false);
  const [rightfileuploadedSalesuccess, setRightfileuploadedSalesuccess] = React.useState(false);
  const [rightfileuploadedSalefailure, setRightfileuploadedSalefailure] = React.useState(false);
  const [showprogressSale, setShowprogressSale] = React.useState(false);

  const [tableDataSale, setTableDataSale] = React.useState([]);
  const [totalCountSale, setTotalCountSale] = React.useState(0);
  const [rowsPerPageSale, setRowsPerPageSale] = React.useState(5);
  const [pageSale, setPageSale] = React.useState(0);
  const [currentPageSale, setCurrentPageSale] = React.useState(1);
  const [reasonforfailureSale, setReasonforfailureSale] = React.useState("");
  const [progressSale, setProgressSale] = React.useState(5);
  const [reasonforsuccessSale, setReasonforsuccesseSale] = React.useState("");

  const [toggleValuePro, setToggleValuePro] = React.useState(false);
  const [togglePro, setTogglePro] = React.useState(true);

  const getuploadfileList = useSelector(
    (state) => state.uploadfile.getuploadfileList
  );

  const getuploadfileSaleList = useSelector(
    (state) => state.uploadfile.getuploadfileSaleList
  );
  const [openPagination, setOpenPagination] = useState(true);
  const dispatch = useDispatch();
  const store = userData ? userData.user.stores[0].storeCode : "";

  const [dragActiveSale, setDragActiveSale] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      history.push("/login");
    }
    if (userData) {
      let payload_user = {
        type: "Customer",
      };
      let payload_Sale = {
        type: "Saleshop",
      };

      let queryString_user = `?limit=${parseInt(
        rowsPerPageuser
      )}&skip=${pageuser}`;

      let queryString_Sale = `?limit=${parseInt(
        rowsPerPageSale
      )}&skip=${pageSale}`;
      dispatch(
        uploadfile.getuploadfilelistproduct("", payload_user, queryString_user)
      );
      dispatch(
        uploadfile.getuploadfilelistsale("", payload_Sale, queryString_Sale)
      );
      setLoading(false);
    }
  }, [pageuser, pageSale, dataupdated]);

  React.useEffect(() => {
    setLoading(true);
    if (getuploadfileList?.data && getuploadfileList?.data.result.length > 0) {
      getuploadfileList?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != "Invalid date") {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableData(getuploadfileList?.data.result);
      setLoading(false);
      setTotalCountuser(getuploadfileList?.data.totalCount);
    } else {
      setTableData([]);
      setLoading(false);
      setTotalCountuser(0);
    }

    if (
      getuploadfileSaleList?.data &&
      getuploadfileSaleList?.data.result.length > 0
    ) {
      getuploadfileSaleList?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != 'Invalid date') {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableDataSale(getuploadfileSaleList?.data.result);
      setLoading(false);
      setTotalCountSale(getuploadfileSaleList?.data.totalCount);
    } else {
      setTableDataSale([]);
      setLoading(false);
      setTotalCountSale(0);
    }
  }, [getuploadfileList, pageuser, pageSale, dataupdated]);

  React.useEffect(() => {
    setLoading(true);
    if (getuploadfileSaleList?.data && getuploadfileSaleList?.data.result.length > 0) {
      getuploadfileSaleList?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != "Invalid date") {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableDataSale(getuploadfileSaleList?.data.result);
      setLoading(false);
      setTotalCountSale(getuploadfileSaleList?.data.totalCount);
    } else {
      setTableDataSale([]);
      setLoading(false);
      setTotalCountSale(0);
    }
  }, [getuploadfileSaleList, pageuser, dataupdated]);

  const totalPagesuser =
    tableData &&
    Math.ceil(parseInt(totalCountuser) / parseInt(rowsPerPageuser));

  const totalPagesSale =
    tableDataSale &&
    Math.ceil(parseInt(totalCountSale) / parseInt(rowsPerPageuser));

  React.useEffect(() => {
    const timeruser = setInterval(() => {
      setProgressuser((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timeruser);
    };
  }, []);

  React.useEffect(() => {
    const timerSale = setInterval(() => {
      setProgressSale((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerSale);
    };
  }, []);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleClickSale = (event) => {
    hiddenFileInputSale.current.click();
  };

  const handleChangeuser = (event) => {
    const fileUploaded = event.target.files[0];
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (types.every((type) => event.target.files[0].type !== type)) {
      setWrongFileUploaduser(true);
      return;
    } else {
      setWrongFileUploaduser(false);
      setRightfileuploadeduser(true);
    }
    setFilenameuser(fileUploaded);
  };

  const handleChangeSale = (event) => {
    const fileUploadedSale = event.target.files[0];
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (types.every((type) => event.target.files[0].type !== type)) {
      setWrongFileUploadSale(true);
      return;
    } else {
      setWrongFileUploadSale(false);
      setRightfileuploadedSale(true);
    }
    setFilenameSale(fileUploadedSale);
  };

  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };

  const handleDraguser = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActiveuser(true);
    } else if (e.type === "dragleave") {
      setDragActiveuser(false);
    }
  };

  // triggers when file is dropped
  const handleDropuser = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActiveuser(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileUploaded = e.dataTransfer.files[0];
      const types = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (types.every((type) => e.dataTransfer.files[0].type !== type)) {
        setWrongFileUploaduser(true);
        return;
      } else {
        setWrongFileUploaduser(false);
        setRightfileuploadeduser(true);
      }
      setFilenameuser(fileUploaded);
    }
  };

  const handleDragSale = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActiveSale(true);
    } else if (e.type === "dragleave") {
      setDragActiveSale(false);
    }
  };

  // triggers when file is dropped
  const handleDropSale = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActiveSale(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileUploaded = e.dataTransfer.files[0];
      const types = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (types.every((type) => e.dataTransfer.files[0].type !== type)) {
        setWrongFileUploadSale(true);
        return;
      } else {
        setWrongFileUploadSale(false);
        setRightfileuploadedSale(true);
      }
      setFilenameSale(fileUploaded);
    }
  };

  const saveFileUser = (event, role) => {
    setDataupdated(false);
    setShowprogressuser(true);
    event.preventDefault();
    //const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameuser);
    payload.append("roles", role);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/users/uploadUser",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessprouct(response.message);
        setRightfileuploadedusersuccess(true);
        setRightfileuploadeduser(false);
        setShowprogressuser(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureuser(error.message);
        setRightfileuploadeduserfailure(true);
        setRightfileuploadeduser(false);
        setShowprogressuser(false);
        setDataupdated(true);
        error;
      });
  };

  const reuploadFileuser = (event, role) => {
    setDataupdated(false);
    setWrongFileUploaduser(false);
    setRightfileuploadedusersuccess(false);
    setRightfileuploadeduserfailure(false);
    setRightfileuploadeduser(true);
    setShowprogressuser(true);
    event.preventDefault();
    //const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameuser);
    payload.append("roles", role);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/users/uploadUser",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessprouct(response.message);
        setRightfileuploadedusersuccess(true);
        setRightfileuploadeduser(false);
        setShowprogressuser(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureuser(error.message);
        setRightfileuploadeduserfailure(true);
        setRightfileuploadeduser(false);
        setShowprogressuser(false);
        setDataupdated(true);
        error;
      });
  };

  const saveFileSale = (event, role) => {
    setDataupdated(false);
    setShowprogressSale(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameSale);
    const token = localStorage.getItem("authorization");
    payload.append("roles", role);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/users/uploadUser",
      payload,
      requestOptions
    )
      .then((response) => {
        setRightfileuploadedSalesuccess(true);
        setRightfileuploadedSale(false);
        setShowprogressSale(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureSale(error.message);
        setRightfileuploadedSalefailure(true);
        setRightfileuploadedSale(false);
        setShowprogressSale(false);
        setDataupdated(true);
        error;
      });
  };

  const reuploadFileSale = (event, role) => {
    setDataupdated(false);
    setWrongFileUploadSale(false);
    setRightfileuploadedSale(true);
    setRightfileuploadedSalesuccess(false);
    setRightfileuploadedSalefailure(false);
    setShowprogressSale(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameSale);
    payload.append("roles", role);

    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/users/uploadUser",
      payload,
      requestOptions
    )
      .then((response) => {
        setRightfileuploadedSalesuccess(true);
        setRightfileuploadedSale(false);
        setShowprogressSale(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureSale(error.message);
        setRightfileuploadedSalefailure(true);
        setRightfileuploadedSale(false);
        setShowprogressSale(false);
        setDataupdated(true);
        error;
      });
  };

  const handleChangePageuser = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPageuser;
    const payload = {
      type: "Customer",
    };

    setPageuser(pages);
    setCurrentPageuser(newPage);
  };

  const handleChangePageSale = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPageSale;
    const payload = {
      type: "Saleshop",
    };

    setPageSale(pages);
    setCurrentPageSale(newPage);
  };

  const clearFileuser = (event) => {
    setFilenameuser("");
    setWrongFileUploaduser(false);
    // setRightfileuploadeduser(false);
    setRightfileuploadedusersuccess(false);
    setRightfileuploadeduserfailure(false);
  };

  const clearFileSale = (event) => {
    setFilenameSale("");
    setWrongFileUploadSale(false);
    setRightfileuploadedSalesuccess(false);
    setRightfileuploadedSalefailure(false);
  };

  const toggleHandler = (eventKey, cb, tcb) => {
    if (eventKey === true) {
      cb(!eventKey);
    } else {
      cb(eventKey);
    }
    tcb(!eventKey);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <CommonTableHeader />
      <Helmet>
        <title>Betagro Customer Upload</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container-fluid" data-testid="bulk-upload-screen-loading">
        <div className="row">
          <HamburgerTableMenu screen={props?.location?.state?.screen} />
          <div
            className="col-sm-9"
            style={{ paddingTop: "100px", backgroundColor: "#F5F5F5" }}
          >
            <div className="UserManagement">
              <div className="bulkUploadHead">
                <h2 data-testid="bulk-upload-heading">Customer Upload</h2>
              </div>
              <div className="bulkUploadCard">
                <h3 data-testid="upload-user-file-heading">
                  Upload Customer File
                </h3>
                <div className="row">
                  <div className="col-lg-12">
                    <form
                      onSubmit={(event) => saveFileUser(event, "Customer")}
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div
                        className="bulkUploadCard-uploadFile"
                        onDragEnter={handleDraguser}
                      >
                        <input
                          id="input-file-upload-user"
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleChangeuser}
                          style={{ display: "none" }}
                          accept=".xlsx,.xlx"
                        />
                        <label
                          title="Drag and Drop user file here"
                          id="label-file-upload"
                          htmlFor="input-file-upload"
                          className={dragActiveuser ? "drag-activeuser" : ""}
                        >
                          <img
                            titel="Click to upload"
                            src={UploadFile}
                            data-testid="click-to-upload-image"
                            onClick={handleClick}
                            className="bulkUploadCard-uploadFile-img cursor-pointer"
                          />
                          <p>
                            {isNull(filenameuser?.name) ? (
                              <span onClick={handleClick}>
                                {" "}
                                Choose a file here
                              </span>
                            ) : (
                              filenameuser?.name
                            )}
                          </p>
                          {wrongFileUploaduser && (
                            <h6 className="invalid-feedback">
                              "Please uplaod a valid file of format .xlsx or
                              .xlx"
                            </h6>
                          )}
                        </label>
                      </div>
                      {dragActiveuser && (
                        <div
                          className="drag-file-element"
                          onDragEnter={handleDraguser}
                          onDragLeave={handleDraguser}
                          onDragOver={handleDraguser}
                          onDrop={handleDropuser}
                        ></div>
                      )}
                      {rightfileuploadedusersuccess ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileSuccess">
                          <div className="row">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div>
                                <h4>{filenameuser.name}</h4>
                                <h4>
                                  <span>{filenameuser.size} mb</span>
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              <img
                                src={CircleCheck}
                                className="mr-4 cursor-pointer"
                              />
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileuser(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {rightfileuploadeduser ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileProgress">
                          <div className="row">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div style={{ width: "95%" }}>
                                <h4>{filenameuser.name}</h4>
                                <div className="d-flex align-items-center justify-content-between">
                                  {showprogressuser &&
                                    !rightfileuploadedusersuccess &&
                                    !rightfileuploadeduserfailure ? (
                                    <>
                                      {/* <LinearProgress
                                        sx={{
                                          width: "100%",
                                          backgroundColor: "#D3D6DA",
                                        }}
                                        color="success"
                                        variant="determinate"
                                        value={progressuser}
                                      />
                                      <div className="bulkUploadCard-uploadFileProgress-status ml-3">{`${Math.round(
                                        progressuser
                                      )}%`}</div> */}
                                      <Stack
                                        sx={{
                                          width: "100%",
                                          color: "grey.500",
                                        }}
                                        spacing={2}
                                      >
                                        <LinearProgress
                                          sx={{
                                            width: "100%",
                                            backgroundColor: "#D3D6DA",
                                          }}
                                          color="success"
                                        />
                                      </Stack>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              {/*  <img src={Pause} className="mr-4 cursor-pointer" /> */}
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileuser(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {rightfileuploadeduserfailure ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileFail">
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div>
                                <h4>{filenameuser.name}</h4>
                                <h4>
                                  <span>
                                    {reasonforsuccessuser}&nbsp;
                                    {reasonforfailureuser} ( Fail)
                                  </span>
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              <button
                                type="button"
                                title="Click and Re Upload  a user file "
                                onClick={(event) => reuploadFileuser(event, "Customer")}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={Reload}
                                  className="mr-4 cursor-pointer"
                                />
                              </button>
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileuser(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="bulkUploadCard-uploadFileHistory">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <div className="d-flex align-items-baseline justify-content-between">
                              <Accordion.Header>
                                <div className="bulkUploadCard-uploadFileHistoryHead">
                                  <h5
                                    onClick={() =>
                                      toggleHandler(
                                        togglePro,
                                        setToggleValuePro,
                                        setTogglePro
                                      )
                                    }
                                  >
                                    {" "}
                                    {toggleValuePro !== togglePro
                                      ? `Hide Upload History`
                                      : `See Upload History`}
                                  </h5>
                                  {/* {rightfileuploadeduser && (
                                  <button
                                    type="submit"
                                    className="bulkUploadCard-uploadBtn"
                                  >
                                    Upload
                                  </button>
                                )}

                                {!rightfileuploadeduser && (
                                  <div
                                    title="Click and upload a user file to activate the button"
                                    className="bulkUploadCard-uploadBtnDsbld"
                                  >
                                    Upload
                                  </div>
                                )} */}
                                </div>
                              </Accordion.Header>
                              {rightfileuploadeduser && (
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <button
                                    onClick={() =>
                                      openInNewTab(
                                        REACT_APP_BASE_URL +
                                        "/api/download/masterfileCustomer"
                                      )
                                    }
                                    className="bulkUploadCard-uploadBtnDownload"
                                    type="button"
                                  >
                                    Template File
                                  </button>
                                  <button
                                    type="submit"
                                    className="bulkUploadCard-uploadBtn"
                                  >
                                    Upload
                                  </button>
                                </Grid>
                              )}

                              {!rightfileuploadeduser && (
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <button
                                    onClick={() =>
                                      openInNewTab(
                                        REACT_APP_BASE_URL +
                                        "/api/download/masterfileCustomer"
                                      )
                                    }
                                    className="bulkUploadCard-uploadBtnDownload"
                                    type="button"
                                  >
                                    Template File
                                  </button>
                                  <div
                                    title="Click and upload a user file to activate the button"
                                    className="bulkUploadCard-uploadBtnDsbld"
                                  >
                                    Upload
                                  </div>
                                </Grid>
                              )}
                            </div>
                            <Accordion.Body>
                              <h3 className="mt-5 mb-5">
                                Upload History ({totalCountuser})
                              </h3>
                              <div className="bulkUploadCard-uploadFileHistoryDesc">
                                <div className="bulkUploadCard-uploadFileHistoryDesc-head">
                                  <div className="row">
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      <h6>Date</h6>
                                    </div>
                                    <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                      <h6>File Name</h6>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                      <h6>Uploaded By</h6>
                                    </div>
                                    {/* <div className="col-lg-2">
                                      <h6>Reason</h6>
                                    </div> */}
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      <h6>Status</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="bulkUploadCard-uploadFileHistoryDesc-body">
                                  {tableData &&
                                    tableData.length > 0 &&
                                    tableData.map((items, index1) => (
                                      <div key={index1} className="row bulkUploadCard-uploadFileHistoryDesc-body-in">
                                        <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                          <h6>{items.updatedAt}</h6>
                                        </div>
                                        <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                          <h6
                                            title={items.filename}
                                            className="my-bulk-upload-file-name-truncate"
                                          >
                                            {items.filename &&
                                              items.filename.length > 35
                                              ? items.filename.substring(
                                                0,
                                                35
                                              ) + "..."
                                              : items.filename}
                                          </h6>
                                        </div>
                                        <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                          <h6
                                            title={
                                              items.firstNameEN +
                                              " " +
                                              items.lastNameEN
                                            }
                                          >
                                            <img
                                              src={gearImage}
                                              className="gearImage"
                                            />
                                            &nbsp;{items.firstNameEN}&nbsp;
                                            {items.lastNameEN &&
                                              items.lastNameEN.length > 4
                                              ? items.lastNameEN.substring(
                                                0,
                                                4
                                              ) + "..."
                                              : items.lastNameEN}
                                          </h6>
                                        </div>
                                        {/* <div className="col-lg-2 d-flex align-items-center justify-content-center">
                                          {items.status === true ? (
                                            <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                              {items.remark}
                                            </h6>
                                          ) : (
                                            <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                              {items.remark}
                                            </h6>
                                          )}
                                        </div> */}
                                        <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                          {items.status === true ? (
                                            <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                              Success
                                            </h6>
                                          ) : (
                                            <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                              Failed
                                            </h6>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                {!tableData ||
                                  (tableData.length === 0 && (
                                    <div className="bulkUploadCard-uploadFileHistoryDesc-bodyEmpty">
                                      <img src={Empty} />
                                      <p>No data available</p>
                                    </div>
                                  ))}
                              </div>
                              {tableData && tableData.length > 0 && (
                                <>
                                  <div
                                    className="row pagination-main-container common-display-none-mobile mt-4 mb-2"
                                    data-testid="pagination-container"
                                  >
                                    <div className="col-xll-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex justify-content-end">
                                      <Stack spacing={2}>
                                        <Pagination
                                          count={totalPagesuser}
                                          page={currentPageuser}
                                          variant="outlined"
                                          shape="rounded"
                                          onChange={(event, page) =>
                                            handleChangePageuser(event, page)
                                          }
                                        />
                                      </Stack>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="bulkUploadHead">
                <h2 data-testid="bulk-upload-heading">Sale Upload</h2>
              </div>
              <div className="bulkUploadCard">
                <h3 data-testid="upload-user-file-heading">Upload Sale File</h3>
                <div className="row">
                  <div className="col-lg-12">
                    <form
                      onSubmit={(event) => saveFileSale(event, "Saleshop")}
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div
                        className="bulkUploadCard-uploadFile"
                        onDragEnter={handleDragSale}
                      >
                        <input
                          id="input-file-upload-user"
                          type="file"
                          ref={hiddenFileInputSale}
                          onChange={handleChangeSale}
                          style={{ display: "none" }}
                          accept=".xlsx,.xlx"
                        />
                        <label
                          title="Drag and Drop Sale file here"
                          id="label-file-upload"
                          htmlFor="input-file-upload"
                          className={dragActiveSale ? "drag-activeuser" : ""}
                        >
                          <img
                            titel="Click to upload"
                            src={UploadFile}
                            data-testid="click-to-upload-image"
                            onClick={handleClickSale}
                            className="bulkUploadCard-uploadFile-img cursor-pointer"
                          />
                          <p>
                            {isNull(filenameSale?.name) ? (
                              <span onClick={handleClickSale}>
                                {" "}
                                Choose a file here
                              </span>
                            ) : (
                              filenameSale?.name
                            )}
                          </p>
                          {wrongFileUploadSale && (
                            <h6 className="invalid-feedback">
                              "Please uplaod a valid file of format .xlsx or
                              .xlx"
                            </h6>
                          )}
                        </label>
                      </div>
                      {dragActiveSale && (
                        <div
                          className="drag-file-element"
                          onDragEnter={handleDragSale}
                          onDragLeave={handleDragSale}
                          onDragOver={handleDragSale}
                          onDrop={handleDropSale}
                        ></div>
                      )}
                      {rightfileuploadedSalesuccess ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileSuccess">
                          <div className="row">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div>
                                <h4>{filenameSale.name}</h4>
                                <h4>
                                  <span>{filenameSale.size} mb</span>
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              <img
                                src={CircleCheck}
                                className="mr-4 cursor-pointer"
                              />
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileSale(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {rightfileuploadedSale ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileProgress">
                          <div className="row">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div style={{ width: "95%" }}>
                                <h4>{filenameSale.name}</h4>
                                <div className="d-flex align-items-center justify-content-between">
                                  {showprogressSale &&
                                    !rightfileuploadedSalesuccess &&
                                    !rightfileuploadedSalefailure ? (
                                    <>
                                      <Stack
                                        sx={{
                                          width: "100%",
                                          color: "grey.500",
                                        }}
                                        spacing={2}
                                      >
                                        <LinearProgress
                                          sx={{
                                            width: "100%",
                                            backgroundColor: "#D3D6DA",
                                          }}
                                          color="success"
                                        />
                                      </Stack>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              {/*  <img src={Pause} className="mr-4 cursor-pointer" /> */}
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileSale(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {rightfileuploadedSalefailure ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileFail">
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div>
                                <h4>{filenameSale.name}</h4>
                                <h4>
                                  <span>
                                    {reasonforsuccessSale}&nbsp;
                                    {reasonforfailureSale} ( Fail)
                                  </span>
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              <button
                                type="button"
                                title="Click and Re Upload  a Sale file "
                                onClick={(event) => reuploadFileSale(event, "Saleshop")}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={Reload}
                                  className="mr-4 cursor-pointer"
                                />
                              </button>
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileSale(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="bulkUploadCard-uploadFileHistory">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <div className="d-flex align-items-baseline justify-content-between">
                              <Accordion.Header>
                                <div className="bulkUploadCard-uploadFileHistoryHead">
                                  <h5
                                    onClick={() =>
                                      toggleHandler(
                                        togglePro,
                                        setToggleValuePro,
                                        setTogglePro
                                      )
                                    }
                                  >
                                    {" "}
                                    {toggleValuePro !== togglePro
                                      ? `Hide Upload History`
                                      : `See Upload History`}
                                  </h5>
                                </div>
                              </Accordion.Header>
                              {rightfileuploadedSale && (
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <button
                                    onClick={() =>
                                      openInNewTab(
                                        REACT_APP_BASE_URL +
                                        "/api/download/masterfileSale"
                                      )
                                    }
                                    className="bulkUploadCard-uploadBtnDownload"
                                    type="button"
                                  >
                                    Template File
                                  </button>
                                  <button
                                    type="submit"
                                    className="bulkUploadCard-uploadBtn"
                                  >
                                    Upload
                                  </button>
                                </Grid>
                              )}

                              {!rightfileuploadedSale && (
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <button
                                    onClick={() =>
                                      openInNewTab(
                                        REACT_APP_BASE_URL +
                                        "/api/download/masterfileSale"
                                      )
                                    }
                                    className="bulkUploadCard-uploadBtnDownload"
                                    type="button"
                                  >
                                    Template File
                                  </button>
                                  <div
                                    title="Click and upload a Sale file to activate the button"
                                    className="bulkUploadCard-uploadBtnDsbld"
                                  >
                                    Upload
                                  </div>
                                </Grid>
                              )}
                            </div>
                            <Accordion.Body>
                              <h3 className="mt-5 mb-5">
                                Upload History ({totalCountSale})
                              </h3>
                              <div className="bulkUploadCard-uploadFileHistoryDesc">
                                <div className="bulkUploadCard-uploadFileHistoryDesc-head">
                                  <div className="row">
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      <h6>Date</h6>
                                    </div>
                                    <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                      <h6>File Name</h6>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                      <h6>Uploaded By</h6>
                                    </div>
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      <h6>Status</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="bulkUploadCard-uploadFileHistoryDesc-body">
                                  {tableDataSale &&
                                    tableDataSale.length > 0 &&
                                    tableDataSale.map((itemssale, index2) => (
                                      <div key={index2} className="row bulkUploadCard-uploadFileHistoryDesc-body-in">
                                        <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                          <h6>{itemssale.updatedAt}</h6>
                                        </div>
                                        <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                          <h6
                                            title={itemssale.filename}
                                            className="my-bulk-upload-file-name-truncate"
                                          >
                                            {itemssale.filename &&
                                              itemssale.filename.length > 35
                                              ? itemssale.filename.substring(
                                                0,
                                                35
                                              ) + "..."
                                              : itemssale.filename}
                                          </h6>
                                        </div>
                                        <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                          <h6
                                            title={
                                              itemssale.firstNameEN +
                                              " " +
                                              itemssale.lastNameEN
                                            }
                                          >
                                            <img
                                              src={gearImage}
                                              className="gearImage"
                                            />
                                            &nbsp;{itemssale.firstNameEN}&nbsp;
                                            {itemssale.lastNameEN &&
                                              itemssale.lastNameEN.length > 4
                                              ? itemssale.lastNameEN.substring(
                                                0,
                                                4
                                              ) + "..."
                                              : itemssale.lastNameEN}
                                          </h6>
                                        </div>
                                        <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                          {itemssale.status === true ? (
                                            <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                              Success
                                            </h6>
                                          ) : (
                                            <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                              Failed
                                            </h6>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                {!tableDataSale ||
                                  (tableDataSale.length === 0 && (
                                    <div className="bulkUploadCard-uploadFileHistoryDesc-bodyEmpty">
                                      <img src={Empty} />
                                      <p>No data available</p>
                                    </div>
                                  ))}
                              </div>
                              {tableDataSale && tableDataSale.length > 0 && (
                                <>
                                  <div
                                    className="row pagination-main-container common-display-none-mobile mt-4 mb-2"
                                    data-testid="pagination-container"
                                  >
                                    <div className="col-xll-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex justify-content-end">
                                      <Stack spacing={2}>
                                        <Pagination
                                          count={totalPagesSale}
                                          page={currentPageSale}
                                          variant="outlined"
                                          shape="rounded"
                                          onChange={(event, page) =>
                                            handleChangePageSale(event, page)
                                          }
                                        />
                                      </Stack>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { UserManagement as UserManagement };

