import { specialpriceConstants } from "../_constants";
const initReducers = {

  postuploadpriceList: null,
  postuploadpriceError: null,
  postuploadspecialpriceList: null,
  postuploadspecialpriceError: null,

};
export function specialprice(state = initReducers, action) {
  switch (action.type) {
    case specialpriceConstants.POST_UPLOAD_PRICE_REQUEST_INFORMATION:
      return {
        ...state,
        postuploadpriceList: action.data,
        postuploadpriceError: action.error,
      };
      case specialpriceConstants.POST_UPLOAD_PRICE_SUCCESS_INFORMATION:
      return {
        ...state,
        postuploadpriceList: action.data,
        postuploadpriceError: action.error,
      };
      case specialpriceConstants.POST_UPLOAD_PRICE_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        postuploadpriceList: action.data,
        postuploadpriceError: action.error,
      };
      case specialpriceConstants.POST_UPLOAD_PRICE_FAILURE_INFORMATION:
      return {
        ...state,
        postuploadpriceList: action.data,
        postuploadpriceError: action.error,
      };

    case specialpriceConstants.POST_UPLOAD_SPECIAL_PRICE_REQUEST_INFORMATION:
      return {
        ...state,
        postuploadspecialpriceList: action.data,
        postuploadspecialpriceError: action.error,
      };
    case specialpriceConstants.POST_UPLOAD_SPECIAL_PRICE_SUCCESS_INFORMATION:
      return {
        ...state,
        postuploadspecialpriceList: action.data,
        postuploadspecialpriceError: action.error,
      };
    case specialpriceConstants.POST_UPLOAD_SPECIAL_PRICE_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        postuploadspecialpriceList: action.data,
        postuploadspecialpriceError: action.error,
      };
    case specialpriceConstants.POST_UPLOAD_SPECIAL_PRICE_FAILURE_INFORMATION:
      return {
        ...state,
        postuploadspecialpriceList: action.data,
        postuploadspecialpriceError: action.error,
      };
      default:
      return state;
  }
}
