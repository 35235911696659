import { stockConstants } from "../_constants";
const initReducers = {
 
  postuploadstockList: null,
  postuploadstockError: null,

};
export function stock(state = initReducers, action) {
  switch (action.type) {
    case stockConstants.POST_UPLOAD_STOCK_REQUEST_INFORMATION:
      return {
        ...state,
        postuploadstockList: action.data,
        postuploadstockError: action.error,
      };
      case stockConstants.POST_UPLOAD_STOCK_SUCCESS_INFORMATION:
      return {
        ...state,
        postuploadstockList: action.data,
        postuploadstockError: action.error,
      };
      case stockConstants.POST_UPLOAD_STOCK_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        postuploadstockList: action.data,
        postuploadstockError: action.error,
      };
      case stockConstants.POST_UPLOAD_STOCK_FAILURE_INFORMATION:
      return {
        ...state,
        postuploadstockList: action.data,
        postuploadstockError: action.error,
      };
    default:
      return state;
  }
}
