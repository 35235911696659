import { createOrderConstant } from "../_constants";
import { createOrderService } from "../_services";

export const createOrderAction = {
  getOrderDetails,
  updateQty,
  getOrderDetailsByOrderId
};


function getOrderDetails(orderId, payload) {
  return (dispatch) => {
      dispatch(request());
      createOrderService.getOrderProductDetails(orderId,payload)
          .then((res) => {

              if (res.status === 200 && res.data) {
                  dispatch(success(res.data));
              } else if ( res.status === undefined && res.response.data.code === 400) {
                  dispatch(noDataFound(res.data));
              }
          })
          .catch((error) => dispatch(failure(error)));
  }
  function request() {
      return { type: createOrderConstant.ORDER_PRODUCT_DETAILS_REQUEST };
  }
  function success(data) {
      return { type: createOrderConstant.ORDER_PRODUCT_DETAILS_SUCCESS, data };
  }
  function noDataFound(data) {
      return { type: createOrderConstant.ORDER_PRODUCT_DETAILS_NODATA, data };
  }
  function failure(error) {
      return { type: createOrderConstant.ORDER_PRODUCT_DETAILS_FAILURE, error };

  }
}


function updateQty(payload) {
    return (dispatch) => {
        dispatch(request());
        createOrderService.qtyUpdate(payload)
            .then((res) => {
  
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if ( res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: createOrderConstant.ORDER_PRODUCT_QTY_UPDATE_REQUEST };
    }
    function success(data) {
        return { type: createOrderConstant.ORDER_PRODUCT_QTY_UPDATE_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: createOrderConstant.ORDER_PRODUCT_QTY_UPDATE_NODATA, data };
    }
    function failure(error) {
        return { type: createOrderConstant.ORDER_PRODUCT_QTY_UPDATE_FAILURE, error };
  
    }
  }

  function getOrderDetailsByOrderId(payload) {
    return (dispatch) => {
        dispatch(request());
        createOrderService.getOrderProductDetailsById(payload)
            .then((res) => {
  
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if ( res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: createOrderConstant.ORDER_PRODUCT_DETAILS_ID_REQUEST };
    }
    function success(data) {
        return { type: createOrderConstant.ORDER_PRODUCT_DETAILS_ID_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: createOrderConstant.ORDER_PRODUCT_DETAILS_ID_NODATA, data };
    }
    function failure(error) {
        return { type: createOrderConstant.ORDER_PRODUCT_DETAILS_ID_FAILURE, error };
  
    }
  }