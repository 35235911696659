import Carousel from "react-bootstrap/Carousel";
import "./style.css";
import LoginSlide1 from "../images/LoginSlide1.png";
import LoginSlide2 from "../images/LoginSlide2.png";
import LoginSlide3 from "../images/LoginSlide3.png" ;
import LoginSlide1Mob from "../images/LoginSlide1Mob.png";
import LoginSlide2Mob from "../images/LoginSlide2Mob.png";
import LoginSlide3Mob from "../images/LoginSlide3Mob.png" ;

const LoginPageBanner = () => {
  return (
    <>
      <div className="banner-wraper">
        <Carousel fade className="banner-promotion">
          <Carousel.Item>
            <div className="herobanner2-parent">
              <img alt="" src={LoginSlide1} className="hidden-sm"/>
              <img alt="" src={LoginSlide1Mob} className="visible-sm"/>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="herobanner2-parent">
              <img alt="" src={LoginSlide2} className="hidden-sm"/>
              <img alt="" src={LoginSlide2Mob} className="visible-sm"/>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="herobanner2-parent">
              <img alt="" src={LoginSlide3} className="hidden-sm"/>
              <img alt="" src={LoginSlide3Mob} className="visible-sm"/>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export { LoginPageBanner as LoginPageBanner };
