import React, { useState } from 'react';
import "./MyAccountChangePassword.css";
import Eye from "./Assets/Images/Eye.png";
import Crosseye from "./Assets/Images/Crosseye.png";
import ChangePassword from "./Assets/Images/ChangePassword.png";
import Warning from "./Assets/SVG/Warning.svg";
import Modal from 'react-bootstrap/Modal';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Header } from "../Header";
import { FaArrowCircleUp } from 'react-icons/fa';
import { Button } from './Styles';
import MyAccountHamburgerMenu from '../MyOrder/MyAccountHamburgerMenu/MyAccountHamburgerMenu';
import { useSelector, useDispatch } from "react-redux";
import { Footer } from "../Footer";
import { REACT_APP_BASE_URL } from "../constant";
import { userActions, landingPageActions, productActions, orderActions } from "../_actions";
import ApiList from '../_constants/const.api';
import ApiProvider from '../_services/ApiProvider';


const MyAccountChangePassword = () => {
  // const handleShowChangePswrdMdl = () => setShowChangePswrdMdl(true);
  const userState = useSelector((state) => state.users);
  const searchState = useSelector((state) => state.landing);
  const lancode = localStorage.getItem("lancode");
  var userData1 = JSON.parse(localStorage.getItem('user'))
  const [flagStatus, setFlagStatus] = useState(true)
  const [loading, setLoading] = useState(false);
  const isNull = (value) => {
    return value === "" || value === undefined || value === null;
  };
  const dispatch = useDispatch();
  var storeCode = !isNull(userData1) ? userData1.user.stores[0].storeCode : "";
  var token = !isNull(userData1) ? userData1.token : "";
  const store = userData1 ? userData1.user.stores[0].storeCode : "";
  const [oldPasswordError, setOldPasswordError] = useState(false)

  React.useEffect(() => {
    setLoading(true);
    if (!userData1) {
      history.push("/login");
    }
    let payload = {};
    let queryString = "";
    //dispatch(userActions.changePassword("", payload,queryString));
    //dispatch(userActions.updateUser("", payload,queryString));
  }, []);
  const [userData, setUserData] = useState([
    { label: "Current Password", value: "", showPassword: false, error: false, errorMessage: "Old password does not match." },
    { label: "New Password", value: "", showPassword: false, error: false, errorMessage: "" },
    { label: "Confirm New Password", showPassword: false,match:false, value: "", error: false, errorMessage: "" }
  ])
  // const isNull = (value) => {
  //   return value === "" || value === undefined || value === null;
  // };
  const [visible, setVisible] = useState(false)
  const [activeMenu, setActiveMenu] = useState('change-password');

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  let history = useHistory();
  const { t } = useTranslation();
  const [showChangePswrdMdl, setShowChangePswrdMdl] = useState(false);
  const handleCloseChangePswrdMdl = () => setShowChangePswrdMdl(false);
  const validateUserData = () => {
    let flag = false;
    let temp = userData;
    let step = true;
    let length = Object.keys(userData).length;
    for (let i = 0; i < length; i++) {
      if (temp[i].error || isNull(temp[i].value)) {
        flag = true;
        step = false;
        setFlagStatus(true)
        // temp[i].errorMessage = "Please fill the required fields!";
        temp[i].error = true;
      }
    }
    if (step) {
      if (temp[1].value.length < 6 || temp[1].value.length > 12) {
        flag = true;
        temp[1].error = true;
        setFlagStatus(true)
      }else if(userData[1].value!=userData[2].value){
        temp[2].match=true
        flag = true;
      }
    }
    setUserData({ ...temp });
    setFlagStatus(false)
    return flag;
  }
  const clearFields = () => {
    let temp = userData;
    let length = Object.keys(userData).length;
    for (let i = 0; i < length; i++) {
      temp[i].value = "";
    }
    setUserData({ ...temp });
  }

  const handleShowChangePswrdMdl = () => {
    if (validateUserData()) {
      return;
    }
   

    const payload = {
      oldPassword: userData[0].value,
      password: userData[1].value,
      confirmPassword: userData[2].value,
    };
    setLoading(true);
    ApiProvider.post(ApiList.API_URL_TO_CREATE_PASSWORD, payload).then((response) => {
      setShowChangePswrdMdl(true);
      setTimeout(() => {
        setShowChangePswrdMdl(false);
        clearFields()
        setFlagStatus(true)
        logOut()
      }, 5000);
    }).catch((errRes) => {
      if (errRes.response.data.data == null || errRes.response.data.code == 500) {
        setOldPasswordError(true);
        return;
      }
    })
    // dispatch(userActions.setPassword("setPassword", payload));
  }
  const isDisableStatus = () => {
    let temp = userData;
    let flag = false;
    let length = Object.keys(userData).length;
    for (let i = 0; i < length; i++) {
      // if (isNull(temp[i].value) || temp[i].error || temp[1].value.length !== temp[2].value.length ||  temp[2].value.length <6) {
        if (isNull(temp[i].value)) {
      flag = true
        break;
      }
    }
    if (flag) {
      setFlagStatus(true)
    } else {
      setFlagStatus(false)
    }
  }

  const logOut = () => {
    // const payloadWithOutLogin = {
    //   typeCode: "2",
    // };
    // const payloadWithOutLoginNew = {
    //   typeCode: "3",
    // };
    dispatch(userActions.logout());
    // setIsUserLoggedIn(false);
    localStorage.removeItem("user");
    localStorage.removeItem("authorization");
    localStorage.removeItem("keepMeLoggedIn");
    localStorage.removeItem("phone");
    localStorage.removeItem("password");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("authorization");
    // dispatch(
    //   landingPageActions.getPromotionWithoutLogin(
    //     "promotion",
    //     payloadWithOutLogin
    //   )
    // );
    // dispatch(
    //   landingPageActions.getNewItemsDataWithOutLogin(
    //     "newItem",
    //     payloadWithOutLoginNew
    //   )
    // );
    history.push("/login");
  };

  // const isDisableStatus=()=>{
  //   let temp=userData;
  //   let flag=false;
  //   let length = Object.keys(userData).length;
  //   for (let i = 0; i < length; i++) {
  //     if(isNull(temp[i].value)){
  //       flag=true
  //     }else{
  //       flag=false
  //     }
  //   }
  //   if(flag){
  //     setFlagStatus(true)
  //   }else{
  //     setFlagStatus(false)
  //   }
  // }
  return (
    <>
    <div className='headerFixed'>
     < Header />
     </div>
      <Helmet>
        <title>
          Betagro Change Password
        </title>
      </Helmet>
      <div className='container-fluid my-order-main-container headerFixed-margin' data-testid="change-password-screen-loading">
        <div className='row'>
          <div className='col-xll-3 col-xl-3 col-md-3 col-sm-12' data-testid="left-menu-section">
            <MyAccountHamburgerMenu activeMenu={activeMenu} />
          </div>
          <div className='col-xll-9 col-xl-9 col-md-9 col-sm-12 p-0'>
            <div className="myAccount myAccount-padxs ">
              <div className="myAccountHead">
                <button className="backBtn visible-sm" onClick={() => history.goBack()} >
                  {t("Back.label")}
                </button>
                <h2 data-testid="change-password-heading"> {t("ChangePassword.label")} </h2>
              </div>
              <div className="myAccountCard myAccountCardHeight">
                <div className="row">
                  <div className="col-lg-6 col-md-9 col-sm-12 col-12">
                    <div className="myAccountCard-ChangePasswordInput">
                      <label data-testid="current-password-heading"> {t("Currentpassword.label")} </label>
                      <input
                      placeholder={t("Entercurrentpassword.label")}
                        type={userData[0].showPassword ? "text" : "password"}
                        value={userData[0].value}
                        error={userData[0].error}
                        maxlength={12}
                        data-testid="current-password-input"
                        onChange={(e) => {
                          let temp = userData;
                          if (!isNull(e.target.value)) {
                            temp[0].value = e.target.value.replace(/[^0-9a-zA-Z]+/ig, "");
                            temp[0].error = false;
                            setOldPasswordError(false)
                            setUserData({ ...temp });
                            // setFlagStatus(false)
                            isDisableStatus()
                          } else {
                            temp[0].value = "";
                            // temp[0].error = true;
                            setUserData({ ...temp });
                            isDisableStatus()

                          }
                        }}
                        className={userData[0].error || oldPasswordError  ? "myAccountCard-ChangePasswordInput-ErrorBorder" : ""}
                      />
                      {userData[0].error || oldPasswordError ?
                        <img src={Warning} className="myAccountCard-ChangePassword-warningIcon" /> : null
                      }

                      <img
                        src={userData[0].showPassword ? Crosseye : Eye}
                        className={`${userData[0].showPassword ? "myAccountCard-ChangePassword-crosseyeIcon" : "myAccountCard-ChangePassword-eyeIcon"}`}
                        onClick={(e) => {
                          let temp = userData;
                          temp[0].showPassword = !temp[0].showPassword;
                          setUserData({ ...temp });
                        }}
                      />
                      {oldPasswordError &&
                        <p className="myAccountCard-ChangePasswordWarning">
                          {t("Passwordinvalid.Pleaseinputthecorrectcurrentpassword.label")}
                        </p>
                      }
                      {/* <img src={Crosseye} className="myAccountCard-ChangePassword-crosseyeIcon" /> */}
                    </div>
                    <div className="myAccountCard-ChangePasswordInput">
                      <label data-testid="new-password-heading">{t("Newpassword.label")}</label>
                      <input
                      placeholder={t("Enternewpassword.label")}
                        type={userData[1].showPassword ? "text" : "password"}
                        value={userData[1].value}
                        maxlength={12}
                        data-testid="new-password-input"
                        onChange={(e) => {
                          let temp = userData;
                          if (!isNull(e.target.value)) {
                            temp[1].value = e.target.value.replace(/[^0-9a-zA-Z]+/ig, "");
                            temp[1].error = false;
                            setUserData({ ...temp });
                            // setFlagStatus(false)
                            isDisableStatus()
                          } else {
                            temp[1].value = "";
                            // temp[1].error = true;
                            // setFlagStatus(true)
                            setUserData({ ...temp });
                            isDisableStatus()
                          }
                        }}
                        className={userData[1].error || userData[2].error || userData[2].match  ? "myAccountCard-ChangePasswordInput-ErrorBorder":""}
                        error={userData[1].error}

                      />
                      {userData[1].error || userData[2].error || userData[2].match ?
                        <img src={Warning} className="myAccountCard-ChangePassword-warningIcon" /> : null
                      }
                      <img
                        src={userData[1].showPassword ? Crosseye : Eye}
                        className={`${userData[1].showPassword ? "myAccountCard-ChangePassword-crosseyeIcon" : "myAccountCard-ChangePassword-eyeIcon"}`}
                        onClick={(e) => {
                          let temp = userData;
                          temp[1].showPassword = !temp[1].showPassword;
                          setUserData({ ...temp });
                        }}
                      />
                      {/* <img src={Eye} className="myAccountCard-ChangePassword-eyeIcon" />
                      <img src={Crosseye} className="myAccountCard-ChangePassword-crosseyeIcon" /> */}
                      {(userData[1].value.length > 0 && !userData[1].error) &&
                        <p className="myAccountCard-ChangePasswordInstruction">
                          {t("Yourpasswordmustbe6-12characterslongandonlycontainEnglishletterornumber.label")}
                        </p>
                      }
                      {userData[1].error &&
                        <p className="myAccountCard-ChangePasswordWarning">
                          {t("Passwordlengthshouldbe6to12characterlong.label")}
                        </p>
                      }
                    </div>
                    <div className="myAccountCard-ChangePasswordInput">
                      <label data-testid="confirm-password-heading"> {t("Confirmnewpassword.label")}</label>
                      <input
                      placeholder={t("Enternewpassword.label")}
                        type={userData[2].showPassword ? "text" : "password"}
                        value={userData[2].value}
                        error={userData[2].error}
                        data-testid="confirm-password-input"
                        maxlength={12}
                        onChange={(e) => {
                          let temp = userData;
                          if (!isNull(e.target.value)) {
                            temp[2].value =e.target.value.replace(/[^0-9a-zA-Z]+/ig, "");
                            temp[2].error = false;
                            temp[2].match=false
                            setUserData({ ...temp });
                            // setFlagStatus(false)
                            isDisableStatus()
                          } else {
                            // setFlagStatus(true)

                            temp[2].value = "";
                            setUserData({ ...temp });
                            isDisableStatus()
                          }
                        }}
                        className={userData[2].error || userData[2].match || userData[1].error ? "myAccountCard-ChangePasswordInput-ErrorBorder":""}
                        // error={userData[2].error}
                      />
                      {userData[2].error || userData[2].match || userData[1].error ?
                        <img src={Warning} className="myAccountCard-ChangePassword-warningIcon" /> : null
                      }
                      <img
                        src={userData[2].showPassword ? Crosseye : Eye}
                        className={`${userData[2].showPassword ? "myAccountCard-ChangePassword-crosseyeIcon" : "myAccountCard-ChangePassword-eyeIcon"}`}
                        onClick={(e) => {
                          let temp = userData;
                          temp[2].showPassword = !temp[2].showPassword;
                          setUserData({ ...temp });
                        }}
                        data-testid="eye-icon"
                      />
                      {/* {(userData[2].value.length > 1 && userData[1].value.length > 1) && userData[2].value !== userData[1].value &&
                        <p className="myAccountCard-ChangePasswordWarning">
                          {t("Passwordsdonotmatch.label")}
                        </p>
                      } */}
                        {(userData[2].match) &&
                        <p className="myAccountCard-ChangePasswordWarning">
                          {t("Passwordsdonotmatch.label")}
                        </p>
                      }
                    </div>
                    {!flagStatus &&
                        <button type="button" className="myAccountCard-changePasswordBtn" onClick={handleShowChangePswrdMdl} data-testid="save-change-button">
                          {t("SaveChanges.label")}
                        </button>
                      }
                      {flagStatus &&
                        <button type="button" disabled={true} className="myAccountCard-changePasswordBtnDsbld" data-testid="save-change-button">
                          {t("SaveChanges.label")}
                        </button>
                      }
                  </div>
                </div>
              </div>
              <div >
                <Modal centered show={showChangePswrdMdl} onHide={handleCloseChangePswrdMdl} className="myAccountChangePasswordModal">
                  <Modal.Body>
                    <img src={ChangePassword} />
                    <h4> {t("Passwordchanged.label")}</h4>
                    <p>{t("You'vesuccessfullyupdatedyourpassword.label")}</p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer-section start from here */}
      <div className='common-display-none-mobile'>
        <Footer />
        </div>
      {/* footer-section start end */}

    </>
  );
};

export { MyAccountChangePassword as MyAccountChangePassword };
