export const userConstants = {
    GET_USER_ALL: 'GET_USER_ALL',
    GET_USER_ALL_SUCCESS: 'GET_USER_ALL_SUCCESS',
    GET_USER_ALL_NODATA: 'GET_USER_ALL_NODATA',
    GET_USER_ALL_FAILURE: 'GET_USER_ALL_FAILURE',

    GET_USER_ALL_CUSTOMER: 'GET_USER_ALL_CUSTOMER',
    GET_USER_ALL_CUSTOMER_SUCCESS: 'GET_USER_ALL_CUSTOMER_SUCCESS',
    GET_USER_ALL_CUSTOMER_NODATA: 'GET_USER_ALL_CUSTOMER_NODATA',
    GET_USER_ALL_CUSTOMER_FAILURE: 'GET_USER_ALL_CUSTOMER_FAILURE',

    GET_CUSTOMER_DETAILS_ALL: 'GET_CUSTOMER_DETAILS_ALL',
    GET_CUSTOMER_DETAILS_ALL_SUCCESS: 'GET_CUSTOMER_DETAILS_ALL_SUCCESS',
    GET_CUSTOMER_DETAILS_ALL_NODATA: 'GET_CUSTOMER_DETAILS_ALL_NODATA',
    GET_CUSTOMER_DETAILS_ALL_FAILURE: 'GET_CUSTOMER_DETAILS_ALL_FAILURE',

    GET_CUSTOMER_ADDRESS_DETAILS_ALL: 'GET_CUSTOMER_ADDRESS_DETAILS_ALL',
    GET_CUSTOMER_ADDRESS_DETAILS_ALL_SUCCESS: 'GET_CUSTOMER_ADDRESS_DETAILS_ALL_SUCCESS',
    GET_CUSTOMER_ADDRESS_DETAILS_ALL_NODATA: 'GET_CUSTOMER_ADDRESS_DETAILS_ALL_NODATA',
    GET_CUSTOMER_ADDRESS_DETAILS_ALL_FAILURE: 'GET_CUSTOMER_ADDRESS_DETAILS_ALL_FAILURE',


    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',    
    LOGIN_DEFAULT: 'USERS_LOGIN_DEFAULT',    

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    // new constant
    FIRST_TIME_LOGIN_REQUEST: 'FIRST_TIME_LOGIN_REQUEST',
    FIRST_TIME_LOGIN_SUCESS: 'FIRST_TIME_LOGIN_SUCESS',
    FIRST_TIME_LOGIN_FAILURE: 'FIRST_TIME_LOGIN_FAILURE',
    FIRST_TIME_LOGIN_REQUEST_EMAIL: 'FIRST_TIME_LOGIN_REQUEST_EMAIL',
    FIRST_TIME_LOGIN_SUCESS_EMAIL: 'FIRST_TIME_LOGIN_SUCESS_EMAIL',
    FIRST_TIME_LOGIN_FAILURE_EMAIL: 'FIRST_TIME_LOGIN_FAILURE_EMAIL',
    OTP_VERIFICATION: 'OTP_VERIFICATION',
    OTP_VERIFICATION_SUCCESS: "OTP_VERIFICATION_SUCCESS",
    OTP_VERIFICATION_FAILURE: "OTP_VERIFICATION_FAILURE",
    //forgot password
    FORGOT_REQUEST: 'FORGOT_REQUEST',
    FORGOT_SUCESS: 'FORGOT_SUCESS',
    FORGOT_SUCESS_EMAIL: 'FORGOT_SUCESS_EMAIL',
    FORGOT_FAILURE: 'FORGOT_FAILURE',
    FORGOT_FAILURE_EMAIL: 'FORGOT_FAILURE_EMAIL',


    CREATE_PASSWORD: 'CREATE_PASSWORD',
    CREATE_PASSWORD_SUCCESS: 'CREATE_PASSWORD_SUCCESS',
    CREATE_PASSWORD_FAILURE: 'CREATE_PASSWORD_FAILURE',

    CHANGE_PASSWORD_USER__ALL:'CHANGE_PASSWORD_USER__ALL',
    CHANGE_PASSWORD_USER__ALL_SUCCESS:'CHANGE_PASSWORD_USER__ALL_SUCCESS',
    CHANGE_PASSWORD_USER__ALL_NODATA:'CHANGE_PASSWORD_USER__ALL_NODATA',
    CHANGE_PASSWORD_USER__ALL_FAILURE:'CHANGE_PASSWORD_USER__ALL_FAILURE',

    UPDATE_USER__ALL:'UPDATE_USER__ALL',
    UPDATE_USER__ALL_SUCCESS:'UPDATE_USER__ALL_SUCCESS',
    UPDATE_USER__ALL_NODATA:'UPDATE_USER__ALL_NODATA',
    UPDATE_USER__ALL_FAILURE:'UPDATE_USER__ALL_FAILURE',
    
};
