import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CalendarGrey from "../../../SVG/CalendarGrey.svg";
import { REACT_APP_BASE_URL } from "../../../constant";
import PaymentImage from "../../../images/money-bills-green.png";
import "../CustomerInfo/CustomerInfo.css";

const ShippingDetails = (props) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(false);
  const [userDetail, setUserDetails] = useState([]);
  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem("user"));
  const userId = localStorage.getItem("userId");
  const [selectedTime, setSelectedTime] = useState('');
  const [customerUserId, setCustomerUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const store = userData ? userData.user.stores[0].storeCode : "";
  const orderdiscardList = useSelector((state) => state.order.orderdiscardList);
  const getOrderItemsByOrderList = useSelector(
    (state) => state.order.getOrderItemsByOrderList
  );
  const orderSubmitDraftList = useSelector(
    (state) => state.order.orderSubmitDraftList
  );
  const orderSubmitConfirmList = useSelector(
    (state) => state.order.orderSubmitConfirmList
  );
  const removeOrderItemsList = useSelector(
    (state) => state.order.removeOrderItemsList
  );

  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 1);

  const timeSlots = [
    "9.00 - 12.00",
    "12.00 - 15.00",
    "15.00 - 18.00",
  ];

  useEffect(() => {
    // debugger;
    let payload = {};
    getUserDetails();
  }, [customerUserId]);

  useEffect(() => {
    if (props.orderDetail.deliveryDate && props.orderDetail.deliveryTime) {
      setDate(moment(props.orderDetail.deliveryDate).format("DD/MM/YYYY"));
      setSelectedTime(props.orderDetail.deliveryTime);
      setCustomerUserId(props.orderDetail.userId)
      const isValid = true
      props.getDeliveryDateAndTime(moment(props.orderDetail.deliveryDate).format("DD/MM/YYYY"), props.orderDetail.deliveryTime, isValid);
    }
  }, [props.orderDetail]);
  const handleChange = (event) => {
    const currentSelectedDate = moment(event).format("DD/MM/YYYY");
    setDate(currentSelectedDate);
    setShowCalendar(false);
    props.getDeliveryDateAndTime(currentSelectedDate, selectedTime, true);
  };
  const handleSelectTime = (event) => {
    setSelectedTime(event.target.value);
    const isValid = true
    props.getDeliveryDateAndTime(date, event.target.value, isValid);

  }

  const getUserDetails = () => {
    // /api/users/getDetails/1
    const userID = customerUserId
    const token = localStorage.getItem("authorization");
    let payload = {};
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.get(
      REACT_APP_BASE_URL + "/api/users/getDetails/" + userID,
      requestOptions
    )
      .then((response) => {
        setUserDetails(response.data);
      })
      .catch((error) => error);
  };

  return (
    <>
      <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 create-order-box-margin p-0">
        <div className="create-order-common-box-shadow mb-4">
          <div className="d-flex flex-row mb-1">
            <img
              src={PaymentImage}
              className="create-order-top-heading-image-payment"
            />
            <h6 className="create-order-box-shadow-top-heading">
              Shipment Details
            </h6>
          </div>
          <div className="createOrderCard">
            <div className="row">
              <div className="col-lg-12">
                {userDetail && userDetail.data && userDetail.data.stores && (
                  <>
                    <label>Delivery From</label>
                    <input
                      type="text"
                      className="createOrderCard-bgSecond createOrderCard-deliveryLoc"
                      value={`${userDetail.data.stores[0].addressTH}, ${userDetail.data.stores[0].subDistrictTH}, ${userDetail.data.stores[0].districtTH}, ${userDetail.data.stores[0].provinceTH} ${userDetail.data.stores[0].postalCodeTH}`}
                    />
                  </>
                )}
              </div>
              <div className="col-lg-6">
                <label>Delivery date</label>
                <div className="createOrderCard-CalanderDesktop">
                  <button
                    className="createOrderCard-deliveryDateBtn"
                    onClick={() => setShowCalendar(true)}
                  >
                    <img src={CalendarGrey} />
                    {date ? date : "Select Delivery Date"}
                  </button>
                  <Calendar
                    minDate={minDate}
                    maxDate={maxDate}
                    className={showCalendar ? "" : "hide"}
                    // value={date}
                    onChange={handleChange}
                    activeStartDate={new Date()}
                  />
                </div>
              </div>
              <div className="col-lg-6 position-relative">
                <label>Delivery time</label>
                <select name="options" id="options" className="createOrderCard-deliveryTimeSelect cursor-pointer"
                  onChange={handleSelectTime}
                >
                  {/* {timeSlots && timeSlots.map((time) => {
                    return (
                      <option selected={time=== selectedTime} value={{time}}>{time}</option>
                    )                    
                  })} */}
                  <option disabled value="">Select delivery time</option>
                  <option selected={selectedTime === "9.00 - 12.00" ? true : false} value="9.00 - 12.00">9.00 - 12.00</option>
                  <option selected={selectedTime === "12.00 - 15.00" ? true : false} value="12.00 - 15.00">12.00 - 15.00</option>
                  <option selected={selectedTime === "15.00 - 18.00" ? true : false} value="15.00 - 18.00">15.00 - 18.00</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingDetails;
