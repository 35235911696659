import { useState } from "react";
function IncDecCounter() {
    let [num, setNum] = useState(0);
    let incNum = () => {
        if (num < 10) {
            setNum(Number(num) + 1);
        }
    };
    let decNum = () => {
        if (num > 0) {
            setNum(num - 1);
        }
    }
    let handleChange = (e) => {
        setNum(e.target.value);
    }
    return (
        <table><tr><td><button className="btn btn-outline-success" type="button" onClick={decNum}>-</button></td>
            <td><input type="text" className="form-control" value={num} onChange={handleChange} size="5" /></td>
            <td><button className="btn btn-outline-success" type="button" onClick={incNum}>+</button>
            </td></tr></table>
    );
}

export { IncDecCounter as IncDecCounter };
