export const specialpriceConstants = {
   
    POST_UPLOAD_PRICE_REQUEST_INFORMATION:'POST_UPLOAD_PRICE_REQUEST_INFORMATION',
    POST_UPLOAD_PRICE_SUCCESS_INFORMATION:'POST_UPLOAD_PRICE_SUCCESS_INFORMATION',
    POST_UPLOAD_PRICE_NODATA_FOUND_INFORMATION:'POST_UPLOAD_PRICE_NODATA_FOUND_INFORMATION',
    POST_UPLOAD_PRICE_FAILURE_INFORMATION:'POST_UPLOAD_PRICE_FAILURE_INFORMATION',

    POST_UPLOAD_SPECIAL_PRICE_REQUEST_INFORMATION:'POST_UPLOAD_SPECIAL_PRICE_REQUEST_INFORMATION',
    POST_UPLOAD_SPECIAL_PRICE_SUCCESS_INFORMATION:'POST_UPLOAD_SPECIAL_PRICE_SUCCESS_INFORMATION',
    POST_UPLOAD_SPECIAL_PRICE_NODATA_FOUND_INFORMATION:'POST_UPLOAD_SPECIAL_PRICE_NODATA_FOUND_INFORMATION',
    POST_UPLOAD_SPECIAL_PRICE_FAILURE_INFORMATION:'POST_UPLOAD_SPECIAL_PRICE_FAILURE_INFORMATION',

};

