import { datadogRum } from '@datadog/browser-rum';
import { REACT_APP_BASE_URL, ENVIRONMENT } from '../constant';


export const datadogMonitoring = () => {
    if(ENVIRONMENT === 'local') return ;

    datadogRum.init({
        applicationId: 'ec8014b2-4e63-4edd-b993-9c96a87d3cbd',
        clientToken: 'pub400fe705d05b4c3fa1deb440cbb6e973',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'bshop-fe',
        env: ENVIRONMENT,
        allowedTracingUrls: [`${REACT_APP_BASE_URL}/api`],
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        version: process.env.SOURCE_COMMIT
    }); 
        datadogRum.startSessionReplayRecording();
}