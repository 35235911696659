import React from "react";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Footer } from "../Footer";
import { userActions } from "../_actions";
import email from "../images/email.png";
import Card from "@mui/joy/Card";
import { Header } from "../Header";
import "./LoginCheckEmailPassword.css";
import "../LoginFirstTime/loginFirstTime.css";
import Loader from "../SharedComponent/Loader/Loader";
import ThankYouForPurchaseModal from "../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal";
import AlertImage from "../images/alert1.png";

const LoginCheckEmailPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const userState = useSelector((state) => state.users);
  const LoginDetails = location.state;
  const search = window.location.search;
  const [mainHeading, setMainHeading] = React.useState("");
  const [subHeading1, setSubHeading1] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);
  const params = new URLSearchParams(search);
  const passwordReset = params.get("resetpassword");
  const resendMail = (e) => {
    e.preventDefault();
    const payload = {
      type: "email",
      email: LoginDetails.email,
    };
    setSubmitted(true);
    setLoading(true);
    dispatch(userActions.forgotPassword("email", payload));
    setMainHeading("ResendEmailSend.label");
    // setSubHeading1("ResendEmailSend2.label");
    setSubHeading1("Pleasecheckyouremailinbox.label");
    setOpenAlert(true);
  };
  const handleAlert = () => {
    setOpenAlert(false);
  };

  React.useEffect(() => {
    setLoading(false);

    //    if(submitted === true && loginType==="email" && userState.firstTimeLoginwithEmail!==null && userState.firstTimeLoginwithEmail.code===200 && userState.firstTimeLoginwithEmail.data!==null){
    //     history.push("/logincheckemailpassword", { otpDetail:userState.firstTimeLoginwithEmail,email: email});
    //    }
  }, [userState.forgotwithEmail]);

  React.useEffect(() => {
    if (passwordReset == "true") {
      localStorage.setItem("resetpassword", "true");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Betagro Check your e-mail</title>
      </Helmet>
      <ThankYouForPurchaseModal
        image={AlertImage}
        cart={true}
        buttonLabel={t("ok.label")}
        mainHeading={mainHeading}
        subHeading1={subHeading1}
        open={openAlert}
        ClickAction={handleAlert}
      />
      {loading && <Loader />}
      <div className="headerFixed">
      <Header />
      </div>
      <div className="logIn-firstTime-outer headerFixed-margin">
        <div className="logIn-firstTime">
          <center>
          <br className="visible-sm"/>
          <br className="visible-sm"/>
          <br className="visible-sm"/>
          <br className="visible-sm"/>
          <br className="visible-sm"/>
          <br className="visible-sm"/>
          <br className="visible-sm"/>
          <br className="visible-sm"/>
            <img src={email} alt="Email Logo" style={{ width: 80 }} />
            <h2>{t("checkEmail.label")}</h2>
            <h6 className="hidden-sm mb-0">{t("emailSent1.label")}</h6>
            <h6 className="visible-sm mb-0 mb-1">{t("emailSent1.label")}</h6>
            <h6 className="hidden-sm mt-0">
              {passwordReset == "true"
                ? t("forgotPasswordCheckmail.label")
                : t("emailSent2.label")}
            </h6>
            <h6 className="visible-sm mt-0">
              {passwordReset == "true"
                ? t("forgotPasswordCheckmail.label")
                : t("emailSent2.label")}
            </h6>
            <br />
            <br className="hidden-sm" />
            <h6>
              {t("Didn'treceiveemail.label")}&nbsp;<span onClick={resendMail} className="resend-text">{t("resend.label")}</span>
            </h6>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
            <br className="visible-sm"/>
          </center>
        </div>
      </div>
      <Footer />
    </>
  );
};

const CheckLogin = withTranslation()(LoginCheckEmailPassword);
export { CheckLogin as LoginCheckEmailPassword };
