import React from "react";
import "./CustomBreadcrumb.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

// import useBreadcrumbs from "use-react-router-breadcrumbs";

const CustomBreadcrumb = ({props}) => {
const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
}
const lancode = localStorage.getItem("lancode");
  return (
    <>
      <div className="customBreadcrumb">
        <Breadcrumb>
        <Breadcrumb.Item href={'/'+props.categorySlug}>{lancode == "en" ?capitalize(props.categoryEN):props.categoryTH}</Breadcrumb.Item>
        <Breadcrumb.Item href={'/'+props.categorySlug+'/'+props.subCategorySlug}>{lancode == "en" ?capitalize(props.subCategoryEN):props.subCategoryTH}</Breadcrumb.Item>
        <Breadcrumb.Item href={'/productdetailscustomer/'+props.articaleCode}>{lancode == "en" ?props.consumerNameEN:props.consumerNameTH}</Breadcrumb.Item>
        </Breadcrumb> 
      </div>
    </>
  );
};

export { CustomBreadcrumb as CustomBreadcrumb };
