import { uploadfileConstants } from '../_constants';
import { customLogout } from '../_helpers/commanFunction';
import { uploadfileService } from '../_services';

export const uploadfile = {

    getuploadfilelistproduct,
    getuploadfilelistprice,
    getuploadfilelistspecialprice,
    getuploadfileliststock,
    getuploadfilelistsale,
    getuploadFileListBanner,
    getuploadFileListMobileBanner

};

function getuploadfilelistproduct(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        uploadfileService.getuploadfilelistproduct(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_FAILURE_INFORMATION, error };

    }
}



function getuploadfilelistsale(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        uploadfileService.getuploadfilelistsale(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SALE_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SALE_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SALE_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SALE_FAILURE_INFORMATION, error };

    }
}

function getuploadFileListBanner(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        uploadfileService.getuploadFileListBanner(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_BANNER_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_BANNER_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_BANNER_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_BANNER_FAILURE_INFORMATION, error };

    }
}
function getuploadFileListMobileBanner(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        uploadfileService.getuploadFileListMobileBanner(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_MOBILE_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_MOBILE_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_MOBILE_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_MOBILE_FAILURE_INFORMATION, error };

    }
}

function getuploadfilelistprice(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        uploadfileService.getuploadfilelistprice(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_PRICE_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_PRICE_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_PRICE_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_PRICE_FAILURE_INFORMATION, error };

    }
}



function getuploadfilelistspecialprice(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        uploadfileService.getuploadfilelistspecialprice(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SPECIAL_PRICE_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SPECIAL_PRICE_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SPECIAL_PRICE_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_SPECIAL_PRICE_FAILURE_INFORMATION, error };

    }
}



function getuploadfileliststock(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        uploadfileService.getuploadfileliststock(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_STOCK_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_STOCK_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_STOCK_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: uploadfileConstants.GET_UPLOAD_FILE_STOCK_FAILURE_INFORMATION, error };

    }
}


