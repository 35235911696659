import React from 'react';
import { connect } from 'react-redux';

import Card from '@mui/joy/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { MDBBtn } from 'mdb-react-ui-kit';
import { IconContext } from "react-icons";
import { AiFillCaretDown } from "react-icons/ai";
import { BiMovie } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdEdit, MdOutlinePayments } from "react-icons/md";
import { Footer } from '../Footer';
import { Header } from '../Header';
import { userActions } from '../_actions';
import saucedge from '../images/saucedge.png';
import smokedpepper from '../images/smokedpepper.png';
import './style.css';


class MyCartCheckOut extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        // this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            isChecked: true,
            paymenttype: "cashondelivery",
            iscash: true,



        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    handleChange(e) {
        if (e.target.type === 'radio') {
            this.setState({ paymenttype: e.target.value });
            if (paymenttype == 'banktransferondelivery') { this.setState({ iscash: false }); }
            else {
                this.setState({ iscash: true });
            }


        }

    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }
    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted, paymenttype, iscash } = this.state;
        return (<center>
            <Header />
            <h4>Checkout</h4>
            <table>
                <tr><td><Card variant="outlined" sx={{ width: 400, backgroundColor: "white", border: "none", }}>
                    <table><tr><td><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}> <BsPerson /></IconContext.Provider>
                        <h4>Personal Info </h4></td><td> <IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}> <MdEdit /></IconContext.Provider>Edit</td></tr></table>
                    <table>
                        <tr><td>Name</td><td>Sarut Viphustein</td></tr>
                        <tr><td>Customer ID</td><td>ABC123456789</td></tr>
                        <tr><td>Phone number</td><td>081-234-5678</td></tr>
                        <tr><td>Shipping Address</td><td>236 U 2 Bangkok 10210</td></tr>
                    </table>
                </Card></td><td>&nbsp;&nbsp;&nbsp;</td><td rowSpan={8}><Card variant="outlined" sx={{ width: 400, backgroundColor: "white", border: "none", height: "550px" }}>
                    <h4>Summary</h4><br></br>
                    <table width="100%">
                        <tr><td align="left"><img src={saucedge} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00 X 1</td></tr>
                        <tr><td colSpan={5}><hr></hr></td></tr>
                        <tr><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00 X 1</td></tr>
                        <tr><td colSpan={5}><hr></hr></td></tr>
                        <tr><td align="left"><img src={saucedge} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00 X 1 <label for="check" className="togButton"><IconContext.Provider value={{ color: "green", className: "global-class-name" }}><AiFillCaretDown /></IconContext.Provider></label></td></tr>
                        <tr><td colSpan={5}><hr></hr></td></tr></table>

                    <input type="checkbox" className="togCheck" id="check" />
                    <div className="togContent">
                        <table width="100%">
                            <tr><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00 X 1</td></tr>
                            <tr><td colSpan={5}><hr></hr></td></tr>
                            <tr><td align="left"><img src={saucedge} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00 X 1</td></tr>
                            <tr><td colSpan={5}><hr></hr></td></tr>
                            <tr><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00 X 1 </td></tr>
                            <tr><td colSpan={5}><hr></hr></td></tr></table>
                    </div>
                    <table>
                        <tr><td style={{ backgroundColor: "#e6fff5" }}>Subtotal</td><td style={{ backgroundColor: "#e6fff5" }}>$ 9,400.00</td></tr>
                        <tr><td style={{ backgroundColor: "#e6fff5" }}>Shipping fee</td><td style={{ backgroundColor: "#e6fff5" }}>$ 0.00</td></tr>
                        <tr><td style={{ backgroundColor: "#e6fff5" }}>Promotion</td><td style={{ backgroundColor: "#e6fff5" }}>-$ 4,400.00</td></tr>
                        <tr><td colSpan={2} style={{ backgroundColor: "#e6fff5" }}><hr></hr></td></tr>
                        <tr><td style={{ backgroundColor: "#e6fff5" }}>Total</td><td style={{ backgroundColor: "#e6fff5" }}>$ 5,400.00</td></tr>
                        <tr><td>&nbsp;&nbsp;&nbsp;</td><td>&nbsp;&nbsp;&nbsp;</td></tr>
                        <tr><td colSpan={2} style={{ alignItems: "center" }}><center><MDBBtn filled rounded color='success' size='lg'>Confirm Order</MDBBtn></center></td></tr>
                    </table>
                </Card>
                    </td></tr>

                <tr><td>&nbsp;&nbsp;&nbsp;</td><td>&nbsp;&nbsp;&nbsp;</td><td></td></tr>
                <tr><td><Card variant="outlined" sx={{ width: 400, backgroundColor: "white", border: "none", }}>
                    <IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}> <BiMovie /></IconContext.Provider>
                    <h4>Shipment Details</h4>
                    <table>
                        <tr><td>Delivery From </td><td></td></tr>
                        <tr><td> Bangkok 10210</td><td></td></tr>
                        <tr><td>Delivery Date</td><td>Delivery Time</td></tr>
                        <tr><td> date picker</td><td> time picker </td></tr>
                    </table>
                </Card></td><td>&nbsp;&nbsp;&nbsp;</td><td></td></tr>
                <tr><td>&nbsp;&nbsp;&nbsp;</td><td>&nbsp;&nbsp;&nbsp;</td><td></td></tr>
                <tr><td><Card variant="outlined" sx={{ width: 400, backgroundColor: "white", border: "none", }}>
                    <IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}> <MdOutlinePayments /></IconContext.Provider>
                    <h4>Payment</h4>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top" value={paymenttype} onChange={this.handleChange}>

                        <FormControlLabel
                            value="cashondelivery"
                            control={<Radio color="success" />}
                            label="Cash on delivery"
                            labelPlacement="End" style={{ border: "1px", borderBlockColor: "green", }}
                        />

                        <FormControlLabel
                            value="banktransferondelivery"
                            control={<Radio color="success" />}
                            label="Bank transfer on delivery "
                            labelPlacement="End"
                        />
                    </RadioGroup>
                </Card></td><td>&nbsp;&nbsp;&nbsp;</td><td></td></tr>
                <tr><td>&nbsp;&nbsp;&nbsp;</td><td>&nbsp;&nbsp;&nbsp;</td><td></td></tr>
                <tr><td>
                    <Card variant="outlined" sx={{ width: 400, backgroundColor: "white", border: "none", }}>

                        <table>
                            <tr><td><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}> <FaFileInvoiceDollar /></IconContext.Provider><h4>Tax Invoice Information</h4></td><td><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}> <MdEdit /></IconContext.Provider>Edit</td></tr>
                            <tr><td>&nbsp;&nbsp;&nbsp;</td><td>&nbsp;&nbsp;&nbsp;</td></tr>
                            <tr><td>Company Name</td><td> Sand Studio & Co.</td></tr>
                            <tr><td>Tax ID</td><td>1-1014-02175-03-02</td></tr>
                            <tr><td>Phone number </td><td>081-234-5678</td></tr>
                            <tr><td>Address</td><td>236 U 2 Bangkok  10210</td></tr>
                        </table>
                    </Card></td><td>&nbsp;&nbsp;&nbsp;</td><td></td></tr>

            </table><Footer /></center>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedMyCartCheckOut = connect(mapState, actionCreators)(MyCartCheckOut);
export { connectedMyCartCheckOut as MyCartCheckOut };
