import { myAccountConstant } from "../_constants";

const initReducers = {  
  orderDetails: null, 
  orderDetailsLoading:false,
  orderDetailsdateTime: new Date().getMilliseconds(),
};

export function myAccount(state = initReducers, action) {
  switch (action.type) {
    case myAccountConstant.GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        orderDetailsLoading: true,
      };
    case myAccountConstant.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.data,
        orderDetailsLoading: false,
      };
    case myAccountConstant.GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        orderDetails: error,
        orderDetailsLoading: false,
        orderDetailsdateTime: new Date().getMilliseconds()
      };

    case myAccountConstant.GET_ORDER_DETAILS_NODATA:
      return {
        ...state,
        orderDetails: null,
        orderDetailsLoading: false,
        orderDetailsdateTime: new Date().getMilliseconds()
      };

    default:
      return state;
  }
}
