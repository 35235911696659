import Carousel from "react-bootstrap/Carousel";
import "./style.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { REACT_APP_BASE_URL } from "../constant";
import {isMobile} from 'react-device-detect';

const HeroBannerSecond = () => {

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const history = useHistory();   
  return (
    <>
      <div className="container-fluid main-container-hero-banner" data-testid="landing-screen-banner-section">
        <div className="row inner-container-hero-banner">
          <div className="col-sm-12">
            <Carousel slide={false} pause={false} className="banner-promotion">
               <Carousel.Item>
              <div className="row">
                  <div className="col-sm-12 col-12 col-sm-12 col-lg-12">
                    <div className="right-side-container" data-testid="banner-section-image-first">
                     {isMobile? <img
                        alt=""
                        src={`${REACT_APP_BASE_URL}/api/download?Promotion_Banner_Mobile-01.png`}
                        className="slider-banner-image"
                      />:<img
                        alt=""
                        src={`${REACT_APP_BASE_URL}/api/download?Promotion_Banner_Desktop-01.png`}
                        className="slider-banner-image"
                      />}
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
              <div className="row">
                  <div className="col-sm-12 col-12 col-sm-12 col-lg-12">
                    <div className="right-side-container"  data-testid="banner-section-image-second">
                    {isMobile ? <img
                        alt=""
                        src={`${REACT_APP_BASE_URL}/api/download?Promotion_Banner_Mobile-02.png`}
                        className="slider-banner-image"
                      /> :<img
                        alt=""
                        src={`${REACT_APP_BASE_URL}/api/download?Promotion_Banner_Desktop-02.png`}
                        className="slider-banner-image"
                      />}
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
              <div className="row">
                  <div className="col-sm-12 col-12 col-sm-12 col-lg-12">
                    <div className="right-side-container"  data-testid="banner-section-image-third">
                    {isMobile ? <img
                        alt=""
                        src={`${REACT_APP_BASE_URL}/api/download?Promotion_Banner_Mobile-03.png`}
                        className="slider-banner-image"
                      />: <img
                        alt=""
                        src={`${REACT_APP_BASE_URL}/api/download?Promotion_Banner_Desktop-03.png`}
                        className="slider-banner-image"
                      />}
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export { HeroBannerSecond as HeroBannerSecond };
