import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { landingPageActions, orderActions, productActions, userActions } from "../_actions";
import Logout from './Assets/SVG/Logout.svg';
import Orders from './Assets/SVG/Orders.svg';
import Password from './Assets/SVG/Password.svg';
import Profile from './Assets/SVG/Profile.svg';
import Wishlist from './Assets/SVG/Wishlist.svg';
import "./Style.css";

const MyAccountMob = (props) => {
  const location = useLocation();
  const history = useHistory();
  const whishlistDetailsInformationList = useSelector((state) => state.product.whishlistDetailsInformationList);
  const newOrderList = useSelector((state) => state.order.newOrderList);
  const activeOrderList = useSelector((state) => state.order.activelistorderList);
  const { t } = useTranslation();
  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem('user'))
  const store = userData ? userData.user.stores[0].storeCode : "";
  const dispatch = useDispatch();
  const [tableDataactivelist, setTableDataactivelist] = React.useState([]);
  const [totalCountWishList, setTotalCountWishList] = React.useState(0);
  const [totalCountactivelist, setTotalCountactivelist] = React.useState(0);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      localStorage.setItem('prevPath', location.pathname)
      history.push("/login");
    }
    let payload = {};
    dispatch(productActions.getWishListInformation("", payload, `?limit=1000000&skip=0`));
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      const payload_new_order = {
        "storeCode": store,
      }
      dispatch(orderActions.getNewOrderList("", payload_new_order, `limit=1000000&skip=0`));
    } else {
      const payload_new_order = {
        "storeCode": store,
        "flags": {
          "product": true
        }
      };
      let queryString = `limit=1000000&skip=0`;
      dispatch(orderActions.activeList("", payload_new_order, queryString));
    }

  }, []);

  React.useEffect(() => {
    if (whishlistDetailsInformationList?.data) {

      if (whishlistDetailsInformationList.data && whishlistDetailsInformationList?.data.totalCount > 0) {
        setTotalCountWishList(whishlistDetailsInformationList?.data.totalCount);

      }
    }
    if (newOrderList?.data && (userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      if (newOrderList.data && newOrderList?.data.result.length > 0) {
        setTableDataactivelist(newOrderList?.data.result);
      }
      setLoading(false);
      setTotalCountactivelist(newOrderList?.data.totalCount);
    } else {
      setTableDataactivelist([]);
      setLoading(false);
      setTotalCountactivelist(0);
    }
    if (activeOrderList?.data && userData?.user?.role !== "Saleshop" && userData?.user?.role !== 'Admin') {
      const activeListItem = activeOrderList?.data.result.filter(item => item.status === "new")
      setTotalCountactivelist(activeListItem.length);
    }
  }, [whishlistDetailsInformationList, newOrderList, activeOrderList]);

  const logout = () => {
    const payloadWithOutLogin = {
      typeCode: "2",
    };
    const payloadWithOutLoginNew = {
      typeCode: "3",
    };
    dispatch(userActions.logout());
    // setIsUserLoggedIn(false);
    localStorage.removeItem("user");
    localStorage.removeItem("authorization");
    localStorage.removeItem("keepMeLoggedIn");
    localStorage.removeItem("phone");
    localStorage.removeItem("password");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("authorization");
    dispatch(
      landingPageActions.getPromotionWithoutLogin(
        "promotion",
        payloadWithOutLogin
      )
    );
    dispatch(
      landingPageActions.getNewItemsDataWithOutLogin(
        "newItem",
        payloadWithOutLoginNew
      )
    );
    history.push("/");
  };
  // const languageText = localStorage.getItem("lancode");
  return (
    <>
      <div className="bg-HeaderMobPages">
        <button onClick={() => history.goBack()}>{t("Back.label")}</button>
        <h4>
          <a>{t("MyAccount.label")}</a>
        </h4>
        <ul>
          <li>
            <Link to="/MyAccountProfile"><img src={Profile} />{t("MyProfile.label")}</Link>
          </li>
          {/*  <li>
            <Link to="/mydashboard"><img src={Dashboard} />{t("MyDashboard.label")}</Link>
          </li> */}
          <li>
            <Link to="/myorder"><img src={Orders} />{t("MyOrders.label")}
              {totalCountactivelist > 0 ? <span>{totalCountactivelist}</span> : null}

            </Link>
          </li>
          <li>
            <Link to="/MyAccountWishlist"><img src={Wishlist} />{t("MyWishlist.label")}
              {totalCountWishList > 0 ? <span>{totalCountWishList}</span> : null}</Link>
          </li>
          <li>
            <Link to="/MyAccountChangePassword"><img src={Password} />{t("ChangePassword.label")}</Link>
          </li>
          <li>
            <Link onClick={logout} style={{ textTransform: "unset" }}><img src={Logout} />{t("Logout.label")}</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export { MyAccountMob as MyAccountMob };

