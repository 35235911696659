import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { useHistory, useLocation } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelOrderModal from '../../ModalScreens/CancelOrderModal/CancelOrderModal';
import { useTranslation, withTranslation } from "react-i18next";
import AlertImage from '../../images/alert1.png';
import { Base64 } from '../../_helpers/commanFunction'
import ThankYouForPurchaseModal from '../../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal';
import ApiProvider from "../../_services/ApiProvider";
import ApiList from "../../_constants/const.api";
import Loading from "../../SharedComponent/Loader/Loader";

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCancell, setOpenCancel] = useState(false);
  const [mainHeading, setMainHeading] = useState("");
  const [subHeading1, setSubHeading1] = useState("");
  const [loadingLoader, setLoadingLoader] = useState(false);
  const history = useHistory();
  const [openAlert, setOpenAlert] = useState(false);
  const [customerSelectErr, setCustomerSelectErr] = useState(false);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const reorder = () => {
    setCustomerSelectErr(false)
    setMainHeading("ReOrder.label")
    setSubHeading1("ReOrder2.label")
    setOpenAlert(true);
    handleClose();
    props.orderUpdate();

  };
  const fulfill = () => {
    const payload = {orderId:props.row.orderId }       
            setLoadingLoader(true) 
          ApiProvider.post(ApiList.API_URL_FOR_ADMIN_REORDER, payload).then((response) => {
            if (response) {        
              history.push("/createOrder/" + Base64.encode(response?.data?.data?.orderId.toString()),{from:6});                    
                setLoadingLoader(false)
            }else{
                setLoadingLoader(false)
            }

        })
    handleClose();
  };
  const handleAlert = () => {
    setOpenAlert(false)
  }
  const cancel = () => {
    setOpenCancel(true)
    handleClose();
  };
  const toCancelConfirm = (rowDetails) => {
    props.orderUpdate(rowDetails, "cancelled")
    setOpenCancel(false)
  }
  const routeToOrderDetailsPage = (items, event) => {
    event.stopPropagation()
    history.push("/createOrder/" + Base64.encode(items.orderId.toString()), {orderDetails:items});
  }

  const style = {
    menu: {
      borderRadius: 12
    },
    menuItem: {
      width: 160,
      boxShadow: 'none',
      color: "#989CA6",
      fontSize: 20
    },
    listStyle: {
      backgroundColor: 'red',
      borderRadius: 0,
      boxShadow: 'none'
    },
    innerDivStyle: {
      borderRadius: 0,
      boxShadow: 'none'
    }
  };

  return (
    <>
    {loadingLoader && (<Loading />)}
      <ThankYouForPurchaseModal
        image={AlertImage}
        cart={true}
        buttonLabel={t("ok.label")}
        mainHeading={mainHeading}
        subHeading1={subHeading1}
        open={openAlert}
        ClickAction={handleAlert}
      />
      <div id="vvvvvv">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{marginTop: 4}}
        >
          <MoreVertIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={style.menu}
        >
          <MenuItem key="1" 
          onClick={fulfill}
          // onClick={(event) => routeToOrderDetailsPage(props.row, event)}
          style={style.menuItem}>
            Reorder
          </MenuItem>

        </Menu>
      </div>
      {openCancell && (
        <CancelOrderModal
          row={props.rowvalue}
          open={openCancell}
          setOpenCancel={setOpenCancel}
          firstLabel="Cancel order?"
          secondLabel="Are you sure you want to cancel this order?"
          firstBtnLabel="No, keep order"
          secondBtnLabel="Yes, cancel order"
          toCancelConfirm={toCancelConfirm}
        />
      )}
    </>
  );
}
