import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from '@mui/material/TableSortLabel';
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ThankYouForPurchaseModal from "../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal";
import Loading from "../SharedComponent/Loader/Loader";
import { landingPageActions } from "../_actions/landing.actions";
import { orderActions } from "../_actions/order.actions";
import { convertToCurrencyFormat } from '../_helpers/commanFunction';
import { REACT_APP_BASE_URL } from "../constant";
import promoBanner from "../images/PromoIconRed.svg";
import AlertImage from "../images/alert1.png";
import blankImage from "../images/empty-image.png";
import emptyTableData from "../images/empty-table-data.png";
import gearImage from "../images/gear.png";
import arrowIcon from '../images/small-chevron-down.png';
import ActionMenu from "./Action";
import UserIconBlack from "./Assets/SVG/UserIconBlack.svg";
import { OrderManagementFilter } from "./FilterComponent/OrderManagementFilter";
import "./OrderManagementByOrder.css";


const TAX_RATE = 0.07;


function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow("Paperclips (Box)", 100, 1.15),
  createRow("Paper (Case)", 10, 45.99),
  createRow("Waste Basket", 2, 17.99),
];


const OrderManagementByOrder = (props) => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };


  window.addEventListener('scroll', toggleVisible);
  // console.log("the value of props .........", props);
  const _exporter = React.createRef();
  const save = (component) => {
    const options = component.current.workbookOptions();
    options.sheets[0].name = "To Be Confirmed";
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    rows.forEach((row) => {
      if (row.type === "data") {
        if (altIdx % 2 !== 0) {
          row.cells.forEach((cell) => {
            cell.background = "#aabbcc";
          });
        }
        altIdx++;
      }
    });
    component.current.save(options);
  };
  const [age, setAge] = React.useState("");

  const lancode = localStorage.getItem("lancode");
  const toconfirmedOrderList = useSelector(
    (state) => state.order.toconfirmedOrderList
  );
  const menuDetails = useSelector((state) => state.landing.menuDetails);
  const [selected, setSelected] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSkip, setPageSkip] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const defaultsSort = { id: "createdAt", orderBy: -1 };
  const [sortDetails, setsetSort] = React.useState(defaultsSort);
  const [orderStatusUpdated, setOrderStatusUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const userData = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const [mainHeading, setMainHeading] = useState("");
  const [subHeading1, setSubHeading1] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [customerSelectErr, setCustomerSelectErr] = useState(false);
  const { t } = useTranslation();
  const [defaultLoad, setDefaultLoad] = useState(false);
  const [attrFilter, setAttrFilter] = useState({});
  const [alloactiondefault, setAlloactiondefault] = useState(0);
  const [filtersFields, setFiltersFields] = useState({});
  const [selecteditemproducts, setSelecteditemproducts] = useState([]);
  const [selecteditemproductssingle, setSelecteditemproductssingle] = useState([]);
  const [selecteditemproductssingleallcation, setSelecteditemproductssingleallcation] = useState([]);
  const [selectedorders, setSelectedorders] = useState([]);
  const [showViewByProduct, setShowViewByProduct] = useState(false);
  const [validationflag, setValidationflag] = useState("false");
  const [categoryvalues, setCategoryvalues] = useState([]);
  const [validationofallocationerr, setValidationofallocationerr] = useState(false);
  const [toggleOrderDetailsOrderitemid, setToggleOrderDetailsOrderitemid] = useState("");
  const [isFocused, setIsFocused] = React.useState(true);
  const [loginType, setLogintype] = React.useState("phone");
  const [selectedQuantity, setSelectedQuantity] = React.useState([]);
  const [updateValue, setUpdateValue] = useState(false);
  const [updateVal, setUpdateVal] = useState(false);
  const [validInputs, setValidInputs] = React.useState([]);
  const [selecteditemproductstoupdate, setSelecteditemproductstoupdate] = React.useState([])
  const [updateEffect, setUpdateEffect] = React.useState(false);
  const [isItemSelectedstate, setIsItemSelectedstate] = useState(false);


  const dispatch = useDispatch();
  const history = useHistory();
  const handleAlert = () => {
    setOpenAlert(false);
  };

  const setUpCartandSpecialPrices = (temp, count) => {
    //  console.log("the value of count and the quantity is .......", count);
    /// console.log("the value of temp and the quantity is .......", temp)    
    let price;
    count = (count) ? parseInt(count) : 1;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    if (isNull(temp.conditionAmount)) {
      if ((Number(temp.quantity1Scale1) <= (count * packSize) || Number(temp.quantity1Scale1) > (count * packSize)) && Number(temp.quantity1Scale1) != 0) {
        price = Number(temp.price1Scale1)
      }
      if (Number(temp.quantity2Scale2) <= (count * packSize) && Number(temp.quantity2Scale2) != 0) {
        price = Number(temp.price2Scale2)
      }
      if (Number(temp.quantity3Scale3) <= (count * packSize) && Number(temp.quantity3Scale3) != 0) {
        price = Number(temp.price3Scale3)
      }
      if (Number(temp.quantity4Scale4) <= (count * packSize) && Number(temp.quantity4Scale4) != 0) {
        price = Number(temp.price4Scale4)
      }
      if (Number(temp.quantity5Scale5) <= (count * packSize) && Number(temp.quantity5Scale5) != 0) {
        price = Number(temp.price5Scale5)
      }
      // if (count < Number(temp.quantity5Scale5)) {
      //   price = Number(temp.price5Scale5)
      // }
    }
    return price;

  }
  const store = userData ? userData.user.stores[0].storeCode : "";
  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      history.push("/login");
    } else {
      if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
        history.push("/");
      }
    }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      const payload = {
        storeCode: store,
        flags: { product: true },
      };
      dispatch(
        orderActions.getToConfirmList("", payload, `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`));
      dispatch(landingPageActions.getAllCatSubAction());

    }


  }, []);

  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      history.push("/login");
    } else {
      if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
        history.push("/");
      }
    }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      const payload = {
        storeCode: store,
        flags: { product: true },
      };
      dispatch(
        orderActions.getToConfirmList("", payload, `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`));
      dispatch(landingPageActions.getAllCatSubAction());

    }


  }, [updateEffect]);

  React.useEffect(() => {
    if (toconfirmedOrderList?.data) {

      if (toconfirmedOrderList.data && toconfirmedOrderList.data.result.length > 0) {

        const newData = toconfirmedOrderList.data.result.map((items) => {
          if (items.orderitems && items.orderitems.length > 0) {

            items.orderitems.map((itemsinorder) => {

              itemsinorder.product.currentUnitPrice = setUpCartandSpecialPrices(itemsinorder.product, itemsinorder.cartQuantity);

              if (isNull(itemsinorder.product.conditionAmount)) {
                itemsinorder.product.currentUnitPrice = setUpCartandSpecialPrices(itemsinorder.product, itemsinorder.cartQuantity);
              } else {
                itemsinorder.product.currentUnitPrice = itemsinorder.product.conditionAmount;
              }
              return itemsinorder;

            });
          }
          return items;
        });
        const data = newData.map((dataItem) => {
          dataItem.createdAt = moment(dataItem.deliveryDate).format('DD/MM/YYYY');
          return dataItem;
        });
        setTableData(data);
      }
      // setTableData(toconfirmedOrderList?.data);
      setLoading(false);
      props.setToConfirmCount(toconfirmedOrderList.data.totalCount);
      setTotalCount(toconfirmedOrderList.data.totalCount);
    } else {
      setTableData([]);
      setLoading(false);
      setTotalCount(0);
    }
    if (menuDetails?.data) {
      setCategoryvalues(menuDetails?.data);
      setLoading(false);

    } else {
      setCategoryvalues([]);
      setLoading(false);

    }


  }, [toconfirmedOrderList, menuDetails, updateEffect]);








  const totalPages =
    toconfirmedOrderList?.data && toconfirmedOrderList?.data.totalCount
      ? Math.ceil(
        parseInt(toconfirmedOrderList?.data.totalCount) /
        parseInt(rowsPerPage)
      )
      : null;

  const handleChangeRowsPerPage = (event) => {
    setLoading(true)
    const payload = {
      "storeCode": store,
      flags: { product: true }
    }
    let merged = { ...payload, ...attrFilter, ...filtersFields };
    //setRowsPerPage(parseInt(event.target.value));
    dispatch(
      orderActions.getToConfirmList(
        "",
        merged,
        `limit=${parseInt(event.target.value)}&skip=${page}&sort=${encodeURIComponent(
          JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
        )}`
      )
    );
    setLoading(false)
    //eachtimeupdate();
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };


  const toconfirmsingle = (orderDetails, existingupdatedallocationarray) => {
    // console.log("teh value of the order details .........", orderDetails);    
    setLoading(true);
    const dataItems = existingupdatedallocationarray.orderitems;

    const orderItems = dataItems.reduce((acc, curr) => {
      const existingObject = acc.find((obj) => obj.orderItemId === curr.orderItemId);
      if (!existingObject) {
        acc.push({ orderItemId: curr.orderItemId, allocatedQtd: parseFloat(curr.allocatedQtd), articaleCode: curr.product.articaleCode });
      }
      return acc;
    }, []);
    orderItems.forEach(item => {
      if (item.allocatedQtd === null || item.allocatedQtd === undefined || isNaN(item.allocatedQtd)) {
        setOpenAlert(true);
        setMainHeading("Missing fields!");
        setSubHeading1("One of the required field is empty. Please check your input.");
        setLoading(false)
      } else {
        const payload2 = {
          storeCode: store,
          orderItems: orderItems
        }
        // console.log("tttt", payload2)
        dispatch(orderActions.updateItemStatus("payload", payload2));
        /* setMainHeading("ConfirmOrder.label")
        setSubHeading1("ConfirmOrder2.label")
        setOpenAlert(true); */

        setLoading(false)
        setUpdateEffect(!updateEffect)
        setSelectedQuantity([]);
        updateorder();
      }
    })
    // console.log('uuuee', orderItems);



    // if (orderDetails.length > 0) {
    //   let orderItems = orderDetails;
    //   setLoading(true)
    //   const payload = {
    //     orderItems
    //   }
    //   console.log("the payload to update is .......", payload)
    //   dispatch(orderActions.updateItemStatus("payload", payload));
    //   setLoading(false)
    //   setSelectedQuantity([]);
    //   setUpdateEffect(!updateEffect)
    //   updateorder();

    // }
    // else {
    //   setLoading(true);
    //   const dataItems = existingupdatedallocationarray.orderitems;
    //   // for (let i = 0; i < existingupdatedallocationarray.orderitems.length; i++) {
    //   //   setSelectedQuantity(prevSelectedData => {
    //   //     const index = prevSelectedData.findIndex(item => item.orderItemId === existingupdatedallocationarray.orderitems[i].orderItemId);
    //   //     if (index === -1) {
    //   //       return [...prevSelectedData, {
    //   //         "orderItemId": existingupdatedallocationarray.orderitems[i].orderItemId,
    //   //         "allocatedQtd": existingupdatedallocationarray.orderitems[i].allocatedQtd
    //   //       }];
    //   //     }
    //   //     else {
    //   //       const updatedData = [...prevSelectedData]; updatedData[index] = {
    //   //         "orderItemId": existingupdatedallocationarray.orderitems[i].orderItemId,
    //   //         "allocatedQtd": existingupdatedallocationarray.orderitems[i].allocatedQtd
    //   //       };
    //   //       return updatedData;
    //   //     }
    //   //   });
    //   // }
    //   // const allocatedQtys = orderItems.map((orderItem) => orderItem.allocatedQtd);
    //   const orderItems = dataItems.reduce((acc, curr) => {
    //   const existingObject = acc.find((obj) => obj.orderItemId === curr.orderItemId);
    //   if (!existingObject) {
    //     acc.push({ orderItemId: curr.orderItemId, allocatedQtd: parseFloat(curr.allocatedQtd) });
    //   }
    //   return acc;
    // }, []);
    // console.log('uuuee', orderItems);
    //   const payload2 = {
    //     orderItems
    //   }
    //   console.log("the payload to update is .......", payload2)
    //   dispatch(orderActions.updateItemStatus("payload", payload2));
    //   /* setMainHeading("ConfirmOrder.label")
    //   setSubHeading1("ConfirmOrder2.label")
    //   setOpenAlert(true); */
    //   setLoading(false)
    //   setUpdateEffect(!updateEffect)
    //   setSelectedQuantity([]);
    //   updateorder();
    // };
  }

  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };
  const handleChangePage = (event, newPage) => {
    setLoading(true)
    let pages = (newPage - 1) * rowsPerPage
    const payload = {
      "storeCode": store,
      flags: { product: true }
    }
    let merged = { ...payload, ...attrFilter, ...filtersFields };
    setPage(newPage);
    setCurrentPage(newPage);
    dispatch(
      orderActions.getToConfirmList(
        "",
        merged,
        `limit=${rowsPerPage}&skip=${pages}&sort=${encodeURIComponent(
          JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
        )}`
      )
    );
    setPageSkip(pages)
    // eachtimeupdate();
    setLoading(false)
  };
  // console.log("vvvv", sortDetails);

  const toggleViewByProduct = () => {
    setShowViewByProduct(true);
    // setViewByOrderActive(false)
    dispatch(orderActions.toggleComponent(showViewByProduct));
    // alert("toggle product");
  };

  const toggleViewByOrder = () => {
    setShowViewByProduct(false);
    // setViewByOrderActive(true)
    dispatch(orderActions.toggleComponent(showViewByProduct));
  };

  const handleConfirmOrder = () => {
    // debugger;
    setLoading(true)
    selectedQuantity.forEach(item => {
      if (item.allocatedQtd === null || item.allocatedQtd === undefined) {
        setOpenAlert(true);
        setMainHeading("Missing fields!");
        setSubHeading1("One of the required field is empty. Please check your input.");
        setLoading(false)
      } else {
        let payload = {
          storeCode: store,
          orderItems: selectedQuantity
        }
        dispatch(orderActions.updateItemStatus("payload", payload));
        // setMainHeading("ConfirmOrder.label")
        // setSubHeading1("ConfirmOrder2.label")
        // setOpenAlert(true);
        setLoading(false)
        setSelectedQuantity([]);
        setUpdateEffect(!updateEffect)
        updateorder();
      }
    })
  }

  // const handletoconfirall = (event) => {
  //   //debugger;
  //   let valiFlag;
  //   console.log("the allocation arrya is ..........", selectedQuantity);
  //   console.log("the  seleted orders ...............", selectedorders);
  //   console.log("the  select chcek box products with ida and quantity is ...................", selecteditemproducts)

  //   if (selectedorders) {
  //     // setCustomerSelectErr(false)
  //     const orignalNull = selectedorders.filter(items => items.allocatedQtd === null)
  //     console.log('vvvv', orignalNull)
  //     if (orignalNull.length === 0) {
  //       let filterselectedproduct = selectedorders.filter(items22 => items22.allocatedQtd >= 0);
  //       console.log("the value of the filtered array is ......", filterselectedproduct);
  //       if (filterselectedproduct.length === selectedorders.length) {
  //         valiFlag = "true";
  //       }
  //     }
  //     if (orignalNull.length === selectedQuantity.length) {
  //       orignalNull.map(item2 => {
  //         let filterArray = selectedQuantity.filter(items22 => items22.orderItemId === item2.orderItemId)
  //         if (filterArray && filterArray[0].allocatedQtd >= 0) {
  //           valiFlag = "true"
  //           setValidationflag("true");
  //           console.log("match is done ................................");
  //           console.log("the flag is ......", valiFlag);

  //         } else {
  //           valiFlag = "false"
  //           setValidationflag("false");
  //           console.log("original item is not in select array");
  //         }
  //       })

  //     } else {


  //       valiFlag = "false"
  //       setValidationflag("false");
  //       console.log("both length is not equal");
  //     }

  //   }


  //   /*  if (orderstobevalidated.length > 0) {
  //      console.log("the slected orders are.......", orderstobevalidated);
  //      for (let i = 0; i < orderstobevalidated.length; i++) {
  //        if (orderstobevalidated[i].orderitems.length > 0) {
  //          for (let j = 0; j < orderstobevalidated[i].orderitems.length; j++) {
  //            if (orderstobevalidated[i].orderitems[j].allocatedQtd >= 0) {
  //              valiFlag="true";
  //              setValidationflag("true");
  //              console.log("i am here in validation true ...............",valiFlag);
  //            }
  //            else {
  //              valiFlag="false";
  //              setValidationflag("false");
  //            }
  //          }
  //        }
  //      }

  //    }
  //    if (valiFlag === "false") {
  //      console.log("the values of the arrays from the check box click ........... ", orderstobevalidated);
  //      console.log("the values of the arrays allocation array ........... ", selectedQuantity);
  //      for (let j = 0; j < orderstobevalidated.length; j++) {
  //        if (selectedQuantity.length > 0) {
  //          if ((orderstobevalidated[j].orderItemId === selectedQuantity[j].orderItemId) && (selectedQuantity[j].allocatedQtd >= 0)) {
  //            console.log("making validation right here ............................");
  //            valiFlag ="true";
  //            setValidationflag("true");
  //            console.log("validation flag is now .....", valiFlag);
  //          }
  //          else {
  //            valiFlag ="false";
  //            setValidationflag("false");
  //          }
  //        }
  //      }

  //    } */
  //   if (valiFlag === "false") {
  //     // setLoading(false);
  //     setMainHeading("MissingFields.label");
  //     setSubHeading1("MissingFields2.label");
  //     setOpenAlert(true);
  //   }
  //   if (valiFlag === "true") {
  //     // setLoading(true)

  //     let payload;
  //     if (selectedQuantity.length > 0) {
  //       let orderItems = selectedQuantity;
  //       payload = {
  //         orderItems
  //       }
  //     }
  //     else {
  //       let orderItems = selecteditemproducts;
  //       payload = {
  //         orderItems
  //       }
  //     }
  //     console.log("the payload to update is .......", payload)
  //     dispatch(orderActions.updateItemStatus("payload", payload));
  //     setMainHeading("ConfirmOrder.label")
  //     setSubHeading1("ConfirmOrder2.label")
  //     // setLoading(false);
  //     setOpenAlert(true);
  //     updateorder();

  //   }
  // }





  const filterToConfirmList = (selectedItem) => {

    let payload = {
      storeCode: store,
      flags: { product: true }
    }
    if (selectedItem?.channelDefaultValue) {
      payload.userChannel = selectedItem?.channelDefaultValue
    }
    if (selectedItem?.deliveryTime) {
      payload.deliveryTime = selectedItem?.deliveryTime
    }
    if (selectedItem?.deliveryDate?.startDate) {
      payload.deliveryDate = {
        from: moment(selectedItem?.deliveryDate?.startDate).format("YYYY-MM-DD"),
        to: moment(selectedItem?.deliveryDate?.endDate).format("YYYY-MM-DD")
      }
    }
    if (selectedItem?.orderDate?.startDate) {
      payload.createdAt = {
        from: moment(selectedItem?.orderDate?.startDate).format("YYYY-MM-DD"),
        to: moment(selectedItem?.orderDate?.endDate).format("YYYY-MM-DD")
      }

    }
    if (selectedItem?.categorySlug) {
      const categorySlugs = selectedItem.categorySlug[0].map(item => item.categorySlug);
      payload.categorySlug = categorySlugs
    }
    if (selectedItem?.subCategorySlug) {
      const subCategorySlug = selectedItem?.subCategorySlug.map(item => item.key);
      payload.subCategorySlug = subCategorySlug
    }
    setLoading(true)
    setFiltersFields(payload)
    let merged = { ...payload, ...attrFilter };
    dispatch(
      orderActions.getToConfirmList(
        "",
        merged,
        `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(
          JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
        )}`
      )
    );
    //eachtimeupdate();
    setLoading(false)


  }

  const updateorder = () => {
    const payload = {
      storeCode: store,
      flags: { product: true },
    };
    dispatch(
      orderActions.getToConfirmList(
        "",
        payload,
        `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(
          JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
        )}`
      )
    );
    //eachtimeupdate();
    props.updateorderbadges();


  }

  const searchItemByAttr = (query) => {
    let payload = {
      storeCode: store,
      flags: { product: true },
      ...query
    };
    /*  let payload = query
     payload.storeCode = store  */
    let merged = { ...payload, ...filtersFields };
    dispatch(
      orderActions.getToConfirmList(
        "",
        merged,
        `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(
          JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
        )}`
      )
    );
    //eachtimeupdate();
    setAttrFilter(payload)
  }

  //console.log("Category Data is ..........................", categoryvalues);

  const invoiceSubtotal = subtotal(rows);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;


  const routeToProductDetailsPage = (items) => {
    history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
  }

  //   const handleSelectAllClick = (event) => {
  //     if (event.target.checked) {
  //       setIsItemSelectedstate(true)
  //         let select = tableData.map(i => {
  //           return i.orderitems.map(items => {
  //             return {orderItemId: items.orderItemId, allocatedQtd: items.allocatedQtd, articaleCode: items.product.articaleCode}
  //           })          
  //         });
  //         // console.log(orderedList, selected)
  //         const flattenedArray = select.flat();
  //         setSelected([...selected, ...flattenedArray])

  //         setSelectedQuantity([...selectedQuantity, ...flattenedArray])
  //         return;
  //     } else {
  //       setIsItemSelectedstate(false)
  //       debugger
  //       let select = tableData.map(i => {
  //         return i.orderitems.map(items => {
  //           return items.orderItemId
  //         })        
  //       });
  //       const flattenedArray = select.flat();
  //       let filtereddata = selected.filter(i => !flattenedArray.includes(i.orderItemId));
  //       setSelected(filtereddata)
  //       setSelectedQuantity(filtereddata)
  //     }

  // };

  const handleClick = (event, ordetails, forselect = false) => {

    const isChecked = (!forselect) ? event.target.checked : true;

    //console.log("the event fired is ........................", event);
    //console.log("the item ids ............................", ordetails.orderitems);

    let orderlineitemsarray = ordetails.orderitems;

    // setSelectedorders(prevSelectedData => {
    //   const index = prevSelectedData.findIndex(item => item.orderId === ordetails.orderId);
    //   if (index === -1) {
    //     return [...prevSelectedData, ordetails];
    //   }
    //   else {
    //     const updatedData = [...prevSelectedData]; updatedData[index] = ordetails
    //     return updatedData;
    //   }
    // });

    // setSelecteditemproducts

    for (let i = 0; i < orderlineitemsarray.length; i++) {

      if (!isChecked) {
        setSelectedQuantity(prevSelectedData => prevSelectedData.filter(item => item.orderItemId !== orderlineitemsarray[i].orderItemId));
        setIsItemSelectedstate(false)
        setSelected(prevSelectedData => prevSelectedData.filter(item => item.orderItemId !== orderlineitemsarray[i].orderItemId));
      } else {
        // 
        // setSelected(prevSelectedData => {
        //   const index = prevSelectedData.findIndex(item => item.orderItemId === orderlineitemsarray[i].orderItemId);
        //   if (index === -1) {
        //     return [...prevSelectedData, {
        //       "orderItemId": orderlineitemsarray[i].orderItemId,
        //       "allocatedQtd": orderlineitemsarray[i].allocatedQtd,
        //       "articaleCode": orderlineitemsarray[i].product.articaleCode
        //     }];
        //   }
        //   else {
        //     const updatedData = [...prevSelectedData]; updatedData[index] = {
        //       "orderItemId": orderlineitemsarray[i].orderItemId,
        //       "allocatedQtd": orderlineitemsarray[i].allocatedQtd,
        //       "articaleCode": orderlineitemsarray[i].product.articaleCode
        //     };
        //     return updatedData;
        //   }
        // });
        // else {
        //   setSelected(selected.filter(item => item.orderItemId !== orderItemId));          
        // }
        // 
        setSelectedQuantity(prevSelectedData => {
          const index = prevSelectedData.findIndex(item => item.orderItemId === orderlineitemsarray[i].orderItemId);
          if (index === -1) {
            return [...prevSelectedData, {
              "orderItemId": orderlineitemsarray[i].orderItemId,
              "allocatedQtd": orderlineitemsarray[i].allocatedQtd,
              "articaleCode": orderlineitemsarray[i].product.articaleCode
            }];
          }
          else {
            const updatedData = [...prevSelectedData]; updatedData[index] = {
              "orderItemId": orderlineitemsarray[i].orderItemId,
              "allocatedQtd": orderlineitemsarray[i].allocatedQtd,
              "articaleCode": orderlineitemsarray[i].product.articaleCode
            };
            return updatedData;
          }
        });
      }

    }

  };

  // console.log("sss", selecteditemproducts);

  const handleChangeinput = (event, orderItemId, index, itemsinorder, index1) => {

    let temp2 = tableData;

    const allocatedQtd = event.target.value;

    const allocatedQtd2 = (event.target.value) ? event.target.value : temp2[index1].orderitems[index].cartQuantity;


    itemsinorder.allocatedQtd = allocatedQtd;
    let temp1 = itemsinorder;

    let price = temp1.product.currentUnitPrice;

    let packSize = 1;
    if (temp2[index1].orderitems[index].product.packSize) {
      packSize = parseFloat(temp2[index1].orderitems[index].product.packSize);
    }

    price = Number(temp2[index1].orderitems[index].product.price1Scale1);
    if (isNull(temp1.product.conditionAmount)) {
      if (Number(temp2[index1].orderitems[index].product.quantity1Scale1) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2[index1].orderitems[index].product.quantity1Scale1) != 0) {
        price = Number(temp2[index1].orderitems[index].product.price1Scale1)
      }
      if (Number(temp2[index1].orderitems[index].product.quantity2Scale2) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2[index1].orderitems[index].product.quantity2Scale2) != 0) {
        price = Number(temp2[index1].orderitems[index].product.price2Scale2)
      }
      if (Number(temp2[index1].orderitems[index].product.quantity3Scale3) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2[index1].orderitems[index].product.quantity3Scale3) != 0) {
        price = Number(temp2[index1].orderitems[index].product.price3Scale3)
      }
      if (Number(temp2[index1].orderitems[index].product.quantity4Scale4) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2[index1].orderitems[index].product.quantity4Scale4) != 0) {
        price = Number(temp2[index1].orderitems[index].product.price4Scale4)
      }
      if (Number(temp2[index1].orderitems[index].product.quantity5Scale5) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2[index1].orderitems[index].product.quantity5Scale5) != 0) {
        price = Number(temp2[index1].orderitems[index].product.price5Scale5)
      }
      // if (allocatedQtd < Number(temp2[index1].orderitems[index].product.quantity5Scale5)) {
      //   price = Number(temp2[index1].orderitems[index].product.price5Scale5)
      // }

      temp1.product.currentUnitPrice = price
      temp2[index1].orderitems[index].product.currentUnitPrice = price

      // console.table('kkkk', temp2[index1].orderitems[index].product, packSize, allocatedQtd2, price, allocatedQtd);
      // console.log('kkkk', temp2[index1].orderitems[index].product);

      temp1.totalPrice = allocatedQtd == 0 || allocatedQtd == null || allocatedQtd == undefined ? 0 : (price * packSize) * parseFloat(allocatedQtd2);
      temp2[index1].orderitems[index].totalPrice = allocatedQtd == 0 || allocatedQtd == null || allocatedQtd == undefined ? 0 : (price * packSize) * parseFloat(allocatedQtd2);


    }
    else if ((temp1.product.conditionAmount) > 0) {
      temp2[index1].orderitems[index].product.currentUnitPrice = temp1.product.conditionAmount
      temp2[index1].orderitems[index].totalPrice = allocatedQtd == 0 || allocatedQtd == null || allocatedQtd == undefined ? 0 : (temp1.product.conditionAmount * packSize) * parseFloat(allocatedQtd);

    }
    // console.log('ttt', temp2[index1].orderitems[index], temp1)
    setTableData(temp2);
    let articaleCode = temp2[index1].orderitems[index].product.articaleCode;
    // setSelecteditemproducts
    setSelectedQuantity(prevSelectedData => {
      const index = prevSelectedData.findIndex(item => item.orderItemId === orderItemId);
      if (index === -1) {
        return [...prevSelectedData, { orderItemId, allocatedQtd, articaleCode }];
      } else {
        const updatedData = [...prevSelectedData];
        updatedData[index] = { orderItemId, allocatedQtd, articaleCode };
        return updatedData;
      }
    });

    // setValidInputs(prevValidInputs => {
    //   const newValidInputs = { ...prevValidInputs };
    //   newValidInputs[orderItemId] = !!allocatedQtd;
    //   return newValidInputs;
    // });

  };

  // console.log('spsp', selectedQuantity);

  const updateProductValue = ( /* itemsinorder.product */) => {
    setUpdateVal(!updateVal)
    // props.updatedProductsValue(products)
  }

  const createSortHandler = (property) => () => {
    let orderby = 1;
    if (property === sortDetails.id) {
      orderby = (sortDetails.orderBy === -1) ? 1 : -1
    }
    setsetSort({ id: property, orderBy: orderby })
    const payload = {
      storeCode: store,
      flags: { product: true },
    };
    dispatch(
      orderActions.getToConfirmList("", payload, `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [property]: orderby }))}`));

  };

  const updateOrderList = () => {
    setUpdateEffect(!updateEffect);
  }

  const checkDecimal = (num) => {
    if (Number(num) % 1 == 0) {
      return num
    } else {
      return Number(num).toFixed(2)
    }
  }
  return (
    <div>
      <ThankYouForPurchaseModal
        image={AlertImage}
        cart={false}
        byOrderStatus={true}
        buttonLabel={t("ok.label")}
        mainHeading={mainHeading}
        subHeading1={subHeading1}
        open={openAlert}
        ClickAction={handleAlert}
      />
      {loading && <Loading />}

      <OrderManagementFilter
        filterToConfirmList={filterToConfirmList}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        searchItemByAttr={searchItemByAttr}
        toggleViewByProduct={toggleViewByProduct}
        toggleViewByOrder={toggleViewByOrder}
      />

      <div className="row">
        <div className="col-sm-8 col-md-8">
          <div className="new-order-list-table-main-container">
            <div className="table-order-counts">
              <h6 className="new-order-list-table-order-counts">
                {totalCount === 0 || !totalCount || totalCount === 1 ? totalCount + " Order" : totalCount + " Orders"}
              </h6>
              {selectedQuantity && selectedQuantity.length > 0 && (
                <h6 className="table-selected-counts">({selectedQuantity.length} selected)</h6>
              )
              }

            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-4">
          {selectedQuantity.length > 0 ? <button
            className="to-confirm-button-main-container"
            onClick={(event) =>
              handleConfirmOrder(event)}
          >
            <span className="to-confirm-button-heading" >Confirm Order</span>
          </button> :
            <button
              className="to-full-button-main-container-deactivated" title=" Select Orders to Activate To Confirm Button "
            >
              <span className="to-full-button-heading-deactivated" >Confirm Order </span>
            </button>
          }

        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 to-comfirm-table-main-container">
          {/* table will come */}

          <TableContainer component={Paper}>
            <Table
              sx={{ width: "100%", height: "100%" }}
              aria-label="spanning table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" colSpan={1} sx={{ borderBottom: "1px solid rgb(224, 224, 224)" }}>
                    <Checkbox
                      // checked={isItemSelectedstate} 
                      color="primary"
                    // onClick={(event) => handleSelectAllClick(event)}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "1px solid rgb(224, 224, 224)" }} colSpan={3}>
                    <div className="odmProduct-ProductDetails-desc">
                      {sortDetails.id === 'createdAt' ?
                        <TableSortLabel
                          active={true}
                          direction={sortDetails.orderBy === -1 ? 'desc' : 'asc'}
                          onClick={createSortHandler(sortDetails.id)}
                        >
                          <h3>Order No.</h3>
                        </TableSortLabel> : <h3 onClick={createSortHandler('createdAt')}>Order No.</h3>}
                    </div>
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "1px solid rgb(224, 224, 224)" }} colSpan={3}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h3>Description</h3>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h3>On hand</h3>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h3>On order</h3>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h3>Remaining</h3>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h3>Ordered</h3>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h3>Unit</h3>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h3>Qty</h3>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h3>Unit Price</h3>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      {sortDetails.id === 'totalOrderPrice' ?
                        <TableSortLabel
                          active={true}
                          direction={sortDetails.orderBy === -1 ? 'desc' : 'asc'}
                          onClick={createSortHandler(sortDetails.id)}
                        >
                          <h3>Total</h3>
                        </TableSortLabel> : <h3 onClick={createSortHandler('totalOrderPrice')}>Total</h3>}
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              {
                tableData && tableData.length > 0 && tableData.map((items, index1) => {

                  return <>
                    <TableHead>
                      <TableRow key={index1}>
                        <TableCell align="left" colSpan={1} >
                          <Checkbox align="left" color="primary"
                            // checked={isItemSelectedstate == true ? isItemSelected : undefined}  
                            // checked={isItemSelected}                          
                            onClick={(event) =>
                              handleClick(event, items)}
                          />
                        </TableCell>
                        <TableCell align="left" colSpan={13}>
                          <div className="odmProduct-UserDetails">
                            <h4 className="odmProduct-UserDetails-userInfo cursor-pointer" >
                              {items.usercustomercode.trim() == "" ? (
                                <img
                                  src={gearImage}
                                  className="table-created-employer"
                                />
                              ) : (
                                <img src={UserIconBlack} />
                              )}
                              <span>&nbsp;&nbsp;&nbsp;{items.userfirstnameen}{" "}
                                {items.userlastnameen}</span>
                              <p>&nbsp;&nbsp;&nbsp;Order No. {items.orderNo}</p>
                            </h4>
                            <div className="odmProduct-UserDetails-userInfoDesc">
                              <h6><span>{items.userfirstnameen}{" "}
                                {items.userlastnameen}</span></h6>
                              <h6>Channel: {items.userchannel}</h6>
                              <h6>Created by <img src={UserIconBlack} />{items.usercustomercode.trim() == "" ? <span>Sales Admin</span> : <span>Customer</span>}</h6>
                              <h6>Creation Date {items.createdAt}</h6>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="right" colSpan={1}>
                          {" "}
                          <ActionMenu
                            rowvalue={items}
                            toconfirmsingle={toconfirmsingle} updateOrderList={updateOrderList} selecteditemproducts={selecteditemproducts} updateorder={updateorder} selectedQuantity={selectedQuantity}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items.orderitems &&
                        items.orderitems.map((itemsinorder, index) => (
                          <TableRow>
                            <TableCell align="left" colSpan={1}>
                            </TableCell>
                            <TableCell align="left" colSpan={3}>
                              <div className="odmProduct-ProductDetails" style={{ width: '230px' }}>
                                <div className="row d-flex align-items-center">
                                  <div className="col-lg-4 col-md-4 pr-0">
                                    <div className="odmProduct-ProductDetails-Img">
                                      <div className="odmProduct-ProductDetails-Img-badge">
                                        {itemsinorder.product &&
                                          itemsinorder.product.typeCode === "2" && (
                                            <img
                                              alt=""
                                              src={promoBanner}
                                              style={{ width: 30 }}
                                            />
                                          )}
                                        {itemsinorder.product &&
                                          itemsinorder.product.typeCode === "3" && (
                                            <div className="promo-img-badge1">
                                              <img
                                                alt=""
                                                src="../NewItemYellow.svg"
                                                style={{ width: 20 }}
                                              />
                                            </div>
                                          )}
                                      </div>
                                      <img title=" Click to Product Details" onClick={() => routeToProductDetailsPage(itemsinorder)}
                                        src={
                                          (itemsinorder.product.picture.split(",")[0] && itemsinorder.product.picture.split(",")[0] != undefined && itemsinorder.product.picture.split(",")[0] != '') ?
                                            (REACT_APP_BASE_URL +
                                              "/api/download?" +
                                              itemsinorder.product.picture.split(",")[0]
                                              ? REACT_APP_BASE_URL +
                                              "/api/download?" +
                                              itemsinorder.product.picture.split(",")[0]
                                              : blankImage)
                                            : blankImage
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-8 col-md-8" colSpan={3}>
                                    {lancode === "en" && (
                                      <h5 title={itemsinorder.product.consumerNameEN}
                                        onClick={() => routeToProductDetailsPage(itemsinorder)}>
                                        {/* {itemsinorder.product.consumerNameEN &&
                                        itemsinorder.product.consumerNameEN.length > 31
                                        ? itemsinorder.product.consumerNameEN.substring(0, 31) +
                                        "..."
                                        : itemsinorder.product.consumerNameEN} */}
                                        {itemsinorder.product.consumerNameEN}
                                      </h5>
                                    )}

                                    {lancode === "th" && (
                                      <h5 title=" Click to Product Details"
                                        onClick={() => routeToProductDetailsPage(itemsinorder)}>
                                        {/* {itemsinorder.product.consumerNameTH &&
                                        itemsinorder.product.consumerNameTH.length > 31
                                        ? itemsinorder.product.consumerNameTH.substring(0, 31) +
                                        "..."
                                        : itemsinorder.product.consumerNameTH} */}
                                        {itemsinorder.product.consumerNameTH}
                                      </h5>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="left" colSpan={3}>
                              <div variant='body1' className="auto-complete-order-by-product-table product-lbl-name-tax-modal" >
                                <>
                                  {itemsinorder.product.selectValues.length > 1 ?
                                    <select style={{ fontSize: "15px", }}
                                      value={itemsinorder.product.articaleCode}
                                      onChange={(e) => {
                                        let temp = itemsinorder.product.selectValues
                                        let selectedItem = itemsinorder.product.selectValues.find(f => f.articaleCode === e.target.value);
                                        let temp2 = itemsinorder
                                        temp2.product.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                        temp2.product.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                        temp2.product.picture = selectedItem.picture;
                                        temp2.product.articaleCode = selectedItem.articaleCode;
                                        // temp2.product.price1Scale1 = selectedItem.price1Scale1;
                                        temp2.product.packSizeEN = selectedItem.packSizeEN;
                                        temp2.product.packSize = selectedItem.packSize;
                                        temp2.product.stock = selectedItem.stock;
                                        // 
                                        // temp2.product.picture = selectedItem.picture;
                                        temp2.product.price1Scale1 = selectedItem.price1Scale1;
                                        temp2.product.price2Scale2 = selectedItem.price2Scale2;
                                        temp2.product.price3Scale3 = selectedItem.price3Scale3;
                                        temp2.product.price4Scale4 = selectedItem.price4Scale4;
                                        temp2.product.price5Scale5 = selectedItem.price5Scale5;
                                        temp2.product.quantity1Scale1 = selectedItem.quantity1Scale1;
                                        temp2.product.quantity2Scale2 = selectedItem.quantity2Scale2;
                                        temp2.product.quantity3Scale3 = selectedItem.quantity3Scale3;
                                        temp2.product.quantity4Scale4 = selectedItem.quantity4Scale4;
                                        temp2.product.quantity5Scale5 = selectedItem.quantity5Scale5;
                                        temp2.product.conditionAmount = selectedItem.conditionAmount;
                                        // 
                                        let tempTableData = tableData;
                                        if (isNull(selectedItem.conditionAmount)) {
                                          temp2.product.currentUnitPrice = setUpCartandSpecialPrices(selectedItem, tempTableData[index1].orderitems[index].cartQuantity);
                                        } else {
                                          temp2.product.currentUnitPrice = selectedItem.conditionAmount;
                                        }
                                        let packSize = 1;
                                        if (selectedItem.packSize) {
                                          packSize = parseFloat(selectedItem.packSize);
                                        }
                                        temp2.totalPrice = parseFloat(temp2.product.currentUnitPrice) * parseInt(tempTableData[index1].orderitems[index].cartQuantity) * packSize;
                                        // 
                                        temp.map((val) => {
                                          return val?.articaleCode == selectedItem?.articaleCode ? val.select = true : val.select = false
                                        });

                                        tempTableData[index1].orderitems[index] = temp2
                                        setTableData(tempTableData);

                                        handleClick("event", tempTableData[index1], true);

                                        updateProductValue(true)
                                      }}
                                    >
                                      {
                                        itemsinorder.product.selectValues.map((i, ind) => {
                                          return (<option selected={i.select} value={i.articaleCode} key={ind}> {i.articaleCode + "  " + i.productNameTH}</option>);

                                          // return (<option selected={i.articaleCode===itemsinorder
                                          //   .articaleCode} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                        })
                                      }
                                    </select>
                                    : lancode === "en" ? <h3><span>{itemsinorder.product.selectValues[0].articaleCode}</span><br></br>{itemsinorder.product.selectValues[0].productNameEN}</h3> : <h3><span>{itemsinorder.product.selectValues[0].articaleCode}</span><br></br>{itemsinorder.product.selectValues[0].productNameTH}</h3>}
                                </>
                              </div>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                              <div className="odmProduct-ProductDetails-desc">
                                <h4>{!isNull(itemsinorder.product.stock) ? checkDecimal(itemsinorder.product.stock) : 0}</h4>
                                {/* <h4>
                                  {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (!isNull(itemsinorder.product.stock) ?
                                     {Number(itemsinorder.product.stock)}  :
                                    )} {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (isNull(itemsinorder.product.stock)
                                      ?  0 : <></>)}

                                </h4> */}
                              </div>
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              <div className="odmProduct-ProductDetails-desc">
                                <h4> {itemsinorder.cartQuantity}</h4>
                              </div>
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              <div className="odmProduct-ProductDetails-desc">
                                <h4>{!isNull(itemsinorder.product.stock) ? checkDecimal(Number(itemsinorder.product.stock - itemsinorder.cartQuantity)) : Number(0 - itemsinorder.cartQuantity)}</h4>
                                {/* <h4>
                                  {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (!isNull(itemsinorder.product.stock) ?
                                    <span className="tax-modal-in-stock-heading" style={{ fontWeight: 400 }}> {Number(itemsinorder.product.stock - itemsinorder.cartQuantity)} </span> : <></>)}
                                  {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (isNull(itemsinorder.product.stock) ?
                                    <span className='tax-modal-out-stock-heading' > 0</span> : <></>)}
                                </h4> */}
                              </div>
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              <div className="odmProduct-ProductDetails-desc">
                                <h4>{itemsinorder.allocatedQtd}</h4>
                              </div>
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              <div className="odmProduct-ProductDetails-desc">
                                <h4>{itemsinorder.product.saleUnitEN}</h4>
                              </div>
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              <div className="odmProduct-ProductDetails-desc">
                                <input
                                  name={`allocatedQtd-${index}`}
                                  type="text"
                                  // value={selectedQuantity.find(select => select.orderItemId === itemsinorder.orderItemId)?.allocatedQtd || (!itemsinorder.allocatedQtd && ((parseInt(itemsinorder.product.stock) - parseInt(itemsinorder.cartQuantity))  > -1))?itemsinorder.cartQuantity:itemsinorder.allocatedQtd}                                  
                                  value={selectedQuantity.find(select => select.orderItemId === itemsinorder.orderItemId)?.allocatedQtd || itemsinorder.allocatedQtd}
                                  // className={invalidInputClass(itemsinorder.orderItemId)}
                                  className={"form-control" + (itemsinorder.allocatedQtd ? "" : " is-invalid")}
                                  onChange={(event) => handleChangeinput(event, itemsinorder.orderItemId, index, itemsinorder, index1)}
                                /* onChange={(event) => {
                                  updateQuantity(
                                    event, itemsinorder.orderItemId, itemsinorder.allocatedQtd);
                                }} */

                                /*  style={{
                                   width: "40px",
                                   color: "green",
                                   border: "3",
                                 }} */
                                />
                              </div>
                            </TableCell>
                            <TableCell align="center" colSpan={1}>
                              <div className="odmProduct-ProductDetails-desc">
                                <h4>
                                  <span>
                                    {/*  ฿{itemsinorder.product.price1Scale1}.00 */}
                                    {"฿ " + (isNull(itemsinorder.product.currentUnitPrice) ? itemsinorder.product.currentUnitPrice : isNull(itemsinorder.product.conditionAmount) ? itemsinorder.product.currentUnitPrice : itemsinorder.product.conditionAmount) + ".00"}
                                    {/* {!isNull(userData) && <span variant='body1' className="price-field-heading">{"฿ " + (isNull(items.productPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.currentUnitPrice : items.conditionAmount : items.productPrice) + ".00"} </span>} */}
                                  </span>
                                </h4>
                              </div>
                            </TableCell>
                            <TableCell align="right" colSpan={1}>
                              <div className="odmProduct-ProductDetails-desc">
                                <h4>
                                  <span>
                                    ฿&nbsp;
                                    {convertToCurrencyFormat(itemsinorder.totalPrice == 0 || itemsinorder.totalPrice == null || itemsinorder.totalPrice == undefined ? itemsinorder.allocatedQtd == 0 || itemsinorder.allocatedQtd == null || itemsinorder.allocatedQtd == undefined ? itemsinorder.product.currentUnitPrice * itemsinorder.cartQuantity * itemsinorder.product.packSize : itemsinorder.allocatedQtd * itemsinorder.product.currentUnitPrice * itemsinorder.product.packSize : itemsinorder.totalPrice)}
                                  </span>
                                </h4>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow>
                        <TableCell align="right" colSpan={14}><div className="odmProduct-ProductDetails-desc" style={{ textAlign: 'right' }}><h4>Total</h4></div></TableCell>
                        <TableCell align="right" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc"><h5>฿&nbsp;{convertToCurrencyFormat(items.totalOrderPrice)}</h5></div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                })}
            </Table>

          </TableContainer>
        </div>
        {tableData && tableData.length > 0 &&
          <div className='row order-table-pagination-main-container'>
            <div className='col-sm-7 order-table-pagination-inner-container'>
              <div className="d-flex flex-row">
                <h6 className='order-table-pagination-count'>1-{rowsPerPage} of {totalCount}</h6>
                <h6 className='order-table-order-per-page'>Order per page</h6>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    variant="outlined"
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    displayEmpty
                    sx={{
                      "&:hover": {
                        "&& fieldset": {
                          border: "2px solid #61A60E;"
                        }
                      }
                    }}
                    inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ position: "absolute", width: 11, height: 7, left: 37, bottom: 37, cursor: "pointer" }} /> }}>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {/* pagination-section start from here */}
            <div className='col-sm-5 order-table-pagination-inner-container p-0 m-0'>
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  variant="outlined"
                  shape="rounded"
                  onChange={(event, page) => handleChangePage(event, page)}
                // onChange={props.handleChangePage}
                />
              </Stack>
            </div>
            {/* pagination-section end */}
          </div>
        }
      </div>
      {/* !toconfirmedOrderList &&
        !toconfirmedOrderList?.data && */
        !tableData &&
        <div className='col-sm-12 col-md-12'>
          <div className='table-empty-data-main-container'>
            <img src={emptyTableData} className='table-empty-data-image' />
            <h6 className='table-empty-data-heading'>No To confirm orders yet</h6>
          </div>
        </div>
      }
    </div>
  );
};

export { OrderManagementByOrder as OrderManagementByOrder };

