import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import _ from 'lodash';
import moment from "moment";
import React, { useCallback, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from "react-bootstrap/Dropdown";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { orderActions } from "../../../_actions";
import { Base64, convertToCurrencyFormat } from '../../../_helpers/commanFunction';
import { REACT_APP_BASE_URL } from '../../../constant';
import AllStatusDownImage from '../../../images/all-status-chevron.png';
import CalendarImage from '../../../images/calendar-icon.png';
import CancelledOrderImage from '../../../images/cancelled-order.png';
import CheckMarkImage from "../../../images/check.png";
import ComfirmedOrderImage from '../../../images/comfirmed-order.png';
import CompletedImage from '../../../images/completed.png';
import blankImage from "../../../images/empty-image.png";
import emptyTableData from '../../../images/empty-list.png';
import FilterImage from '../../../images/filter-icon.png';
import OrderPlaceImage from '../../../images/order-place.png';
import SearchImage from '../../../images/Search.png';
import ShippedImage from '../../../images/shipped.png';
import TagImage from '../../../images/tag.png';
import UserAvatar from "../../../images/user-avatar.png";
import Loading from "../../../SharedComponent/Loader/Loader";
// import { isMobile } from 'react-device-detect';

const ActiveTab = (props) => {
    const userState = useSelector((state) => state.users);
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const lancode = localStorage.getItem("lancode");
    var userData = JSON.parse(localStorage.getItem('user'))
    const [loading, setLoading] = useState(false);
    const isNull = (value) => {
        return value === "" || value === undefined || value === null;
    };
    var storeCode = !isNull(userData) ? userData.user.stores[0].storeCode : "";
    var token = !isNull(userData) ? userData.token : "";
    const store = userData ? userData.user.stores[0].storeCode : "";
    const activeOrderList = useSelector((state) => state.order.activelistorderList);
    const [tableDataactivelist, setTableDataActivelist] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [userQuery, setUserQuery] = useState("");
    const [selectedValue, setSelectedValue] = useState('allstatus');
    const [orderStatusModal, setOrderStatusModal] = useState(false);
    const [mobileActiveList, setMobileActiveList] = useState([]);
    const [openAllStatusModal, setOpenAllStatusModal] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    const historylistorderList = useSelector((state) => state.order.historylistorderList);
    const torevieworderList = useSelector((state) => state.order.torevieworderList);

    const [tableDatahistorylist, setTableDatahistorylist] = React.useState([]);
    const [tableDatatoreview, setTableDatatoreview] = React.useState([]);
    const [totalCountactivelist, setTotalCountactivelist] = React.useState(0);
    const [totalCounthistorylist, setTotalCounthistorylist] = React.useState(0);
    const [totalCounttoreview, setTotalCounttoreview] = React.useState(0);
    const history = useHistory();

    const [hasMore, setHasMore] = useState(true);

    const sendQuery = query => { searchItem(query) };
    const delayedQuery = useCallback(_.debounce(q => sendQuery(q), 500), []);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    React.useEffect(() => {
        setLoading(true);
        if (!userData) {
            history.push("/login");
        } else if (!selectedValue || selectedValue == '') {
            let payload_activelist = {
                "storeCode": store,
                "flags": {
                    "product": true
                }

            };
            let payload = {};
            let queryString = `limit=${parseInt(rowsPerPage)}&skip=${page}`;
            dispatch(orderActions.activeList("", payload_activelist, queryString));
        }
        else if (selectedValue === 'allstatus') {
            let payload_activelist = {
                "storeCode": store,
                "flags": {
                    "product": true
                }

            };
            let payload = {};
            let queryString = `limit=${parseInt(rowsPerPage)}&skip=${page}`;
            dispatch(orderActions.activeList("", payload_activelist, queryString));
        } else {
            const payload = {
                "storeCode": store,
                "status": selectedValue,
                "flags": {
                    "product": true
                }
            }
            dispatch(orderActions.activeList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
            setLoading(false);
        }

    }, [page]);


    React.useEffect(() => {
        if (isMobile == true) {
            let payload_activelist = {
                "storeCode": store,
                "flags": {
                    "product": true
                }

            };
            let payload = {};
            // let queryString = `limit=${parseInt(rowsPerPage)}&skip=${page}`;        
            dispatch(orderActions.activeList("", payload_activelist, ""));
        }
    }, [isMobile]);

    React.useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobileDevice = /mobile|iphone|ipad|android/.test(userAgent);
        setIsMobile(isMobileDevice);
    }, []);

    React.useEffect(() => {
        setLoading(true);
        if (isMobile == true) {
            if (activeOrderList?.data) {
                if (activeOrderList.data && activeOrderList?.data.result.length > 0) {
                    activeOrderList?.data.result.map(item => {
                        item.ordercreatedat = new Date(item.createdAt).toLocaleDateString('en-IN')
                        return item;
                    });
                    setMobileActiveList(activeOrderList?.data);
                }
                setLoading(false);
                // setTotalCountactivelist(activeOrderList?.data.totalCount);
            } else {
                setMobileActiveList([]);
                setLoading(false);
                // setTotalCountactivelist(0);
            }
        }
    }, [activeOrderList, isMobile]);


    React.useEffect(() => {
        setLoading(true);
        if (activeOrderList?.data) {
            if (activeOrderList.data && activeOrderList?.data.result.length > 0) {
                activeOrderList?.data.result.map(item => {
                    item.ordercreatedat = moment(item.createdAt).format("DD/MM/YYYY")
                    //  new Date(item.createdAt).toLocaleDateString('en-IN') 
                    return item;
                });
                setTableDataActivelist(activeOrderList?.data);
            }
            setLoading(false);
            // setTotalCountactivelist(activeOrderList?.data.totalCount);
        } else {
            setTableDataActivelist([]);
            setLoading(false);
            // setTotalCountactivelist(0);
        }

    }, [activeOrderList, page]);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const routeToOrderDetailsPage = (items, event) => {
        event.stopPropagation()
        history.push("/myorderdetails/" + Base64.encode(items.orderId.toString()), { orderId: items.orderId, storeCode: items.storeCode });
    }


    const handleChangePage = (event, newPage) => {
        // setLoading(true);
        let pages = (newPage - 1) * rowsPerPage;
        const payload = {
            "storeCode": store,
            "flags": {
                "product": true
            }
        };

        setPage(pages);
        setCurrentPage(newPage)
        // dispatch(
        //     orderActions.activeList(
        //     "",
        //     payload,
        //     `limit=${parseInt(rowsPerPage)}&skip=${pages}`
        //   )
        // );
    };

    const searchItem = (query) => {
        //   setLoadingLoader(true)
        const payload = {
            "storeCode": store,
            "orderSearch": query,
            "flags": {
                "product": true
            }
        };
        try {
            dispatch(orderActions.activeList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
            // localStorage.setItem('keywordsearch', query);
            // setKeywordsearch(query);
        } catch (error) {
            // setLoadingLoader(false)
        }
    }

    const totalPages =
        tableDataactivelist && tableDataactivelist.totalCount
        && Math.ceil(parseInt(tableDataactivelist.totalCount) / parseInt(rowsPerPage));

    const handleSelect = (eventKey) => {
        setLoading(true);
        setOrderStatusModal(false);
        setOpenAllStatusModal(false);
        setSelectedValue(eventKey);
        const value = eventKey;
        if (value == "allstatus") {
            const payload = {
                "storeCode": store,
                "flags": {
                    "product": true
                }
            }
            dispatch(orderActions.activeList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
            setLoading(false);
        } else {
            const payload = {
                "storeCode": store,
                "status": value,
                "flags": {
                    "product": true
                }
            }
            dispatch(orderActions.activeList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
            setLoading(false);
        }
        setOrderStatusModal(false);
        setOpenAllStatusModal(false);

    }

    const handleSelectionChange = (event) => {
        setLoading(true);
        setSelectedValue(event.target.value);
        const value = event.target.value
        if (value == "allstatus") {
            const payload = {
                "storeCode": store,
                "flags": {
                    "product": true
                }
            }
            dispatch(orderActions.activeList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
            setLoading(false);
        } else {
            const payload = {
                "storeCode": store,
                "status": value,
                "flags": {
                    "product": true
                }
            }
            dispatch(orderActions.activeList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
            setLoading(false);
        }
    }

    // All Status Filter Options
    const AllStatusFilter = {
        allstatus: t("AllStatus.label"),
        orderplaced: t("OrderPlaced.label"),
        confirmed: t("Confirmed.label"),
        shipped: t("Shipped.label"),
        // completed: t("Completed.label"),
        // cancelled: t("Cancelled.label")
    }

    const routeToProductDetailsPage = (items) => {
        history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });
        // history.push("/productdetailscustomer", { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
    }
    return (<>
        <Helmet>
            <title>Betagro My Order</title>
        </Helmet>
        {(!tableDataactivelist.result && (!activeOrderList || activeOrderList.code !== 400)) && (<Loading />)}
        {/* search-bar-section start from here */}
        <div className='row my-order-search-bar-margin-bottom'>
            <div className={userData && userData.user && (userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') ? 'col-xll-9 col-xl-9 col-md-10 col-sm-10 col-10' : 'col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12'}>
                <div className='my-order-tab-search-bar-main-container'>
                    <input type="text"
                        value={userQuery}
                        onChange={(e) => {
                            // setUserQuery(e.target.value);                            
                            // searchAlgo(e.target.value)
                            setUserQuery(e.target.value);
                            delayedQuery(e.target.value);
                        }}
                        data-testid="active-tab-search-input"
                        placeholder={(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') ? t("SearchOrderByOrderNo.label") : t("Searchorder.label")}
                        className='my-order-tab-search-bar-input' />
                    <img src={SearchImage} className="search-by-order-number-input-icon" />
                </div>
            </div>
            {/* search-bar-section end */}
            {/* all-status-dropdown-section start from here */}
            {userData && userData.user && (userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (
                <div className='col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 common-display-none-mobile'>
                    <div className='my-order-all-state-selection-options'>
                        {/* <div className="my-order-all-state-selection-option-inner-container">
                    <select name="sources" 
                            value={selectedValue}
                            onChange={handleSelectionChange}                             
                            id="sources" 
                            className="my-order-all-state-selection-select-custom-container" 
                            placeholder="All Status">
                    <option value="allstatus" className='my-order-all-state-selection-option-custom-container'>{t("AllStatus.label")}</option>
                    <option value="orderplaced" className='my-order-all-state-selection-option-custom-container'>{t("OrderPlaced.label")}</option>
                    <option value="confirmed" className='my-order-all-state-selection-option-custom-container'>{t("Confirmed.label")}</option>
                    <option value="shipped" className='my-order-all-state-selection-option-custom-container'>{t("Shipped.label")}</option>
                    <option value="completed" className='my-order-all-state-selection-option-custom-container'>{t("Completed.label")}</option>
                    <option value="cancelled" className='my-order-all-state-selection-option-custom-container'>{t("Cancelled.label")}</option>                    
                    </select>
                </div> */}
                        <div className='my-order-all-status-filter-custom-dropdown' onClick={() => setOpenAllStatusModal(!openAllStatusModal)}>
                            <div className='d-flex flex-row align-items-center justify-content-between'>
                                <h6 className='my-order-all-status-filter-custom-dropdown-heading'>{AllStatusFilter[selectedValue]}</h6>
                                <img src={AllStatusDownImage} className='my-order-all-status-filter-custom-dropdown-image' />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* mobile-filter-section start from here*/}
            {userData && userData.user && (userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') ?
                <div className='col-xll-3 col-xl-3 col-md-2 col-sm-2 col-2 my-order-display-block-mobile'>
                    <div className='my-orders-filter-icon-container' onClick={() => setOrderStatusModal(true)}>
                        <img src={FilterImage} className='my-orders-filter-icon-inner-container' />
                    </div>
                </div> : null}
        </div>
        {/* mobile-filter-section end*/}
        {/* all-status-dropdown-section end */}
        {tableDataactivelist && tableDataactivelist.result && tableDataactivelist.result.map((items, index) => (
            <>
                <div className='col-md-12 p-0 my-order-tab-accordion-custom-container'>
                    <div className='my-order-tab-accordion-cards'>
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>

                                    <div className='col-xl-7 col-md-7'>
                                        <div className='my-order-search-order-number-main-container'>
                                            <div className='d-flex flex-row'>
                                                <img src={TagImage} className='my-order-search-order-tag-image' />   <h6 className='my-order-search-order-number-heading'>{t("OrderNo.label")}. {items.orderNo}</h6>
                                                {/* <div className='my-order-order-status-inner-container'>
                                                    <img src={OrderPlaceImage} className='my-order-order-status-image' />
                                                    <h6 className='my-order-order-heading'>{items.status}</h6>
                                                </div> */}
                                                {items.status === "cancelled" && (
                                                    <div className='my-order-order-cancelled-status-container'>
                                                        <img src={CancelledOrderImage} className='my-order-cancelled-status-image' />
                                                        <h6 className='my-order-cancelled-status-heading'>{t("Cancelled.label")}</h6>
                                                    </div>
                                                )}
                                                {items.status === "new" && (
                                                    <div className='my-order-order-status-inner-container'>
                                                        <img src={OrderPlaceImage} className='my-order-completed-status-image' />
                                                        <h6 className='my-order-order-heading'>{t("OrderPlaced.label")}</h6>
                                                    </div>
                                                )}
                                                {items.status === "to_confirm" && (
                                                    <div className='my-order-order-status-inner-container'>
                                                        <img src={OrderPlaceImage} className='my-order-completed-status-image' />
                                                        <h6 className='my-order-order-heading'>{t("OrderPlaced.label")}</h6>
                                                    </div>
                                                )}
                                                {items.status == 'confirmed' && (
                                                    <div className='my-order-order-status-confirmed-container'>
                                                        <img src={ComfirmedOrderImage} className='my-order-completed-status-image' />
                                                        <h6 className='my-order-confirmed-order-heading'>{t("Confirmed.label")}</h6>
                                                    </div>
                                                )}
                                                {items.status == 'shipped' && (
                                                    <div className='my-order-shipped-status-container'>
                                                        <img src={ShippedImage} className='my-order-completed-status-image' />
                                                        <h6 className='my-order-shipped-status-heading'>{t("Shipped.label")}</h6>
                                                    </div>
                                                )}
                                                {items.status == 'completed' && (
                                                    <div className='my-order-order-completed-status-container'>
                                                        <img src={CompletedImage} className='my-order-completed-status-image' />
                                                        <h6 className='my-order-completed-status-heading'>{t("Completed.label")}</h6>
                                                    </div>
                                                )}
                                            </div>
                                            {/* user-name-section start from here */}
                                            <div className='d-flex flex-row align-items-center'>
                                                {userData && userData.user && (userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (
                                                    <>
                                                        <img src={UserAvatar} className='my-order-search-order-user-image' />
                                                        {lancode === 'en' && (
                                                            <h6 className='my-order-search-order-user-name-heading'>{items.userfirstnameen} {" "} {items.userlastnameen.charAt(0).toUpperCase()}.</h6>
                                                        )}
                                                        {lancode === 'th' && (
                                                            <h6 className='my-order-search-order-user-name-heading'>{items.userfirstnameth} {" "} {items.userlastnameth.charAt(0).toUpperCase()}.</h6>
                                                        )}
                                                    </>
                                                )}
                                                <img src={CalendarImage} className='my-order-search-calendar-image' /> <h6 className='my-order-search-order-date-heading'>{t("Orderdate.label")}<span className='my-order-search-order-date-right-heading'>{items.ordercreatedat}</span></h6>
                                            </div>
                                            {/* user-name-section end */}
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='my-order-view-details-main-container'>
                                            <button onClick={(event) => routeToOrderDetailsPage(items, event)} className={lancode === "en" ? 'my-order-view-details-button' : 'my-order-view-details-button-th'}>{t("ViewDetails.label")}</button>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {items && items.orderitems && items.orderitems.map((item, index) => (
                                        <>
                                            {item.allocatedQtd != 0 && (
                                                <>
                                                    <div className='my-order-gray-line' />
                                                    <div className='row'>
                                                        <div className='col-xll-8 col-xl-8 col-md-8'>
                                                            <div className='my-order-accordion-custom-main-container'>
                                                                <div className='my-order-accordion-custom-inner-container'>
                                                                    <div className='d-flex flex-row my-order-accordion-custom-center'>
                                                                        <div className='my-order-accordion-custom-image-border'>
                                                                            <img
                                                                                onClick={() => routeToProductDetailsPage(item)} src={
                                                                                    (item.product.picture && item.product.picture.split(",")[0] && item.product.picture.split(",")[0] != undefined && item.product.picture.split(",")[0] != '') ?
                                                                                        (REACT_APP_BASE_URL +
                                                                                            "/api/download?" +
                                                                                            item.product.picture.split(",")[0]
                                                                                            ? REACT_APP_BASE_URL +
                                                                                            "/api/download?" +
                                                                                            item.product.picture.split(",")[0]
                                                                                            : blankImage)
                                                                                        : blankImage
                                                                                } className='my-order-accordion-custom-image' />
                                                                        </div>
                                                                        {lancode === "en" && (
                                                                            <h6
                                                                                onClick={() => routeToProductDetailsPage(item)} className='my-order-accordion-custom-product-heading'>{item?.product?.consumerNameEN} {item?.product?.packSizeEN}</h6>
                                                                        )}
                                                                        {lancode === "th" && (
                                                                            <h6
                                                                                onClick={() => routeToProductDetailsPage(item)} className='my-order-accordion-custom-product-heading'>{item?.product?.consumerNameTH} {item?.product?.packSizeTH}</h6>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-xll-4 col-xl-4 col-md-4'>
                                                            <div className='my-order-customer-price-main-container'>
                                                                <h6 className='my-order-customer-price-heading'>฿ {convertToCurrencyFormat(item.price)}</h6>
                                                                {item.allocatedQtd == null ? (
                                                                    <h6 className='my-order-customer-price-inner-heading'>x{item.cartQuantity}</h6>
                                                                ) : (
                                                                    <h6 className='my-order-customer-price-inner-heading'>x{item.allocatedQtd}</h6>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ))}
                                    <div className='my-order-gray-line' />
                                    <div className='row'>
                                        <div className='col-xl-8'>

                                        </div>
                                        <div className='col-xl-4'>
                                            <div className='my-order-customer-total-price-main-container d-flex flex-row'>
                                                <h6 className='my-order-customer-total-main-container'>{t("Total.label")}</h6>
                                                <h6 className='my-order-customer-total-price-heading'>฿ {convertToCurrencyFormat(items.totalOrderPrice)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </>
        ))}
        {/* cards-section section end */}
        {/* Mobile-cards-section start from here */}
        <div className='row my-order-display-block-mobile'>
            <div className='col-md-12 col-sm-12 col-12'>
                {mobileActiveList && mobileActiveList.result && mobileActiveList.result.map((items, index) => (
                    <>
                        <div className='my-orders-search-cards-mobile-main-container'>
                            <div className='my-orders-search-cards-mobile-inner-container'>
                                <div className='row'>
                                    <div className='col-xll-7 col-xl-7 col-md-7 col-sm-7 col-7'>
                                        <img src={TagImage} className='my-orders-search-cards-tag-image' /><span className='my-orders-search-cards-order-number'>{t("OrderNo.label")}. {items.orderNo}</span>
                                    </div>
                                    <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 text-right pl-0'>
                                        <img src={CalendarImage} className='my-orders-search-cards-calendar-image' /><span className='my-orders-search-cards-order-date'>{items.ordercreatedat}</span>
                                    </div>
                                    <div className='col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 align-items-center'>
                                        {userData && userData.user && (userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (
                                            <>
                                                <img src={UserAvatar} className='my-order-search-order-user-image' />
                                                {lancode === 'en' && (
                                                    <span className='my-order-search-order-user-name-heading'>{items.userfirstnameen} {" "} {items.userlastnameen.charAt(0).toUpperCase()}.</span>
                                                )}
                                                {lancode === 'th' && (
                                                    <span className='my-order-search-order-user-name-heading'>{items.userfirstnameth} {" "} {items.userlastnameth.charAt(0).toUpperCase()}.</span>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                        <div className='d-flex flex-row my-orders-search-cards-quantity-container'>
                                            <h6 className='my-orders-search-cards-quantity-heading'>{t("Items.label")}:</h6>
                                            <h6 className='my-orders-search-cards-quantity-counts'>{items?.orderitems.length}</h6>
                                        </div>
                                    </div>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                        <div className='d-flex flex-row float-right my-orders-search-cards-quantity-container'>
                                            <h6 className='my-orders-search-cards-total-heading'>{t("GrantTotalText.label")}:</h6>
                                            <h6 className='my-orders-search-cards-total-counts'>฿ {convertToCurrencyFormat(items.totalOrderPrice)}</h6>
                                        </div>
                                    </div>
                                    <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 my-orders-search-cards-button-section'>

                                        {items.status == 'cancelled' && (
                                            <div className='my-orders-search-cards-cancelled-button'>
                                                <img src={CancelledOrderImage} className='my-orders-search-cards-completed-button-image' />
                                                <span className='my-orders-search-cards-cancelled-button-heading'>{t("Cancelled.label")}</span>
                                            </div>
                                        )}
                                        {items.status == 'new' && (
                                            <div className='my-orders-search-cards-placed-button'>
                                                <img src={OrderPlaceImage} className='my-orders-search-cards-completed-button-image' />
                                                <span className='my-orders-search-cards-placed-button-heading'>{t("OrderPlaced.label")}</span>
                                            </div>
                                        )}
                                        {items.status == 'to_confirm' && (
                                            <div className='my-orders-search-cards-placed-button'>
                                                <img src={OrderPlaceImage} className='my-orders-search-cards-completed-button-image' />
                                                <span className='my-orders-search-cards-placed-button-heading'>{t("OrderPlaced.label")}</span>
                                            </div>
                                        )}
                                        {items.status == 'confirmed' && (
                                            <div className='my-orders-search-cards-comfirmed-button'>
                                                <img src={ComfirmedOrderImage} className='my-orders-search-cards-completed-button-image' />
                                                <span className='my-orders-search-cards-comfirmed-button-heading'>{t("Confirmed.label")}</span>
                                            </div>
                                        )}
                                        {items.status == 'shipped' && (
                                            <div className='my-orders-search-cards-shipped-button'>
                                                <img src={ShippedImage} className='my-orders-search-cards-completed-button-image' />
                                                <span className='my-orders-search-cards-shipped-button-heading'>{t("Shipped.label")}</span>
                                            </div>
                                        )}
                                        {items.status == 'completed' && (
                                            <div className='my-orders-search-cards-completed-button'>
                                                <img src={CompletedImage} className='my-orders-search-cards-completed-button-image' />
                                                <span className='my-orders-search-cards-completed-button-heading'>{t("Completed.label")}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 pl-0 my-orders-search-cards-button-section'>
                                        {/* <button className='my-orders-search-cards-reorder-button'>
                                            Reorder
                                        </button> */}
                                    </div>
                                    <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pl-0 text-right my-orders-search-cards-button-section float-right'>
                                        <button onClick={(event) => routeToOrderDetailsPage(items, event)} className='my-orders-search-cards-view-details-button'>
                                            {t("ViewDetails.label")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
                {/* <div className='my-orders-search-cards-mobile-main-container'>
                    <div className='my-orders-search-cards-mobile-inner-container'>
                        <div className='row'>
                            <div className='col-xll-7 col-xl-7 col-md-7 col-sm-7 col-7'>
                                <img src={TagImage} className='my-orders-search-cards-tag-image' /><span className='my-orders-search-cards-order-number'>Order No. 1234567890</span>
                            </div>
                            <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 text-right pl-0'>
                                <img src={CalendarImage} className='my-orders-search-cards-calendar-image' /><span className='my-orders-search-cards-order-date'>01/10/2022</span>
                            </div>
                            <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                <div className='d-flex flex-row my-orders-search-cards-quantity-container'>
                                    <h6 className='my-orders-search-cards-quantity-heading'>Quantity:</h6>
                                    <h6 className='my-orders-search-cards-quantity-counts'>3</h6>
                                </div>
                            </div>
                            <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                <div className='d-flex flex-row float-right my-orders-search-cards-quantity-container'>
                                    <h6 className='my-orders-search-cards-total-heading'>Total:</h6>
                                    <h6 className='my-orders-search-cards-total-counts'>฿ 2,000</h6>
                                </div>
                            </div>
                            <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 my-orders-search-cards-button-section'>
                                <div className='my-orders-search-cards-completed-button'>
                                    <img src={CompletedImage} className='my-orders-search-cards-completed-button-image' />
                                    <span className='my-orders-search-cards-completed-button-heading'>Completed</span>
                                </div>
                            </div>
                            <div className='col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 pl-0 my-orders-search-cards-button-section'>
                                <button className='my-orders-search-cards-reorder-button'>
                                    Reorder
                                </button>
                            </div>
                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pl-0 my-orders-search-cards-button-section float-right'>
                                <button className='my-orders-search-cards-view-details-button'>
                                    View details
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
        {/* Mobile-cards-section end */}
        {/* Pagination-section start from here */}
        {tableDataactivelist && tableDataactivelist.result && tableDataactivelist.result.length > 0 && (
            <div className="row pagination-main-container common-display-none-mobile">
                <div className="col-xll-6 col-xl-6 col-md-6 col-4"></div>
                <div className="col-xll-6 col-xl-6 col-md-6 col-12 mt-32">
                    {tableDataactivelist.totalCount > 10 && <Stack spacing={2}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            variant="outlined"
                            shape="rounded"
                            onChange={(event, page) => handleChangePage(event, page)}
                        />
                    </Stack>}
                </div>
            </div>
        )}
        {/* Pagination-section start from here */}
        {/* no-order-yet-section start from here */}
        {tableDataactivelist.length == 0 && (
            <div className='col-sm-12 col-md-12'>
                <div className='table-empty-data-main-container'>
                    <img src={emptyTableData} className='table-empty-data-image' />
                    <h6 className='table-empty-data-heading'>{t("Noorderyet.label")}</h6>
                    <div className='my-cart-is-empty-button' onClick={() => history.push("/allproducts")}>
                        <span className='my-cart-is-empty-text'>{t("ContinueShopping.label")}</span>
                    </div>
                </div>
            </div>
        )}
        {/* no-order-yet-section end */}
        {/* order all status modal end */}
        {/* order all status modal section start from here */}
        {userData && userData.user && (userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (
            <>
                {orderStatusModal ?
                    <div className='order-all-status-section-modal'>
                        <div className='order-all-status-inner-section-modal'>
                            <div className='order-all-status-custom-section-modal'>
                                <div className='order-all-status-gray-line' />
                                <div className="order-all-status-inner-custom-modal">
                                    <Dropdown onSelect={handleSelect}>
                                        <Dropdown.Item value="allstatus" className={selectedValue == "allstatus" ? "order-all-status-inner-custom-heading-active" : "order-all-status-inner-custom-heading-disable"} eventKey="allstatus">{t("AllStatus.label")}{selectedValue == "allstatus" ? <span><img src={CheckMarkImage} className='order-all-status-inner-custom-checkmark-image' /></span> : null}</Dropdown.Item>
                                        <Dropdown.Item value="orderplaced" className={selectedValue == "orderplaced" ? "order-all-status-inner-custom-heading-active" : "order-all-status-inner-custom-heading-disable"} eventKey="orderplaced">{t("OrderPlaced.label")}{selectedValue == "orderplaced" ? <span><img src={CheckMarkImage} className='order-all-status-inner-custom-checkmark-image' /></span> : null}</Dropdown.Item>
                                        <Dropdown.Item value="confirmed" className={selectedValue == "confirmed" ? "order-all-status-inner-custom-heading-active" : "order-all-status-inner-custom-heading-disable"} eventKey="confirmed">
                                            {t("Confirmed.label")}{selectedValue == "confirmed" ? <span><img src={CheckMarkImage} className='order-all-status-inner-custom-checkmark-image' /></span> : null}
                                        </Dropdown.Item>
                                        <Dropdown.Item value="shipped" className={selectedValue == "shipped" ? "order-all-status-inner-custom-heading-active" : "order-all-status-inner-custom-heading-disable"} eventKey="shipped">{t("Shipped.label")}{selectedValue == "shipped" ? <span><img src={CheckMarkImage} className='order-all-status-inner-custom-checkmark-image' /></span> : null}</Dropdown.Item>
                                        {/* <Dropdown.Item value="completed" className={selectedValue == "completed" ? "order-all-status-inner-custom-heading-active" : "order-all-status-inner-custom-heading-disable"} eventKey="completed">{t("Completed.label")}{selectedValue == "completed" ? <span><img src={CheckMarkImage} className='order-all-status-inner-custom-checkmark-image' /></span>: null}</Dropdown.Item>
                                <Dropdown.Item value="cancelled" className={selectedValue == "cancelled" ? "order-all-status-inner-custom-heading-active" : "order-all-status-inner-custom-heading-disable"} eventKey="cancelled">{t("Cancelled.label")}{selectedValue == "cancelled" ? <span><img src={CheckMarkImage} className='order-all-status-inner-custom-checkmark-image' /></span>: null}</Dropdown.Item> */}
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                {/* order all status modal end */}
                {/* Desktop-all-status section start from here  */}
                {openAllStatusModal ?
                    <div className='my-order-all-status-filter-desktop-dropdown-main-container'>
                        <Dropdown onSelect={handleSelect}>
                            <Dropdown.Item value="allstatus" className={selectedValue == "allstatus" ? "my-order-all-status-filter-desktop-dropdown-active-heading" : "my-order-all-status-filter-desktop-dropdown-disable-heading"} eventKey="allstatus">{t("AllStatus.label")}</Dropdown.Item>
                            <Dropdown.Item value="orderplaced" className={selectedValue == "orderplaced" ? "my-order-all-status-filter-desktop-dropdown-active-heading" : "my-order-all-status-filter-desktop-dropdown-disable-heading"} eventKey="orderplaced">{t("OrderPlaced.label")}</Dropdown.Item>
                            <Dropdown.Item value="confirmed" className={selectedValue == "confirmed" ? "my-order-all-status-filter-desktop-dropdown-active-heading" : "my-order-all-status-filter-desktop-dropdown-disable-heading"} eventKey="confirmed">
                                {t("Confirmed.label")}
                            </Dropdown.Item>
                            <Dropdown.Item value="shipped" className={selectedValue == "shipped" ? "my-order-all-status-filter-desktop-dropdown-active-heading" : "my-order-all-status-filter-desktop-dropdown-disable-heading"} eventKey="shipped">{t("Shipped.label")}</Dropdown.Item>
                            {/* <Dropdown.Item value="completed" className={selectedValue == "completed" ? "my-order-all-status-filter-desktop-dropdown-active-heading" : "my-order-all-status-filter-desktop-dropdown-disable-heading"} eventKey="completed">{t("Completed.label")}</Dropdown.Item>
                                <Dropdown.Item value="cancelled" className={selectedValue == "cancelled" ? "my-order-all-status-filter-desktop-dropdown-active-heading" : "my-order-all-status-filter-desktop-dropdown-disable-heading"} eventKey="cancelled">{t("Cancelled.label")}</Dropdown.Item> */}
                        </Dropdown>
                    </div> : null}
                {/* Desktop-all-status section end */}


            </>
        )}
        {/* order all status modal end */}
    </>
    )
}
export default ActiveTab;