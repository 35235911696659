import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { Helmet } from "react-helmet";
import { useTranslation, withTranslation } from "react-i18next";
import { userActions } from "../_actions";
import sendotpbutton from "../images/sendotpbutton.png";
import sendemaillinkbutton from "../images/sendemaillinkbutton.png";
import betagoapplicationname from "../images/betagroLogin.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import "./LoginCreatePassword.css";
import "../LoginFirstTime/loginFirstTime.css";
import Card from "@mui/joy/Card";
import { Footer } from "../Footer";
import { Header } from "../Header";
import Button from "react-bootstrap/Button";
import Eye from "../images/Eye.svg";
import CrossEye from "../images/crossEye.svg";
import Loader from "../SharedComponent/Loader/Loader";

const defaultFormFields = {
  password: "",
  confirmPassword: "",
};

const LoginCreatePassword = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [type, setType] = useState("password");
  const [submitted, setSubmitted] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordMatched, setPasswordMatched] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const { password, confirmPassword } = formFields;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const passwordReset = params.get("resetpassword");

  const history = useHistory();

  const { t } = useTranslation();

  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  const userState = useSelector((state) => state.users);


  const dispatch = useDispatch();

  //   useEffect(() => {
  //     const test = {
  //       type: "email",
  //       email: "jawahardurai.j@coforge.com",
  //     };
  //     dispatch(userActions.firtTimeLogin("email", test));
  //   }, []);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  useEffect(() => {
    setLoading(false);
    if (inputRef) {
      inputRef.current.addEventListener("focus", () => {
        inputRef.current.classList.add("custom-focus");
      });

      inputRef.current.addEventListener("blur", () => {
        inputRef.current.classList.remove("custom-focus");
      });
    }

    if (inputRef2) {
      inputRef2.current.addEventListener("focus", () => {
        inputRef2.current.classList.add("custom-focus");
      });

      inputRef2.current.addEventListener("blur", () => {
        inputRef2.current.classList.remove("custom-focus");
      });
    }
  }, []);

  const createYourPassword = (password, confirmPassword) => {
    const payload = {
      password: password,
      confirmPassword: confirmPassword,
    };
    setLoading(true);
    dispatch(userActions.setPassword("setPassword", payload));
  };

  const handleChange = (event) => {
    setPasswordMatched(false);
    setPasswordLength(false);
    setSubmitted(false);
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value.replace(/[^0-9a-zA-Z]+/ig, "") });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let pwd = false, cpwd = false;
    setPasswordLength(false);
    setPasswordMatched(false);
    if (!formFields.password) {
      setPasswordLength(true);
    } else if (
      formFields.password.length < 6 ||
      formFields.password.length > 12
    ) {
      setPasswordLength(true);
    } else if (formFields.password !== formFields.confirmPassword) {
      setPasswordMatched(true);
    } else {
      try {
        setPasswordMatched(false);
        createYourPassword(password, confirmPassword);
        resetFormFields();
        if(passwordReset === "true") {          
          history.push("/loginresetpasswordsuccess");
        } else {
          history.push("/login");
        }
      } catch (error) {
        setSubmitted(true);
      }
    }
  };

  const handleMouseDown = () => {
    setShowPassword(true);
    setType("text");
  };

  const handleMouseUp = () => {
    setShowPassword(false);
    setType("password");
  };

  const handleShowPassowrd = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPassowrd2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <>
      <Helmet>
        <title>
          Betagro{" "}
          {passwordReset == "true" ? "Reset Password" : "Create Your Password"}{" "}
        </title>
      </Helmet>
      {loading && <Loader />}
      <div className="headerFixed">
      <Header />
      </div>
        <div className="logIn-firstTime-outer headerFixed-margin">
        <div className="logIn-firstTime">
          <div className="loginheaderimage">
            <img
              src={betagoapplicationname}
              alt="Betagro Logo"
            />
          </div>
          <h2>
            {passwordReset == "true"
              ? t("ResetPassword.label")
              : t("createYourPassword.label")}
          </h2>
          <h6>
            {passwordReset == "true"
              ? t("Enteranewpasswordforyouraccount.label")
              : t("createStrongPassword.label")}
          </h6>

          <form onSubmit={handleSubmit}>
            <div className="password-form">
              <p className="input-label-st"> {t("password.label")}</p>
              <div className={"form-group"} style={{position: "relative"}} >
                <input
                  ref={inputRef}
                  type={showPassword ? "text" : "password"}
                  className={
                    "form-control" +
                    (passwordLength ? " is-invalid" : "")
                  }
                  placeholder={t("Enterpassword.label")}
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
                {/* {submitted && !formFields.password && (
                  <div className="help-block"> {t("Passwordisrequired.label")}</div>
                )} */}
                {/* onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp} */}
                <div onClick={handleShowPassowrd}>
                  {showPassword ? (
                    <img
                      src={CrossEye}
                      alt="React "
                      className="passwordIcon1"
                    />
                  ) : (
                    <img src={Eye} alt="React " className="passwordIcon1" />
                  )}
                </div>
              {passwordLength && (
                  <div className="invalid-feedback">
                    <p className="invalid-feedbackMsg">
                      {t("Passwordlengthshouldbe6to12characterlong.label")}
                    </p>
                  </div>
                )}

              {/* {passwordMatched && (
                  <div className="invalid-feedback">
                    <p className="invalid-feedbackMsg">
                      {t("Passwordlengthshouldbe6to12characterlong.label")}
                    </p>
                  </div>
                )} */}
              </div>

              <p className={passwordMatched ? 'input-label-st' : 'input-label-st'}>{t("Confirmpassword.label")}</p>
              <div className={"form-group"} style={{position: "relative"}}>
                <input
                  ref={inputRef2}
                  type={showPassword2 ? "text" : "password"}
                  className={
                    "form-control" +
                    (passwordMatched ? " is-invalid" : "")
                  }
                  style={{ marginBottom: "20px" }}
                  placeholder={t("Enterpassword.label")}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                />

                <div onClick={handleShowPassowrd2}>
                  {showPassword2 ? (
                    <img
                      src={CrossEye}
                      alt="React "
                      className="passwordIcon2"
                    />
                  ) : (
                    <img src={Eye} alt="React " className="passwordIcon2" />
                  )}
                </div>

                {passwordMatched && (
                  <div className="invalid-feedback">
                    <p className="invalid-feedbackMsg">
                      {t("Passwordsdonotmatch.label")}
                    </p>
                  </div>
                )}

                
              </div>
            </div>

            {passwordReset == "true" ? (
              <button
              type="submit"
                className="logIn-firstTimeBtn"
              >
                {t("ResetPassword.label")}
              </button>
            ) : (
              <button
                type="submit"
                className="logIn-firstTimeBtn"
              >
                {t("Confirm.label")}
              </button>
            )}

            {/* {passwordReset == "true"
              ? t("ResetPassword.label")
              : t("createYourPassword.label")}
              ResetPassword.label */}
          </form>

          <div className="returnLogin">
            <Link to="/login">{t("returnTOlogin.label")}</Link>
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
          </div>
        </div>
        </div>
      <Footer />
    </>
  );
};

export { LoginCreatePassword as LoginCreatePassword };
