import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { productActions } from "../../../_actions/product.actions";
import { REACT_APP_BASE_URL } from "../../../constant";
import Edit from "../../../images/Edit.svg";
import SearchImage from "../../../images/Search.png";
import CrossImage from "../../../images/cross-black.png";
import userImage from "../../../images/user-green.png";
import "./CustomerInfo.css";

const CustomerInfo = (props) => {
  const [userNote, setUserNote] = React.useState("");
  const lancode = localStorage.getItem("lancode");
  const userData = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : undefined;
  const [loading, setLoading] = useState(false);
  const [searchInfoModal, setSearchInfoModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const UserListData = useSelector((state) => state.product.UserList);
  const userAddressSaveFromCheckout = useSelector((state) => state.product.userAddressSaveFromCheckout);
  const isSalesUserList = useSelector((state) => state.product.isSalesUserList);
  const store = userData ? userData.user.stores[0].storeCode : "";
  const orderdiscardList = useSelector((state) => state.order.orderdiscardList);

  const orderDetails = useSelector((state) => state.createOrder.orderDetails);

  const getOrderItemsByOrderList = useSelector(

    (state) => state.order.getOrderItemsByOrderList

  );
  const orderSubmitDraftList = useSelector(

    (state) => state.order.orderSubmitDraftList

  );
  const orderSubmitConfirmList = useSelector(

    (state) => state.order.orderSubmitConfirmList

  );
  const removeOrderItemsList = useSelector(

    (state) => state.order.removeOrderItemsList

  );

  const [usersListData, setUsersListData] = useState([]);

  const [showCustomerInfo, setShowCustomerInfo] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerValue, updateCustomerValue] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerChannel, setCustomerChannel] = useState("");
  const [customerNote, setCustomerNote] = useState("");

  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingAddress3, setShippingAddress3] = useState("");
  const [defaultShippingAddress, setDefaultShippingAddress] = useState("");

  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [address1, setAddress1] = useState("");
  const [province1, setProvince1] = useState("");
  const [district1, setDistrict1] = useState("");
  const [subDistrict1, setSubDistrict1] = useState("");
  const [postalCode1, setPostalCode1] = useState("");

  const [address2, setAddress2] = useState("");
  const [province2, setProvince2] = useState("");
  const [district2, setDistrict2] = useState("");
  const [subDistrict2, setSubDistrict2] = useState("");
  const [postalCode2, setPostalCode2] = useState("");

  const [address3, setAddress3] = useState("");
  const [province3, setProvince3] = useState("");
  const [district3, setDistrict3] = useState("");
  const [subDistrict3, setSubDistrict3] = useState("");
  const [postalCode3, setPostalCode3] = useState("");

  const [address4, setAddress4] = useState("");
  const [province4, setProvince4] = useState("");
  const [district4, setDistrict4] = useState("");
  const [subDistrict4, setSubDistrict4] = useState("");
  const [postalCode4, setPostalCode4] = useState("");

  const [address5, setAddress5] = useState("");
  const [province5, setProvince5] = useState("");
  const [district5, setDistrict5] = useState("");
  const [subDistrict5, setSubDistrict5] = useState("");
  const [postalCode5, setPostalCode5] = useState("");

  const [selectedAddress, setSelectedAddress] = useState("");
  // province
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedProvince2, setSelectedProvince2] = useState("");
  const [selectedProvince3, setSelectedProvince3] = useState("");
  const [selectedProvince4, setSelectedProvince4] = useState("");
  //
  // District
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedDistrict2, setSelectedDistrict2] = useState("");
  const [selectedDistrict3, setSelectedDistrict3] = useState("");
  const [selectedDistrict4, setSelectedDistrict4] = useState("");
  //
  // subDistrict
  const [selectedSubDistrict, setSelectedSubDistrict] = useState("");
  const [selectedSubDistrict2, setSelectedSubDistrict2] = useState("");
  const [selectedSubDistrict3, setSelectedSubDistrict3] = useState("");
  const [selectedSubDistrict4, setSelectedSubDistrict4] = useState("");
  //
  // postal Code
  const [selectedPostalCode, setSelectedPostalCode] = useState("");
  const [selectedPostalCode2, setSelectedPostalCode2] = useState("");
  const [selectedPostalCode3, setSelectedPostalCode3] = useState("");
  const [selectedPostalCode4, setSelectedPostalCode4] = useState("");
  //

  const [note, setNote] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [note4, setNote4] = useState("");
  const [note5, setNote5] = useState("");
  const [selectedAddressId, setSelectedAddressId] = useState("");

  const [editFormFirst, setShowEditFormFirst] = useState(false);
  const [editFormSecond, setShowEditFormSecond] = useState(false);
  const [editFormThired, setShowEditFormThired] = useState(false);
  const [editNewAddressForm, setShowNewAddressForm] = useState(false);

  const [isShippingAddress, setIsShippingAddress] = useState(false);
  // const [isCheckedForadd1, setIsCheckedForadd1] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValid1, setIsValid1] = useState(false);

  // 
  const [isCheckedForadd1, setIsCheckedForadd1] = useState(false);
  const [isCheckedForadd2, setIsCheckedForadd2] = useState(false);
  const [isCheckedForadd4, setIsCheckedForadd4] = useState(false);
  const [isCheckedForadd3, setIsCheckedForadd3] = useState(false);
  const [isCheckedForadd5, setIsCheckedForadd5] = useState(false);

  const [newCustomerForm, setNewCustomerForm] = useState(false);

  const [orderDetail, setOrderDetail] = React.useState([]);

  const [showDefaultCustomerInfo, setShowDefaultCustomerInfo] = useState(false);

  const [customerAddressId, setCustomerAddressId] = useState(false);

  const [userAddressId0, setUserAddressId0] = useState("");
  const [userAddressId1, setUserAddressId1] = useState("");
  const [userAddressId2, setUserAddressId2] = useState("");

  const [userId, setUserId] = useState("");
  const [userDetail, setUserDetails] = useState([]);
  const [updateUserDetails, setUpdateUserDetails] = useState(false);
  const [callAfterUpdate, setCallAfterUpdate] = useState(false);

  useEffect(() => {
    if (!userData) {
      history.push("/login");
    }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      dispatch(productActions.salesUserListAction({}));
    }
  }, []);

  useEffect(() => {
    if (!userData) {
      history.push("/login");
    }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      dispatch(productActions.salesUserListAction({}));
    }
  }, [updateUserDetails]);

  React.useEffect(() => {
    if (orderDetails && orderDetails.data) {
      // setOrderDetail(orderDetails?.data)



      if (orderDetails?.data.userfirstnameen === "New Customer") {
        setCustomerPhoneNumber(orderDetails?.data.newcustomerphone);
        props.setProductInfoUserPhone(orderDetails?.data.newcustomerphone);
        setCustomerChannel(orderDetails?.data.userchannel);
        props.setProductInfoUserChannel(orderDetails?.data.userchannel);
        // props.getNewCustomerStatus(true);
      }

      if (orderDetails?.data.userfirstnameen !== "New Customer") {
        setCustomerPhoneNumber(orderDetails?.data.userphone);
        props.setProductInfoUserPhone(orderDetails?.data.userphone);
        setCustomerChannel(orderDetails?.data.userchannel);
        props.setProductInfoUserChannel(orderDetails?.data.userchannel);
        // props.getNewCustomerStatus(true);
      }

    } else {
      setOrderDetail([])
    }

  }, [orderDetails])

  useEffect(() => {
    setShowDefaultCustomerInfo(true);
    getUserDetails(props.defaultCustomerId);
  }, []);

  useEffect(() => {
    getUserDetails(props.defaultCustomerId);
  }, [updateUserDetails]);

  useEffect(() => {
    // setShowDefaultCustomerInfo(true);
    getUserDetails(props.defaultCustomerId);
    setCustomerNote(props.userNoteForCheckout)
  }, [props.defaultCustomerId]);

  useEffect(() => {
    // if (!userData) {
    //   history.push("/login");
    // }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      dispatch(productActions.salesUserListAction({}));
    }
  }, [dataSaved]);

  useEffect(() => {
    getProvinceData("province", null);
  }, []);

  useEffect(() => {
    selectedCustomer;
  }, [selectedCustomer]);


  // useEffect(() => {
  //   setUsersListData(UserListData);

  //   //  handleSelect(selectedCustomer);      
  // }, [UserListData]); 

  useEffect(() => {
    if (UserListData?.data && customerValue == true) {
      const customerSelected = UserListData.data.filter(item => item.customerCode === selectedCustomer.customerCode)
      const customerObj = { ...customerSelected[0] }
      handleSelect(customerObj);
      // setSelectedCustomer(customerSelected);
      // console.log('rree', customerObj)

    }


  }, [UserListData]);

  // console.log('ueee', UserListData);

  // useEffect(() => {            
  //    handleSelect(selectedCustomer);      
  // }, [selectedCustomer]); 

  useEffect(() => {
    // userDetail
    // const minDate = new Date();   
    if (showDefaultCustomerInfo) {
      if (userDetail && userDetail.data) {

        setCustomerName(userDetail?.data.firstNameEN + " " + userDetail?.data.lastNameEN);
        setCustomerId(userDetail?.data.customerCode);
        // setCustomerPhoneNumber(userDetail?.data.phone);
        // setCustomerChannel(userDetail?.data.channel);  
        setUserId(userDetail?.data.userId);

        if (userDetail?.data.role === "New Customer") {
          // setCustomerPhoneNumber(userDetail?.data.phone);
          props.getNewCustomerStatus(true);

          const isValidate = true;
          props.getCustomerValues(userDetail?.data.userId, "", userDetail?.data.storeCode, "", isValidate);
        }

        if (userDetail && userDetail.data && userDetail?.data?.role !== "New Customer") {

          const isValidate = true;
          props.getCustomerValues(userDetail?.data.userId, props.addressId, userDetail?.data.storeCode, "", isValidate);
          userDetail?.data?.address.map((item, index) => {
            if (item.userAddressId == props.addressId) {
              const shippingAddress =
                item.addressTH +
                ", " +
                item.subDistrictTH +
                ", " +
                item.districtTH +
                ", " +
                item.provinceTH +
                " " +
                item.postalCodeTH;
              setDefaultShippingAddress(shippingAddress);
              setIsShippingAddress(item.isShippingAddress);
            }
            {
            }
          });
        }


        if (userDetail?.data?.role !== "New Customer") {
          const shipAddress1 =
            userDetail.data.address[0].addressTH +
            ", " +
            userDetail.data.address[0].subDistrictTH +
            ", " +
            userDetail.data.address[0].districtTH +
            ", " +
            userDetail.data.address[0].provinceTH +
            " " +
            userDetail.data.address[0].postalCodeTH;
          setShippingAddress1(shipAddress1);

          setUserAddressId0(userDetail.data.address[0].userAddressId)

          setIsCheckedForadd1(userDetail && userDetail.data && userDetail.data.address[0]?.isInvoiceAddress)


          if (userDetail?.data?.address.length > 1) {
            const shipAddress2 =
              userDetail.data.address[1].addressTH +
              ", " +
              userDetail.data.address[1].subDistrictTH +
              ", " +
              userDetail.data.address[1].districtTH +
              ", " +
              userDetail.data.address[1].provinceTH +
              " " +
              userDetail.data.address[1].postalCodeTH;

            setShippingAddress2(shipAddress2);
            setUserAddressId1(userDetail.data.address[1].userAddressId)

            setIsCheckedForadd2(userDetail && userDetail.data && userDetail.data.address[1]?.isInvoiceAddress)
          }

          if (userDetail?.data?.address.length > 2) {
            const shipAddress3 =
              userDetail.data.address[2].addressTH +
              ", " +
              userDetail.data.address[2].subDistrictTH +
              ", " +
              userDetail.data.address[2].districtTH +
              ", " +
              userDetail.data.address[2].provinceTH +
              " " +
              userDetail.data.address[2].postalCodeTH;

            setShippingAddress3(shipAddress3);
            setUserAddressId2(userDetail.data.address[2].userAddressId)

            setIsCheckedForadd3(userDetail && userDetail.data && userDetail.data.address[2]?.isInvoiceAddress)
          }

          if (userDetail?.data.address[0]) {
            setProvince1(userDetail.data.address[0].provinceTH);
            setAddress1(userDetail.data.address[0].addressTH);
            setDistrict1(userDetail.data.address[0].districtTH);
            setSubDistrict1(userDetail.data.address[0].subDistrictTH);
            setPostalCode1(userDetail.data.address[0].postalCodeTH);
          }

          if (userDetail?.data.address[1]) {
            setProvince2(userDetail.data.address[1].provinceTH);
            setAddress2(userDetail.data.address[1].addressTH);
            setDistrict2(userDetail.data.address[1].districtTH);
            setSubDistrict2(userDetail.data.address[1].subDistrictTH);
            setPostalCode2(userDetail.data.address[1].postalCodeTH);
          }

          if (userDetail?.data.address[2]) {
            setProvince3(userDetail.data.address[2].provinceTH);
            setAddress3(userDetail.data.address[2].addressTH);
            setDistrict3(userDetail.data.address[2].districtTH);
            setSubDistrict3(userDetail.data.address[2].subDistrictTH);
            setPostalCode3(userDetail.data.address[2].postalCodeTH);
          }
        }
        props.getUserInfo(userDetail?.data.customerCode);
      }
    }


  }, [userDetail, updateUserDetails]);

  React.useEffect(() => {
    if (userAddressSaveFromCheckout?.data) {
      // setDataSaved(!dataSaved);
      setUpdateUserDetails(!updateUserDetails);
    }
  }, [userAddressSaveFromCheckout])

  // useState(() => {
  //   setIsValid(!address1 || !province1 || !district1 || !subDistrict1 || !postalCode1);
  // }, [address1, province1, district1, subDistrict1, postalCode1]);

  const validateForm = () => {
    if (address4 && province4 && district4) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const validateForm2 = () => {
    if (address5 && province5 && district5) {
      setIsValid1(true);
    } else {
      setIsValid1(false);
    }
  };

  const showCusotmerInforform = () => {
    setShowCustomerInfo(true);
  };

  const saveChanges = () => {
    props.isAddressUpdated()
    // setShowCustomerInfo(false);
    const isValidate = true;
    // setEditPersonalInfo(false);
    let storeCode;
    let userAddressId;
    let customerCode;
    if (selectedCustomer && selectedCustomer.storeCode && !showDefaultCustomerInfo) {
      storeCode = selectedCustomer.storeCode
      userAddressId = selectedCustomer.address[0].userAddressId;
      customerCode = selectedCustomer.customerCode;
    }

    if (userDetail && userDetail.data && showDefaultCustomerInfo) {
      storeCode = userDetail.data.storeCode;
      userAddressId = userDetail.data.address[0].userAddressId
      customerCode = userDetail.data.customerCode
    }

    props.getCustomerValues(userId, userAddressId0, "", storeCode, isValidate);

    let payload = {
      userAddressId: userAddressId,
      customerCode: customerCode,
      addressTH: address1,
      districtTH: district1,
      subDistrictTH: subDistrict1,
      provinceTH: province1,
      postalCodeTH: postalCode1,
      isInvoiceAddress: isCheckedForadd1,
      note: note,
    };

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    const shipAddress1 = address1 + ", " + subDistrict1 + ", " + district1 + ", " + province1 + " " + postalCode1;
    // console.log('spsp', shipAddress1)
    setShippingAddress1(shipAddress1);

    if (isPayloadValid) {
      // setAddressFormData(payload);
      setDataSaved(!dataSaved);
      // console.log("pyy", payload);
      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
      setShowEditFormFirst(false);
    }
  };

  const saveChangesForDefaultUser = () => {
    // setShowCustomerInfo(false);
    // console.log("postalcode", postalCode);
    const isValidate = true;

    // setEditPersonalInfo(false);
    props.getCustomerValues(customerAddressId, orderDetail.userId, orderDetail.storeCode, orderDetail.orderId, isValidate);

    let payload = {
      userAddressId: customerAddressId,
      customerCode: customerId,
      addressTH: address1,
      districtTH: district1,
      subDistrictTH: subDistrict1,
      provinceTH: province1,
      postalCodeTH: postalCode1
        ? postalCode1
        : postalData.postalCode.postalCodeTH,
      isInvoiceAddress: isCheckedForadd1,
      note: note,
    };

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    const shipAddress1 = address1 + ", " + subDistrict1 + ", " + district1 + ", " + province1 + " " + postalCode1;
    // console.log('spsp', shipAddress1)
    setShippingAddress1(shipAddress1);

    if (isPayloadValid) {
      // setAddressFormData(payload);
      setDataSaved(!dataSaved);
      // console.log("pyy", payload);
      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
      setShowEditFormFirst(false);
    }
  };

  const saveForm2 = () => {
    props.isAddressUpdated()
    // console.log("postalcode", postalCode);
    const isValidate = true;
    // setEditPersonalInfo(false);
    let storeCode;
    let userAddressId;
    let customerCode;
    if (selectedCustomer && selectedCustomer.storeCode && !showDefaultCustomerInfo) {
      storeCode = selectedCustomer.storeCode
      userAddressId = selectedCustomer.address[1].userAddressId;
      customerCode = selectedCustomer.customerCode;
    }

    if (userDetail && userDetail.data && showDefaultCustomerInfo) {
      storeCode = userDetail.data.storeCode;
      userAddressId = userDetail.data.address[1].userAddressId
      customerCode = userDetail.data.customerCode
    }

    props.getCustomerValues(userId, userAddressId1, "", storeCode, isValidate);

    let payload = {
      userAddressId: userAddressId,
      customerCode: customerCode,
      addressTH: address2,
      districtTH: district2,
      subDistrictTH: subDistrict2,
      provinceTH: province2,
      postalCodeTH: postalCode2,
      isInvoiceAddress: isCheckedForadd2,
      note: note2,
    };

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    const shipAddress2 = address2 + ", " + subDistrict2 + ", " + district2 + ", " + province2 + " " + postalCode2;
    // console.log('spsp', shipAddress2)
    setShippingAddress2(shipAddress2);

    if (isPayloadValid) {
      // setAddressFormData(payload);
      setDataSaved(!dataSaved);
      // console.log("pyy", payload);
      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
      setShowEditFormSecond(false);
    }

  };

  const saveForm3 = () => {
    props.isAddressUpdated()
    // setShowCustomerInfo(false);
    // console.log("postalcode", postalCode);
    const isValidate = true;
    let storeCode;
    let userAddressId;
    let customerCode;
    if (selectedCustomer && selectedCustomer.storeCode && !showDefaultCustomerInfo) {
      storeCode = selectedCustomer.storeCode
      userAddressId = selectedCustomer.address[2].userAddressId;
      customerCode = selectedCustomer.customerCode;
    }

    if (userDetail && userDetail.data && showDefaultCustomerInfo) {
      storeCode = userDetail.data.storeCode;
      userAddressId = userDetail.data.address[2].userAddressId
      customerCode = userDetail.data.customerCode
    }
    props.getCustomerValues(userId, userAddressId2, "", storeCode, isValidate);
    // setEditPersonalInfo(false);

    let payload = {
      userAddressId: userAddressId,
      customerCode: customerCode,
      addressTH: address3,
      districtTH: district3,
      subDistrictTH: subDistrict3,
      provinceTH: province3,
      postalCodeTH: postalCode3,
      isInvoiceAddress: isCheckedForadd3,
      note: note3,
    };

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    const shipAddress3 = address3 + ", " + subDistrict3 + ", " + district3 + ", " + province3 + " " + postalCode3;
    // console.log('spsp', shipAddress2)
    setShippingAddress3(shipAddress3);

    if (isPayloadValid) {
      // setAddressFormData(payload);
      setDataSaved(!dataSaved);
      // console.log("pyy", payload);
      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
      setShowEditFormThired(false);
    }

  };

  const saveAddNewAddress = () => {
    props.isAddressUpdated()
    // setShowCustomerInfo(false);


    // setEditPersonalInfo(false);
    const isValidate = true;
    props.getCustomerValues(userId, userAddressId0, "", userDetail.data.storeCode, isValidate);

    let payload

    if (userDetail?.data.role === "New Customer") {
      payload = {
        customerCode: "null",
        addressTH: address4,
        districtTH: district4,
        subDistrictTH: subDistrict4,
        provinceTH: province4,
        postalCodeTH: postalCode4,
        isInvoiceAddress: isCheckedForadd4,
        note: note4,
      }
    }

    if (userDetail?.data.role !== "New Customer") {
      let customerCode;
      if (showDefaultCustomerInfo) {
        customerCode = userDetail.data.customerCode
      }
      if (!showDefaultCustomerInfo) {
        customerCode = selectedCustomer.customerCode
      }
      payload = {
        customerCode: customerCode,
        addressTH: address4,
        districtTH: district4,
        subDistrictTH: subDistrict4,
        provinceTH: province4,
        postalCodeTH: postalCode4
          ? postalCode4
          : postalData.postalCode.postalCodeTH,
        isInvoiceAddress: isCheckedForadd1,
        note: note4,
      }
    }

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    // const shipAddress3 = address4 + ", " + subDistrict4 + ", " + district4 + ", " + province4 + " " + postalCode4;
    //     // console.log('spsp', shipAddress2)
    // setShippingAddress4(shipAddress3);

    if (isPayloadValid) {
      // setAddressFormData(payload);
      setDataSaved(!dataSaved);
      // console.log("pyy", payload);
      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
      // if(!showDefaultCustomerInfo) {                
      //   if(userAddressSaveFromCheckout !== null) {
      //     handleSelect(selectedCustomer)  
      //   }                     
      //   setAddress4(""); setProvince4(""); setDistrict4(""); setSubDistrict4(""); setPostalCode4("");
      //   setNote4("");
      // }      
      setShowNewAddressForm(false);
      setUpdateUserDetails(!updateUserDetails);
      setAddress4(""); setProvince4(""); setDistrict4(""); setSubDistrict4(""); setPostalCode4("");
      setNote4("");
    }

  };

  const saveAddNewAddressForNewCustomer = () => {
    props.isAddressUpdated()
    // setShowCustomerInfo(false);
    // console.log("postalcode", postalCode);    
    // setEditPersonalInfo(false);
    const isValidate = true;

    props.getCustomerValues(userId, "", "", selectedCustomer.storeCode, isValidate);

    let payload = {
      customerCode: "null",
      addressTH: address5,
      districtTH: district5,
      subDistrictTH: subDistrict5,
      provinceTH: province5,
      postalCodeTH: postalCode5,
      isInvoiceAddress: isCheckedForadd5,
      note: note4,
    };

    // const isPayloadValid = Object.entries(payload)
    //   .filter(([key]) => key !== "note")
    //   .every((value) => value !== undefined && value !== "");

    // const shipAddress3 = address4 + ", " + subDistrict4 + ", " + district4 + ", " + province4 + " " + postalCode4;
    //     // console.log('spsp', shipAddress2)
    // setShippingAddress4(shipAddress3);

    // setAddressFormData(payload);
    setDataSaved(!dataSaved);
    // console.log("pyy", payload);
    dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
    setNewCustomerForm(false);
    setUpdateUserDetails(!updateUserDetails);
  }

  const cancelBtn = (type) => {
    if (type == "form1") {
      setShowEditFormFirst(false);
    }

    if (type == "form2") {
      setShowEditFormSecond(false);
    }

    if (type == "form3") {
      setShowEditFormThired(false);
    }

    if (type == "saveNewAddress") {
      setShowNewAddressForm(false);
    }

    if (type == "newAddressForNewCustomer") {
      setNewCustomerForm(false);
    }
    // 
    if (type == "forDefaultUser") {
      setShowEditFormFirst(false);
    }
  }

  const showNewAddressForm = () => {
    setShowNewAddressForm(true);

    setShowEditFormFirst(false);
    setShowEditFormSecond(false);
    setShowEditFormThired(false);
    setNewCustomerForm(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };



  const handleSelect = (customer) => {
    // console.log('cucu', customer);
    // setUpdateUserDetails(!updateUserDetails);
    setSelectedCustomer(customer);
    setSearchInfoModal(false);
    updateCustomerValue(true);
    setShowDefaultCustomerInfo(false);
    validateForm();

    setCustomerName(customer.firstNameEN + " " + customer.lastNameEN);
    setCustomerId(customer.customerCode);
    setCustomerPhoneNumber(customer.phone);
    setCustomerChannel(customer.channel);
    setUserId(customer.userId);

    props.getUserInfo(customer.customerCode);

    localStorage.setItem('userId', customer.userId);
    // localStorage.setItem('userAddressId', )

    if (customer.role === "New Customer") {
      props.getNewCustomerStatus(true);
    } else {
      props.getNewCustomerStatus(false);
    }

    if (customer.role !== "New Customer") {
      customer.address.map((item, index) => {
        if (item.isShippingAddress === props.addressId) {
          const shippingAddress =
            item.addressTH +
            ", " +
            item.subDistrictTH +
            ", " +
            item.districtTH +
            ", " +
            item.provinceTH +
            " " +
            item.postalCodeTH;
          setDefaultShippingAddress(shippingAddress);
          setIsShippingAddress(item.isShippingAddress);
        }
        {
        }
      });
    }

    if (customer.role !== "New Customer") {
      const shipAddress1 =
        customer.address[0].addressTH +
        ", " +
        customer.address[0].subDistrictTH +
        ", " +
        customer.address[0].districtTH +
        ", " +
        customer.address[0].provinceTH +
        " " +
        customer.address[0].postalCodeTH;
      setShippingAddress1(shipAddress1);

      setUserAddressId0(customer.address[0].userAddressId)

      const isValidate = true;
      props.getCustomerValues(customer.userId, customer.address[0].userAddressId, "", customer.storeCode, isValidate);


      if (customer.address.length > 1) {
        const shipAddress2 =
          customer.address[1].addressTH +
          ", " +
          customer.address[1].subDistrictTH +
          ", " +
          customer.address[1].districtTH +
          ", " +
          customer.address[1].provinceTH +
          " " +
          customer.address[1].postalCodeTH;

        setShippingAddress2(shipAddress2);
        setUserAddressId1(customer.address[1].userAddressId)
      }

      if (customer.address.length > 2) {
        const shipAddress3 =
          customer.address[2].addressTH +
          ", " +
          customer.address[2].subDistrictTH +
          ", " +
          customer.address[2].districtTH +
          ", " +
          customer.address[2].provinceTH +
          " " +
          customer.address[2].postalCodeTH;

        setShippingAddress3(shipAddress3);
        setUserAddressId2(customer.address[2].userAddressId)
      }

      if (customer.address[0]) {
        setProvince1(customer.address[0].provinceTH);
        setAddress1(customer.address[0].addressTH);
        setDistrict1(customer.address[0].districtTH);
        setSubDistrict1(customer.address[0].subDistrictTH);
        setPostalCode1(customer.address[0].postalCodeTH);
        if (customer.address[0].note !== null) {
          setNote(customer.address[0].note);
        }
      }

      if (customer.address[1]) {
        setProvince2(customer.address[1].provinceTH);
        setAddress2(customer.address[1].addressTH);
        setDistrict2(customer.address[1].districtTH);
        setSubDistrict2(customer.address[1].subDistrictTH);
        setPostalCode2(customer.address[1].postalCodeTH);
        if (customer.address[1].note !== null) {
          setNote2(customer.address[1].note);
        }
      }

      if (customer.address[2]) {
        setProvince3(customer.address[2].provinceTH);
        setAddress3(customer.address[2].addressTH);
        setDistrict3(customer.address[2].districtTH);
        setSubDistrict3(customer.address[2].subDistrictTH);
        setPostalCode3(customer.address[2].postalCodeTH);
        if (customer.address[2].note !== null) {
          setNote3(customer.address[2].note);
        }
      }
    }

  };

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    // Update the state for the input field being changed
    if (name === "customerName") setCustomerName(value);
    if (name === "customerId") setCustomerId(value);
    if (name === "customerPhoneNumber") {
      setCustomerPhoneNumber(value);
      props.setProductInfoUserPhone(value);
    }
    if (name === "customerChannel") {
      setCustomerChannel(value);
      props.setProductInfoUserChannel(value);
    }
    if (name === "customerNote") {
      setCustomerNote(value);
      props.setProductInfoUserNote(value)
    }

    if (name === "address1") setAddress1(value);
    if (name === "address2") setAddress2(value);
    if (name === "address3") setAddress3(value);
    if (name === "address4") setAddress4(value);
    if (name === "address5") setAddress5(value);
    //
    if (name === "postalCode1") setPostalCode(value);
    if (name === "postalCode2") setPostalCode2(value);
    if (name === "postalCode3") setPostalCode3(value);
    if (name === "postalCode4") setPostalCode4(value);
    if (name === "postalCode5") setPostalCode5(value);
    //
    if (name === "note") setNote(value);
    if (name === "note2") setNote2(value);
    if (name === "note3") setNote3(value);
    if (name === "note4") setNote4(value);
    if (name === "note5") setNote5(value);

    if (name === "label1") setIsCheckedForadd1(event.target.checked);
    if (name === "label2") setIsCheckedForadd2(event.target.checked);
    if (name === "label3") setIsCheckedForadd3(event.target.checked);
    if (name === "label4") setIsCheckedForadd4(event.target.checked);
    if (name === "label5") setIsCheckedForadd5(event.target.checked);
  };

  const handleAreaChange = (type, event) => {

    const name = event.target.name;
    if (type === "district") {
      setSelectedProvince(event.target.value);
      if (name === "province1") setProvince1(event.target.value);
      if (name === "province2") setProvince2(event.target.value);
      if (name === "province3") setProvince3(event.target.value);
      if (name === "province4") setProvince4(event.target.value);
      if (name === "province5") setProvince5(event.target.value);
      getProvinceData(type, event.target.value);
    }

    if (type === "subdistrict") {
      if (name === "district1") setDistrict1(event.target.value);
      if (name === "district2") setDistrict2(event.target.value);
      if (name === "district3") setDistrict3(event.target.value);
      if (name === "district4") setDistrict4(event.target.value);
      if (name === "district5") setDistrict5(event.target.value);
      setSelectedDistrict(event.target.value);
      getProvinceData(type, event.target.value);
    }

    if (type === "postalCode") {
      setSelectedSubDistrict(event.target.value);
      if (name === "subDistrict1") setSubDistrict1(event.target.value);
      if (name === "subDistrict2") setSubDistrict2(event.target.value);
      if (name === "subDistrict3") setSubDistrict3(event.target.value);
      if (name === "subDistrict4") setSubDistrict4(event.target.value);
      if (name === "subDistrict5") setSubDistrict5(event.target.value);
      getProvinceData(type, event.target.value);
    }
  };


  const filteredCustomers = UserListData?.data?.filter((customer) => {
    const fullName = `${customer.firstNameEN} ${customer.lastNameEN}`;
    const customerCode = customer.customerCode;
    const phone = customer.phone
    return fullName.toLowerCase().includes(searchTerm.toLowerCase()) || customerCode.toLowerCase().includes(searchTerm.toLowerCase()) || phone.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleAddressChange = (event) => {
    setIsShippingAddress(true);
    // setDefaultShippingAddress(event.target.value);
  };

  const showFormFirst = (addressId) => {
    setShowEditFormFirst(true);
    setShowEditFormSecond(false);
    setShowEditFormThired(false);
    setNewCustomerForm(false);
    setShowNewAddressForm(false);
    setSelectedAddressId(addressId);
  };

  const showFormSecond = (addressId) => {
    setShowEditFormSecond(true);
    setShowEditFormFirst(false);
    setShowEditFormThired(false);
    setNewCustomerForm(false);
    setShowNewAddressForm(false);
    setSelectedAddressId(addressId);
  };

  const showFormThired = (addressId) => {
    setShowEditFormThired(true);
    setShowEditFormSecond(false);
    setShowEditFormFirst(false);
    setNewCustomerForm(false);
    setShowNewAddressForm(false);
    setSelectedAddressId(addressId);
  };

  const showNewCustomerForm = () => {
    setNewCustomerForm(true);
    setShowEditFormThired(false);
    setShowEditFormSecond(false);
    setShowEditFormFirst(false);
    setShowNewAddressForm(false);
  }

  const clearInput = () => {
    setSearchInfoModal(false);
    setSearchTerm("");
  }

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
      <span>
        {parts.filter(part => part).map((part, i) => (
          regex.test(part) ? <mark key={i} style={{ background: "none", color: "black", "padding": "0px" }}>{part}</mark> : <span key={i}>{part}</span>
        ))}
      </span>
    )
  }

  const getProvinceData = (type, selectedItem) => {

    let payload = {};
    if (type == "district") {
      payload = {
        provinceTH: selectedItem,
      };
    }
    if (type == "subdistrict") {
      payload = {
        provinceTH: selectedProvince,
        districtTH: selectedItem,
      };
    }
    if (type == "postalCode") {
      payload = {
        provinceTH: selectedProvince,
        districtTH: selectedDistrict,
        subDistrictTH: selectedItem,
      };
    }
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/postcode/",
      payload,
      requestOptions
    )
      .then((response) => {
        if (type == "province") {
          setProvince(response.data.data.province);
        } else if (type == "district") {
          setDistrict(response.data.data.district);
        } else if (type == "subdistrict") {
          setSubDistrict(response.data.data.subDistrict);
        } else if (type == "postalCode") {
          // temp[4].value = response.data.data.postalCode.postalCodeTH;
          setPostalCode(response.data.data.postalCode.postalCodeTH);
          setPostalCode1(response.data.data.postalCode.postalCodeTH);
          setPostalCode2(response.data.data.postalCode.postalCodeTH);
          setPostalCode3(response.data.data.postalCode.postalCodeTH);
          setPostalCode4(response.data.data.postalCode.postalCodeTH);
          setPostalCode5(response.data.data.postalCode.postalCodeTH);
        }
        // return response
      })
      .catch((error) => error);
  };

  const handleShippingAddress = (event) => {
    setDefaultShippingAddress(event.target.value);
    userDetail?.data?.address.map((item, index) => {
      const shippingAddress =
        item.addressTH +
        ", " +
        item.subDistrictTH +
        ", " +
        item.districtTH +
        ", " +
        item.provinceTH +
        " " +
        item.postalCodeTH;
      if (shippingAddress == event.target.value) {
        props.handleaddressIdChanges(item.userAddressId)
      }
    });
  }

  const getUserDetails = (userId) => {
    // /api/users/getDetails/1

    const token = localStorage.getItem("authorization");
    let payload = {};
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return Axios.get(
      REACT_APP_BASE_URL +
      "/api/users/getDetails/" +
      props.defaultCustomerId,
      requestOptions
    )
      .then((response) => {
        setUserDetails(response.data);

      })
      .catch((error) => error);

  };

  // console.log('ttt', selectedCustomer)

  return (
    <>
      <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 create-order-box-margin p-0">
        {/* Customer info form */}
        <div className="create-order-common-box-shadow mb-4">
          <div className="d-flex flex-row">
            <img src={userImage} className="create-order-top-heading-image" />
            <h6 className="create-order-box-shadow-top-heading">
              Customer Info
            </h6>
          </div>

          {/* customer-info-search-bar-section start from here */}
          <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 p-0 product-info-search-bar-input-main-container">
            <div className="product-info-search-bar-main-container">
              <input
                type="text"
                className="product-info-search-bar-input"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search customer by Name, ID, Phone number"
                onClick={() => setSearchInfoModal(true)}
              />
              <img
                src={SearchImage}
                className="product-info-search-bar-image"
              />
              {searchInfoModal ? (
                <img
                  src={CrossImage}
                  className="product-info-search-cross-image"
                  onClick={() => clearInput()}
                />
              ) : null}
            </div>
            {searchInfoModal ? (
              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 p-0">
                <div className="customer-info-search-bar-dropdown-main-container">
                  <div className="customer-info-search-bar-information-main-container">
                    {filteredCustomers &&
                      filteredCustomers.map((customer) => (
                        <h6
                          className="customer-info-search-bar-information-main-heading"
                          key={customer.userId}
                          onClick={() => handleSelect(customer)}
                        >
                          <Highlighted text={`${customer.firstNameEN} ${customer.lastNameEN} - ID${customer.customerCode} - ${customer.phone}`} highlight={searchTerm} />
                        </h6>
                      ))}
                    {/* <h6 className="customer-info-search-bar-information-main-heading">Sarita Sawangsri - ID14892430 - 0812345678</h6>                           */}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/* customer-info-search-bar-section end */}
          <div className="createOrderCard">
            <div className="row">
              <div className="col-lg-12">
                <div className="customer-information-common-gray-line" />
                <label>Customer name</label>
                <input
                  disabled
                  type="text"
                  name="customerName"
                  value={customerName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6">
                <label>Customer ID</label>
                <input
                  disabled
                  type="text"
                  name="customerId"
                  onChange={handleInputChange}
                  value={customerId}
                />
              </div>
              {(showDefaultCustomerInfo && userDetail && userDetail?.data && userDetail?.data.role !== "New Customer") && (
                <div className="col-lg-6">
                  <label>Phone number</label>
                  <input
                    disabled
                    type="text"
                    name="customerPhoneNumber"
                    onChange={handleInputChange}
                    value={customerPhoneNumber}
                  />
                </div>
              )}
              {(showDefaultCustomerInfo && userDetail && userDetail?.data && userDetail?.data.role !== "New Customer") && (
                <div className="col-lg-6">
                  <label>Channel</label>
                  <select
                    disabled
                    name="customerChannel"
                    id="options"
                    value={customerChannel}
                    onChange={handleInputChange}
                  >
                    <option selected={customerChannel === "Food Service" ? true : false} value="Food Service">Food Service</option>
                    <option selected={customerChannel === "traditional trade" ? true : false} value="traditional trade">Traditional Trade</option>
                    <option selected={customerChannel === "industry" ? true : false} value="industry">Industry</option>
                  </select>
                </div>
              )}

              {selectedCustomer && selectedCustomer.role !== "New Customer" && (
                <div className="col-lg-6">
                  <label>Phone number</label>
                  <input
                    disabled
                    type="text"
                    maxlength='10'
                    name="customerPhoneNumber"
                    onChange={handleInputChange}
                    value={customerPhoneNumber}
                  />
                </div>
              )}
              {(selectedCustomer && selectedCustomer.role === "New Customer" || userDetail && userDetail?.data && userDetail?.data.role === "New Customer") && (
                <div className="col-lg-6">
                  <label>Phone number</label>
                  <input
                    type="text"
                    maxlength='10'
                    name="customerPhoneNumber"
                    onChange={handleInputChange}
                    value={customerPhoneNumber}
                  />
                </div>
              )}
              {selectedCustomer && selectedCustomer.role !== "New Customer" && (
                <div className="col-lg-6">
                  <label>Channel</label>
                  <select
                    disabled
                    name="customerChannel"
                    id="options"
                    value={customerChannel}
                    onChange={handleInputChange}
                  >
                    <option selected={customerChannel === "Food Service" ? true : false} value="Food Service">Food Service</option>
                    <option selected={customerChannel === "traditional trade" ? true : false} value="traditional trade">Traditional Trade</option>
                    <option selected={customerChannel === "industry" ? true : false} value="industry">Industry</option>
                  </select>
                </div>
              )}
              {(selectedCustomer && selectedCustomer.role === "New Customer" || userDetail && userDetail?.data && userDetail?.data.role === "New Customer") && (
                <div className="col-lg-6">
                  <label>Channel</label>
                  <select
                    name="customerChannel"
                    id="options"
                    value={customerChannel}
                    onChange={handleInputChange}
                  >
                    <option selected={customerChannel === "Food Service" ? true : false} value="Food Service">Food Service</option>
                    <option selected={customerChannel === "traditional trade" ? true : false} value="traditional trade">Traditional Trade</option>
                    <option selected={customerChannel === "industry" ? true : false} value="industry">Industry</option>
                  </select>
                </div>
              )}
              <div className="col-lg-12">
                <label>Note (optional)</label>
                <textarea
                  name="customerNote"
                  maxlength='100'
                  onChange={handleInputChange}
                  value={customerNote}
                ></textarea>
                <h6 className='createOrderCard-editAddress-textarea-counts'>{customerNote?.length ? customerNote?.length : "0"}/100</h6>
              </div>

              {selectedCustomer && selectedCustomer.role !== "New Customer" && (
                <div className="col-lg-12">
                  <label>Shipping address</label>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={defaultShippingAddress}
                    onChange={(e) => { handleShippingAddress(e) }}
                  >
                    {(selectedCustomer && selectedCustomer.address.length > 0) && (
                      <>
                        <div className="row createOrderCardRadio m-0 mb-4">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            {/* <input
                            type="radio"
                            id="address1"
                            name="shipping_address"
                            // checked={!isShippingAddress}
                            value={shippingAddress1}
                            onChange={(event) =>
                              handleAddressChange(event, address)
                            }
                            className="m-0 cursor-pointer"
                          /> */}
                            <FormControlLabel
                              name="shipping_address"
                              value={shippingAddress1}
                              control={<Radio defaultChecked />}
                              label={
                                lancode === "en"
                                  ? shippingAddress1
                                  : shippingAddress1
                              }
                            />
                            {/* <label htmlFor="address1" className="ml-2">
                            {shippingAddress1}
                          </label> */}
                          </div>
                          <div
                            onClick={() => showFormFirst()}
                            className="col-lg-2 d-flex justify-content-end"
                          >
                            <img src={Edit} className="cursor-pointer" />
                          </div>
                        </div>
                        {editFormFirst && (
                          <div className="createOrderCard">
                            <div className="createOrderCard-editAddress mb-4">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address1"
                                    value={address1}
                                    onChange={(event) => {
                                      handleInputChange(event);
                                      validateForm();
                                    }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label>Province</label>

                                  <select
                                    name="province1"
                                    id="options"
                                    value={province1}
                                    onChange={(event) => {
                                      handleAreaChange("district", event);
                                      validateForm();
                                    }
                                    }
                                  >
                                    <option value={province1}>{province1}</option>
                                    {province &&
                                      province.map((item) => {
                                        return (
                                          <option value={item.provinceTH}>
                                            {item.provinceTH}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>District</label>
                                  <select
                                    name="district1"
                                    id="options"
                                    value={district1}
                                    onChange={(event) => {
                                      handleAreaChange("subdistrict", event);
                                      validateForm();
                                    }
                                    }
                                  >
                                    <option value="district1">{district1}</option>
                                    {district &&
                                      district.map((item) => (
                                        <option value={item.districtTH}>
                                          {item.districtTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Sub-district</label>
                                  <select
                                    name="subDistrict1"
                                    id="options"
                                    value={subDistrict1}
                                    onChange={(event) => {
                                      handleAreaChange("postalCode", event);
                                      validateForm();
                                    }
                                    }
                                  >
                                    <option value="subDistrict1">
                                      {subDistrict1}
                                    </option>
                                    {subDistrict &&
                                      subDistrict.map((item) => (
                                        <option
                                          selected={subDistrict1}
                                          value={item.subDistrictTH}
                                        >
                                          {item.subDistrictTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Postal code</label>
                                  <input
                                    type="text"
                                    value={postalCode1}
                                    name="postalCode1"
                                    onChange={(event) => { handleInputChange(event); validateForm(); }

                                    }
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <label>Note (optional)</label>
                                  <textarea
                                    value={note}
                                    maxlength='100'
                                    onChange={(event) => handleInputChange(event)}
                                    name="note"
                                  ></textarea>
                                  <h6 className='createOrderCard-editAddress-textarea-counts'>{note.length ? note.length : "0"}/100</h6>

                                </div>
                                <div className="col-lg-12">
                                  <input type="checkbox" checked={isCheckedForadd1} name="label1" className="m-0 mr-2"
                                    onChange={(event) => handleInputChange(event)} />
                                  <label>Use shipping address for invoice</label>
                                </div>
                                <div className="col-lg-12 d-flex align-items-center justify-content-end">
                                  <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("form1")}>Cancel</button>
                                  <button className="createOrderCard-editAddress-save" onClick={saveChanges}>Use this address</button>
                                  {/* {isValid && (
                                
                              )}                            
                              {!isValid && (
                                  <button  className="createOrderCard-editAddress-save-disbled">Use this address</button>
                                )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {(selectedCustomer && selectedCustomer.address.length > 1) && (
                      <>
                        <div className="row createOrderCardRadio m-0 mb-4">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            {/* <input
                            type="radio"
                            id="address1"
                            name="shipping_address"
                            // checked={!isShippingAddress}
                            value={shippingAddress2}
                            onChange={(event) =>
                              handleAddressChange(event, address)
                            }
                            className="m-0 cursor-pointer"
                          /> */}

                            <FormControlLabel
                              name="shipping_address"
                              value={shippingAddress2}
                              control={<Radio defaultChecked />}
                              label={
                                lancode === "en"
                                  ? shippingAddress2
                                  : shippingAddress2
                              }
                            />

                            {/* <label htmlFor="address1" className="ml-2">
                            {shippingAddress2}
                          </label> */}
                          </div>
                          <div
                            onClick={() => showFormSecond()}
                            className="col-lg-2 d-flex justify-content-end"
                          >
                            <img src={Edit} className="cursor-pointer" />
                          </div>
                        </div>

                        {editFormSecond && (
                          <div className="createOrderCard">
                            <div className="createOrderCard-editAddress mb-4">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address2"
                                    value={address2}
                                    onChange={(event) => { handleInputChange(event); validateForm(); }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label>Province</label>
                                  <select
                                    name="province2"
                                    id="options"
                                    value={province2}
                                    onChange={(event) => { handleAreaChange("district", event); validateForm(); }
                                    }
                                  >
                                    <option value={province2}>{province2}</option>
                                    {province &&
                                      province.map((item) => {
                                        return (
                                          <option value={item.provinceTH}>
                                            {item.provinceTH}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>District</label>
                                  <select
                                    name="district2"
                                    id="options"
                                    value={district2}
                                    onChange={(event) => { handleAreaChange("subdistrict", event); validateForm(); }
                                    }
                                  >
                                    <option value={district2}>{district2}</option>
                                    {district &&
                                      district.map((item) => (
                                        <option value={item.districtTH}>
                                          {item.districtTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Sub-district</label>
                                  <select
                                    name="subDistrict2"
                                    id="options"
                                    value={subDistrict2}
                                    onChange={(event) => { handleAreaChange("postalCode", event); validateForm(); }
                                    }
                                  >
                                    <option value={subDistrict2}>
                                      {subDistrict2}
                                    </option>
                                    {subDistrict &&
                                      subDistrict.map((item) => (
                                        <option value={item.subDistrictTH}>
                                          {item.subDistrictTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Postal Code</label>
                                  <input
                                    type="text"
                                    value={postalCode2}
                                    name="postalCode2"
                                    onChange={(event) => handleInputChange(event)}
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <label>Note (optional)</label>
                                  <textarea
                                    value={note2}
                                    maxlength='100'
                                    onChange={(event) => handleInputChange(event)}
                                    name="note2"
                                  ></textarea>
                                  <h6 className='createOrderCard-editAddress-textarea-counts'>{note2.length ? note2.length : "0"}/100</h6>
                                </div>
                                <div className="col-lg-12">
                                  <input type="checkbox" checked={isCheckedForadd2} name="label2" className="m-0 mr-2"
                                    onChange={(event) => handleInputChange(event)} />
                                  <label>Use shipping address for invoice</label>
                                </div>
                                <div className="col-lg-12 d-flex align-items-center justify-content-end">
                                  <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("form2")}>Cancel</button>
                                  <button className="createOrderCard-editAddress-save" onClick={saveForm2}>Use this address</button>
                                  {/* {isValid && (
                                
                              )}              
                              {!isValid && (
                                <button  className="createOrderCard-editAddress-save-disbled">Use this address</button>
                              )}               */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {(selectedCustomer && selectedCustomer.address.length > 2) && (
                      <>
                        <div className="row createOrderCardRadio m-0 mb-4">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            {/* <input
                            type="radio"
                            id="address1"
                            name="shipping_address"
                            // checked={!isShippingAddress}
                            value={shippingAddress3}
                            onChange={(event) =>
                              handleAddressChange(event, address)
                            }
                            className="m-0 cursor-pointer"
                          /> */}
                            <FormControlLabel
                              name="shipping_address"
                              value={shippingAddress3}
                              control={<Radio defaultChecked />}
                              label={
                                lancode === "en"
                                  ? shippingAddress3
                                  : shippingAddress3
                              }
                            />
                            {/* <label htmlFor="address1" className="ml-2">
                            {shippingAddress3}
                          </label> */}
                          </div>
                          <div
                            onClick={() => showFormThired()}
                            className="col-lg-2 d-flex justify-content-end"
                          >
                            <img src={Edit} className="cursor-pointer" />
                          </div>
                        </div>

                        {editFormThired && (
                          <div className="createOrderCard">
                            <div className="createOrderCard-editAddress mb-4">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address3"
                                    value={address3}
                                    onChange={(event) => { handleInputChange(event); validateForm(); }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label>Province</label>
                                  <select
                                    name="province3"
                                    id="options"
                                    value={province3}
                                    onChange={(event) => { handleAreaChange("district", event); validateForm(); }
                                    }
                                  >
                                    <option value={province3}>{province3}</option>
                                    {province &&
                                      province.map((item) => {
                                        return (
                                          <option value={item.provinceTH}>
                                            {item.provinceTH}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>District</label>
                                  <select
                                    name="district3"
                                    id="options"
                                    value={district3}
                                    onChange={(event) => { handleAreaChange("subdistrict", event); validateForm(); }
                                    }
                                  >
                                    <option value={district3}>{district3}</option>
                                    {district &&
                                      district.map((item) => (
                                        <option value={item.districtTH}>
                                          {item.districtTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Sub-district</label>
                                  <select
                                    name="subDistrict3"
                                    id="options"
                                    value={subDistrict3}
                                    onChange={(event) => { handleAreaChange("postalCode", event); validateForm() }
                                    }
                                  >
                                    <option value={subDistrict3}>
                                      {subDistrict3}
                                    </option>
                                    {subDistrict &&
                                      subDistrict.map((item) => (
                                        <option value={item.subDistrictTH}>
                                          {item.subDistrictTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Postal Code</label>
                                  <input
                                    type="text"
                                    value={postalCode3}
                                    name="postalCode3"
                                    onChange={(event) => handleInputChange(event)}
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <label>Note (optional)</label>
                                  <textarea
                                    value={note3}
                                    maxlength='100'
                                    onChange={(event) => handleInputChange(event)}
                                    name="note3"
                                  ></textarea>
                                  <h6 className='createOrderCard-editAddress-textarea-counts'>{note3.length ? note3.length : "0"}/100</h6>

                                </div>
                                <div className="col-lg-12">
                                  <input type="checkbox" checked={isCheckedForadd3} name="label3" onChange={(event) => handleInputChange(event)} className="m-0 mr-2" />
                                  <label>Use shipping address for invoice</label>
                                </div>
                                <div className="col-lg-12 d-flex align-items-center justify-content-end">
                                  <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("form3")}>Cancel</button>
                                  <button className="createOrderCard-editAddress-save" onClick={saveForm3}>Use this address</button>
                                  {/* {isValid && (
                                
                              )}                            
                              {!isValid && (
                                <button  className="createOrderCard-editAddress-save-disbled">Use this address</button>
                              )}  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {(selectedCustomer && selectedCustomer.address.length < 3) && (
                      <>
                        <div
                          // className="row createOrderCardRadio m-0 mb-4"
                          className={`${"" == defaultShippingAddress ? 'bulkUploadCardRadio-focus' : ''} row createOrderCardRadio m-0 mb-4`}

                        >
                          <div
                            onClick={() => showNewAddressForm()}
                            className="col-lg-10 d-flex align-items-center justify-content-start"
                          >
                            <input
                              type="radio"
                              id="address1"
                              name="shipping_address"
                              value=""
                              onChange={(event) => {
                                handleAddressChange(event, address);
                              }}
                              className="m-0 cursor-pointer"
                            />
                            <label htmlFor="address1" className="ml-2">
                              Add new address
                            </label>
                          </div>
                          {/* <div
                              
                              className="col-lg-2 d-flex justify-content-end"
                            >
                              <img src={Edit} className="cursor-pointer" />
                            </div> */}
                        </div>
                        {/*  */}
                        {editNewAddressForm && (
                          <div className="createOrderCard">
                            <div className="createOrderCard-editAddress">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address4"
                                    value={address4}
                                    onChange={(event) => { handleInputChange(event); validateForm(); }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label>Province</label>
                                  <select
                                    name="province4"
                                    id="options"
                                    value={province4}
                                    onChange={(event) => { handleAreaChange("district", event); validateForm(); }
                                    }
                                  >
                                    <option value=""></option>
                                    {province &&
                                      province.map((item) => {
                                        return (
                                          <option value={item.provinceTH}>
                                            {item.provinceTH}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>District</label>
                                  <select
                                    name="district4"
                                    id="options"
                                    value={district4}
                                    onChange={(event) => { handleAreaChange("subdistrict", event); validateForm(); }
                                    }
                                  >
                                    <option value=""></option>
                                    {district &&
                                      district.map((item) => (
                                        <option value={item.districtTH}>
                                          {item.districtTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Sub-district</label>
                                  <select
                                    name="subDistrict4"
                                    id="options"
                                    value={subDistrict4}
                                    onChange={(event) => { handleAreaChange("postalCode", event); validateForm(); }
                                    }
                                  >
                                    <option value=""></option>
                                    {subDistrict &&
                                      subDistrict.map((item) => (
                                        <option value={item.subDistrictTH}>
                                          {item.subDistrictTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Postal code</label>
                                  <input
                                    type="text"
                                    value={postalCode4}
                                    name="postalCode4"
                                    onChange={(event) => { handleInputChange(event); validateForm(); }}
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <label>Note (optional)</label>
                                  <textarea
                                    value={note4}
                                    maxlength='100'
                                    onChange={(event) => handleInputChange(event)}
                                    name="note4"
                                  ></textarea>
                                  <h6 className='createOrderCard-editAddress-textarea-counts'>{note4.length ? note4.length : "0"}/100</h6>

                                </div>
                                <div className="col-lg-12">
                                  <input type="checkbox" checked={isCheckedForadd4} name="label4" onChange={(event) => handleInputChange(event)} className="m-0 mr-2" />
                                  <label>Use shipping address for invoice</label>
                                </div>
                                <div className="col-lg-12 d-flex align-items-center justify-content-end">
                                  <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("saveNewAddress")}>Cancel</button>
                                  {isValid && (
                                    <button className="createOrderCard-editAddress-save" onClick={saveAddNewAddress}>Use this address</button>
                                  )}
                                  {!isValid && (
                                    <button className="createOrderCard-editAddress-save-disbled">Use this address</button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </RadioGroup>
                </div>
              )}
              {selectedCustomer && selectedCustomer.role === "New Customer" && (
                <div className="col-lg-12">
                  <label>Shipping address</label>
                  {/*  */}
                  <div className="row createOrderCardRadio m-0 mb-4">
                    <div
                      onClick={() => showNewCustomerForm()}
                      className="col-lg-10 d-flex align-items-center justify-content-start"
                    >
                      <input
                        type="radio"
                        id="address1"
                        name="shipping_address"
                        value=""
                        onChange={(event) =>
                          handleAddressChange(event, address)
                        }
                        className="m-0 cursor-pointer"
                      />
                      <label htmlFor="address1" className="ml-2">
                        Add New Address
                      </label>
                    </div>
                    {/* <div
                            
                            className="col-lg-2 d-flex justify-content-end"
                          >
                            <img src={Edit} className="cursor-pointer" />
                          </div> */}
                  </div>
                  {/*  */}
                  {newCustomerForm && (
                    <div className="createOrderCard">

                      <div className="createOrderCard-editAddress">
                        <div className="row">
                          <div className="col-lg-12">
                            <label>Address</label>
                            <input
                              type="text"
                              name="address5"
                              value={address5}
                              onChange={(event) => {
                                handleInputChange(event);
                                validateForm2();
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label>Province</label>
                            <select
                              name="province5"
                              id="options"
                              value={province5}
                              onChange={(event) => {
                                handleAreaChange("district", event)
                                validateForm2();
                              }
                              }
                            >
                              <option value=""> </option>
                              {province &&
                                province.map((item) => {
                                  return (
                                    <option value={item.provinceTH}>
                                      {item.provinceTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label>District</label>
                            <select
                              name="district5"
                              id="options"
                              value={district5}
                              onChange={(event) => { handleAreaChange("subdistrict", event); validateForm2(); }
                              }
                            >
                              {district &&
                                district.map((item) => (
                                  <option value={item.districtTH}>
                                    {item.districtTH}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label>Sub-district</label>
                            <select
                              name="subDistrict5"
                              id="options"
                              value={subDistrict5}
                              onChange={(event) => { handleAreaChange("postalCode", event); validateForm2(); }
                              }
                            >
                              {subDistrict &&
                                subDistrict.map((item) => (
                                  <option value={item.subDistrictTH}>
                                    {item.subDistrictTH}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label>Postal code</label>
                            <input
                              type="text"
                              value={postalCode5}
                              name="postalCode5"
                              onChange={(event) => { handleInputChange(event); validateForm2(); }}
                            />
                          </div>
                          <div className="col-lg-12">
                            <label>Note (optional)</label>
                            <textarea
                              value={note5}
                              maxlength='100'
                              onChange={(event) => handleInputChange(event)}
                              name="note5"
                            />
                            <h6 className='createOrderCard-editAddress-textarea-counts'>{note5.length ? note5.length : "0"}/100</h6>

                          </div>
                          <div className="col-lg-12">
                            <input type="checkbox" checked={isCheckedForadd5} name="label5" onChange={(event) => handleInputChange(event)} className="m-0 mr-2" />
                            <label>Use shipping address for invoice</label>
                          </div>
                          <div className="col-lg-12 d-flex align-items-center justify-content-end">
                            <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("newAddressForNewCustomer")}>Cancel</button>
                            {isValid1 && (
                              <button onClick={saveAddNewAddressForNewCustomer} className="createOrderCard-editAddress-save">Use this address</button>
                            )}
                            {!isValid1 && (
                              <button className="createOrderCard-editAddress-save-disbled">Use this address</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*  */}

              {/* {showDefaultCustomerInfo  && (
                <div className="col-lg-12">
                  <label>Shipping address</label>
                    <div className="row createOrderCardRadio m-0 mb-4">
                      <div className="col-lg-10 d-flex align-items-center justify-content-start">
                        <input
                          type="radio"
                          id="address1"
                          name="shipping_address"
                          checked={true}
                          value={defaultShippingAddress}
                          onChange={(event) =>
                            handleAddressChange(event, address)
                          }
                          className="m-0 cursor-pointer"
                        />
                        <label htmlFor="address1" className="ml-2">
                          {defaultShippingAddress}
                        </label>
                      </div>
                      <div
                        onClick={() => showFormFirst()}
                        className="col-lg-2 d-flex justify-content-end"
                      >
                        <img src={Edit} className="cursor-pointer" />
                      </div>
                    </div>
                    {editFormFirst && (
                      <div className="createOrderCard-editAddress">
                        <div className="createOrderCard">
                          <div className="row">
                            <div className="col-lg-12">
                              <label>Address</label>
                              <input
                                type="text"
                                name="address1"
                                value={address1}
                                onChange={(event) => {handleInputChange(event); validateForm()}}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label>Province</label>

                              <select
                                name="province1"
                                id="options"
                                value={province1}
                                onChange={(event) =>
                                  {handleAreaChange("district", event); validateForm()}
                                }
                              >
                                <option value={province1}>{province1}</option>
                                {province &&
                                  province.map((item) => {
                                    return (
                                      <option value={item.provinceTH}>
                                        {item.provinceTH}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="col-lg-6">
                              <label>District</label>
                              <select
                                name="district1"
                                id="options"
                                value={district1}
                                onChange={(event) =>
                                  {handleAreaChange("subdistrict", event); validateForm();}
                                }
                              >
                                <option value="district1">{district1}</option>
                                {district &&
                                  district.map((item) => (
                                    <option value={item.districtTH}>
                                      {item.districtTH}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-6">
                              <label>Sub-district</label>
                              <select
                                name="subDistrict1"
                                id="options"
                                value={subDistrict1}
                                onChange={(event) =>
                                  {handleAreaChange("postalCode", event); validateForm()}
                                }
                              >
                                <option value="subDistrict1">
                                  {subDistrict1}
                                </option>
                                {subDistrict &&
                                  subDistrict.map((item) => (
                                    <option
                                      selected={subDistrict1}
                                      value={item.subDistrictTH}
                                    >
                                      {item.subDistrictTH}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-6">
                              <label>Postal Code</label>
                              <input
                                type="text"
                                value={postalCode1}
                                name="postalCode1"
                                onChange={(event) => {handleInputChange(event); validateForm();}}
                              />
                            </div>
                            <div className="col-lg-12">
                              <label>Note (optional)</label>
                              <textarea
                                maxlength='100'
                                value={note}
                                onChange={(event) => handleInputChange(event)}
                                name="note"
                              ></textarea>
                              <h6 className='createOrderCard-editAddress-textarea-counts'>{note.length? note.length : "0"}/100</h6>

                            </div>
                            <div className="col-lg-12">
                              <input type="checkbox" name="label1" onChange={(event) => handleInputChange(event)} className="m-0 mr-2"/>
                              <label>Use shipping address for invoice</label>
                            </div>
                            <div className="col-lg-12 d-flex align-items-center justify-content-end">
                            <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("forDefaultUser")}>Cancel</button>
                            <button className="createOrderCard-editAddress-save" onClick={saveChangesForDefaultUser}>Use this address</button>                            
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  
                </div>
                
                  
                )} */}

              {showDefaultCustomerInfo && (
                <div className="col-lg-12">
                  <label>Shipping address</label>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={defaultShippingAddress}
                    onChange={(e) => { handleShippingAddress(e) }}
                  >
                    {userDetail && userDetail.data && userDetail.data.address.length > 0 && (
                      <>
                        <div
                          // className="row createOrderCardRadio m-0 mb-4"
                          className={`${shippingAddress1 == defaultShippingAddress ? 'bulkUploadCardRadio-focus' : ''} row createOrderCardRadio m-0 mb-4`}
                        >
                          <div
                            className=" col-lg-10 d-flex align-items-center justify-content-start"
                          // className={`${shippingAddress1==defaultShippingAddress?'bulkUploadCardRadio-focus':''} col-lg-10 d-flex align-items-center justify-content-start`}
                          >
                            {/* <input
                          type="radio"
                          id="address1"
                          name="shipping_address"
                          // checked={!isShippingAddress}
                          value={shippingAddress1}
                          onChange={(event) =>
                            handleAddressChange(event, address)
                          }
                          className="m-0 cursor-pointer"
                        /> */}
                            <FormControlLabel
                              name="shipping_address"
                              value={shippingAddress1}
                              control={<Radio defaultChecked />}
                              label={
                                lancode === "en"
                                  ? shippingAddress1
                                  : shippingAddress1
                              }
                            />
                            {/* <label htmlFor="address1" className="ml-2">
                          {shippingAddress1}
                        </label> */}
                          </div>
                          <div
                            onClick={() => showFormFirst()}
                            className="col-lg-2 d-flex justify-content-end"
                          >
                            <img src={Edit} className="cursor-pointer" />
                          </div>
                        </div>
                        {editFormFirst && (
                          <div className="createOrderCard">
                            <div className="createOrderCard-editAddress mb-4">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address1"
                                    value={address1}
                                    onChange={(event) => {
                                      handleInputChange(event);
                                      validateForm();
                                    }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label>Province</label>

                                  <select
                                    name="province1"
                                    id="options"
                                    value={province1}
                                    onChange={(event) => {
                                      handleAreaChange("district", event);
                                      validateForm();
                                    }
                                    }
                                  >
                                    <option value={province1}>{province1}</option>
                                    {province &&
                                      province.map((item) => {
                                        return (
                                          <option value={item.provinceTH}>
                                            {item.provinceTH}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>District</label>
                                  <select
                                    name="district1"
                                    id="options"
                                    value={district1}
                                    onChange={(event) => {
                                      handleAreaChange("subdistrict", event);
                                      validateForm();
                                    }
                                    }
                                  >
                                    <option value="district1">{district1}</option>
                                    {district &&
                                      district.map((item) => (
                                        <option value={item.districtTH}>
                                          {item.districtTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Sub-district</label>
                                  <select
                                    name="subDistrict1"
                                    id="options"
                                    value={subDistrict1}
                                    onChange={(event) => {
                                      handleAreaChange("postalCode", event);
                                      validateForm();
                                    }
                                    }
                                  >
                                    <option value="subDistrict1">
                                      {subDistrict1}
                                    </option>
                                    {subDistrict &&
                                      subDistrict.map((item) => (
                                        <option
                                          selected={subDistrict1}
                                          value={item.subDistrictTH}
                                        >
                                          {item.subDistrictTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Postal code</label>
                                  <input
                                    type="text"
                                    value={postalCode1}
                                    name="postalCode1"
                                    onChange={(event) => { handleInputChange(event); validateForm(); }

                                    }
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <label>Note (optional)</label>
                                  <textarea
                                    value={note}
                                    maxlength='100'
                                    onChange={(event) => handleInputChange(event)}
                                    name="note"
                                  ></textarea>
                                  <h6 className='createOrderCard-editAddress-textarea-counts'>{note.length ? note.length : "0"}/100</h6>

                                </div>
                                <div className="col-lg-12">
                                  <input type="checkbox" checked={isCheckedForadd1} name="label1" className="m-0 mr-2"
                                    onChange={(event) => handleInputChange(event)} />
                                  <label>Use shipping address for invoice</label>
                                </div>
                                <div className="col-lg-12 d-flex align-items-center justify-content-end">
                                  <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("form1")}>Cancel</button>
                                  <button className="createOrderCard-editAddress-save" onClick={saveChanges}>Use this address</button>
                                  {/* {isValid && (
                              
                            )}                            
                            {!isValid && (
                                <button  className="createOrderCard-editAddress-save-disbled">Use this address</button>
                              )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {userDetail && userDetail.data && userDetail.data.address.length > 1 && (
                      <>
                        <div
                          //  className="row createOrderCardRadio m-0 mb-4"
                          className={`${shippingAddress2 == defaultShippingAddress ? 'bulkUploadCardRadio-focus' : ''} row createOrderCardRadio m-0 mb-4`}
                        >
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            {/* <input
                          type="radio"
                          id="address1"
                          name="shipping_address"
                          // checked={!isShippingAddress}
                          value={shippingAddress2}
                          onChange={(event) =>
                            handleAddressChange(event, address)
                          }
                          className="m-0 cursor-pointer"
                        /> */}
                            <FormControlLabel
                              name="shipping_address"
                              value={shippingAddress2}
                              control={<Radio defaultChecked />}
                              label={
                                lancode === "en"
                                  ? shippingAddress2
                                  : shippingAddress2
                              }
                            />
                            {/* <label htmlFor="address1" className="ml-2">
                          {shippingAddress2}
                        </label> */}
                          </div>
                          <div
                            onClick={() => showFormSecond()}
                            className="col-lg-2 d-flex justify-content-end"
                          >
                            <img src={Edit} className="cursor-pointer" />
                          </div>
                        </div>

                        {editFormSecond && (
                          <div className="createOrderCard">
                            <div className="createOrderCard-editAddress mb-4">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address2"
                                    value={address2}
                                    onChange={(event) => { handleInputChange(event); validateForm(); }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label>Province</label>
                                  <select
                                    name="province2"
                                    id="options"
                                    value={province2}
                                    onChange={(event) => { handleAreaChange("district", event); validateForm(); }
                                    }
                                  >
                                    <option value={province2}>{province2}</option>
                                    {province &&
                                      province.map((item) => {
                                        return (
                                          <option value={item.provinceTH}>
                                            {item.provinceTH}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>District</label>
                                  <select
                                    name="district2"
                                    id="options"
                                    value={district2}
                                    onChange={(event) => { handleAreaChange("subdistrict", event); validateForm(); }
                                    }
                                  >
                                    <option value={district2}>{district2}</option>
                                    {district &&
                                      district.map((item) => (
                                        <option value={item.districtTH}>
                                          {item.districtTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Sub-district</label>
                                  <select
                                    name="subDistrict2"
                                    id="options"
                                    value={subDistrict2}
                                    onChange={(event) => { handleAreaChange("postalCode", event); validateForm(); }
                                    }
                                  >
                                    <option value={subDistrict2}>
                                      {subDistrict2}
                                    </option>
                                    {subDistrict &&
                                      subDistrict.map((item) => (
                                        <option value={item.subDistrictTH}>
                                          {item.subDistrictTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Postal Code</label>
                                  <input
                                    type="text"
                                    value={postalCode2}
                                    name="postalCode2"
                                    onChange={(event) => handleInputChange(event)}
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <label>Note (optional)</label>
                                  <textarea
                                    value={note2}
                                    maxlength='100'
                                    onChange={(event) => handleInputChange(event)}
                                    name="note2"
                                  ></textarea>
                                  <h6 className='createOrderCard-editAddress-textarea-counts'>{note2.length ? note2.length : "0"}/100</h6>
                                </div>
                                <div className="col-lg-12">
                                  <input type="checkbox" checked={isCheckedForadd2} name="label2" className="m-0 mr-2"
                                    onChange={(event) => handleInputChange(event)} />
                                  <label>Use shipping address for invoice</label>
                                </div>
                                <div className="col-lg-12 d-flex align-items-center justify-content-end">
                                  <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("form2")}>Cancel</button>
                                  <button className="createOrderCard-editAddress-save" onClick={saveForm2}>Use this address</button>
                                  {/* {isValid && (
                              
                            )}              
                            {!isValid && (
                              <button  className="createOrderCard-editAddress-save-disbled">Use this address</button>
                            )}               */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {userDetail && userDetail.data && userDetail.data.address.length > 2 && (
                      <>
                        <div
                          // className="row createOrderCardRadio m-0 mb-4"
                          className={`${shippingAddress3 == defaultShippingAddress ? 'bulkUploadCardRadio-focus' : ''} row createOrderCardRadio m-0 mb-4`}

                        >
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            {/* <input
                          type="radio"
                          id="address1"
                          name="shipping_address"
                          // checked={!isShippingAddress}
                          value={shippingAddress3}
                          onChange={(event) =>
                            handleAddressChange(event, address)
                          }
                          className="m-0 cursor-pointer"
                        /> */}

                            <FormControlLabel
                              name="shipping_address"
                              value={shippingAddress3}
                              control={<Radio defaultChecked />}
                              label={
                                lancode === "en"
                                  ? shippingAddress3
                                  : shippingAddress3
                              }
                            />

                            {/* <label htmlFor="address1" className="ml-2">
                          {shippingAddress3}
                        </label> */}
                          </div>
                          <div
                            onClick={() => showFormThired()}
                            className="col-lg-2 d-flex justify-content-end"
                          >
                            <img src={Edit} className="cursor-pointer" />
                          </div>
                        </div>

                        {editFormThired && (
                          <div className="createOrderCard">
                            <div className="createOrderCard-editAddress mb-4">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address3"
                                    value={address3}
                                    onChange={(event) => { handleInputChange(event); validateForm(); }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label>Province</label>
                                  <select
                                    name="province3"
                                    id="options"
                                    value={province3}
                                    onChange={(event) => { handleAreaChange("district", event); validateForm(); }
                                    }
                                  >
                                    <option value={province3}>{province3}</option>
                                    {province &&
                                      province.map((item) => {
                                        return (
                                          <option value={item.provinceTH}>
                                            {item.provinceTH}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>District</label>
                                  <select
                                    name="district3"
                                    id="options"
                                    value={district3}
                                    onChange={(event) => { handleAreaChange("subdistrict", event); validateForm(); }
                                    }
                                  >
                                    <option value={district3}>{district3}</option>
                                    {district &&
                                      district.map((item) => (
                                        <option value={item.districtTH}>
                                          {item.districtTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Sub-district</label>
                                  <select
                                    name="subDistrict3"
                                    id="options"
                                    value={subDistrict3}
                                    onChange={(event) => { handleAreaChange("postalCode", event); validateForm() }
                                    }
                                  >
                                    <option value={subDistrict3}>
                                      {subDistrict3}
                                    </option>
                                    {subDistrict &&
                                      subDistrict.map((item) => (
                                        <option value={item.subDistrictTH}>
                                          {item.subDistrictTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Postal Code</label>
                                  <input
                                    type="text"
                                    value={postalCode3}
                                    name="postalCode3"
                                    onChange={(event) => handleInputChange(event)}
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <label>Note (optional)</label>
                                  <textarea
                                    value={note3}
                                    maxlength='100'
                                    onChange={(event) => handleInputChange(event)}
                                    name="note3"
                                  ></textarea>
                                  <h6 className='createOrderCard-editAddress-textarea-counts'>{note3.length ? note3.length : "0"}/100</h6>

                                </div>
                                <div className="col-lg-12">
                                  <input type="checkbox" checked={isCheckedForadd3} name="label3" onChange={(event) => handleInputChange(event)} className="m-0 mr-2" />
                                  <label>Use shipping address for invoice</label>
                                </div>
                                <div className="col-lg-12 d-flex align-items-center justify-content-end">
                                  <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("form3")}>Cancel</button>
                                  <button className="createOrderCard-editAddress-save" onClick={saveForm3}>Use this address</button>
                                  {/* {isValid && (
                              
                            )}                            
                            {!isValid && (
                              <button  className="createOrderCard-editAddress-save-disbled">Use this address</button>
                            )}  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {userDetail && userDetail.data && userDetail.data.address.length < 3 && (
                      <>
                        {/* {console.log("row createOrderCardRadio m-0 mb-4",defaultShippingAddress)} */}
                        <div
                          // className="row createOrderCardRadio m-0 mb-4"
                          className={`${"" == defaultShippingAddress ? 'bulkUploadCardRadio-focus' : ''} row createOrderCardRadio m-0 mb-4`}
                        >
                          <div
                            onClick={() => {
                              setDefaultShippingAddress("")
                              showNewAddressForm()
                            }
                            }
                            className="col-lg-10 d-flex align-items-center justify-content-start"
                          >
                            <input
                              type="radio"
                              id="address1"
                              name="shipping_address"
                              value=""
                              onChange={(event) =>
                                handleAddressChange(event, address)
                              }
                              className="m-0 cursor-pointer"
                            />
                            <label htmlFor="address1" className="ml-2">
                              Add new address
                            </label>
                          </div>
                          {/* <div
                            
                            className="col-lg-2 d-flex justify-content-end"
                          >
                            <img src={Edit} className="cursor-pointer" />
                          </div> */}
                        </div>
                        {/*  */}
                        {editNewAddressForm && (
                          <div className="createOrderCard">
                            <div className="createOrderCard-editAddress">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Address</label>
                                  <input
                                    type="text"
                                    name="address4"
                                    value={address4}
                                    onChange={(event) => { handleInputChange(event); validateForm(); }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label>Province</label>
                                  <select
                                    name="province4"
                                    id="options"
                                    value={province4}
                                    onChange={(event) => { handleAreaChange("district", event); validateForm(); }
                                    }
                                  >
                                    <option value=""></option>
                                    {province &&
                                      province.map((item) => {
                                        return (
                                          <option value={item.provinceTH}>
                                            {item.provinceTH}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>District</label>
                                  <select
                                    name="district4"
                                    id="options"
                                    value={district4}
                                    onChange={(event) => { handleAreaChange("subdistrict", event); validateForm(); }
                                    }
                                  >
                                    <option value=""></option>
                                    {district &&
                                      district.map((item) => (
                                        <option value={item.districtTH}>
                                          {item.districtTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Sub-district</label>
                                  <select
                                    name="subDistrict4"
                                    id="options"
                                    value={subDistrict4}
                                    onChange={(event) => { handleAreaChange("postalCode", event); validateForm(); }
                                    }
                                  >
                                    <option value=""></option>
                                    {subDistrict &&
                                      subDistrict.map((item) => (
                                        <option value={item.subDistrictTH}>
                                          {item.subDistrictTH}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-lg-6">
                                  <label>Postal code</label>
                                  <input
                                    type="text"
                                    value={postalCode4}
                                    name="postalCode4"
                                    onChange={(event) => { handleInputChange(event); validateForm(); }}
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <label>Note (optional)</label>
                                  <textarea
                                    value={note4}
                                    maxlength='100'
                                    onChange={(event) => handleInputChange(event)}
                                    name="note4"
                                  ></textarea>
                                  <h6 className='createOrderCard-editAddress-textarea-counts'>{note4.length ? note4.length : "0"}/100</h6>

                                </div>
                                <div className="col-lg-12">
                                  <input type="checkbox" checked={isCheckedForadd4} name="label4" onChange={(event) => handleInputChange(event)} className="m-0 mr-2" />
                                  <label>Use shipping address for invoice</label>
                                </div>
                                <div className="col-lg-12 d-flex align-items-center justify-content-end">
                                  <button className="createOrderCard-editAddress-cancel mr-3" onClick={() => cancelBtn("saveNewAddress")}>Cancel</button>
                                  {isValid && (
                                    <button className="createOrderCard-editAddress-save" onClick={saveAddNewAddress}>Use this address</button>
                                  )}
                                  {!isValid && (
                                    <button className="createOrderCard-editAddress-save-disbled">Use this address</button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </RadioGroup>
                </div>
              )}

              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerInfo;
