import FormControl from "@mui/material/FormControl";
import LinearProgress from "@mui/material/LinearProgress";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Axios from "axios";
import moment from "moment";
import React, { useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { HamburgerTableMenu } from "../OrderList/HamburgerTableMenu/HamburgerTableMenu";
import { CommonTableHeader } from "../OrderList/TableHeader/TableHeader";
import Loading from "../SharedComponent/Loader/Loader";
import {
  uploadfile
} from "../_actions";
import { REACT_APP_BASE_URL } from "../constant";
import gearImage from "../images/gear.png";
import Empty from "./Assets/Images/Empty.png";
import UploadFile from "./Assets/Images/UploadFile.png";
import CircleCheck from "./Assets/SVG/CircleCheck.svg";
import CrossGrey from "./Assets/SVG/CrossGrey.svg";
import MoneyBills from "./Assets/SVG/MoneyBills.svg";
import Reload from "./Assets/SVG/Reload.svg";
import "./BulkUpload.css";

const BulkUpload = (props) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);
  const hiddenFileInput = useRef(null);
  const hiddenFileInputprice = useRef(null);
  const hiddenFileInputspecialprice = useRef(null);
  const hiddenFileInputstock = useRef(null);
  const [filenameproduct, setFilenameproduct] = React.useState([]);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [filenameprice, setFilenameprice] = React.useState([]);
  const [filenamespecialprice, setFilenamespecialprice] = React.useState([]);
  const [filenamestock, setFilenamestock] = React.useState([]);
  const [wrongFileUploadproduct, setWrongFileUploadproduct] =
    React.useState(false);
  const [rightfileuploadedproduct, setRightfileuploadedproduct] =
    React.useState(false);
  const [rightfileuploadedproductsuccess, setRightfileuploadedproductsuccess] =
    React.useState(false);
  const [rightfileuploadedproductfailure, setRightfileuploadedproductfailure] =
    React.useState(false);
  const [rightfileuploadedprice, setRightfileuploadedprice] =
    React.useState(false);
  const [rightfileuploadedpricesuccess, setRightfileuploadedpricesuccess] =
    React.useState(false);
  const [rightfileuploadedpricefailure, setRightfileuploadedpricefailure] =
    React.useState(false);

  const [rightfileuploadedspecialprice, setRightfileuploadedspecialprice] =
    React.useState(false);
  const [
    rightfileuploadedspecialpricesuccess,
    setRightfileuploadedspecialpricesuccess,
  ] = React.useState(false);
  const [
    rightfileuploadedspecialpricefailure,
    setRightfileuploadedspecialpricefailure,
  ] = React.useState(false);

  const [rightfileuploadedstock, setRightfileuploadedstock] =
    React.useState(false);
  const [rightfileuploadedstocksuccess, setRightfileuploadedstocksuccess] =
    React.useState(false);
  const [rightfileuploadedstockfailure, setRightfileuploadedstockfailure] =
    React.useState(false);
  const [showprogressproduct, setShowprogressproduct] = React.useState(false);

  const [showprogressprice, setShowprogressprice] = React.useState(false);
  const [showprogressspecialprice, setShowprogressspecialprice] =
    React.useState(false);

  const [showprogressstock, setShowprogressstock] = React.useState(false);

  const [wrongFileUploadprice, setWrongFileUploadprice] = React.useState(false);
  const [wrongFileUploadspecialprice, setWrongFileUploadspecialprice] =
    React.useState(false);
  const [wrongFileUploadstock, setWrongFileUploadstock] = React.useState(false);
  const [progressproduct, setProgressproduct] = React.useState(5);
  const [progressprice, setProgressprice] = React.useState(5);
  const [progressspecialprice, setProgressspecialprice] = React.useState(5);
  const [progressstock, setProgressstock] = React.useState(5);
  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [pricetype, setPricetype] = React.useState("Scale Price");
  const [tableData, setTableData] = React.useState([]);
  const [totalCountproduct, setTotalCountproduct] = React.useState(0);
  const [rowsPerPageproduct, setRowsPerPageproduct] = React.useState(5);
  const [pageproduct, setPageproduct] = React.useState(0);
  const [currentPageproduct, setCurrentPageproduct] = React.useState(1);
  const [currentPageprice, setCurrentPageprice] = React.useState(1);
  const [currentPagespecialprice, setCurrentPagespecialprice] =
    React.useState(1);
  const [currentPagestock, setCurrentPagestock] = React.useState(1);
  const [tableDataprice, setTableDataprice] = React.useState([]);
  const [totalCountprice, setTotalCountprice] = React.useState(0);
  const [reasonforfailureproduct, setReasonforfailureproduct] = React.useState("");
  const [reasonforfailureprice, setReasonforfailureprice] = React.useState("");
  const [reasonforfailurespecialprice, setReasonforfailurespecialprice] = React.useState("");
  const [reasonforfailurestock, setReasonforfailurestock] = React.useState("");

  const [reasonforsuccessproduct, setReasonforsuccesseproduct] = React.useState("");
  const [reasonforsuccessprice, setReasonforsuccessprice] = React.useState("");
  const [reasonforsuccessspecialprice, setReasonforsuccessspecialprice] = React.useState("");
  const [reasonforsuccessstock, setReasonforsuccessstock] = React.useState("");

  const [rowsPerPageprice, setRowsPerPageprice] = React.useState(5);
  const [pageprice, setPageprice] = React.useState(0);
  const [tableDataspecialprice, setTableDataspecialprice] = React.useState([]);
  const [totalCountspecialprice, setTotalCountspecialprice] = React.useState(0);
  const [rowsPerPagespecialprice, setRowsPerPagespecialprice] =
    React.useState(5);
  const [pagespecialprice, setPagespecialprice] = React.useState(0);
  const [tableDatastock, setTableDatastock] = React.useState([]);
  const [totalCountstock, setTotalCountstock] = React.useState(0);
  const [rowsPerPagestock, setRowsPerPagestock] = React.useState(5);
  const [pagestock, setPagestock] = React.useState(0);
  const [toggleValuePro, setToggleValuePro] = React.useState(false);
  const [togglePro, setTogglePro] = React.useState(true);
  const [toggleValuePri, setToggleValuePri] = React.useState(false);
  const [togglePri, setTogglePri] = React.useState(true);
  const [toggleValueSp, setToggleValueSp] = React.useState(false);
  const [toggleSp, setToggleSp] = React.useState(true);
  const [toggleValue, setToggleValue] = React.useState(false);
  const [toggle, setToggle] = React.useState(true);
  const postuploadpriceList = useSelector(
    (state) => state.specialprice.postuploadpriceList
  );
  const postuploadstockList = useSelector(
    (state) => state.stock.postuploadstockList
  );
  const getuploadfileList = useSelector(
    (state) => state.uploadfile.getuploadfileList
  );
  const getuploadfilepriceList = useSelector(
    (state) => state.uploadfile.getuploadfilepriceList
  );
  const getuploadfilespecialpriceList = useSelector(
    (state) => state.uploadfile.getuploadfilespecialpriceList
  );
  const getuploadfilestockList = useSelector(
    (state) => state.uploadfile.getuploadfilestockList
  );
  const postuploadproductsList = useSelector(
    (state) => state.product.postuploadproductsList
  );
  const [openPagination, setOpenPagination] = useState(true);
  const dispatch = useDispatch();
  const store = userData ? userData.user.stores[0].storeCode : "";

  const [dragActiveproduct, setDragActiveproduct] = React.useState(false);
  const [dragActiveprice, setDragActiveprice] = React.useState(false);
  const [dragActivespecialprice, setDragActivespecialprice] =
    React.useState(false);
  const [dragActivestock, setDragActivestock] = React.useState(false);
  const prevLocation = useLocation()
  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      localStorage.setItem('prevPath', prevLocation.pathname)
      history.push("/login");
    }
    if (userData) {
      let payload_product = {
        type: "Product",
      };
      let payload_price = {
        type: "Price",
      };
      let payload_special_price = {
        type: "Special Price",
      };
      let payload_stock = {
        type: "Stock",
      };
      let queryString_product = `?limit=${parseInt(
        rowsPerPageproduct
      )}&skip=${pageproduct}`;
      let queryString_price = `?limit=${parseInt(
        rowsPerPageprice
      )}&skip=${pageprice}`;
      let queryString_special_price = `?limit=${parseInt(
        rowsPerPagespecialprice
      )}&skip=${pagespecialprice}`;
      let queryString_stock = `?limit=${parseInt(
        rowsPerPagestock
      )}&skip=${pagestock}`;
      dispatch(
        uploadfile.getuploadfilelistproduct(
          "",
          payload_product,
          queryString_product
        )
      );
      dispatch(
        uploadfile.getuploadfilelistprice("", payload_price, queryString_price)
      );
      dispatch(
        uploadfile.getuploadfilelistspecialprice(
          "",
          payload_special_price,
          queryString_special_price
        )
      );
      dispatch(
        uploadfile.getuploadfileliststock("", payload_stock, queryString_stock)
      );
      setLoading(false);
    }
  }, [pageproduct, pageprice, pagespecialprice, pagestock, dataupdated]);

  React.useEffect(() => {
    setLoading(true);
    if (getuploadfileList?.data && getuploadfileList?.data.result.length > 0) {
      getuploadfileList?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != 'Invalid date') {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableData(getuploadfileList?.data.result);
      setLoading(false);
      setTotalCountproduct(getuploadfileList?.data.totalCount);
    } else {
      setTableData([]);
      setLoading(false);
      setTotalCountproduct(0);
    }

    if (
      getuploadfilepriceList?.data &&
      getuploadfilepriceList?.data.result.length > 0
    ) {
      getuploadfilepriceList?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != 'Invalid date') {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableDataprice(getuploadfilepriceList?.data.result);
      setLoading(false);
      setTotalCountprice(getuploadfilepriceList?.data.totalCount);
    } else {
      setTableDataprice([]);
      setLoading(false);
      setTotalCountprice(0);
    }

    if (
      getuploadfilespecialpriceList?.data &&
      getuploadfilespecialpriceList?.data.result.length > 0
    ) {
      getuploadfilespecialpriceList?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != 'Invalid date') {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableDataspecialprice(getuploadfilespecialpriceList?.data.result);
      setLoading(false);
      setTotalCountspecialprice(getuploadfilespecialpriceList?.data.totalCount);
    } else {
      setTableDataspecialprice([]);
      setLoading(false);
      setTotalCountspecialprice(0);
    }

    if (
      getuploadfilestockList?.data &&
      getuploadfilestockList?.data.result.length > 0
    ) {
      getuploadfilestockList?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != 'Invalid date') {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableDatastock(getuploadfilestockList?.data.result);
      setLoading(false);
      setTotalCountstock(getuploadfilestockList?.data.totalCount);
    } else {
      setTableDatastock([]);
      setLoading(false);
      setTotalCountstock(0);
    }
  }, [
    getuploadfileList,
    getuploadfilepriceList,
    getuploadfilespecialpriceList,
    getuploadfilestockList,
    pageproduct,
    pageprice,
    pagespecialprice,
    pagestock, dataupdated,
  ]);

  const totalPagesproduct =
    tableData &&
    Math.ceil(parseInt(totalCountproduct) / parseInt(rowsPerPageproduct));

  const totalPagesprice =
    tableDataprice &&
    Math.ceil(parseInt(totalCountprice) / parseInt(rowsPerPageprice));

  const totalPagesspecialprice =
    tableDataspecialprice &&
    Math.ceil(
      parseInt(totalCountspecialprice) / parseInt(rowsPerPagespecialprice)
    );

  const totalPagesstock =
    tableDatastock &&
    Math.ceil(parseInt(totalCountstock) / parseInt(rowsPerPagestock));

  React.useEffect(() => {
    const timerproduct = setInterval(() => {
      setProgressproduct((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerproduct);
    };
  }, []);

  React.useEffect(() => {
    const timerspecialprice = setInterval(() => {
      setProgressspecialprice((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerspecialprice);
    };
  }, []);
  React.useEffect(() => {
    const timerprice = setInterval(() => {
      setProgressprice((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerprice);
    };
  }, []);
  React.useEffect(() => {
    const timerstock = setInterval(() => {
      setProgressstock((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerstock);
    };
  }, []);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleClickprice = (event) => {
    hiddenFileInputprice.current.click();
  };

  const handleClickspecialprice = (event) => {
    hiddenFileInputspecialprice.current.click();
  };
  const handleClickstock = (event) => {
    hiddenFileInputstock.current.click();
  };

  const handlechangeproduct = (event) => {
    const fileUploaded = event.target.files[0];
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (types.every((type) => event.target.files[0].type !== type)) {
      setWrongFileUploadproduct(true);
      return;
    } else {
      setWrongFileUploadproduct(false);
      setRightfileuploadedproduct(true);
    }
    setFilenameproduct(fileUploaded);
  };
  const handleChangespecialprice = (event) => {
    const fileUploadedspecialprice = event.target.files[0];
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (types.every((type) => event.target.files[0].type !== type)) {
      setWrongFileUploadspecialprice(true);
      return;
    } else {
      setWrongFileUploadspecialprice(false);
      setRightfileuploadedspecialprice(true);
    }
    setFilenamespecialprice(fileUploadedspecialprice);
  };
  const handleChangeprice = (event) => {
    const fileUploadedprice = event.target.files[0];
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (types.every((type) => event.target.files[0].type !== type)) {
      setWrongFileUploadprice(true);
      return;
    } else {
      setWrongFileUploadprice(false);
      setRightfileuploadedprice(true);
    }
    setFilenameprice(fileUploadedprice);
  };
  const handleChangestock = (event) => {
    const fileUploadedstock = event.target.files[0];
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (types.every((type) => event.target.files[0].type !== type)) {
      setWrongFileUploadstock(true);
      return;
    } else {
      setWrongFileUploadstock(false);
      setRightfileuploadedstock(true);
    }
    setFilenamestock(fileUploadedstock);
  };
  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };

  const handledragproduct = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActiveproduct(true);
    } else if (e.type === "dragleave") {
      setDragActiveproduct(false);
    }
  };

  // triggers when file is dropped
  const handleDropproduct = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActiveproduct(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileUploaded = e.dataTransfer.files[0];
      const types = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (types.every((type) => e.dataTransfer.files[0].type !== type)) {
        setWrongFileUploadproduct(true);
        return;
      } else {
        setWrongFileUploadproduct(false);
        setRightfileuploadedproduct(true);
      }
      setFilenameproduct(fileUploaded);
    }
  };

  const handleDragprice = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActiveprice(true);
    } else if (e.type === "dragleave") {
      setDragActiveprice(false);
    }
  };

  // triggers when file is dropped
  const handleDropprice = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActiveprice(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileUploaded = e.dataTransfer.files[0];
      const types = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (types.every((type) => e.dataTransfer.files[0].type !== type)) {
        setWrongFileUploadprice(true);
        return;
      } else {
        setWrongFileUploadprice(false);
        setRightfileuploadedprice(true);
      }
      setFilenameprice(fileUploaded);
    }
  };

  const handleDragspecialprice = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActivespecialprice(true);
    } else if (e.type === "dragleave") {
      setDragActivespecialprice(false);
    }
  };

  // triggers when file is dropped
  const handleDropspecialprice = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActivespecialprice(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileUploaded = e.dataTransfer.files[0];
      const types = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (types.every((type) => e.dataTransfer.files[0].type !== type)) {
        setWrongFileUploadspecialprice(true);
        return;
      } else {
        setWrongFileUploadspecialprice(false);
        setRightfileuploadedspecialprice(true);
      }
      setFilenamespecialprice(fileUploaded);
    }
  };

  const handleDragstock = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActivestock(true);
    } else if (e.type === "dragleave") {
      setDragActivestock(false);
    }
  };

  // triggers when file is dropped
  const handleDropstock = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActivestock(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileUploaded = e.dataTransfer.files[0];
      const types = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (types.every((type) => e.dataTransfer.files[0].type !== type)) {
        setWrongFileUploadstock(true);
        return;
      } else {
        setWrongFileUploadstock(false);
        setRightfileuploadedstock(true);
      }
      setFilenamestock(fileUploaded);
    }
  };

  const savefileproduct = (event) => {
    setDataupdated(false);
    setShowprogressproduct(true);
    event.preventDefault();
    //const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameproduct);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/product/upload/products",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessprouct(response.message);
        setRightfileuploadedproductsuccess(true);
        setRightfileuploadedproduct(false);
        setShowprogressproduct(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureproduct(error.message);
        setRightfileuploadedproductfailure(true);
        setRightfileuploadedproduct(false);
        setShowprogressproduct(false);
        setDataupdated(true);
        error;
      });
  };
  const reuploadFileproduct = (event) => {
    setDataupdated(false);
    setWrongFileUploadproduct(false);
    setRightfileuploadedproductsuccess(false);
    setRightfileuploadedproductfailure(false);
    setRightfileuploadedproduct(true);
    setShowprogressproduct(true);
    event.preventDefault();
    //const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameproduct);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/product/upload/products",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessprouct(response.message);
        setRightfileuploadedproductsuccess(true);
        setRightfileuploadedproduct(false);
        setShowprogressproduct(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureproduct(error.message);
        setRightfileuploadedproductfailure(true);
        setRightfileuploadedproduct(false);
        setShowprogressproduct(false);
        setDataupdated(true);
        error;
      });
  };

  const reuploadFilespecialprice = (event) => {
    setDataupdated(false);
    setWrongFileUploadspecialprice(false);
    setRightfileuploadedspecialprice(true);
    setRightfileuploadedspecialpricesuccess(false);
    setRightfileuploadedspecialpricefailure(false);
    setShowprogressspecialprice(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    payload.append("objects", filenamespecialprice);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/specialprice/upload",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessspecialprice(response.message);
        setRightfileuploadedspecialpricesuccess(true);
        setRightfileuploadedspecialprice(false);
        setShowprogressspecialprice(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailurespecialprice(error.message);
        setRightfileuploadedspecialpricefailure(true);
        setRightfileuploadedspecialprice(false);
        setShowprogressspecialprice(false);
        setDataupdated(true);
        error;
      });
  };

  const saveFilespecialprice = (event) => {
    setDataupdated(false);
    setShowprogressspecialprice(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    payload.append("objects", filenamespecialprice);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/specialprice/upload",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessspecialprice(response.message);
        setRightfileuploadedspecialpricesuccess(true);
        setRightfileuploadedspecialprice(false);
        setShowprogressspecialprice(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailurespecialprice(error.message);
        setRightfileuploadedspecialpricefailure(true);
        setRightfileuploadedspecialprice(false);
        setShowprogressspecialprice(false);
        setDataupdated(true);
        error;
      });
  };

  const saveFileprice = (event) => {
    setDataupdated(false);
    setShowprogressprice(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameprice);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/price/upload",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessprice(response.message);
        setRightfileuploadedpricesuccess(true);
        setRightfileuploadedprice(false);
        setShowprogressprice(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureprice(error.message);
        setRightfileuploadedpricefailure(true);
        setRightfileuploadedprice(false);
        setShowprogressprice(false);
        setDataupdated(true);
        error;
      });
  };

  const reuploadFileprice = (event) => {
    setDataupdated(false);
    setWrongFileUploadprice(false);
    setRightfileuploadedprice(true);
    setRightfileuploadedpricesuccess(false);
    setRightfileuploadedpricefailure(false);
    setShowprogressprice(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameprice);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/price/upload",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessprice(response.message);
        setRightfileuploadedpricesuccess(true);
        setRightfileuploadedprice(false);
        setShowprogressprice(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureprice(error.message);
        setRightfileuploadedpricefailure(true);
        setRightfileuploadedprice(false);
        setShowprogressprice(false);
        setDataupdated(true);
        error;
      });
  };
  const saveFilestock = (event) => {
    setDataupdated(false);
    setShowprogressstock(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    payload.append("objects", filenamestock);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/stock/upload",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessstock(response.message);
        setRightfileuploadedstocksuccess(true);
        setRightfileuploadedstock(false);
        setShowprogressstock(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailurestock(error.message);
        setRightfileuploadedstockfailure(true);
        setRightfileuploadedstock(false);
        setShowprogressstock(false);
        setDataupdated(true);
        error;
      });
  };
  const reuploadFilestock = (event) => {
    setDataupdated(false);
    setWrongFileUploadstock(false);
    setRightfileuploadedstock(true);
    setRightfileuploadedstocksuccess(false);
    setRightfileuploadedstockfailure(false);
    setShowprogressstock(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    payload.append("objects", filenamestock);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/stock/upload",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessstock(response.message);
        setRightfileuploadedstocksuccess(true);
        setRightfileuploadedstock(false);
        setShowprogressstock(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailurestock(error.message);
        setRightfileuploadedstockfailure(true);
        setRightfileuploadedstock(false);
        setShowprogressstock(false);
        setDataupdated(true);
        error;
      });
  };

  const handleChangePagespecialprice = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPagespecialprice;
    const payload = {
      type: "Special Price",
    };

    setPagespecialprice(pages);
    setCurrentPagespecialprice(newPage);
  };
  const handleChangePageprice = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPageprice;
    const payload = {
      type: "Price",
    };

    setPageprice(pages);
    setCurrentPageprice(newPage);
  };

  const handleChangePageproduct = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPageproduct;
    const payload = {
      type: "Product",
    };

    setPageproduct(pages);
    setCurrentPageproduct(newPage);
  };
  const handleChangePagestock = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPagestock;
    const payload = {
      type: "Stock",
    };

    setPagestock(pages);
    setCurrentPagestock(newPage);
  };

  const handlepricetypeChange = (statusValue) => {
    setPricetype(statusValue);
    // if (pricetype == "Scale Price") {
    //   setPricetype("Special Price");
    // } else {
    //   setPricetype("Scale Price");
    // }

    /* if (!event.target) {
    }; */
  };

  const clearFileproduct = (event) => {
    setFilenameproduct("");
    setWrongFileUploadproduct(false);
    // setRightfileuploadedproduct(false);
    setRightfileuploadedproductsuccess(false);
    setRightfileuploadedproductfailure(false);
  };

  const clearFileprice = (event) => {
    setFilenameprice("");
    setWrongFileUploadprice(false);
    // setRightfileuploadedprice(false);
    setRightfileuploadedpricesuccess(false);
    setRightfileuploadedpricefailure(false);
  };

  const clearFilespecialprice = (event) => {
    setFilenamespecialprice("");
    setWrongFileUploadspecialprice(false);
    // setRightfileuploadedspecialprice(false);
    setRightfileuploadedspecialpricesuccess(false);
    setRightfileuploadedspecialpricefailure(false);
  };

  const clearFilestock = (event) => {
    setFilenamestock("");
    setWrongFileUploadstock(false);
    // setRightfileuploadedstock(false);
    setRightfileuploadedstocksuccess(false);
    setRightfileuploadedstockfailure(false);
  };

  const toggleHandler = (eventKey, cb, tcb) => {
    if (eventKey === true) {
      cb(!eventKey);
    } else {
      cb(eventKey);
    }
    tcb(!eventKey);
  };

  return <>
    <CommonTableHeader />
    <Helmet>
      <title>Betagro Bulk Upload</title>
    </Helmet>
    {loading && <Loading />}
    <div className="container-fluid" data-testid="bulk-upload-screen-loading">
      <div className="row">
        <HamburgerTableMenu screen={props?.location?.state?.screen} />
        <div className="col-sm-9" style={{ paddingTop: "100px", backgroundColor: "#F5F5F5" }}>
          <div className="bulkUpload">
            <div className="bulkUploadHead">
              <h2 data-testid="bulk-upload-heading">Bulk Upload</h2>
            </div>
            <div className="bulkUploadCard">
              <h3>Upload Price File</h3>
              <FormControl variant="standard">

                <div className="row m-0">
                  <div className="col-lg-6 p-0 pr-3">
                    <div
                      // className="bulkUploadCardRadio  d-flex align-items-center"
                      className={`${pricetype == 'Scale Price' ? "bulkUploadCardRadio-focus" : ""} bulkUploadCardRadio  d-flex align-items-center`}
                    >
                      {/* <FormControlLabel value="Scale Price"
                            control={<Radio />}
                            label="Scale Price"
                            className={
                              pricetype ==
                                "Scale Price"
                                ? "selected-price"
                                : "unselected-price "
                            } /> */}
                      <input
                        type="radio"
                        name="price"
                        checked={pricetype == "Scale Price" ? true : false}
                        value="Scale Price"
                        onChange={() => handlepricetypeChange("Scale Price")}
                        className={
                          pricetype == "Scale Price"
                            ? "selected-price"
                            : "unselected-price "

                        }


                      />
                      <label className="ml-2">Scale Price</label>
                    </div>
                  </div>
                  <div className="col-lg-6 p-0 pl-3">
                    <div
                      // className="bulkUploadCardRadio d-flex align-items-center"
                      className={`${pricetype !== 'Scale Price' ? "bulkUploadCardRadio-focus" : ""} bulkUploadCardRadio d-flex align-items-center`}

                    >

                      {/* <FormControlLabel value="Special Price"
                            control={<Radio />}
                            label="Special Price"
                            className={
                              pricetype ==
                                "Special Price"
                                ? "selected-price"
                                : "unselected-price "
                            } /> */}
                      <input
                        type="radio"
                        name="price"
                        value="Special Price"
                        checked={pricetype == "Special Price" ? true : false}
                        onChange={() => handlepricetypeChange("Special Price")}
                        className={
                          pricetype == "Special Price"
                            ? "selected-price"
                            : "unselected-price "
                        }

                      ></input>
                      <label className="ml-2">Special Price</label>
                    </div>
                  </div>
                </div>
              </FormControl>
              {pricetype == "Scale Price" ? (
                <form
                  onSubmit={(event) => saveFileprice(event)}
                  method="post"
                  encType="multipart/form-data"
                >
                  <div
                    className="bulkUploadCard-uploadFile"
                    onDragEnter={handleDragprice}
                  >
                    <input
                      type="file"
                      ref={hiddenFileInputprice}
                      onChange={handleChangeprice}
                      style={{ display: "none" }}
                      accept=".xlsx,.xlx"
                    />
                    <label
                      title="Drag and Drop Price file here"
                      id="label-file-upload"
                      htmlFor="input-file-upload"
                      className={dragActiveprice ? "drag-activeprice" : ""}
                    >
                      <img
                        title="Click to Upload a Price file"
                        src={UploadFile}
                        data-testid="click-to-upload-image"
                        onClick={handleClickprice}
                        className="bulkUploadCard-uploadFile-img cursor-pointer"
                      />
                      <p>
                        {isNull(filenameprice?.name)
                          ? <span onClick={handleClickprice}>Choose a file here</span>
                          : filenameprice?.name}
                      </p>
                      {wrongFileUploadprice && (
                        <h6 className="invalid-feedback">
                          "Please uplaod a valid file of format .xlsx or .xlx"
                        </h6>
                      )}
                    </label>
                  </div>
                  {dragActiveprice && (
                    <div
                      className="drag-file-element"
                      onDragEnter={handleDragprice}
                      onDragLeave={handleDragprice}
                      onDragOver={handleDragprice}
                      onDrop={handleDropprice}
                    ></div>
                  )}
                  {rightfileuploadedpricesuccess ? (
                    <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileSuccess">
                      <div className="row">
                        <div className="col-lg-10 d-flex align-items-center justify-content-start">
                          <div>
                            <img src={MoneyBills} className="mr-4" />
                          </div>
                          <div>
                            <h4>{filenameprice.name}</h4>
                            <h4>
                              <span>{filenameprice.size} mb</span>
                            </h4>
                          </div>
                        </div>
                        <div className="col-lg-2 d-flex align-items-center justify-content-end">
                          <img src={CircleCheck} className="mr-4" />
                          <button
                            title="Click to  Clear"
                            onClick={(event) => clearFileprice(event)}
                            className="bulkUploadCard-uploadFileStatus-btn"
                          >
                            <img src={CrossGrey} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {rightfileuploadedprice ? (
                    <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileProgress">
                      <div className="row">
                        <div className="col-lg-10 d-flex align-items-center justify-content-start">
                          <div>
                            <img src={MoneyBills} className="mr-4" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <h4>{filenameprice.name}</h4>
                            <div className="d-flex align-items-center justify-content-between">
                              {showprogressprice &&
                                !rightfileuploadedpricesuccess &&
                                !rightfileuploadedpricefailure ? (
                                <>
                                  {/* <LinearProgress
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "#D3D6DA",
                                    }}
                                    color="success"
                                    variant="determinate"
                                    value={progressprice}
                                  />
                                  <div className="bulkUploadCard-uploadFileProgress-status ml-3">{`${Math.round(
                                    progressprice
                                  )}%`}</div>{" "} */}
                                  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                    <LinearProgress sx={{
                                      width: "100%",
                                      backgroundColor: "#D3D6DA",
                                    }} color="success" />
                                  </Stack>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 d-flex align-items-center justify-content-end">
                          {/*  <img src={Pause} className="mr-4" /> */}
                          <button
                            title="Click to  Clear"
                            onClick={(event) => clearFileprice(event)}
                            className="bulkUploadCard-uploadFileStatus-btn"
                          >
                            <img src={CrossGrey} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {rightfileuploadedpricefailure ? (
                    <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileFail">
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-10 d-flex align-items-center justify-content-start">
                          <div>
                            <img src={MoneyBills} className="mr-4" />
                          </div>
                          <div>
                            <h4>{filenameprice.name}</h4>
                            <h4>
                              <span>{reasonforsuccessprice}&nbsp; {reasonforfailureprice} (Fail)</span>
                            </h4>
                          </div>
                        </div>
                        <div className="col-lg-2 d-flex align-items-center justify-content-end">
                          <button
                            title="Click and Re Upload  a Price file "
                            onClick={(event) => reuploadFileprice(event)}
                            className="bulkUploadCard-uploadFileStatus-btn"
                          >
                            <img src={Reload} className="mr-4" />
                          </button>
                          <button
                            title="Click to  Clear"
                            onClick={(event) => clearFileprice(event)}
                            className="bulkUploadCard-uploadFileStatus-btn"
                          >
                            <img src={CrossGrey} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="bulkUploadCard-uploadFileHistory">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <div className="d-flex align-items-baseline justify-content-between">
                          <Accordion.Header>
                            <div className="bulkUploadCard-uploadFileHistoryHead">
                              <h5 onClick={() => toggleHandler(togglePri, setToggleValuePri, setTogglePri)}>
                                {" "}
                                {toggleValuePri !== togglePri
                                  ? `Hide Upload History`
                                  : `See Upload History`}
                              </h5>
                              {/* {rightfileuploadedproduct && (
                                  <button
                                    type="submit"
                                    className="bulkUploadCard-uploadBtn"
                                  >
                                    Upload
                                  </button>
                                )}

                                {!rightfileuploadedproduct && (
                                  <div
                                    title="Click and upload a Product file to activate the button"
                                    className="bulkUploadCard-uploadBtnDsbld"
                                  >
                                    Upload
                                  </div>
                                )} */}
                            </div>
                          </Accordion.Header>
                          {rightfileuploadedprice && (
                            <button
                              type="submit"
                              className="bulkUploadCard-uploadBtn"
                            >
                              Upload
                            </button>
                          )}

                          {!rightfileuploadedprice && (
                            <div
                              title="Click and upload a Price file to activate the button"
                              className="bulkUploadCard-uploadBtnDsbld"
                            >
                              Upload
                            </div>
                          )}
                        </div>
                        <Accordion.Body>
                          <h3 className="mt-5 mb-5">
                            Upload History ({totalCountprice})
                          </h3>
                          <div className="bulkUploadCard-uploadFileHistoryDesc">
                            <div className="bulkUploadCard-uploadFileHistoryDesc-head">
                              <div className="row">
                                <div className="col-lg-2  d-flex align-items-center justify-content-start">
                                  <h6>Date</h6>
                                </div>
                                <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                  <h6>File Name</h6>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                  <h6>Uploaded By</h6>
                                </div>
                                {/* <div className="col-lg-2">
                                  <h6>Reason</h6>
                                </div> */}
                                <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                  <h6>Status</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bulkUploadCard-uploadFileHistoryDesc-body">
                              {tableDataprice &&
                                tableDataprice.length > 0 &&
                                tableDataprice.map((items, index1) => (
                                  <div className="row bulkUploadCard-uploadFileHistoryDesc-body-in">
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      <h6>{items.updatedAt}</h6>
                                    </div>
                                    <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                      <h6 title={items.filename} className="my-bulk-upload-file-name-truncate">
                                        {items.filename &&
                                          items.filename.length > 35
                                          ? items.filename.substring(0, 35) +
                                          "..."
                                          : items.filename}
                                      </h6>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                      <h6
                                        title={
                                          items.firstNameEN +
                                          " " +
                                          items.lastNameEN
                                        }
                                      >
                                        <img
                                          src={gearImage}
                                          className="gearImage"
                                        />
                                        &nbsp;{items.firstNameEN}&nbsp;
                                        {items.lastNameEN &&
                                          items.lastNameEN.length > 4
                                          ? items.lastNameEN.substring(0, 4) +
                                          "..."
                                          : items.lastNameEN}
                                      </h6>
                                    </div>
                                    {/* <div className="col-lg-2">
                                      {items.status === true ? (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                          {items.remark}
                                        </h6>
                                      ) : (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                          {items.remark}
                                        </h6>
                                      )}
                                    </div> */}
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      {items.status === true ? (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                          Success
                                        </h6>
                                      ) : (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                          Failed
                                        </h6>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                            {!tableDataprice ||
                              (tableDataprice.length === 0 && (
                                <div className="bulkUploadCard-uploadFileHistoryDesc-bodyEmpty">
                                  <img src={Empty} />
                                  <p>No data available</p>
                                </div>
                              ))}
                          </div>
                          {tableDataprice && tableDataprice.length > 0 && (
                            <>
                              <div className="row pagination-main-container common-display-none-mobile mt-4 mb-2">
                                <div className="col-xll-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex justify-content-end">
                                  <Stack spacing={2}>
                                    <Pagination
                                      count={totalPagesprice}
                                      page={currentPageprice}
                                      variant="outlined"
                                      shape="rounded"
                                      onChange={(event, page) =>
                                        handleChangePageprice(event, page)
                                      }
                                    />
                                  </Stack>
                                </div>
                              </div>
                            </>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </form>
              ) : (
                <form
                  onSubmit={(event) => saveFilespecialprice(event)}
                  method="post"
                  encType="multipart/form-data"
                >
                  <div
                    className="bulkUploadCard-uploadFile"
                    onDragEnter={handleDragspecialprice}
                  >
                    <input
                      type="file"
                      ref={hiddenFileInputspecialprice}
                      onChange={handleChangespecialprice}
                      style={{ display: "none" }}
                      accept=".xlsx,.xlx"
                    />
                    <label
                      title="Drag and Drop Special price file  here"
                      id="label-file-upload"
                      htmlFor="input-file-upload"
                      className={
                        dragActivespecialprice
                          ? "drag-activespecialprice"
                          : ""
                      }
                    >
                      <img
                        title="Click to Upload Special price file  here"
                        src={UploadFile}
                        data-testid="click-to-upload-image"
                        onClick={handleClickspecialprice}
                        className="bulkUploadCard-uploadFile-img cursor-pointer"
                      />
                      <p>
                        {isNull(filenamespecialprice?.name)
                          ? <span onClick={handleClickspecialprice}>Choose a file here</span>
                          : filenamespecialprice?.name}
                      </p>
                      {wrongFileUploadspecialprice && (
                        <h6 className="invalid-feedback">
                          "Please uplaod a valid file of format .xlsx or .xlx"
                        </h6>
                      )}
                    </label>
                  </div>
                  {dragActivespecialprice && (
                    <div
                      className="drag-file-element"
                      onDragEnter={handleDragspecialprice}
                      onDragLeave={handleDragspecialprice}
                      onDragOver={handleDragspecialprice}
                      onDrop={handleDropspecialprice}
                    ></div>
                  )}
                  {rightfileuploadedspecialpricesuccess ? (
                    <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileSuccess">
                      <div className="row">
                        <div className="col-lg-10 d-flex align-items-center justify-content-start">
                          <div>
                            <img src={MoneyBills} className="mr-4" />
                          </div>
                          <div>
                            <h4>{filenamespecialprice.name}</h4>
                            <h4>
                              <span>{filenamespecialprice.size} mb</span>
                            </h4>
                          </div>
                        </div>
                        <div className="col-lg-2 d-flex align-items-center justify-content-end">
                          <img src={CircleCheck} className="mr-4" />
                          <button
                            title="Click to  Clear"
                            onClick={(event) => clearFilespecialprice(event)}
                            className="bulkUploadCard-uploadFileStatus-btn"
                          >
                            <img src={CrossGrey} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {rightfileuploadedspecialprice ? (
                    <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileProgress">
                      <div className="row">
                        <div className="col-lg-10 d-flex align-items-center justify-content-start">
                          <div>
                            <img src={MoneyBills} className="mr-4" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <h4>{filenamespecialprice.name}</h4>
                            <div className="d-flex align-items-center justify-content-between">
                              {showprogressspecialprice &&
                                !rightfileuploadedspecialpricesuccess &&
                                !rightfileuploadedspecialpricefailure ? (
                                <>
                                  {/* <LinearProgress
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "#D3D6DA",
                                    }}
                                    color="success"
                                    variant="determinate"
                                    value={progressspecialprice}
                                  />
                                  <div className="bulkUploadCard-uploadFileProgress-status ml-3">{`${Math.round(
                                    progressspecialprice
                                  )}%`}</div>{" "} */}
                                  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                    <LinearProgress sx={{
                                      width: "100%",
                                      backgroundColor: "#D3D6DA",
                                    }} color="success" />
                                  </Stack>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 d-flex align-items-center justify-content-end">
                          {/*  <img src={Pause} className="mr-4" /> */}
                          <button
                            title="Click to  Clear"
                            onClick={(event) => clearFilespecialprice(event)}
                            className="bulkUploadCard-uploadFileStatus-btn"
                          >
                            <img src={CrossGrey} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {rightfileuploadedspecialpricefailure ? (
                    <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileFail">
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-10 d-flex align-items-center justify-content-start">
                          <div>
                            <img src={MoneyBills} className="mr-4" />
                          </div>
                          <div>
                            <h4>{filenamespecialprice.name}</h4>
                            <h4>
                              <span> {reasonforsuccessspecialprice} &nbsp; {reasonforfailurespecialprice} ( Fail)</span>
                            </h4>
                          </div>
                        </div>
                        <div className="col-lg-2 d-flex align-items-center justify-content-end">
                          <button
                            title="Click and Re Upload  a Special Price file "
                            onClick={(event) =>
                              reuploadFilespecialprice(event)
                            }
                            className="bulkUploadCard-uploadFileStatus-btn"
                          >
                            <img src={Reload} className="mr-4" />
                          </button>
                          <button
                            title="Click to  Clear"
                            onClick={(event) => clearFilespecialprice(event)}
                            className="bulkUploadCard-uploadFileStatus-btn"
                          >
                            <img src={CrossGrey} />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="bulkUploadCard-uploadFileHistory">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <div className="d-flex align-items-baseline justify-content-between">
                          <Accordion.Header>
                            <div className="bulkUploadCard-uploadFileHistoryHead">
                              <h5 onClick={() => toggleHandler(toggleSp, setToggleValueSp, setToggleSp)}>
                                {" "}
                                {toggleValueSp !== toggleSp
                                  ? `Hide Upload History`
                                  : `See Upload History`}
                              </h5>

                            </div>
                          </Accordion.Header>
                          {rightfileuploadedspecialprice &&
                            (<button
                              type="submit"
                              className="bulkUploadCard-uploadBtn"
                            >
                              Upload
                            </button>)}
                          {!rightfileuploadedspecialprice &&
                            (<div
                              title="Click and upload a  Special Price file to activate the button"
                              className="bulkUploadCard-uploadBtnDsbld"
                            >
                              Upload
                            </div>)}
                        </div>
                        <Accordion.Body>
                          <h3 className="mt-5 mb-5">
                            Upload History ({totalCountspecialprice})
                          </h3>
                          <div className="bulkUploadCard-uploadFileHistoryDesc">
                            <div className="bulkUploadCard-uploadFileHistoryDesc-head">
                              <div className="row">
                                <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                  <h6>Date</h6>
                                </div>
                                <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                  <h6>File Name</h6>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center justify-content-start">
                                  <h6>Uploaded By</h6>
                                </div>
                                {/* <div className="col-lg-2">
                                  <h6>Reason</h6>
                                </div> */}
                                <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                  <h6>Status</h6>
                                </div>
                              </div>
                            </div>
                            <div className="bulkUploadCard-uploadFileHistoryDesc-body">
                              {tableDataspecialprice &&
                                tableDataspecialprice.length > 0 &&
                                tableDataspecialprice.map((items, index1) => (
                                  <div className="row bulkUploadCard-uploadFileHistoryDesc-body-in">
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      <h6>{items.updatedAt}</h6>
                                    </div>
                                    <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                      <h6 title={items.filename} className="my-bulk-upload-file-name-truncate">
                                        {items.filename &&
                                          items.filename.length > 35
                                          ? items.filename.substring(0, 35) +
                                          "..."
                                          : items.filename}
                                      </h6>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                      <h6
                                        title={
                                          items.firstNameEN +
                                          " " +
                                          items.lastNameEN
                                        }
                                      >
                                        &nbsp;
                                        <img
                                          src={gearImage}
                                          className="gearImage"
                                        />
                                        &nbsp;{items.firstNameEN}&nbsp;
                                        {items.lastNameEN &&
                                          items.lastNameEN.length > 4
                                          ? items.lastNameEN.substring(0, 4) +
                                          "..."
                                          : items.lastNameEN}
                                      </h6>
                                    </div>
                                    {/* <div className="col-lg-2">
                                      {items.status === true ? (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                          {items.remark}
                                        </h6>
                                      ) : (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                          {items.remark}
                                        </h6>
                                      )}
                                    </div> */}
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      {items.status === true ? (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                          Success
                                        </h6>
                                      ) : (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                          Failed
                                        </h6>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                            {!tableDataspecialprice ||
                              (tableDataspecialprice.length === 0 && (
                                <div className="bulkUploadCard-uploadFileHistoryDesc-bodyEmpty">
                                  <img src={Empty} />
                                  <p>No data available</p>
                                </div>
                              ))}
                          </div>
                          {tableDataspecialprice &&
                            tableDataspecialprice.length > 0 && (
                              <>
                                <div className="row pagination-main-container common-display-none-mobile mt-4 mb-2">
                                  <div className="col-xll-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex justify-content-end">
                                    <Stack spacing={2}>
                                      <Pagination
                                        count={totalPagesspecialprice}
                                        page={currentPagespecialprice}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={(event, page) =>
                                          handleChangePagespecialprice(
                                            event,
                                            page
                                          )
                                        }
                                      />
                                    </Stack>
                                  </div>
                                </div>
                              </>
                            )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </form>
              )}
            </div>
            <div className="bulkUploadCard">
              <h3>Upload Stock File</h3>
              <form
                onSubmit={(event) => saveFilestock(event)}
                method="post"
                encType="multipart/form-data"
              >
                <div
                  className="bulkUploadCard-uploadFile"
                  onDragEnter={handleDragstock}
                >
                  <input
                    type="file"
                    ref={hiddenFileInputstock}
                    onChange={handleChangestock}
                    style={{ display: "none" }}
                    accept=".xlsx,.xlx"
                  />
                  <label
                    title="Drag and Drop Stock file here"
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActivestock ? "drag-activestock" : ""}
                  >
                    <img
                      title="Click to Upload Stock file here"
                      src={UploadFile}
                      onClick={handleClickstock}
                      data-testid="click-to-upload-image"
                      className="bulkUploadCard-uploadFile-img cursor-pointer"
                    />
                    <p>
                      {isNull(filenamestock?.name)
                        ? <span onClick={handleClickstock}> Choose a file here</span>
                        : filenamestock?.name}
                    </p>
                    {wrongFileUploadstock && (
                      <h6 className="invalid-feedback">
                        "Please uplaod a valid file of format .xlsx or .xlx"
                      </h6>
                    )}
                  </label>
                </div>
                {dragActivestock && (
                  <div
                    className="drag-file-element"
                    onDragEnter={handleDragstock}
                    onDragLeave={handleDragstock}
                    onDragOver={handleDragstock}
                    onDrop={handleDropstock}
                  ></div>
                )}
                {rightfileuploadedstocksuccess ? (
                  <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileSuccess">
                    <div className="row">
                      <div className="col-lg-10 d-flex align-items-center justify-content-start">
                        <div>
                          <img src={MoneyBills} className="mr-4" />
                        </div>
                        <div>
                          <h4>{filenamestock.name}</h4>
                          <h4>
                            <span>{filenamestock.size} mb</span>
                          </h4>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center justify-content-end">
                        <img src={CircleCheck} className="mr-4" />
                        <button
                          title="Click to  Clear"
                          onClick={(event) => clearFilestock(event)}
                          className="bulkUploadCard-uploadFileStatus-btn"
                        >
                          <img src={CrossGrey} />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {rightfileuploadedstock ? (
                  <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileProgress">
                    <div className="row">
                      <div className="col-lg-10 d-flex align-items-center justify-content-start">
                        <div>
                          <img src={MoneyBills} className="mr-4" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <h4>{filenamestock.name}</h4>
                          <div className="d-flex align-items-center justify-content-between">
                            {showprogressstock &&
                              !rightfileuploadedstocksuccess &&
                              !rightfileuploadedstockfailure ? (
                              <>
                                {/* <LinearProgress
                                  sx={{
                                    width: "100%",
                                    backgroundColor: "#D3D6DA",
                                  }}
                                  color="success"
                                  variant="determinate"
                                  value={progressstock}
                                />
                                <div className="bulkUploadCard-uploadFileProgress-status ml-3">{`${Math.round(
                                  progressstock
                                )}%`}</div> */}
                                <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                  <LinearProgress sx={{
                                    width: "100%",
                                    backgroundColor: "#D3D6DA",
                                  }} color="success" />
                                </Stack>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center justify-content-end">
                        {/*   <img src={Pause} className="mr-4" /> */}
                        <button
                          title="Click to  Clear"
                          onClick={(event) => clearFilestock(event)}
                          className="bulkUploadCard-uploadFileStatus-btn"
                        >
                          <img src={CrossGrey} />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {rightfileuploadedstockfailure ? (
                  <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileFail">
                    <div className="row d-flex align-items-center">
                      <div className="col-lg-10 d-flex align-items-center justify-content-start">
                        <div>
                          <img src={MoneyBills} className="mr-4" />
                        </div>
                        <div>
                          <h4>{filenamestock.name}</h4>
                          <h4>
                            <span>{reasonforsuccessstock}&nbsp;{reasonforfailurestock} (Fail)</span>
                          </h4>
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex align-items-center justify-content-end">
                        <button
                          title="Click and Re Upload  a Stock file "
                          onClick={(event) => reuploadFilestock(event)}
                          className="bulkUploadCard-uploadFileStatus-btn"
                        >
                          <img src={Reload} className="mr-4" />
                        </button>
                        <button
                          title="Click to  Clear"
                          onClick={(event) => clearFilestock(event)}
                          className="bulkUploadCard-uploadFileStatus-btn"
                        >
                          <img src={CrossGrey} />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="bulkUploadCard-uploadFileHistory">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <div className="d-flex align-items-baseline justify-content-between">
                        <Accordion.Header>
                          <div className="bulkUploadCard-uploadFileHistoryHead">
                            <h5 onClick={() => toggleHandler(toggle, setToggleValue, setToggle)}>
                              {" "}
                              {toggleValue !== toggle
                                ? `Hide Upload History`
                                : `See Upload History`}
                            </h5>

                          </div>
                        </Accordion.Header>
                        {rightfileuploadedstock &&
                          (<button
                            type="submit"
                            className="bulkUploadCard-uploadBtn"
                          >
                            Upload
                          </button>)}
                        {!rightfileuploadedstock &&
                          (<div
                            title="Click and upload a Stock file to activate the button"
                            className="bulkUploadCard-uploadBtnDsbld"
                          >
                            Upload
                          </div>)}
                      </div>
                      <Accordion.Body>
                        <h3 className="mt-5 mb-5">
                          Upload History({totalCountstock})
                        </h3>
                        <div className="bulkUploadCard-uploadFileHistoryDesc">
                          <div className="bulkUploadCard-uploadFileHistoryDesc-head">
                            <div className="row">
                              <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                <h6>Date</h6>
                              </div>
                              <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                <h6>File Name</h6>
                              </div>
                              <div className="col-lg-3 d-flex align-items-center justify-content-start">
                                <h6>Uploaded By</h6>
                              </div>
                              {/* <div className="col-lg-3">
                                  <h6>Reason</h6>
                                </div> */}
                              <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                <h6>Status</h6>
                              </div>
                            </div>
                          </div>
                          <div className="bulkUploadCard-uploadFileHistoryDesc-body">
                            {tableDatastock &&
                              tableDatastock.length > 0 &&
                              tableDatastock.map((items, index1) => (
                                <div className="row bulkUploadCard-uploadFileHistoryDesc-body-in">
                                  <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                    <h6>{items.updatedAt}</h6>
                                  </div>
                                  <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                    <h6 title={items.filename}>
                                      {items.filename &&
                                        items.filename.length > 35
                                        ? items.filename.substring(0, 35) +
                                        "..."
                                        : items.filename}
                                    </h6>
                                  </div>
                                  <div className="col-lg-3 d-flex align-items-center justify-content-start">
                                    <h6
                                      title={
                                        items.firstNameEN +
                                        " " +
                                        items.lastNameEN
                                      }
                                    >
                                      &nbsp;
                                      <img
                                        className="gearImage"
                                        src={gearImage}
                                      />
                                      &nbsp;{items.firstNameEN}&nbsp;
                                      {items.lastNameEN &&
                                        items.lastNameEN.length > 4
                                        ? items.lastNameEN.substring(0, 4) +
                                        "..."
                                        : items.lastNameEN}
                                    </h6>
                                  </div>
                                  {/* <div className="col-lg-2">
                                      {items.status === true ? (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                          {items.remark}
                                        </h6>
                                      ) : (
                                        <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                          {items.remark}
                                        </h6>
                                      )}
                                    </div> */}
                                  <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                    {items.status === true ? (
                                      <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                        Success
                                      </h6>
                                    ) : (
                                      <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                        Failed
                                      </h6>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {!tableDatastock ||
                            (tableDatastock.length === 0 && (
                              <div className="bulkUploadCard-uploadFileHistoryDesc-bodyEmpty">
                                <img src={Empty} />
                                <p>No data available</p>
                              </div>
                            ))}
                        </div>
                        {tableDatastock && tableDatastock.length > 0 && (
                          <>
                            <div className="row pagination-main-container common-display-none-mobile">
                              <div className="col-xll-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex justify-content-end">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPagesstock}
                                    page={currentPagestock}
                                    variant="outlined"
                                    shape="rounded"
                                    onChange={(event, page) =>
                                      handleChangePagestock(event, page)
                                    }
                                  />
                                </Stack>
                              </div>
                            </div>

                          </>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export { BulkUpload as BulkUpload };

