import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { landingConstants } from '../../_constants';
import { REACT_APP_BASE_URL } from "../../constant";
import AddToCartGrey from "../../images/AddToCartGrey.svg";
import promoBanner from "../../images/PromoIconRed.svg";
import blankImage from "../../images/empty-image.png";

import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { orderActions } from "../../_actions/order.actions";
import { productActions } from "../../_actions/product.actions";

import { useCallback } from 'react';
import { landingPageActions } from '../../_actions/landing.actions';
import ApiList from "../../_constants/const.api";
import ApiProvider from "../../_services/ApiProvider";
import crossImage from '../../images/cross-icon.png';
import minusProdouctButton from "../../images/minus-product-button.png";
import noresultsfoundserach from "../../images/noresultsfoundserach.png";
import addProdouctButton from "../../images/plus-product-button.png";
// import Box from '@mui/material/Box';s
const userInformation = JSON.parse(localStorage.getItem('user'));
const useStyles = styled(({ theme }) => ({
  root: {
    flexGrow: 1,
    minHeight: "600px"
    // fontSize: '1rem'

  },
  inputOnly: {
    fontSize: '2rem',
    // padding: '1px'


  },
  [`& fieldset`]: {
    borderRadius: 15,
  },

  paper: {
    // padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ProductInfoModel = (props) => {
  const [showProdInfoModel, setShowProdInfoModel] = useState(props.showmemodal);
  const [showTaxInvoiceModel, setShowTaxInvoiceModel] = useState(props.showmemodal);
  const isNull = (value) => {
    return value == "" || value == undefined || value == null
  }

  const classes = useStyles();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [search, setSearch] = React.useState("");
  const [userQuery, setUserQuery] = useState("");
  const [emailErr, setEmailErr] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [userInfo, setUserInfo] = React.useState(userInformation);
  const promotionData = useSelector((state) => state.landing);
  const [addedToCardModal, setAddedToCardModal] = useState(false);
  const [orderreceived, setOrderreceived] = useState(props.rowvalue);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(0);
  const [itemdefaulstquantity, setItemdefaulstquantity] = useState(1);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [iscartactive, setIscartactive] = useState(false);
  const [cartupdateQuantity, setCartupdateQuantity] = useState(1);
  const [wishlistStatus, setwishlistStatus] = useState(false);
  const [carttoggleStatus, setCarttoggleStatus] = useState(true);
  const userState = useSelector((state) => state.users);
  const [isLiked, setIsLiked] = useState(false);
  const [totalproductcountvalue, setTotalproductcountvalue] = useState(0);
  let [loadingLoader, setLoadingLoader] = useState(false);
  const [keywordsearch, setKeywordsearch] = React.useState("");
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.landing);
  const [products, setProducts] = useState([]);
  const [selecteditemproducts, setSelecteditemproducts] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const lancode = localStorage.getItem("lancode");
  const userData = JSON.parse(localStorage.getItem("user"));
  const [selected, setSelected] = React.useState([]);
  const [selectedvalues, setSelectedvalues] = React.useState([]);
  const [updateValue, setUpdateValue] = useState(false);
  const [updateVal, setUpdateVal] = useState(false);



  const sendQuery = query => { searchItem(query) };
  const delayedQuery = useCallback(_.debounce(q => sendQuery(q), 500), []);

  const searchItem = (query) => {
    setUserInfo(JSON.parse(localStorage.getItem('user')))
    var userData = JSON.parse(localStorage.getItem('user'))
    var storeCode = !isNull(userData) ? userData.user.stores[0].storeCode : "";
    var token = !isNull(userData) ? userData.token : "";
    const payload = !isNull(storeCode) ? { "searchItem": query, "storeCode": storeCode, token: token, userId: props.rowvalue.userId, customerCode: props.rowvalue.usercustomercode } : { "searchItem": query }
    if (query.length !== 0 && query.length > 2) {
      setLoadingLoader(true)
      try {
        dispatch(landingPageActions.searchWithoutLogin("search", payload));
        localStorage.setItem('keywordsearch', query);
        setKeywordsearch(query);
      } catch (error) {
        setLoadingLoader(false)
      }

    } else {
      setLoadingLoader(false)
      setProducts(null)
    }
  }


  const setUpCartandSpecialPrices = (temp) => {
    let count = 1;
    let price;
    if (isNull(temp.conditionAmount)) {
      if (Number(temp.quantity1Scale1) <= count && Number(temp.quantity1Scale1) != 0) {
        price = Number(temp.price1Scale1)
      }
      if (Number(temp.quantity2Scale2) <= count && Number(temp.quantity2Scale2) != 0) {
        price = Number(temp.price2Scale2)
      }
      if (Number(temp.quantity3Scale3) <= count && Number(temp.quantity3Scale3) != 0) {
        price = Number(temp.price3Scale3)
      }
      if (Number(temp.quantity4Scale4) <= count && Number(temp.quantity4Scale4) != 0) {
        price = Number(temp.price4Scale4)
      }
      if (Number(temp.quantity5Scale5) <= count && Number(temp.quantity5Scale5) != 0) {
        price = Number(temp.price5Scale5)
      }
      // if (count < Number(temp.quantity5Scale5)) {
      //   price = Number(temp.price5Scale5)
      // }
    }
    return price;

  }

  const setuporderquantityinformation = (itemsinformation) => {
    let quantitytobereturned = null;
    props?.rowvalue?.orderitems.map((iteminorder) => {
      if (iteminorder.articaleCode == itemsinformation.articaleCode) {
        quantitytobereturned = iteminorder.cartQuantity;
      }
      else {
        // quantitytobereturned = null;
      }
    }
    );
    return quantitytobereturned;

  }


  React.useEffect(() => {
    // debugger
    setUserInfo(JSON.parse(localStorage.getItem('user')))
    if (isNull(userQuery)) {
      dispatch(noDataFound(null));
      function noDataFound(data) {
        return { type: landingConstants.SEARCH_WITHOUT_LOGIN_NO_DATA_FOUND, data };
      }
      setProducts(null)
    }
    else {
      if (!isNull(searchState.searchWithoutLogin)) {
        setLoadingLoader(false)
        if (searchState && searchState.searchWithoutLogin.data && searchState.searchWithoutLogin.data.result.length > 0) {

          const newData = searchState.searchWithoutLogin.data.result.map((items) => {
            items.currentUnitPrice = setUpCartandSpecialPrices(items);
            if (isNull(items.conditionAmount)) {
              items.currentUnitPrice = setUpCartandSpecialPrices(items);
            } else {
              items.currentUnitPrice = items.conditionAmount;
            }
            return items;
          });


          const newdatawithorderinfo = newData.map((items) => {
            items.cartordequantityifpresent = setuporderquantityinformation(items);
            return items;
          });
          setProducts(newdatawithorderinfo);
        }

        // setProducts(searchState.searchWithoutLogin.data);
        setTotalproductcountvalue(searchState?.searchWithoutLogin?.data?.totalCount);


      }
      else {
        setProducts(null);
      }
    }
  }, [searchState.searchWithoutLogin])



  const clearInput = () => {
    setProducts(null)
    setUserQuery("")
    // alert("WIP")

  }

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
      <span>
        {parts.filter(part => part).map((part, i) => (
          regex.test(part) ? <mark key={i} style={{ background: "none", color: "black", "padding": "0px" }}>{part}</mark> : <span key={i}>{part}</span>
        ))}
      </span>
    )
  }

  const hiddenFileInputProdInfo = useRef(null);
  const prodInfoModalClick = (event) => {
    hiddenFileInputProdInfo.current.click();
  };
  const prodInfoModalChange = (event) => {
    const fileUploadedProdInfo = event.target.files[0];
    props.handleFileProdInfo(fileUploadedProdInfo);
  };


  const AddItemToCart = async (items, index) => {
    //debugger;
    let temp = products;
    let count = isNull(items.cartordequantityifpresent) ? 0 : Number(items.cartordequantityifpresent)
    let price = items.currentUnitPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= count && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= count && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= count && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= count && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= count && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartordequantityifpresent = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // setProducts(temp)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      setProducts(temp);

    }

    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload =
      {
        "orderId": props.rowvalue.orderId,
        "orderItems": [
          {
            "articaleCode": items.articaleCode,
            "cartQuantity": count
          }]
      };

      dispatch(orderActions.addOrderItems("payload", payload));
      // props.updateorder();
      setLoading(false);


    } else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
    // setAddedToCardModal(true)
    setProductData(items)

  }

  const updateQuantity = async (e, type, itemDetails, index) => {
    //e.preventDefault()
    // debugger;
    let temp = products;
    // let count = Number(itemDetails.cartordequantityifpresent)
    let count = itemDetails.cartordequantityifpresent;
    count = type == "add" ? count + 1 : count - 1
    let price = itemDetails.currentUnitPrice;
    // count = type == "add" ? count + 1 : count > 1 && count - 1
    // count >= 1 && setCartQuantity(count)
    setCartQuantity(count)
    if (isNull(temp.conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= count && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= count && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= count && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= count && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= count && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartordequantityifpresent = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setProducts(temp);

    } else {
      temp[index].cartQuantity = count
      temp[index].productPrice = temp[index].conditionAmount
      setProductPrice(price)
      setProducts(temp)
      // setUpdatedEffect(!updateEffect)
    }
    // if ((count == 1) && (type == "minus")) {
    //   setLoading(true)
    //   let payload = {
    //     "orderId": props.rowvalue.orderId,
    //     "orderItems": [
    //       {
    //         "articaleCode": itemDetails.articaleCode,
    //         "cartQuantity": 0
    //       }]
    //   }
    //   dispatch(orderActions.addOrderItems("payload", payload));
    //   // props.updateorder();

    // } else {
    //   if (count) {
    if (count && count !== "0") {
      setLoading(true)
      let payload = {

        "orderId": props.rowvalue.orderId,
        "orderItems": [
          {
            "articaleCode": itemDetails.articaleCode,
            "cartQuantity": count
          }]
      }
      dispatch(orderActions.addOrderItems("payload", payload));
    }
    if ((count == "0" || count < 1) && count !== "") {
      setLoading(true)
      const payload2 = {
        "orderId": props.rowvalue.orderId,
        "articaleCode": itemDetails.articaleCode
      }
      ApiProvider.post(ApiList.API_URL_TO_REMVOE_ORDER_ITES, payload2).then((response) => {
        if (response) {
          setLoading(false)

        } else {
          setLoading(false)
        }

      })
    }

    //  props.updateorder();

    // }
    // }
  }


  const AddItemToWishList = (status, items) => {

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }
      dispatch(productActions.addProductToWishList("payload", payload))
      dispatch(productActions.getWishListInformation("payload", {}, `?limit=100000&skip=0`));
      let payload2 = {
        storeCode: items.articaleCode,
        articleCode: items.storeCode
      }
      dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)
    } else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }

  }

  const routeToProductDetailsPage = (items) => {
    if (!isNull(userData)) {
      history.push("/productdetailscustomer/" + items.articaleCode, { items: items, storeCode: items.storeCode });                           // history.push("/productdetailscustomer")                  
      window.location.reload();
      props.openSearchPopUpClosed();
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  // Handling added to cart modal close
  const addedToCartModalClose = () => {
    setAddedToCardModal(false);
    setCarttoggleStatus(carttoggleStatus)
  }

  const updateProductValue = (products) => {
    setUpdateVal(!updateVal)
    // props.updatedProductsValue(products)
  }

  const handleClick = (event, articlecode, alocatedItem) => {
    setSelectedvalues(prevSelectedData => {
      const index = prevSelectedData.findIndex(item => item.articaleCode === articlecode);
      if (index === -1) {
        return [...prevSelectedData, {
          articaleCode: articlecode,
          cartQuantity: alocatedItem
        }];
      }
      else {
        const updatedData = [...prevSelectedData]; updatedData[index] = {
          articaleCode: articlecode,
          cartQuantity: alocatedItem
        };
        return updatedData;
      }
    });
    const objValue = {
      articaleCode: articlecode,
      cartQuantity: alocatedItem,
    };
    // setSelectedvalues([...selectedvalues, objValue]);

  };

  const saveselectionproduct = (event) => {
    additemsinorder(selectedvalues, props.row);
    closeButton();
  }

  const additemsinorder = (orderItems, orderdetails) => {
    if (orderItems.length > 0) {
      setLoading(true)
      const payload = {
        "orderId": orderdetails.orderId,
        orderItems
      }
      dispatch(orderActions.addOrderItems("payload", payload));
      props.updateorder();
      setLoading(false);
    }
  }
  const handleIsLiked = (value) => {
    setIsLiked(value)
  }

  const handlecarttoggle = (value) => {
    setIscartactive(value)

  }

  const handleupdateQuantity = (value) => {
    setCartupdateQuantity(value)
  }

  const closeButton = (event) => {
    setShowTaxInvoiceModel(false);
    props.closeproductmodal();
    props.updateTabelData();
    // props.updateorder();

  }

  return (
    <>
      <Modal
        className="taxInvoice"
        show={showTaxInvoiceModel}
        onHide={() => setShowTaxInvoiceModel(false)}
        centered
      >
        <div>
          <Modal.Body>
            <div className="row align-items-center mb-4">
              <div className="col-md-10">
                <h5>
                  <img src={AddToCartGrey} />
                  <span>Product Info</span>
                </h5>
              </div>
              <div className="col-md-2">
                <img src={crossImage} className="cross-image-product-modal" onClick={(event) => closeButton(event)} />
              </div>
            </div>
            <div className="taxInvoice-search">
              <FormControl style={{ width: "100%" }} >
                <OutlinedInput
                  sx={{ borderRadius: "30px", paddingTop: "10px", paddingBottom: "10px" }}
                  placeholder="Search product by Name, SKU, Category, Article Code"
                  disableUnderline={true}
                  id="SearchBox"
                  // placeholder={t("Search.label")}
                  endAdornment={<InputAdornment sx={{ cursor: 'pointer' }} sposition="end" onClick={() => clearInput()}>{!isNull(userQuery) && <CloseIcon />}</InputAdornment>}
                  startAdornment={<InputAdornment sposition="end"><SearchIcon /></InputAdornment>}
                  value={userQuery}
                  className={classes.inputOnly}
                  onChange={(e) => {
                    setLoadingLoader(true)
                    setProducts(null)
                    setUserQuery(e.target.value);
                    delayedQuery(e.target.value);
                  }}
                  // inputRef={props.childInputRef}
                  autoFocus={true}
                />

              </FormControl>
              {isNull(products) && (userQuery) &&
                <Grid container spacing={0} className="SearchBarContainer">
                  <Grid item xs={12}>
                    <div className={"text-center"}>
                      <RotatingLines
                        strokeColor="#61A60E"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={loadingLoader}
                      />
                    </div>

                    {/* for loader */}
                    {!loadingLoader && <div>
                      <h6 className="all-product-heading">
                        {userQuery}
                      </h6>
                      <h1 className="no-found-result-text">{t("0productsfound.label")}</h1>
                      <div className='my-cart-is-empty-main-container'>
                        <div className='my-cart-is-empty-inner-container'>
                          <img src={noresultsfoundserach} className='my-cart-is-empty-inner-container my-cart-is-empty-search-not-round' height={100} width={150} />
                          <h6 className='my-cart-is-empty-heading'>{t("NoSearchResultFound.label")}!</h6>

                        </div>
                      </div>
                    </div>}
                    {!loadingLoader && <Typography variant='body1' className="product-lbl-name-tax-modal">{t("NoSearchResultFound.label")}!</Typography>}
                  </Grid >
                </Grid>
              }
            </div>
            {products ?

              <div className="new-order-list-table-main-container">
                <div className="table-order-counts">
                  {/* <Grid item xs={5} sm={5}>
                    <Box ><h6 className="new-order-list-table-order-counts">
                      {totalproductcountvalue} Products Found
                    </h6></Box></Grid> */}
                  <Grid item xs={3} sm={5}>
                    <Box >
                      <h6 className="table-selected-counts">
                      </h6></Box></Grid>
                </div>
              </div>
              : <></>}
            <div className="taxInvoice-ProdDetails-main-container">
              {/* <div className="row taxInvoice-ProdDetails-inner-container">
                <div className="col-sm-3 text-center pl-5 m-0 d-flex">
                  <span>
                    <h6 className="taxInvoice-ProdDetails-main-heading">Product</h6></span>
                </div>
                <div className="col-sm-2">
                  <h6 className="taxInvoice-ProdDetails-main-heading">Pack Size</h6>
                </div>
                <div className="col-sm-2">
                  <h6 className="taxInvoice-ProdDetails-main-heading">Unit Price</h6>
                </div>
                <div className="col-sm-2">
                  <h6 className="taxInvoice-ProdDetails-main-heading">Stock</h6>
                </div>
                <div className="col-sm-1">
                  <h6 className="taxInvoice-ProdDetails-main-heading">Quantity</h6>
                </div>
                <div className="col-sm-2">
                  <h6 className="taxInvoice-ProdDetails-main-heading">Total</h6>
                </div>

              </div> */}
            </div>
            {products && products.length > 0 && products.map((item, index) => (
              <div className="row">
                <div className="col-md-3 d-flex flex-row mt-4 pl-2 pr-0">
                  <div className="tax-modal-article-checkbox">
                    <Box >
                      {/* <Checkbox
                        color="primary"
                        onClick={(event) =>
                          handleClick(
                            event,
                            item.articaleCode,
                            itemdefaulstquantity
                          )
                        }
                      /> */}</Box>
                  </div>
                  <Box >
                    <div className="odmProduct-ProductDetails-Img">
                      <div className="odmProduct-ProductDetails-Img-badge">
                        {products &&
                          item.typeCode === "2" && (
                            <img
                              alt=""
                              src={promoBanner}
                              style={{ width: 30 }}
                            />
                          )}
                        {products &&
                          item.typeCode === "3" && (
                            <div className="promo-img-badge1">
                              <img
                                alt=""
                                src="../NewItemYellow.svg"
                                style={{ width: 20 }}
                              />
                            </div>
                          )}
                      </div>
                      <img
                        src={
                          (item.picture && item.picture.split(",")[0] && item.picture.split(",")[0] != undefined && item.picture.split(",")[0] != '') ?
                            (REACT_APP_BASE_URL +
                              "/api/download?" +
                              item.picture.split(",")[0]
                              ? REACT_APP_BASE_URL +
                              "/api/download?" +
                              item.picture.split(",")[0]
                              : blankImage)
                            : blankImage
                        }
                      />
                    </div>
                  </Box>
                  <Box >
                    <Box >
                      {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && <Typography variant='body1' className="tax-modal-article-code-heading"><Highlighted text={item.articaleCode} highlight={userQuery} /></Typography>}
                    </Box>
                    <div variant='body1' className="product-lbl-name-tax-modal" title=" Click to Product Details" onClick={() => routeToProductDetailsPage(item)}>
                      <Highlighted text={lancode === "en" ? item.consumerNameEN : item.consumerNameTH} highlight={userQuery} />
                    </div>
                  </Box>
                </div>
                <div className="col-md-2 text-center mt-4">
                  <Box >
                    <div variant='body1' className="product-lbl-name-tax-modal" >
                      {item.selectValues.length > 1 ?
                        <select
                          onChange={(e) => {
                            let selectedItem = item.selectValues.find(f => f.articaleCode === e.target.value);
                            let temp2 = products
                            temp2[index].picture = selectedItem.picture
                            temp2[index].price1Scale1 = selectedItem.price1Scale1
                            temp2[index].articaleCode = selectedItem.articaleCode
                            temp2[index].cartQuantity = selectedItem.cartQuantity
                            temp2[index].wishlistStatus = selectedItem.wishlistStatus
                            temp2[index].stock = selectedItem.stock
                            temp2[index].cartId = selectedItem.cartId
                            temp2[index].productPrice = isNull(selectedItem.conditionAmount) ? selectedItem.price1Scale1 : selectedItem.conditionAmount;
                            temp2[index].conditionAmount = selectedItem.conditionAmount
                            temp2[index].consumerNameEN = selectedItem.consumerNameEN
                            temp2[index].packSizeEN = selectedItem.packSizeEN
                            temp2[index].productTypeEN = selectedItem.productTypeEN
                            // temp2[index].aboutProductEN = selectedItem.abutProductEN
                            temp2[index].aboutProductEN = selectedItem.aboutProductEN
                            temp2[index].shelfLifeEN = selectedItem.shelfLifeEN
                            temp2[index].otherProductEN = selectedItem.otherProductEN
                            temp2[index].consumerNameTH = selectedItem.consumerNameTH
                            temp2[index].packSizeTH = selectedItem.packSizeTH
                            temp2[index].productTypeTH = selectedItem.productTypeTH
                            temp2[index].aboutProductTH = selectedItem.aboutProductTH
                            temp2[index].instructionTH = selectedItem.instructionTH
                            temp2[index].shelfLifeTH = selectedItem.shelfLifeTH
                            temp2[index].otherProductTH = selectedItem.otherProductTH
                            // temp2[index].cartordequantityifpresent = selectedItem.cartQuantity

                            setProducts(temp2)

                            updateProductValue(true)
                          }}
                        >
                          {
                            item.selectValues.map((i, ind) => {
                              // let selected = (i.select)?'selected':'';
                              // return(<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ?i.valueEN:i.valueTH}</option>);
                              return (<option selected={i.articaleCode === item
                                .articaleCode} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                            })
                          }
                        </select> : lancode === "en" ? <h3>{item.selectValues[0].valueEN}</h3> : <h3> {item.selectValues[0].valueTH}</h3>}
                    </div>
                  </Box>
                </div>
                <div className="col-md-2 text-center mt-4">
                  <Box >{!isNull(userData) && <span variant='body1' className="price-field">{"฿ " + (isNull(item.productPrice) ? isNull(item.conditionAmount) ? !isNull(item.price1Scale1) && item.price1Scale1 : item.conditionAmount : item.productPrice) + ".00"}{/* {!isNull(item.price1Scale1) && "฿ " + item.price1Scale1 + ".00"} */} </span>}</Box>
                </div>
                <div className="col-md-2 text-center mt-4">
                  <Box > {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (!isNull(item.stock) ? <span className='tax-modal-in-stock-heading'> {item.stock} in Stock </span> : <></>)} {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (isNull(item.stock) ? <span className='tax-modal-out-stock-heading'> {t("OutofStock.label")}</span> : <></>)}</Box>
                </div>
                <div className="col-md-3 text-center mt-4">
                  <Box >
                    {(item.cartordequantityifpresent == 0 || item.cartordequantityifpresent == null) ?
                      <button className="promo-btn-addtocart d-flex align-items-center justify-content-center" onClick={() => { AddItemToCart(item, index) }} style={{
                        width: "100%",
                      }}>
                        <img alt="" src="../AddToCartWhite.svg" />
                        {t("Addtocart.label")}
                      </button> : null}
                    {item.cartordequantityifpresent > 0 ?
                      <div className="all-product-add-to-cart-button">
                        <div className="search-bar-add-minus" style={{ padding: "5px" }}>
                          <div className="row text-center">
                            <div className="col-sm-4 col-5 d-flex align-items-center justify-content-start">
                              <img src={minusProdouctButton} height={23} width={23} onClick={(e) => { updateQuantity(e, "minus", item, index) }} className="all-products-add-minus-cursor-pointer-pointer" />
                            </div>
                            <div className="col-sm-4 col-2 p-0 m-0 d-flex align-items-center justify-content-center">
                              <h6 className="add-to-chart-count-search-bar">{item.cartordequantityifpresent}</h6>
                            </div>
                            <div className="col-sm-4 col-5 d-flex align-items-center justify-content-end">
                              <img src={addProdouctButton} height={23} width={23} onClick={(e) => { updateQuantity(e, "add", item, index) }} className="all-products-add-minus-cursor-pointer-pointer" />
                            </div>
                          </div>
                        </div>
                      </div>

                      : null}
                  </Box>
                </div>
                {/* <div className="col-md-2 text-center mt-4">
                  <Box >{!isNull(userData) && <span variant='body1' className="price-field">{"฿ " + (isNull(item.currentUnitPrice) * (item.cartordequantityifpresent) ? isNull(item.conditionAmount) * (item.cartordequantityifpresent) ? !isNull(item.price1Scale1) * (item.cartordequantityifpresent) && item.price1Scale1 * (item.cartordequantityifpresent) : item.conditionAmount * (item.cartordequantityifpresent) : item.currentUnitPrice * (item.cartordequantityifpresent)) + ".00"}</span>}</Box>
                </div> */}
              </div>
            ))}
          </Modal.Body>
          {/* <Modal.Footer>
            <button className="taxInvoice-cancelBtn" onClick={(event) => closeButton(event)}> Close</button>
          </Modal.Footer> */}
        </div>
      </Modal>
    </>
  );
};
export default ProductInfoModel;
