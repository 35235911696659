import React from 'react';
import './LatestOffer.css';
import { useTranslation, withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

const LatestOffer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="latestOffer">{t("฿200offon1stpurchaseCode.label")}</div>
  )
}


export {LatestOffer as LatestOffer}