import React from "react";
// import { ReactComponent as Logo } from "../../Header/Assets/SVG/Logo.svg";
import headerBellIcon from '../../images/bells-icon.png';
import arrowImage from '../../images/chevron-down.png';
import { Link, useHistory, useLocation, } from "react-router-dom";
import { useTranslation } from "react-i18next";
import betagroLogo from '../../images/logobetagro.png';
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userActions, landingPageActions, productActions } from "../../_actions";
import { ReactComponent as AddToCart } from "./Assets/SVG/AddToCart.svg";
import { ReactComponent as WishList } from "./Assets/SVG/WishList.svg";
import { ReactComponent as Dashboard } from "./Assets/SVG/Dashboard.svg";
import { ReactComponent as Orders } from "./Assets/SVG/Orders.svg";
import { ReactComponent as Password } from "./Assets/SVG/Password.svg";
import { ReactComponent as Logout } from "./Assets/SVG/Logout.svg";
import { ReactComponent as UserGrey } from "./Assets/SVG/UserGrey.svg";
import { ReactComponent as WishListGrey } from "./Assets/SVG/WishListGrey.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import "../../Header/Header.css";


const CommonTableHeader = () => {
    const logOut = () => {
        const payloadWithOutLogin = {
            typeCode: "2",
        };
        const payloadWithOutLoginNew = {
            typeCode: "3",
        };
        dispatch(userActions.logout());
        setIsUserLoggedIn(false);
        localStorage.removeItem("user");
        localStorage.removeItem("authorization");
        localStorage.removeItem("keepMeLoggedIn");
        localStorage.removeItem("phone");
        localStorage.removeItem("password");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("authorization");
        dispatch(
            landingPageActions.getPromotionWithoutLogin(
                "promotion",
                payloadWithOutLogin
            )
        );
        dispatch(
            landingPageActions.getNewItemsDataWithOutLogin(
                "newItem",
                payloadWithOutLoginNew
            )
        );
        history.push("/");
    };
    const isNull = (value) => {
        return value == "" || value == undefined || value == null;
    };
    const cartData = useSelector((state) => state.product);
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(true);
    const location = useLocation();
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const whishlistDetailsInformationList = useSelector(
        (state) => state.product.whishlistDetailsInformationList
      );
    const sessionUser = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : undefined;
    const userData = JSON.parse(sessionStorage.getItem("user"));
    const [cartCount, setCartCount] = useState(0);
    const [totalQuantityCount, setTotalQuantityCount] = useState(0);
    const [wishlistCount, setWishlistCount] = useState(0);
   const languageText = localStorage.getItem("lancode");
   /*
    React.useEffect(() => {
        let payload = {};
        let totalquantity = 0;
        isNull(cartData.cartDetails) &&
            location.pathname !== '/mycart' && dispatch(productActions.getCartData("payload", payload));
        isNull(whishlistDetailsInformationList) &&
        dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
        dispatch(landingPageActions.getAllCatSubAction());
        if (!isNull(cartData.cartDetails)) {
            setCartCount(cartData.cartDetails.data.length);
            cartData.cartDetails.data.map(items => {
                totalquantity = totalquantity + items.cartQuantity;
            });
            setTotalQuantityCount(totalquantity);
        }
        if (!isNull(whishlistDetailsInformationList)) {
            setWishlistCount(whishlistDetailsInformationList?.data.totalCount);
        }
        setTimeout(() => {
            if (
                localStorage.getItem("lancode") &&
                localStorage.getItem("lancode") !== ""
            ) {
            } else {
                localStorage.setItem("lancode", "th");
                i18n.changeLanguage("th");
            }
        }, 2000);
    }, [cartData.cartDetails, whishlistDetailsInformationList, cartData.cartQuantity]);
    */
    React.useEffect(() => {
        // if (sessionUser && sessionUser.user && sessionUser.user.isVerified) {
        //     setIsUserLoggedIn(sessionUser.user.isVerified);
        // }
    }, [isUserLoggedIn, userData]);

    return (<>
        {/* new order section header-section start from here */}
        <div className='new-order-list-header'>
            <div className='container-fuild'>
                <div className='row'>
                    <div className='col-sm-10 col-md-10 pr-0'>
                        <div className='new-order-list-navbar-left'>
                            <Link to="/">
                                <img src={betagroLogo} className='create-order-list-header-betagro-logo' />
                            </Link>
                                {/* <Logo className='new-order-list-header-betagro-logo' style={{width: '28%'}} /> */}
                            
                        </div>
                    </div>
                    <div className='col-sm-2 col-md-2 pl-0'>
                        <div className='new-order-list-navbar-right d-flex flex-row'>
                            {/* <img src={headerBellIcon} className='header-bell-icon' /> */}
                            {/* <span className="order-table-header-line"></span> */}
                            {isUserLoggedIn && (
                                <>
                                <div className="order-table-user-button"><span>{userData?.user?.firstNameEN ? userData.user.firstNameEN.split("")[0] :  ""}</span></div>
                                 <h6 className="order-table-customer-name">{userData?.user?.firstNameEN ? userData.user.firstNameEN :  ""}</h6>
                                </>

                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* new order section header-section end */}
    </>)
}
export { CommonTableHeader as CommonTableHeader };