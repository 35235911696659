import { Popper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { StyledEngineProvider } from '@mui/material/styles';
import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; //
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { landingPageActions } from "../../_actions/landing.actions";
import ApiProvider from "../../_services/ApiProvider";
import { REACT_APP_BASE_URL } from "../../constant";
import Search from "../../images/Search.png";
import arrowIcon from '../../images/small-chevron-down.png';
import ArrowGrey from "./Assets/SVG/ArrowGrey.svg";
import Calander from "./Assets/SVG/Calander.svg";
import "./OrderManagementFilter.css";

const OrderManagementFilter = (props) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [showDateRangePicker2, setShowDateRangePicker2] = useState(false);
  const menuDetails = useSelector((state) => state.landing.menuDetails);
  const [selectionDate, setSectionDate] = useState(false);
  const [currentSelectionStartDate, setCurrentSelectionStartDate] = useState();
  const [currentSelectionEndDate, setCurrentSelectionEndDate] = useState();
  const [selectionDate2, setSectionDate2] = useState(false);
  const [currentSelectionStartDate2, setCurrentSelectionStartDate2] =
    useState();
  const [currentSelectionEndDate2, setCurrentSelectionEndDate2] = useState();
  const [viewByOrderActive, setViewByOrderActive] = useState(true);
  const [Category, setCategory] = useState([]);
  const [categoryDefaultValue, setCategoryDefaultValue] = useState([]);
  const [SabCategory, setSabCategory] = useState([]);
  const [sabCategoryDefaultValue, setSabCategoryDefaultValue] = useState([]);
  const [channelDefaultValue, setChannelDefaultValue] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [selectionForSearch, setSelectionForSearch] = useState("Order No.");
  const [queryText, setQueryText] = useState("");
  // const [searchSelection, setSearchSelection] = useState();
  const [loading, setLoading] = useState(false);
  const [categoryvalues, setCategoryvalues] = useState([]);
  const [categorySlug, setCategorySlug] = useState([]);

  const dispatch = useDispatch();

  const history = useHistory();
  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      history.push("/login");
    } else {
      if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
        history.push("/");
      }
    }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      dispatch(landingPageActions.getAllCatSubAction());
    }
  }, []);
  React.useEffect(() => {
    if (menuDetails?.data) {
      setCategoryvalues(menuDetails?.data);
      setLoading(false);
    } else {
      setCategoryvalues([]);
      setLoading(false);
    }
  }, [menuDetails]);

  const handleSubCatSelect = (selectedList) => {
    props.filterToConfirmList({
      categoryDefaultValue: channelDefaultValue,
      orderDate: {
        startDate: currentSelectionStartDate,
        endDate: currentSelectionEndDate,
      },
      deliveryDate: {
        startDate: currentSelectionStartDate2,
        endDate: currentSelectionEndDate2,
      },
      deliveryTime: deliveryTime,
      // "categorySlug": categoryDefaultValue,
      subCategorySlug: selectedList,
    });
    // setSelectedValues(selectedList);
  };
  const handleSelect = (ranges) => {
    setSectionDate(true);
    setCurrentSelectionStartDate(ranges.selection.startDate);
    setCurrentSelectionEndDate(ranges.selection.endDate);
    props.filterToConfirmList({
      channelDefaultValue: channelDefaultValue,
      orderDate: {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      },
      deliveryDate: {
        startDate: currentSelectionStartDate2,
        endDate: currentSelectionEndDate2,
      },
      deliveryTime: deliveryTime,
    });
    setShowDateRangePicker(false);
  };
  const handleSelect2 = (ranges) => {
    setSectionDate2(true);
    setCurrentSelectionStartDate2(ranges.selection.startDate);
    setCurrentSelectionEndDate2(ranges.selection.endDate);
    props.filterToConfirmList({
      channelDefaultValue: channelDefaultValue,
      orderDate: {
        startDate: currentSelectionStartDate,
        endDate: currentSelectionEndDate,
      },
      deliveryDate: {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      },
      deliveryTime: deliveryTime,
    });
    setShowDateRangePicker2(false);
  };
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const selectionRange2 = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  // const [age, setAge] = React.useState("");


  const [orderStartDate, setOrderStartDate] = useState(new Date());
  const [orderEndDate, setOrderEndDate] = useState(new Date());
  const [userQuery, setUserQuery] = useState("");

  const [deliverystartDate, setDeliveryStartDate] = useState(new Date());
  const [deliveryEndDate, setDeliveryEndDate] = useState(new Date());
  const userData = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : undefined;
  React.useEffect(
    () => {
      getCategories();
    },
    [
      /* props.filterToConfirmList */
    ]
  );

  const getCategories = () => {
    const payload = {
      storeCode:
        userData && userData.user ? userData.user.stores[0].storeCode : "",
    };
    ApiProvider.post(REACT_APP_BASE_URL + "/api/product/filters", payload).then(
      (response) => {
        setCategory(response.data.data.category);
      }
    );
  };

  const SearchAlgo = (query, selectionForSearch) => {
    if (
      (query.length > 2 && query.length > queryText.length) ||
      (queryText.length && query.length < queryText.length)
    ) {
      if (selectionForSearch === "Order No.") {
        props.searchItemByAttr({ searchOrderNo: query });
      } else if (selectionForSearch === "Customer Name") {
        props.searchItemByAttr({
          searchCustomerName: query,
        });
      } else if (selectionForSearch === "Customer ID") {
        props.searchItemByAttr({
          searchCustomerCode: query,
        });
      } else if (selectionForSearch === "Product") {
        props.searchItemByAttr({
          searchItem: query,
        });
      }
    } else {
    }
    setQueryText(query);
  };
  const toggleViewByProduct = () => {
    setViewByOrderActive(false);
    // dispatch(orderActions.toggleComponent(false))
    props.toggleViewByProduct();
  };

  const toggleViewByOrder = () => {
    setViewByOrderActive(true);
    // dispatch(orderActions.toggleComponent(true))
    props.toggleViewByOrder();
  };

  return <>
    <StyledEngineProvider injectFirst>
      <div className="OrderManagementFilter">
        {/* row one start here */}
        <div className="row2 d-flex align-items-center marginTop30">
          <div className="col-lg-8 col-md-8">
            <div className="OrderManagementFilter-order">
              <div className="row">
                <div className="col-lg-3 col-md-3 pr-0">
                  <div className="OrderManagementFilter-orderType">
                    <FormControl variant="standard" sx={{}}>
                      <Select
                        variant="outlined"
                        sx={{
                          height: "44px",
                          fontSize: "20px",
                        }}
                        value={selectionForSearch}
                        onChange={(e) => {
                          setSelectionForSearch(e.target.value);
                          setUserQuery("");
                        }}
                        displayEmpty
                        inputProps={{ IconComponent: () => <img src={arrowIcon} className="order-number-search-arrow-icon" /> }}>
                        <MenuItem
                          value="Order No."
                          sx={{
                            fontSize: "20px",
                            color: "#989CA6",
                          }}
                        >
                          Order No.
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontSize: "20px",
                            color: "#989CA6",
                          }}
                          value={"Customer Name"}
                        >
                          Customer Name
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontSize: "20px",
                            color: "#989CA6",
                          }}
                          value={"Customer ID"}
                        >
                          Customer ID
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontSize: "20px",
                            color: "#989CA6",
                          }}
                          value={"Product"}
                        >
                          Product
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 pl-0 order-management-autocomplete">
                  <input
                    type="text"
                    value={userQuery}
                    onChange={(e) => {
                      setUserQuery(e.target.value);
                      SearchAlgo(e.target.value, selectionForSearch);
                    }}
                    placeholder={"Search by " + selectionForSearch}
                    className="search-by-order-number-input"
                  />
                  <img
                    src={Search}
                    className="search-by-order-number-input-icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 8 p-0">
            <div className="OrderManagementFilter-orderBtn">
              <button
                className={
                  viewByOrderActive == false
                    ? "view-by-order-active-button view-by-order-border-radius"
                    : "view-by-order-disable-button view-by-order-border-radius"
                }
                onClick={toggleViewByOrder}
              >
                View By Product
              </button>
              <button
                className={
                  viewByOrderActive == true
                    ? "view-by-order-active-button view-by-product-border-radius"
                    : "view-by-order-disable-button view-by-product-border-radius"
                }
                onClick={toggleViewByProduct}
              >
                View By Order
              </button>
            </div>
          </div>
        </div>

        {/* row one end here */}
        {/* row two start here */}
        <div className="row mt-25">
          <div className="col-lg-8 col-md-8">
            <div className="row">
              <div className="col-lg-6 col-md-6 category-filter-main-container">
                <label>Category</label>
                <Autocomplete
                  multiple
                  id={Math.random().toString(36).substring(7)}
                  onOpen={() => {
                  }}
                  options={Category}
                  popupIcon={<img src={arrowIcon} className="category-filter-arrow-icon" />}
                  getOptionLabel={option => option['categoryEN']}
                  defaultValue={categoryDefaultValue}
                  onChange={(e, value) => {
                    props.filterShippedList({ "categoryDefaultValue": value })
                    setCategoryDefaultValue(value)
                    setSabCategory(value[0].subCategory)
                    setSabCategoryDefaultValue(null)
                  }}
                  disabled={true}
                  openOnFocus
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                    />
                  )}
                  PopperComponent={(props) => {
                    return <Popper {...props} placement="bottom" />;
                  }}
                />
                {/* <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={Category}
                  getOptionLabel={(option) => option.categoryEN}
                  defaultValue={categoryDefaultValue}
                  onChange={(e, value) => {
                    props.filterToConfirmList({
                      "categoryDefaultValue": value,
                      "orderDate": { "startDate": currentSelectionStartDate, "endDate": currentSelectionEndDate },
                      "deliveryDate": { "startDate": currentSelectionStartDate2, "endDate": currentSelectionEndDate2 },
                      "deliveryTime": deliveryTime,
                      "categorySlug": [value]
                    })
                    const categorySlug = value.map(item => item.key);
                    const groupedData = _.groupBy(value[0], 'categorySlug');


                      const options = value.map(category => {
                        return {
                          cat: category.categoryEN,
                          key: category.subCategory.map(subCategory => subCategory.subCategorySlug)
                        };
                      });

                      const outputArray = options.flatMap(item => {
                        return item.key.map(key => {
                          return { cat: item.cat, key };
                        });
                      });

                    setCategorySlug(categorySlug)
                    setCategoryDefaultValue(value)
                    setSabCategory(outputArray)
                    setSabCategoryDefaultValue(null)
                  }}
                  openOnFocus
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: '#61A60E' }}
                        checked={selected}
                      />
                      {option.categoryEN}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Category" />
                  )}
                /> */}
              </div>
              <div className="col-lg-6 col-md-6 pl-0 sub-category-filter-main-container">
                <label>Subcategory</label>
                <Autocomplete
                  multiple
                  id={Math.random().toString(36).substring(7)}
                  onOpen={() => {
                  }}
                  disabled={true}
                  popupIcon={<img src={arrowIcon} className="category-filter-arrow-icon" />}
                  defaultValue={sabCategoryDefaultValue}
                  options={SabCategory}
                  getOptionLabel={option => option['subCategoryEN']}
                  onChange={(e, value) => {
                    // 
                    props.filterShippedList({
                      "categoryDefaultValue": categoryDefaultValue,
                      "sabCategoryDefaultValue": value
                    })
                    setSabCategoryDefaultValue(value)
                  }}
                  openOnFocus
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                    />
                  )}
                  PopperComponent={(props) => {
                    return <Popper {...props} placement="bottom" />;
                  }}
                />
                {/* <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  defaultValue={categoryvalues}
                  options={SabCategory}
                  onSelect={handleSubCatSelect}
                  getOptionLabel={(option) => option.categoryEN}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: '#61A60E' }}
                        checked={selected}
                      />
                      {option.categoryEN}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Category" />
                  )}
                /> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="row">
              <div className="col-lg-7 col-md-7 pl-0 channel-category-main-container">
                <label>Channel</label>
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: "150px",
                    fontSize: "20px",
                    borderRadius: "8px",
                  }}>
                  <Select
                    variant="outlined"
                    sx={{
                      minWidth: "150px",
                      fontSize: "20px",
                      borderRadius: "8px",
                      color: "#464B55",
                      padding: "0",
                      height: "36px",
                    }}
                    onChange={(e) => {
                      props.filterToConfirmList({
                        channelDefaultValue: e.target.value,
                        orderDate: {
                          startDate: currentSelectionStartDate,
                          endDate: currentSelectionEndDate,
                        },
                        deliveryDate: {
                          startDate: currentSelectionStartDate2,
                          endDate: currentSelectionEndDate2,
                        },
                        deliveryTime: deliveryTime,
                      });
                      setChannelDefaultValue(e.target.value);
                      //
                    }}
                    value={channelDefaultValue}
                    displayEmpty
                    inputProps={{ IconComponent: () => <img src={arrowIcon} className="delivery-time-arrow-image" /> }}>
                    <MenuItem value="">All Channel</MenuItem>
                    <MenuItem value="Food Service">Food Service</MenuItem>
                    <MenuItem value="Industry">Industry</MenuItem>
                    <MenuItem value="Traditional-Trade">
                      Traditional Trade
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        {/* row two end here */}
        <div className="row mt-25">
          <div className="row">
            <div className="col-lg-3 col-md-3 order-date-filter-main-container">
              <div className="OrderManagementFilter-datepicker">
                <label>Order Date</label>
                <div className="OrderManagementFilter-datepicker-date">
                  <button
                    className="OrderManagementFilter-datepicker-dateButton"
                    onClick={() => setShowDateRangePicker(true)}
                  >
                    <img src={Calander} />
                    {selectionDate
                      ? `${moment(currentSelectionStartDate).format("DD/MM/YYYY")} - ${moment(currentSelectionEndDate).format("DD/MM/YYYY")}`
                      : "Select Date"}
                    <img src={ArrowGrey} />
                  </button>
                  {showDateRangePicker ? (
                    <div className="order-date-calendar">
                      <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 pl-0 delivery-date-filter-main-container">
              <div className="OrderManagementFilter-dateicker">
                <label>Delivery Date</label>
                <div className="OrderManagementFilter-datepicker-date">
                  <button
                    className="OrderManagementFilter-datepicker-dateButton"
                    onClick={() => setShowDateRangePicker2(true)}
                  >
                    <img src={Calander} />
                    {selectionDate2
                      ? `${moment(currentSelectionStartDate2).format("DD/MM/YYYY")} - ${moment(currentSelectionEndDate2).format("DD/MM/YYYY")}`
                      : "Select Date"}
                    <img src={ArrowGrey} />
                  </button>
                  {showDateRangePicker2 ? (
                    <div className="delivery-date-calendar">
                      <DateRangePicker
                        ranges={[selectionRange2]}
                        onChange={handleSelect2}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 pl-0 delivery-inner-date-filter-main-container">
              <div className="OrderManagementFilter-select">
                <label>Delivery Time</label>
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: "150px",
                    fontSize: "20px",
                    height: "36px",
                  }}>
                  <Select
                    variant="outlined"
                    sx={{
                      width: "150px",
                      fontSize: "20px",
                      borderRadius: "8px",
                      color: "#464B55",
                      padding: "0",
                      height: "36px",
                    }}
                    value={deliveryTime}
                    // onChange={handleChange}
                    onChange={(e) => {
                      props.filterToConfirmList({
                        channelDefaultValue: channelDefaultValue,
                        orderDate: {
                          startDate: currentSelectionStartDate,
                          endDate: currentSelectionEndDate,
                        },
                        deliveryDate: {
                          startDate: currentSelectionStartDate2,
                          endDate: currentSelectionEndDate2,
                        },
                        deliveryTime: e.target.value,
                      });
                      setDeliveryTime(e.target.value);
                      //debugger
                    }}
                    displayEmpty
                    inputProps={{ IconComponent: () => <img src={arrowIcon} className="delivery-time-arrow-image" /> }}>
                    <MenuItem value="">All Time</MenuItem>
                    <MenuItem value={"9.00 - 12.00"}>09.00 - 12.00</MenuItem>
                    <MenuItem value={"12.00 - 15.00"}>12.00 - 15.00</MenuItem>
                    <MenuItem value={"15.00 - 18.00"}>15.00 - 18.00</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {props.filterToConfirmList.length > 0 && (
              <div className="col-lg-3 col-md-3 pl-0 d-flex align-items-center justify-content-end mt-5 p-0 filter-order-per-page-image-main-container">
                <label style={{ width: "unset" }} className="m-0">
                  Order per page &nbsp;
                </label>
                <FormControl
                  variant="outlined"
                  sx={{
                    minWidth: "60px",
                    fontSize: "20px",
                    borderRadius: "8px",
                    color: "#464B55",
                    padding: "0",
                    height: "36px",
                  }}>
                  <Select
                    variant="outlined"
                    value={props.rowsPerPage}
                    onChange={props.handleChangeRowsPerPage}
                    displayEmpty
                    inputProps={{ IconComponent: () => <img src={arrowIcon} className="filter-order-per-page-image" /> }}
                    sx={{
                      width: "60px",
                      fontSize: "20px",
                      borderRadius: "8px",
                      color: "#464B55",
                      padding: "0",
                      height: "36px",
                      "&:hover": {
                        "&& fieldset": {
                          border: "2px solid #61A60E;"
                        }
                      }
                    }}>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledEngineProvider>
  </>;
};

export { OrderManagementFilter as OrderManagementFilter };

