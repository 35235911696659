import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Base64 } from '../../_helpers/commanFunction'
import CancelOrderModal from '../../ModalScreens/CancelOrderModal/CancelOrderModal'
import ApiProvider from "../../_services/ApiProvider";
import ApiList from "../../_constants/const.api";
import Loading from "../../SharedComponent/Loader/Loader";

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCancell, setOpenCancel] = useState(false);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fulfill = () => {
    const payload = {orderId:props.row.orderId }       
            setLoadingLoader(true) 
          ApiProvider.post(ApiList.API_URL_FOR_ADMIN_REORDER, payload).then((response) => {
            if (response) {        
              history.push("/createOrder/" + Base64.encode(response?.data?.data?.orderId.toString()),{from:4});                    
                setLoadingLoader(false)
            }else{
                setLoadingLoader(false)
            }

        })
    handleClose();
  };
  const cancel = () => {
    setOpenCancel(true)
    handleClose();
  };
  const toConfirm = () => {

  }
  // const routeToOrderDetailsPage = (items) => {
  //   history.push("/createOrder/" + Base64.encode(items.orderId.toString()), {orderDetails:items});
  // }

  const style = {
    menu: {
      borderRadius: 12
    },
    menuItem: {
      width: 160,
      boxShadow: 'none',
      color: "#989CA6",
      fontSize: 20
    },
    listStyle: {
      backgroundColor: 'red',
      borderRadius: 0,
      boxShadow: 'none'
    },
    innerDivStyle: {
      borderRadius: 0,
      boxShadow: 'none'
    }
  };

  return (
    <>
     {loadingLoader && (<Loading />)}
      <div id="vvvvvv">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{marginTop: 4}}
        >
          <MoreVertIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={style.menu}
        >
          <MenuItem key="1" 
          onClick={fulfill} style={style.menuItem} 
          // onClick={(event) => routeToOrderDetailsPage(props.row, event)}
          >
            Reorder
          </MenuItem>
          {/* <MenuItem key="2" onClick={cancel}>
        Cancel order
        </MenuItem> */}
        </Menu>
      </div>
      {openCancell && (
        <CancelOrderModal
          row={props.row}
          open={openCancell}
          setOpenCancel={setOpenCancel}
          firstLabel="Cancel order?"
          secondLabel="Are you sure you want to cancel this order?"
          firstBtnLabel="No, keep order"
          secondBtnLabel="Yes, cancel order"
          toConfirm={toConfirm}
        />
      )}
    </>
  );
}
