
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from "@mui/material/Pagination";
import Select from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import * as React from 'react';
import arrowIcon from '../../images/small-chevron-down.png';
import ActionMenu from "./Action";

import gearImage from '../../images/gear.png';
import userAvatar from '../../images/table-user.png';



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.headerAlign}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <>
                            {headCell.sortable ?
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel> : <>{headCell.label}</>

                            }
                        </>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export const convertToCurrencyFormat = (value = 0) => {
    value = value === "null" ? 0 : value;

    const currency = Intl.NumberFormat("en-us").format(Number(value).toFixed(2));

    let afterDecimal = currency.includes(".") ? currency.split(".")[1] : "00";

    if (afterDecimal.length === 1) {

        afterDecimal = `${afterDecimal}0`;

    }

    return `${currency.split(".")[0]}.${afterDecimal}`;
};

export default function CompletedOrderTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('orderCreateDate');
    // const [selected, setSelected] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    React.useEffect(() => {
        setRows(props.tableData)
    }, [props.tableData])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        props.setsetSortFn(property, isAsc ? -1 : 1);
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            const newSelectedexcel = rows;
            props.setExcelselectedData(newSelectedexcel);
            props.setSelected(newSelected);
            return;
        }
        props.setSelected([]);
        props.setExcelselectedData([]);
    };

    const handleClick = (event, name, row) => {

        const selectedIndex = props.selected.indexOf(name);
        let newSelected = [];
        let newSelectedexcel = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(props.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(props.selected.slice(1));
        } else if (selectedIndex === props.selected.length - 1) {
            newSelected = newSelected.concat(props.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                props.selected.slice(0, selectedIndex),
                props.selected.slice(selectedIndex + 1),
            );
        }

        if (selectedIndex === -1) {
            newSelectedexcel = newSelectedexcel.concat(props.excelselectedData, row);
        } else if (selectedIndex === 0) {
            newSelectedexcel = newSelectedexcel.concat(props.excelselectedData.slice(1));
        } else if (selectedIndex === props.excelselectedData.length - 1) {
            newSelectedexcel = newSelectedexcel.concat(props.excelselectedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedexcel = newSelectedexcel.concat(
                props.excelselectedData.slice(0, selectedIndex),
                props.excelselectedData.slice(selectedIndex + 1),
            );
        }
        props.setSelected(newSelected);
        props.setExcelselectedData(newSelectedexcel);
    };


    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => props.selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const renderCell = (row) => {
        //debugger
        return (
            <>
                {
                    props?.headCells && props?.headCells?.length > 0 && props.headCells.map(headObj => {
                        if (headObj.id === "status") {
                            return (
                                <>
                                    <TableCell align={headObj.align}><span className='order-table-completed'>{row[headObj.id] === 'completed' ? 'Completed' : null}</span></TableCell>
                                </>
                            )
                        }
                        else if (headObj.id === "action") {
                            return (
                                <>
                                    <TableCell align={headObj.align}>
                                        <ActionMenu row={row} />
                                    </TableCell>
                                </>
                            )
                        }
                        else if (headObj.id === "totalOrderPrice") {
                            return (
                                <>
                                    <TableCell align={headObj.align}>฿ {convertToCurrencyFormat(row[headObj.id])}
                                    </TableCell>
                                    {/* <TableCell align={headObj.align}>฿ {Intl.NumberFormat("en-us").format(Number(row[headObj.id]).toFixed(3))}</TableCell> */}
                                </>
                            )
                        }

                        else if (headObj.id === "createduserfirstnameen") {
                            return (
                                <>
                                    <TableCell align={headObj.align}>
                                        {row["createduserrole"] === "Customer" ?
                                            <>
                                                <img src={userAvatar} className='table-created-employer' /> Customer </>
                                            :
                                            <>
                                                <img src={gearImage} className='table-created-employer' />
                                                {row[headObj.id]}
                                            </>
                                        }
                                    </TableCell>
                                </>
                            )
                        }
                        else {
                            let text = row[headObj.id]
                            if (headObj?.id.includes('+')) {
                                let colName = headObj.id.split("+");
                                text = row[colName[0]] + ' ' + row[colName[1]]
                            }
                            return (
                                <>
                                    <TableCell align={headObj.align} className='common-table-overflow-ellipsis'>{text}</TableCell>
                                </>
                            )
                        }


                    })
                }

            </>

        )
    }

    const totalPages = props && props.totalCount ? Math.ceil((parseInt(props.totalCount) / parseInt(props.rowsPerPage))) : null;

    return (
        <>
            <div className='common-table-material'>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={props.selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={props.headCells}
                        />
                        <TableBody>
                            {rows?.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) => handleClick(event, row.id, row)}
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        {renderCell(row)}
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
    <TableRow
        style={{
            height: (dense ? 33 : 53) * emptyRows,
        }}
    >
        <TableCell colSpan={6} />
    </TableRow>
)} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='row order-table-pagination-main-container'>
                    <div className='col-sm-7 order-table-pagination-inner-container'>
                        <div className="d-flex flex-row">
                            <h6 className='order-table-pagination-count'>1-{props.rowsPerPage} of {props.totalCount}</h6>
                            <h6 className='order-table-order-per-page'>Order per page</h6>
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    variant="outlined"
                                    value={props.rowsPerPage}
                                    onChange={props.handleChangeRowsPerPage}
                                    displayEmpty
                                    sx={{
                                        "&:hover": {
                                            "&& fieldset": {
                                                border: "2px solid #61A60E;"
                                            }
                                        }
                                    }}
                                    disabled={props.totalCount == 0 || props.totalCount == "0"}
                                    inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ position: "absolute", width: 11, height: 7, left: 37, bottom: 37, cursor: "pointer" }} /> }}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={40}>40</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {/* pagination-section start from here */}
                    <div className='col-sm-5 order-table-pagination-inner-container p-0 m-0'>
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                // page={ undefined }
                                variant="outlined"
                                shape="rounded"
                                onChange={(event, page) => props.handleChangePage(event, page)}
                            // onChange={props.handleChangePage}
                            />
                        </Stack>
                    </div>
                    {/* pagination-section end */}
                </div>
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            </div>
        </>
    );
}
