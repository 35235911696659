import { uploadfileConstants } from "../_constants";
const initReducers = {
  getuploadfileList: null,
  getuploadfileError: null,
  getuploadfilepriceList: null,
  getuploadfilepriceError: null,
  getuploadfilespecialpriceList: null,
  getuploadfilespecialpriceError: null,
  getuploadfilestockList: null,
  getuploadfilestockError: null,
  getuploadfileSaleList: null,
  getuploadfileSaleError: null,
  getuploadfileBannerList: null,
  getuploadfileBannerError: null,
  getuploadfileMobileList: null,
  getuploadfileMobileError: null,

};
export function uploadfile(state = initReducers, action) {
  switch (action.type) {

    case uploadfileConstants.GET_UPLOAD_FILE_PRICE_REQUEST_INFORMATION:
      return {
        ...state,
        getuploadfilepriceList: action.data,
        getuploadfilepriceError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_PRICE_SUCCESS_INFORMATION:
      return {
        ...state,
        getuploadfilepriceList: action.data,
        getuploadfilepriceError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_PRICE_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        getuploadfilepriceList: action.data,
        getuploadfilepriceError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_PRICE_FAILURE_INFORMATION:
      return {
        ...state,
        getuploadfilepriceList: action.data,
        getuploadfilepriceError: action.error,
      };



    case uploadfileConstants.GET_UPLOAD_FILE_SPECIAL_PRICE_REQUEST_INFORMATION:
      return {
        ...state,
        getuploadfilespecialpriceList: action.data,
        getuploadfilespecialpriceError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_SPECIAL_PRICE_SUCCESS_INFORMATION:
      return {
        ...state,
        getuploadfilespecialpriceList: action.data,
        getuploadfilespecialpriceError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_SPECIAL_PRICE_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        getuploadfilespecialpriceList: action.data,
        getuploadfilespecialpriceError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_SPECIAL_PRICE_FAILURE_INFORMATION:
      return {
        ...state,
        getuploadfilespecialpriceList: action.data,
        getuploadfilespecialpriceError: action.error,
      };




    case uploadfileConstants.GET_UPLOAD_FILE_STOCK_REQUEST_INFORMATION:
      return {
        ...state,
        getuploadfilestockList: action.data,
        getuploadfilestockError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_STOCK_SUCCESS_INFORMATION:
      return {
        ...state,
        getuploadfilestockList: action.data,
        getuploadfilestockError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_STOCK_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        getuploadfilestockList: action.data,
        getuploadfilestockError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_STOCK_FAILURE_INFORMATION:
      return {
        ...state,
        getuploadfilestockList: action.data,
        getuploadfilestockError: action.error,
      };


    case uploadfileConstants.GET_UPLOAD_FILE_REQUEST_INFORMATION:
      return {
        ...state,
        getuploadfileList: action.data,
        getuploadfileError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_SUCCESS_INFORMATION:
      return {
        ...state,
        getuploadfileList: action.data,
        getuploadfileError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        getuploadfileList: action.data,
        getuploadfileError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_FAILURE_INFORMATION:
      return {
        ...state,
        getuploadfileList: action.data,
        getuploadfileError: action.error,
      };




    case uploadfileConstants.GET_UPLOAD_FILE_SALE_REQUEST_INFORMATION:
      return {
        ...state,
        getuploadfileSaleList: action.data,
        getuploadfileSaleError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_SALE_SUCCESS_INFORMATION:
      return {
        ...state,
        getuploadfileSaleList: action.data,
        getuploadfileSaleError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_SALE_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        getuploadfileSaleList: action.data,
        getuploadfileSaleError: action.error,
      };

    case uploadfileConstants.GET_UPLOAD_FILE_SALE_FAILURE_INFORMATION:
      return {
        ...state,
        getuploadfileSaleList: action.data,
        getuploadfileSaleError: action.error,
      };


    case uploadfileConstants.GET_UPLOAD_FILE_BANNER_REQUEST_INFORMATION:
      return {
        ...state,
        getuploadfileBannerList: action.data,
        getuploadfileBannerError: action.error,
      };
    case uploadfileConstants.GET_UPLOAD_FILE_BANNER_SUCCESS_INFORMATION:
      return {
        ...state,
        getuploadfileBannerList: action.data,
        getuploadfileBannerError: action.error,
      };
    case uploadfileConstants.GET_UPLOAD_FILE_BANNER_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        getuploadfileBannerList: action.data,
        getuploadfileBannerError: action.error,
      };
    case uploadfileConstants.GET_UPLOAD_FILE_BANNER_FAILURE_INFORMATION:
      return {
        ...state,
        getuploadfileBannerList: action.data,
        getuploadfileBannerError: action.error,
      };


    case uploadfileConstants.GET_UPLOAD_FILE_MOBILE_REQUEST_INFORMATION:
      return {
        ...state,
        getuploadfileMobileList: action.data,
        getuploadfileMobileError: action.error,
      };
    case uploadfileConstants.GET_UPLOAD_FILE_MOBILE_SUCCESS_INFORMATION:
      return {
        ...state,
        getuploadfileMobileList: action.data,
        getuploadfileMobileError: action.error,
      };
    case uploadfileConstants.GET_UPLOAD_FILE_MOBILE_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        getuploadfileMobileList: action.data,
        getuploadfileMobileError: action.error,
      };
    case uploadfileConstants.GET_UPLOAD_FILE_MOBILE_FAILURE_INFORMATION:
      return {
        ...state,
        getuploadfileMobileList: action.data,
        getuploadfileMobileError: action.error,
      };


    default:
      return state;
  }
}
