import React from 'react';
import { connect } from 'react-redux';

import Card from '@mui/joy/Card';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { userActions } from '../_actions';

import { IconContext } from "react-icons";
import { BsFillGeoAltFill, BsPerson } from "react-icons/bs";
import { FaRegPlusSquare } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import './style.css';


class MyProfile extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        // this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            isChecked: true,



        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }


    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;
        return (<center>
            <Header />
            <h3>My Profile</h3>
            <Card variant="outlined" sx={{ width: 1000, backgroundColor: "white", border: "none" }}>
                <div className="col-md-12">
                    <form name="form" onSubmit={this.handleSubmit}>
                        <center>
                            <table width="100%">
                                <tr><td><h4><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}> <BsPerson /></IconContext.Provider> Personal Information</h4></td></tr>
                                <tr><td>
                                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                        <label htmlFor="username" style={{ alignItems: 'left', }}>Name</label> <label htmlFor="profilename" style={{ alignItems: 'left', }}>Sandee Usanachitt</label>
                                    </div></td></tr><tr><td>
                                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                            <label htmlFor="password" style={{ alignItems: 'left', }}>Customer ID</label><label htmlFor="password" style={{ alignItems: 'left', }}>JD 1343429023</label>
                                        </div></td></tr>
                                <tr><td>
                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <label htmlFor="password" style={{ alignItems: 'left', }}>Phone Number</label><label htmlFor="password" style={{ alignItems: 'left', }}>081-123-3456</label>
                                    </div></td></tr>
                                <tr><td>
                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <label htmlFor="password" style={{ alignItems: 'left', }}>Assigned Branch</label><label htmlFor="password" style={{ alignItems: 'left', }}><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}><BsFillGeoAltFill /></IconContext.Provider>323 location Province Bangok District Thung Song Hung Subdistrict Lak si Postal Code 10210 note optional </label>
                                    </div></td></tr>
                                <tr><td>
                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <label htmlFor="password" style={{ alignItems: 'left', }}>Email address</label><label htmlFor="password" style={{ alignItems: 'left', }}>sandee@gmail.com</label><label for="check4" className="togButton"><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}><MdEdit /> </IconContext.Provider></label>
                                    </div></td></tr>
                                <tr><input type="checkbox" className="togCheck" id="check4" />
                                    <div className="togContent">
                                        hdsdasdlahsdlhasld
                                    </div></tr>
                            </table></center>
                    </form>
                </div></Card>
            <br></br>
            <Card variant="outlined" sx={{ width: 1000, backgroundColor: "white", border: "none" }}>
                <div className="col-md-12">
                    <form name="form" onSubmit={this.handleSubmit}>
                        <center>
                            <table width="100%">
                                <tr><td><h4><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}><BsFillGeoAltFill /></IconContext.Provider>Address</h4></td></tr>
                                <tr><td>
                                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                        <label htmlFor="username" style={{ alignItems: 'left', }}>323 location Province Bangok District Thung Song Hung Subdistrict Lak si Postal Code 10210 note optional </label> <label htmlFor="profilename" style={{ alignItems: 'left', }}><input type="checkbox" id="keepmeloggedin" name="keepmeloggedin" value="keepmeloggedin"
                                            defaultChecked={this.state.isChecked}
                                            onChange={this.toggleChange} />Default Shipping Address <input type="checkbox" id="keepmeloggedin" name="keepmeloggedin" value="keepmeloggedin"
                                                defaultChecked={this.state.isChecked}
                                                onChange={this.toggleChange} />Default Billing Address</label><label for="check" className="togButton"><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}><MdEdit /> </IconContext.Provider></label>
                                    </div></td></tr>
                                <tr><input type="checkbox" className="togCheck" id="check" />
                                    <div className="togContent">
                                        hdsdasdlahsdlhasld
                                    </div></tr>
                                <tr><td>
                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <label htmlFor="password" style={{ alignItems: 'left', }}>323 location Province Bangok District Thung Song Hung Subdistrict Lak si Postal Code 10210 note optional </label>
                                        <label for="check1" className="togButton"><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}><MdEdit /> </IconContext.Provider></label>
                                    </div></td></tr>
                                <tr><input type="checkbox" className="togCheck" id="check1" />
                                    <div className="togContent">
                                        hdsdasdlahsdlhasld
                                    </div></tr>
                                <tr><td>
                                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <label htmlFor="password" style={{ alignItems: 'left', }}><label for="check2" className="togButton"><IconContext.Provider value={{ color: "green", className: "global-class-name", size: "15px" }}><FaRegPlusSquare /> </IconContext.Provider></label> Add New Address</label>
                                    </div></td></tr>
                                <tr><input type="checkbox" className="togCheck" id="check2" />
                                    <div className="togContent">
                                        hdsdasdlahsdlhasld
                                    </div></tr>
                            </table></center>
                    </form>
                </div></Card>


            <Footer /></center>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedMyProfile = connect(mapState, actionCreators)(MyProfile);
export { connectedMyProfile as MyProfile };
