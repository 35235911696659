import Check from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { myAccountAction, orderActions, productActions } from "../_actions";
import ApiList from "../_constants/const.api";
import { Base64, convertToCurrencyFormat } from '../_helpers/commanFunction';
import ApiProvider from "../_services/ApiProvider";
import { REACT_APP_BASE_URL } from "../constant";
import { Footer } from '../Footer';
import { Header } from '../Header';
import AddCancellation from '../images/add-cancellation.png';
import CalendarImage from '../images/calendar-icon.png';
import CancelledOrderImage from '../images/cancelled-order.png';
import ComfirmedOrderImage from '../images/comfirmed-order.png';
import CompletedOrderImage from '../images/completed.png';
import EditImage from '../images/edit.png';
import blankImage from "../images/empty-image.png";
import GearImage from '../images/gear.png';
import Tag from '../images/offer-tag.png';
import OrderCancelled from '../images/order-cancelled.svg';
import OrderCompleted from '../images/order-completed.svg';
import OrderConfirmed from '../images/order-confirmed-stepper.svg';
import OrderPlaceImage from '../images/order-place.png';
import OrderPlace from '../images/order-placed-stepper.svg';
import ClockImage from '../images/order-status-clock.png';
import OrderStepper from '../images/order-stepper.svg';
import PaymentSummary from "../images/payment-summary-icon.png";
import ShippedOrderImage from '../images/shipped.png';
import ThankYouImage from "../images/thank-you-puchase-image.png";
import ThankYouForPurchaseModal from '../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal';
import MyAccountHamburgerMenu from '../MyOrder/MyAccountHamburgerMenu/MyAccountHamburgerMenu';
import Loading from "../SharedComponent/Loader/Loader";

const MyOrderDetails = () => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const lancode = localStorage.getItem("lancode");
    const userData = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : undefined;
    const dispatch = useDispatch();
    const store = userData ? userData.user.stores[0].storeCode : ''
    const history = useHistory();
    const [tableData, setTableData] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [showFullNote, SetShowFullNote] = useState(false);
    const [shippingAddress, setShippingAddress] = useState();
    const [cancelledInput, setCancelledInput] = useState(false);
    const [cancelDetails, setCancelDetails] = useState("");
    const [saveTextAreaValue, setSaveTextAreaValue] = useState("");
    const orderDetails = useSelector((state) => state.myAccount.orderDetails);
    const reorderDetails = useSelector((state) => state.order.reOrderProduct);
    const location = useLocation()
    const [mainHeading, setMainHeading] = useState("");
    const [subHeading1, setSubHeading1] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [reorderStatus, setReorderStatus] = useState(false);
    const orderId = location.pathname.split('/').slice(1)[1];

    const handleReset = () => {
        setActiveStep(0);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    // stepper function start from here

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#D3D6DA',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 26,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));
    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 26,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgbargba(97, 166, 14, 1) 0%,rgba(97, 166, 14, 1) 50%,rgba(97, 166, 14, 1) 100%)',
                marginRight: 12,
                marginLeft: 12
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgba(97, 166, 14, 1) 0%,rgba(97, 166, 14, 1) 50%,rgba(97, 166, 14, 1) 100%)',
                marginRight: 12,
                marginLeft: 12
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 1.8,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#D3D6DA',
            borderRadius: 1,
            marginRight: 12,
            marginLeft: 12
        },
    }));

    // Handling mobile connectors
    const ColorlibConnectorMobile = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 26,
            width: 2,
            height: 1.4
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgbargba(97, 166, 14, 1) 0%,rgba(97, 166, 14, 1) 50%,rgba(97, 166, 14, 1) 100%)',
                marginRight: 0,
                marginLeft: 0,
                width: 2,
                height: 1.4,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgba(97, 166, 14, 1) 0%,rgba(97, 166, 14, 1) 50%,rgba(97, 166, 14, 1) 100%)',
                marginRight: 0,
                marginLeft: 0,
                width: 2,
                height: 1.4,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 1.4,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#D3D6DA',
            borderRadius: 1,
            marginRight: 0,
            marginLeft: 0,
            width: 2,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#BFC2C9',
        zIndex: 1,
        color: '#fff',
        width: 56,
        height: 56,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient( 136deg, rgba(97, 166, 14, 1) 0%, rgba(97, 166, 14, 1) 50%, rgba(97, 166, 14, 1)',
            //   boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient( 136deg, rgba(97, 166, 14, 1) 0%, rgba(97, 166, 14, 1) 50%, rgba(97, 166, 14, 1) 100%)',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <img src={OrderPlace} className='stepper-image-size-orderplace' />,
            2: <img src={OrderConfirmed} className='stepper-image-size-orderconfirmed' />,
            3: <img src={OrderStepper} className='stepper-image-size-orderstepper' />,
            4: <img src={OrderCompleted} className='stepper-image-size-ordercompleted' />
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    function ColorlibCancelStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <img src={OrderPlace} className='stepper-image-size-orderplace' />,
            2: <img src={OrderCancelled} className='stepper-image-size-ordercancelled' />
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    const steps = ['Order Placed', 'Order Confirmed', 'Order Shipped', 'Order Completed'];
    const stepsCancelled = ['Order Placed', 'Order Cancelled'];

    // stepper function end

    React.useEffect(() => {
        setLoading(true)
        // setDefaultLoad(true)
        if (!userData) {
            history.push('/login')
        }
        if (!orderId) {
            history.push('/myorder')
        }
        // if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
        let payload = {
            "storeCode": store,
            "flags": { "product": true }
        }
        if (orderId) {
            dispatch(myAccountAction.getOrderDetails(Base64.decode(orderId), payload))
        }

        // }
    }, [])

    const reOrder = (e) => {
        setLoading(true)
        e.preventDefault()
        if (orderId) {
            const payload = {
                "storeCode": store,
                "orderId": Base64.decode(orderId)
            }
            dispatch(orderActions.reOrderProduct("", payload))
            setReorderStatus(true)



        }
    }

    React.useEffect(() => {
        if (reorderDetails?.code === 200 && reorderStatus) {
            dispatch(productActions.getCartData("payload", {}))
            setLoading(false)
            setOpenAlert(true)
            setMainHeading(t("ProductAddedtoCart.label"))
            setSubHeading1(t("Thankyouforreorderingthisproduct!.label"))
        }
    }, [reorderDetails])
    React.useEffect(() => {
        if (orderDetails?.data) {
            const filterData = JSON.parse(JSON.stringify(orderDetails.data))
            filterData.status = filterData.status === 'to_confirm' ? 'new' : filterData.status
            // if(filterData?.ordertrack && filterData.ordertrack.length && filterData.ordertrack.length > 1 && filterData.ordertrack[1].orderStatus ==="to_confirm"){
            //     filterData.ordertrack.splice(1 , 1)
            // }
            let statusord = [];
            filterData.ordertrack = filterData.ordertrack.filter(i => {
                if (i.orderStatus != 'to_confirm' && i.orderStatus != 'draft' && !statusord.includes(i.orderStatus)) {
                    statusord.push(i.orderStatus);
                    return true;
                } else {
                    return false;
                }
            });
            console.log('file', filterData.ordertrack)
            if (orderDetails?.data?.expired) {
                filterData.ordertrack[1] = { createdAt: moment(filterData.ordertrack[0]?.createdAt).add(24, 'hours') }
            }
            setTableData(filterData)
            setShippingAddress(JSON.parse(orderDetails.data.shippingAddress))
            setLoading(false)
            if (orderDetails.data.cancellationDetails) {
                setSaveTextAreaValue(true)
                setCancelDetails(orderDetails.data.cancellationDetails)
            } else {
                setCancelDetails("")
            }
            // 
        } else {
            setTableData([])
            setLoading(false)
            setShippingAddress({})

        }
    }, [orderDetails])

    const handleAlert = () => {
        setOpenAlert(false);
    };

    const routeToProductDetailsPage = (items) => {
        history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });
        // history.push("/productdetailscustomer", { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
    }

    const handleCancel = e => {
        setCancelDetails(e.target.value)
    }
    const saveCancel = (e) => {
        e.preventDefault()
        const payload = {
            "orderId": Base64.decode(orderId),
            "cancellationDetails": cancelDetails
        }
        // if(cancelDetails || tableData.cancellationDetails){
        if (cancelDetails) {
            setLoading(true)
            ApiProvider.post(ApiList.API_URL_TO_GET_CANCEL_ORDER, payload).then((response) => {
                if (response) {
                    let payload2 = {
                        "storeCode": store,
                        "flags": { "product": true }
                    }
                    dispatch(myAccountAction.getOrderDetails(Base64.decode(orderId), payload2))
                    setCancelledInput(false)
                } else {
                    setLoading(false)
                }

            })
        } else {
            setCancelledInput(false)
            setLoading(false)
        }

    };


    const getDate = (trackItem) => {
        if (trackItem?.createdAt) {
            return moment(trackItem.createdAt).format("DD/MM/YYYY")
        }
        else return null;
    }
    const getTime = (trackItem) => {
        if (trackItem?.createdAt) {
            return moment(trackItem.createdAt).format("HH:mm")
        }
        else return null;
    }
    const activeColorIndex = {
        new: 1,
        confirmed: 2,
        shipped: 3,
        completed: 4
    }
    return <>
        {loading && (<Loading />)}
        <Helmet>
            <title>Betagro Order Details</title>
        </Helmet>
        <Header />
        <ThankYouForPurchaseModal
            image={ThankYouImage}
            cart={true}
            buttonLabel={t("ok.label")}
            mainHeading="productAddedToCart.lable1"
            subHeading1="productAddedToCart.lable2"
            open={openAlert}
            ClickAction={handleAlert}
        />
        <div className='container-fluid' id="productDetails" data-testid="my-order-details-loading">
            <div className='row'>
                <div className='col-xl-3' data-testid="left-menu-section">
                    <MyAccountHamburgerMenu activeMenu="my-orders" />
                </div>
                <div className='col-xl-9 p-0 my-order-details-main-container overflow-hidden'>
                    <div className='my-order-details-inner-container'>
                        <div className="row">
                            <div className='col-md-12 col-xl-12 pl-3' data-testid="bread-crumb-section">
                                <div className='d-flex flex-row'>
                                    <div>
                                        <h6 className='my-order-details-main-container-breadcrumb-heading' onClick={() => history.push('/myorder')}>{t("MyOrders.label")}</h6>
                                    </div>
                                    <img src="/images/chevron-right.png" className='my-order-details-main-container-breadcrumb-image' />
                                    <h6 className='my-order-details-main-container-breadcrumb-heading' onClick={() => history.push('/myorder')}>{t("OrderDetails.label")} {tableData.orderNo}</h6>
                                </div>
                            </div>
                            <div className='my-order-detail-main-heading-section'>
                                <h6 className='my-order-details-main-container-main-heading'>{t("OrderDetails.label")} </h6>
                                <div className='my-order-details-reorder-button-main-container'>
                                    {(tableData.status === "cancelled" || tableData.status === "completed" || tableData.expired === true) && (
                                        <button className='my-order-details-reorder-button-inner-container' onClick={reOrder}>{t("Reorder.label")}</button>
                                    )}
                                </div>
                            </div>
                            {/* order-details-status-section start from here */}
                            <div className='col-xl-12 col-md-12 my-order-details-status-main-container common-display-none-mobile'>
                                <div>
                                    <div className='d-flex flex-row'>
                                        <div>
                                            <img src={Tag} className='my-order-details-status-image' />
                                        </div>
                                        <h6 className='my-order-details-status-order-number'>{t("OrderNo.label")}. {tableData.orderNo}</h6>
                                        <img src={CalendarImage} className='my-order-details-status-calendar-image' />
                                        <h6 className='my-order-details-status-order-date'>{t("Orderdate.label")} {tableData?.createdAt ? moment(tableData.createdAt).format("DD/MM/YYYY") : null}</h6>

                                        {tableData.status === 'new' && !tableData.expired && (
                                            <div className='my-order-details-order-placed-status-button'>
                                                <img src={OrderPlaceImage} className='my-order-details-order-placed-status-image' />
                                                <span className='my-order-details-order-placed-status-heading'>{t("OrderPlaced.label")}</span>
                                            </div>
                                        )}
                                        {tableData.status === 'confirmed' && (
                                            <div className='my-order-details-order-confirmed-status-button'>
                                                <img src={ComfirmedOrderImage} className='my-order-details-order-placed-status-image' />
                                                <span className='my-order-details-order-confirmed-status-heading'>{t("Confirmed.label")}</span>
                                            </div>
                                        )}
                                        {tableData.status === 'shipped' && (
                                            <div className='my-order-details-order-shipped-status-button'>
                                                <img src={ShippedOrderImage} className='my-order-details-order-placed-status-image' />
                                                <span className='my-order-details-order-shipped-status-heading'>{t("Shipped.label")}</span>
                                            </div>
                                        )}
                                        {tableData.status === 'completed' && (
                                            <div className='my-order-details-order-completed-status-button'>
                                                <img src={CompletedOrderImage} className='my-order-details-order-placed-status-image' />
                                                <span className='my-order-details-order-completed-status-heading'>{t("Completed.label")}</span>
                                            </div>
                                        )}
                                        {(tableData.status === 'cancelled' || tableData.expired) && (
                                            <div className='my-order-details-order-cancelled-status-button'>
                                                <img src={CancelledOrderImage} className='my-order-details-order-placed-status-image' />
                                                <span className='my-order-details-order-cancelled-status-heading'>{t("Cancelled.label")}</span>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            {/* order-details-status-section end */}
                            {/* mobile-order-details-status-section start from here */}
                            <div className="row p-0 m-0 align-items-center my-order-details-status-section-mobile" data-testid="order-details-information">
                                <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 pl-2 pr-0'>
                                    <img src={Tag} className='my-order-details-status-image' alt="tag" />
                                    <span className='my-order-details-status-order-number' data-testid="order-number-heading">{t("OrderNo.label")}. {tableData.orderNo}</span>
                                </div>
                                <div className='col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 pl-2 pr-0'>
                                    <img src={CalendarImage} className='my-order-details-status-calendar-image' />
                                    <span className='my-order-details-status-order-date'>{tableData?.createdAt ? moment(tableData.createdAt).format("DD/MM/YYYY") : null}</span>
                                </div>
                                <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 p-0'>
                                    {tableData.status === 'new' && !tableData.expired && (
                                        <div className='my-order-details-order-placed-status-button'>
                                            <img src={OrderPlaceImage} className='my-order-details-order-placed-status-image' />
                                            <span className='my-order-details-order-placed-status-heading'>{t("OrderPlaced.label")}</span>
                                        </div>
                                    )}
                                    {tableData.status === 'confirmed' && (
                                        <div className='my-order-details-order-confirmed-status-button'>
                                            <img src={ComfirmedOrderImage} className='my-order-details-order-placed-status-image' />
                                            <span className='my-order-details-order-confirmed-status-heading'>{t("Confirmed.label")}</span>
                                        </div>
                                    )}
                                    {tableData.status === 'shipped' && (
                                        <div className='my-order-details-order-shipped-status-button'>
                                            <img src={ShippedOrderImage} className='my-order-details-order-placed-status-image' />
                                            <span className='my-order-details-order-shipped-status-heading'>{t("Shipped.label")}</span>
                                        </div>
                                    )}
                                    {tableData.status === 'completed' && (
                                        <div className='my-order-details-order-completed-status-button'>
                                            <img src={CompletedOrderImage} className='my-order-details-order-placed-status-image' />
                                            <span className='my-order-details-order-completed-status-heading'>{t("Completed.label")}</span>
                                        </div>
                                    )}
                                    {(tableData.status === 'cancelled' || tableData.expired) && (
                                        <div className='my-order-details-order-cancelled-status-button'>
                                            <img src={CancelledOrderImage} className='my-order-details-order-placed-status-image' />
                                            <span className='my-order-details-order-cancelled-status-heading'>{t("Cancelled.label")}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* mobile-order-details-status-section end */}
                            {/* mobile-steppers-section start from here */}
                            <div className='col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 my-order-display-block-mobile'>
                                <div className='my-order-details-stepper-section' data-testid="order-track-stepper-section">
                                    <div>
                                        {tableData?.status && (
                                            <Stack>
                                                <Stepper orientation="vertical" activeStep={tableData?.status && tableData.status === 'cancelled' ? 1 : activeColorIndex[tableData.status]} connector={< ColorlibConnectorMobile />}>
                                                    {(tableData.status === 'cancelled' || tableData.expired) && (
                                                        stepsCancelled.map((label, index) => (
                                                            <Step key={label}>
                                                                <div className='stepper-status-main-container'>
                                                                    <div className='row align-items-center'>
                                                                        {
                                                                            tableData?.ordertrack && typeof tableData?.ordertrack[index] !== 'undefined' ?
                                                                                <>
                                                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6 pl-3 pr-0'>
                                                                                        <StepLabel StepIconComponent={ColorlibCancelStepIcon}>

                                                                                            <span className='stepper-status-main-heading'>
                                                                                                {
                                                                                                    label === 'Order Placed' ? t("OrderPlaced.label")
                                                                                                        : label === 'Order Cancelled' ? t("OrderCancelled.label")

                                                                                                            : null
                                                                                                }
                                                                                            </span>
                                                                                        </StepLabel>
                                                                                    </div>
                                                                                    <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 p-0'>
                                                                                        <img src={CalendarImage} className='stepper-status-image' /><span className='stepper-status-inner-heading'>{getDate(tableData?.ordertrack[index])}</span>
                                                                                    </div>
                                                                                    <div className='col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2 p-0'>
                                                                                        <img src={ClockImage} className='stepper-status-image' /><span className='stepper-status-inner-heading'>{getTime(tableData?.ordertrack[index])}</span>
                                                                                    </div>
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Step>
                                                        ))
                                                    )
                                                    }
                                                    {tableData.status !== 'cancelled' && !tableData.expired && (
                                                        steps.map((label, index) => {
                                                            return (
                                                                <Step key={label}>
                                                                    <div className='stepper-status-main-container'>
                                                                        <div className='row align-items-center'>
                                                                            <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6 pl-3 pr-0'>
                                                                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                                                    {
                                                                                        tableData?.ordertrack && typeof tableData?.ordertrack[index] !== 'undefined' ?
                                                                                            <span className='stepper-status-main-heading'>
                                                                                                {
                                                                                                    label === 'Order Placed' ? t("OrderPlaced.label")
                                                                                                        : label === 'Order Confirmed' ? t("OrderConfirmed.label")
                                                                                                            : label === 'Order Shipped' ? t("OrderShpped.label")
                                                                                                                : label === 'Order Completed' ? t("OrderCompleted.label")
                                                                                                                    : null
                                                                                                }
                                                                                            </span> : null}
                                                                                </StepLabel>

                                                                            </div>{
                                                                                tableData?.ordertrack && typeof tableData?.ordertrack[index] !== 'undefined' ?
                                                                                    <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 p-0'>
                                                                                        <img src={CalendarImage} className='stepper-status-image' /><span className='stepper-status-inner-heading'>{getDate(tableData?.ordertrack[index])}</span>
                                                                                    </div> : null}
                                                                            {
                                                                                tableData?.ordertrack && typeof tableData?.ordertrack[index] !== 'undefined' ?
                                                                                    <div className='col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2 p-0'>
                                                                                        <img src={ClockImage} className='stepper-status-image' /><span className='stepper-status-inner-heading'>{getTime(tableData?.ordertrack[index])}</span>
                                                                                    </div> : null}
                                                                        </div>
                                                                    </div>
                                                                </Step>
                                                            )
                                                        }

                                                        )

                                                    )
                                                    }


                                                </Stepper>
                                            </Stack>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* mobile-steppers-section end */}
                            {/* stepper-section start from here */}
                            <div className='col-xl-12 common-display-none-mobile'>
                                <div className='my-order-details-stepper-section'>
                                    <div>
                                        {tableData?.status && (
                                            <Stack sx={{ width: '100%' }} spacing={1}>
                                                <Stepper alternativeLabel activeStep={tableData?.status && tableData.status === 'cancelled' ? 1 : activeColorIndex[tableData.status]} connector={<ColorlibConnector />} >

                                                    {(tableData.status === 'cancelled' || tableData.expired) && (
                                                        stepsCancelled.map((label, index) => (
                                                            <Step key={label}>
                                                                <StepLabel StepIconComponent={ColorlibCancelStepIcon}></StepLabel>
                                                                <div className='stepper-status-main-container'>
                                                                    {
                                                                        tableData?.ordertrack && typeof tableData?.ordertrack[index] !== 'undefined' ?
                                                                            <>
                                                                                <h6 className='stepper-status-main-heading'>
                                                                                    {
                                                                                        label === 'Order Placed' ? t("OrderPlaced.label")
                                                                                            : label === 'Order Cancelled' ? t("OrderCancelled.label")

                                                                                                : null
                                                                                    }
                                                                                </h6>
                                                                                <img src={CalendarImage} className='stepper-status-image' /><span className='stepper-status-inner-heading'>{getDate(tableData?.ordertrack[index])}</span><br />
                                                                                <img src={ClockImage} className='stepper-status-image' /><span className='stepper-status-inner-heading'>{getTime(tableData?.ordertrack[index])}</span>
                                                                            </>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </Step>
                                                        ))

                                                    )
                                                    }
                                                    {tableData.status !== 'cancelled' && !tableData.expired && (
                                                        steps.map((label, index) => {
                                                            return (
                                                                <Step key={label}>
                                                                    <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                                                                    <div className='stepper-status-main-container'>
                                                                        {
                                                                            tableData?.ordertrack && typeof tableData?.ordertrack[index] !== 'undefined' ?
                                                                                <>
                                                                                    <h6 className='stepper-status-main-heading'>
                                                                                        {
                                                                                            label === 'Order Placed' ? t("OrderPlaced.label")
                                                                                                : label === 'Order Confirmed' ? t("OrderConfirmed.label")
                                                                                                    : label === 'Order Shipped' ? t("OrderShpped.label")
                                                                                                        : label === 'Order Completed' ? t("OrderCompleted.label")
                                                                                                            : null
                                                                                        }
                                                                                    </h6>
                                                                                    <img src={CalendarImage} className='stepper-status-image' /><span className='stepper-status-inner-heading'>{getDate(tableData?.ordertrack[index])}</span><br />
                                                                                    <img src={ClockImage} className='stepper-status-image' /><span className='stepper-status-inner-heading'>{getTime(tableData?.ordertrack[index])}</span>
                                                                                </>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                </Step>
                                                            )
                                                        }

                                                        )

                                                    )
                                                    }


                                                </Stepper>
                                            </Stack>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* stepper-section end */}
                            {/* cancellation-details-section start from here */}
                            {(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (tableData.status === "cancelled" || tableData.expired === true) && (
                                <div className='col-xl-12' data-testid="cancellation-section">
                                    <div className='my-order-details-cancellation-section'>
                                        <div className='my-order-details-cancellation-inner-section'>
                                            <div className='d-flex flex-row align-items-center'>
                                                <img src="/images/triangle-exclamation.png" className='my-order-details-cancellation-section-image' />
                                                <h6 className='my-order-details-cancellation-main-heading'>{t("cancelationDetail.label")}</h6>

                                                {(!saveTextAreaValue || tableData.cancellationDetails === null) ?
                                                    <>
                                                        <img src={AddCancellation} className='add-cancellation-image' onClick={() => setCancelledInput(true)} />
                                                        <h6 className='add-cancellation-heading' onClick={() => setCancelledInput(true)}>
                                                            {t("addDetails.label")} </h6>
                                                    </>
                                                    :
                                                    <>
                                                        <img src={EditImage} className='add-cancellation-image' onClick={() => setCancelledInput(true)} />
                                                        <h6 className='add-cancellation-heading' onClick={() => setCancelledInput(true)}>{t("Edit.label")}</h6>
                                                    </>
                                                }
                                                {/* {!saveTextAreaValue ? 
                                            <h6 className='add-cancellation-heading' onClick={() => setCancelledInput(true)}>
                                                Add Cancellation </h6> 
                                            : <h6 className='add-cancellation-heading' onClick={() => setCancelledInput(true)}>Edit</h6>} */}
                                            </div>
                                            {cancelledInput ?
                                                <div className='row align-items-center'>
                                                    <div className='col-md-12'>
                                                        <div className='my-order-details-cancelled-input-main-container'>
                                                            <textarea type="text" value={cancelDetails} className="my-order-details-cancelled-input-main-container" onChange={handleCancel} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2 col-sm-6 col-6'>
                                                        <button className={cancelDetails.length > 0 ? 'my-order-details-cancelled-save-button' : 'my-order-details-cancelled-save-button-disabled'} disabled={cancelDetails.length > 0 ? false : true} onClick={saveCancel}>{t("Save.label")}</button>
                                                    </div>
                                                    <div className='col-md-2 col-sm-6 col-6'>
                                                        <button
                                                            className='my-order-details-cancelled-cancel-button'
                                                            onClick={() => setCancelledInput(false)}
                                                        >{t("Cancel.label")}</button>
                                                    </div>
                                                </div> :
                                                <h6 className='my-order-details-cancellation-inner-heading'>
                                                    {tableData.cancellationDetails}</h6>
                                            }

                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* cancellation-details-section end */}
                            {/* product-information-section start from here */}
                            <div className='col-xl-12' data-testid="product-info-section">
                                <div className='my-order-details-product-information-section'>
                                    <div className='my-order-details-product-information-inner-section'>
                                        <div className='d-flex flex-row'>
                                            <img src="/images/cart-shopping.png" className='my-order-details-product-information-card-shopping-image' />
                                            <h6 className='my-order-details-product-information-main-heading'>{t("ProductInfo.label")}</h6>
                                        </div>
                                        <div className='row my-order-details-product-information-main-content'>
                                            <div className='col-xl-6 my-order-details-product-information-content'>
                                                <h6 className='my-order-details-product-information-content-header'>{t("productSort.label")}</h6>
                                            </div>
                                            <div className='col-xl-2 my-order-details-product-information-content text-center'>
                                                <h6 className='my-order-details-product-information-content-header'>{t("unitPrice.label")}</h6>
                                            </div>
                                            <div className='col-xl-2 my-order-details-product-information-content text-center'>
                                                <h6 className='my-order-details-product-information-content-header'>{t("Quantity.label")}</h6>
                                            </div>
                                            <div className='col-xl-2 my-order-details-product-information-content text-right'>
                                                <h6 className='my-order-details-product-information-content-header'>{t("Total.label")}</h6>
                                            </div>
                                        </div>
                                        <div className='my-order-details-product-info-gray-line' />
                                        {
                                            tableData?.orderitems
                                                ? tableData.orderitems.map((items, index) => {
                                                    const isLast = items.length - 1 === index;
                                                    return (

                                                        <>
                                                            {items.allocatedQtd != 0 ?
                                                                <>
                                                                    <div className='row my-order-details-inner-product-price'>
                                                                        <div className='col-xll-6 col-xl-6 col-md-6 col-sm-9 col-9 pr-0'>
                                                                            <div className='d-flex flex-row my-order-details-product-information-data'>
                                                                                <div className='my-order-details-product-information-image-border'>
                                                                                    <img
                                                                                        src={
                                                                                            (items.product.picture && items.product.picture.split(",")[0] && items.product.picture.split(",")[0] != undefined && items.product.picture.split(",")[0] != '') ?
                                                                                                (REACT_APP_BASE_URL +
                                                                                                    "/api/download?" +
                                                                                                    items.product.picture.split(",")[0]
                                                                                                    ? REACT_APP_BASE_URL +
                                                                                                    "/api/download?" +
                                                                                                    items.product.picture.split(",")[0]
                                                                                                    : blankImage)
                                                                                                : blankImage}
                                                                                        className='my-order-details-product-information-image'
                                                                                        onClick={() => routeToProductDetailsPage(items.product)}
                                                                                    />
                                                                                </div>
                                                                                <h6 className='my-order-details-product-information-heading' onClick={() => routeToProductDetailsPage(items.product)}>
                                                                                    {(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (
                                                                                        <><span className='my-order-details-articles-code'>{items.product.articaleCode}</span><br /></>
                                                                                    )}
                                                                                    {lancode === "en" && (
                                                                                        <>{items.product.consumerNameEN} {items?.product?.packSizeEN}</>
                                                                                    )}
                                                                                    {lancode === "th" && (
                                                                                        <>{items.product.consumerNameTH} {items?.product?.packSizeTH}</>
                                                                                    )}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-xll-2 col-xl-2 text-center common-display-none-mobile'>
                                                                            <h6 className='my-order-details-product-information-unit-price'>฿ {convertToCurrencyFormat(items.price)}</h6>
                                                                        </div>
                                                                        <div className='col-xl-2 text-center common-display-none-mobile'>
                                                                            <h6 className='my-order-details-product-information-unit-price'>{items.allocatedQtd !== null ? items.allocatedQtd : items.cartQuantity}</h6>
                                                                        </div>
                                                                        <div className='col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2 text-right common-display-none-mobile'>
                                                                            <h6 className='my-order-details-product-information-unit-price'>฿ {convertToCurrencyFormat(items.totalPrice)}</h6>
                                                                        </div>
                                                                        <div className='col-xll-2 col-xl-2 col-md-4 col-sm-3 col-3 text-center pl-0 my-order-display-block-mobile'>
                                                                            <h6 className='my-order-details-product-information-unit-price'>฿ {convertToCurrencyFormat(items.price)}</h6><br />
                                                                            <h6 className='my-order-details-product-information-quatity-value my-order-display-block-mobile'>x{items.allocatedQtd !== null ? items.allocatedQtd : items.cartQuantity}</h6>
                                                                        </div>
                                                                    </div>
                                                                    {!isLast ? <div className='my-order-details-product-info-inner-gray-line' /> : null}
                                                                </>
                                                                : null}
                                                        </>

                                                    )
                                                }) : null}




                                    </div>
                                </div>
                            </div>
                            {/* product-information-section end */}
                            {/* shipping-summary-section start from here */}
                            <div className='row common-display-none-mobile' data-testid="shipping-summary-section">
                                <div className='col-xl-6'>
                                    <div className='my-order-details-shipping-summary-section my-order-details-section-min-height'>
                                        <div className='d-flex flex-row'>
                                            <img src="/images/shipping.png" className='my-order-details-shipping-summary-section-image' />
                                            <h6 className='my-order-details-shipping-summary-section-heading'>{t("ShippingSummary.label")}</h6>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Recipient.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {lancode === "en" && (
                                                        <>{tableData?.userfirstnameen + " " + tableData?.userlastnameen}</>
                                                    )}
                                                    {lancode === "th" && (
                                                        <>{tableData?.userfirstnameth + " " + tableData?.userlastnameth}</>
                                                    )}
                                                </h6>
                                            </div>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("CustomerId.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>{t("id.label")}{tableData.usercustomercode}</h6>
                                            </div>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("phonenosmall.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>{tableData.userphone}</h6>
                                            </div>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Deliveryto.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {shippingAddress && (
                                                        <>
                                                            {shippingAddress?.addressTH},{" "}
                                                            {shippingAddress?.subDistrictTH},{" "}
                                                            {shippingAddress?.districtTH},{" "}
                                                            {shippingAddress?.provinceTH}{" "}
                                                            {shippingAddress?.postalCodeTH}
                                                        </>
                                                    )}
                                                    {/* 323 ซอย วิภาวดีรังสิต Thung Song Hong, Lak Si, Bangkok 10210 */}
                                                </h6>
                                            </div>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Deliveryfrom.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {/* {userData?.user.stores[0].storeNameTH},{" "} */}
                                                    {userData?.user.stores[0].addressTH},{" "}
                                                    {userData?.user.stores[0].subDistrictTH},{" "}
                                                    {userData?.user.stores[0].districtTH},{" "}
                                                    {userData?.user.stores[0].provinceTH}{" "}
                                                    {userData?.user.stores[0].postalCodeTH}
                                                </h6>
                                            </div>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Deliverydate.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>{moment(tableData.deliveryDate).format("DD/MM/YYYY")}</h6>
                                            </div>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Deliverytime.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>{tableData.deliveryTime}</h6>
                                            </div>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Payment.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {tableData.paymentMode === 'Bank transfer on delivery' ? t("Banktransferondelivery.label") : t("Cashondelivery.label")}
                                                </h6>
                                            </div>
                                            <div className='col-xl-4'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Noteadd.label")}</h6>
                                            </div>
                                            <div className='col-xl-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {tableData.userNote === "" ?
                                                        <>
                                                            -
                                                        </>
                                                        :
                                                        <>
                                                            {!showFullNote ?
                                                                <>
                                                                    {tableData?.userNote && tableData?.userNote.length && tableData?.userNote.length > 31
                                                                        ?
                                                                        <>
                                                                            {tableData.userNote.substring(0, 31)}
                                                                            <span onClick={() => SetShowFullNote(true)} className="cursor-pointer"> ...</span>
                                                                        </>
                                                                        : tableData.userNote}
                                                                </>
                                                                :
                                                                tableData.userNote
                                                            }
                                                        </>
                                                    }

                                                </h6>
                                            </div>
                                            {(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (
                                                <>
                                                    <div className='col-xl-4'>
                                                        <h6 className='my-order-details-shipping-summary-left-heading'>{t("createdBy.label")}</h6>
                                                    </div>
                                                    <div className='col-xl-8 my-order-details-shipping-summary-right-heading'>
                                                        <img src={GearImage} className='my-order-details-created-by-image' />
                                                        <span>

                                                            {lancode === "en" && (
                                                                <>{tableData.createduserfirstnameen}</>
                                                            )}
                                                            {lancode === "th" && (
                                                                <>{tableData.createduserfirstnameth}</>
                                                            )}

                                                        </span>
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                {/* shipping-summary-section end */}
                                {/* payment-section start from here */}
                                <div className='col-xl-6 pr-0' data-testid="payment-section">
                                    <div className='my-order-details-payment-section my-order-details-section-min-height'>
                                        <div className='my-order-details-payment-inner-section'>
                                            <div className='d-flex flex-row'>
                                                <img src={PaymentSummary} className='my-order-details-payment-image' />
                                                <h6 className='my-order-details-payment-heading'>{t("PaymentSummary.label")}</h6>
                                            </div>

                                            <div className='my-order-details-payment-custom-section'>
                                                <div className='row'>
                                                    <div className='col-xl-8'>
                                                        <h6 className='my-order-details-payment-custom-heading-left'>{t("Subtotal.label")} ({tableData?.orderitems?.length})</h6>
                                                    </div>
                                                    <div className='col-xl-4'>
                                                        <h6 className='my-order-details-payment-custom-heading-right'>฿ {convertToCurrencyFormat(tableData.totalOrderPrice)}</h6>
                                                    </div>
                                                    <div className='col-xl-8'>
                                                        <h6 className='my-order-details-payment-custom-heading-left mt-12'>{t("shippingFee.label")}</h6>
                                                    </div>
                                                    <div className='col-xl-4'>
                                                        <h6 className='my-order-details-payment-custom-heading-right mt-12'>฿ 0.00</h6>
                                                    </div>
                                                    <div className='col-xl-8'>
                                                        <h6 className='my-order-details-payment-custom-heading-left mt-12'>{t("Promotion.label")}</h6>
                                                    </div>
                                                    <div className='col-xl-4'>
                                                        <h6 className='my-order-details-payment-custom-heading-right mt-12'>-฿ 0.00</h6>
                                                    </div>
                                                    <div className='col-xl-12'>
                                                        <div className='my-order-details-payment-section-inner-gray-line mt-24' />
                                                    </div>
                                                    <div className='col-xl-8'>
                                                        <h6 className='my-order-details-payment-custom-total-heading mt-24'>{t("Total.label")}</h6>
                                                    </div>
                                                    <div className='col-xl-4'>
                                                        <h6 className='my-order-details-payment-custom-amount-heading mt-24'>฿ {convertToCurrencyFormat(tableData.totalOrderPrice)}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* payment-section end */}
                            </div>
                            {/* Mobile Shipping-section and payment section start from here */}
                            <div className='my-order-display-block-mobile'>
                                {/* payment-section start from here */}
                                <div className='col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pl-2 pr-2'>
                                    <div className='my-order-details-payment-section my-order-details-section-min-height'>
                                        <div className='my-order-details-payment-inner-section'>
                                            <div className='d-flex flex-row'>
                                                <img src={PaymentSummary} className='my-order-details-payment-image' />
                                                <h6 className='my-order-details-payment-heading'>{t("PaymentSummary.label")}</h6>
                                            </div>

                                            <div className='my-order-details-payment-custom-section'>
                                                <div className='row'>
                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                                        <h6 className='my-order-details-payment-custom-heading-left'>{t("Subtotal.label")} ({tableData.orderitems ? tableData.orderitems.length : null})</h6>
                                                    </div>
                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                                        <h6 className='my-order-details-payment-custom-heading-right'>฿ {convertToCurrencyFormat(tableData.totalOrderPrice)}</h6>
                                                    </div>
                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                                        <h6 className='my-order-details-payment-custom-heading-left mt-12'>{t("shippingFee.label")}</h6>
                                                    </div>
                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                                        <h6 className='my-order-details-payment-custom-heading-right mt-12'>฿ 0.00</h6>
                                                    </div>
                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                                        <h6 className='my-order-details-payment-custom-heading-left mt-12'>{t("Promotion.label")}</h6>
                                                    </div>
                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                                        <h6 className='my-order-details-payment-custom-heading-right mt-12'>-฿ 0.00</h6>
                                                    </div>
                                                    <div className='col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12'>
                                                        <div className='my-order-details-payment-section-inner-gray-line mt-24' />
                                                    </div>
                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                                        <h6 className='my-order-details-payment-custom-total-heading mt-24'>{t("Total.label")}</h6>
                                                    </div>
                                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                                        <h6 className='my-order-details-payment-custom-amount-heading mt-24'>฿ {convertToCurrencyFormat(tableData.totalOrderPrice)}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* payment-section end */}
                                <div className='col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pl-2 pr-2'>
                                    <div className='my-order-details-shipping-summary-section my-order-details-section-min-height'>
                                        <div className='d-flex flex-row'>
                                            <img src="/images/shipping.png" className='my-order-details-shipping-summary-section-image' />
                                            <h6 className='my-order-details-shipping-summary-section-heading'>{t("ShippingSummary.label")}</h6>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Recipient.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {lancode === "en" && (
                                                        <>{tableData?.userfirstnameen + " " + tableData?.userlastnameen}</>
                                                    )}
                                                    {lancode === "th" && (
                                                        <>{tableData?.userfirstnameth + " " + tableData?.userlastnameth}</>
                                                    )}
                                                </h6>
                                            </div>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("CustomerId.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>{t("id.label")}{tableData.usercustomercode}</h6>
                                            </div>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("phoneno.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>{tableData.userphone}</h6>
                                            </div>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Deliveryto.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {shippingAddress && (
                                                        <>
                                                            {shippingAddress?.addressTH},{" "}
                                                            {shippingAddress?.subDistrictTH},{" "}
                                                            {shippingAddress?.districtTH},{" "}
                                                            {shippingAddress?.provinceTH},{" "}
                                                            {shippingAddress?.postalCodeTH}
                                                        </>
                                                    )}
                                                    {/* 323 ซอย วิภาวดีรังสิต Thung Song Hong, Lak Si, Bangkok 10210 */}
                                                </h6>
                                            </div>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Deliveryfrom.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {userData?.user.stores[0].storeNameTH},{" "}
                                                    {userData?.user.stores[0].addressTH},{" "}
                                                    {userData?.user.stores[0].subDistrictTH},{" "}
                                                    {userData?.user.stores[0].districtTH},{" "}
                                                    {userData?.user.stores[0].provinceTH},{" "}
                                                    {userData?.user.stores[0].postalCodeTH}
                                                </h6>
                                            </div>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Deliverydate.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>{moment(tableData.deliveryDate).format("DD/MM/YYYY")}</h6>
                                            </div>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Deliverytime.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>{tableData.deliveryTime}</h6>
                                            </div>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Payment.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {tableData.paymentMode === 'Bank transfer on delivery' ? t("Banktransferondelivery.label") : t("Cashondelivery.label")}
                                                </h6>
                                            </div>
                                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                <h6 className='my-order-details-shipping-summary-left-heading'>{t("Note.label")}</h6>
                                            </div>
                                            <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8'>
                                                <h6 className='my-order-details-shipping-summary-right-heading'>
                                                    {tableData.userNote === "" ?
                                                        <>
                                                            -
                                                        </>
                                                        :
                                                        <>
                                                            {!showFullNote ?
                                                                <>
                                                                    {tableData?.userNote && tableData?.userNote.length && tableData?.userNote.length > 31
                                                                        ?
                                                                        <>
                                                                            {tableData.userNote.substring(0, 31)}
                                                                            <span onClick={() => SetShowFullNote(true)} className="cursor-pointer"> ...</span>
                                                                        </>
                                                                        : tableData.userNote}
                                                                </>
                                                                :
                                                                tableData.userNote
                                                            }
                                                        </>
                                                    }                                                    {/* {tableData.userNote} */}
                                                </h6>
                                            </div>
                                            {(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (
                                                <>
                                                    <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pr-0'>
                                                        <h6 className='my-order-details-shipping-summary-left-heading'>{t("createdBy.label")}</h6>
                                                    </div>
                                                    <div className='col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8 my-order-details-shipping-summary-right-heading'>
                                                        <img src={GearImage} className='my-order-details-created-by-image' />
                                                        <span className='created-by-right-side-content'>

                                                            {lancode === "en" && (
                                                                <>{tableData.createduserfirstnameen}</>
                                                            )}
                                                            {lancode === "th" && (
                                                                <>{tableData.createduserfirstnameth}</>
                                                            )}

                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* shipping-summary-section end */}

                            </div>
                            {/* Mobile Shipping-section and payment end */}

                        </div>
                    </div>
                </div>
            </div>
        </div >
        <Footer />
    </>
}
// export default withTranslation()(MyOrderDetails);
export { MyOrderDetails as MyOrderDetails };

