import React, { useEffect } from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import './thankYouForPuchaseModal.css';
import { useState } from 'react';
import thankYouPurchaseImage from '../../images/thank-you-puchase-image.png';
import DicardOrder from "../../images/dicard-order.png"

const ThankYouForPurchaseModal = (props) => {

  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    setOpenModal(false);
  }
  useEffect(() => {

  }, [props.orderNumber])
  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };
  return <>
    {props.open ?
      <div className='thank-you-purchase-modal-main-container'>
        <div className='thank-you-purchase-modal-inner-container'>
          <div className='thank-you-purchase-modal'>
            {!props.buttonClickedName && (
              <img src={isNull(props.image) ? thankYouPurchaseImage : props.image} className='thank-you-purchase-modal-image' />
            )}

            {props.buttonClickedName && (
              <img src={DicardOrder} className='thank-you-purchase-modal-image' />
            )}



            <h6 className='thank-you-purchase-modal-heading'>{t(props.mainHeading)}</h6>
            {props.cart ? (
              <>
                {props.screen === "my-cart" ?
                  <h6 className='thank-you-purchase-modal-paragraph'>{props.subHeading1}</h6> : ""}
                {(props.screen === "checkout") ?
                  props.error ? <h6 className='thank-you-purchase-modal-paragraph'><div>{t("CreateSaleOrderError.label")}</div><span className='modal-order-number-id'>{props.subHeading1}</span></h6>
                    : <h6 className='thank-you-purchase-modal-paragraph'>{t("ConfirmMsg1.label")}<span className='modal-order-number-id'>{props.subHeading1}</span></h6>
                  : null}
              </>

            ) :
              <>
                {(!props.buttonClickedName && !props.byOrderStatus && props.error === false) && (
                  <h6 className='thank-you-purchase-modal-paragraph'>{t("ConfirmMsg1.label")}<span className='modal-order-number-id'>{props.subHeading1}</span></h6>
                )}
                {(!props.buttonClickedName && !props.byOrderStatus && props.error) && (
                  <h6 className='thank-you-purchase-modal-paragraph'><div>{t("CreateSaleOrderError.label")}</div><span className='modal-order-number-id'>{props.subHeading1}</span></h6>
                )}
                {props.buttonClickedName && (
                  <h6 className='thank-you-purchase-modal-paragraph'>{props.subHeading1}</h6>
                )}
                {props.byOrderStatus && (
                  <h6 className='thank-you-purchase-modal-paragraph'>{props.subHeading1}</h6>
                )}
              </>
            }

            {!props.buttonClickedName && (
              <>
                {props.draft && <h6 className='thank-you-purchase-modal-inner-paragraph'>{t("Savedraftmsg1.label")}</h6>}
                {!props.cart || props.checkout && (
                  <>
                    <h6 className='thank-you-purchase-modal-inner-paragraph'>{props.subHeading2}</h6></>

                )}

                {props.subHeading2 && (
                  <>
                    <h6 className='thank-you-purchase-modal-inner-paragraph'>{props.subHeading2}</h6></>

                )}

                <button className='thank-you-purchase-modal-button' onClick={() => props.ClickAction()}><span data-testid="okay-button-heading">{props.buttonLabel}</span></button>
              </>
            )}
          </div>
        </div>
      </div> : null}
  </>
}

export default ThankYouForPurchaseModal