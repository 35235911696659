import { authHeader } from "../_helpers";
import { Axios } from "./axiosProvider";
import { REACT_APP_BASE_URL } from "../constant"
export const landingService = {
  searchWithoutLogin,
  searchWithLogin,
  getAllCatSub,
  getPromotionWithoutLogin,
  getPromotionWithLogin,
  getNewItemsDataWithOutLogin,
  getNewItemsDataWithLogin
};
const isNull = (value) => {
  return value == "" || value == undefined || value == null
}
function searchWithoutLogin(payload) {
  var data = {}
  var requestOptions = {}
  if (!isNull(payload.userId) && (!isNull(payload.customerCode)) && (!isNull(payload.token))) {

    data = { "searchItem": payload.searchItem, "storeCode": payload.storeCode, "userId": payload.userId, "customerCode": payload.customerCode }
    requestOptions = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${payload.token}`
      },
    };

  }
  else if (!isNull(payload.token)) {
    data = { "searchItem": payload.searchItem, "storeCode": payload.storeCode }
    requestOptions = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${payload.token}`
      },
    };
  }
  else {
    requestOptions = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8"
      },
    };
    data = { "searchItem": payload.searchItem }
  }


  return Axios.post(
    REACT_APP_BASE_URL + "/api/product/search?limit=10&skip=0",
    data,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}
function searchWithLogin(payload) {

}

function getPromotionWithoutLogin(payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    body: JSON.stringify(payload),
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/product/search",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getPromotionWithLogin(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(payload),
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/product/search",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

// new item getNewItemsDataWithOutLogin

function getNewItemsDataWithOutLogin(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    body: JSON.stringify(payload),
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/product/search",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getNewItemsDataWithLogin(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(payload),
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/product/search",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getAllCatSub() {
  const token = localStorage.getItem("authorization");
  let requestOptions;
  if (token) {
    requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    };

  } else {
    requestOptions = {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`
      },
    };
  }

  return Axios.get(
    REACT_APP_BASE_URL + "/api/productcategorys/getDetails",
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}