import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import AddToCartModalMobile from "../ModalScreens/AddToCartModal/addToCardMobileModal";
import { AddToCartModal } from "../ModalScreens/AddToCartModal/addToCardModal";
import { productActions } from "../_actions/product.actions";
import { REACT_APP_BASE_URL } from "../constant";
import addToWhishlist from "../images/AddToCartWhite.svg";
import promoBanner from "../images/PromoIconRed.svg";
import blankImage from "../images/empty-image.png";
import grayHeart from '../images/gray-heart.png';
import minusProdouctButton from "../images/minus-product-button.png";
import noresultsfoundserach from "../images/noresultsfoundserach.png";
import addProdouctButton from "../images/plus-product-button.png";
import sortByIcon from "../images/sort-by-icon.png";
import WishListRed from "../images/white-heart.svg";
import "./AllProductCards.css";

const subCategoryItems = [
  { name: 'pork', category: 'Pork.label' },
  { name: 'chicken', category: 'Chicken.label' },
  { name: 'duck', category: 'Duck.label' },
  { name: 'fish', category: 'Fish.label' },
  { name: 'egg', category: 'Egg.label' },
  { name: 'processed-meat', category: 'ProcessedMeat.label' },
  { name: 'ready-to-eat', category: 'ReadytoEat.label' },
  { name: 'ready-to-cook', category: 'ReadytoCook.label' },
  { name: 'others', category: 'Other.label' },
  { name: 'offspec', category: 'Offspec.label' }
]

const ProductCards = (props) => {
  const [openSortByDropDown, setOpenSortByDropDown] = useState(false); // storing open & close sortby dropdown
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [products, setProducts] = useState([]);
  const [productsPerPage] = useState(32);
  const [selectedValue, setSelectedValue] = useState("");
  const [wishlistStatus, setwishlistStatus] = useState(false);
  const [updateVal, setUpdateVal] = useState(false);
  const [addedToCardModal, setAddedToCardModal] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(0);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [carttoggleStatus, setCarttoggleStatus] = useState(true);
  const [updateEffect, setUpdatedEffect] = useState(false);
  // const [currentProducts, setCurrentProducts] = useState(1);
  const lancode = localStorage.getItem("lancode");
  // const productData = useSelector((state) => state.product);

  // const userData = JSON.parse(localStorage.getItem("user"));
  const userData = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
  const store = userData ? userData.user.stores[0].storeCode : "";
  const filteredCategory = subCategoryItems.filter(item => item.name === props.paramValue)

  const addWishListDetails = useSelector(
    (state) => state.product.addWishlistDetails
  );
  const cartAddedDetails = useSelector(
    (state) => state.product.cartAdded
  );
  const cartRemoveDetails = useSelector(
    (state) => state.product.cartRemove
  );

  React.useEffect(() => {
    if (addWishListDetails?.data)
      dispatch(productActions.getWishListInformation("payload", {}, ''));
    // let payload2 = {
    //   storeCode: storeCodeNew,
    //   articleCode: items.articaleCode
    // }
    // dispatch(productActions.getProductDetails("payload", payload2));
  }, [addWishListDetails])

  React.useEffect(() => {
    if (cartAddedDetails?.data)
      dispatch(productActions.getCartData("payload", {}))
  }, [cartAddedDetails])

  React.useEffect(() => {
    if (cartRemoveDetails?.data)
      dispatch(productActions.getCartData("payload", {}))
  }, [cartRemoveDetails])

  const setUpCartandSpecialPrices = (count, temp) => {
    let price;
    count = (count) ? parseInt(count) : 1;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    // if (isNull(temp.conditionAmount)) {
    if (Number(temp.quantity1Scale1) <= (count * packSize) && Number(temp.quantity1Scale1) != 0) {
      price = Number(temp.price1Scale1)
    }
    if (Number(temp.quantity2Scale2) <= (count * packSize) && Number(temp.quantity2Scale2) != 0) {
      price = Number(temp.price2Scale2)
    }
    if (Number(temp.quantity3Scale3) <= (count * packSize) && Number(temp.quantity3Scale3) != 0) {
      price = Number(temp.price3Scale3)
    }
    if (Number(temp.quantity4Scale4) <= (count * packSize) && Number(temp.quantity4Scale4) != 0) {
      price = Number(temp.price4Scale4)
    }
    if (Number(temp.quantity5Scale5) <= (count * packSize) && Number(temp.quantity5Scale5) != 0) {
      price = Number(temp.price5Scale5)
    }
    // if (count < Number(temp.quantity5Scale5)) {
    //     price = Number(temp.price5Scale5)
    // }
    return price
    // }

  }

  useEffect(() => {
    if (props.productData && props.productData?.result && props.productData?.result.length > 0) {
      const newData = props.productData.result.map(items => {
        items.productPrice = setUpCartandSpecialPrices(items.cartQuantity, items)

        if (isNull(items.conditionAmount)) {
          items.productPrice = setUpCartandSpecialPrices(items.cartQuantity, items)
        } else {
          items.productPrice = items.conditionAmount
        }
        return items
      })
      setProducts(newData);
    }
    else {
      setProducts([]);
    }

  }, [props.productData, props.productData.wishlistStatus, wishlistStatus, props.productData.cartQuantity, cartQuantity, updateEffect]);

  // useEffect(() => {
  //   setProducts(products);
  // }, [products]);

  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };

  // const totalPages = props && props.totalProduct ? Math.ceil(props.totalProduct / 32) : null;

  // const totalPages = props && props.totalProduct ? (props.totalProduct / 32).toFixed(0) : null;
  const totalPages = props && props.totalProduct ? Number((props.totalProduct / 32).toFixed(0)) : null;

  const getProduts = () => {
    const payloadForAllProducts = {
      storeCode: store,
    };

    const pagePayload = {
      limit: productsPerPage,
      currentPage: currentPage
    }

  };
  const setPage = (e, page) => {
    props.setPage(page)
  }

  const handleSelect = (eventKey) => {
    setSelectedValue(eventKey);
    props.onValueSelected(eventKey);
  };

  const routeToProductDetailsPage = (items) => {

    history.push("/productdetailscustomer/" + items.articaleCode, { items: items, storeCode: items.storeCode });                           // history.push("/productdetailscustomer")                  
  }


  const AddItemToCart = async (items, index) => {

    setAddedToCardModal(true)

    // 
    let temp = products;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].productPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      setProducts(temp);
      // setProducts(temp)
      setProductData(items)
    } else {
      temp[index].cartQuantity = count
      temp[index].productPrice = temp[index].conditionAmount
      setProductPrice(price)
      setProducts(temp)
    }
    // 
    // let message = "Item Added To Cart!";
    let payload = {
      "articaleCode": items.articaleCode,
      "storeCode": items.storeCode,
      "cartQuantity": temp[index].cartQuantity
    }
    dispatch(productActions.addProductToCart("payload", payload))
    // dispatch(productActions.getCartData("payload", payload))
    // setCarttoggleStatus(!carttoggleStatus)
    // props.handlecarttoggle(carttoggleStatus)
    // props.handleupdateQuantity(temp[index].cartQuantity)

    // setProductPrice(items.price1Scale1) 

  }

  const AddItemToWishList = async (status, items, index) => {
    let temp = products
    // if (!isNull(userData)) {
    let payload = {
      "articaleCode": items.articaleCode,
      "wishlistStatus": status,
      "storeCode": items.storeCode,
    }

    let updatePD = [];
    let x = temp[index].selectValues
    for await (const i of x) {
      if (i.articaleCode === items.articaleCode) {
        i.wishlistStatus = status
      }
      updatePD.push(i);
    }
    items.wishlistStatus = status;
    temp[index].selectValues = updatePD
    setProducts(temp);
    dispatch(productActions.addProductToWishList("payload", payload))
    // dispatch(productActions.getWishListInformation("payload", {}, `?limit=100000&skip=0`));
    // let temp = products;
    // temp.result[index].wishlistStatus = status
    // setProducts({ ...temp })
    let payload2 = {
      storeCode: items.articaleCode,
      articleCode: items.storeCode
    }
    dispatch(productActions.getProductDetails("payload", payload2));
    setwishlistStatus(!wishlistStatus)
    // handleIsLiked(wishlistStatus)
    // } else {
    //   if (location.pathname == "/login") {
    //     props.openSearchPopUpClosed()
    //   }
    //   history.push("/login")
    // }

  }

  // const AddItemToWishList = (status, items, index) => {

  //   let temp = products;    

  //   temp[index].wishlistStatus = status    

  //   let payload = {
  //     "articaleCode": items.articaleCode,
  //     "wishlistStatus": status,
  //     "storeCode": items.storeCode,
  //   }
  //   dispatch(productActions.addProductToWishList("payload", payload))

  //   dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
  //   let payload2 = {
  //     storeCode: items.storeCode,
  //     articleCode: items.articaleCode
  //   }
  //   dispatch(productActions.getProductDetails("payload", payload2));
  //   setwishlistStatus(!wishlistStatus)
  //   // props.handleIsLiked(wishlistStatus)
  // }

  const updateProductValue = (products) => {
    setUpdateVal(!updateVal)
    // props.updatedProductsValue(products)
  }

  // Handling added to cart modal close
  const addedToCartModalClose = () => {
    setAddedToCardModal(false);
    setCarttoggleStatus(carttoggleStatus)
  }


  const updateQuantity = async (e, type, itemDetails, index) => {
    e.preventDefault()
    // debugger
    let temp = products;

    let count = parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    count >= 1 && setCartQuantity(count)
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].productPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setProducts(temp);
      // 
      // setProducts(temp)
      setUpdatedEffect(!updateEffect)
    } else {
      temp[index].cartQuantity = count
      temp[index].productPrice = temp[index].conditionAmount
      setProductPrice(price)
      setProducts(temp)
      setUpdatedEffect(!updateEffect)
    }
    // if ((count == 0) && (type == "minus")) {
    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }

    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   // setUpdatedEffect(!updateEffect)
    //   // setCartQuantity(count)
    //   // props.handlecarttoggle(carttoggleStatus)
    //   // props.handleupdateQuantity(count)
    // } 
    // else {
    // if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    // dispatch(productActions.getCartData("payload", payload)); 
    // setUpdatedEffect(!updateEffect)       
    // props.handleupdateQuantity(count)
    // }
    // }

  }

  // const updateQuantity = (e, type, itemDetails) => {
  //   e.preventDefault()
  //   let count = itemDetails.cartQuantity;

  //   if ((count == 1) && (type == "minus")) { /////////////// remove from cart called

  //     setLoading(true)
  //     const payload = {
  //       cartId: itemDetails.cartId
  //     }
  //     dispatch(productActions.removeCartAction(payload))
  //     setCarttoggleStatus(!carttoggleStatus)
  //     props.handleupdateQuantity(count)
  //     props.handlecarttoggle(carttoggleStatus)

  //   } else {
  //     count = type == "add" ? count + 1 : count > 1 && count - 1
  //     if (count) {
  //       setLoading(true)
  //       let payload = {
  //         "articaleCode": itemDetails.articaleCode,
  //         "storeCode": itemDetails.storeCode,
  //         "cartQuantity": count
  //       }
  //       dispatch(productActions.addProductToCart("payload", payload))
  //       props.handleupdateQuantity(count)

  //     }
  //   }


  // }
  return (
    <>

      <div className="col-xll-8 col-xl-8 col-md-12 col-sm-12">
        {/* {props.paramValue === undefined ? (
          <h6 className="all-product-heading">{t("allProduct.label")}</h6>
        ) : (
          <h6 className="all-product-heading">{t(props.paramValue + ".label")}</h6>
        )} */}

        {filteredCategory && filteredCategory.map(item => (
          // <h6 className="all-product-text">{t(item.category)}</h6>
          <h6 className="all-product-heading common-display-none-mobile">{t(item.category)}</h6>
        ))}

        {/* {props.paramValue === undefined && lancode==='th' ? (
            <h6 className="all-product-heading">{t("allProduct.label")}</h6>
          ): (
            <h6 className="all-product-heading">{t(props.paramValue)}</h6>
        )} */}

        <div className="all-product-right-side-container">
          <div className="row">
            <div className="col-xll-6 col-xl-6 col-md-6 col-sm-6 common-display-none-mobile">
              {products && products.length > 0 && (
                <h6 className="product-count-text">
                  {lancode === 'en' && (
                    <>{props.totalProduct + " " + t("productRound.label")}</>
                  )}
                  {lancode === 'th' && (
                    <>{t("product.label") + " " + props.totalProduct + " " + t("productfound.label")}</>
                  )}

                </h6>
              )}
            </div>
            <div className="col-xll-6 col-xl-6 col-md-6 col-sm-6 sort-by-container">
              {openSortByDropDown ? (
                <Dropdown className="sort-by-dropdown" onSelect={handleSelect}>
                  <Dropdown.Menu show>
                    <Dropdown.Item eventKey="Clear">{t("clear.label")}</Dropdown.Item>
                    <Dropdown.Item eventKey="Price high to low">{t("priceHightoLow.label")}</Dropdown.Item>
                    <Dropdown.Item eventKey="Price low to high">
                      {t("priceLowtoHigh.label")}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="A to Z">{t("priceAtoZ.label")}</Dropdown.Item>
                    <Dropdown.Item eventKey="Z to A">{t("priceZtoA.label")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
              <span>
                <img
                  src={sortByIcon}
                  style={{ cursor: "pointer" }}
                  height={16}
                  width={16}
                  className="mb-3"
                  onClick={() => setOpenSortByDropDown(!openSortByDropDown)}
                />
                <span className="sort-by-text">{t("sortBy.label")} :</span>
                {isNull(selectedValue) || selectedValue === "Clear" ? <span></span> : <span className="sort-by-price-text">
                  {lancode === "en" && (
                    <>
                      {selectedValue === 'Price high to low' && t("priceHightoLow.label")}
                      {selectedValue === 'Price low to high' && t("priceLowtoHigh.label")}
                      {selectedValue === 'A to Z' && t("priceAtoZ.label")}
                      {selectedValue === 'Z to A' && t("priceZtoA.label")}
                    </>
                  )}
                  {lancode === "th" && (
                    <>
                      {selectedValue === 'Price high to low' && t("priceHightoLow.label")}
                      {selectedValue === 'Price low to high' && t("priceLowtoHigh.label")}
                      {selectedValue === 'A to Z' && t("priceAtoZ.label")}
                      {selectedValue === 'Z to A' && t("priceZtoA.label")}
                    </>
                  )}
                </span>}
                {/*  <span className="sort-by-price-text">{selectedValue || t("selectAnItem.label")}</span> */}
                {/* t(selectedValue+".label") */}
              </span>
            </div>
            {isNull(products) &&

              < div className='my-cart-is-empty-main-container'>
                <h1 className="no-found-result-text">{t("0productsfound.label")}</h1>
                <div className='my-cart-is-empty-inner-container'>
                  <img src={noresultsfoundserach} className='my-cart-is-empty-inner-container my-cart-is-empty-search-not-round' height={100} width={150} />
                  <h6 className='my-cart-is-empty-heading'>{t("NoSearchResultFound.label")}!</h6>
                  <div className='my-cart-is-empty-button'>
                    <Link to={"/allproducts"}> <span className='my-cart-is-empty-text'>{t("Continueshopping.label")}</span></Link>
                  </div>
                </div>
              </div>
            }
            {/* <div className="all-prouduct-found-card-container"> */}
            {products &&
              products.map((items, index) => (
                <div className="col-xll-4 col-xl-4 col-md-6 col-sm-6 col-6 mt-4 all-product-padding-card" key={index}>
                  <div className="all-prouduct-card-shadow">
                    <div className="all-product-card-inner">
                      <div className="product-card-image-badge">
                        {items && items.typeCode === "2" && (
                          <img alt="" src={promoBanner} style={{ width: 80 }} />
                        )}
                        {items && items.typeCode === "3" && (
                          <div className="promo-img-badge1">
                            <img alt="" src='../NewItemYellow.svg' />
                          </div>

                        )}
                      </div>
                      {/* <img src={promoImage} className="img-fluid" /> */}
                      <div className="head-text">
                        <img onClick={() => routeToProductDetailsPage(items)} src={
                          (items.picture && items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                            (REACT_APP_BASE_URL +
                              "/api/download?" +
                              items.picture.split(",")[0]
                              ? REACT_APP_BASE_URL +
                              "/api/download?" +
                              items.picture.split(",")[0]
                              : blankImage)
                            : blankImage} className="product-category-card-image" />
                        {userData && userData.user && userData.user.role && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') ?
                          <div className='text-on-image'>
                            {items.articaleCode}
                          </div> : null}
                      </div>
                      <div className="row">
                        <div className="col-xll-8 col-xl-8 col-md-8 col-sm-12 col-12 card-product-name-alignment">
                          {lancode === "en" && (
                            <span className="card-product-name" onClick={() => routeToProductDetailsPage(items)}>
                              {items.consumerNameEN &&
                                items.consumerNameEN.length > 24
                                ? items.consumerNameEN.substring(0, 24) +
                                "..."
                                : items.consumerNameEN}
                            </span>
                          )}

                          {lancode === "th" && (
                            <span className="card-product-name" onClick={() => routeToProductDetailsPage(items)}>
                              {items.consumerNameTH &&
                                items.consumerNameTH.length > 24
                                ? items.consumerNameTH.substring(0, 24) +
                                "..."
                                : items.consumerNameTH}
                            </span>
                          )}
                        </div>
                        <div className="col-xll-4 col-xl-4 col-md-4 col-4 p-0 m-0 common-display-none-mobile">
                          <h6 className="card-product-price">
                            {/* ฿ {items.price1Scale1} */}
                            {!isNull(userData) && <span variant='body1' className="price-field-heading">{"฿ " + (isNull(items.productPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.productPrice) + ".00"} </span>}
                          </h6>
                          {lancode === "en" && !isNull(userData) && userData.user.role !== "Saleshop" && (
                            <h6 className="card-product-quantity" data-toggle="tooltip"
                              data-placement="bottom"
                              title={items.packSizeEN}>
                              {items.packSizeEN.length > 8 ? items.packSizeEN.substring(0, 8) +
                                ".."
                                : items.packSizeEN}
                            </h6>
                          )}
                          {lancode === "th" && !isNull(userData) && userData.user.role !== "Saleshop" && (
                            <h6 className="card-product-quantity" data-toggle="tooltip"
                              data-placement="bottom"
                              title={items.packSizeTH}>
                              {items.packSizeTH.length > 8 ? items.packSizeTH.substring(0, 8) +
                                ".."
                                : items.packSizeTH}
                            </h6>
                          )}
                        </div>
                        {/* Mobile-out-of-stock-section start from here */}
                        {userData && userData.user && userData.user.role && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') ?
                          <div className={(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') ? "common-display-block-mobile" : null}>
                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (!isNull(items.stock)) && (
                                <>
                                  <div className="col-xll-12 col-xl-12 col-md-12 col-12 col-sm-12 pl-2 m-0">
                                    {lancode === "en" && (
                                      <h6 className="all-product-quantity-heading" data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={items.packSizeEN}>
                                        {items.packSizeEN.length > 8 ? items.packSizeEN.substring(0, 8) +
                                          ".."
                                          : items.packSizeEN}
                                      </h6>
                                    )}
                                    {lancode === "th" && (
                                      <h6 className="all-product-quantity-heading" data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={items.packSizeTH}>
                                        {items.packSizeTH.length > 8 ? items.packSizeTH.substring(0, 8) +
                                          ".."
                                          : items.packSizeTH}
                                      </h6>
                                    )}
                                  </div>
                                </>
                              )}

                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (isNull(items.stock)) && (
                                <>
                                  <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12">
                                    {lancode === "en" && (
                                      <h6 className="all-product-quantity-heading" key={items.id}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={items.packSizeEN}>
                                        {items.packSizeEN.length > 8 ? items.packSizeEN.substring(0, 8) +
                                          ".."
                                          : items.packSizeEN}
                                      </h6>
                                    )}
                                    {lancode === "th" && (
                                      <h6 className="all-product-quantity-heading" key={items.id}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={items.packSizeTH}>
                                        {items.packSizeTH.length > 8 ? items.packSizeTH.substring(0, 8) +
                                          ".."
                                          : items.packSizeTH}
                                      </h6>
                                    )}
                                  </div>
                                </>
                              )}
                          </div> : null}
                        <div className="col-sm-4 col-4 common-display-block-mobile pr-1">
                          <h6 className="card-product-price-mobile-heading ">
                            ฿ {items.price1Scale1}
                          </h6>
                        </div>
                        {lancode === "en" && !isNull(userData) && userData.user.role !== "Saleshop" && (
                          <div className="col-sm-8 col-8 common-display-block-mobile text-center mt-2">
                            <h6 className="card-product-quantity" data-toggle="tooltip"
                              data-placement="bottom"
                              title={items.packSizeEN}>
                              {items.packSizeEN.length > 8 ? items.packSizeEN.substring(0, 8) +
                                ".."
                                : items.packSizeEN}
                            </h6>
                          </div>
                        )}
                        {lancode === "th" && !isNull(userData) && userData.user.role !== "Saleshop" && (
                          <div className="col-sm-8 col-8 common-display-block-mobile text-center mt-2">
                            <h6 className="card-product-quantity" data-toggle="tooltip"
                              data-placement="bottom"
                              title={items.packSizeTH}>
                              {items.packSizeTH.length > 8 ? items.packSizeTH.substring(0, 8) +
                                ".."
                                : items.packSizeTH}
                            </h6>
                          </div>
                        )}
                        <div className="col-sm-8 col-8 common-display-block-mobile">
                          {userData && userData.user && userData.user.role && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') ?
                            <div className={(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') ? "out-of-stock-main-container" : null}>
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (!isNull(items.stock)) && (
                                  <>
                                    <div>
                                      <div className="out-of-stock-container">
                                        <h6 className="out-of-stock-heading">
                                          {items.stock} {t("inStock.label")}
                                        </h6>
                                      </div>
                                    </div>
                                  </>
                                )}

                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (isNull(items.stock)) && (
                                  <>
                                    <div>
                                      <div className="out-of-stock-containers">
                                        <h6 className="out-of-stock-headings">
                                          {t("OutofStock.label")}
                                        </h6>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div> : null}
                        </div>

                        {/* Mobile-out-of-stock end */}
                        {/* Out-of-stocks-section start from here */}
                        {userData && userData.user && userData.user.role && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') ?
                          <div className={(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') ? "out-of-stock-main-container common-display-none-mobile" : null}>
                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (!isNull(items.stock)) && (
                                <>
                                  <div className="col-xll-4 col-xl-4 col-md-4 col-4">
                                    {lancode === "en" && (
                                      <h6 className="all-product-quantity-heading" data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={items.packSizeEN}>
                                        {items.packSizeEN.length > 8 ? items.packSizeEN.substring(0, 8) +
                                          ".."
                                          : items.packSizeEN}
                                      </h6>
                                    )}
                                    {lancode === "th" && (
                                      <h6 className="all-product-quantity-heading" data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={items.packSizeTH}>
                                        {items.packSizeTH.length > 8 ? items.packSizeTH.substring(0, 8) +
                                          ".."
                                          : items.packSizeTH}
                                      </h6>
                                    )}


                                  </div>
                                  <div className="col-xll-8 col-xl-8 col-md-8 col-8">
                                    <div className="out-of-stock-container">
                                      <h6 className="out-of-stock-heading">
                                        {items.stock}  {t("inStock.label")}
                                      </h6>
                                    </div>
                                  </div>
                                </>
                              )}

                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (isNull(items.stock)) && (
                                <>
                                  <div className="col-xll-4 col-xl-4 col-md-4">
                                    {lancode === "en" && (
                                      <h6 className="all-product-quantity-heading" data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={items.packSizeEN}>
                                        {items.packSizeEN.length > 8 ? items.packSizeEN.substring(0, 8) +
                                          ".."
                                          : items.packSizeEN}
                                      </h6>
                                    )}
                                    {lancode === "th" && (
                                      <h6 className="all-product-quantity-heading" data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={items.packSizeTH}>
                                        {items.packSizeTH.length > 8 ? items.packSizeTH.substring(0, 8) +
                                          ".."
                                          : items.packSizeTH}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-xll-8 col-xl-8 col-md-8 col-8">
                                    <div className="out-of-stock-containers">
                                      <h6 className="out-of-stock-headings">
                                        {t("OutofStock.label")}
                                      </h6>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div> : null}
                        {/* Out-of-stocks-section end */}
                        <div className="col-xll-12 col-xl-12 col-md-12 col-12">
                          {!isNull(userData) && <div className="promo-desc">
                            {/* {items.selectValues.length ? */}
                            <select
                              onChange={(e) => {

                                // let temp = products.result[index].selectValues
                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = products
                                temp2[index].picture = selectedItem.picture
                                temp2[index].price1Scale1 = selectedItem.price1Scale1;
                                temp2[index].price2Scale2 = selectedItem.price2Scale2;
                                temp2[index].price3Scale3 = selectedItem.price3Scale3;
                                temp2[index].price4Scale4 = selectedItem.price4Scale4;
                                temp2[index].price5Scale5 = selectedItem.price5Scale5;
                                temp2[index].quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2[index].quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2[index].quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2[index].quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2[index].quantity5Scale5 = selectedItem.quantity5Scale5;
                                temp2[index].articaleCode = selectedItem.articaleCode
                                temp2[index].cartQuantity = selectedItem.cartQuantity
                                temp2[index].wishlistStatus = selectedItem.wishlistStatus
                                temp2[index].stock = selectedItem.stock
                                temp2[index].cartId = selectedItem.cartId
                                temp2[index].productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2[index].conditionAmount = selectedItem.conditionAmount
                                temp2[index].consumerNameEN = selectedItem.consumerNameEN
                                temp2[index].packSizeEN = selectedItem.packSizeEN
                                temp2[index].packSize = selectedItem.packSize
                                temp2[index].productTypeEN = selectedItem.productTypeEN
                                // temp2[index].aboutProductEN = selectedItem.abutProductEN
                                temp2[index].aboutProductEN = selectedItem.aboutProductEN
                                temp2[index].shelfLifeEN = selectedItem.shelfLifeEN
                                temp2[index].otherProductEN = selectedItem.otherProductEN
                                temp2[index].consumerNameTH = selectedItem.consumerNameTH
                                temp2[index].packSizeTH = selectedItem.packSizeTH
                                temp2[index].productTypeTH = selectedItem.productTypeTH
                                temp2[index].aboutProductTH = selectedItem.aboutProductTH
                                temp2[index].instructionTH = selectedItem.instructionTH
                                temp2[index].shelfLifeTH = selectedItem.shelfLifeTH
                                temp2[index].otherProductTH = selectedItem.otherProductTH

                                setProducts(temp2)



                                // 
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select)?'selected':'';
                                  // return(<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ?i.valueEN:i.valueTH}</option>);
                                  return (<option selected={i.articaleCode === items
                                    .articaleCode} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/* : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                            {/* {items.selectValues.length > 1 ?
                              <Autocomplete
                                disablePortal
                                id={Math.random().toString(36).substring(7)}
                                sx={{ width: 150, height: 50 }}
                                options={items.selectValues}
                                autoHighlight
                                // getOptionLabel={(option) => option?.valueEN}
                                value={lancode === "en" ? items?.selectValues?.filter((value) => {
                                  return value?.select
                                })[0]?.valueEN : items?.selectValues?.filter((value) => {
                                  return value?.select
                                })[0]?.valueTH}
                                className={"test"}
                                onChange={(e, selectedItem) => {                                  
                                  let temp = items.selectValues
                                  let temp2 = items
                                  temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                  temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                  temp2.picture = selectedItem.picture;
                                  temp2.articaleCode = selectedItem.articaleCode;
                                  temp2.price1Scale1 = selectedItem.price1Scale1;
                                  temp2.packSizeEN = selectedItem.packSizeEN;
                                  temp2.stock = selectedItem.stock;
                                  temp.map((val) => {
                                    return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                  })
                                                                    
                                  updateProductValue(true)

                                }}
                                renderOption={(props, option) => (
                                  <Box component="li"  {...props}>
                                    {option?.valueEN}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      // autoComplete: 'new-password',
                                    }}
                                  />
                                )}
                              />: lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}

                          {/* <select>
                                {items.selectValues &&
                                  items.selectValues.map((value) => {
                                    let selected = value.select ? "selected" : "";
                                    let result = [
                                      value.articaleCode,
                                      value.valueEN,
                                      value.valueTH,
                                      value.packSizeEN,
                                      value.productTypeEN,
                                      value.consumerNameEN,
                                    ];
                                    return (
                                      <option value={result} {...selected}>
                                        {lancode === "en"
                                          ? value.valueEN
                                          : value.valueTH}
                                      </option>
                                    );
                                  })}
                              </select> */}

                        </div>
                        <div className="row">
                          <div className="col-xll-4 col-xl-4 col-md-4 col-4 text-center">
                            {items && !items.wishlistStatus &&
                              <button className="card-add-to-card-whishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishList(true, items, index)}>
                                <img src={grayHeart} />
                                {/* <span className="hidden-xs">Add To Whishlist</span> */}
                              </button>
                            }
                            {items && items.wishlistStatus &&
                              <button className="card-add-to-card-whishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishList(false, items, index)}>
                                <img src={WishListRed} />
                                {/* <img src={WishListWhite} /> */}
                                {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                              </button>
                            }
                          </div>
                          {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                            <div className="col-sm-8 col-8 p-0 m-0">
                              <button className="added-to-card-button" onClick={() => AddItemToCart(items, index)}>
                                <img src={addToWhishlist} className="all-product-whishlist-card-image" />
                                {t("Addtocart.label")}
                              </button>
                            </div> : null}

                          {items.cartQuantity > 0 ?
                            <div className="col-xll-8 col-xl-8 col-md-8 col-8 p-0 m-0 all-product-add-to-cart-button">
                              <div className="add-minus-button">
                                <div className="row text-center">
                                  <div className="col-sm-4 col-4" >
                                    <img src={minusProdouctButton} onClick={(e) => { updateQuantity(e, "minus", items, index) }} className="all-products-add-minus-cursor" />
                                  </div>
                                  <div className="col-xll-4 col-xl-4 col-md-4 col-4">
                                    <h6 className="add-to-chart-count">{items.cartQuantity}</h6>
                                  </div>
                                  <div className="col-sm-4 col-4 p-0 m-0" >
                                    <img src={addProdouctButton} height={28} width={28} onClick={(e) => { updateQuantity(e, "add", items, index) }} className="all-products-add-minus-cursor" />
                                  </div>
                                </div>
                              </div>
                            </div> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {/* </div> */}
            {/* Add-to-cart-section start from here */}
            <div className="add-to-cart-modal-desktop-block">
              {addedToCardModal ? <AddToCartModal visible={addedToCardModal} product={productData} quantity={cartQuantity} priceofproductchosen={productPrice} close={addedToCartModalClose} /> : null}
            </div>
            <div className="common-display-block-mobile">
              {addedToCardModal ? <AddToCartModalMobile visible={addedToCardModal} product={productData} quantity={cartQuantity} priceofproductchosen={productPrice} close={addedToCartModalClose} /> : null}
            </div>
            {/* Add-to-cart-section end */}
            {products && products.length && (

              <div className="row pagination-main-container">
                <div className="col-xll-4 col-xl-4 col-md-4 col-4"></div>
                <div className="col-xll-8 col-xl-8 col-md-8 col-12">
                  <Stack spacing={2}>
                    {/* <Pagination count={10} shape="rounded" /> */}
                    <Pagination count={totalPages}
                      page={props.currentPage}
                      onChange={(event, page) => setPage(event, page)}
                      variant="outlined" shape="rounded" />
                  </Stack>
                </div>
              </div>
            )}
          </div>
        </div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored" />
      </div>
    </>
  );
};

export default ProductCards;
