import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { productActions } from "../../_actions";
import { REACT_APP_BASE_URL } from "../../constant";
import checkMarkGreen from '../../images/circle-check.png';
import crossIcon from '../../images/crossIcon.png';
import blankImage from "../../images/empty-image.png";
import promoBanner from "../../images/PromoIconRed.svg";
import './addToCardModal.css';
const AddToCartModal = (props) => {
    const [open, setOpen] = useState(props.visible); // show or hide added to cart modal
    const lancode = localStorage.getItem("lancode");
    const [cartCount, setCartCount] = useState(0);
    const [cartQuantityCount, setCartQuantityCount] = useState(0);
    const [messageforcartlimit, setMessageforcartlimit] = useState("");
    const cartData = useSelector((state) => state.product);
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = JSON.parse(sessionStorage.getItem("user"));
    const { t } = useTranslation();
    const isNull = (value) => {
        return value == "" || value == undefined || value == null;
    };

    // handling close added to cart modal
    const handleClose = () => {
        setOpen(false);
    }


    React.useEffect(() => {
        let quantitycount = 0;
        let payload = {};
        isNull(cartData.cartDetails) &&
            dispatch(productActions.getCartData("payload", payload));

        if (!isNull(cartData.cartDetails)) {
            if (cartData.cartDetails.data.length === 30) {
                setMessageforcartlimit("Cart limit Reached of 30 Items, Remaining , Not Added");
            }
            setCartCount(cartData.cartDetails.data.length);

            cartData.cartDetails.data.map(items => {
                quantitycount = quantitycount + items.cartQuantity;
            });

            setCartQuantityCount(quantitycount);
        }

    }, [props.product, cartData.cartDetails]);

    const redirecttocart = () => {
        if (!isNull(userData)) {
            history.push("/mycart");
        } else {
            localStorage.setItem('prevPath', '/mycart')
            history.push("/login");
        }
    };
    return <>
        <div className='added-to-my-card' style={{ zIndex: "1400" }}>
            <Dialog onClose={props.close} open={open} className='add-to-cart-modal-desktop-block' style={{ zIndex: 1400, position: "fixed", zIndex: 1300, inset: "none", left: "unset", top: 40, right: 120, height: "unset" }}>
                <DialogTitle>
                    <div className="container-fluid added-to-my-card-inner-container">
                        <div className='container'>
                            <div className="row align-items-center">
                                <div className='col-sm-1 col-1'>
                                    <img src={checkMarkGreen} className='add-to-cart-checkbox-right-image' />
                                </div>
                                <div className='col-sm-8 col-8'>
                                    <h6 className='add-to-card-heading'>{t("AddedtoCart.label")}</h6>
                                    <h6 style={{ color: "red" }}>{messageforcartlimit}</h6>
                                </div>
                                <div className='col-sm-3 col-3 added-to-card-cross-icon' onClick={() => props.close()}>
                                    <img src={crossIcon} className='add-to-cart-cross-image' />
                                </div>
                            </div>
                            <p className='gray-line-added-to-card'></p>
                            <div className='row added-to-card-image-container align-items-center'>
                                <div className='col-sm-3 col-3'>

                                    <div className='add-to-cart-image-border'>
                                        <div className="add-product-card-image-badge">
                                            {props.product && props.product.typeCode === "2" && (
                                                <img alt="" src={promoBanner} style={{ width: 30 }} />
                                            )}
                                            {props.product && props.product.typeCode === "3" && (
                                                <div className="promo-img-badge1">
                                                    <img alt="" src='../NewItemYellow.svg' style={{ width: 20 }} />
                                                </div>

                                            )}
                                            {/* <img alt="" src={promoBanner} style={{ width: 80 }} /> */}
                                        </div>
                                        <img src={
                                            (props.product.picture && props?.product?.picture.split(",")[0] && props?.product?.picture.split(",")[0] != undefined && props?.product?.picture.split(",")[0] != '') ?
                                                (REACT_APP_BASE_URL +
                                                    "/api/download?" +
                                                    props?.product?.picture.split(",")[0]
                                                    ? REACT_APP_BASE_URL +
                                                    "/api/download?" +
                                                    props?.product?.picture.split(",")[0]
                                                    : blankImage)
                                                : blankImage
                                        } height={72} width={72} className="add-to-cart-image-object" />
                                    </div>
                                </div>
                                <div className='col-sm-5 col-5 pl-0'>
                                    <div className='common-margin-added-to-card-model'>
                                        <h6 className='added-my-card-modal-product-name'>{props.product && lancode === "en" ? props.product.consumerNameEN : props.product.consumerNameTH}</h6>
                                    </div>
                                </div>
                                <div className='col-sm-4 col-4 added-to-modal-left-section'>
                                    <div className='common-margin-added-to-card-model'>
                                        <h6 className='common-margin-added-to-card-price-unit'>฿ {(props.product && (props.priceofproductchosen > 0)) ? props.priceofproductchosen : props.product.price1Scale1}.00 </h6>
                                        <h6 className='added-my-card-quantity'>x {props.quantity}</h6>
                                    </div>
                                </div>
                            </div>
                            {/*  <div className='added-to-card-modal-button'> */}
                            <div className='row'>
                                <div className='col-sm-12 col-12 add-to-cart-view-button-margin-bottom'>
                                    <button className='added-to-card-modal-button'
                                        onClick={() => redirecttocart()}
                                    >  {t("Viewcart.label")} ({cartQuantityCount > 0 ? <span>{cartQuantityCount}</span> : <span>{props.quantity}</span>})</button>
                                    {/* </div> */}
                                    <h6 className='added-to-card-continue-shopping' onClick={() => props.close()} >{t("Continueshopping.label")}</h6>
                                    {/* <div className='added-to-card-continue-shopping-line'></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogTitle>
            </Dialog>
        </div>
    </>
}
export { AddToCartModal as AddToCartModal };

