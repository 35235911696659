import React from 'react';
import cancelOrderError from '../../images/cancel-order-error.png';
import './CancelOrderModal.css';
import deleteWhite from '../../images/delete-white.png';
const CancelOrderModal = (props) => {
    const closePopUp=(e)=>{
        e.preventDefault()
        props.setOpenCancel(false)
    }
    return <>
    {open ? 
        <div className='cancel-order-modal-main-container'>
            <div className='cancel-order-modal-inner-container'>
                <div className='cancel-order-modal-custom-container'>
                    <div className='cancel-order-modal-common-container'>
                        <img src={cancelOrderError} className='cancel-order-modal-error-images' />
                        <h6 className='cancel-order-modal-main-heading'>{props.firstLabel}</h6>
                        <h6 className='cancel-order-modal-main-paragraph'>{props.secondLabel}</h6>
                        <div className='row'>
                            <div className='col-sm-6'>
                            <button className="cancel-order-modal-cancel-order-button"
                            onClick={closePopUp}
                            >
                            {props.firstBtnLabel}
                                </button>
                            </div>
                            <div className='col-sm-6'>
                            <button className="cancel-order-modal-keep-order-button"
                            onClick={e=>props.toCancelConfirm(props.row)}
                            >
                                  <img src={deleteWhite} className='cancel-order-modal-delete-image'/>
                                  {props.secondBtnLabel}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : null}
    </>
}
export default CancelOrderModal;