import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { userActions } from "../_actions";
import sendotpbutton from "../images/sendotpbutton.png";
import sendemaillinkbutton from "../images/sendemaillinkbutton.png";
import betagoapplicationname from "../images/betagroLogin.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
// import "./ForgotPassword.css";
import "../LoginFirstTime/loginFirstTime.css";
import Card from "@mui/joy/Card";
import { Footer } from "../Footer";
import { Header } from "../Header";
import Button from "react-bootstrap/Button";
import Loader from "../SharedComponent/Loader/Loader";
import { ValidateEmail } from "../_helpers/commanFunction";
import {nospaces} from "../_helpers/commanFunction"

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [phoneErr, setPhoneErr] = React.useState(false);
  const [emailErr, setEmailErr] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [loginType, setLogintype] = React.useState("phone");
  const userState = useSelector((state) => state.users);


  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  useEffect(() => {
    setLoading(false);
    if (
      submitted === true &&
      loginType === "phone" &&
      userState.forgotwithPhone !== null &&
      userState.forgotwithPhone.code === 200 &&
      userState.forgotwithPhone.data !== null
    ) {
      history.push("/forgototpverification?resetpassword=true", {
        otpDetail: userState.forgotwithPhone,
        phone: phone,
      });
    } else if (
      submitted === true &&
      loginType === "phone" &&
      userState.forgotwithPhone.code !== 200
    ) {
      setPhoneErr(true);
      setLoading(false);
    }
    if (
      submitted === true &&
      loginType === "email" &&
      userState.forgotwithEmail !== null &&
      userState.forgotwithEmail.code === 200 &&
      userState.forgotwithEmail.data !== null
    ) {
      history.push("/logincheckemailpassword?resetpassword=true", {
        otpDetail: userState.forgotwithEmail,
        email: email,
      });
    } else if (
      submitted === true &&
      loginType === "email" &&
      userState.forgotwithEmail.code !== 200
    ) {
      setEmailErr(true);
      setLoading(false);
    }
  }, [userState.forgotwithPhone, userState.forgotwithEmail]);

  useEffect(() => {
    if (loginType === "phone") {
      inputRef.current.addEventListener("focus", () => {
        inputRef.current.classList.add("custom-focus");
      });

      inputRef.current.addEventListener("blur", () => {
        inputRef.current.classList.remove("custom-focus");
      });
    }

    if (loginType === "email") {
      inputRef2.current.addEventListener("focus", () => {
        inputRef2.current.classList.add("custom-focus");
      });

      inputRef2.current.addEventListener("blur", () => {
        inputRef2.current.classList.remove("custom-focus");
      });
    }
  }, [isFocused]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (e) => {
    setPhoneErr(false);
    setEmailErr(false);
    if (e.target.type === "radio") {
      setLogintype(e.target.value);
    }
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "phone") {
      if (isNaN(e.target.value)) {
        return;
      }
      if(!nospaces(e.target.value)){
        return;
      }
      setPhone(e.target.value);
    }
  };

  const firstTimeLoginAction = (e) => {
    setPhoneErr(false);
    setEmailErr(false);
    e.preventDefault();
    if (loginType === "email") {
      if (!ValidateEmail(email)) {
        setEmailErr(true);
        return;
      }
      const payload = {
        type: "email",
        email: email,
      };
      setSubmitted(true);
      setLoading(true);
      localStorage.setItem("resetpassword", "true");
      dispatch(userActions.forgotPassword("email", payload));
    }

    if (loginType === "phone") {
      if (phone.length !== 10) {
        setPhoneErr(true);
        return;
      }
      const payload = {
        type: "phone",
        phone: phone,
      };
      setSubmitted(true);
      setLoading(true);
      dispatch(userActions.forgotPassword("phone", payload));
    }
  };
  return (
    <>
      <Helmet>
        <title>Betagro Forgot password</title>
      </Helmet>
      {loading && <Loader />}
      <div className="headerFixed">
      <Header />
      </div>
      <div className="logIn-firstTime-outer headerFixed-margin">
        <div className="logIn-firstTime logInFirstTimeError">
          <div className="loginheaderimage">
            <img src={betagoapplicationname} alt="Logo" data-testid="betagro-shop-logo"/>
          </div>
          <h2 data-testid="forgot-password-heading">{t("Forgotpassword?.lable")}</h2>
          <h6 data-testid="reset-password-option-heading">
            {t(
              "Enteryouregisteredphonenumberore-mailaddresstoresetyourpassword.label"
            )}
          </h6>
          <div className="logIn-firstTime-radioBtn login-radio-button-main-container">
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={loginType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="phone"
                control={<Radio color="success" />}
                label={t("phoneno.label")}
                labelPlacement="End"
                className="forgotPassword-label"
                data-testid="radio-for-phone"
              />
              <FormControlLabel
                value="email"
                control={<Radio color="success" />}
                label={t("email.label")}
                labelPlacement="End"
                className="forgotPassword-label"
                data-testid="radio-for-email"
              />
            </RadioGroup>
          </div>

          {loginType === "phone" && (
            <>
              <div className={"form-group"}>
              <p htmlFor="phone" className="input-label-st">
                  {t("phoneno.label")}
                </p>
                <input
                  ref={inputRef}
                  type="text"
                  className={"form-control" + (phoneErr ? " is-invalid" : "")}
                  placeholder="081-234-5678"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                  maxLength="10"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {phoneErr && (
                  <div className="invalid-feedback firstTimeLogin">
                    {t("dataNotFound.error")}
                  </div>
                )}
              </div>
              <button
                className="logIn-firstTimeBtn"
                onClick={firstTimeLoginAction}
                // disabled={!phone}
              >
                {t("sentOtp.label")}
              </button>
            </>
          )}

          {loginType === "email" && (
            <>
              <div className="form-group">
              <p htmlFor="password" className="input-label-st">
                  {t("emailAddress.label")}
                </p>
                <input
                  ref={inputRef2}
                  type="text"
                  className={"form-control" + (emailErr ? " is-invalid" : "")}
                  placeholder="example@gmail.com"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {emailErr && (
                  <div className="invalid-feedback firstTimeLogin">
                    {t("dataNotFound.error")}
                  </div>
                )}
              </div>
              <Link to="/logincheckemailfirst">
                <button
                  // disabled={!email}
                  className="logIn-firstTimeBtn"
                  onClick={firstTimeLoginAction}
                >
                  {t("sendLinkToEmail.label")}
                </button>
              </Link>{" "}
            </>
          )}

          <div className="returnLogin" data-testid="return-to-login-link">
            <Link to="/login">{t("returnTOlogin.label")}</Link>
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const Forgotpassword = withTranslation()(ForgotPassword);
export { Forgotpassword as ForgotPassword };
