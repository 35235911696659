import { REACT_APP_BASE_URL } from "../constant";
import { Axios } from "./axiosProvider";
export const productService = {
  getFilter,
  getProduct,
  getProductDetailsService,
  getCartCountService,
  getWishListCountService,
  addProductToCart,
  addProductToWishList,
  removeToCart,
  getSalesUserLisr,
  saveTaxInformationService,
  saveUserAddressFromCheckOutService,
  getWishListInformation,
  postuploadproducts,
};


function postuploadproducts(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`

    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/product/upload/products" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}
function getFilter(payload) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      // "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/product/filters",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getProduct(payload, pagePayload) {
  const { currentPage } = pagePayload;
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/product/search?limit=32&skip=" + currentPage,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}
function getProductDetailsService(payload) {
  const token = localStorage.getItem("authorization");
  var requestOptions = {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`
    },
  };
  let data = {
    storeCode: payload.storeCode
  }
  return Axios.post(
    REACT_APP_BASE_URL + `/api/product/${payload.articleCode}`,
    data,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}
function getCartCountService(payload) {
  const token = localStorage.getItem("authorization");
  var url = ''
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  if (payload?.userId) {
    url = REACT_APP_BASE_URL + "/api/cart/getUserCart?userId=" + payload.userId
  } else {
    url = REACT_APP_BASE_URL + "/api/cart/getUserCart"
  }
  return Axios.get(
    url,
    requestOptions
  )
    // Axios.g
    .then((response) => response)
    .catch((error) => error);
}




function getWishListInformation(payload, queryString) {
  //debugger;
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
      // "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
    },
  };

  return Axios.get(
    REACT_APP_BASE_URL + "/api/wishlist/getUserWishlist" + queryString,
    requestOptions
  )
    // Axios.g
    .then((response) => response)
    .catch((error) => error);

}

function getWishListCountService(payload, queryString) {
  //debugger;
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
      // "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
    },
  };

  return Axios.get(
    REACT_APP_BASE_URL + "/api/wishlist/getUserWishlist" + queryString,
    requestOptions
  )
    // Axios.g
    .then((response) => response)
    .catch((error) => error);
}

function addProductToCart(payload, pagePayload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(payload),
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/cart/create",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function removeToCart(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(payload),
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/cart/remove",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function addProductToWishList(payload, pagePayload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(payload),
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/wishlist/create",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getSalesUserLisr(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
      // "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
    },
  };

  return Axios.get(
    REACT_APP_BASE_URL + "/api/users/customersList",
    requestOptions
  )
    // Axios.g
    .then((response) => response)
    .catch((error) => error);
}

function saveTaxInformationService(payload) {
  const token = localStorage.getItem("authorization");
  var requestOptions = {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`
    },
  };

  return Axios.post(
    REACT_APP_BASE_URL + `/api/users/taxinvoice`,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function saveUserAddressFromCheckOutService(payload) {
  const token = localStorage.getItem("authorization");
  var requestOptions = {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`
    },
  };

  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/address",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


