import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { orderActions } from "../_actions";
import { Footer } from '../Footer';
import { Header } from '../Header';
import MyCartBackButton from '../images/my-cart-back-button.png';
import Loading from "../SharedComponent/Loader/Loader";
import ActiveTab from './MenuTabs/ActiveTab/ActiveTab';
import HistoryTab from './MenuTabs/HistoryTab/HistoryTab';
import ToReviewTab from './MenuTabs/ToReviewTab/ToReviewTab';
import MyAccountHamburgerMenu from './MyAccountHamburgerMenu/MyAccountHamburgerMenu';
import './MyOrder.css';


function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const MyOrder = () => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };


  window.addEventListener('scroll', toggleVisible);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  var userData = JSON.parse(localStorage.getItem('user'))
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const store = userData ? userData.user.stores[0].storeCode : "";
  const activelistorderList = useSelector((state) => state.order.activelistorderList);
  const historylistorderList = useSelector((state) => state.order.historylistorderList);
  const torevieworderList = useSelector((state) => state.order.torevieworderList);
  const [tableDataactivelist, setTableDataactivelist] = React.useState([]);
  const [tableDatahistorylist, setTableDatahistorylist] = React.useState([]);
  const [tableDatatoreview, setTableDatatoreview] = React.useState([]);
  const [totalCountactivelist, setTotalCountactivelist] = React.useState(0);
  const [totalCounthistorylist, setTotalCounthistorylist] = React.useState(0);
  const [totalCounttoreview, setTotalCounttoreview] = React.useState(0);
  const location = useLocation()
  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      localStorage.setItem('prevPath', location.pathname)
      history.push("/login");
    }
    let payload_activelist = {
      "storeCode": store,
      "flags": {
        "product": true
      }

    };
    let queryString = `limit=${parseInt(10)}&skip=${0}`;
    dispatch(orderActions.activeList("", payload_activelist, queryString));
    dispatch(orderActions.historyList("", payload_activelist, queryString));
    dispatch(orderActions.toReview("", payload_activelist, queryString));
    // dispatch(orderActions.userReorder("", payload, queryString));
    // dispatch(orderActions.updateCancellationDetails("", payload, queryString));
    // dispatch(orderActions.getbyorderID("", payload, queryString));

  }, []);

  React.useEffect(() => {
    if (activelistorderList?.data) {
      // if (activelistorderList.data && activelistorderList?.data.result.length > 0) {
      //     setTableDataactivelist(activelistorderList?.data.result);
      // }
      setLoading(false);
      setTotalCountactivelist(activelistorderList?.data.totalCount);
    } else {
      setTableDataactivelist([]);
      setLoading(false);
      setTotalCountactivelist(0);
    }
    if (historylistorderList?.data) {
      // if (historylistorderList.data && historylistorderList?.data.result.length > 0) {
      //     setTableDatahistorylist(historylistorderList?.data.result);
      // }
      setLoading(false);
      setTotalCounthistorylist(historylistorderList?.data.totalCount);
    } else {
      setTableDatahistorylist([]);
      setLoading(false);
      setTotalCounthistorylist(0);
    }
    if (torevieworderList?.data) {
      // if (torevieworderList.data && torevieworderList?.data.result.length > 0) {
      //     setTableDatatoreview(torevieworderList?.data.result);
      // }
      setLoading(false);
      setTotalCounttoreview(torevieworderList?.data.totalCount);
    } else {
      setTableDatatoreview([]);
      setLoading(false);
      setTotalCounttoreview(0);
    }
  }, [activelistorderList, historylistorderList, torevieworderList]);

  const [activeMenu, setActiveMenu] = useState('my-orders');
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  function handleGoBack() {
    history.goBack();
  }

  return <>
    <Helmet>
      <title>Betagro My Order</title>
    </Helmet>
    {loading && (<Loading />)}
    <div className='common-header-fixed'>
      < Header />
    </div>
    <div className='container-fluid my-order-main-container' data-testid="my-order-loading">
      <div className='row common-header-margin-fixed'>
        <div className='col-xll-3 col-xl-3 col-md-3'>
          <MyAccountHamburgerMenu activeMenu={activeMenu} activeList={activelistorderList?.data} />
        </div>
        <div className='col-xll-9 col-xl-9 col-md-9 my-order-main-right-container overflow-hidden'>
          {/* Mobile-back-button section start from here*/}
          <div className='row my-order-back-button-main-container my-order-display-block-mobile'>
            <div className='col-sm-12 col-12 d-flex flex-row my-order-back-button-inner-container'>
              <img src={MyCartBackButton} className='my-order-back-button-image' onClick={() => handleGoBack()} />
              <h6 className='my-order-back-button-heading' onClick={() => handleGoBack()}>{t("Back.label")}</h6>
            </div>
          </div>
          {/* Mobile-back-button section end*/}
          <div className='my-order-right-main-container'>
            <div className='my-order-right-inner-container'>
              <h6 className='my-orders-right-main-heading'>{t("MyOrders.label")}</h6>
            </div>
          </div>

          {/* tabs-menu-section  start from here */}
          <div className='col-xl-12'>
            <div className='my-order-tab-menu' data-testid="my-order-menu-tabs">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={<span>{t("Active.label")}
                      {totalCountactivelist > 0 ? (
                        <span className='tabs-active-count'> {totalCountactivelist}</span>
                      ) : null}
                    </span>} {...a11yProps(0)} />
                    {userData && userData.user && userData?.user?.role !== "Saleshop" && userData?.user?.role !== "Admin" && (
                      <Tab label={<span>{t("ToReview.label")}
                        {totalCounttoreview > 0 ? (<span className='tabs-active-count'>{totalCounttoreview}</span>) : null}
                      </span>} {...a11yProps(1)} />
                    )}
                    <Tab label={<span>{t("History.label")}
                      {/* { totalCounthistorylist > 0 ? (<span className='tabs-active-count'> {totalCounthistorylist}</span>): null} */}
                    </span>} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <ActiveTab />
                </TabPanel>
                {userData && userData.user && userData?.user?.role !== "Saleshop" && userData?.user?.role !== "Admin" && (
                  <TabPanel value={value} index={1} data-testid="to-review-tab-panel">
                    <ToReviewTab />
                  </TabPanel>
                )}
                {userData && userData.user && (userData?.user?.role === "Saleshop" || userData?.user?.role === 'Admin') ? (
                  <TabPanel value={value} index={1} data-testid="history-tab-panel">
                    <HistoryTab />
                  </TabPanel>
                ) : (
                  <TabPanel value={value} index={2}>
                    <HistoryTab />
                  </TabPanel>
                )}
              </Box>
            </div>
          </div>
          {/* tabs-menu-section  end */}
        </div>
      </div>
    </div>
    <div className='common-display-none-mobile'>
      <Footer />
    </div>
  </>
}
export { MyOrder as MyOrder };

