import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import AddToCartModalMobile from "../ModalScreens/AddToCartModal/addToCardMobileModal";
import { AddToCartModal } from "../ModalScreens/AddToCartModal/addToCardModal";
import { landingPageActions } from "../_actions/landing.actions";
import { productActions } from "../_actions/product.actions";
import { REACT_APP_BASE_URL } from "../constant";
import blankImage from "../images/empty-image.png";
import minusProdouctButton from "../images/minus-product-button.png";
import addProdouctButton from "../images/plus-product-button.png";
import ActiveWishlist from "../images/white-heart.svg";
import WishListGrey from "./SVG/WishListGrey.svg";
import "./style.css";


const userInformation = JSON.parse(localStorage.getItem("user"));
const PromotionCard = () => {
  const [promoData, setPromoData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authUsers, setAuthUsers] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = React.useState(userInformation);
  const history = useHistory();
  const location = useLocation();
  const [updateVal, setUpdateVal] = useState(false);
  // const searchState = useSelector((state) => state.landing);
  const promotionData = useSelector((state) => state.landing.promotionData);
  const [addedToCardModal, setAddedToCardModal] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(0);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [iscartactive, setIscartactive] = useState(false);
  const [cartupdateQuantity, setCartupdateQuantity] = useState(1);
  const [wishlistStatus, setwishlistStatus] = useState(false);
  const [carttoggleStatus, setCarttoggleStatus] = useState(true);
  const userState = useSelector((state) => state.users);
  const addWishListDetails = useSelector(
    (state) => state.product.addWishlistDetails
  );
  const cartAddedDetails = useSelector(
    (state) => state.product.cartAdded
  );
  const cartRemoveDetails = useSelector(
    (state) => state.product.cartRemove
  );

  const [isLiked, setIsLiked] = useState(false);
  const [updateValue, setUpdateValue] = useState(false);

  const authUser = sessionStorage.getItem("authorization");

  const userData = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : undefined;

  const lancode = localStorage.getItem("lancode");

  const setUpCartandSpecialPrices = (count, temp) => {
    let price;
    count = (count) ? parseInt(count) : 1;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    // if (isNull(temp.conditionAmount)) {
    if ((Number(temp.quantity1Scale1) <= (count * packSize) || Number(temp.quantity1Scale1) > (count * packSize)) && Number(temp.quantity1Scale1) != 0) {
      price = Number(temp.price1Scale1)
    }
    if (Number(temp.quantity2Scale2) <= (count * packSize) && Number(temp.quantity2Scale2) != 0) {
      price = Number(temp.price2Scale2)
    }
    if (Number(temp.quantity3Scale3) <= (count * packSize) && Number(temp.quantity3Scale3) != 0) {
      price = Number(temp.price3Scale3)
    }
    if (Number(temp.quantity4Scale4) <= (count * packSize) && Number(temp.quantity4Scale4) != 0) {
      price = Number(temp.price4Scale4)
    }
    if (Number(temp.quantity5Scale5) <= (count * packSize) && Number(temp.quantity5Scale5) != 0) {
      price = Number(temp.price5Scale5)
    }
    // if (count < Number(temp.quantity5Scale5)) {
    //   price = Number(temp.price5Scale5)
    // }
    return price
    // }

  }

  useEffect(() => {
    getPromotionCard();
  }, []);

  // useEffect(() => {
  //   getPromotionCard();
  // }, [authUser]);

  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };

  useEffect(() => {
    // setAuthUsers(authUser);
    if (promotionData && promotionData.data && promotionData.data.result.length > 0) {
      const newData = promotionData.data.result.map(items => {
        items.currentUnitPrice = setUpCartandSpecialPrices(items.cartQuantity, items)

        if (isNull(items.conditionAmount)) {
          items.currentUnitPrice = setUpCartandSpecialPrices(items.cartQuantity, items)
        } else {
          items.currentUnitPrice = items.conditionAmount
        }
        return items
      });
      setPromoData(newData);
    }
    else {
      setPromoData(null);
    }
    /*  ? setPromoData(promotionData.promotionData.data)
     : setPromoData(null); */

    /// giving error , so commented  promotionData.promotionData.wishlistStatus 
  }, [promotionData]);



  const getPromotionCard = () => {
    // const authUser = localStorage.getItem("authorization");
    const payloadWithOutLogin = {
      typeCode: "2",
    };
    const payloadWithLogin = {
      typeCode: "2",
      storeCode: userData ? userData.user.stores[0].storeCode : "",
    };

    if (!authUser) {
      dispatch(
        landingPageActions.getPromotionWithoutLogin("promotion", payloadWithOutLogin)
      );
    } else {
      dispatch(
        landingPageActions.getPromotionWithLogin("promotion", payloadWithLogin)
      );
    }
  };

  const routeToProductDetailsPage = (items) => {
    if (!isNull(userData)) {
      history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice4550 = async (items, index) => {
    index = index + 45;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice4045 = async (items, index) => {
    index = index + 40;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice3540 = async (items, index) => {
    index = index + 35;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice3035 = async (items, index) => {
    index = index + 30;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice2530 = async (items, index) => {
    index = index + 25;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice2025 = async (items, index) => {
    index = index + 20;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToCartslice1520 = async (items, index) => {
    index = index + 15;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }


  const AddItemToCartslice1015 = async (items, index) => {
    index = index + 10;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice510 = async (items, index) => {
    index = index + 5;
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
      // setPromoData(temp)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      setCarttoggleStatus(!carttoggleStatus)
      handleupdateQuantity(count)
      handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToCartslice05 = async (items, index) => {
    // debugger
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setProductData(items)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      // setCarttoggleStatus(!carttoggleStatus)
      // handleupdateQuantity(count)
      // handlecarttoggle(carttoggleStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  React.useEffect(() => {
    if (addWishListDetails?.data)
      dispatch(productActions.getWishListInformation("payload", {}, ''));
    // let payload2 = {
    //   storeCode: storeCodeNew,
    //   articleCode: items.articaleCode
    // }
    // dispatch(productActions.getProductDetails("payload", payload2));
  }, [addWishListDetails])

  React.useEffect(() => {
    if (cartAddedDetails?.data)
      dispatch(productActions.getCartData("payload", {}))
  }, [cartAddedDetails])

  React.useEffect(() => {
    if (cartRemoveDetails?.data)
      dispatch(productActions.getCartData("payload", {}))
  }, [cartRemoveDetails])

  const AddItemToWishListslice4550 = async (status, items, index) => {
    index = index + 45
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);
      dispatch(productActions.addProductToWishList("payload", payload))

      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToWishListslice4045 = async (status, items, index) => {
    index = index + 40
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);
      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToWishListslice3540 = async (status, items, index) => {
    index = index + 35
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToWishListslice3035 = async (status, items, index) => {
    index = index + 30
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToWishListslice2530 = async (status, items, index) => {
    index = index + 25
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToWishListslice2025 = async (status, items, index) => {
    index = index + 20
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToWishListslice1520 = async (status, items, index) => {
    index = index + 15
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToWishListslice1015 = async (status, items, index) => {
    index = index + 10
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToWishListslice510 = async (status, items, index) => {
    index = index + 5
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToWishListslice05 = async (status, items, index) => {
    let temp = promoData;
    //////// changes to be updated by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)

    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const updateProductValue = (promoData) => {
    setUpdateVal(!updateVal)
    // props.updatedProductsValue(products)
  }

  // Handling added to cart modal close
  const addedToCartModalClose = () => {
    setAddedToCardModal(false);
    setCarttoggleStatus(carttoggleStatus)
  }

  const handleIsLiked = (value) => {
    setIsLiked(value)
  }

  const handlecarttoggle = (value) => {
    setIscartactive(value)
  }

  const handleupdateQuantity = (value) => {
    setCartupdateQuantity(value)
  }

  const updateQuantityslice4550 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 45;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      // setProducts(temp);
      // 
      // setPromoData(temp)
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } 
    // else {
    // if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    // }
    // }

  }

  const updateQuantityslice4045 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 40;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } 
    // else {
    // if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    // dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    // }
    // }

  }
  const updateQuantityslice3540 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 35;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } 
    // else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    //   }
    // }

  }
  const updateQuantityslice3035 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 30;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } else {
    // if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    //   }
    // }

  }
  const updateQuantityslice2530 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 25;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    //   }
    // }

  }

  const updateQuantityslice2025 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 20;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    //   }
    // }

  }

  const updateQuantityslice1520 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 15;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    //   }
    // }

  }
  const updateQuantityslice1015 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 10;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    //   }
    // }

  }

  const updateQuantityslice510 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    index = index + 5;
    let temp = promoData;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      // temp[index].cartQuantity = count
      // temp[index].currentUnitPrice = price
      // setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setPromoData(temp)

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   setCarttoggleStatus(!carttoggleStatus)
    //   handleupdateQuantity(count)
    //   handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    dispatch(productActions.getCartData("payload", payload));
    handleupdateQuantity(count);

    //   }
    // }

  }
  const updateQuantityslice05 = async (e, type, itemDetails, index) => {
    e.preventDefault()
    let temp = promoData;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      // setProducts(temp);
      // 
      setPromoData(temp)
      setUpdateValue(!updateValue);

    }
    // if ((count == 0) && (type == "minus")) {

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   // dispatch(productActions.getCartData("payload", payload));
    //   // setCarttoggleStatus(!carttoggleStatus)
    //   // handleupdateQuantity(count)
    //   // handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    // dispatch(productActions.getCartData("payload", payload));
    // handleupdateQuantity(count);

    //   }
    // }

  }

  return (
    <>
      <div className="container-fluid common-margin">
        <div className="promotion-main-container">
          <div className="promoparent">
            <div className="promohead">
              <div className="promohead-lt">
                <img src="../promo.svg" alt="" />
                <h4> {t("Promotion.label")}</h4>
              </div>
              <div className="promohead-rt">
                <Link to="/promoproducts">{t("Seeallpromotions.label")}</Link>
                <img
                  src="../greenarrowright.svg"
                  alt=""
                  className="arror-image-style"
                />
              </div>
            </div>
            <Carousel className="promotion-card" interval={null} slide={false} controls={false} pause={false}>
              <Carousel.Item>
                <div className="promotions">
                  {promoData &&
                    promoData.length > 0 &&
                    promoData.slice(0, 5).map((items, index) => (
                      <div className="promo" key={index}>
                        <div className="promo-img">
                          <div className="promo-img-badge">
                            <img
                              alt=""
                              src="../PromoIconRed.svg"

                            />
                          </div>
                          <img onClick={() => routeToProductDetailsPage(items)}
                            src={
                              (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                (REACT_APP_BASE_URL +
                                  "/api/download?" +
                                  items.picture.split(",")[0]
                                  ? REACT_APP_BASE_URL +
                                  "/api/download?" +
                                  items.picture.split(",")[0]
                                  : blankImage)
                                : blankImage
                            }
                          />
                        </div>
                        <div className="promo-price">
                          <div className="promo-price-lt">
                            {lancode === "en" && (
                              <span
                                key={items.id}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={items.consumerNameEN}
                                onClick={() => routeToProductDetailsPage(items)}
                              >
                                {items.consumerNameEN &&
                                  items.consumerNameEN.length > 31
                                  ? items.consumerNameEN.substring(0, 31) +
                                  "..."
                                  : items.consumerNameEN}
                              </span>
                            )}

                            {lancode === "th" && (
                              <span
                                key={items.id}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={items.consumerNameTH}
                                onClick={() => routeToProductDetailsPage(items)}
                              >
                                {items.consumerNameTH &&
                                  items.consumerNameTH.length > 31
                                  ? items.consumerNameTH.substring(0, 31) +
                                  "..."
                                  : items.consumerNameTH}
                              </span>
                            )}
                          </div>
                          <div className="promo-price-rt">
                            {authUser && (
                              <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                            )}
                            {lancode === "en" && (
                              <h5 key={items.id}>{items.packSizeEN}</h5>
                            )}
                            {lancode === "th" && (
                              <h5 key={items.id}>{items.packSizeTH}</h5>
                            )}
                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                              (!isNull(items.stock) ? (
                                <span className="stock-field-in">
                                  {" "}
                                  {items.stock} in Stock{" "}
                                </span>
                              ) : (
                                <></>
                              ))}
                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                              (isNull(items.stock) ? (
                                <span className="stock-field-out hidden-sm">
                                  {" "}
                                  {t("OutofStock.label")}
                                </span>
                              ) : (
                                <></>
                              ))}
                          </div>
                        </div>

                        {!isNull(userData) && <div className="promo-desc">
                          {/*  {items.selectValues && items.selectValues.length > 1 ? */}
                          <select
                            defaultValue={null}
                            onChange={(e) => {
                              let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                              let temp2 = items
                              temp2.picture = selectedItem.picture
                              temp2.price1Scale1 = selectedItem.price1Scale1
                              //                             
                              temp2.price2Scale2 = selectedItem.price2Scale2;
                              temp2.price3Scale3 = selectedItem.price3Scale3;
                              temp2.price4Scale4 = selectedItem.price4Scale4;
                              temp2.price5Scale5 = selectedItem.price5Scale5;
                              temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                              temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                              temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                              temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                              temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                              // 
                              temp2.articaleCode = selectedItem.articaleCode
                              temp2.cartQuantity = selectedItem.cartQuantity
                              temp2.wishlistStatus = selectedItem.wishlistStatus
                              temp2.stock = selectedItem.stock
                              temp2.cartId = selectedItem.cartId
                              // 
                              temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                              temp2.packSize = selectedItem.packSize
                              temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                              //
                              temp2.conditionAmount = selectedItem.conditionAmount
                              temp2.consumerNameEN = selectedItem.consumerNameEN
                              temp2.packSizeEN = selectedItem.packSizeEN
                              temp2.productTypeEN = selectedItem.productTypeEN
                              // temp2.aboutProductEN = selectedItem.abutProductEN
                              temp2.aboutProductEN = selectedItem.aboutProductEN
                              temp2.shelfLifeEN = selectedItem.shelfLifeEN
                              temp2.otherProductEN = selectedItem.otherProductEN
                              temp2.consumerNameTH = selectedItem.consumerNameTH
                              temp2.packSizeTH = selectedItem.packSizeTH
                              temp2.productTypeTH = selectedItem.productTypeTH
                              temp2.aboutProductTH = selectedItem.aboutProductTH
                              temp2.instructionTH = selectedItem.instructionTH
                              temp2.shelfLifeTH = selectedItem.shelfLifeTH
                              temp2.otherProductTH = selectedItem.otherProductTH
                              // let temp = items.selectValues
                              // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                              // let temp2 = items
                              // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                              // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                              // temp2.picture = selectedItem.picture;
                              // temp2.articaleCode = selectedItem.articaleCode;
                              // temp2.price1Scale1 = selectedItem.price1Scale1;
                              // temp2.packSizeEN = selectedItem.packSizeEN;
                              // temp2.stock = selectedItem.stock;
                              // temp.map((val) => {
                              //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                              // })

                              updateProductValue(true)
                            }}
                          >
                            {
                              items.selectValues.map((i, ind) => {
                                // let selected = (i.select) ? 'selected' : '';
                                // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                              })
                            }
                          </select>
                          {/*   : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                        </div>}

                        {/* <div className="promo-desc">
                          <select
                            onChange={(e) => {
                              alert("WIP");
                            }}
                          >
                            {items.selectValues &&
                              items.selectValues.map((value) => {
                                let selected = value.select ? "selected" : "";
                                let result = [
                                  value.articaleCode,
                                  value.valueEN,
                                  value.valueTH,
                                  value.packSizeEN,
                                  value.productTypeEN,
                                  value.consumerNameEN,
                                ];
                                return (
                                  <option value={result} {...selected}>
                                    {lancode === "en"
                                      ? value.valueEN
                                      : value.valueTH}
                                  </option>
                                );
                              })}
                          </select>
                        </div> */}

                        <div className="promo-btn">
                          <div className="promo-btn-lt">
                            {items && !items.wishlistStatus &&
                              <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice05(true, items, index)}>
                                <img src={WishListGrey} />
                                {/* <span className="hidden-xs">Add To Whishlist</span> */}
                              </button>
                            }
                            {items && items.wishlistStatus &&
                              <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice05(false, items, index)}>
                                <img src={ActiveWishlist} />
                              </button>
                            }

                          </div>
                          {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                            <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                              <div className="add-to-cart-container">
                                <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice05(items, index)}>
                                  <img alt="" src="../AddToCartWhite.svg" />
                                  {t("Addtocart.label")}
                                </button>
                              </div>
                            </div> : null}
                          {items.cartQuantity > 0 ?
                            <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                              <div className="promo-btn-addtocart-add-minus">
                                <div className="row text-center">
                                  <div className="col-sm-5 col-5 justify-content-start">
                                    <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice05(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                  </div>
                                  <div className="col-sm-2 col-2 p-0 m-0 justify-content-center">
                                    <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                  </div>
                                  <div className="col-sm-5 col-5 justify-content-end">
                                    <img src={addProdouctButton} onClick={(e) => { updateQuantityslice05(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                  </div>
                                </div>
                              </div>
                            </div> : null}
                        </div>
                      </div>
                    ))}
                </div>
                {/* start second row of first item */}

                <div className="promotions">
                  {promoData &&
                    promoData.length > 0 &&
                    promoData.slice(5, 10).map((items, index) => (
                      <div className="promo" key={index}>
                        <div className="promo-img" title=" Click to Product Details" onClick={() => routeToProductDetailsPage(items)}>
                          <div className="promo-img-badge">
                            <img
                              alt=""
                              src="../PromoIconRed.svg"

                            />
                          </div>
                          {/* <img src="../rectangle-2067@2x.png"/> */}
                          <img onClick={() => routeToProductDetailsPage(items)}
                            src={
                              (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                (REACT_APP_BASE_URL +
                                  "/api/download?" +
                                  items.picture.split(",")[0]
                                  ? REACT_APP_BASE_URL +
                                  "/api/download?" +
                                  items.picture.split(",")[0]
                                  : blankImage)
                                : blankImage
                            }
                          />
                        </div>
                        <div className="promo-price">
                          <div className="promo-price-lt">
                            {lancode === "en" && (
                              <span
                                key={items.id}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={items.consumerNameEN}
                                onClick={() => routeToProductDetailsPage(items)}
                              >
                                {items.consumerNameEN &&
                                  items.consumerNameEN.length > 31
                                  ? items.consumerNameEN.substring(0, 31) +
                                  "..."
                                  : items.consumerNameEN}
                              </span>
                            )}

                            {lancode === "th" && (
                              <span
                                key={items.id}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={items.consumerNameTH}
                                onClick={() => routeToProductDetailsPage(items)}
                              >
                                {items.consumerNameTH &&
                                  items.consumerNameTH.length > 31
                                  ? items.consumerNameTH.substring(0, 31) +
                                  "..."
                                  : items.consumerNameTH}
                              </span>
                            )}
                          </div>
                          <div className="promo-price-rt">
                            {authUser && (
                              <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                            )}
                            {lancode === "en" && (
                              <h5 key={items.id}>{items.packSizeEN}</h5>
                            )}
                            {lancode === "th" && (
                              <h5 key={items.id}>{items.packSizeTH}</h5>
                            )}
                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                              (!isNull(items.stock) ? (
                                <span className="stock-field-in">
                                  {" "}
                                  {items.stock} in Stock{" "}
                                </span>
                              ) : (
                                <></>
                              ))}
                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                              (isNull(items.stock) ? (
                                <span className="stock-field-out">
                                  {" "}
                                  {t("OutofStock.label")}
                                </span>
                              ) : (
                                <></>
                              ))}
                          </div>
                        </div>
                        {!isNull(userData) && <div className="promo-desc">
                          {/*  {items.selectValues.length > 1 ? */}
                          <select
                            defaultValue={null}
                            onChange={(e) => {

                              let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                              let temp2 = items
                              temp2.picture = selectedItem.picture
                              temp2.price1Scale1 = selectedItem.price1Scale1
                              //                             
                              temp2.price2Scale2 = selectedItem.price2Scale2;
                              temp2.price3Scale3 = selectedItem.price3Scale3;
                              temp2.price4Scale4 = selectedItem.price4Scale4;
                              temp2.price5Scale5 = selectedItem.price5Scale5;
                              temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                              temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                              temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                              temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                              temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                              // 
                              temp2.articaleCode = selectedItem.articaleCode
                              temp2.cartQuantity = selectedItem.cartQuantity
                              temp2.wishlistStatus = selectedItem.wishlistStatus
                              temp2.stock = selectedItem.stock
                              temp2.cartId = selectedItem.cartId
                              // 
                              temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                              temp2.packSize = selectedItem.packSize
                              temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                              //
                              temp2.conditionAmount = selectedItem.conditionAmount
                              temp2.consumerNameEN = selectedItem.consumerNameEN
                              temp2.packSizeEN = selectedItem.packSizeEN
                              temp2.productTypeEN = selectedItem.productTypeEN
                              // temp2.aboutProductEN = selectedItem.abutProductEN
                              temp2.aboutProductEN = selectedItem.aboutProductEN
                              temp2.shelfLifeEN = selectedItem.shelfLifeEN
                              temp2.otherProductEN = selectedItem.otherProductEN
                              temp2.consumerNameTH = selectedItem.consumerNameTH
                              temp2.packSizeTH = selectedItem.packSizeTH
                              temp2.productTypeTH = selectedItem.productTypeTH
                              temp2.aboutProductTH = selectedItem.aboutProductTH
                              temp2.instructionTH = selectedItem.instructionTH
                              temp2.shelfLifeTH = selectedItem.shelfLifeTH
                              temp2.otherProductTH = selectedItem.otherProductTH
                              // let temp = items.selectValues
                              // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                              // let temp2 = items
                              // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                              // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                              // temp2.picture = selectedItem.picture;
                              // temp2.articaleCode = selectedItem.articaleCode;
                              // temp2.price1Scale1 = selectedItem.price1Scale1;
                              // temp2.packSizeEN = selectedItem.packSizeEN;
                              // temp2.stock = selectedItem.stock;
                              // temp.map((val) => {
                              //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                              // })
                              // setPromoData(temp2)

                              updateProductValue(true)
                            }}
                          >
                            {
                              items.selectValues.map((i, ind) => {
                                // let selected = (i.select) ? 'selected' : '';
                                // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                              })
                            }
                          </select>
                          {/*    : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                        </div>}

                        {/* <div className="promo-desc">
                          <select
                            onChange={(e) => {
                              alert("WIP");
                            }}
                          >
                            {items.selectValues &&
                              items.selectValues.map((value) => {
                                let selected = value.select ? "selected" : "";
                                let result = [
                                  value.articaleCode,
                                  value.valueEN,
                                  value.valueTH,
                                  value.packSizeEN,
                                  value.productTypeEN,
                                  value.consumerNameEN,
                                ];
                                return (
                                  <option value={result} {...selected}>
                                    {lancode === "en"
                                      ? value.valueEN
                                      : value.valueTH}
                                  </option>
                                );
                              })}
                          </select>
                        </div> */}

                        <div className="promo-btn">
                          <div className="promo-btn-lt">
                            {items && !items.wishlistStatus &&
                              <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice510(true, items, index)}>
                                <img src={WishListGrey} />
                                {/* <span className="hidden-xs">Add To Whishlist</span> */}
                              </button>
                            }
                            {items && items.wishlistStatus &&
                              <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice510(false, items, index)}>
                                <img src={ActiveWishlist} />
                                {/* <img className="visible-xs" src={WishListWhite} /> */}
                                {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                              </button>
                            }
                          </div>
                          {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                            <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                              <div className="add-to-cart-container">
                                <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice510(items, index)}>
                                  <img alt="" src="../AddToCartWhite.svg" />
                                  {t("Addtocart.label")}
                                </button>
                              </div>
                            </div> : null}
                          {items.cartQuantity > 0 ?
                            <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                              <div className="promo-btn-addtocart-add-minus">
                                <div className="row text-center">
                                  <div className="col-sm-5 col-5 justify-content-start">
                                    <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice510(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                  </div>
                                  <div className="col-sm-2 col-2 p-0 m-0 justify-content-center">
                                    <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                  </div>
                                  <div className="col-sm-5 col-5 justify-content-end">
                                    <img src={addProdouctButton} onClick={(e) => { updateQuantityslice510(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                  </div>
                                </div>
                              </div>
                            </div> : null}
                        </div>
                      </div>
                    ))}
                </div>
                {/* end second row of first item */}
              </Carousel.Item>

              {/* second slide */}
              {promoData && promoData.length > 0 && promoData.length > 10 && (
                <Carousel.Item>
                  <div className="promotions">
                    {promoData &&
                      promoData.length > 0 &&
                      promoData.slice(10, 15).map((items, index) => (
                        <div className="promo" key={index}>
                          <div className="promo-img" onClick={() => routeToProductDetailsPage(items)}>
                            <div className="promo-img-badge">
                              <img
                                alt=""
                                src="../PromoIconRed.svg"

                              />
                            </div>
                            {/* <img src="../rectangle-2067@2x.png"/> */}
                            <img onClick={() => routeToProductDetailsPage(items)}
                              src={
                                (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                  (REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    ? REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    : blankImage)
                                  : blankImage
                              }
                            />
                          </div>
                          <div className="promo-price">
                            <div className="promo-price-lt">
                              {lancode === "en" && (
                                <span key={items.id} onClick={() => routeToProductDetailsPage(items)}>
                                  {items.consumerNameEN &&
                                    items.consumerNameEN.length > 31
                                    ? items.consumerNameEN.substring(0, 31) +
                                    "..."
                                    : items.consumerNameEN}
                                </span>
                              )}

                              {lancode === "th" && (
                                <span key={items.id} onClick={() => routeToProductDetailsPage(items)}>
                                  {items.consumerNameTH &&
                                    items.consumerNameTH.length > 31
                                    ? items.consumerNameTH.substring(0, 31) +
                                    "..."
                                    : items.consumerNameTH}
                                </span>
                              )}
                            </div>
                            <div className="promo-price-rt">
                              {authUser && (
                                <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                              )}
                              {lancode === "en" && (
                                <h5 key={items.id}>{items.packSizeEN}</h5>
                              )}
                              {lancode === "th" && (
                                <h5 key={items.id}>{items.packSizeTH}</h5>
                              )}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (!isNull(items.stock) ? (
                                  <span className="stock-field-in">
                                    {" "}
                                    {items.stock} in Stock{" "}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (isNull(items.stock) ? (
                                  <span className="stock-field-out">
                                    {" "}
                                    {t("OutofStock.label")}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                            </div>
                          </div>
                          {!isNull(userData) && <div className="promo-desc">
                            {/*  {items.selectValues.length > 1 ? */}
                            <select
                              defaultValue={null}
                              onChange={(e) => {

                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = items
                                temp2.picture = selectedItem.picture
                                temp2.price1Scale1 = selectedItem.price1Scale1
                                //                             
                                temp2.price2Scale2 = selectedItem.price2Scale2;
                                temp2.price3Scale3 = selectedItem.price3Scale3;
                                temp2.price4Scale4 = selectedItem.price4Scale4;
                                temp2.price5Scale5 = selectedItem.price5Scale5;
                                temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                // 
                                temp2.articaleCode = selectedItem.articaleCode
                                temp2.cartQuantity = selectedItem.cartQuantity
                                temp2.wishlistStatus = selectedItem.wishlistStatus
                                temp2.stock = selectedItem.stock
                                temp2.cartId = selectedItem.cartId
                                // 
                                temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2.packSize = selectedItem.packSize
                                temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                //
                                temp2.conditionAmount = selectedItem.conditionAmount
                                temp2.consumerNameEN = selectedItem.consumerNameEN
                                temp2.packSizeEN = selectedItem.packSizeEN
                                temp2.productTypeEN = selectedItem.productTypeEN
                                // temp2.aboutProductEN = selectedItem.abutProductEN
                                temp2.aboutProductEN = selectedItem.aboutProductEN
                                temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.otherProductEN = selectedItem.otherProductEN
                                temp2.consumerNameTH = selectedItem.consumerNameTH
                                temp2.packSizeTH = selectedItem.packSizeTH
                                temp2.productTypeTH = selectedItem.productTypeTH
                                temp2.aboutProductTH = selectedItem.aboutProductTH
                                temp2.instructionTH = selectedItem.instructionTH
                                temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.otherProductTH = selectedItem.otherProductTH
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/*     : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}


                          {/* <div className="promo-desc">
                            <select
                              onChange={(e) => {
                                alert("WIP");
                              }}
                            >
                              {items.selectValues &&
                                items.selectValues.map((value) => {
                                  let selected = value.select ? "selected" : "";
                                  let result = [
                                    value.articaleCode,
                                    value.valueEN,
                                    value.valueTH,
                                    value.packSizeEN,
                                    value.productTypeEN,
                                    value.consumerNameEN,
                                  ];
                                  return (
                                    <option value={result} {...selected}>
                                      {lancode === "en"
                                        ? value.valueEN
                                        : value.valueTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
 */}
                          <div className="promo-btn">
                            <div className="promo-btn-lt">
                              {items && !items.wishlistStatus &&
                                <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice1015(true, items, index)}>
                                  <img src={WishListGrey} />
                                  {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                </button>
                              }
                              {items && items.wishlistStatus &&
                                <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice1015(false, items, index)}>
                                  <img src={ActiveWishlist} />
                                  {/* <img className="visible-xs" src={WishListWhite} /> */}
                                  {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                </button>
                              }
                            </div>
                            {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                <div className="add-to-cart-container">
                                  <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice1015(items, index)}>
                                    <img alt="" src="../AddToCartWhite.svg" />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              </div> : null}

                            {items.cartQuantity > 0 ?
                              <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                <div className="promo-btn-addtocart-add-minus">
                                  <div className="row text-center">
                                    <div className="col-sm-5 col-5 d-flex justify-content-start">
                                      <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice1015(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                    <div className="col-sm-2 col-2 p-0 m-0 d-flex justify-content-center">
                                      <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                    </div>
                                    <div className="col-sm-5 col-5 d-flex justify-content-end">
                                      <img src={addProdouctButton} onClick={(e) => { updateQuantityslice1015(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div> : null}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* start second row of first item */}

                  <div className="promotions">
                    {promoData &&
                      promoData.length > 0 &&
                      promoData.slice(15, 20).map((items, index) => (
                        <div className="promo" key={index}>
                          <div className="promo-img" >
                            <div className="promo-img-badge">
                              <img
                                alt=""
                                src="../PromoIconRed.svg"

                              />
                            </div>
                            {/* <img src="../rectangle-2067@2x.png"/> */}
                            <img onClick={() => routeToProductDetailsPage(items)}
                              src={
                                (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                  (REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    ? REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    : blankImage)
                                  : blankImage
                              }
                            />
                          </div>
                          <div className="promo-price">
                            <div className="promo-price-lt">
                              {lancode === "en" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameEN}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameEN &&
                                    items.consumerNameEN.length > 31
                                    ? items.consumerNameEN.substring(0, 31) +
                                    "..."
                                    : items.consumerNameEN}
                                </span>
                              )}

                              {lancode === "th" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameTH}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameTH &&
                                    items.consumerNameTH.length > 31
                                    ? items.consumerNameTH.substring(0, 31) +
                                    "..."
                                    : items.consumerNameTH}
                                </span>
                              )}
                            </div>
                            <div className="promo-price-rt">
                              {authUser && (
                                <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                              )}
                              {lancode === "en" && (
                                <h5 key={items.id}>{items.packSizeEN}</h5>
                              )}
                              {lancode === "th" && (
                                <h5 key={items.id}>{items.packSizeTH}</h5>
                              )}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (!isNull(items.stock) ? (
                                  <span className="stock-field-in">
                                    {" "}
                                    {items.stock} in Stock{" "}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (isNull(items.stock) ? (
                                  <span className="stock-field-out">
                                    {" "}
                                    {t("OutofStock.label")}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                            </div>
                          </div>
                          {!isNull(userData) && <div className="promo-desc">
                            {/* {items.selectValues.length > 1 ? */}
                            <select
                              defaultValue={null}
                              onChange={(e) => {

                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = items
                                temp2.picture = selectedItem.picture
                                temp2.price1Scale1 = selectedItem.price1Scale1
                                //                             
                                temp2.price2Scale2 = selectedItem.price2Scale2;
                                temp2.price3Scale3 = selectedItem.price3Scale3;
                                temp2.price4Scale4 = selectedItem.price4Scale4;
                                temp2.price5Scale5 = selectedItem.price5Scale5;
                                temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                // 
                                temp2.articaleCode = selectedItem.articaleCode
                                temp2.cartQuantity = selectedItem.cartQuantity
                                temp2.wishlistStatus = selectedItem.wishlistStatus
                                temp2.stock = selectedItem.stock
                                temp2.cartId = selectedItem.cartId
                                // 
                                temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2.packSize = selectedItem.packSize
                                temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                //
                                temp2.conditionAmount = selectedItem.conditionAmount
                                temp2.consumerNameEN = selectedItem.consumerNameEN
                                temp2.packSizeEN = selectedItem.packSizeEN
                                temp2.productTypeEN = selectedItem.productTypeEN
                                // temp2.aboutProductEN = selectedItem.abutProductEN
                                temp2.aboutProductEN = selectedItem.aboutProductEN
                                temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.otherProductEN = selectedItem.otherProductEN
                                temp2.consumerNameTH = selectedItem.consumerNameTH
                                temp2.packSizeTH = selectedItem.packSizeTH
                                temp2.productTypeTH = selectedItem.productTypeTH
                                temp2.aboutProductTH = selectedItem.aboutProductTH
                                temp2.instructionTH = selectedItem.instructionTH
                                temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.otherProductTH = selectedItem.otherProductTH
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}


                          {/* <div className="promo-desc">
                            <select
                              onChange={(e) => {
                                alert("WIP");
                              }}
                            >
                              {items.selectValues &&
                                items.selectValues.map((value) => {
                                  let selected = value.select ? "selected" : "";
                                  let result = [
                                    value.articaleCode,
                                    value.valueEN,
                                    value.valueTH,
                                    value.packSizeEN,
                                    value.productTypeEN,
                                    value.consumerNameEN,
                                  ];
                                  return (
                                    <option value={result} {...selected}>
                                      {lancode === "en"
                                        ? value.valueEN
                                        : value.valueTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div> */}

                          <div className="promo-btn">
                            <div className="promo-btn-lt">
                              {items && !items.wishlistStatus &&
                                <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice1520(true, items, index)}>
                                  <img src={WishListGrey} />
                                  {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                </button>
                              }
                              {items && items.wishlistStatus &&
                                <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice1520(false, items, index)}>
                                  <img src={ActiveWishlist} />
                                  {/* <img className="visible-xs" src={WishListWhite} /> */}
                                  {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                </button>
                              }
                            </div>
                            {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                <div className="add-to-cart-container">
                                  <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice1520(items, index)}>
                                    <img alt="" src="../AddToCartWhite.svg" />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              </div> : null}
                            {items.cartQuantity > 0 ?
                              <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                <div className="promo-btn-addtocart-add-minus">
                                  <div className="row text-center">
                                    <div className="col-sm-5 col-5 justify-content-start p-0">
                                      <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice1520(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                    <div className="col-sm-2 col-2 p-0 m-0 justify-content-center">
                                      <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                    </div>
                                    <div className="col-sm-5 col-5 justify-content-end p-0">
                                      <img src={addProdouctButton} onClick={(e) => { updateQuantityslice1520(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div> : null}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* end second row of first item */}
                </Carousel.Item>
              )}

              {/* third slide */}
              {promoData && promoData.length > 20 && (
                <Carousel.Item>
                  <div className="promotions">
                    {promoData &&
                      promoData.length > 0 &&
                      promoData.slice(20, 25).map((items, index) => (
                        <div className="promo" key={index}>
                          <div className="promo-img" onClick={() => routeToProductDetailsPage(items)}>
                            <div className="promo-img-badge">
                              <img
                                alt=""
                                src="../PromoIconRed.svg"

                              />
                            </div>
                            {/* <img src="../rectangle-2067@2x.png"/> */}
                            <img onClick={() => routeToProductDetailsPage(items)}
                              src={
                                (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                  (REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    ? REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    : blankImage)
                                  : blankImage
                              }
                            />
                          </div>
                          <div className="promo-price">
                            <div className="promo-price-lt">
                              {lancode === "en" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameEN}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameEN &&
                                    items.consumerNameEN.length > 31
                                    ? items.consumerNameEN.substring(0, 31) +
                                    "..."
                                    : items.consumerNameEN}
                                </span>
                              )}

                              {lancode === "th" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameTH}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameTH &&
                                    items.consumerNameTH.length > 31
                                    ? items.consumerNameTH.substring(0, 31) +
                                    "..."
                                    : items.consumerNameTH}
                                </span>
                              )}
                            </div>
                            <div className="promo-price-rt">
                              {authUser && (
                                <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                              )}
                              {lancode === "en" && (
                                <h5 key={items.id}>{items.packSizeEN}</h5>
                              )}
                              {lancode === "th" && (
                                <h5 key={items.id}>{items.packSizeTH}</h5>
                              )}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (!isNull(items.stock) ? (
                                  <span className="stock-field-in">
                                    {" "}
                                    {items.stock} in Stock{" "}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (isNull(items.stock) ? (
                                  <span className="stock-field-out">
                                    {" "}
                                    {t("OutofStock.label")}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                            </div>
                          </div>

                          {!isNull(userData) && <div className="promo-desc">
                            {/*  {items.selectValues.length > 1 ? */}
                            <select
                              defaultValue={null}
                              onChange={(e) => {

                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = items
                                temp2.picture = selectedItem.picture
                                temp2.price1Scale1 = selectedItem.price1Scale1
                                //                             
                                temp2.price2Scale2 = selectedItem.price2Scale2;
                                temp2.price3Scale3 = selectedItem.price3Scale3;
                                temp2.price4Scale4 = selectedItem.price4Scale4;
                                temp2.price5Scale5 = selectedItem.price5Scale5;
                                temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                // 
                                temp2.articaleCode = selectedItem.articaleCode
                                temp2.cartQuantity = selectedItem.cartQuantity
                                temp2.wishlistStatus = selectedItem.wishlistStatus
                                temp2.stock = selectedItem.stock
                                temp2.cartId = selectedItem.cartId
                                // 
                                temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2.packSize = selectedItem.packSize
                                temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                //
                                temp2.conditionAmount = selectedItem.conditionAmount
                                temp2.consumerNameEN = selectedItem.consumerNameEN
                                temp2.packSizeEN = selectedItem.packSizeEN
                                temp2.productTypeEN = selectedItem.productTypeEN
                                // temp2.aboutProductEN = selectedItem.abutProductEN
                                temp2.aboutProductEN = selectedItem.aboutProductEN
                                temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.otherProductEN = selectedItem.otherProductEN
                                temp2.consumerNameTH = selectedItem.consumerNameTH
                                temp2.packSizeTH = selectedItem.packSizeTH
                                temp2.productTypeTH = selectedItem.productTypeTH
                                temp2.aboutProductTH = selectedItem.aboutProductTH
                                temp2.instructionTH = selectedItem.instructionTH
                                temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.otherProductTH = selectedItem.otherProductTH
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/*   : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}


                          {/*  <div className="promo-desc">
                            <select
                              onChange={(e) => {
                                alert("WIP");
                              }}
                            >
                              {items.selectValues &&
                                items.selectValues.map((value) => {
                                  let selected = value.select ? "selected" : "";
                                  let result = [
                                    value.articaleCode,
                                    value.valueEN,
                                    value.valueTH,
                                    value.packSizeEN,
                                    value.productTypeEN,
                                    value.consumerNameEN,
                                  ];
                                  return (
                                    <option value={result} {...selected}>
                                      {lancode === "en"
                                        ? value.valueEN
                                        : value.valueTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div> */}

                          <div className="promo-btn">
                            <div className="promo-btn-lt">
                              {items && !items.wishlistStatus &&
                                <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice2025(true, items, index)}>
                                  <img src={WishListGrey} />
                                  {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                </button>
                              }
                              {items && items.wishlistStatus &&
                                <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice2025(false, items, index)}>
                                  <img src={ActiveWishlist} />
                                  {/* <img className="visible-xs" src={WishListWhite} /> */}
                                  {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                </button>
                              }
                            </div>
                            {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                <div className="add-to-cart-container">
                                  <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice2025(items, index)}>
                                    <img alt="" src="../AddToCartWhite.svg" />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              </div> : null}

                            {items.cartQuantity > 0 ?
                              <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                <div className="promo-btn-addtocart-add-minus">
                                  <div className="row text-center">
                                    <div className="col-sm-5 col-5 justify-content-start p-0">
                                      <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice2025(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                    <div className="col-sm-2 col-2 p-0 m-0 justify-content-center">
                                      <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                    </div>
                                    <div className="col-sm-5 col-5 justify-content-end p-0">
                                      <img src={addProdouctButton} onClick={(e) => { updateQuantityslice2025(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div> : null}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* start second row of first item */}

                  <div className="promotions">
                    {promoData &&
                      promoData.length > 0 &&
                      promoData.slice(25, 30).map((items, index) => (
                        <div className="promo" key={index}>
                          <div className="promo-img">
                            <div className="promo-img-badge">
                              <img
                                alt=""
                                src="../PromoIconRed.svg"

                              />
                            </div>
                            {/* <img src="../rectangle-2067@2x.png"/> */}
                            <img onClick={() => routeToProductDetailsPage(items)}
                              src={
                                (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                  (REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    ? REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    : blankImage)
                                  : blankImage
                              }
                            />
                          </div>
                          <div className="promo-price">
                            <div className="promo-price-lt">
                              {lancode === "en" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameEN}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameEN &&
                                    items.consumerNameEN.length > 31
                                    ? items.consumerNameEN.substring(0, 31) +
                                    "..."
                                    : items.consumerNameEN}
                                </span>
                              )}

                              {lancode === "th" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameTH}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameTH &&
                                    items.consumerNameTH.length > 31
                                    ? items.consumerNameTH.substring(0, 31) +
                                    "..."
                                    : items.consumerNameTH}
                                </span>
                              )}
                            </div>
                            <div className="promo-price-rt">
                              {authUser && (
                                <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                              )}
                              {lancode === "en" && (
                                <h5 key={items.id}>{items.packSizeEN}</h5>
                              )}
                              {lancode === "th" && (
                                <h5 key={items.id}>{items.packSizeTH}</h5>
                              )}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (!isNull(items.stock) ? (
                                  <span className="stock-field-in">
                                    {" "}
                                    {items.stock} in Stock{" "}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (isNull(items.stock) ? (
                                  <span className="stock-field-out">
                                    {" "}
                                    {t("OutofStock.label")}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                            </div>
                          </div>

                          {!isNull(userData) && <div className="promo-desc">
                            {/*  {items.selectValues.length > 1 ? */}
                            <select
                              defaultValue={null}
                              onChange={(e) => {

                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = items
                                temp2.picture = selectedItem.picture
                                temp2.price1Scale1 = selectedItem.price1Scale1
                                //                             
                                temp2.price2Scale2 = selectedItem.price2Scale2;
                                temp2.price3Scale3 = selectedItem.price3Scale3;
                                temp2.price4Scale4 = selectedItem.price4Scale4;
                                temp2.price5Scale5 = selectedItem.price5Scale5;
                                temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                // 
                                temp2.articaleCode = selectedItem.articaleCode
                                temp2.cartQuantity = selectedItem.cartQuantity
                                temp2.wishlistStatus = selectedItem.wishlistStatus
                                temp2.stock = selectedItem.stock
                                temp2.cartId = selectedItem.cartId
                                // 
                                temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2.packSize = selectedItem.packSize
                                temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                //
                                temp2.conditionAmount = selectedItem.conditionAmount
                                temp2.consumerNameEN = selectedItem.consumerNameEN
                                temp2.packSizeEN = selectedItem.packSizeEN
                                temp2.productTypeEN = selectedItem.productTypeEN
                                // temp2.aboutProductEN = selectedItem.abutProductEN
                                temp2.aboutProductEN = selectedItem.aboutProductEN
                                temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.otherProductEN = selectedItem.otherProductEN
                                temp2.consumerNameTH = selectedItem.consumerNameTH
                                temp2.packSizeTH = selectedItem.packSizeTH
                                temp2.productTypeTH = selectedItem.productTypeTH
                                temp2.aboutProductTH = selectedItem.aboutProductTH
                                temp2.instructionTH = selectedItem.instructionTH
                                temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.otherProductTH = selectedItem.otherProductTH
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}

                          {/* <div className="promo-desc">
                            <select
                              onChange={(e) => {
                                alert("WIP");
                              }}
                            >
                              {items.selectValues &&
                                items.selectValues.map((value) => {
                                  let selected = value.select ? "selected" : "";
                                  let result = [
                                    value.articaleCode,
                                    value.valueEN,
                                    value.valueTH,
                                    value.packSizeEN,
                                    value.productTypeEN,
                                    value.consumerNameEN,
                                  ];
                                  return (
                                    <option value={result} {...selected}>
                                      {lancode === "en"
                                        ? value.valueEN
                                        : value.valueTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div> */}

                          <div className="promo-btn">
                            <div className="promo-btn-lt">
                              {items && !items.wishlistStatus &&
                                <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice2530(true, items, index)}>
                                  <img src={WishListGrey} />
                                  {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                </button>
                              }
                              {items && items.wishlistStatus &&
                                <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice2530(false, items, index)}>
                                  <img src={ActiveWishlist} />
                                  {/* <img className="visible-xs" src={WishListWhite} /> */}
                                  {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                </button>
                              }
                            </div>
                            {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                <div className="add-to-cart-container">
                                  <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice2530(items, index)}>
                                    <img alt="" src="../AddToCartWhite.svg" />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              </div> : null}
                            {items.cartQuantity > 0 ?
                              <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                <div className="promo-btn-addtocart-add-minus">
                                  <div className="row text-center">
                                    <div className="col-sm-5 col-5 justify-content-start p-0">
                                      <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice2530(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                    <div className="col-sm-2 col-2 p-0 m-0 justify-content-center">
                                      <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                    </div>
                                    <div className="col-sm-5 col-5 justify-content-end p-0">
                                      <img src={addProdouctButton} onClick={(e) => { updateQuantityslice2530(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div> : null}

                          </div>
                        </div>
                      ))}
                  </div>
                  {/* end second row of first item */}
                </Carousel.Item>
              )}

              {/* fourth slide */}
              {promoData && promoData.length > 30 && (
                <Carousel.Item>
                  <div className="promotions">
                    {promoData &&
                      promoData.length > 0 &&
                      promoData.slice(30, 35).map((items, index) => (
                        <div className="promo" key={index}>
                          <div className="promo-img" onClick={() => routeToProductDetailsPage(items)}>
                            <div className="promo-img-badge">
                              <img
                                alt=""
                                src="../PromoIconRed.svg"

                              />
                            </div>
                            {/* <img src="../rectangle-2067@2x.png"/> */}
                            <img onClick={() => routeToProductDetailsPage(items)}
                              src={
                                (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                  (REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    ? REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    : blankImage)
                                  : blankImage
                              }
                            />
                          </div>
                          <div className="promo-price">
                            <div className="promo-price-lt">
                              {lancode === "en" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameEN}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameEN &&
                                    items.consumerNameEN.length > 31
                                    ? items.consumerNameEN.substring(0, 31) +
                                    "..."
                                    : items.consumerNameEN}
                                </span>
                              )}

                              {lancode === "th" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameTH}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameTH &&
                                    items.consumerNameTH.length > 31
                                    ? items.consumerNameTH.substring(0, 31) +
                                    "..."
                                    : items.consumerNameTH}
                                </span>
                              )}
                            </div>
                            <div className="promo-price-rt">
                              {authUser && (
                                <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                              )}
                              {lancode === "en" && (
                                <h5 key={items.id}>{items.packSizeEN}</h5>
                              )}
                              {lancode === "th" && (
                                <h5 key={items.id}>{items.packSizeTH}</h5>
                              )}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (!isNull(items.stock) ? (
                                  <span className="stock-field-in">
                                    {" "}
                                    {items.stock} in Stock{" "}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (isNull(items.stock) ? (
                                  <span className="stock-field-out">
                                    {" "}
                                    {t("OutofStock.label")}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                            </div>
                          </div>

                          {!isNull(userData) && <div className="promo-desc">
                            {/*  {items.selectValues.length > 1 ? */}
                            <select
                              defaultValue={null}
                              onChange={(e) => {

                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = items
                                temp2.picture = selectedItem.picture
                                temp2.price1Scale1 = selectedItem.price1Scale1
                                //                             
                                temp2.price2Scale2 = selectedItem.price2Scale2;
                                temp2.price3Scale3 = selectedItem.price3Scale3;
                                temp2.price4Scale4 = selectedItem.price4Scale4;
                                temp2.price5Scale5 = selectedItem.price5Scale5;
                                temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                // 
                                temp2.articaleCode = selectedItem.articaleCode
                                temp2.cartQuantity = selectedItem.cartQuantity
                                temp2.wishlistStatus = selectedItem.wishlistStatus
                                temp2.stock = selectedItem.stock
                                temp2.cartId = selectedItem.cartId
                                // 
                                temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2.packSize = selectedItem.packSize
                                temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                //
                                temp2.conditionAmount = selectedItem.conditionAmount
                                temp2.consumerNameEN = selectedItem.consumerNameEN
                                temp2.packSizeEN = selectedItem.packSizeEN
                                temp2.productTypeEN = selectedItem.productTypeEN
                                // temp2.aboutProductEN = selectedItem.abutProductEN
                                temp2.aboutProductEN = selectedItem.aboutProductEN
                                temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.otherProductEN = selectedItem.otherProductEN
                                temp2.consumerNameTH = selectedItem.consumerNameTH
                                temp2.packSizeTH = selectedItem.packSizeTH
                                temp2.productTypeTH = selectedItem.productTypeTH
                                temp2.aboutProductTH = selectedItem.aboutProductTH
                                temp2.instructionTH = selectedItem.instructionTH
                                temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.otherProductTH = selectedItem.otherProductTH
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}


                          {/*  <div className="promo-desc">
                            <select
                              onChange={(e) => {
                                alert("WIP");
                              }}
                            >
                              {items.selectValues &&
                                items.selectValues.map((value) => {
                                  let selected = value.select ? "selected" : "";
                                  let result = [
                                    value.articaleCode,
                                    value.valueEN,
                                    value.valueTH,
                                    value.packSizeEN,
                                    value.productTypeEN,
                                    value.consumerNameEN,
                                  ];
                                  return (
                                    <option value={result} {...selected}>
                                      {lancode === "en"
                                        ? value.valueEN
                                        : value.valueTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div> */}

                          <div className="promo-btn">
                            <div className="promo-btn-lt">
                              {items && !items.wishlistStatus &&
                                <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice3035(true, items, index)}>
                                  <img src={WishListGrey} />
                                  {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                </button>
                              }
                              {items && items.wishlistStatus &&
                                <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice3035(false, items, index)}>
                                  <img src={ActiveWishlist} />
                                  {/* <img className="visible-xs" src={WishListWhite} /> */}
                                  {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                </button>
                              }
                            </div>
                            {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                <div className="add-to-cart-container">
                                  <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice3035(items, index)}>
                                    <img alt="" src="../AddToCartWhite.svg" />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              </div> : null}
                            {items.cartQuantity > 0 ?
                              <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                <div className="promo-btn-addtocart-add-minus">
                                  <div className="row text-center">
                                    <div className="col-sm-5 col-5 justify-content-start p-0">
                                      <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice3035(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                    <div className="col-sm-2 col-2 p-0 m-0 justify-content-center">
                                      <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                    </div>
                                    <div className="col-sm-5 col-5 justify-content-end p-0">
                                      <img src={addProdouctButton} onClick={(e) => { updateQuantityslice3035(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div> : null}

                          </div>
                        </div>
                      ))}
                  </div>

                  {/* start second row of first item */}

                  <div className="promotions">
                    {promoData &&
                      promoData.length > 0 &&
                      promoData.slice(35, 40).map((items, index) => (
                        <div className="promo" key={index}>
                          <div className="promo-img">
                            <div className="promo-img-badge">
                              <img
                                alt=""
                                src="../PromoIconRed.svg"

                              />
                            </div>
                            {/* <img src="../rectangle-2067@2x.png"/> */}
                            <img onClick={() => routeToProductDetailsPage(items)}
                              src={
                                (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                  (REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    ? REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    : blankImage)
                                  : blankImage
                              }
                            />
                          </div>
                          <div className="promo-price">
                            <div className="promo-price-lt">
                              {lancode === "en" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameEN}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameEN &&
                                    items.consumerNameEN.length > 31
                                    ? items.consumerNameEN.substring(0, 31) +
                                    "..."
                                    : items.consumerNameEN}
                                </span>
                              )}

                              {lancode === "th" && (
                                <p
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameTH}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameTH &&
                                    items.consumerNameTH.length > 31
                                    ? items.consumerNameTH.substring(0, 31) +
                                    "..."
                                    : items.consumerNameTH}
                                </p>
                              )}
                            </div>
                            <div className="promo-price-rt">
                              {authUser && (
                                <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                              )}
                              {lancode === "en" && (
                                <h5 key={items.id}>{items.packSizeEN}</h5>
                              )}
                              {lancode === "th" && (
                                <h5 key={items.id}>{items.packSizeTH}</h5>
                              )}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (!isNull(items.stock) ? (
                                  <span className="stock-field-in">
                                    {" "}
                                    {items.stock} in Stock{" "}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (isNull(items.stock) ? (
                                  <span className="stock-field-out">
                                    {" "}
                                    {t("OutofStock.label")}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                            </div>
                          </div>

                          {!isNull(userData) && <div className="promo-desc">
                            {/* {items.selectValues.length > 1 ? */}
                            <select
                              defaultValue={null}
                              onChange={(e) => {

                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = items
                                temp2.picture = selectedItem.picture
                                temp2.price1Scale1 = selectedItem.price1Scale1
                                //                             
                                temp2.price2Scale2 = selectedItem.price2Scale2;
                                temp2.price3Scale3 = selectedItem.price3Scale3;
                                temp2.price4Scale4 = selectedItem.price4Scale4;
                                temp2.price5Scale5 = selectedItem.price5Scale5;
                                temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                // 
                                temp2.articaleCode = selectedItem.articaleCode
                                temp2.cartQuantity = selectedItem.cartQuantity
                                temp2.wishlistStatus = selectedItem.wishlistStatus
                                temp2.stock = selectedItem.stock
                                temp2.cartId = selectedItem.cartId
                                // 
                                temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2.packSize = selectedItem.packSize
                                temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                //
                                temp2.conditionAmount = selectedItem.conditionAmount
                                temp2.consumerNameEN = selectedItem.consumerNameEN
                                temp2.packSizeEN = selectedItem.packSizeEN
                                temp2.productTypeEN = selectedItem.productTypeEN
                                // temp2.aboutProductEN = selectedItem.abutProductEN
                                temp2.aboutProductEN = selectedItem.aboutProductEN
                                temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.otherProductEN = selectedItem.otherProductEN
                                temp2.consumerNameTH = selectedItem.consumerNameTH
                                temp2.packSizeTH = selectedItem.packSizeTH
                                temp2.productTypeTH = selectedItem.productTypeTH
                                temp2.aboutProductTH = selectedItem.aboutProductTH
                                temp2.instructionTH = selectedItem.instructionTH
                                temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.otherProductTH = selectedItem.otherProductTH
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}

                          {/* <div className="promo-desc">
                            <select
                              onChange={(e) => {
                                alert("WIP");
                              }}
                            >
                              {items.selectValues &&
                                items.selectValues.map((value) => {
                                  let selected = value.select ? "selected" : "";
                                  let result = [
                                    value.articaleCode,
                                    value.valueEN,
                                    value.valueTH,
                                    value.packSizeEN,
                                    value.productTypeEN,
                                    value.consumerNameEN,
                                  ];
                                  return (
                                    <option value={result} {...selected}>
                                      {lancode === "en"
                                        ? value.valueEN
                                        : value.valueTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div> */}

                          <div className="promo-btn">
                            <div className="promo-btn-lt">
                              {items && !items.wishlistStatus &&
                                <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice3540(true, items, index)}>
                                  <img src={WishListGrey} />
                                  {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                </button>
                              }
                              {items && items.wishlistStatus &&
                                <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice3540(false, items, index)}>
                                  <img src={ActiveWishlist} />
                                  {/* <img className="visible-xs" src={WishListWhite} /> */}
                                  {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                </button>
                              }
                            </div>
                            {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                <div className="add-to-cart-container">
                                  <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice3540(items, index)}>
                                    <img alt="" src="../AddToCartWhite.svg" />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              </div> : null}
                            {items.cartQuantity > 0 ?
                              <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                <div className="promo-btn-addtocart-add-minus">
                                  <div className="row text-center">
                                    <div className="col-sm-5 col-5 d-flex justify-content-start">
                                      <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice3540(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                    <div className="col-sm-2 col-2 p-0 m-0 d-flex justify-content-center">
                                      <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                    </div>
                                    <div className="col-sm-5 col-5 d-flex justify-content-end">
                                      <img src={addProdouctButton} onClick={(e) => { updateQuantityslice3540(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div> : null}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* end second row of first item */}

                </Carousel.Item>
              )}
              {/* fifth slide */}
              {promoData && promoData.length > 40 && (
                <Carousel.Item>
                  <div className="promotions">
                    {promoData &&
                      promoData.length &&
                      promoData.slice(40, 45).map((items, index) => (
                        <div className="promo" key={index}>
                          <div className="promo-img" onClick={() => routeToProductDetailsPage(items)}>
                            <div className="promo-img-badge">
                              <img
                                alt=""
                                src="../PromoIconRed.svg"

                              />
                            </div>
                            {/* <img src="../rectangle-2067@2x.png"/> */}
                            <img onClick={() => routeToProductDetailsPage(items)}
                              src={
                                (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                  (REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    ? REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    : blankImage)
                                  : blankImage
                              }
                            />
                          </div>
                          <div className="promo-price">
                            <div className="promo-price-lt">
                              {lancode === "en" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameEN}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameEN &&
                                    items.consumerNameEN.length > 31
                                    ? items.consumerNameEN.substring(0, 31) +
                                    "..."
                                    : items.consumerNameEN}
                                </span>
                              )}

                              {lancode === "th" && (
                                <p
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameTH}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameTH &&
                                    items.consumerNameTH.length > 31
                                    ? items.consumerNameTH.substring(0, 31) +
                                    "..."
                                    : items.consumerNameTH}
                                </p>
                              )}
                            </div>
                            <div className="promo-price-rt">
                              {authUser && (
                                <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                              )}
                              {lancode === "en" && (
                                <h5 key={items.id}>{items.packSizeEN}</h5>
                              )}
                              {lancode === "th" && (
                                <h5 key={items.id}>{items.packSizeTH}</h5>
                              )}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (!isNull(items.stock) ? (
                                  <span className="stock-field-in">
                                    {" "}
                                    {items.stock} in Stock{" "}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (isNull(items.stock) ? (
                                  <span className="stock-field-out">
                                    {" "}
                                    {t("OutofStock.label")}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                            </div>
                          </div>

                          {!isNull(userData) && <div className="promo-desc">
                            {/*  {items.selectValues.length > 1 ? */}
                            <select
                              defaultValue={null}
                              onChange={(e) => {

                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = items
                                temp2.picture = selectedItem.picture
                                temp2.price1Scale1 = selectedItem.price1Scale1
                                //                             
                                temp2.price2Scale2 = selectedItem.price2Scale2;
                                temp2.price3Scale3 = selectedItem.price3Scale3;
                                temp2.price4Scale4 = selectedItem.price4Scale4;
                                temp2.price5Scale5 = selectedItem.price5Scale5;
                                temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                // 
                                temp2.articaleCode = selectedItem.articaleCode
                                temp2.cartQuantity = selectedItem.cartQuantity
                                temp2.wishlistStatus = selectedItem.wishlistStatus
                                temp2.stock = selectedItem.stock
                                temp2.cartId = selectedItem.cartId
                                // 
                                temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2.packSize = selectedItem.packSize
                                temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                //
                                temp2.conditionAmount = selectedItem.conditionAmount
                                temp2.consumerNameEN = selectedItem.consumerNameEN
                                temp2.packSizeEN = selectedItem.packSizeEN
                                temp2.productTypeEN = selectedItem.productTypeEN
                                // temp2.aboutProductEN = selectedItem.abutProductEN
                                temp2.aboutProductEN = selectedItem.aboutProductEN
                                temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.otherProductEN = selectedItem.otherProductEN
                                temp2.consumerNameTH = selectedItem.consumerNameTH
                                temp2.packSizeTH = selectedItem.packSizeTH
                                temp2.productTypeTH = selectedItem.productTypeTH
                                temp2.aboutProductTH = selectedItem.aboutProductTH
                                temp2.instructionTH = selectedItem.instructionTH
                                temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.otherProductTH = selectedItem.otherProductTH
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}


                          {/*  <div className="promo-desc">
                            <select
                              onChange={(e) => {
                                alert("WIP");
                              }}
                            >
                              {items.selectValues &&
                                items.selectValues.map((value) => {
                                  let selected = value.select ? "selected" : "";
                                  let result = [
                                    value.articaleCode,
                                    value.valueEN,
                                    value.valueTH,
                                    value.packSizeEN,
                                    value.productTypeEN,
                                    value.consumerNameEN,
                                  ];
                                  return (
                                    <option value={result} {...selected}>
                                      {lancode === "en"
                                        ? value.valueEN
                                        : value.valueTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div> */}

                          <div className="promo-btn">
                            <div className="promo-btn-lt">
                              {items && !items.wishlistStatus &&
                                <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice4045(true, items, index)}>
                                  <img src={WishListGrey} />
                                  {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                </button>
                              }
                              {items && items.wishlistStatus &&
                                <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice4045(false, items, index)}>
                                  <img src={ActiveWishlist} />
                                  {/* <img className="visible-xs" src={WishListWhite} /> */}
                                  {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                </button>
                              }
                            </div>
                            {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                <div className="add-to-cart-container">
                                  <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice4045(items, index)}>
                                    <img alt="" src="../AddToCartWhite.svg" />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              </div> : null}
                            {items.cartQuantity > 0 ?
                              <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                <div className="promo-btn-addtocart-add-minus">
                                  <div className="row text-center">
                                    <div className="col-sm-5 col-5 d-flex justify-content-start">
                                      <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice4045(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                    <div className="col-sm-2 col-2 p-0 m-0 d-flex justify-content-center">
                                      <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                    </div>
                                    <div className="col-sm-5 col-5 d-flex justify-content-end">
                                      <img src={addProdouctButton} onClick={(e) => { updateQuantityslice4045(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div> : null}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* start second row of first item */}

                  <div className="promotions">
                    {promoData &&
                      promoData.length > 0 &&
                      promoData.slice(45, 50).map((items, index) => (
                        <div className="promo" key={index}>
                          <div className="promo-img">
                            <div className="promo-img-badge">
                              <img
                                alt=""
                                src="../PromoIconRed.svg"

                              />
                            </div>
                            {/* <img src="../rectangle-2067@2x.png"/> */}
                            <img onClick={() => routeToProductDetailsPage(items)}
                              src={
                                (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                  (REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    ? REACT_APP_BASE_URL +
                                    "/api/download?" +
                                    items.picture.split(",")[0]
                                    : blankImage)
                                  : blankImage
                              }
                            />
                          </div>
                          <div className="promo-price">
                            <div className="promo-price-lt">
                              {lancode === "en" && (
                                <span
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameEN}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameEN &&
                                    items.consumerNameEN.length > 31
                                    ? items.consumerNameEN.substring(0, 31) +
                                    "..."
                                    : items.consumerNameEN}
                                </span>
                              )}

                              {lancode === "th" && (
                                <p
                                  key={items.id}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={items.consumerNameTH}
                                  onClick={() => routeToProductDetailsPage(items)}
                                >
                                  {items.consumerNameTH &&
                                    items.consumerNameTH.length > 31
                                    ? items.consumerNameTH.substring(0, 31) +
                                    "..."
                                    : items.consumerNameTH}
                                </p>
                              )}
                            </div>
                            <div className="promo-price-rt">
                              {authUser && (
                                <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                              )}
                              {lancode === "en" && (
                                <h5 key={items.id}>{items.packSizeEN}</h5>
                              )}
                              {lancode === "th" && (
                                <h5 key={items.id}>{items.packSizeTH}</h5>
                              )}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (!isNull(items.stock) ? (
                                  <span className="stock-field-in">
                                    {" "}
                                    {items.stock} in Stock{" "}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                              {!isNull(userData) &&
                                (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                (isNull(items.stock) ? (
                                  <span className="stock-field-out">
                                    {" "}
                                    {t("OutofStock.label")}
                                  </span>
                                ) : (
                                  <></>
                                ))}
                            </div>
                          </div>

                          {!isNull(userData) && <div className="promo-desc">
                            {/*  {items.selectValues.length > 1 ? */}
                            <select
                              defaultValue={null}
                              onChange={(e) => {

                                let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = items
                                temp2.picture = selectedItem.picture
                                temp2.price1Scale1 = selectedItem.price1Scale1
                                //                             
                                temp2.price2Scale2 = selectedItem.price2Scale2;
                                temp2.price3Scale3 = selectedItem.price3Scale3;
                                temp2.price4Scale4 = selectedItem.price4Scale4;
                                temp2.price5Scale5 = selectedItem.price5Scale5;
                                temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                // 
                                temp2.articaleCode = selectedItem.articaleCode
                                temp2.cartQuantity = selectedItem.cartQuantity
                                temp2.wishlistStatus = selectedItem.wishlistStatus
                                temp2.stock = selectedItem.stock
                                temp2.cartId = selectedItem.cartId
                                // 
                                temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                temp2.packSize = selectedItem.packSize
                                temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                //
                                temp2.conditionAmount = selectedItem.conditionAmount
                                temp2.consumerNameEN = selectedItem.consumerNameEN
                                temp2.packSizeEN = selectedItem.packSizeEN
                                temp2.productTypeEN = selectedItem.productTypeEN
                                // temp2.aboutProductEN = selectedItem.abutProductEN
                                temp2.aboutProductEN = selectedItem.aboutProductEN
                                temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.otherProductEN = selectedItem.otherProductEN
                                temp2.consumerNameTH = selectedItem.consumerNameTH
                                temp2.packSizeTH = selectedItem.packSizeTH
                                temp2.productTypeTH = selectedItem.productTypeTH
                                temp2.aboutProductTH = selectedItem.aboutProductTH
                                temp2.instructionTH = selectedItem.instructionTH
                                temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.otherProductTH = selectedItem.otherProductTH
                                // let temp = items.selectValues
                                // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                // let temp2 = items
                                // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                // temp2.picture = selectedItem.picture;
                                // temp2.articaleCode = selectedItem.articaleCode;
                                // temp2.price1Scale1 = selectedItem.price1Scale1;
                                // temp2.packSizeEN = selectedItem.packSizeEN;
                                // temp2.stock = selectedItem.stock;
                                // temp.map((val) => {
                                //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                // })

                                updateProductValue(true)
                              }}
                            >
                              {
                                items.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  // return (<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                            {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                          </div>}

                          {/* <div className="promo-desc">
                            <select
                              onChange={(e) => {
                                alert("WIP");
                              }}
                            >
                              {items.selectValues &&
                                items.selectValues.map((value) => {
                                  let selected = value.select ? "selected" : "";
                                  let result = [
                                    value.articaleCode,
                                    value.valueEN,
                                    value.valueTH,
                                    value.packSizeEN,
                                    value.productTypeEN,
                                    value.consumerNameEN,
                                  ];
                                  return (
                                    <option value={result} {...selected}>
                                      {lancode === "en"
                                        ? value.valueEN
                                        : value.valueTH}
                                    </option>
                                  );
                                })}
                            </select>
                          </div> */}

                          <div className="promo-btn">
                            <div className="promo-btn-lt">
                              {items && !items.wishlistStatus &&
                                <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice4550(true, items, index)}>
                                  <img src={WishListGrey} />
                                  {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                </button>
                              }
                              {items && items.wishlistStatus &&
                                <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice4550(false, items, index)}>
                                  <img src={ActiveWishlist} />
                                  {/* <img className="visible-xs" src={WishListWhite} /> */}
                                  {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                </button>
                              }
                            </div>
                            {(items.cartQuantity == 0 || items.cartQuantity == null) ?
                              <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                <div className="add-to-cart-container">
                                  <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice4550(items, index)}>
                                    <img alt="" src="../AddToCartWhite.svg" />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              </div> : null}
                            {items.cartQuantity > 0 ?
                              <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                <div className="promo-btn-addtocart-add-minus">
                                  <div className="row text-center">
                                    <div className="col-sm-5 col-5 d-flex justify-content-start">
                                      <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice4550(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                    <div className="col-sm-2 col-2 p-0 m-0 d-flex justify-content-center">
                                      <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                    </div>
                                    <div className="col-sm-5 col-5 d-flex justify-content-end">
                                      <img src={addProdouctButton} onClick={(e) => { updateQuantityslice4550(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div> : null}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* end second row of first item */}
                </Carousel.Item>
              )}

            </Carousel>
          </div>
        </div>
        {/* Add-to-cart-section start from here */}
        <div className="add-to-cart-modal-desktop-block">
          {addedToCardModal ? <AddToCartModal visible={addedToCardModal} product={productData} quantity={cartQuantity} priceofproductchosen={productPrice} close={addedToCartModalClose} /> : null}
        </div>
        <div className="common-display-block-mobile">
          {addedToCardModal ? <AddToCartModalMobile visible={addedToCardModal} product={productData} quantity={cartQuantity} priceofproductchosen={productPrice} close={addedToCartModalClose} /> : null}
        </div>
        {/* Add-to-cart-section end */}
        {/* <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" /> */}
      </div>
    </>
  );
};

export { PromotionCard as PromotionCard };

