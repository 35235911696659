export const GreaterThan = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_360_18780)">
                <rect x="10" y="6" width="48" height="48" rx="24" fill="#61A60E" shapeRendering="crispEdges" />
                <path d="M31.9141 22.375L39.0625 29.8359C39.2188 30.0312 39.3359 30.2656 39.3359 30.5C39.3359 30.7344 39.2188 30.9688 39.0625 31.125L31.9141 38.5859C31.5625 38.9766 30.9375 38.9766 30.5859 38.625C30.1953 38.2734 30.1953 37.6875 30.5469 37.2969L37.1094 30.4609L30.5469 23.6641C30.1953 23.3125 30.1953 22.6875 30.5859 22.3359C30.9375 21.9844 31.5625 21.9844 31.9141 22.375Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_360_18780" x="0" y="0" width="68" height="68" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_360_18780" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_360_18780" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}
export const SmallerThan = () => {
    return (
        <svg width="35" height="35" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#EDEFF2" />
            <path d="M25.5469 32.6641L18.3984 25.2031C18.2031 24.9688 18.125 24.7344 18.125 24.5C18.125 24.3047 18.2031 24.0703 18.3594 23.875L25.5078 16.4141C25.8594 16.0234 26.4844 16.0234 26.8359 16.375C27.2266 16.7266 27.2266 17.3125 26.875 17.7031L20.3516 24.5L26.9141 31.375C27.2656 31.7266 27.2656 32.3516 26.875 32.7031C26.5234 33.0547 25.8984 33.0547 25.5469 32.6641Z" fill="white" />
        </svg>
    )
}
export const Logo = () => {
    return (
        <svg width="280" height="40" viewBox="0 0 280 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7957 18.404C21.3288 18.8079 19.807 19.2197 18.1989 19.4177C23.384 16.0836 24.3724 12.2982 25.4157 8.30687C25.8079 6.81804 26.2079 5.28961 26.8512 3.77702C27.1179 9.97785 29.8712 12.7417 32.7736 15.656C33.8561 16.733 34.9622 17.8576 35.9427 19.1643C30.4909 16.2975 26.7492 17.327 22.8035 18.4119L22.7957 18.404ZM40.5473 10.0254C39.5824 9.04337 38.2725 8.44942 36.8997 8.33855C37.2762 7.54662 37.4723 6.67549 37.4723 5.81229C37.4723 5.32129 37.4096 4.83029 37.2841 4.35513C36.8919 2.84254 35.9349 1.58337 34.6013 0.79936C33.2678 0.0153476 31.7068 -0.198474 30.2164 0.205411C28.9142 0.553861 27.769 1.35371 26.9924 2.4545C26.9924 2.4545 26.9845 2.43866 26.9767 2.43074C26.2079 1.34579 25.0784 0.561781 23.7998 0.213331C22.3172 -0.206393 20.7562 -0.000490957 19.4148 0.775602C18.0734 1.5517 17.1086 2.81087 16.7085 4.31554C16.5752 4.80654 16.5124 5.30545 16.5124 5.81229C16.5124 6.68341 16.7164 7.54662 17.0851 8.34647C15.728 8.45734 14.4258 9.04337 13.4688 10.0095C12.3706 11.1103 11.7666 12.5754 11.7588 14.1355V14.1513C11.7588 15.7035 12.355 17.1686 13.4375 18.2694C14.3866 19.2276 15.6417 19.8374 16.9831 19.9641C16.9831 19.9641 16.9752 19.9799 16.9674 19.9879C16.4261 21.1995 16.3163 22.5775 16.6536 23.8683C17.0458 25.373 18.0028 26.6401 19.3364 27.4241C20.6699 28.2081 22.2309 28.4219 23.7213 28.026C25.0392 27.6775 26.2079 26.8302 26.9924 25.6819C27.769 26.8143 28.9221 27.6538 30.2242 28.0101C31.7146 28.4219 33.2756 28.216 34.617 27.4399C35.9584 26.6639 36.9154 25.4047 37.3233 23.9C37.4566 23.4011 37.5272 22.8863 37.5272 22.3716C37.5272 21.5401 37.3547 20.7164 37.0095 19.9562C37.0095 19.9562 37.0252 19.9562 37.0331 19.9562C38.343 19.8216 39.5824 19.2276 40.5238 18.2931C41.622 17.1924 42.226 15.7273 42.2338 14.1672V14.1513C42.2338 12.5991 41.6376 11.1341 40.5551 10.0333" fill="#74B529" />
            <path d="M3.97705 37.2677C3.93783 37.3786 3.85939 37.4895 3.74957 37.5845C3.6319 37.6795 3.48286 37.7666 3.28675 37.83C3.09065 37.8933 2.83963 37.925 2.5337 37.925H1.76496V35.8977H2.45526C2.79256 35.8977 3.06711 35.9294 3.27891 35.9927C3.48286 36.0561 3.63975 36.1353 3.75741 36.2382C3.86723 36.3332 3.94567 36.4362 3.98489 36.555C4.02412 36.6738 4.04765 36.8005 4.04765 36.9193C4.04765 37.0301 4.02412 37.1489 3.98489 37.2756L3.97705 37.2677ZM1.76496 32.4765H1.93754C2.14933 32.4765 2.34544 32.4924 2.5337 32.524C2.71412 32.5557 2.87101 32.6032 2.99651 32.6745C3.12202 32.7458 3.21615 32.8408 3.28675 32.9596C3.35735 33.0784 3.38873 33.2289 3.38873 33.4031C3.38873 33.704 3.28675 33.9416 3.0828 34.1158C2.87885 34.2901 2.49448 34.3851 1.95323 34.3851H1.75712V32.4765M5.42824 35.5809C5.19291 35.2483 4.86345 35.0186 4.47124 34.8682C4.67519 34.6781 4.84776 34.4643 4.96543 34.2425C5.11447 33.9654 5.18507 33.6248 5.18507 33.2289C5.18507 32.9675 5.138 32.7062 5.04387 32.4369C4.94974 32.1677 4.78501 31.9143 4.54184 31.6925C4.30651 31.4708 3.97705 31.2886 3.56915 31.154C3.16124 31.0115 2.63568 30.9402 2.00029 30.9402H0V39.4297H2.72981C3.19262 39.4297 3.61621 39.3743 4.00058 39.2634C4.38495 39.1525 4.71441 38.9941 4.98112 38.7882C5.25567 38.5823 5.46746 38.321 5.6165 38.0201C5.76554 37.7191 5.84399 37.3786 5.84399 37.0064C5.84399 36.4283 5.71064 35.9452 5.44393 35.5809" fill="#8A803C" />
            <path d="M12.4651 30.9403H7.10742V39.4298H12.6141L12.1121 37.9172H8.87238V35.9136H11.9473V34.401H8.87238V32.4687H11.9317L12.6063 30.9324H12.4651V30.9403Z" fill="#8A803C" />
            <path d="M21.1791 30.9402H13.9232L13.3662 32.4765H16.3784V39.4455H18.1512V32.4765H20.7477L21.3046 30.9402H21.1713H21.1791Z" fill="#8A803C" />
            <path d="M23.4233 35.5334L24.2862 33.6248L25.1569 35.5334H23.4233ZM24.6862 30.6868L24.6078 30.7184L23.6429 31.1303L19.9404 39.2476L21.4858 39.9999C21.4858 39.9999 22.8271 37.0618 22.8742 36.9589H25.6981C25.7452 37.0618 27.063 39.9761 27.063 39.9761L28.6476 39.2555L24.6941 30.6868" fill="#8A803C" />
            <path d="M31.5258 31.178C30.9689 31.3997 30.4825 31.7006 30.0746 32.0808C29.6667 32.4609 29.3451 32.9202 29.1176 33.4508C28.8901 33.9814 28.7725 34.5595 28.7725 35.1772C28.7725 35.7949 28.8901 36.3889 29.1255 36.9195C29.3608 37.4501 29.6824 37.9173 30.0981 38.3053C30.5139 38.6934 31.0081 39.0022 31.565 39.2161C32.1298 39.4299 32.7417 39.5408 33.4084 39.5408C33.8791 39.5408 34.3811 39.4695 34.9145 39.3428C35.4479 39.2082 35.997 39.0022 36.5618 38.7172L36.6167 38.6934V34.6704L34.8753 35.4702V37.6876C34.6714 37.7748 34.4674 37.846 34.2478 37.8856C34.0124 37.9331 33.7379 37.9569 33.432 37.9569C32.9456 37.9569 32.522 37.8777 32.169 37.7272C31.816 37.5768 31.518 37.3629 31.2905 37.1016C31.0551 36.8403 30.8826 36.5473 30.7649 36.2226C30.6551 35.89 30.5923 35.5494 30.5923 35.1931C30.5923 34.7892 30.6629 34.417 30.7963 34.0844C30.9296 33.7517 31.1179 33.4587 31.3532 33.2132C31.5886 32.9677 31.871 32.7777 32.1926 32.6351C32.5142 32.4926 32.875 32.4292 33.2515 32.4292C33.6281 32.4292 34.0124 32.4847 34.4282 32.5876C34.8439 32.6906 35.346 32.8965 35.9108 33.1974L36.0049 33.2449L36.5932 31.661L36.5148 31.6294C36.1539 31.471 35.8245 31.3363 35.5421 31.2334C35.2597 31.1304 34.9851 31.0513 34.742 30.9958C34.4988 30.9404 34.2556 30.9008 34.036 30.8849L33.3457 30.8533C32.6946 30.8533 32.0827 30.9641 31.5258 31.178Z" fill="#8A803C" />
            <path d="M41.7003 34.0843C41.4807 34.2506 41.1356 34.3377 40.6727 34.3377H40.0374V32.4767H40.6492C41.1356 32.4767 41.4886 32.5638 41.7003 32.738C41.9121 32.9043 42.0141 33.1181 42.0141 33.3874C42.0141 33.6883 41.9121 33.918 41.7003 34.0764V34.0843ZM42.5711 35.3831C43.3947 34.9713 43.8183 34.2744 43.8183 33.3003C43.8183 32.9993 43.7634 32.7063 43.6536 32.4212C43.5438 32.1361 43.3633 31.8748 43.128 31.661C42.8848 31.4392 42.5711 31.265 42.1867 31.1383C41.8023 31.0116 41.3317 30.9482 40.7826 30.9482H38.2881V39.4457H40.0374V35.8582H40.9395C40.9944 35.9374 43.6143 39.8733 43.6143 39.8733L45.0499 38.9626C45.0499 38.9626 42.6809 35.5573 42.5711 35.391" fill="#8A803C" />
            <path d="M51.9992 36.2937C51.858 36.6263 51.6698 36.9272 51.4187 37.1727C51.1756 37.4182 50.8853 37.6162 50.5559 37.7588C50.2264 37.9013 49.8656 37.9647 49.489 37.9647C49.1125 37.9647 48.7517 37.8934 48.4222 37.7588C48.0928 37.6162 47.8025 37.4262 47.5593 37.1727C47.3162 36.9272 47.1201 36.6263 46.9789 36.2937C46.8377 35.9611 46.7671 35.5889 46.7671 35.2008C46.7671 34.8128 46.8377 34.4406 46.9789 34.108C47.1201 33.7754 47.3162 33.4744 47.5593 33.2289C47.8025 32.9834 48.0928 32.7854 48.4222 32.6429C48.7517 32.5004 49.1125 32.437 49.489 32.437C49.8656 32.437 50.2264 32.5083 50.5559 32.6429C50.8853 32.7854 51.1756 32.9755 51.4187 33.2289C51.6619 33.4744 51.858 33.7754 51.9992 34.108C52.1404 34.4406 52.2032 34.8128 52.2032 35.2008C52.2032 35.5889 52.1326 35.9611 51.9992 36.2937ZM53.6465 33.4903C53.4112 32.9597 53.0896 32.5004 52.6817 32.1123C52.2738 31.7243 51.7953 31.4154 51.2462 31.1857C50.6971 30.9561 50.1009 30.8452 49.4734 30.8452C48.8458 30.8452 48.2496 30.9561 47.7005 31.1857C47.1514 31.4154 46.6729 31.7243 46.2729 32.1123C45.8728 32.5004 45.5512 32.9676 45.3159 33.4903C45.0806 34.0209 44.9629 34.5911 44.9629 35.1929C44.9629 35.7948 45.0806 36.3808 45.3159 36.9035C45.5512 37.4262 45.8728 37.8934 46.2729 38.2815C46.6729 38.6774 47.1593 38.9863 47.7005 39.208C48.2496 39.4298 48.8458 39.5406 49.4734 39.5406C50.1009 39.5406 50.6971 39.4298 51.2462 39.208C51.7953 38.9863 52.2738 38.6774 52.6817 38.2815C53.0896 37.8855 53.4112 37.4262 53.6465 36.9035C53.8818 36.3808 53.9995 35.8027 53.9995 35.1929C53.9995 34.5831 53.8818 34.0209 53.6465 33.4903Z" fill="#8A803C" />
            <path d="M68 4L68 36" stroke="#989CA6" strokeWidth="0.850575" />
            <g clipPath="url(#clip0_5175_31355)">
                <path d="M131.102 26.4845L129.888 29.1596H125.87L134.327 10.415L142.808 29.1569H138.777L137.574 26.4711L137.515 26.3429H131.158L131.099 26.4818L131.102 26.4845ZM135.809 22.6014L134.338 19.3223L132.855 22.6014H135.809Z" fill="#61A60E" />
                <path d="M99.6561 22.9569C99.6561 29.4563 94.4058 29.1569 92.5904 29.1569H86V10.415H92.2815C97.2364 10.415 97.6017 13.6808 97.6017 15.0998C97.6017 16.8263 96.7476 17.8338 95.9473 18.3923C98.7269 19.0711 99.6534 21.6046 99.6534 22.9542L99.6561 22.9569ZM92.5958 16.7568C93.2269 16.7568 93.8419 16.4067 93.8419 15.458C93.8419 14.5092 93.2269 14.1591 92.5958 14.1591H89.7732V16.7568H92.5958ZM89.7732 20.5116V25.4022H92.5904C93.3692 25.4022 95.4183 25.4022 95.4183 22.9569C95.4183 20.5116 93.3692 20.5116 92.5904 20.5116H89.7732Z" fill="#61A60E" />
                <path d="M112.651 10.415L111.193 14.1591H105.126V17.9139H110.769L109.311 21.658H105.124V25.4022H112.649L111.191 29.1569H101.351V10.415H112.649H112.651Z" fill="#61A60E" />
                <path d="M193.326 13.1703C195.171 15.0063 196.095 17.2138 196.095 19.7847C196.095 22.3556 195.171 24.5737 193.326 26.4043C191.481 28.2376 189.265 29.1543 186.679 29.1543C184.093 29.1543 181.867 28.2376 180.027 26.4043C178.185 24.571 177.264 22.3636 177.264 19.7847C177.264 17.2057 178.185 15.0063 180.027 13.1703C181.869 11.3344 184.088 10.415 186.679 10.415C189.271 10.415 191.481 11.3344 193.326 13.1703ZM182.689 15.8161C181.587 16.9198 181.034 18.2453 181.034 19.7873C181.034 21.3293 181.585 22.6602 182.689 23.7586C183.79 24.8543 185.119 25.4048 186.679 25.4048C188.24 25.4048 189.558 24.857 190.67 23.7586C191.779 22.6629 192.335 21.3374 192.335 19.7873C192.335 18.2373 191.779 16.9225 190.67 15.8161C189.561 14.7123 188.232 14.1591 186.679 14.1591C185.127 14.1591 183.792 14.7123 182.689 15.8161Z" fill="#61A60E" />
                <path d="M162.721 10.415H169.311C171.127 10.415 172.679 11.0591 173.973 12.3445C175.268 13.63 175.912 15.1747 175.912 16.9706C175.912 18.3656 175.512 19.619 174.714 20.7307C173.917 21.8424 172.896 22.6308 171.658 23.0958L175.91 29.1543H171.306L166.494 22.2941V29.1543H162.721V10.415ZM169.311 19.7847C170.09 19.7847 170.756 19.5094 171.309 18.9589C171.862 18.4083 172.139 17.7456 172.139 16.9706C172.139 16.1956 171.862 15.5435 171.309 14.9876C170.756 14.4344 170.09 14.1565 169.311 14.1565H166.494V19.7847H169.311Z" fill="#61A60E" />
                <path d="M127.978 10.415V14.1591H123.27V29.1569H119.497V14.1591H114.789V10.415H127.981H127.978Z" fill="#61A60E" />
                <path d="M151.59 17.8685V21.4977H156.359C156.314 24.2637 152.967 25.4022 151.418 25.4022C149.868 25.4022 148.531 24.8543 147.427 23.7559C146.326 22.6602 145.773 21.3347 145.773 19.7847C145.773 18.2346 146.323 16.9198 147.427 15.8134C148.528 14.7097 149.857 14.1565 151.418 14.1565C152.978 14.1565 154.297 14.7097 155.409 15.8134C155.629 16.0325 155.819 16.2624 155.997 16.5002H160.251C159.791 15.2816 159.072 14.1698 158.067 13.1703C156.222 11.3344 154.007 10.415 151.42 10.415C148.834 10.415 146.608 11.3344 144.768 13.1703C142.926 15.0063 142.005 17.2138 142.005 19.7847C142.005 22.3556 142.926 24.5737 144.768 26.4043C146.611 28.2376 148.829 29.1543 151.42 29.1543C154.012 29.1543 156.639 28.39 158.269 26.4471C159.19 25.3487 161.432 23.0985 160.686 17.8658H151.59V17.8685Z" fill="#61A60E" />
                <path d="M237.227 29.2022H231.85V21.1715H225.531V29.2022H220.225V10.3882H225.531V17.6038H231.85V10.3882H237.227V29.2022Z" fill="#F5B335" />
                <path d="M280 16.5482C280 18.5552 279.261 20.0838 277.784 21.1314C276.401 22.1283 274.497 22.6253 272.077 22.6253H270.002V29.2022H264.625V10.3882H271.062C277.022 10.3882 280 12.4406 280 16.5455V16.5482ZM274.669 16.468C274.669 14.7817 273.576 13.9372 271.39 13.9372H269.999V19.079H271.39C272.397 19.079 273.165 18.8999 273.702 18.5418C274.347 18.1169 274.669 17.4248 274.669 16.468Z" fill="#F5B335" />
                <path d="M242.864 11.7752C238.416 16.2008 238.416 23.3764 242.864 27.7993C247.311 32.2248 254.522 32.2248 258.966 27.7993C263.414 23.3737 263.414 16.1981 258.966 11.7752C254.519 7.34966 247.308 7.34966 242.864 11.7752ZM254.267 23.1225C252.416 24.9638 249.417 24.9638 247.566 23.1225C245.716 21.2812 245.716 18.296 247.566 16.4547C249.417 14.6134 252.416 14.6134 254.267 16.4547C256.117 18.296 256.117 21.2812 254.267 23.1225ZM253.539 12.8656C250.891 11.8768 247.786 12.4407 245.662 14.5573C242.764 17.4408 242.764 22.1337 245.662 25.0172C245.92 25.2738 245.92 25.6907 245.662 25.9473C245.404 26.2038 244.985 26.2038 244.728 25.9473C241.314 22.5506 241.314 17.0239 244.728 13.6273C247.308 11.059 251.117 10.4337 254.294 11.7512C254.286 11.7592 254.277 11.7672 254.267 11.7752C253.942 12.0986 253.7 12.4701 253.539 12.8656Z" fill="#F5B335" />
                <path d="M258.969 11.7752C257.672 10.4844 255.566 10.4844 254.266 11.7752C252.966 13.066 252.969 15.1612 254.266 16.4547C254.266 16.4547 254.272 16.4574 254.272 16.46C256.117 18.3014 256.114 21.2838 254.266 23.1225C252.504 24.8756 249.701 24.9611 247.84 23.3737C247.746 23.2935 247.654 23.2106 247.566 23.1225C247.566 23.1225 247.735 23.3069 247.821 23.4111C247.995 23.6249 248.208 23.9643 248.358 24.3865C248.68 25.2925 248.71 26.6608 247.566 27.7992C246.269 29.09 244.163 29.09 242.863 27.7992C247.311 32.2248 254.521 32.2248 258.966 27.7992C263.413 23.3737 263.413 16.1981 258.966 11.7752H258.969Z" fill="#61A60E" />
                <path d="M215.501 19.0791C214.18 18.2693 212.456 17.7134 210.903 17.187C210.041 16.8957 208.631 16.4627 208.631 15.343C208.631 14.4637 209.596 13.7796 212.013 13.7796C214.62 13.7796 216.554 14.5252 216.871 14.6562V10.7758C216.524 10.6769 214.236 10.0542 211.682 10.0542C209.128 10.0542 207.208 10.4871 205.669 11.3503C203.846 12.3605 202.933 13.8624 202.933 15.8534C202.933 17.6199 203.596 18.7771 205.172 19.9102C206.22 20.6638 208.135 21.3961 209.961 22.0108C211.787 22.6254 212.329 23.2722 212.413 23.983C212.485 24.603 212.074 25.2017 211.398 25.4983C208.862 26.6074 202.933 24.1594 202.933 24.1594V28.6304C203.258 28.7427 205.669 29.523 209.109 29.523C215.004 29.523 217.953 27.4572 217.953 23.3256C217.953 21.5591 217.279 20.1694 215.501 19.0817V19.0791Z" fill="#F5B335" />
            </g>
            <defs>
                <clipPath id="clip0_5175_31355">
                    <rect width="194" height="22.6624" fill="white" transform="translate(86 8.45605)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const AddToCart = () => {
    return (
        <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.75 0.5C4.17969 0.5 4.57031 0.851562 4.64844 1.28125L4.72656 1.75H21.1328C21.9531 1.75 22.5781 2.57031 22.3438 3.35156L20.2344 10.8516C20.0781 11.3984 19.6094 11.75 19.0234 11.75H6.64062L6.99219 13.625H19.0625C19.5703 13.625 20 14.0547 20 14.5625C20 15.1094 19.5703 15.5 19.0625 15.5H6.21094C5.78125 15.5 5.39062 15.1875 5.3125 14.7578L2.96875 2.375H0.9375C0.390625 2.375 0 1.98438 0 1.4375C0 0.929688 0.390625 0.5 0.9375 0.5H3.75ZM18.5547 9.875L20.3125 3.625H5.11719L6.28906 9.875H18.5547ZM5 18.625C5 17.6094 5.82031 16.75 6.875 16.75C7.89062 16.75 8.75 17.6094 8.75 18.625C8.75 19.6797 7.89062 20.5 6.875 20.5C5.82031 20.5 5 19.6797 5 18.625ZM20 18.625C20 19.6797 19.1406 20.5 18.125 20.5C17.0703 20.5 16.25 19.6797 16.25 18.625C16.25 17.6094 17.0703 16.75 18.125 16.75C19.1406 16.75 20 17.6094 20 18.625Z" fill="#464B55" />
        </svg>
    )
}

export const WishList = () => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.53125 2.78125L9.96094 3.25L10.4297 2.82031C11.7188 1.53125 13.5547 0.945312 15.3125 1.25781C18.0078 1.6875 20 4.03125 20 6.76562V6.96094C20 8.60156 19.2969 10.1641 18.125 11.2578L11.0547 17.8594C10.7812 18.1328 10.3906 18.25 10 18.25C9.57031 18.25 9.17969 18.1328 8.90625 17.8594L1.83594 11.2578C0.664062 10.1641 0 8.60156 0 6.96094V6.76562C0 4.03125 1.95312 1.6875 4.64844 1.25781C6.40625 0.945312 8.24219 1.53125 9.53125 2.78125C9.53125 2.82031 9.49219 2.78125 9.53125 2.78125ZM9.96094 5.90625L8.20312 4.10938C7.34375 3.28906 6.13281 2.89844 4.96094 3.09375C3.16406 3.40625 1.875 4.92969 1.875 6.76562V6.96094C1.875 8.09375 2.30469 9.14844 3.125 9.89062L10 16.2969L16.8359 9.89062C17.6562 9.14844 18.125 8.09375 18.125 6.96094V6.76562C18.125 4.92969 16.7969 3.40625 15 3.09375C13.8281 2.89844 12.6172 3.28906 11.7578 4.10938L9.96094 5.90625Z" fill="#464B55" />
        </svg>

    )
}

export const VerticleLine = () => {
    return (
        <svg width="2" height="25" viewBox="0 0 2 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0V32" stroke="#989CA6" />
        </svg>

    )
}

export const UserIcon = () => {
    return (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.625 12.375H6.875C3.04688 12.375 0 15.4609 0 19.25C0 19.9531 0.546875 20.5 1.25 20.5H16.25C16.9141 20.5 17.5 19.9531 17.5 19.25C17.5 15.4609 14.4141 12.375 10.625 12.375ZM1.875 18.625C2.1875 16.1641 4.29688 14.25 6.875 14.25H10.625C13.1641 14.25 15.2734 16.1641 15.5859 18.625H1.875ZM8.75 10.5C11.4844 10.5 13.75 8.27344 13.75 5.5C13.75 2.76562 11.4844 0.5 8.75 0.5C5.97656 0.5 3.75 2.76562 3.75 5.5C3.75 8.27344 5.97656 10.5 8.75 10.5ZM8.75 2.375C10.4688 2.375 11.875 3.78125 11.875 5.5C11.875 7.25781 10.4688 8.625 8.75 8.625C6.99219 8.625 5.625 7.25781 5.625 5.5C5.625 3.78125 6.99219 2.375 8.75 2.375Z" fill="#464B55" />
        </svg>

    )
}

export const Dashboard = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1.5C0 0.6875 0.65625 0 1.5 0H4.5C5.3125 0 6 0.6875 6 1.5V4.5C6 5.34375 5.3125 6 4.5 6H1.5C0.65625 6 0 5.34375 0 4.5V1.5ZM1.5 4.5H4.5V1.5H1.5V4.5ZM0 9.5C0 8.6875 0.65625 8 1.5 8H4.5C5.3125 8 6 8.6875 6 9.5V12.5C6 13.3438 5.3125 14 4.5 14H1.5C0.65625 14 0 13.3438 0 12.5V9.5ZM1.5 12.5H4.5V9.5H1.5V12.5ZM12.5 0C13.3125 0 14 0.6875 14 1.5V4.5C14 5.34375 13.3125 6 12.5 6H9.5C8.65625 6 8 5.34375 8 4.5V1.5C8 0.6875 8.65625 0 9.5 0H12.5ZM12.5 1.5H9.5V4.5H12.5V1.5ZM8 9.5C8 8.6875 8.65625 8 9.5 8H12.5C13.3125 8 14 8.6875 14 9.5V12.5C14 13.3438 13.3125 14 12.5 14H9.5C8.65625 14 8 13.3438 8 12.5V9.5ZM9.5 12.5H12.5V9.5H9.5V12.5Z" fill="#989CA6" />
        </svg>

    )
}

export const Orders = () => {
    return (
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 7H3.75C3.3125 7 3 7.34375 3 7.75C3 8.1875 3.3125 8.5 3.75 8.5H8.25C8.65625 8.5 9 8.1875 9 7.75C9 7.34375 8.65625 7 8.25 7ZM5.25 10H3.75C3.3125 10 3 10.3438 3 10.75C3 11.1875 3.3125 11.5 3.75 11.5H5.25C5.65625 11.5 6 11.1875 6 10.75C6 10.3438 5.65625 10 5.25 10ZM8.25 4H3.75C3.3125 4 3 4.34375 3 4.75C3 5.1875 3.3125 5.5 3.75 5.5H8.25C8.65625 5.5 9 5.1875 9 4.75C9 4.34375 8.65625 4 8.25 4ZM10 0H2C0.875 0 0 0.90625 0 2V14C0 15.125 0.875 16 2 16H10C11.0938 16 12 15.125 12 14V2C12 0.90625 11.0938 0 10 0ZM10.5 14C10.5 14.2812 10.25 14.5 10 14.5H2C1.71875 14.5 1.5 14.2812 1.5 14V2C1.5 1.75 1.71875 1.5 2 1.5H10C10.25 1.5 10.5 1.75 10.5 2V14Z" fill="#989CA6" />
        </svg>

    )
}

export const Password = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 4.5C10.5 3.96875 10.9375 3.5 11.5 3.5C12.0312 3.5 12.5 3.96875 12.5 4.5C12.5 5.0625 12.0312 5.5 11.5 5.5C10.9375 5.5 10.5 5.0625 10.5 4.5ZM10.5 11C10.1875 11 9.90625 11 9.625 10.9375L8.75 11.7812C8.625 11.9375 8.4375 12 8.25 12H7V13.25C7 13.6875 6.65625 14 6.25 14H5V15.25C5 15.6875 4.65625 16 4.25 16H0.75C0.3125 16 0 15.6875 0 15.25V11.75C0 11.5625 0.0625 11.375 0.21875 11.2188L5.0625 6.375C5 6.09375 5 5.8125 5 5.5C5 2.46875 7.4375 0 10.5 0C13.5312 0 16 2.46875 16 5.5C16 8.5625 13.5312 11 10.5 11ZM10.5 9.5C12.6875 9.5 14.5 7.71875 14.5 5.5C14.5 3.3125 12.6875 1.5 10.5 1.5C8.28125 1.5 6.5 3.3125 6.5 5.5C6.5 5.71875 6.5 5.9375 6.53125 6.15625L6.65625 6.90625L1.46875 12.0625V14.5H3.46875V12.5H5.46875V10.5H7.9375L9.09375 9.34375L9.84375 9.46875C10.0625 9.5 10.2812 9.5 10.5 9.5Z" fill="#989CA6" />
        </svg>

    )
}

export const Logout = () => {
    return (
        <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 13.25C6 13.6875 5.65625 14 5.25 14H3C1.3125 14 0 12.6875 0 11V3C0 1.34375 1.3125 0 3 0H5.25C5.65625 0 6 0.34375 6 0.75C6 1.1875 5.65625 1.5 5.25 1.5H3C2.15625 1.5 1.5 2.1875 1.5 3V11C1.5 11.8438 2.15625 12.5 3 12.5H5.25C5.65625 12.5 6 12.8438 6 13.25ZM15.7812 6.5L11.8125 2.25C11.5312 1.9375 11.0625 1.9375 10.75 2.21875C10.4375 2.5 10.4375 2.96875 10.7188 3.28125L13.5 6.25H5.71875C5.3125 6.25 5 6.59375 5 7C5 7.4375 5.3125 7.75 5.71875 7.75H13.4688L10.6562 10.75C10.375 11.0625 10.375 11.5312 10.6875 11.8125C10.875 11.9375 11.0625 12 11.2188 12C11.4062 12 11.5938 11.9375 11.75 11.7812L15.7188 7.53125C16.0625 7.25 16.0625 6.78125 15.7812 6.5Z" fill="#989CA6" />
        </svg>

    )
}

export const StoreLocationGreen = () => {
    return (<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.0625 20.0312C5.03125 17.4922 0.5 11.4375 0.5 8C0.5 3.85938 3.82031 0.5 8 0.5C12.1406 0.5 15.5 3.85938 15.5 8C15.5 11.4375 10.9297 17.4922 8.89844 20.0312C8.42969 20.6172 7.53125 20.6172 7.0625 20.0312ZM8 10.5C9.36719 10.5 10.5 9.40625 10.5 8C10.5 6.63281 9.36719 5.5 8 5.5C6.59375 5.5 5.5 6.63281 5.5 8C5.5 9.40625 6.59375 10.5 8 10.5Z" fill="#61A60E" />
    </svg>
    )
}

export const Web = () => {
    return (<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0.75C10.8555 0.75 14 3.89453 14 7.75C14 11.6328 10.8555 14.75 7 14.75C3.11719 14.75 0 11.6328 0 7.75C0 3.89453 3.11719 0.75 7 0.75ZM7 13.4375C7.19141 13.4375 7.71094 13.2461 8.28516 12.125C8.53125 11.6328 8.72266 11.0586 8.88672 10.375H5.08594C5.25 11.0586 5.44141 11.6328 5.6875 12.125C6.26172 13.2461 6.78125 13.4375 7 13.4375ZM4.86719 9.0625H9.10547C9.16016 8.65234 9.1875 8.21484 9.1875 7.75C9.1875 7.3125 9.16016 6.875 9.10547 6.4375H4.86719C4.8125 6.875 4.8125 7.3125 4.8125 7.75C4.8125 8.21484 4.8125 8.65234 4.86719 9.0625ZM8.88672 5.125C8.72266 4.46875 8.53125 3.89453 8.28516 3.40234C7.71094 2.28125 7.19141 2.0625 7 2.0625C6.78125 2.0625 6.26172 2.28125 5.6875 3.40234C5.44141 3.89453 5.25 4.46875 5.08594 5.125H8.88672ZM10.418 6.4375C10.4727 6.875 10.5 7.3125 10.5 7.75C10.5 8.21484 10.4727 8.65234 10.418 9.0625H12.5234C12.6328 8.65234 12.6875 8.21484 12.6875 7.75C12.6875 7.3125 12.6328 6.875 12.5234 6.4375H10.418ZM9.35156 2.58203C9.73438 3.29297 10.0352 4.16797 10.2266 5.125H12.0312C11.457 4.00391 10.5 3.10156 9.35156 2.58203ZM4.62109 2.58203C3.47266 3.10156 2.51562 4.00391 1.94141 5.125H3.74609C3.9375 4.16797 4.23828 3.29297 4.62109 2.58203ZM1.3125 7.75C1.3125 8.21484 1.33984 8.65234 1.44922 9.0625H3.55469C3.5 8.65234 3.5 8.21484 3.5 7.75C3.5 7.3125 3.5 6.875 3.55469 6.4375H1.44922C1.33984 6.875 1.3125 7.3125 1.3125 7.75ZM12.0312 10.375H10.2266C10.0352 11.3594 9.73438 12.2344 9.35156 12.9453C10.5 12.4258 11.457 11.5234 12.0312 10.375ZM3.74609 10.375H1.94141C2.51562 11.5234 3.47266 12.4258 4.62109 12.9453C4.23828 12.2344 3.9375 11.3594 3.74609 10.375Z" fill="#464B55" />
    </svg>
    )
}

export const UserGrey = () => {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 9.5H5.5C2.4375 9.5 0 11.9688 0 15C0 15.5625 0.4375 16 1 16H13C13.5312 16 14 15.5625 14 15C14 11.9688 11.5312 9.5 8.5 9.5ZM1.5 14.5C1.75 12.5312 3.4375 11 5.5 11H8.5C10.5312 11 12.2188 12.5312 12.4688 14.5H1.5ZM7 8C9.1875 8 11 6.21875 11 4C11 1.8125 9.1875 0 7 0C4.78125 0 3 1.8125 3 4C3 6.21875 4.78125 8 7 8ZM7 1.5C8.375 1.5 9.5 2.625 9.5 4C9.5 5.40625 8.375 6.5 7 6.5C5.59375 6.5 4.5 5.40625 4.5 4C4.5 2.625 5.59375 1.5 7 1.5Z" fill="#989CA6" />
        </svg>

    )
}
