import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./Style.css";

const OffspecMob = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const languageText = localStorage.getItem("lancode");
  const menuDetails = useSelector((state) => state.landing.menuDetails);

  const getMenus = (cat) => {
    const menuMain =
      menuDetails &&
      menuDetails.data &&
      menuDetails.data.filter((item) => item.categoryEN == cat);
    const subCategory =
      menuMain && menuMain.length > 0 && menuMain[0].subCategory;
    return subCategory;
  };
  return (
    <>
    <div className="bg-HeaderMobPages">
    <button onClick={()=>history.goBack()}>
    {t("Back.label")}
        </button>
        <h4><Link to="/off-spec">{t("offspec.label")}</Link></h4>
                            <ul>
                            {getMenus("off spec") &&
                              getMenus("off spec").length > 0 &&
                              getMenus("off spec").map((item) => {
                                return (
                                  <li>
                                    <Link to={"/off-spec/" + item.subCategorySlug}>
                                      {languageText === "en"
                                        ? item.subCategoryEN
                                        : item.subCategoryTH}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
    </div>
    </>
  )
}

export {OffspecMob as OffspecMob}