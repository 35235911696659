export const uploadfileConstants = {
    GET_UPLOAD_FILE_REQUEST_INFORMATION: 'GET_UPLOAD_FILE_REQUEST_INFORMATION',
    GET_UPLOAD_FILE_SUCCESS_INFORMATION: 'GET_UPLOAD_FILE_SUCCESS_INFORMATION',
    GET_UPLOAD_FILE_NODATA_FOUND_INFORMATION: 'GET_UPLOAD_FILE_NODATA_FOUND_INFORMATION',
    GET_UPLOAD_FILE_FAILURE_INFORMATION: 'GET_UPLOAD_FILE_FAILURE_INFORMATION',


    GET_UPLOAD_FILE_PRICE_REQUEST_INFORMATION: 'GET_UPLOAD_FILE_PRICE_REQUEST_INFORMATION',
    GET_UPLOAD_FILE_PRICE_SUCCESS_INFORMATION: 'GET_UPLOAD_FILE_PRICE_SUCCESS_INFORMATION',
    GET_UPLOAD_FILE_PRICE_NODATA_FOUND_INFORMATION: 'GET_UPLOAD_FILE_PRICE_NODATA_FOUND_INFORMATION',
    GET_UPLOAD_FILE_PRICE_FAILURE_INFORMATION: 'GET_UPLOAD_FILE_PRICE_FAILURE_INFORMATION',


    GET_UPLOAD_FILE_SPECIAL_PRICE_REQUEST_INFORMATION: 'GET_UPLOAD_FILE_SPECIAL_PRICE_REQUEST_INFORMATION',
    GET_UPLOAD_FILE_SPECIAL_PRICE_SUCCESS_INFORMATION: 'GET_UPLOAD_FILE_SPECIAL_PRICE_SUCCESS_INFORMATION',
    GET_UPLOAD_FILE_SPECIAL_PRICE_NODATA_FOUND_INFORMATION: 'GET_UPLOAD_FILE_SPECIAL_PRICE_NODATA_FOUND_INFORMATION',
    GET_UPLOAD_FILE_SPECIAL_PRICE_FAILURE_INFORMATION: 'GET_UPLOAD_FILE_SPECIAL_PRICE_FAILURE_INFORMATION',


    GET_UPLOAD_FILE_STOCK_REQUEST_INFORMATION: 'GET_UPLOAD_FILE_STOCK_REQUEST_INFORMATION',
    GET_UPLOAD_FILE_STOCK_SUCCESS_INFORMATION: 'GET_UPLOAD_FILE_STOCK_SUCCESS_INFORMATION',
    GET_UPLOAD_FILE_STOCK_NODATA_FOUND_INFORMATION: 'GET_UPLOAD_FILE_STOCK_NODATA_FOUND_INFORMATION',
    GET_UPLOAD_FILE_STOCK_FAILURE_INFORMATION: 'GET_UPLOAD_FILE_STOCK_FAILURE_INFORMATION',


    GET_UPLOAD_FILE_SALE_REQUEST_INFORMATION: 'GET_UPLOAD_FILE_SALE_REQUEST_INFORMATION',
    GET_UPLOAD_FILE_SALE_SUCCESS_INFORMATION: 'GET_UPLOAD_FILE_SALE_SUCCESS_INFORMATION',
    GET_UPLOAD_FILE_SALE_NODATA_FOUND_INFORMATION: 'GET_UPLOAD_FILE_SALE_NODATA_FOUND_INFORMATION',
    GET_UPLOAD_FILE_SALE_FAILURE_INFORMATION: 'GET_UPLOAD_FILE_SALE_FAILURE_INFORMATION',


    GET_UPLOAD_FILE_BANNER_REQUEST_INFORMATION: 'GET_UPLOAD_FILE_DESKTOP_BANNER_REQUEST_INFORMATION',
    GET_UPLOAD_FILE_BANNER_SUCCESS_INFORMATION: 'GET_UPLOAD_FILE_DESKTOP_BANNER_SUCCESS_INFORMATION',
    GET_UPLOAD_FILE_BANNER_NODATA_FOUND_INFORMATION: 'GET_UPLOAD_FILE_DESKTOP_BANNER_NODATA_FOUND_INFORMATION',
    GET_UPLOAD_FILE_BANNER_FAILURE_INFORMATION: 'GET_UPLOAD_FILE_DESKTOP_BANNER_FAILURE_INFORMATION',

    GET_UPLOAD_FILE_MOBILE_REQUEST_INFORMATION: 'GET_UPLOAD_FILE_MOBILE_BANNER_REQUEST_INFORMATION',
    GET_UPLOAD_FILE_MOBILE_SUCCESS_INFORMATION: 'GET_UPLOAD_FILE_MOBILE_BANNER_SUCCESS_INFORMATION',
    GET_UPLOAD_FILE_MOBILE_NODATA_FOUND_INFORMATION: 'GET_UPLOAD_FILE_MOBILE_BANNER_NODATA_FOUND_INFORMATION',
    GET_UPLOAD_FILE_MOBILE_FAILURE_INFORMATION: 'GET_UPLOAD_FILE_MOBILE_BANNER_FAILURE_INFORMATION',

};
