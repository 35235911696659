import { authHeader } from "../_helpers";
import { Axios } from "./axiosProvider";
import { REACT_APP_BASE_URL } from "../constant"
export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  firtTimeLogin,
  otpVerification,
  setPassword,
  getAllcustomerlist,
  getcustomerdetailsbyid,
  getcustomeraddressdetails,
  changePassword,
  updateUser,
  forgotPassword
};



function changePassword(payload,queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/changePassword" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function updateUser(payload,queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/updateUser" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}



function firtTimeLogin(payload) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      // "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/firstTimeLogin",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function forgotPassword(payload) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      // "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/forgotPassword",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function setPassword(payload) {
  const token = localStorage.getItem("x-auth-token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(payload),
  };

  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/setPassword",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function login(payload) {
  
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    body: JSON.stringify(payload),
  };

  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/login",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function logout(token) {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  sessionStorage.removeItem("user"); 

  // const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "token": `Bearer ${token}`
    },
  };
  let payload={
    token:`Bearer ${token}`
  }
  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/logout",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getAll(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.get(
    REACT_APP_BASE_URL + "/api/users",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}



function getcustomerdetailsbyid(payload) {
  let payload1={};
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.get(
    REACT_APP_BASE_URL + "/api/users/getDetails/" + payload.selecteduserincart,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}



function getcustomeraddressdetails(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/users/address",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getAllcustomerlist(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.get(
    REACT_APP_BASE_URL + "/api/users/customersList",
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);

}



function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function otpVerification(payload) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      // "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + '/api/users/verifyOTP',
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}