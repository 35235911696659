import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { isMobile } from 'react-device-detect';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import MyAccountHamburgerMenu from "../MyOrder/MyAccountHamburgerMenu/MyAccountHamburgerMenu";
import Loader from "../SharedComponent/Loader/Loader";
import ApiList from "../_constants/const.api";
import ApiProvider from "../_services/ApiProvider";
import arrowIcon from "../images/chevron-down.svg";
import Add from "./Assets/SVG/Add.svg";
import AddGreen from "./Assets/SVG/AddGreen.svg";
import Address from "./Assets/SVG/Address.svg";
import Check from "./Assets/SVG/Check.svg";
import Delete from "./Assets/SVG/Delete.svg";
import Edit from "./Assets/SVG/Edit.svg";
import Location from "./Assets/SVG/Location.svg";
import Profile from "./Assets/SVG/Profile.svg";
import "./MyAccountProfile.css";

const MyAccountProfile = () => {
  const [visible, setVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState("my-profile");
  const lancode = localStorage.getItem("lancode");
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };


  window.addEventListener("scroll", toggleVisible);
  let history = useHistory();
  const { t } = useTranslation();
  // const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const isNull = (value) => {
    return value === "" || value === undefined || value === null;
  };
  const [userInfo, setUserInfo] = useState([]);
  const [userEmail, setUserEmail] = useState();
  // const [userEmailPrev, setUserEmailPrev] = useState();
  const [userEmailError, setUserEmailError] = useState(false);
  const [userEmailErrorMessage, setUserEmailErrorMessage] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  const [showAddressWindow, setShowAddressWindow] = useState(false);
  const [provinceDdOptions, setProvinceDdOptions] = useState([]);
  const [districtDdOptions, setDistrictDdOptions] = useState([]);
  const [subDistrictDdOptions, setSubDistrictDdOptions] = useState([]);
  const [userAddressID, setUserAddressID] = useState("");
  const [userCustomerCode, setUserCustomerCode] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [row, setRow] = useState()
  const [noteCount, setNoteCount] = useState(0); // variable use for storing note message count
  // const [loading, setLoading] = React.useState(true);
  const [newCustomerAddress, setNewCustomerAddress] = useState([
    {
      label: "Address",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Province",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "District",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Sub-District",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Postal Code",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Notes",
      value: "",
      error: false,
      mandatory: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Default Shipping Address",
      value: false,
      error: false,
      disabled: false,
      mandatory: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Default Invoice Address",
      value: false,
      error: false,
      disabled: false,
      mandatory: false,
      errorMessage: "Please fill the required field!",
    },
  ]);
  const location = useLocation()
  React.useEffect(() => {
    // setLoading(true);
    if (!userData) {
      localStorage.setItem('prevPath', location.pathname)
      history.push("/login");
    } else {
      // if (userData && userData.user.role !== "Saleshop") {
      //   history.push("/");
      // }
    }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      // let temp = newCustomerAddress
      // temp[0].value=userData.user.
      //dispatch(userActions.changePassword("", payload,queryString));
      //dispatch(userActions.updateUser("", payload,queryString));
      // setUserEmail(userData?.user?.email)
    }
    getProvinceData("province", null);

    getUserDetails();
  }, []);
  const isNullorZero = (value) => {
    return value == "" || value == undefined || value == null || value == false || value == 0;
  };
  const getUserDetails = () => {
    // /api/users/getDetails/1
    let payload = {};
    ApiProvider.get(
      ApiList.API_URL_FOR_GET_USER_DETAILS + "/" + userData?.user?.userId,
      payload,
      false
    ).then((res) => {
      setUserEmail(res.data.data.email);
      setUserInfo(res.data.data);
    });
  };


  const handleEdit = () => {
    setEditStatus(!editStatus);
  };
  const handleOnchangeDd = (selectedItem, type) => {
    let temp = newCustomerAddress;
    if (type == "province") {
      temp[1].value = selectedItem;
      temp[1].error = false;
      temp[2].value = "";
      temp[3].value = "";
      temp[4].value = "";
      setNewCustomerAddress({ ...temp });
      getProvinceData("district", selectedItem);
    } else if (type == "district") {
      temp[2].value = selectedItem;
      temp[2].error = false;
      temp[3].value = "";
      temp[4].value = "";
      setNewCustomerAddress({ ...temp });
      getProvinceData("subdistrict", selectedItem);
    } else if (type == "subdistrict") {
      temp[3].value = selectedItem;
      temp[3].error = false;
      temp[4].value = "";
      setNewCustomerAddress({ ...temp });
      getProvinceData("postalCode", selectedItem);
    }
  };
  const getProvinceData = (type, selectedItem) => {
    let payload = {};
    if (type == "district") {
      payload = {
        provinceTH: selectedItem.provinceTH,
      };
    }
    if (type == "subdistrict") {
      payload = {
        provinceTH: newCustomerAddress[1].value.provinceTH,
        districtTH: selectedItem.districtTH,
      };
    }
    if (type == "postalCode") {
      payload = {
        provinceTH: newCustomerAddress[1].value.provinceTH,
        districtTH: newCustomerAddress[2].value.districtTH,
        subDistrictTH: selectedItem.subDistrictTH,
      };
    }
    ApiProvider.post(ApiList.API_URL_FOR_POSTCODE, payload).then((response) => {
      if (type == "province") {
        setProvinceDdOptions(response.data.data.province);
      } else if (type == "district") {
        setDistrictDdOptions(response.data.data.district);
      } else if (type == "subdistrict") {
        setSubDistrictDdOptions(response.data.data.subDistrict);
      } else if (type == "postalCode") {
        let temp = newCustomerAddress;
        temp[4].value = response.data.data.postalCode.postalCodeTH;
        temp[4].error = isNull(response.data.data.postalCode.postalCodeTH)
          ? true
          : false;
        setNewCustomerAddress(temp);
      }
    });

  };
  const addOrUpdateEmail = () => {
    let payload = {
      email: userEmail,
    };
    setLoading(true);
    ApiProvider.post(ApiList.API_URL_TO_UPDATE_USER, payload)
      .then(() => {
        setEditStatus(false);
        setLoading(false);
        // getUserDetails()
      })
      .catch((errRes) => {
        setLoading(false);
        if (
          errRes.response.data.code == 400 ||
          errRes.response.data.data == null
        ) {
          setUserEmailError(true);
          setUserEmailErrorMessage(" Please enter a valid email address");
        }
      });
  };
  const clearAddressFields = () => {
    let temp = newCustomerAddress;
    let length = Object.keys(newCustomerAddress).length;
    for (let i = 0; i < length; i++) {
      temp[i].value = "";
      temp[i].disabled = false
      temp[i].disabled = false
    }

    setUserAddressID("");
    setUserCustomerCode("");
    setNewCustomerAddress({ ...temp });
  };
  const getFieldValidation = () => {
    let flag = false;
    let temp = newCustomerAddress;
    let length = Object.keys(newCustomerAddress).length;
    let step = true;
    for (let i = 0; i < length; i++) {
      if (temp[i].mandatory) {
        if (temp[i].error || isNull(temp[i].value)) {
          flag = true;
          step = false;
          temp[i].errorMessage = "Please fill the required fields!";
          temp[i].error = true;
        } else {
          step = true;
        }
      }
      // }
      if (step) {
        if (temp[4].value.length != 5) {
          flag = true;
          temp[4].error = true;
          temp[4].errorMessage = "Please enter a valid 5 digit postal code";
        }
      }
      setNewCustomerAddress({ ...temp });
    }

    return flag;
  };
  const saveUserAddess = () => {
    setLoading(true);
    if (getFieldValidation("address")) {
      setLoading(false)
      return;
    }

    let payload = {
      customerCode: userData?.user?.customerCode,
      addressTH: newCustomerAddress[0].value,
      provinceTH: newCustomerAddress[1].value.provinceTH,
      districtTH: newCustomerAddress[2].value.districtTH,
      subDistrictTH: newCustomerAddress[3].value.subDistrictTH,
      postalCodeTH: newCustomerAddress[4].value,
      note: newCustomerAddress[5].value,
      isShippingAddress: isNullorZero(newCustomerAddress[6].value) ? false : true,
      isInvoiceAddress: isNullorZero(newCustomerAddress[7].value) ? false : true,
    };
    if (!isNull(userAddressID)) {
      payload.userAddressId = userAddressID;
    }
    ApiProvider.post(ApiList.API_URL_FOR_USER_ADDRESS, payload)
      .then(() => {
        setLoading(false);
        getUserDetails();
        setShowAddressWindow(false);
        clearAddressFields();
      })
      .catch(() => setLoading(false));
  };
  const handleEditAddress = (index) => {
    setLoading(true)
    let temp = newCustomerAddress;
    temp[0].value = userInfo.address[index].addressTH;
    temp[1].value = { provinceTH: userInfo.address[index].provinceTH };
    temp[2].value = { districtTH: userInfo.address[index].districtTH };
    temp[3].value = { subDistrictTH: userInfo.address[index].subDistrictTH };
    temp[4].value = userInfo.address[index].postalCodeTH;
    temp[5].value = userInfo.address[index].note;
    temp[6].value = userInfo.address[index].isShippingAddress ? true : false;
    temp[6].disabled = userInfo.address[index].isShippingAddress ? true : false
    temp[7].value = userInfo.address[index].isInvoiceAddress;
    // temp[7].disabled=userInfo.address[index].isInvoiceAddress?true:false
    setRow(index)
    setUserAddressID(userInfo.address[index].userAddressId);
    setUserCustomerCode(userInfo.address[index].customerCode);
    setNewCustomerAddress({ ...temp });
    setShowAddressWindow(true);
    setShowCancel(false);
    setLoading(false)
  };
  const deleteUserAddess = () => {
    setLoading(true);
    let payload = {
      customerCode: userCustomerCode,
      userAddressId: userAddressID,
    };
    ApiProvider.post(ApiList.API_URL_TO_REMOVE_USER, payload)
      .then(() => {
        setLoading(false);
        getUserDetails();
        setShowAddressWindow(false);
        clearAddressFields();
        setDeleteError(false);
      })
      .catch((errRes) => {
        setLoading(false);
        setTimeout(() => {
          setDeleteError(false);
        }, 5000);
        if (
          errRes.response.data.code == 400 ||
          errRes.response.data.data == null
        ) {
          setDeleteError(true);
        }
      });
  };
  const AddressWindow = () => {
    return (
      <div style={{ marginBottom: "20px" }} className="myAccountCard-addressEdit">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label>{t("Address.label")}</label>
            <input
              type="text"
              name="address"
              className="new-address-input-field-height"
              // placeholder="236-1 ม.2 ถนนแจ้งวัฒนะ"
              value={newCustomerAddress[0].value}
              onChange={(e) => {
                let temp = newCustomerAddress;
                if (!isNull(e.target.value)) {
                  temp[0].value = e.target.value;
                  temp[0].error = false;
                  setNewCustomerAddress({ ...temp });
                } else {
                  temp[0].value = e.target.value;
                  temp[0].error = true;
                  setNewCustomerAddress({ ...temp });
                }
              }}
            // value={address}
            // onChange={handleChange}
            />
            {newCustomerAddress[0].error && (
              <div className="invalid-feedback-tax">
                {newCustomerAddress[0].errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-4 my-profile-autocomplete-main-container">
            <label>{t("Province.label")}</label>
            <Autocomplete
              disablePortal
              id={Math.random().toString(36).substring(7)}
              options={provinceDdOptions}
              autoHighlight
              disableClearable
              popupIcon={<img src={arrowIcon} width={14} height={16} style={{ marginTop: "6px" }} />}
              getOptionLabel={(option) =>
                isMobile && option.provinceTH.length > 10 ? option.provinceTH.substring(0, 10) +
                  ".."
                  : option.provinceTH || ""
              }
              value={newCustomerAddress[1].value}
              onChange={(e, selectedItem) => {
                handleOnchangeDd(selectedItem, "province");
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} style={{ fontSize: 20 }}>
                  <p className="checkout-screen-dropdown-options">{option.provinceTH}</p>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  error={newCustomerAddress[1].error}
                  helperText={
                    newCustomerAddress[1].error
                      ? newCustomerAddress[1].errorMessage
                      : ""
                  }
                  {...params}
                  // label="Province"
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: 'new-password',
                  }} />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-4 my-profile-autocomplete-main-container">
            <label>{t("District.label")}</label>
            <Autocomplete
              disablePortal
              id={Math.random().toString(36).substring(7)}
              options={districtDdOptions}
              autoHighlight
              disableClearable
              popupIcon={<img src={arrowIcon} width={14} height={16} style={{ marginTop: "6px" }} />}
              getOptionLabel={(option) =>
                option.districtTH || ""
              }
              value={newCustomerAddress[2].value}
              onChange={(e, selectedItem) => {
                handleOnchangeDd(selectedItem, "district");
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} style={{ fontSize: 20 }}>
                  <p className="checkout-screen-dropdown-options">{option.districtTH}</p>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  error={newCustomerAddress[2].error}
                  helperText={
                    newCustomerAddress[2].error
                      ? newCustomerAddress[2].errorMessage
                      : ""
                  }
                  {...params}
                  // label="Province"
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: 'new-password',
                  }} />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-4 my-profile-autocomplete-main-container">
            <label>{t("Sub-district.label")}</label>
            <Autocomplete
              disablePortal
              id={Math.random().toString(36).substring(7)}
              options={subDistrictDdOptions}
              autoHighlight
              disableClearable
              popupIcon={<img src={arrowIcon} width={14} height={16} style={{ marginTop: "6px" }} />}
              getOptionLabel={(option) =>
                option.subDistrictTH || ""
              }
              value={newCustomerAddress[3].value}
              onChange={(e, selectedItem) => {
                handleOnchangeDd(selectedItem, "subdistrict");
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} style={{ fontSize: 20 }}>
                  <p className="checkout-screen-dropdown-options">{option.subDistrictTH}</p>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  error={newCustomerAddress[3].error}
                  helperText={
                    newCustomerAddress[3].error
                      ? newCustomerAddress[3].errorMessage
                      : ""
                  }
                  {...params}
                  // label="Province"
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: 'new-password',
                  }} />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-4 my-profile-autocomplete-main-container">
            <label>{t("PostalCode.label")}</label>
            <input
              type="text"
              maxlength={5}
              // placeholder="081-355-5039"
              disabled={true}
              value={newCustomerAddress[4].value}
              onChange={(e) => {
                let temp = newCustomerAddress;
                if (!isNull(e.target.value)) {
                  temp[4].value = e.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                  temp[4].error = false;
                  setNewCustomerAddress({
                    ...temp,
                  });
                } else {
                  temp[4].value = e.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                  temp[4].error = true;
                  setNewCustomerAddress({
                    ...temp,
                  });
                }
              }}
            />
            {newCustomerAddress[4].error && (
              <div className="invalid-feedback-tax">
                {newCustomerAddress[4].errorMessage}
              </div>
            )}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
            <label>{t("Notes(Optional).label")}</label>
            <textarea
              value={newCustomerAddress[5].value}
              maxLength={100}
              onChange={(e) => {
                let temp = newCustomerAddress;
                if (!isNull(e.target.value)) {
                  temp[5].value = e.target.value;
                  temp[5].error = false;
                  setNewCustomerAddress({ ...temp });
                  setNoteCount(e.target.value);
                } else {
                  temp[5].value = e.target.value;
                  temp[5].error = true;
                  setNewCustomerAddress({ ...temp });
                  setNoteCount(e.target.value);
                }
              }}
            />
            <h6 className="my-profile-note-counts">{noteCount?.length ? noteCount?.length : 0}/100</h6>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center mt-4">
            <input
              type="checkbox"
              onClick={(e) => {
                let temp = newCustomerAddress;
                temp[6].value = e.target.checked;
                setNewCustomerAddress({ ...temp });
              }}
              disabled={newCustomerAddress[6].disabled}
              checked={newCustomerAddress[6].value}
              value={newCustomerAddress[6].value}
              className="m-0 mr-2 cursor-pointer"
            />
            <label
              style={{ width: "unset" }}
              className="m-0 ml-2"
            >
              {t("Setasdefaultshippingaddress.label")}
            </label>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
            <input
              type="checkbox"
              onClick={(e) => {
                let temp = newCustomerAddress;
                temp[7].value = e.target.checked;
                setNewCustomerAddress({ ...temp });
              }}
              // disabled={newCustomerAddress[6].disabled}
              checked={newCustomerAddress[7].value}
              value={newCustomerAddress[7].value}
              className="m-0 mr-2 cursor-pointer"
            />
            <label
              style={{ width: "unset" }}
              className="m-0 ml-2"
            >
              {t("Usetheshippingaddressfortaxinvoices.label")}
            </label>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="myAccountCard-addressEdit-btn">
              {/* {showCancel || newCustomerAddress[6].value==true || newCustomerAddress[7].value==true? <button */}
              {newCustomerAddress[6].disabled || newCustomerAddress[7].disabled || isNull(userAddressID) ? <button
                onClick={() => {
                  setShowAddressWindow(!showAddressWindow)
                  setShowCancel(false)
                  setRow("")
                }}
                className="myAccountCard-addressEdit-delete"
              >
                {/* <img src={Delete} /> */}
                {t("Cancel.label")}
              </button> :
                // isNull(newCustomerAddress[6].value)
                <button
                  onClick={() => { deleteUserAddess() }}
                  className="myAccountCard-addressEdit-delete"
                >
                  <img src={Delete} />
                  {t("Delete.label")}
                </button>}
              <button
                type="button"
                onClick={() => {
                  saveUserAddess();
                }}
                className="myAccountCard-addressEdit-save"
              >
                {showCancel
                  ? t("Usethisaddress.label")
                  : t("SaveChanges.label")}
              </button>
            </div>
            {deleteError && (
              <div className="invalid-feedback-tax">
                {
                  "This address is marked as default so we cannot delete."
                }
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  // useEffect(()=>{
  //   // setRow("")
  // },[row])
  return (
    <>
      <div className="headerFixed">
        <Header />
      </div>
      <Helmet>
        <title>Betagro My Profile </title>
      </Helmet>
      <div className="container-fluid my-order-main-container headerFixed-margin" data-testid="my-account-screen-loading">
        <div className="row">
          <div className="col-xll-3 col-xl-3 col-md-3 col-sm-12" data-testid="my-account-hamburger-menu-section">
            <MyAccountHamburgerMenu activeMenu={activeMenu} />
          </div>
          {loading && <Loader />}
          <div className="col-xll-9 col-xl-9 col-md-9 col-sm-12 p-0">
            <div className="myAccount myAccount-padxs">
              <div className="myAccountHead">
                <button
                  className="backBtn visible-sm"
                  onClick={() => history.goBack()}
                >
                  {t("Back.label")}
                </button>
                <h2 data-testid="my-profile-heading">{t("MyProfile.label")}</h2>
              </div>
              <div className="myAccountCard">
                <h4>
                  <img src={Profile} className="mr-4" />
                  <span data-testid="personal-information-heading">{t("PersonalInformation2.label")}</span>
                </h4>
                <div className="row d-flex align-items-center">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                    <p>
                      <span data-testid="name-heading">{t("Name.label")}</span>
                    </p>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                    <p className="text-capitalize">
                      {lancode == "en"
                        ? userData?.user?.fullname
                        : userData?.user?.firstNameTH + "  " + userData?.user?.lastNameTH}
                    </p>
                    <div className="myAccount-mrgnxs visible-sm"></div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <span className="myAccountCard-borderBottom"></span>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                    <p>
                      <span>
                        {userInfo?.role === "Saleshop"
                          ? t("SalesID.label")
                          : t("CustomerId.label")}
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                    <p>
                      {userData?.user?.customerCode}
                    </p>
                    <div className="myAccount-mrgnxs visible-sm"></div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <span className="myAccountCard-borderBottom"></span>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                    <p>
                      <span data-testid="phone-heading">{t("Phonenumber.label")}</span>
                    </p>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                    <p>{userData?.user?.phone}</p>
                    <div className="myAccount-mrgnxs visible-sm"></div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <span className="myAccountCard-borderBottom"></span>
                  </div>
                  {userInfo?.role === "Saleshop" && (
                    <>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <p>
                          <span>{t("Assignedbranch.label")}</span>
                        </p>
                      </div>
                      <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                        <p>
                          <img
                            src={Location}
                            className="mr-3"
                            style={{ width: "12px" }}
                          />
                          {lancode == "en"
                            ? userInfo.stores[0].addressEN +
                            ", " +
                            userInfo.stores[0].subDistrictEN +
                            ", " +
                            userInfo.stores[0].districtEN +
                            ", " +
                            userInfo.stores[0].provinceEN +
                            " " +
                            userInfo.stores[0].postalCodeEN
                            : userInfo.stores[0].addressTH +
                            ", " +
                            userInfo.stores[0].subDistrictTH +
                            ", " +
                            userInfo.stores[0].districtTH +
                            ", " +
                            userInfo.stores[0].provinceTH +
                            " " +
                            userInfo.stores[0].postalCodeTH}
                        </p>
                        <div className="myAccount-mrgnxs visible-sm"></div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <span className="myAccountCard-borderBottom"></span>
                      </div>
                    </>
                  )}
                </div>
                <div className="row d-flex align-items-center">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 hidden-sm">
                    <p>
                      <span data-testid="email-heading">{t("E-mailaddress.label")}</span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mt-3 visible-sm">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="m-0">
                        <span>{t("E-mailaddress.label")}</span>
                      </p>
                      <h5
                        onClick={() => addOrUpdateEmail()}
                        className="cursor-pointer"
                      >
                        {userInfo?.role !== "Saleshop" && editStatus && <span> {t("Save.label")}</span>}
                      </h5>
                    </div>
                    <div className="myAccount-mrgnxs visible-sm"></div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12 col-12 add-email-address-image">
                    {!isNull(userEmail) && !editStatus && (
                      <p className="text-lowerCase add-email-address-text">
                        {userEmail}
                        {userInfo?.role !== "Saleshop" && (
                          <img
                            src={Edit}
                            onClick={() => handleEdit()}
                            className="ml-3 cursor-pointer my-profile-edit-pencil-icon"
                          />
                        )}
                      </p>
                    )}
                    <div className="myAccount-mrgnxs visible-sm"></div>
                    {isNull(userEmail) &&
                      userData?.user?.role !== "Saleshop" && userData?.user?.role !== "Admin" &&
                      !editStatus && (
                        <h5
                          className="d-flex align-items-center cursor-pointer Addnewaddress-xs add-email-address-icon-main-container"
                          onClick={() => {
                            setEditStatus(true);
                          }}
                        >
                          <img src={AddGreen} className="mr-3 add-email-address-icon" />
                          <span>{t("Addemail.label")}</span>
                        </h5>
                      )}
                    <div className="myAccount-mrgnxs visible-sm"></div>
                    {userData?.user?.role !== "Saleshop" && userData?.user?.role !== "Admin" && editStatus && (
                      <input
                        type="email"
                        value={userEmail}
                        className={`${userEmailError ? 'myAccountCard-input-errorBorder' : ''}`}
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                          setUserEmailError(false);
                          setUserEmailErrorMessage("");
                        }}
                      />
                    )}
                    {userEmailError && (
                      <p className="myAccountCard-wrongemailWarning">
                        {userEmailErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 visible-sm">
                    <span className="myAccountCard-borderBottom"></span>
                  </div>
                  {userInfo?.role !== "Saleshop" && editStatus && (
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 add-email-address-image hidden-sm">
                      <button
                        className="myAccountCard-cancel"
                        onClick={() => {
                          setEditStatus(false);
                          setUserEmailError(false);
                          setUserEmailErrorMessage("");
                          getUserDetails();
                        }}
                      >
                        {t("Cancel.label")}
                      </button>
                      <button
                        disabled={isNull(userEmail)}
                        className={`${!isNull(userEmail) ? "myAccountCard-save" : "myAccountCard-save-disabled"}`}
                        onClick={() => addOrUpdateEmail()}
                      >
                        {t("Save.label")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {userInfo?.role !== "Saleshop" && (
                <>
                  <div className="myAccountCard">
                    <h4 className="mb-4">
                      <img src={Address} className="mr-4" />
                      <span>{t("Address2.label")}</span>
                    </h4>
                    {userInfo?.address?.map((item, index) => {
                      return <>
                        {(showAddressWindow && index == row)
                          ?
                          AddressWindow()
                          :
                          <div className="myAccountCard-address">
                            <div className="row d-flex align-items-center">
                              <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                                <h5>
                                  {item.addressTH +
                                    ", " +
                                    item.subDistrictTH +
                                    ", " +
                                    item.districtTH +
                                    ", " +
                                    item.provinceTH +
                                    " " +
                                    item.postalCodeTH}
                                </h5>
                                <div className="myAccountCard-address-image">
                                  {item.isShippingAddress && (
                                    <>
                                      <img src={Check} />
                                      <p className="m-0 ml-2 mr-4 my-profile-billing-address-container">
                                        <span>
                                          {t("Defaultshippingaddress.label")}
                                        </span>
                                      </p>
                                    </>
                                  )}
                                  {item.isInvoiceAddress && (
                                    <>
                                      <img src={Check} />
                                      <p className="m-0 ml-2 mr-4 my-profile-billing-address-container">
                                        <span>
                                          {t("Defaultbillingaddress.label")}
                                        </span>
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2 col-2 d-flex align-items-center justify-content-end">
                                <img
                                  src={Edit}
                                  onClick={() => handleEditAddress(index)}
                                  className="cursor-pointer my-profile-edit-pencil-icon"
                                />
                              </div>
                            </div>
                          </div>
                        }

                      </>
                    })}
                    {userInfo?.address?.length < 3 && (
                      //  (showAddressWindow && !isNull(row)) &&
                      <div
                        className="myAccountCard-address"
                        onClick={() => {
                          clearAddressFields();
                          setShowCancel(true);
                          setRow(null)
                          setShowAddressWindow(true);

                        }}
                      >
                        <div className="row">
                          <div
                            className="col-lg-10 col-md-10 col-sm-10 col-10 d-flex align-items-center justify-content-start"
                            style={{ cursor: "pointer" }}
                          >
                            <h5>
                              <img src={Add} className="mr-3" />
                              {t("Addnewaddress.label")}
                            </h5>
                          </div>
                        </div>
                      </div>
                    )}

                    {(showAddressWindow && isNull(row)) && AddressWindow()}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* footer-section start from here */}
      <div className='common-display-none-mobile'>
        <Footer />
      </div>
      {/* footer-section start end */}
    </>
  );
};

export { MyAccountProfile as MyAccountProfile };

