import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HeaderCheckout } from "../HeaderCheckout";
import { OrderSummary } from "../OrderSummary";
import { Base64 } from "../_helpers/commanFunction";
import "./CustomerCheckout.css";
// import { Footer } from "../Footer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Footer } from "../Footer";
// import { FormControl, Select, MenuItem } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import ArrowGrey from "./Assets/SVG/ArrowGrey.svg";
import Calander from "./Assets/SVG/Calander.svg";
import Delete from "./Assets/SVG/Delete.svg";
import Edit from "./Assets/SVG/Edit.svg";
import Location from "./Assets/SVG/Location.svg";
import Payment from "./Assets/SVG/Payment.svg";
import Shipment from "./Assets/SVG/Shipment.svg";
import UserIconGreen from "./Assets/SVG/UserIconGreen.svg";
import Watch from "./Assets/SVG/Watch.svg";
// import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ThankYouForPurchaseModal from "../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal";
import Loading from "../SharedComponent/Loader/Loader";
import { productActions } from "../_actions";
import { REACT_APP_BASE_URL } from "../constant";
import AlertImage from "../images/alert1.png";
import arrowIcon from "../images/small-chevron-down.png";
import ThankYouImage from "../images/thank-you-puchase-image.png";
import { TaxInvoice } from "./TaxInvoice";

import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Calendar from "react-calendar";

import "react-datepicker/dist/react-datepicker.css";

const CustomerCheckout = (props) => {
  const [visible, setVisible] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarDate, setSelected] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  const userAddressSaveFromCheckout = useSelector(
    (state) => state.product.userAddressSaveFromCheckout
  );

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);
  const [value, onChange] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [showTime, setShowTime] = useState(false);

  const handleCloseDate = () => setShowDate(false);
  const handleShowDate = () => setShowDate(true);

  const handleCloseTime = () => setShowTime(false);
  const handleShowTime = () => setShowTime(true);

  //to set delivery date
  const history = useHistory();
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [deliveryTimeError, setDeliveryTimeError] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState(false);
  //to set Customer Province
  const [customerProvince, setCustomerProvince] = useState("");
  const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [customerDistrict, setCustomerDistrict] = useState("");
  const [customerSubDistrict, setCustomerSubDistrict] = useState("");
  const [loading, setLoading] = useState(false);

  // for second address form
  const [province2, setProvince2] = useState("");
  const [address2, setAddress2] = useState("");
  // address3 used for new address
  const [address3, setAddress3] = useState("");
  const [addressForInvoice, setAddressForInvoice] = useState("");

  const [postalCode2, setPostalCode2] = useState("");
  const [postalCode3, setPostalCode3] = useState("");
  const [customerDistrict2, setCustomerDistrict2] = useState("");
  const [customerSubDistrict2, setCustomerSubDistrict2] = useState("");

  // address 4 used if 3 row of address in available
  const [address4, setAddress4] = useState("");
  const [postalCode4, setPostalCode4] = useState("");
  const [province4, setProvince4] = useState("");
  const [customerDistrict4, setCustomerDistrict4] = useState("");
  const [customerSubDistrict4, setCustomerSubDistrict4] = useState("");
  //
  const [province3, setProvince3] = useState("");
  const [customerDistrict3, setCustomerDistrict3] = useState("");
  const [customerSubDistrict3, setCustomerSubDistrict3] = useState("");
  const [isError, setIsError] = useState(false)
  //to switch payment method
  // const [paymentMethod, setPaymentMethod] = useState(null);
  //to switch shipping address
  const [changeShippingAddress, setChangeShippingAddress] = useState(null);
  //to set the tax authority
  // const [taxAuthority, setTaxAuthority] = useState(null);
  const [selectedPayment, setSelectedPayment] =
    React.useState("Cash on delivery");
  const [taxInvoiceData, setTaxInvoiceData] = React.useState([
    { label: "Company Name", value: "", error: false },
    { label: "Tax ID", value: "", error: false },
    { label: "Phone Number", value: "", error: false },
    { label: "Address", value: "", error: false },
  ]);
  const [nextDate, setNextDate] = useState("");
  const [todayDate, setTodayDate] = useState("");

  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const [editPersonalInfo1, setEditPersonalInfo1] = useState(false);
  const [editPersonalInfo2, setEditPersonalInfo2] = useState(false);
  const [editPersonalInfo3, setEditPersonalInfo3] = useState(false);
  const [editPersonalInfo4, setEditPersonalInfo4] = useState(false);

  const dispatch = useDispatch();

  const [showShippingAddress, setShowShippingAddress] = useState(false);
  const [showShippingAddress2, setShowShippingAddress2] = useState(false);
  const [showShippingAddress3, setShowShippingAddress3] = useState(false);
  const [showShippingAddress4, setShowShippingAddress4] = useState(false);
  const [hideShippindAddress, setHideShippindAddress] = useState(true);
  const [hidePersonalInfo, setHidePersonalInfo] = useState(true);
  const [optionalNote, setOptionalNote] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);


  const [shippingAddressLabel, setShippingAddressLabel] = useState("");
  const [shippingAddressLabelTH2, setShippingAddressLabelTH2] = useState("");
  const [shippingAddressLabelTH3, setShippingAddressLabelTH3] = useState("");

  const [isShippingAddress, setIsShippingAddress] = useState(false);

  const [selectedAddressValue, setSelectedAddressValue] = useState(null);
  const [defaultShippingAddress, setDefaultShippingAddress] = useState("");

  const [userIdForOrder, setUserIdForOrder] = useState("");
  const [userAddressIdForOrder, setUserAddressIdForOrder] = useState("");
  const [storeCodeForOrder, setStoreCodeForOrder] = useState("");
  const [tomorrowDate, settomorrowDate] = useState("");

  const [postalData, setPostalData] = useState([]);

  const [userDetail, setUserDetails] = useState([]);

  const [updateUsEffect, setUpdateeffect] = useState(false);
  const [updateUsEffect2, setUpdateeffect2] = useState(false);
  const [updateUsEffect3, setUpdateeffect3] = useState(false);
  const [updateUsEffect4, setUpdateeffect4] = useState(false);
  const [draftOrder, setDraftOrder] = useState([]);

  const [mainHeading, setMainHeading] = useState("");
  const [subHeading1, setSubHeading1] = useState("");
  const [orderId, setOrderId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [userNote, setUserNote] = useState("");
  const [userNote2, setUserNote2] = useState("");
  const [userNote3, setUserNote3] = useState("");
  const [userNote4, setUserNote4] = useState("");
  const [isCorrectTime, setIsCorrectTime] = useState(false);
  // const postalCodes = useSelector((state) => state.postcode);
  const [isCheckedForadd1, setIsCheckedForadd1] = useState(false);
  const [isCheckedForadd2, setIsCheckedForadd2] = useState(false);
  const [isCheckedForadd4, setIsCheckedForadd4] = useState(false);
  const [isCheckedForadd3, setIsCheckedForadd3] = useState(false);

  const [isdafaultDelivery, setIsdafaultDelivery] = useState(false);
  const [isdafaultDelivery2, setIsdafaultDelivery2] = useState(false);
  const [isdafaultDelivery4, setIsdafaultDelivery4] = useState(false);
  const [isdafaultDelivery3, setIsdafaultDelivery3] = useState(false);

  const [hideEditButton, setHideEditButton] = useState(false);

  const [addressFormData, setAddressFormData] = useState({
    customerCode: "",
    addressTH: "",
    districtTH: "",
    subDistrictTH: "",
    provinceTH: "",
    postalCodeTH: "",
    defaultDelivery: true,
    shippingAddress: true,
  });

  const timeSlots = [
    "9.00 - 12.00",
    "12.00 - 15.00",
    "15.00 - 18.00",
    // "18.00 - 21.00",
    // "21.00 - 1.00",
    // "1.00 - 5.00"
  ];

  const [selectedTime, setSelectedTime] = useState("");

  const [showAddressWindowForNewCustomer, setShowAddressWindowForNewCustomer] =
    useState(false);
  const [newCustomerDetails, setNewCustomerDetails] = useState([
    {
      label: "Name",
      value: "",
      error: false,
      mandatory: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Customer ID",
      value: "",
      error: false,
      mandatory: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Phone Number",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Channel ID",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Notes",
      value: "",
      error: false,
      mandatory: false,
      errorMessage: "Please fill the required field!",
    },
  ]);
  const [newCustomerAddress, setNewCustomerAddress] = useState([
    {
      label: "Address",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Province",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "District",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Sub-District",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Postal Code",
      value: "",
      error: false,
      mandatory: true,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Notes",
      value: "",
      error: false,
      mandatory: false,
      errorMessage: "Please fill the required field!",
    },
  ]);
  const [provinceDdOptions, setProvinceDdOptions] = React.useState([]);
  const [districtDdOptions, setDistrictDdOptions] = React.useState([]);
  const [subDistrictDdOptions, setSubDistrictDdOptions] = React.useState([]);
  let [newCustomerAddressId, setNewCustomerAddressId] = useState();
  const [noAddressErrorNewCustomer, setNoAddressErrorNewCustomer] =
    useState(false);

  const isNull = (value) => {
    return value === "" || value === undefined || value === null;
  };

  const [customerOrderData, setCustomerOrderData] = useState({
    userId: "",
    userNote: "",
    userAddressId: "",
    deliveryDate: "",
    deliveryTime: "",
    paymentMode: "Cash on delivery" || "",
    storeCode: "",
    cartId: [],
  });
  const cartId =
    props && props?.location?.state?.selectedProducts.map((item) => item?.cartId);

  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 1);

  useEffect(() => {
    // const minDate = new Date();

    userDetail?.data?.address.map((item) => {
      if (item.isShippingAddress == true) {
        const shippingAddress =
          item.addressTH +
          ", " +
          item.subDistrictTH +
          ", " +
          item.districtTH +
          ", " +
          item.provinceTH +
          " " +
          item.postalCodeTH;
        setIsShippingAddress(item.isShippingAddress);
        setNewCustomerAddressId(item.userAddressId);
        setDefaultShippingAddress(shippingAddress);
        setSelectedAddressValue(shippingAddress);
      }
    });

    // const isShippingAddress = userDetail?.data?.address.map(item => {
    //   if(item.isShippingAddress == true) {
    //   return item.isShippingAddress
    //     // setSelectedAddressValue(shippingAddress)
    //   }
    // })

    // setIsShippingAddress(isShippingAddress);

    const shipAddress =
      userDetail &&
      userDetail.data &&
      userDetail.data.address?.length > 0 &&
      userDetail.data.address[0].addressTH +
      ", " +
      userDetail.data.address[0].subDistrictTH +
      ", " +
      userDetail.data.address[0].districtTH +
      ", " +
      userDetail.data.address[0].provinceTH +
      " " +
      userDetail.data.address[0].postalCodeTH;

    setShippingAddressLabel(shipAddress);

    const shippingAddressLabelTH2 =
      userDetail &&
      userDetail.data &&
      userDetail.data?.address?.length > 1 &&
      userDetail.data.address[1].addressTH +
      ", " +
      userDetail.data.address[1].subDistrictTH +
      ", " +
      userDetail.data.address[1].districtTH +
      ", " +
      userDetail.data.address[1].provinceTH +
      " " +
      userDetail.data.address[1].postalCodeTH;

    setShippingAddressLabelTH2(shippingAddressLabelTH2);

    const shippingAddressLabelTH3 =
      userDetail &&
      userDetail.data &&
      userDetail.data?.address?.length > 2 &&
      userDetail.data.address[2].addressTH +
      ", " +
      userDetail.data.address[2].subDistrictTH +
      ", " +
      userDetail.data.address[2].districtTH +
      ", " +
      userDetail.data.address[2].provinceTH +
      " " +
      userDetail.data.address[2].postalCodeTH;

    setShippingAddressLabelTH3(shippingAddressLabelTH3);

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1);

    settomorrowDate(moment(maxDate).format("DD/MM/YYYY"));
  }, [userDetail]);

  // const _handleSelectedDate = (event) => {
  //   setShowCalendar(false);
  //   const currentSelectedDate = new Date(event).toLocaleDateString();
  //   setCustomerOrderData({...currentSelectedDate, deliveryDate:currentSelectedDate})
  //   setShowDate(false);
  //   }

  const handleSelection = (time) => {
    setShowTime(false);
    const selectedDate = customerOrderData.deliveryDate.split("/")[0]
    const today = new Date()
    if (selectedDate.toString() != today.getDate().toString()) {
      setIsCorrectTime(true)
      setOrderDataValidation(true)
    }
    else {
      const selectedTime = time.split(" - ")[1].split(".")[0]
      if (selectedTime > today.getHours()) {
        setIsCorrectTime(true)
        setOrderDataValidation(true)
      }
      else {
        setIsCorrectTime(false)
        setOrderDataValidation(false)
      }
    }
    setCustomerOrderData({
      ...customerOrderData,
      deliveryTime: time,
      userId: userDetail.data.userId,
      userAddressId: newCustomerAddressId
        ? newCustomerAddressId
        : userDetail?.data?.address[0]?.userAddressId,
      storeCode: userDetail.data?.stores[0]?.storeCode,
      cartId: cartId,
      deliveryDate: customerOrderData.deliveryDate
    });
  };

  const handleInputChange = (event) => {
    if (!event.target) {
      // console.log('add','change only',customerOrderData)
      setShowCalendar(false);
      const currentSelectedDate = moment(event).format("DD/MM/YYYY");
      setCustomerOrderData({
        ...customerOrderData,
        [name]: value,
        userId: userDetail.data.userId,
        userAddressId: newCustomerAddressId
          ? newCustomerAddressId
          : userDetail.data?.address[0]?.userAddressId,
        storeCode: userDetail.data?.stores[0]?.storeCode,
        cartId: cartId,
        deliveryDate: currentSelectedDate,
        deliveryTime: ''
      });
      setShowDate(false);
      setShowTime(false);
      setIsCorrectTime(false)
      setOrderDataValidation(false)
    } else {
      // console.log('add','change',newCustomerAddressId)
      const { name, value } = event.target;
      if (name === 'deliveryTime') {
        const selectedDate = customerOrderData.deliveryDate.split("/")[0]
        const today = new Date()
        if (selectedDate.toString() != today.getDate().toString()) {
          setIsCorrectTime(true)
          setOrderDataValidation(true)
        }
        else {
          const selectedTime = value.split(" - ")[1].split(".")[0]
          if (selectedTime > today.getHours()) {
            setIsCorrectTime(true)
            setOrderDataValidation(true)
          }
          else {
            setIsCorrectTime(false)
            setOrderDataValidation(false)
          }
        }
      }
      setDeliveryTimeError(false);
      setPaymentMethodError(false);
      setCustomerOrderData({
        ...customerOrderData,
        [name]: value,
        userId: userDetail.data.userId,
        userAddressId: newCustomerAddressId
          ? newCustomerAddressId
          : userDetail.data?.address[0]?.userAddressId,
        storeCode: userDetail.data?.stores[0]?.storeCode,
        cartId: cartId,
        deliveryDate: customerOrderData.deliveryDate
      });
    }

    // setUpdateeffect(true);
    // setUserIdForOrder(userIdForOrder)
    // setUserAddressIdForOrder(userAddressIdForOrder)

    // setStoreCodeForOrder(storeCodeForOrder)
  };

  const [OrderDataValidation, setOrderDataValidation] = useState(false);

  const [formValidForAddress, setFormValidForAddress] = useState(false);
  const [newCustomerValid, setNewCustomerValid] = useState(false);

  const postalCodeDataUser = useSelector((state) => state.order);

  // const isNull = (value) => {
  //   return value == "" || value == undefined || value == null;
  // };

  useEffect(() => {
    let payload = {};
    setLoading(false);
    getPostalCode(payload);
    getUserDetails();
  }, [dataSaved]);

  useEffect(() => {
    getProvinceData("province", null);
  }, [userDetail]);

  useEffect(() => { }, [draftOrder]);

  // useEffect(() => {
  //   const isFormValid = Object.values(addressFormData).every((value) => value !== '');
  //   setFormValidForAddress(isFormValid);
  // }, [formValidForAddress]);

  useEffect(() => {
    // console.log('add',customerOrderData)
    const isFormValid = Object.entries(customerOrderData)
      .filter(([key]) => key !== "userNote" && key !== "paymentMode")
      .every(([, value]) => value !== "");
    if (isCorrectTime)
      setOrderDataValidation(isFormValid);
    else
      setOrderDataValidation(false)
  }, [customerOrderData]);

  React.useEffect(() => {
    if (userAddressSaveFromCheckout?.data) {
      setDataSaved(!dataSaved);
    }
  }, [userAddressSaveFromCheckout]);

  const { t } = useTranslation();

  const lancode = localStorage.getItem("lancode");

  const userDetails = sessionStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : undefined;

  const handleDeliveryTime = (event) => {
    setDeliveryTimeError(false);
    setDeliveryTime(event.target.value);
  };

  const ClickAction = () => {
    setShowToast(false);
    history.push("/allproducts");
  };

  const handleCustomerProvince = (event) => {
    let payload = {
      provinceTH: event.target.value,
    };

    if (event.target.name === "province3") {
      // console.log('clickProvince3:', payload)
      setProvince3(event.target.value);
      setCustomerDistrict3('')
      setCustomerSubDistrict3('')
      setPostalCode3('')
      if (userDetail.data.address.length <= 1) {
        setProvince2(event.target.value);
        setCustomerDistrict2('')
        setCustomerSubDistrict2('')
        setPostalCode2('')
      }
      else {
        setProvince4(event.target.value);
        setCustomerDistrict4('')
        setCustomerSubDistrict4('')
        setPostalCode4('')
      }
    }

    else if (event.target.name === "province2") {
      // console.log('clickProvince2:', payload)
      setProvince2(event.target.value);
      setCustomerDistrict2('')
      setCustomerSubDistrict2('')
      setPostalCode2('')
    }

    else if (event.target.name === "province4") {
      // console.log('clickProvince4:', payload)
      setProvince4(event.target.value);
      setCustomerDistrict4('')
      setCustomerSubDistrict4('')
      setPostalCode4('')
    }

    else {
      // console.log('clickProvince:', payload)
      setProvince(event.target.value);
      setCustomerDistrict('')
      setCustomerSubDistrict('')
      setPostalCode('')
    }
    getPostalCode(payload);
  };

  const handleCustomerDistrict = (event) => {
    let payload

    if (event.target.name === "customerDistrict3") {
      payload = {
        provinceTH: province3,
        districtTH: event.target.value,
      };
      // console.log('clickDT3:', payload)
      setCustomerDistrict3(event.target.value);
      setCustomerSubDistrict3('')
      setPostalCode3('')
      if (userDetail.data.address.length <= 1) {
        setCustomerDistrict2(event.target.value);
        setCustomerSubDistrict2('')
        setPostalCode2('')
      }
      else {
        setCustomerDistrict4(event.target.value);
        setCustomerSubDistrict4('')
        setPostalCode4('')
      }
    }

    else if (event.target.name === "customerDistrict2") {
      payload = {
        provinceTH: province2,
        districtTH: event.target.value,
      };
      // console.log('clickDT2:', payload)
      setCustomerDistrict2(event.target.value);
      setCustomerSubDistrict2('')
      setPostalCode2('')
    }

    else if (event.target.name === "customerDistrict4") {
      payload = {
        provinceTH: province4,
        districtTH: event.target.value,
      };
      // console.log('clickDT4:', payload)
      setCustomerDistrict4(event.target.value);
      setCustomerSubDistrict4('')
      setPostalCode4('')
    }
    else {
      payload = {
        provinceTH: province,
        districtTH: event.target.value,
      };
      // console.log('clickDT:', payload)
      setCustomerDistrict(event.target.value);
      setCustomerSubDistrict('')
      setPostalCode('')
    }
    getPostalCode(payload);
  };

  const handleCustomerSubDistrict = (event) => {
    let payload
    if (event.target.name === "customerSubDistrict3") {
      payload = {
        provinceTH: province3,
        subDistrictTH: event.target.value,
        districtTH: customerDistrict3,
      };
      // console.log('clickSubDT3:', payload)
      setCustomerSubDistrict3(event.target.value);
      setPostalCode3('')
      if (userDetail.data.address.length <= 1) {
        setCustomerSubDistrict2(event.target.value);
        setPostalCode2('')
      }
      else {
        setCustomerSubDistrict4(event.target.value);
        setPostalCode4('')
      }
    }
    else if (event.target.name === "customerSubDistrict2") {
      payload = {
        provinceTH: province2,
        subDistrictTH: event.target.value,
        districtTH: customerDistrict2,
      };
      // console.log('clickSubDT2:', payload)
      setCustomerSubDistrict2(event.target.value);
      setPostalCode2('')
    }
    else if (event.target.name === "customerSubDistrict4") {
      payload = {
        provinceTH: province4,
        subDistrictTH: event.target.value,
        districtTH: customerDistrict4,
      };
      // console.log('clickSubDT4:', payload)
      setCustomerSubDistrict4(event.target.value);
      setPostalCode4('')
    }
    else {
      payload = {
        provinceTH: province,
        subDistrictTH: event.target.value,
        districtTH: customerDistrict,
      };
      // console.log('clickSubDT:', payload)
      setCustomerSubDistrict(event.target.value);
      setPostalCode('')
    }
    getPostalCode(payload);
  };

  //to switch payment method
  const [paymentMethod, setPaymentMethod] = React.useState("Cash on delivery");
  // const [showInvoiceForm, setShowInvoiceForm] = React.useState(false);

  const handlePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleShippingAddress = (event) => {
    setChangeShippingAddress(event.target.value);
    setSelectedAddressValue(event.target.value);

    if (event.target.name === "firstAddress") {
      const isTrue = Boolean(event.target.value);
      setIsdafaultDelivery(isTrue);
    }

    if (event.target.name === "secondAddress") {
      const isTrue = Boolean(event.target.value);
      setIsdafaultDelivery2(isTrue);
    }

    if (event.target.name === "thirdAddress") {
      const isTrue = Boolean(event.target.value);
      setIsdafaultDelivery3(isTrue);
    }
  };

  //to set the tax authority
  const [taxAuthority, setTaxAuthority] = React.useState("Company");

  const handleTaxAuthority = (event) => {
    setTaxAuthority(event.target.value);
  };

  useEffect(() => {
    if (userDetail && userDetail.data && userDetail.data.address) {
      // setCustomerProvince(userDetails.user.address[0].provinceEN);
      setProvince(
        userDetail && userDetail.data && userDetail.data?.address[0]?.provinceTH
      );

      setCustomerDistrict(
        userDetail && userDetail.data && userDetail.data.address[0]?.districtTH
      );

      setCustomerSubDistrict(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[0]?.subDistrictTH
      );


      setPostalCode(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[0]?.postalCodeTH
      );

      setAddress(
        userDetail && userDetail.data && userDetail.data.address[0]?.addressTH
      );

      setUserIdForOrder(
        userDetail && userDetail.data && userDetail.data.userId
      );

      setUserAddressIdForOrder(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[0]?.userAddressId
      );

      setStoreCodeForOrder(
        userDetail && userDetail.data && userDetail.data.stores[0]?.storeCode
      );

      setUserNote(
        userDetail && userDetail.data && userDetail.data.address[0]?.note
      );

      setIsCheckedForadd1(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[0]?.isInvoiceAddress
      );
    }

  }, [updateUsEffect]);

  useEffect(() => {
    if (userDetail && userDetail.data && userDetail.data.address[1]) {
      // setCustomerProvince(userDetails.user.address[0].provinceEN);
      setProvince2(
        userDetail && userDetail.data && userDetail.data.address[1]?.provinceTH
      );

      setCustomerDistrict2(
        userDetail && userDetail.data && userDetail.data.address[1]?.districtTH
      );

      setCustomerSubDistrict2(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[1].subDistrictTH
      );

      setPostalCode2(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[1]?.postalCodeTH
      );

      setAddress2(
        userDetail && userDetail.data && userDetail.data.address[1]?.addressTH
      );

      setUserNote2(
        userDetail && userDetail.data && userDetail.data.address[1]?.note
      );

      setIsCheckedForadd2(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[1]?.isInvoiceAddress
      );
    }
  }, [updateUsEffect2])

  useEffect(() => {
    if (userDetail && userDetail.data && userDetail.data.address[2]) {
      // setCustomerProvince(userDetails.user.address[0].provinceEN);
      setProvince4(
        userDetail && userDetail.data && userDetail.data.address[2]?.provinceTH
      );

      setCustomerDistrict4(
        userDetail && userDetail.data && userDetail.data.address[2]?.districtTH
      );

      setCustomerSubDistrict4(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[2]?.subDistrictTH
      );

      setPostalCode4(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[2]?.postalCodeTH
      );

      setAddress4(
        userDetail && userDetail.data && userDetail.data.address[2]?.addressTH
      );

      setUserNote4(
        userDetail && userDetail.data && userDetail.data.address[2]?.note
      );

      setIsCheckedForadd3(
        userDetail &&
        userDetail.data &&
        userDetail.data.address[2]?.isInvoiceAddress
      );
    }
  }, [updateUsEffect4])

  useEffect(() => {
    setAddress3('')
    setProvince3('')
    setCustomerDistrict3('')
    setCustomerSubDistrict3('')
    setPostalCode3('')
  }, [updateUsEffect3])

  useEffect(() => {
    // get today's date
    const today = new Date();
    // add one day to get tomorrow's date
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // format the date as YYYY-MM-DD
    const yyyy = tomorrow.getFullYear();
    const mm = String(tomorrow.getMonth() + 1).padStart(2, "0");
    const dd = String(tomorrow.getDate()).padStart(2, "0");
    const date = `${yyyy}-${mm}-${dd}`;

    const yyyyToday = today.getFullYear();
    const mmToday = String(today.getMonth() + 1).padStart(2, "0");
    const ddToday = String(today.getDate()).padStart(2, "0");
    const todayDate = `${yyyyToday}-${mmToday}-${ddToday}`;

    // set the state with the next date
    setTodayDate(todayDate);
    setNextDate(date);
  }, []);

  const showEditForm = () => {
    setHideEditButton(true);
    setEditPersonalInfo(true);
    setEditPersonalInfo1(true);
    setEditPersonalInfo2(true);
    setEditPersonalInfo3(true);
    setEditPersonalInfo4(true);
    setHideShippindAddress(false);
    setHidePersonalInfo(false);
    setUpdateeffect(!updateUsEffect);
    setUpdateeffect2(!updateUsEffect2);
    setUpdateeffect3(!updateUsEffect3);
    setUpdateeffect4(!updateUsEffect4);
  };

  const editShippingAddressForm = () => {
    setEditPersonalInfo1(false);
    setShowShippingAddress(true);
    setShowShippingAddress2(false);
    setShowShippingAddress4(false);
    setShowShippingAddress3(false);
    setUpdateeffect(!updateUsEffect);
    setUpdateeffect2(!updateUsEffect2);
    setUpdateeffect3(!updateUsEffect3);
    setUpdateeffect4(!updateUsEffect4);

    setEditPersonalInfo2(true);
    setEditPersonalInfo3(true);
    setEditPersonalInfo4(true);
    let payload = {
      provinceTH: province,
      subDistrictTH: customerDistrict,
      districtTH: customerDistrict,
    };
    // console.log('before: ', payload)
    getPostalCode(payload);

  };

  const editShippingAddressForm2 = () => {
    setEditPersonalInfo2(false);
    setShowShippingAddress2(true);
    setShowShippingAddress(false);
    setShowShippingAddress4(false);
    setShowShippingAddress3(false);
    setUpdateeffect(!updateUsEffect);
    setUpdateeffect2(!updateUsEffect2);
    setUpdateeffect3(!updateUsEffect3);
    setUpdateeffect4(!updateUsEffect4);

    setEditPersonalInfo1(true);
    setEditPersonalInfo3(true);
    setEditPersonalInfo4(true);
    let payload = {
      provinceTH: province2,
      subDistrictTH: customerDistrict2,
      districtTH: customerDistrict2,
    };
    // console.log('before2: ', payload)
    getPostalCode(payload);
  };

  const editShippingAddressForm3 = () => {
    setEditPersonalInfo3(false);
    setShowShippingAddress3(true);
    setShowShippingAddress4(false);
    setShowShippingAddress2(false);
    setShowShippingAddress(false);
    setUpdateeffect(!updateUsEffect);
    setUpdateeffect2(!updateUsEffect2);
    setUpdateeffect3(!updateUsEffect3);
    setUpdateeffect4(!updateUsEffect4);

    setEditPersonalInfo2(true);
    setEditPersonalInfo1(true);
    setEditPersonalInfo4(true);
    setPostalCode3('');
    let payload = {
      provinceTH: province3,
      subDistrictTH: customerDistrict3,
      districtTH: customerDistrict3,
    };
    // console.log('before3: ', payload)
    getPostalCode(payload);
  };
  const editShippingAddressForm4 = () => {
    setEditPersonalInfo4(false);
    setShowShippingAddress4(true);
    setShowShippingAddress2(false);
    setShowShippingAddress(false);
    setShowShippingAddress3(false);
    setUpdateeffect(!updateUsEffect);
    setUpdateeffect2(!updateUsEffect2);
    setUpdateeffect3(!updateUsEffect3);
    setUpdateeffect4(!updateUsEffect4);

    setEditPersonalInfo2(true);
    setEditPersonalInfo3(true);
    setEditPersonalInfo1(true);
    let payload = {
      provinceTH: province4,
      subDistrictTH: customerDistrict4,
      districtTH: customerDistrict4,
    };
    // console.log('before4: ', payload)
    getPostalCode(payload);
  };

  const saveShippingAddress = () => {
    setEditPersonalInfo(false);
    setShowShippingAddress(false);
    setHideShippindAddress(true);
    setHidePersonalInfo(true);
    setHideEditButton(false);
  };

  const updateShippindAddress3 = (e) => {
    e.preventDefault();
    setShowShippingAddress3(false);
    setEditPersonalInfo3(true);
    setUpdateeffect3(!updateUsEffect3);
  };

  const saveShippingAddressForNewAddress = (event) => {
    event.stopPropagation();
    let payload = {
      customerCode: userDetail.data.customerCode,
      addressTH: address3,
      districtTH: customerDistrict3,
      subDistrictTH: customerSubDistrict3,
      provinceTH: province3,
      postalCodeTH: postalCode3,
      // isShippingAddress: isdafaultDelivery3,
      isInvoiceAddress: isCheckedForadd3,
      note: userNote3,
    };

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    if (isPayloadValid) {
      // setDataSaved(!dataSaved);
      setShowShippingAddress3(false);
      setEditPersonalInfo3(true);

      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
      setAddress3("");
      setPostalCode3("");
      setProvince3("");
      setUserNote3("");
      setUpdateeffect(!updateUsEffect);
      setUpdateeffect2(!updateUsEffect2);
      setUpdateeffect4(!updateUsEffect4);
    }
  };

  const saveUserAddressInfo = (event) => {
    event.stopPropagation();
    // setEditPersonalInfo(false);

    let payload = {
      userAddressId: userDetail.data.address[0].userAddressId,
      customerCode: userDetail.data.customerCode,
      addressTH: address,
      districtTH: customerDistrict,
      subDistrictTH: customerSubDistrict,
      provinceTH: province,
      postalCodeTH: postalCode,
      isInvoiceAddress: isCheckedForadd1,
      note: userNote,
    };

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    if (isPayloadValid) {
      // setAddressFormData(payload);
      // setDataSaved(!dataSaved);
      setShowShippingAddress(false);
      setShowShippingAddress2(false);
      setEditPersonalInfo1(true);
      // setEditPersonalInfo(true)

      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
    }
  };

  const saveUserAddressInfo2 = (event) => {
    event.stopPropagation()
    // setEditPersonalInfo(false);

    let payload = {
      userAddressId:
        userDetail &&
        userDetail.data.address[1] &&
        userDetail.data.address[1].userAddressId,
      customerCode: userDetail.data.customerCode,
      addressTH: address2,
      districtTH: customerDistrict2,
      subDistrictTH: customerSubDistrict2,
      provinceTH: province2,
      postalCodeTH: postalCode2,
      isInvoiceAddress: isCheckedForadd2,
      note: userNote2,
    };

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    if (isPayloadValid) {
      setShowShippingAddress(false);
      setShowShippingAddress2(false);
      setEditPersonalInfo2(true);
      // setDataSaved(!dataSaved);

      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
    }
    // setAddressFormData(payload);
  };

  const saveUserAddressInfo4 = (event) => {
    event.stopPropagation()
    // setEditPersonalInfo(false);

    let payload = {
      userAddressId:
        userDetail &&
        userDetail.data.address[2] &&
        userDetail.data.address[2].userAddressId,
      customerCode: userDetail.data.customerCode,
      addressTH: address4,
      districtTH: customerDistrict4,
      subDistrictTH: customerSubDistrict4,
      provinceTH: province4,
      postalCodeTH: postalCode4,
      isInvoiceAddress: isCheckedForadd2,
      note: userNote4,
    };

    const isPayloadValid = Object.entries(payload)
      .filter(([key]) => key !== "note")
      .every((value) => value !== undefined && value !== "");

    if (isPayloadValid) {
      setShowShippingAddress(false);
      setShowShippingAddress4(false);
      setEditPersonalInfo4(true);
      // setDataSaved(!dataSaved);

      dispatch(productActions.saveUserAddressFromCheckOut("payload", payload));
    }
    // setAddressFormData(payload);
  };


  const handleChange = (event) => {
    if (event.target.name === "address") {
      setAddress(event.target.value);
      // setAddressForInvoice(event.target.value)
    }

    if (event.target.name === "address2") {
      setAddress2(event.target.value);
      // setAddressForInvoice(event.target.value)
    }

    if (event.target.name === "address3") {
      setAddress3(event.target.value);
    }

    if (event.target.name === "address4") {
      setAddress4(event.target.value);
    }

    if (event.target.name === "label1") {
      setIsCheckedForadd1(event.target.checked);
      event.target.checked
        ? setAddressForInvoice("0")
        : setAddressForInvoice("");
    }

    if (event.target.name === "label2") {
      setIsCheckedForadd2(event.target.checked);
      event.target.checked ? setAddressForInvoice(1) : setAddressForInvoice("");
    }

    if (event.target.name === "label3") {
      setIsCheckedForadd3(event.target.checked);
      event.target.checked ? setAddressForInvoice(2) : setAddressForInvoice("");
    }

    if (event.target.name === "userNotes1") {
      setUserNote(event.target.value);
    }

    if (event.target.name === "userNotes2") {
      setUserNote2(event.target.value);
    }

    if (event.target.name === "userNotes4") {
      setUserNote4(event.target.value);
    }

    if (event.target.name === "userNotes3") {
      setUserNote3(event.target.value);
    }
  };

  const handleLabelClick = (userAddressId) => {
    setNewCustomerAddressId(userAddressId);
  };
  React.useEffect(() => {
    // console.log('add',newCustomerAddressId)
    setCustomerOrderData({
      ...customerOrderData,
      userAddressId: newCustomerAddressId
    });
  }, [newCustomerAddressId])
  const handleSubmit = (event) => {
    if (event && event.preventDefault)
      event.preventDefault();
    else
      return
    let payload = {};
    if (
      userDetail &&
      userDetail.data &&
      userDetail.data.role == "New Customer"
    ) {
      if (getFieldValidation("customer")) {
        return;
      }
      if (isNull(newCustomerAddressId)) {
        setNoAddressErrorNewCustomer(true);
      }
      if (getFieldValidation("address")) {
        return;
      }

      if (isNull(customerOrderData.deliveryTime)) {
        setDeliveryTimeError(true);
        return;
      }
      if (isNull(customerOrderData.paymentMode)) {
        setPaymentMethodError(true);
        return;
      }
      payload = {
        cartId: cartId,
        userId: userInfo.userId,
        userNote: newCustomerDetails[4].value,
        userAddressId: newCustomerAddressId,
        deliveryDate: customerOrderData.deliveryDate,
        deliveryTime: customerOrderData.deliveryTime,
        paymentMode: customerOrderData.paymentMode,
        storeCode: userInfo.storeCode,
        newCustomer: {
          phone: newCustomerDetails[2].value,
          channel: newCustomerDetails[3].value,
          note: newCustomerDetails[4].value,
        },
      };
    }
    // else {
    //   if (isNull(customerOrderData.deliveryTime)) {
    //     setDeliveryTimeError(true);
    //     return;
    //   }
    //   if (isNull(customerOrderData.paymentMode)) {
    //     setPaymentMethodError(true);
    //     return;
    //   }
    //   payload = {
    //     cartId: cartId,
    //     userId: userInfo.userId,
    //     userNote: "Some Note",
    //     userAddressId: customerOrderData.userAddressId,
    //     deliveryDate: nextDate,
    //     deliveryTime: customerOrderData.deliveryTime,
    //     paymentMode: customerOrderData.paymentMode,
    //     storeCode: userInfo.storeCode,
    //   };

    // }

    if (
      userDetail &&
      userDetail.data &&
      userDetail.data.role == "New Customer"
    ) {
      confirmOrder(payload);
    } else {
      confirmOrder(customerOrderData);
      // setOpenAlert(true);
    }


    // setMainHeading("Thank you for your purchase");
    // setSubHeading1(confirmOrderData?.data?.data?.orderNo);
    // setOpenAlert(true);
  };

  const handleAlert = () => {
    setOpenAlert(false);
    if (isError)
      history.push('/orderDetails')
    else
      history.push("/myorderdetails/" + Base64.encode(orderId.toString()));
    // history.push("/myorder");
  };

  const getPostalCode = (payload) => {
    // console.log(payload)
    setLoading(true);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/postcode/",
      payload,
      requestOptions
    )
      .then((response) => {
        if (response.data.code === 200) {
          setPostalData(response.data.data && response.data.data);
          // console.log(response.data.data)
          if (
            response.data.data &&
            response.data.data.postalCode.postalCodeTH
          ) {
            setPostalCode(response.data.data.postalCode.postalCodeTH);
            setPostalCode2(response.data.data.postalCode.postalCodeTH);
            setPostalCode3(response.data.data.postalCode.postalCodeTH);
            setPostalCode4(response.data.data.postalCode.postalCodeTH);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        //console.log(error)
      });
  };

  const confirmOrder = (payload) => {
    setLoading(true);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/order/create",
      payload,
      requestOptions
    )
      .then((response) => {
        setOpenAlert(true);
        setLoading(false);

        if (response?.data?.sapResult === 200 || response?.data?.sapResult === undefined) {
          setIsError(false)
          setMainHeading((lancode === "en") ? "Thank you for your purchase" : "ขอบคุณสำหรับการสั่งซ์ื้อ");
          setSubHeading1(response?.data?.data?.orderNo);
          setOrderId(response?.data?.data?.orderId);
          let payloadCart = {};
          dispatch(productActions.getCartData("payload", payloadCart));
        }
        else {
          setIsError(true)
          setMainHeading((lancode === "en") ? "Thank you for your purchase" : "ขอบคุณสำหรับการสั่งซ์ื้อ");
          setSubHeading1(response?.data?.SAPMessage);
          setOrderId(response?.data?.data?.orderId);
          let payloadCart = {};
          dispatch(productActions.getCartData("payload", payloadCart));
        }
      })
      .catch((error) => {
        setLoading(false);
        let payloadCart = {};
        dispatch(productActions.getCartData("payload", payloadCart));
        history.push('/')
      });
  };

  const getUserDetails = () => {
    // /api/users/getDetails/1

    const token = localStorage.getItem("authorization");
    let payload = {};
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.get(
      REACT_APP_BASE_URL +
      "/api/users/getDetails/" +
      props?.location?.state?.userId,
      requestOptions
    )
      .then((response) => {
        setUserDetails(response.data);
        let temp = newCustomerDetails;

        temp[0].value = response.data.data.firstNameEN;
        setNewCustomerDetails({ ...temp });
      })
      .catch((error) => error);
  };

  const handleDelete = (userAddressId, status) => {
    setLoading(true);
    // setUpdateeffect(!updateUsEffect);

    let payload = {
      userAddressId: userAddressId,
      customerCode: userDetail.data.customerCode,
    };
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/users/address/remove",
      payload,
      requestOptions
    )
      .then((response) => {
        if (response.status == 200) {
          setShowShippingAddress(false);
          setShowShippingAddress2(false);
          setShowShippingAddress3(false);
          setShowShippingAddress4(false);
          setLoading(false);
          setDataSaved(!dataSaved);
          if (status) {
            setProvince2(province4)
            setCustomerDistrict2(customerDistrict4)
            setCustomerSubDistrict2(customerSubDistrict4)
            setPostalCode2(postalCode4)
          }
        }
      })
      .catch((error) => error);
  };

  const getFieldValidation = (type) => {
    let flag = false;
    if (type == "customer") {
      let temp = newCustomerDetails;
      let length = Object.keys(newCustomerDetails).length;
      let step = true;
      for (let i = 0; i < length; i++) {
        if (temp[i].mandatory) {
          if (temp[i].error || isNull(temp[i].value)) {
            flag = true;
            step = false;
            temp[i].errorMessage = "Please fill the required fields!";
            temp[i].error = true;
          } else {
            step = true;
          }
        }
      }
      if (step) {
        if (temp[2].value.length < 9 || temp[2].value.length > 10) {
          flag = true;
          temp[2].error = true;
          temp[2].errorMessage =
            "Please enter a valid phone number between 9 to 10 digits";
        }
      }
      setNewCustomerDetails({ ...temp });
    } else {
      let temp = newCustomerAddress;
      let length = Object.keys(newCustomerAddress).length;
      let step = true;
      for (let i = 0; i < length; i++) {
        if (temp[i].mandatory) {
          if (temp[i].error || isNull(temp[i].value)) {
            flag = true;
            step = false;
            temp[i].errorMessage = "Please fill the required fields!";
            temp[i].error = true;
          } else {
            step = true;
          }
        }
      }
      if (step) {
        if (temp[4].value.length != 5) {
          flag = true;
          temp[4].error = true;
          temp[4].errorMessage = "Please enter a valid 5 digit postal code";
        }
      }

      if (flag) {
        setNewCustomerValid(false);
      } else {
        setNewCustomerValid(true);
      }
      // OrderDataValidation
      setNewCustomerAddress({ ...temp });
    }

    return flag;
  };
  const handleDraftOrder = () => {
    const token = localStorage.getItem("authorization");
    let userInfo = userDetail.data;
    let payload = {};

    if (
      userDetail &&
      userDetail.data &&
      userDetail.data.role == "New Customer"
    ) {
      if (getFieldValidation("customer")) {
        return;
      }
      // if (isNull(newCustomerAddressId)) {
      //   setNoAddressErrorNewCustomer(true);
      // }
      // if (getFieldValidation("address")) {
      //   return;
      // }

      if (isNull(customerOrderData.deliveryTime)) {
        setDeliveryTimeError(true);
        return;
      }
      if (isNull(customerOrderData.paymentMode)) {
        setPaymentMethodError(true);
        return;
      }
      payload = {
        cartId: cartId,
        userId: userInfo.userId,
        userNote: newCustomerDetails[4].value,
        userAddressId: newCustomerAddressId,
        deliveryDate: customerOrderData.deliveryDate,
        deliveryTime: customerOrderData.deliveryTime,
        paymentMode: customerOrderData.paymentMode,
        storeCode: userInfo.storeCode,
        newCustomer: {
          phone: newCustomerDetails[2].value,
          channel: newCustomerDetails[3].value,
          note: newCustomerDetails[4].value,
        },
      };
    } else {
      if (isNull(customerOrderData.deliveryTime)) {
        setDeliveryTimeError(true);
        return;
      }
      if (isNull(customerOrderData.paymentMode)) {
        setPaymentMethodError(true);
        return;
      }
      payload = {
        cartId: cartId,
        userId: userInfo.userId,
        userNote: customerOrderData.userNote,
        userAddressId: customerOrderData.userAddressId,
        deliveryDate: customerOrderData.deliveryDate,
        deliveryTime: customerOrderData.deliveryTime,
        paymentMode: customerOrderData.paymentMode,
        storeCode: userInfo.storeCode,
      };
    }
    // console.log(payload);
    var requestOptions = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + `/api/order/create/draft`,
      payload,
      requestOptions
    )
      .then((response) => {
        setShowToast(true);
        setDraftOrder(response.data);
        let payloadCart = {};
        dispatch(productActions.getCartData("payload", payloadCart));
      })
      .catch((error) => error);
  };
  const getProvinceData = (type, selectedItem) => {
    let payload = {};
    if (type == "district") {
      payload = {
        provinceTH: selectedItem.provinceTH,
      };
    }
    if (type == "subdistrict") {
      payload = {
        provinceTH: newCustomerAddress[1].value.provinceTH,
        districtTH: selectedItem.districtTH,
      };
    }
    if (type == "postalCode") {
      payload = {
        provinceTH: newCustomerAddress[1].value.provinceTH,
        districtTH: newCustomerAddress[2].value.districtTH,
        subDistrictTH: selectedItem.subDistrictTH,
      };
    }
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/postcode/",
      payload,
      requestOptions
    )
      .then((response) => {
        if (type == "province") {
          setProvinceDdOptions(response.data.data.province);
        } else if (type == "district") {
          setDistrictDdOptions(response.data.data.district);
        } else if (type == "subdistrict") {
          setSubDistrictDdOptions(response.data.data.subDistrict);
        } else if (type == "postalCode") {
          let temp = newCustomerAddress;
          temp[4].value = response.data.data.postalCode.postalCodeTH;
          temp[4].error = isNull(response.data.data.postalCode.postalCodeTH)
            ? true
            : false;
          setNewCustomerAddress(temp);
        }
        // return response
      })
      .catch((error) => error);
  };

  const handleOnchangeDd = (selectedItem, type) => {
    let temp = newCustomerAddress;
    if (type == "province") {
      temp[1].value = selectedItem;
      temp[1].error = false;
      temp[2].value = "";
      temp[3].value = "";
      temp[4].value = "";
      setNewCustomerAddress({ ...temp });
      getProvinceData("district", selectedItem);
    } else if (type == "district") {
      temp[2].value = selectedItem;
      temp[2].error = false;
      temp[3].value = "";
      temp[4].value = "";
      setNewCustomerAddress({ ...temp });
      getProvinceData("subdistrict", selectedItem);
    } else if (type == "subdistrict") {
      temp[3].value = selectedItem;
      temp[3].error = false;
      temp[4].value = "";
      setNewCustomerAddress({ ...temp });
      getProvinceData("postalCode", selectedItem);
    }
  };

  const saveAndUpdateNewCustomerAddress = () => {
    if (getFieldValidation("address")) {
      return;
    }
    let payload = {
      // userAddressId: 100,
      customerCode: "null",
      addressTH: newCustomerAddress[0].value,
      provinceTH: newCustomerAddress[1].value.provinceTH,
      districtTH: newCustomerAddress[2].value.districtTH,
      subDistrictTH: newCustomerAddress[3].value.subDistrictTH,
      postalCodeTH: newCustomerAddress[4].value,
      // isShippingAddress: true,
      isInvoiceAddress: true,
    };
    const token = localStorage.getItem("authorization");
    var requestOptions = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    };

    return Axios.post(
      REACT_APP_BASE_URL + "/api/users/address",
      payload,
      requestOptions
    )
      .then((response) => {
        newCustomerAddressId = response.data.data.userAddressId;
        setNewCustomerAddressId(response.data.data.userAddressId);
        setShowAddressWindowForNewCustomer(false);
      })
      .catch((error) => error);
  };
  const handleCancelEvent = () => {
    setShowAddressWindowForNewCustomer(false);
  };

  return (
    <>
      <Helmet>
        <title>Betagro Checkout</title>
      </Helmet>
      <HeaderCheckout />
      {loading && <Loading />}
      <ThankYouForPurchaseModal
        image={isError ? AlertImage : ThankYouImage}
        cart={true}
        buttonLabel={t("ThanksBtn.label")}
        mainHeading={mainHeading}
        subHeading1={subHeading1}
        subHeading2={isError ? t("CreateSaleOrderError.label") : t("ConfirmMsg.label")}
        open={showToast ? false : openAlert}
        error={isError}
        ClickAction={handleAlert}
        screen={"checkout"}
      />
      <div className="customerCheckout customerCheckout-outer" data-testid="customer-checkout-loading">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h1 className="hidden-sm" data-testid="checkout-heading">{t("Checkout.label")}</h1>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 col-12">
              <div className="customerCheckoutCard">
                <div className="customerCheckoutCardHead">
                  {userDetails?.user?.role !== "Saleshop" && (
                    <div className="customerCheckoutCardHeadLt">
                      <h3 data-testid="customer-info-heading">
                        <img src={UserIconGreen} />
                        {t("PersonalInfo.label")}
                      </h3>
                    </div>
                  )}

                  {userDetails?.user?.role === "Saleshop" && (
                    <div className="customerCheckoutCardHeadLt">
                      <h3>
                        <img src={UserIconGreen} />
                        {t("CustomerIdorSalesID.label")}
                      </h3>
                    </div>
                  )}

                  {/* ### New Customer Code### */}
                  {userDetail &&
                    userDetail?.data &&
                    userDetail?.data?.role != "New Customer" &&
                    !hideEditButton && (
                      <div
                        onClick={showEditForm}
                        className="customerCheckoutCardHeadRt"
                      >
                        <h2>
                          <img src={Edit} />
                          <span>{t("Edit.label")}</span>
                        </h2>
                      </div>
                    )}
                  {/* ### New Customer Code### */}
                </div>

                {/* ### New Customer Code### */}
                {userDetail &&
                  userDetail?.data &&
                  userDetail?.data?.role == "New Customer" && (
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>{t("customerName.label")}</label>
                        <input
                          disabled
                          type="text"
                          value={newCustomerDetails[0].value}
                          className="customerCheckoutCard-bgSecond"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-15">
                        <label>{t("CustomerId.label")}</label>
                        <input
                          disabled
                          type="text"
                          value={newCustomerDetails[1].value}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-15">
                        <label>{t("phoneno.label")}</label>
                        <input
                          type="text"
                          maxlength={10}
                          value={newCustomerDetails[2].value}
                          onChange={(e) => {
                            let temp = newCustomerDetails;
                            if (!isNull(e.target.value)) {
                              temp[2].value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              temp[2].error = false;
                              setNewCustomerDetails({ ...temp });
                            } else {
                              temp[2].value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              temp[2].error = true;
                              setNewCustomerDetails({ ...temp });
                            }
                          }}
                        />
                        {newCustomerDetails[2].error && (
                          <div className="invalid-feedback-tax">
                            {newCustomerDetails[2].errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-15">
                        <label>{t("Channel.label")}</label>
                        <div className="customerCheckoutCardDropDown">
                          <FormControl variant="standard" fullWidth>
                            <Select
                              variant="outline"
                              displayEmpty
                              value={newCustomerDetails[3].value}
                              onChange={(e) => {
                                let temp = newCustomerDetails;
                                if (!isNull(e.target.value)) {
                                  temp[3].value = e.target.value;
                                  temp[3].error = false;
                                  setNewCustomerDetails({ ...temp });
                                } else {
                                  temp[3].value = e.target.value;
                                  temp[3].error = true;
                                  setNewCustomerDetails({ ...temp });
                                }
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                              placeholder="Select channel">
                              <MenuItem
                                value={"food service"}
                                sx={{ fontSize: "20px", color: "#464B55" }}
                              >
                                Food Service
                              </MenuItem>
                              <MenuItem
                                value={"traditional trade"}
                                sx={{ fontSize: "20px", color: "#464B55" }}
                              >
                                Traditional Trade
                              </MenuItem>
                              <MenuItem
                                value={"industry"}
                                sx={{ fontSize: "20px", color: "#464B55" }}
                              >
                                Industry
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        {newCustomerDetails[3].error && (
                          <div className="invalid-feedback-tax">
                            {newCustomerDetails[3].errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                        <label>{t("Notes(Optional).label")}</label>
                        <input
                          type="textarea"
                          value={newCustomerDetails[4].value}
                          onChange={(e) => {
                            let temp = newCustomerDetails;
                            if (!isNull(e.target.value)) {
                              temp[4].value = e.target.value;
                              temp[4].error = false;
                              setNewCustomerDetails({ ...temp });
                            } else {
                              temp[4].value = e.target.value;
                              temp[4].error = false;
                              setNewCustomerDetails({ ...temp });
                            }
                          }}
                          maxlength="100"
                        />
                        {/* <textarea
                               value={newCustomerDetails[4].value}
                               onChange={(e) => {
                                 let temp = newCustomerDetails;
                                 if (!isNull(e.target.value)) {
                                   temp[4].value = e.target.value;
                                   temp[4].error = false;
                                   setNewCustomerDetails({ ...temp });
                                 } else {
                                   temp[4].value = e.target.value;
                                   temp[4].error = false;
                                   setNewCustomerDetails({ ...temp });
                                 }
                               }}
                               maxlength="100" /> */}
                        {newCustomerDetails[4].error && (
                          <div className="invalid-feedback-tax">
                            {newCustomerDetails[4].errorMessage}
                          </div>
                        )}
                        {/* <h6 className="customerCheckoutCard-ShippingAddress-textarea-counts marginTop-15">
                        0/100
                      </h6> */}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                        <FormControl variant="standard">
                          <label>{t("ShippingAddress.label")}</label>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            defaultValue={true}
                          // value={changeShippingAddress}
                          // onChange={handleShippingAddress}
                          >
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                <div className="row customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center">
                                  <div
                                    onClick={() => {
                                      setShowAddressWindowForNewCustomer(
                                        !showAddressWindowForNewCustomer
                                      );
                                      setNoAddressErrorNewCustomer(false);
                                    }}
                                    className="col-lg-11 col-md-10 col-sm-10 col-10"
                                  >
                                    <FormControlLabel
                                      value="Address3"
                                      control={<Radio />}
                                      label={
                                        isNull(newCustomerAddressId)
                                          ? t("AddnewAddress.label")
                                          : newCustomerAddress[0].value +
                                          " " +
                                          newCustomerAddress[1].value
                                            .provinceTH +
                                          " " +
                                          newCustomerAddress[2].value
                                            .districtTH +
                                          " " +
                                          newCustomerAddress[3].value
                                            .subDistrictTH +
                                          " " +
                                          newCustomerAddress[4].value
                                      }
                                    />
                                    {noAddressErrorNewCustomer && (
                                      <div className="invalid-feedback-tax">
                                        Customer address is required!
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {userDetail &&
                                userDetail.data &&
                                userDetail.data.address.map((item) => {
                                  return (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                      <div className="row customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center">
                                        <div
                                          onClick={() =>
                                            setShowAddressWindowForNewCustomer(
                                              !showAddressWindowForNewCustomer
                                            )
                                          }
                                          className="col-lg-11 col-md-10 col-sm-10 col-10"
                                        >
                                          <FormControlLabel
                                            value="Address3"
                                            control={<Radio />}
                                            label={item}
                                          />
                                        </div>
                                        <div className="col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-end">
                                          <img
                                            src={Edit}
                                            className="ShippingAdddressEdit"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                              {showAddressWindowForNewCustomer && (
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                  <div className="customerCheckoutCard-ShippingAddress">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      <label>{t("Address.label")}</label>
                                      <input
                                        type="text"
                                        name="address"
                                        value={newCustomerAddress[0].value}
                                        onChange={(e) => {
                                          let temp = newCustomerAddress;
                                          if (!isNull(e.target.value)) {
                                            temp[0].value = e.target.value;
                                            temp[0].error = false;
                                            setNewCustomerAddress({ ...temp });
                                          } else {
                                            temp[0].value = e.target.value;
                                            temp[0].error = true;
                                            setNewCustomerAddress({ ...temp });
                                          }
                                        }}
                                      // value={address}
                                      // onChange={handleChange}
                                      />
                                      {newCustomerAddress[0].error && (
                                        <div className="invalid-feedback-tax">
                                          {newCustomerAddress[0].errorMessage}
                                        </div>
                                      )}
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 marginTop-15">
                                          <label>Province</label>
                                          <Autocomplete
                                            disablePortal
                                            noOptionsText={""}
                                            id={Math.random()
                                              .toString(36)
                                              .substring(7)}
                                            options={provinceDdOptions}
                                            autoHighlight
                                            disableClearable
                                            popupIcon={<img src={arrowIcon} width={12} height={8} style={{ marginRight: "16px", marginTop: "12px", cursor: "pointer" }} />}
                                            getOptionLabel={(option) =>
                                              option.provinceTH || ""
                                            }
                                            value={newCustomerAddress[1].value}
                                            onChange={(e, selectedItem) => {
                                              handleOnchangeDd(
                                                selectedItem,
                                                "province"
                                              );
                                            }}
                                            renderOption={(props, option) => (
                                              <Box component="li" {...props}>
                                                <p className="checkout-screen-dropdown-options">{option.provinceTH}</p>
                                              </Box>
                                            )}
                                            renderInput={(params) => (
                                              <TextField
                                                error={
                                                  newCustomerAddress[1].error
                                                }
                                                helperText={
                                                  newCustomerAddress[1].error
                                                    ? newCustomerAddress[1]
                                                      .errorMessage
                                                    : ""
                                                }
                                                {...params}
                                                inputProps={{
                                                  ...params.inputProps,
                                                  // autoComplete: 'new-password',
                                                }}
                                              // label="Province"
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 marginTop-15">
                                          <label>District</label>
                                          <Autocomplete
                                            disablePortal
                                            noOptionsText={""}
                                            id={Math.random()
                                              .toString(36)
                                              .substring(7)}
                                            options={districtDdOptions}
                                            autoHighlight
                                            popupIcon={<img src={arrowIcon} width={12} height={8} style={{ marginRight: "16px", marginTop: "12px", cursor: "pointer" }} />}
                                            disableClearable
                                            getOptionLabel={(option) =>
                                              option.districtTH || ""
                                            }
                                            value={newCustomerAddress[2].value}
                                            onChange={(e, selectedItem) => {
                                              handleOnchangeDd(
                                                selectedItem,
                                                "district"
                                              );
                                            }}
                                            renderOption={(props, option) => (
                                              <Box component="li" {...props}>
                                                <p className="checkout-screen-dropdown-options">{option.districtTH}</p>
                                              </Box>
                                            )}
                                            renderInput={(params) => (
                                              <TextField
                                                error={
                                                  newCustomerAddress[2].error
                                                }
                                                helperText={
                                                  newCustomerAddress[2].error
                                                    ? newCustomerAddress[2]
                                                      .errorMessage
                                                    : ""
                                                }
                                                {...params}
                                                inputProps={{
                                                  ...params.inputProps,
                                                  // autoComplete: 'new-password', 
                                                }}
                                              // label="Province"
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 marginTop-15">
                                          <label>Sub-district</label>
                                          <Autocomplete
                                            disablePortal
                                            noOptionsText={""}
                                            id={Math.random()
                                              .toString(36)
                                              .substring(7)}
                                            options={subDistrictDdOptions}
                                            autoHighlight
                                            disableClearable
                                            popupIcon={<img src={arrowIcon} width={12} height={8} style={{ marginRight: "16px", marginTop: "12px", cursor: "pointer" }} />}
                                            getOptionLabel={(option) =>
                                              option.subDistrictTH || ""
                                            }
                                            value={newCustomerAddress[3].value}
                                            onChange={(e, selectedItem) => {
                                              handleOnchangeDd(
                                                selectedItem,
                                                "subdistrict"
                                              );
                                            }}
                                            renderOption={(props, option) => (
                                              <Box component="li" {...props}>
                                                <p className="checkout-screen-dropdown-options">{option.subDistrictTH}</p>
                                              </Box>
                                            )}
                                            renderInput={(params) => (
                                              <TextField
                                                error={
                                                  newCustomerAddress[3].error
                                                }
                                                helperText={
                                                  newCustomerAddress[3].error
                                                    ? newCustomerAddress[3]
                                                      .errorMessage
                                                    : ""
                                                }
                                                {...params}
                                                inputProps={{
                                                  ...params.inputProps,
                                                  // autoComplete: 'new-password',
                                                }}
                                              // label="Province"
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 marginTop-15">
                                          <label>{t("PostalCode.label")}</label>
                                          <input
                                            type="text"
                                            maxlength={5}
                                            value={newCustomerAddress[4].value}
                                            onChange={(e) => {
                                              let temp = newCustomerAddress;
                                              if (!isNull(e.target.value)) {
                                                temp[4].value =
                                                  e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  );
                                                temp[4].error = false;
                                                setNewCustomerAddress({
                                                  ...temp,
                                                });
                                              } else {
                                                temp[4].value =
                                                  e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  );
                                                temp[4].error = true;
                                                setNewCustomerAddress({
                                                  ...temp,
                                                });
                                              }
                                            }}
                                          />
                                          {newCustomerAddress[4].error && (
                                            <div className="invalid-feedback-tax">
                                              {
                                                newCustomerAddress[4]
                                                  .errorMessage
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                      <label>
                                        {t("Notes(Optional).label")}
                                      </label>
                                      <input
                                        type="textarea"
                                        value={newCustomerAddress[5].value}
                                        onChange={(e) => {
                                          let temp = newCustomerAddress;
                                          if (!isNull(e.target.value)) {
                                            temp[5].value = e.target.value;
                                            temp[5].error = false;
                                            setNewCustomerAddress({ ...temp });
                                          } else {
                                            temp[5].value = e.target.value;
                                            temp[5].error = true;
                                            setNewCustomerAddress({ ...temp });
                                          }
                                        }}
                                      />
                                      {/* <textarea
                                        value={newCustomerAddress[5].value}
                                        onChange={(e) => {
                                          let temp = newCustomerAddress;
                                          if (!isNull(e.target.value)) {
                                            temp[5].value = e.target.value;
                                            temp[5].error = false;
                                            setNewCustomerAddress({ ...temp });
                                          } else {
                                            temp[5].value = e.target.value;
                                            temp[5].error = true;
                                            setNewCustomerAddress({ ...temp });
                                          }
                                        }}
                                      /> */}
                                      <h6 className="customerCheckoutCard-ShippingAddress-textarea-counts">
                                        0/100
                                      </h6>
                                    </div>
                                    {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <FormGroup>
                                      <FormControlLabel
                                        control={<Checkbox defaultChecked />}
                                        label={t("Label.label")}
                                      />
                                    </FormGroup>
                                  </div> */}
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                                      <div className="customerCheckoutCardBtn">
                                        <button
                                          onClick={handleCancelEvent}
                                          className="customerCheckoutCardCancelBtn"
                                          data-test-id="cancel-button-heading"
                                        >
                                          {/* <img src={Delete} /> */}
                                          {t("Cancel.label")}
                                        </button>
                                        <button
                                          type="button"
                                          onClick={
                                            saveAndUpdateNewCustomerAddress
                                            // saveShippingAddressForNewAddress
                                          }
                                          className="customerCheckoutCardSaveBtn"
                                        >
                                          {t("Usethisaddress.label")}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  )}
                {/* ### New Customer Code### */}

                {userDetail &&
                  userDetail.data &&
                  userDetail.data.role != "New Customer" && (
                    <div className="row">
                      {hidePersonalInfo && (
                        <>
                          <div className="col-lg-3 col-md-5 col-sm-4 col-5">
                            <p>{t("customerName.label")}</p>
                          </div>
                          {userDetail && userDetail.data && (
                            <div className="col-lg-9 col-md-7 col-sm-8 col-7">
                              {lancode === "en" && (
                                <h4>
                                  {userDetail.data.firstNameEN}{" "}
                                  {userDetail.data.lastNameEN}
                                </h4>
                              )}
                              {lancode === "th" && (
                                <h4>
                                  {userDetail.data.firstNameTH}{" "}
                                  {userDetail.data.lastNameTH}
                                </h4>
                              )}
                            </div>
                          )}
                          <div className="col-lg-3 col-md-5 col-sm-4 col-5">
                            <p>{t("CustomerId.label")}</p>
                          </div>
                          {userDetail && userDetail.data && (
                            <div className="col-lg-9 col-md-7 col-sm-8 col-7">
                              {/* customerCode */}
                              <h4>{userDetail.data.customerCode}</h4>
                            </div>
                          )}
                          <div className="col-lg-3 col-md-5 col-sm-4 col-5">
                            <p>{t("phoneno.label")}</p>
                          </div>
                          {userDetail && userDetail.data && (
                            <div className="col-lg-9 col-md-7 col-sm-8 col-7">
                              <h4>{userDetail.data.phone}</h4>
                            </div>
                          )}
                          {hideShippindAddress && (
                            <div className="col-lg-3 col-md-5 col-sm-4 col-5">
                              <p>{t("ShippingAddress.label")}</p>
                            </div>
                          )}
                          {userDetail && userDetail.data && (
                            <div className="col-lg-9 col-md-7 col-sm-8 col-7">
                              {/* {hideShippindAddress &&
                            userDetail &&
                            userDetail.data &&
                            userDetail.data.address.length > 0 && (
                              <h4>
                                {shippingAddressLabel}
                              </h4>
                            )} */}
                              {hideShippindAddress && selectedAddressValue ? (
                                <h4>{selectedAddressValue}</h4>
                              ) : (
                                <h4>
                                  {isShippingAddress == true
                                    ? defaultShippingAddress
                                    : shippingAddressLabel}
                                </h4>
                              )}
                            </div>
                          )}
                          {userDetails?.user?.role === "Saleshop" && (
                            <>
                              <div className="col-lg-3 col-md-5 col-sm-4 col-5">
                                <p>{t("Channel.label")}</p>
                              </div>
                              {userDetail && userDetail.data && (
                                <div className="col-lg-9 col-md-7 col-sm-8 col-7">
                                  {userDetails?.user?.role === "Saleshop" && (
                                    <h4>{userDetail.data.channel}</h4>
                                  )}
                                </div>
                              )}
                              <div className="col-lg-3 col-md-5 col-sm-4 col-5">
                                <p>{t("Note.label")}</p>
                              </div>
                              {customerOrderData.userNote && (
                                <div className="col-lg-9 col-md-7 col-sm-8 col-7">
                                  <h4 style={{ textTransform: "none" }}>
                                    {customerOrderData.userNote}
                                  </h4>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {editPersonalInfo && userDetail && userDetail.data && (
                        <>
                          {lancode === "en" && (
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <label>{t("customerName.label")}</label>
                              <input
                                disabled
                                type="text"
                                value={
                                  userDetail.data.firstNameEN +
                                  " " +
                                  userDetail.data.lastNameEN
                                }
                                className="customerCheckoutCard-bgSecond"
                              />
                            </div>
                          )}

                          {lancode === "th" && (
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                              <label>{t("Name.label")}</label>
                              <input
                                disabled
                                type="text"
                                value={
                                  userDetail.data.firstNameTH +
                                  " " +
                                  userDetail.data.lastNameTH
                                }
                                className="customerCheckoutCard-bgSecond"
                              />
                            </div>
                          )}

                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                            <label>{t("CustomerId.label")}</label>
                            <input
                              disabled
                              type="text"
                              value={userDetail.data.customerCode}
                              className="customerCheckoutCard-bgSecond"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                            <label>{t("phoneno.label")}</label>
                            <input
                              type="text"
                              disabled
                              value={userDetail.data.phone}
                              className="customerCheckoutCard-bgSecond"
                            />
                          </div>

                          {userDetails?.user?.role === "Saleshop" && (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                <label>{t("Channel.label")}</label>
                                <FormControl fullWidth sx={{ fontSize: "20px", color: "#464B55", backgroundColor: "#EDEFF2" }}>
                                  <Select
                                    disabled
                                    displayEmpty
                                    value={userDetail.data.channel}
                                    onChange={handleCustomerSubDistrict}
                                    inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                    placeholder="Select District"
                                    sx={{ fontSize: "20px", color: "#464B55", backgroundColor: "#EDEFF2" }}
                                  >
                                    <MenuItem
                                      value={userDetail.data.channel}
                                    >
                                      {userDetail.data.channel}
                                    </MenuItem>
                                    {/* <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem> */}
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                                <label>{t("Notes(Optional).label")}</label>
                                <input
                                  name="userNote"
                                  type="textarea"
                                  value={customerOrderData.userNote}
                                  onChange={handleInputChange}
                                  maxlength="100"
                                />
                                {/* <textarea
                                  name="userNote"
                                  value={customerOrderData.userNote}
                                  onChange={handleInputChange}
                                  maxlength="100"
                                /> */}
                                <h6 className="createOrderCard-editAddress-textarea-counts">
                                  {customerOrderData.userNote.length
                                    ? customerOrderData.userNote.length
                                    : "0"}
                                  /100
                                </h6>
                                {/* <h6 className="customerCheckoutCard-ShippingAddress-textarea-counts">
                                  0/100
                                </h6> */}
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {/* forms for shipping address */}
                      {editPersonalInfo &&
                        userDetail &&
                        userDetail.data &&
                        userDetail.data.address && (
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <FormControl>
                              <label>{t("ShippingAddress.label")}</label>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={selectedAddressValue}
                                onChange={handleShippingAddress}
                              >
                                <div className="row">
                                  {editPersonalInfo1 &&
                                    userDetail &&
                                    userDetail.data &&
                                    userDetail.data.address.length > 0 && (
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                        <div
                                          //  className="row customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center"
                                          className={`${shippingAddressLabel == selectedAddressValue ? 'bulkUploadCardRadio-focus' : ''} row   customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center`}
                                        >
                                          <div className="col-lg-11 col-md-10 col-sm-10 col-10">
                                            <FormControlLabel
                                              name="firstAddress"
                                              value={shippingAddressLabel}
                                              control={<Radio defaultChecked />}
                                              onClick={() =>
                                                handleLabelClick(
                                                  userDetail.data.address[0]
                                                    .userAddressId
                                                )
                                              }
                                              label={
                                                lancode === "en"
                                                  ? shippingAddressLabel
                                                  : shippingAddressLabel
                                              }
                                            />
                                          </div>
                                          <div className="col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-end">
                                            <img
                                              onClick={editShippingAddressForm}
                                              src={Edit}
                                              className="ShippingAdddressEdit"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  {showShippingAddress &&
                                    userDetail &&
                                    userDetail.data &&
                                    userDetail.data.address.length > 0 && (
                                      <form onSubmit={saveUserAddressInfo}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                          <div className="customerCheckoutCard-ShippingAddress">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                              <label>
                                                {t("Address.label")}
                                              </label>
                                              <input
                                                type="text"
                                                name="address"
                                                value={address}
                                                // value={ lancode === "en"
                                                // ? shipAddressLabelEN
                                                // : shipAddressLabelTH}
                                                onChange={handleChange}
                                              />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("Province.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  name="province"
                                                  value={province}
                                                  onChange={
                                                    handleCustomerProvince
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select Customer Province"
                                                >
                                                  {/* <MenuItem value={province}>
                                                    {province}
                                                  </MenuItem> */}
                                                  {postalData &&
                                                    postalData.province &&
                                                    postalData.province.map(
                                                      (pc, index) => (
                                                        <MenuItem
                                                          value={pc.provinceTH}
                                                          key={`province1-${index}`}
                                                        >
                                                          {pc.provinceTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                        Select Customer Province
                                      </MenuItem>
                                      <MenuItem value="ten">Ten</MenuItem>
                                      <MenuItem value="Twenty">Twenty</MenuItem>
                                      <MenuItem value="Thirty">Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("District.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  name="customerDistrict"
                                                  value={customerDistrict}
                                                  onChange={
                                                    handleCustomerDistrict
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select District"
                                                >
                                                  {/* <MenuItem
                                                    value={customerDistrict}
                                                  >
                                                    {customerDistrict}
                                                  </MenuItem> */}
                                                  {postalData &&
                                                    postalData.district &&
                                                    postalData.district.map(
                                                      (dc, index) => (
                                                        <MenuItem
                                                          key={`district1-${index}`}
                                                          value={dc.districtTH}
                                                        >
                                                          {dc.districtTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                        Select District
                                      </MenuItem>
                                      <MenuItem value={10}>Ten</MenuItem>
                                      <MenuItem value={20}>Twenty</MenuItem>
                                      <MenuItem value={30}>Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("Sub-district.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  name="customerSubDistrict"
                                                  displayEmpty
                                                  value={customerSubDistrict}
                                                  onChange={
                                                    handleCustomerSubDistrict
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select District"
                                                >
                                                  {/* <MenuItem
                                                    value={customerSubDistrict}
                                                  >
                                                    {customerSubDistrict}
                                                  </MenuItem> */}
                                                  {/* subDistrict disabled={!subDistrict.length}*/}
                                                  {postalData &&
                                                    postalData.subDistrict &&
                                                    postalData.subDistrict.map(
                                                      (sd, index) => (
                                                        <MenuItem
                                                          key={`subdistrict1-${index}`}
                                                          value={
                                                            sd.subDistrictTH
                                                          }
                                                        >
                                                          {sd.subDistrictTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                        Select Sub District
                                      </MenuItem>
                                      <MenuItem value={10}>Ten</MenuItem>
                                      <MenuItem value={20}>Twenty</MenuItem>
                                      <MenuItem value={30}>Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("PostalCode.label")}
                                              </label>
                                              <input
                                                type="text"
                                                name="postalCode1"
                                                maxlength={5}
                                                value={
                                                  postalCode
                                                }
                                              // postalData && postalData.postalCode ? postalData.postalCode.postalCodeTH :
                                              />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                                              <label>
                                                {t("Notes(Optional).label")}
                                              </label>
                                              <input
                                                type="textarea"
                                                name="userNotes1"
                                                value={userNote}
                                                onChange={handleChange}
                                                maxlength="100"
                                              />
                                              {/* <textarea
                                              name="userNotes1"
                                              maxlength='100'
                                              value={userNote}
                                              onChange={handleChange}
                                            /> */}
                                              <h6 className="createOrderCard-editAddress-textarea-counts">
                                                {!isNull(userNote)
                                                  ? userNote.length
                                                  : "0"}
                                                /100
                                              </h6>
                                              {/* <h6 className="customerCheckoutCard-ShippingAddress-textarea-counts">
                                              0/100
                                            </h6> */}
                                            </div>
                                            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <FormGroup>
                                              <FormControlLabel
                                                name="label1"
                                                control={<Checkbox />}
                                                label="Use shipping address for invoice"
                                                onChange={handleChange}
                                              />
                                            </FormGroup>
                                            </div> */}
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                checked={isCheckedForadd1}
                                                onChange={handleChange}
                                                name="label1"
                                                className="m-0 mr-2"
                                              />
                                              <label className="mt-0">
                                                {/* Use shipping address for invoice */}
                                                {t(
                                                  "Usetheshippingaddressfortaxinvoices.label"
                                                )}
                                              </label>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                                              <div className="customerCheckoutCardBtn">
                                                {userDetail &&
                                                  userDetail.data &&
                                                  userDetail.data.address[0]
                                                    .isShippingAddress ===
                                                  false && (
                                                    <button
                                                      onClick={() =>
                                                        handleDelete(
                                                          userDetail.data
                                                            .address[0]
                                                            .userAddressId
                                                        )
                                                      }
                                                      className="customerCheckoutCardCancelBtn"
                                                    >
                                                      <img src={Delete} />
                                                      {t("Delete.label")}
                                                    </button>
                                                  )}
                                                <button
                                                  type="submit"
                                                  disabled={
                                                    !address ||
                                                    !province ||
                                                    !customerDistrict ||
                                                    !customerSubDistrict
                                                  }
                                                  // onClick={saveUserAddressInfo}
                                                  className={
                                                    !address ||
                                                      !province ||
                                                      !customerDistrict ||
                                                      !customerSubDistrict
                                                      ? "customerCheckoutCardSaveBtnDisable"
                                                      : "customerCheckoutCardSaveBtn"
                                                  }
                                                >
                                                  {t("SaveChanges.label")}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    )}

                                  {editPersonalInfo2 &&
                                    userDetail &&
                                    userDetail.data &&
                                    userDetail.data.address.length > 1 && (
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                        <div
                                          // className="row customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center"
                                          className={`${shippingAddressLabelTH2 == selectedAddressValue ? 'bulkUploadCardRadio-focus' : ''} row customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center`}
                                        >
                                          <div className="col-lg-11 col-md-10 col-sm-10 col-10">
                                            <FormControlLabel
                                              name="secondAddress"
                                              value={shippingAddressLabelTH2}
                                              control={<Radio />}
                                              onClick={() =>
                                                handleLabelClick(
                                                  userDetail.data.address[1]
                                                    .userAddressId
                                                )
                                              }
                                              label={shippingAddressLabelTH2}
                                            />
                                          </div>
                                          <div className="col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-end">
                                            <img
                                              onClick={editShippingAddressForm2}
                                              src={Edit}
                                              className="ShippingAdddressEdit"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  {showShippingAddress2 &&
                                    userDetail &&
                                    userDetail.data &&
                                    userDetail.data.address.length > 1 && (
                                      <form onSubmit={saveUserAddressInfo2}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                          <div className="customerCheckoutCard-ShippingAddress">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                              <label>
                                                {t("Address.label")}
                                              </label>
                                              <input
                                                type="text"
                                                name="address2"
                                                value={address2}
                                                // value={ lancode === "en"
                                                // ? shipAddressLabelEN
                                                // : shipAddressLabelTH}
                                                onChange={handleChange}
                                              />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("Province.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  name="province2"
                                                  value={province2}
                                                  onChange={
                                                    handleCustomerProvince
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select Customer Province"
                                                >
                                                  {/* <MenuItem value={province2}>
                                                    {province2}
                                                  </MenuItem> */}
                                                  {postalData &&
                                                    postalData.province &&
                                                    postalData.province.map(
                                                      (pc, index) => (
                                                        <MenuItem
                                                          value={pc.provinceTH}
                                                          key={`province2-${index}`}
                                                        >
                                                          {pc.provinceTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                        Select Customer Province
                                      </MenuItem>
                                      <MenuItem value="ten">Ten</MenuItem>
                                      <MenuItem value="Twenty">Twenty</MenuItem>
                                      <MenuItem value="Thirty">Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("District.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  name="customerDistrict2"
                                                  value={customerDistrict2}
                                                  onChange={
                                                    handleCustomerDistrict
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select District"
                                                >
                                                  {/* <MenuItem
                                                    value={customerDistrict2}
                                                  >
                                                    {customerDistrict2}
                                                  </MenuItem> */}
                                                  {postalData &&
                                                    postalData.district &&
                                                    postalData.district.map(
                                                      (dc, index) => (
                                                        <MenuItem
                                                          key={`district2-${index}`}
                                                          value={dc.districtTH}
                                                        >
                                                          {dc.districtTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                        Select District
                                      </MenuItem>
                                      <MenuItem value={10}>Ten</MenuItem>
                                      <MenuItem value={20}>Twenty</MenuItem>
                                      <MenuItem value={30}>Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("Sub-district.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  displayEmpty
                                                  name="customerSubDistrict2"
                                                  value={customerSubDistrict2}
                                                  onChange={
                                                    handleCustomerSubDistrict
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select District"
                                                >
                                                  {/* <MenuItem
                                                    value={customerSubDistrict2}
                                                  >
                                                    {customerSubDistrict2}
                                                  </MenuItem> */}
                                                  {/* subDistrict disabled={!subDistrict.length}*/}
                                                  {postalData &&
                                                    postalData.subDistrict &&
                                                    postalData.subDistrict.map(
                                                      (sd, index) => (
                                                        <MenuItem
                                                          key={`subdistrict2-${index}`}
                                                          value={
                                                            sd.subDistrictTH
                                                          }
                                                        >
                                                          {sd.subDistrictTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                        Select Sub District
                                      </MenuItem>
                                      <MenuItem value={10}>Ten</MenuItem>
                                      <MenuItem value={20}>Twenty</MenuItem>
                                      <MenuItem value={30}>Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>Postal code</label>
                                              <input
                                                type="text"
                                                maxlength={5}
                                                value={
                                                  postalCode2
                                                }
                                              // postalData && postalData.postalCode ? postalData.postalCode.postalCodeTH :
                                              />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                                              <label>
                                                {t("Notes(Optional).label")}
                                              </label>
                                              <input
                                                type="textarea"
                                                name="userNotes2"
                                                value={userNote2}
                                                onChange={handleChange}
                                                maxlength="100"
                                              />
                                              {/* <textarea 
                                            name="userNotes2"
                                            maxlength='100'
                                            value={userNote2}
                                            onChange={handleChange}
                                            /> */}
                                              <h6 className="createOrderCard-editAddress-textarea-counts">
                                                {!isNull(userNote2)
                                                  ? userNote2.length
                                                  : "0"}
                                                /100
                                              </h6>
                                            </div>
                                            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                                            <FormGroup>
                                              <FormControlLabel
                                                name="label2"
                                                control={<Checkbox />}
                                                label="Use shipping address for invoice"
                                                onChange={handleChange}
                                              />
                                            </FormGroup>
                                          </div> */}
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                checked={isCheckedForadd2}
                                                onChange={handleChange}
                                                name="label2"
                                                className="m-0 mr-2"
                                              />
                                              <label>
                                                Use shipping address for invoice
                                              </label>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                                              <div className="customerCheckoutCardBtn">
                                                {userDetail &&
                                                  userDetail.data &&
                                                  userDetail.data.address[1]
                                                    .isShippingAddress ===
                                                  false && (
                                                    <button
                                                      onClick={() => {
                                                        if (userDetail.data
                                                          .address[2] !== null) {
                                                          handleDelete(
                                                            userDetail.data
                                                              .address[1]
                                                              .userAddressId, true
                                                          )
                                                        }
                                                        else {
                                                          handleDelete(
                                                            userDetail.data
                                                              .address[1]
                                                              .userAddressId
                                                          )
                                                        }
                                                      }
                                                      }
                                                      className="customerCheckoutCardCancelBtn"
                                                    >
                                                      <img src={Delete} />
                                                      {t("Delete.label")}
                                                    </button>
                                                  )}
                                                <button
                                                  type="submit"
                                                  // onClick={saveUserAddressInfo2}
                                                  disabled={
                                                    !address2 ||
                                                    !province2 ||
                                                    !customerDistrict2 ||
                                                    !customerSubDistrict2
                                                  }
                                                  // onClick={saveUserAddressInfo}
                                                  className={
                                                    !address2 ||
                                                      !province2 ||
                                                      !customerDistrict2 ||
                                                      !customerSubDistrict2
                                                      ? "customerCheckoutCardSaveBtnDisable"
                                                      : "customerCheckoutCardSaveBtn"
                                                  }
                                                >
                                                  {t("SaveChanges.label")}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    )}

                                  {/* third address form */}

                                  {editPersonalInfo4 &&
                                    userDetail &&
                                    userDetail.data &&
                                    userDetail.data.address.length > 2 && (
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                        <div
                                          className={`${shippingAddressLabelTH3 == selectedAddressValue ? 'bulkUploadCardRadio-focus' : ''} row customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center`}
                                        // className="row customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center"
                                        >
                                          <div className="col-lg-11 col-md-10 col-sm-10 col-10">
                                            <FormControlLabel
                                              name="secondAddress"
                                              value={shippingAddressLabelTH3}
                                              control={<Radio />}
                                              onClick={() =>
                                                handleLabelClick(
                                                  userDetail.data.address[2]
                                                    .userAddressId
                                                )
                                              }
                                              label={shippingAddressLabelTH3}
                                            />
                                          </div>
                                          <div className="col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-end">
                                            <img
                                              onClick={editShippingAddressForm4}
                                              src={Edit}
                                              className="ShippingAdddressEdit"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  {showShippingAddress4 &&
                                    userDetail &&
                                    userDetail.data &&
                                    userDetail.data.address.length > 2 && (
                                      <form onSubmit={saveUserAddressInfo4}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                          <div className="customerCheckoutCard-ShippingAddress">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                              <label>
                                                {t("Address.label")}
                                              </label>
                                              <input
                                                type="text"
                                                name="address4"
                                                value={address4}
                                                // value={ lancode === "en"
                                                // ? shipAddressLabelEN
                                                // : shipAddressLabelTH}
                                                onChange={handleChange}
                                              />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("Province.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  name="province4"
                                                  value={province4}
                                                  onChange={
                                                    handleCustomerProvince
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select Customer Province"
                                                >
                                                  {/* <MenuItem value={province4}>
                                                    {province4}
                                                  </MenuItem> */}
                                                  {postalData &&
                                                    postalData.province &&
                                                    postalData.province.map(
                                                      (pc, index) => (
                                                        <MenuItem
                                                          value={pc.provinceTH}
                                                          key={index}
                                                        >
                                                          {pc.provinceTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                          Select Customer Province
                                        </MenuItem>
                                        <MenuItem value="ten">Ten</MenuItem>
                                        <MenuItem value="Twenty">Twenty</MenuItem>
                                        <MenuItem value="Thirty">Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("District.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  name="customerDistrict4"
                                                  value={customerDistrict4}
                                                  onChange={
                                                    handleCustomerDistrict
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select District"
                                                >
                                                  {/* <MenuItem
                                                    value={customerDistrict4}
                                                  >
                                                    {customerDistrict4}
                                                  </MenuItem> */}
                                                  {postalData &&
                                                    postalData.district &&
                                                    postalData.district.map(
                                                      (dc, index) => (
                                                        <MenuItem
                                                          key={index}
                                                          value={dc.districtTH}
                                                        >
                                                          {dc.districtTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                          Select District
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>
                                                {t("Sub-district.label")}
                                              </label>
                                              <FormControl fullWidth>
                                                <Select
                                                  name="customerSubDistrict4"
                                                  displayEmpty
                                                  value={customerSubDistrict4}
                                                  onChange={
                                                    handleCustomerSubDistrict
                                                  }
                                                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                  placeholder="Select District"
                                                >
                                                  {/* <MenuItem
                                                    value={customerSubDistrict4}
                                                  >
                                                    {customerSubDistrict4}
                                                  </MenuItem> */}
                                                  {/* subDistrict disabled={!subDistrict.length}*/}
                                                  {postalData &&
                                                    postalData.subDistrict &&
                                                    postalData.subDistrict.map(
                                                      (sd, index) => (
                                                        <MenuItem
                                                          key={index}
                                                          value={
                                                            sd.subDistrictTH
                                                          }
                                                        >
                                                          {sd.subDistrictTH}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  {/* <MenuItem value="">
                                          Select Sub District
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem> */}
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                              <label>Postal code</label>
                                              <input
                                                type="text"
                                                maxlength={5}
                                                value={
                                                  postalCode4
                                                }
                                              // postalData && postalData.postalCode ? postalData.postalCode.postalCodeTH :
                                              />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                                              <label>
                                                {t("Notes(Optional).label")}
                                              </label>
                                              <input
                                                type="textarea"
                                                name="userNotes4"
                                                value={userNote4}
                                                onChange={handleChange}
                                                maxlength="100"
                                              />
                                              {/* <textarea 
                                              name="userNotes4"
                                              maxlength='100'
                                              value={userNote4}
                                              onChange={handleChange}
                                              /> */}
                                              <h6 className="createOrderCard-editAddress-textarea-counts">
                                                {!isNull(userNote4)
                                                  ? userNote4.length
                                                  : "0"}
                                                /100
                                              </h6>
                                            </div>
                                            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                                              <FormGroup>
                                                <FormControlLabel
                                                  name="label2"
                                                  control={<Checkbox />}
                                                  label="Use shipping address for invoice"
                                                  onChange={handleChange}
                                                />
                                              </FormGroup>
                                            </div> */}
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                name="label3"
                                                checked={isCheckedForadd3}
                                                onChange={handleChange}
                                                className="m-0 mr-2"
                                              />
                                              <label>
                                                Use shipping address for invoice
                                              </label>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                                              <div className="customerCheckoutCardBtn">
                                                {userDetail &&
                                                  userDetail.data &&
                                                  userDetail.data.address[2]
                                                    .isShippingAddress ===
                                                  false && (
                                                    <button
                                                      onClick={() =>
                                                        handleDelete(
                                                          userDetail.data
                                                            .address[2]
                                                            .userAddressId
                                                        )
                                                      }
                                                      className="customerCheckoutCardCancelBtn"
                                                    >
                                                      <img src={Delete} />
                                                      {t("Delete.label")}
                                                    </button>
                                                  )}
                                                <button
                                                  type="submit"
                                                  // onClick={saveUserAddressInfo4}
                                                  disabled={
                                                    !address4 ||
                                                    !province4 ||
                                                    !customerDistrict4 ||
                                                    !customerSubDistrict4
                                                  }
                                                  // onClick={saveUserAddressInfo}
                                                  className={
                                                    !address4 ||
                                                      !province4 ||
                                                      !customerDistrict4 ||
                                                      !customerSubDistrict4
                                                      ? "customerCheckoutCardSaveBtnDisable"
                                                      : "customerCheckoutCardSaveBtn"
                                                  }
                                                >
                                                  {t("SaveChanges.label")}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    )}

                                  {/*  */}
                                  {userDetail &&
                                    userDetail.data &&
                                    userDetail.data.address.length < 3 && (
                                      <>
                                        {editPersonalInfo3 && (
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                            <div className="row customerCheckoutCardGreyBorderedLabel d-flex flex-wrap align-items-center">
                                              <div
                                                onClick={
                                                  editShippingAddressForm3
                                                }
                                                className="col-lg-11 col-md-10 col-sm-10 col-10"
                                              >
                                                <FormControlLabel
                                                  disabled
                                                  value=""
                                                  name="thirdAddress"
                                                  // type="radio"
                                                  control={<Radio />}
                                                  label={t(
                                                    "AddnewAddress.label"
                                                  )}
                                                />
                                                {/* <label htmlFor="address1" className="ml-2">
                                                  Add new address
                                                </label> */}
                                              </div>
                                              {/* <div className="col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-end">
                                                <img
                                                  onClick={() =>
                                                    setShowShippingAddress3(true)
                                                  }
                                                  src={Edit}
                                                  className="ShippingAdddressEdit"
                                                />
                                              </div> */}
                                            </div>
                                          </div>
                                        )}

                                        {showShippingAddress3 && (
                                          <form
                                            onSubmit={
                                              saveShippingAddressForNewAddress
                                            }
                                          >
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-15">
                                              <div className="customerCheckoutCard-ShippingAddress">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                  <label>
                                                    {t("Address.label")}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    name="address3"
                                                    value={address3}
                                                    onChange={handleChange}
                                                  />
                                                  {/* {!address3 && (
                                                    <div className="invalid-feedback-tax">
                                                      "Please fill the required field!"
                                                    </div>
                                                  )} */}
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                                  <label>
                                                    {t("Province.label")}
                                                  </label>
                                                  <FormControl fullWidth>
                                                    <Select
                                                      displayEmpty
                                                      name="province3"
                                                      value={province3}
                                                      onChange={
                                                        handleCustomerProvince
                                                      }
                                                      inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                      placeholder="Select Customer Province"
                                                    >
                                                      {/* <MenuItem value=""> </MenuItem> */}
                                                      {postalData &&
                                                        postalData.province &&
                                                        postalData.province.map(
                                                          (pc, index) => (
                                                            <MenuItem
                                                              value={
                                                                pc.provinceTH
                                                              }
                                                              key={index}
                                                            >
                                                              {pc.provinceTH}
                                                            </MenuItem>
                                                          )
                                                        )}
                                                      {/* <MenuItem value="">
                                        Select Customer Province
                                      </MenuItem>
                                      <MenuItem value="ten">Ten</MenuItem>
                                      <MenuItem value="Twenty">Twenty</MenuItem>
                                      <MenuItem value="Thirty">Thirty</MenuItem> */}
                                                    </Select>
                                                    {/* {!province3 && (
                                                      // <FormHelperText>"Please fill the required field!"</FormHelperText>
                                                      <div className="invalid-feedback-tax">
                                                        "Please fill the required
                                                        field!"
                                                      </div>
                                                    )} */}
                                                  </FormControl>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                                  <label>
                                                    {t("District.label")}
                                                  </label>
                                                  <FormControl fullWidth>
                                                    <Select
                                                      name="customerDistrict3"
                                                      value={customerDistrict3}
                                                      onChange={
                                                        handleCustomerDistrict
                                                      }
                                                      inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                      placeholder="Select District"
                                                    >
                                                      {postalData &&
                                                        postalData.district &&
                                                        postalData.district.map(
                                                          (dc, index) => (
                                                            <MenuItem
                                                              key={index}
                                                              value={
                                                                dc.districtTH
                                                              }
                                                            >
                                                              {dc.districtTH}
                                                            </MenuItem>
                                                          )
                                                        )}
                                                    </Select>
                                                    {/* {!customerDistrict3 && (
                                                      <div className="invalid-feedback-tax">
                                                        "Please fill the required
                                                        field!"
                                                      </div>
                                                    )} */}
                                                  </FormControl>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                                  <label>
                                                    {t("Sub-district.label")}
                                                  </label>
                                                  <FormControl fullWidth>
                                                    <Select
                                                      displayEmpty
                                                      name="customerSubDistrict3"
                                                      value={
                                                        customerSubDistrict3
                                                      }
                                                      onChange={
                                                        handleCustomerSubDistrict
                                                      }
                                                      inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                                                      placeholder="Select District"
                                                    >
                                                      {/* subDistrict disabled={!subDistrict.length}*/}
                                                      {postalData &&
                                                        postalData.subDistrict &&
                                                        postalData.subDistrict.map(
                                                          (sd, index) => (
                                                            <MenuItem
                                                              key={index}
                                                              value={
                                                                sd.subDistrictTH
                                                              }
                                                            >
                                                              {sd.subDistrictTH}
                                                            </MenuItem>
                                                          )
                                                        )}
                                                    </Select>
                                                    {/* {!customerSubDistrict3 && (
                                                      <div className="invalid-feedback-tax">
                                                        "Please fill the required
                                                        field!"
                                                      </div>
                                                    )} */}
                                                  </FormControl>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                                                  <label>
                                                    {t("PostalCode.label")}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    maxlength={5}
                                                    value={
                                                      postalCode3
                                                    }
                                                  // postalData && postalData.postalCode ? postalData.postalCode.postalCodeTH :
                                                  />
                                                  {/* {!postalCode3 ||
                                                    (!postalData.postalCode &&
                                                      postalData.postalCode
                                                        .postalCodeTH && (
                                                        <div className="invalid-feedback-tax">
                                                          "Please fill the
                                                          required field!"
                                                        </div>
                                                      ))} */}
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                                                  <label>
                                                    {t("Notes(Optional).label")}
                                                  </label>
                                                  <input
                                                    type="textarea"
                                                    name="userNotes3"
                                                    value={userNote3}
                                                    onChange={handleChange}
                                                    maxlength="100"
                                                  />
                                                  {/* <textarea 
                                                  name="userNotes3"
                                                  maxlength='100'
                                                  value={userNote3}
                                                  onChange={handleChange}
                                                  /> */}
                                                  <h6 className="createOrderCard-editAddress-textarea-counts">
                                                    {!isNull(userNote3)
                                                      ? userNote3.length
                                                      : "0"}
                                                    /100
                                                  </h6>
                                                </div>
                                                {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                                                  <FormGroup>
                                                    <FormControlLabel
                                                      name="label3"
                                                      control={<Checkbox />}
                                                      label="Use shipping address for invoice"
                                                      onChange={handleChange}
                                                    />
                                                  </FormGroup>
                                                </div> */}
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                                  <input
                                                    type="checkbox"
                                                    onChange={handleChange}
                                                    name="label3"
                                                    className="m-0 mr-3"
                                                  />
                                                  <label>
                                                    Use shipping address for
                                                    invoice
                                                  </label>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                                                  <div className="customerCheckoutCardBtn">
                                                    <button
                                                      type="button"
                                                      onClick={(e) =>
                                                        updateShippindAddress3(
                                                          e
                                                        )
                                                      }
                                                      className="customerCheckoutCardCancelBtn"
                                                    >
                                                      {/* <img src={Delete} /> */}
                                                      {t("Cancel.label")}
                                                    </button>
                                                    <button
                                                      type="submit"
                                                      // onClick={
                                                      //   saveShippingAddressForNewAddress
                                                      // }
                                                      disabled={
                                                        !address3 ||
                                                        !province3 ||
                                                        !customerDistrict3 ||
                                                        !customerSubDistrict3
                                                      }
                                                      // onClick={saveUserAddressInfo}
                                                      className={
                                                        !address3 ||
                                                          !province3 ||
                                                          !customerDistrict3 ||
                                                          !customerSubDistrict3
                                                          ? "customerCheckoutCardSaveBtnDisable"
                                                          : "customerCheckoutCardSaveBtn"
                                                      }
                                                    // className="customerCheckoutCardSaveBtn"
                                                    >
                                                      {t(
                                                        "Usethisaddress.label"
                                                      )}
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        )}
                                      </>
                                    )}
                                </div>
                              </RadioGroup>
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20 p-0">
                                <div className="customerCheckoutCardBtn">
                                  <button
                                    onClick={saveShippingAddress}
                                    className="customerCheckoutCardSaveBtn customerCheckoutCardSaveBtn-xs"
                                  >
                                    {t("SaveChanges.label")}
                                  </button>
                                </div>
                              </div>
                            </FormControl>
                          </div>
                        )}
                      {/*  */}
                    </div>
                  )}
              </div>

              <div className="customerCheckoutCard">
                <div className="customerCheckoutCardHead">
                  <h3>
                    <img src={Shipment} />
                    {t("ShipmentDetails.label")}
                  </h3>
                </div>
                <div className="row">
                  {userDetail && userDetail.data && userDetail.data.stores && (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <label>{t("Deliveryfrom.label")}</label>
                      <h5>
                        <img src={Location} />
                        {/* {userDetail.data.stores[0].storeNameTH},{" "} */}
                        {userDetail.data.stores[0].addressTH},{" "}
                        {userDetail.data.stores[0].subDistrictTH},{" "}
                        {userDetail.data.stores[0].districtTH},{" "}
                        {userDetail.data.stores[0].provinceTH}{" "}
                        {userDetail.data.stores[0].postalCodeTH}
                        {/* แจ้งวัฒนะ - 236 ม.2 ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่
                    กทม. 10210 */}
                      </h5>
                    </div>
                  )}

                  {userDetails?.user?.role !== "Saleshop" && (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 hidden-sm ">
                      <label>{t("Deliverydate.label")}</label>
                      {/* <input
                        type="date"
                        id="date-input"
                        name="deliveryDate"
                        value={customerOrderData.deliveryDate}
                        onChange={handleInputChange}
                        min={todayDate}
                        max={nextDate}
                      /> */}
                      <div className="customerCheckoutCard-calendarDesktop">
                        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                        {/* <input type="text"
                        value={calendarDate}
                        onClick={() => setShowCalendar(true)}
                        onClick={() => setShowCalendar(true)}
                      /> */}
                        {/* <button
                          className="customerCheckoutCard-calendarDesktop-btn"
                          type="button"
                          value={tomorrowDate}
                        >
                          <img src={Calander} />
                          {tomorrowDate
                            ? tomorrowDate
                            : customerOrderData.deliveryDate}
                          <img src={ArrowGrey} style={{width: 12, height: 8}}></img>
                        </button> */}
                        <button
                          className="customerCheckoutCard-calendarDesktop-btn"
                          type="button"
                          value={calendarDate}
                          onClick={() => setShowCalendar(true)}
                        >
                          <img src={Calander} />
                          {customerOrderData.deliveryDate
                            ? customerOrderData.deliveryDate
                            : t("SelectDeliveryDate.label")}
                          <img src={ArrowGrey} style={{ width: 12, height: 8 }}></img>
                        </button>
                        {showCalendar ? (
                          <Calendar
                            className={showCalendar ? "" : "hide"}
                            value={calendarDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={handleInputChange}
                          // onClick={(event) => handleInputChange(event)}
                          // onChange={handleCalendarChange}
                          />
                        ) : null}
                        {/* {showCalendar ?
                          <Calendar
                            className={showCalendar ? "" : "hide"}
                            value={calendarDate}
                            minDate={maxDate}
                            maxDate={maxDate}
                            onChange={handleInputChange}
                            onClick={(event) => handleInputChange(event)}
                          
                          /> : null} */}
                      </div>
                    </div>
                  )}

                  {userDetails?.user?.role === "Saleshop" && (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 hidden-sm ">
                      <label>{t("Deliverydate.label")}</label>
                      {/* <input
                        type="date"
                        id="date-input"
                        name="deliveryDate"
                        value={customerOrderData.deliveryDate}
                        onChange={handleInputChange}
                        min={todayDate}
                        max={nextDate}
                      /> */}
                      <div className="customerCheckoutCard-calendarDesktop">
                        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                        {/* <input type="text"
                        value={calendarDate}
                        onClick={() => setShowCalendar(true)}
                      /> */}
                        {/* tomorrowDate */}
                        <button
                          className="customerCheckoutCard-calendarDesktop-btn"
                          type="button"
                          value={calendarDate}
                          onClick={() => setShowCalendar(true)}
                        >
                          <img src={Calander} />
                          {customerOrderData.deliveryDate
                            ? customerOrderData.deliveryDate
                            : t("SelectDeliveryDate.label")}
                          <img src={ArrowGrey} style={{ width: 12, height: 8 }}></img>
                        </button>
                        {showCalendar ? (
                          <Calendar
                            className={showCalendar ? "" : "hide"}
                            value={calendarDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={handleInputChange}
                          // onClick={(event) => handleInputChange(event)}
                          // onChange={handleCalendarChange}
                          />
                        ) : null}
                      </div>
                    </div>
                  )}

                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 customerCheckoutCard-select hidden-sm">
                    <label>{t("Deliverytime.label")}</label>
                    <div className="customerCheckoutCardDropDown">
                      <FormControl
                        variant="standard"
                        sx={{
                          width: "100%",
                          fontSize: "20px",
                          borderRadius: "8px",
                          backgroundColor: "#F5F6F7",
                          color: "#989CA6",
                        }}
                      >
                        <Select
                          variant="outlined"
                          displayEmpty
                          name="deliveryTime"
                          value={customerOrderData.deliveryTime}
                          onChange={handleInputChange}
                          inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ width: 12, height: 8, marginRight: "16px", cursor: "pointer" }} /> }}
                          sx={{
                            width: "100%",
                            fontSize: "20px",
                            borderRadius: "8px",
                            color: "#989CA6",
                            padding: "0",
                            height: "48px",
                            backgroundColor: "#F5F6F7",
                            border: 'none',
                            "& fieldset": { border: 'none' }
                          }}>
                          <MenuItem sx={{ fontSize: 'large' }} disabled value="">
                            {t("SelectDeliveryTime.label")}
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 'large' }} value={"9.00 - 12.00"}>
                            9.00 - 12.00
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 'large' }} value={"12.00 - 15.00"}>
                            12.00 - 15.00
                          </MenuItem>
                          <MenuItem sx={{ fontSize: 'large' }} value={"15.00 - 18.00"}>
                            15.00 - 18.00
                          </MenuItem>
                          {/* <MenuItem style={{ fontSize: "17" }} value={"18.00 - 21.00"}>
                          18.00 - 21.00
                        </MenuItem>
                        <MenuItem style={{ fontSize: "17" }} value={"21.00 - 01.00"}>
                          21.00 - 01.00
                        </MenuItem>
                        <MenuItem style={{ fontSize: "17" }} value={"01.00 - 05.00"}>
                          01.00 - 05.00
                        </MenuItem> */}
                        </Select>
                      </FormControl>
                      {deliveryTimeError && (
                        <div className="invalid-feedback-tax">
                          Please select delivery time!
                        </div>
                      )}
                    </div>
                  </div>
                  {userDetails?.user?.role !== "Saleshop" && (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 visible-sm">
                      <label>{t("Deliverydate.label")}</label>
                      <>
                        <button
                          type="button"
                          className="CheckoutDeliveryDate"
                          onClick={handleShowDate}
                        >
                          <img src={Calander} />
                          {customerOrderData.deliveryDate
                            ? customerOrderData.deliveryDate
                            : t("SelectDate.label")}
                          <img src={ArrowGrey} style={{ width: 12, height: 8 }} />
                        </button>
                        <Modal
                          show={showDate}
                          onHide={handleCloseDate}
                          className="customerCheckoutCard-mobModal"
                        >
                          {/* <Modal.Header closeButton>
                        </Modal.Header> */}
                          <Modal.Body>
                            <div className="customerCheckoutCard-mobModalCalander">
                              <Calendar
                                minDate={minDate}
                                maxDate={maxDate}
                                onChange={handleInputChange}
                                value={value}
                                activeStartDate={new Date()}
                              />
                            </div>
                          </Modal.Body>
                        </Modal>
                      </>
                    </div>
                  )}

                  {userDetails?.user?.role === "Saleshop" && (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 visible-sm">
                      <label>{t("Deliverydate.label")}</label>
                      <>
                        <button
                          type="button"
                          className="CheckoutDeliveryDate"
                          onClick={handleShowDate}
                        >
                          <img src={Calander} />
                          {customerOrderData.deliveryDate
                            ? customerOrderData.deliveryDate
                            : t("SelectDate.label")}
                          <img src={ArrowGrey} style={{ width: 12, height: 8 }} />
                        </button>
                        <Modal
                          show={showDate}
                          onHide={handleCloseDate}
                          className="customerCheckoutCard-mobModal"
                        >
                          {/* <Modal.Header closeButton>
                          </Modal.Header> */}
                          <Modal.Body>
                            <div className="customerCheckoutCard-mobModalCalander">
                              <Calendar
                                minDate={minDate}
                                maxDate={maxDate}
                                onChange={handleInputChange}
                                value={value}
                                activeStartDate={new Date()}
                              />
                            </div>
                          </Modal.Body>
                        </Modal>
                      </>
                    </div>
                  )}

                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 visible-sm">
                    <div className="">
                      <label>{t("Deliverytime.label")}</label>
                      <>
                        <button
                          type="button"
                          className="CheckoutDeliveryTime"
                          onClick={handleShowTime}
                        >
                          <img src={Watch} />
                          {customerOrderData.deliveryTime
                            ? customerOrderData.deliveryTime
                            : t("SelectTime.label")}
                          {/* Select Time */}
                          <img src={ArrowGrey} style={{ width: 12, height: 8 }} />
                        </button>
                        <Modal
                          show={showTime}
                          onHide={handleCloseTime}
                          className="customerCheckoutCard-mobModal"
                        >
                          {/* <Modal.Header closeButton>
                        </Modal.Header> */}
                          <Modal.Body>
                            <div className="customerCheckoutCard-mobModalCalander">
                              <ul>
                                {timeSlots.map((time) => (
                                  <li key={time}>
                                    <a onClick={() => handleSelection(time)}>
                                      {time}
                                    </a>
                                  </li>
                                ))}
                                {/* <li>
                                <a>9.00 - 12.00</a>
                              </li>
                              <li>
                                <a>12.00 - 15.00</a>
                              </li>
                              <li>
                                <a>15.00 - 18.00</a>
                              </li>
                              <li>
                                <a>18.00 - 21.00</a>
                              </li>
                              <li>
                                <a>21.00 - 1.00</a>
                              </li>
                              <li>
                                <a>1.00 - 5.00</a>
                              </li> */}
                              </ul>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="customerCheckoutCard">
                <div className="customerCheckoutCardHead">
                  <h3>
                    <img src={Payment} />
                    {t("PaymentText.label")}
                  </h3>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <FormControl variant="standard" sx={{ display: 'block!important' }}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="paymentMode"
                        value={customerOrderData.paymentMode}
                        onChange={handleInputChange}
                      >
                        <div className="row3">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12 marginBottom-10xs checkout-payment-section-main-container">
                            <FormControlLabel
                              value="Cash on delivery"
                              control={<Radio />}
                              label={<Typography sx={{ marginTop: '3px!important', fontSize: '20px!important', color: 'black!important' }}>{t("Cashondelivery.label")}
                              </Typography>}
                              className={
                                customerOrderData.paymentMode ==
                                  "Cash on delivery"
                                  ? "selected-payment"
                                  : "unselected-payment "
                              }
                              sx={{ display: "flex", alignItems: "center" }}
                            />
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12 checkout-payment-section-main-container">
                            <FormControlLabel
                              value="Bank transfer on delivery"
                              control={<Radio />}
                              label={<Typography sx={{ marginTop: '3px!important', fontSize: '20px!important', color: 'black!important' }}>
                                {t("Banktransferondelivery.label")}
                              </Typography>}
                              className={
                                customerOrderData.paymentMode ==
                                  "Bank transfer on delivery"
                                  ? "selected-payment"
                                  : "unselected-payment "
                              }
                              sx={{ display: "flex", alignItems: "center", width: '50%' }}

                            />
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>

                    {paymentMethodError && (
                      <div className="invalid-feedback-tax" data-test-id="please-select-payment-heading">
                        Please select payment method!
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <TaxInvoice
                addressForInvoice={addressForInvoice}
                userDetail={userDetail}
              />
              {showToast && (
                <ThankYouForPurchaseModal
                  open={showToast}
                  mainHeading={t("Savedraftmsg.label")}
                  subHeading1={
                    draftOrder && draftOrder.data && draftOrder.data.orderNo
                  }
                  // checkout={true}
                  // cart={false}
                  draft={true}
                  buttonLabel={t("DoneBtn.label")}
                  orderNumber={
                    draftOrder && draftOrder.data && draftOrder.data.orderNo
                  }
                  ClickAction={ClickAction}
                />
              )}
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12">
              <OrderSummary
                selecteddata={props?.location?.state}
                isFormValid={OrderDataValidation}
                handleConfrimOrder={handleSubmit}
                handleDraftOrder={handleDraftOrder}
                userDetails={userDetails}
                userDetail={userDetail}
                userId={props?.location?.state?.userId}
                newCustomerValid={newCustomerValid}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="footer-section hidden-sm">
        <Footer />
      </div>
    </>
  );
};

export { CustomerCheckout as CustomerCheckout };

