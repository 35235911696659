import FormControl from "@mui/material/FormControl";
import LinearProgress from "@mui/material/LinearProgress";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Axios from "axios";
import moment from "moment";
import React, { useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { HamburgerTableMenu } from "../OrderList/HamburgerTableMenu/HamburgerTableMenu";
import { CommonTableHeader } from "../OrderList/TableHeader/TableHeader";
import Loading from "../SharedComponent/Loader/Loader";
import {
  uploadfile
} from "../_actions";
import { REACT_APP_BASE_URL } from "../constant";
import gearImage from "../images/gear.png";
import Empty from "./Assets/Images/Empty.png";
import UploadFile from "./Assets/Images/UploadFile.png";
import CircleCheck from "./Assets/SVG/CircleCheck.svg";
import CrossGrey from "./Assets/SVG/CrossGrey.svg";
import MoneyBills from "./Assets/SVG/MoneyBills.svg";
import Reload from "./Assets/SVG/Reload.svg";
import "./MarkentingManagement.css";

const MarkentingManagement = (props) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);
  const hiddenFileInput = useRef(null);
  const hiddenFileInputprice = useRef(null);
  const hiddenFileInputspecialprice = useRef(null);
  const hiddenFileInputstock = useRef(null);
  const [filenameproduct, setFilenameproduct] = React.useState([]);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [filenamespecialprice, setFilenamespecialprice] = React.useState([]);
  const [filenameBanner, setFilenameBanner] = React.useState([]);
  const [filenameMobileBanner, setFilenameMobileBanner] = React.useState([]);
  const [filenamestock, setFilenamestock] = React.useState([]);
  const [wrongFileUploadproduct, setWrongFileUploadproduct] =
    React.useState(false);
  const [rightfileuploadedproduct, setRightfileuploadedproduct] =
    React.useState(false);
  const [rightfileuploadedproductsuccess, setRightfileuploadedproductsuccess] =
    React.useState(false);
  const [rightfileuploadedproductfailure, setRightfileuploadedproductfailure] =
    React.useState(false);
  const [rightfileuploadedprice, setRightfileuploadedprice] =
    React.useState(false);
  const [rightfileuploadedpricesuccess, setRightfileuploadedpricesuccess] =
    React.useState(false);
  const [rightfileuploadedpricefailure, setRightfileuploadedpricefailure] =
    React.useState(false);

  const [rightfileuploadedspecialprice, setRightfileuploadedspecialprice] =
    React.useState(false);
  const [
    rightfileuploadedspecialpricesuccess,
    setRightfileuploadedspecialpricesuccess,
  ] = React.useState(false);
  const [
    rightfileuploadedspecialpricefailure,
    setRightfileuploadedspecialpricefailure,
  ] = React.useState(false);

  const [rightfileuploadedstock, setRightfileuploadedstock] =
    React.useState(false);
  const [rightfileuploadedstocksuccess, setRightfileuploadedstocksuccess] =
    React.useState(false);
  const [rightfileuploadedstockfailure, setRightfileuploadedstockfailure] =
    React.useState(false);
  const [showprogressproduct, setShowprogressproduct] = React.useState(false);

  const [showprogressprice, setShowprogressprice] = React.useState(false);
  const [showprogressspecialprice, setShowprogressspecialprice] =
    React.useState(false);

  const [showprogressstock, setShowprogressstock] = React.useState(false);

  const [wrongFileUploadprice, setWrongFileUploadprice] = React.useState(false);
  const [wrongFileUploadspecialprice, setWrongFileUploadspecialprice] =
    React.useState(false);
  const [wrongFileUploadstock, setWrongFileUploadstock] = React.useState(false);
  const [progressproduct, setProgressproduct] = React.useState(5);
  const [progressprice, setProgressprice] = React.useState(5);
  const [progressspecialprice, setProgressspecialprice] = React.useState(5);
  const [progressstock, setProgressstock] = React.useState(5);
  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [tableData, setTableData] = React.useState([]);
  const [totalCountproduct, setTotalCountproduct] = React.useState(0);
  const [rowsPerPageproduct, setRowsPerPageproduct] = React.useState(5);
  const [pageproduct, setPageproduct] = React.useState(0);
  const [currentPageproduct, setCurrentPageproduct] = React.useState(1);
  const [currentPageBanner, setCurrentPageBanner] =
    React.useState(1);
  const [currentPageMobileBanner, setCurrentPageMobileBanner] =
    React.useState(1);
  const [tableDataBanner, setTableDataBanner] = React.useState([]);
  const [tableDataMobileBanner, setTableMobileBanner] = React.useState([]);
  const [totalCountBanner, setTotalCountBanner] = React.useState(0);
  const [totalCountMobileBanner, setTotalCountMobileBanner] = React.useState(0);
  const [totalCountprice, setTotalCountprice] = React.useState(0);
  const [reasonforfailureproduct, setReasonforfailureproduct] = React.useState("");
  const [reasonforfailureprice, setReasonforfailureprice] = React.useState("");
  const [reasonforfailurespecialprice, setReasonforfailurespecialprice] = React.useState("");
  const [reasonforfailurestock, setReasonforfailurestock] = React.useState("");

  const [reasonforsuccessproduct, setReasonforsuccesseproduct] = React.useState("");
  const [reasonforsuccessprice, setReasonforsuccessprice] = React.useState("");
  const [reasonforsuccessspecialprice, setReasonforsuccessspecialprice] = React.useState("");
  const [rowsPerPageBanner, setRowsPerPageBanner] = React.useState(5);
  const [rowsPerPageMobileBanner, setRowsPerPageMobileBanner] = React.useState(5)
  const [pageBanner, setPageBanner] = React.useState(0);
  const [pageMobileBanner, setPageMobileBanner] = React.useState(0);

  const [toggleValuePro, setToggleValuePro] = React.useState(false);
  const [togglePro, setTogglePro] = React.useState(true);
  const [toggleValuePri, setToggleValuePri] = React.useState(false);
  const [togglePri, setTogglePri] = React.useState(true);
  const [toggleValueSp, setToggleValueSp] = React.useState(false);
  const [toggleSp, setToggleSp] = React.useState(true);
  const [bannerType, setBannerType] = useState('PC')
  const getuploadfileList = useSelector(
    (state) => state.uploadfile.getuploadfileList
  );

  const getuploadfileBanner = useSelector(
    (state) => state.uploadfile.getuploadfileBannerList
  )
  const getuploadfileMobileBanner = useSelector(
    (state) => state.uploadfile.getuploadfileMobileList
  )
  const dispatch = useDispatch();

  const [dragActiveproduct, setDragActiveproduct] = React.useState(false);
  const [dragActiveprice, setDragActiveprice] = React.useState(false);
  const [dragActivespecialprice, setDragActivespecialprice] =
    React.useState(false);
  const [dragActivestock, setDragActivestock] = React.useState(false);
  const [showprogressBanner, setShowprogressBanner] = useState(false);
  const prevLocation = useLocation()
  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      localStorage.setItem('prevPath', prevLocation.pathname)
      history.push("/login");
    }
    if (userData) {
      let payload_product = {
        type: "Product",
      };
      let payload_price = {
        type: "Price",
      };
      let payload_special_price = {
        type: "Special Price",
      };
      let payload_stock = {
        type: "Stock",
      };
      let payload_banner = {
        type: "Desktop"
      }
      let payload_mobilebanner = {
        type: "Mobile"
      }
      let queryString_product = `?limit=${parseInt(
        rowsPerPageproduct
      )}&skip=${pageproduct}`;

      let queryString_Banner = `?limit=${parseInt(
        rowsPerPageBanner
      )}&skip=${pageBanner}`
      let queryString_Mobile = `?limit=${parseInt(
        rowsPerPageMobileBanner
      )}&skip=${pageMobileBanner}`
      dispatch(
        uploadfile.getuploadfilelistproduct(
          "",
          payload_product,
          queryString_product
        )
      );
      dispatch(
        uploadfile.getuploadFileListBanner("", payload_banner, queryString_Banner)
      );
      dispatch(
        uploadfile.getuploadFileListMobileBanner("", payload_mobilebanner, queryString_Mobile)
      );

      setLoading(false);
    }
  }, [pageproduct, pageBanner, pageMobileBanner, dataupdated]);

  React.useEffect(() => {
    setLoading(true);
    if (getuploadfileList?.data && getuploadfileList?.data.result.length > 0) {
      getuploadfileList?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != 'Invalid date') {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableData(getuploadfileList?.data.result);
      setLoading(false);
      setTotalCountproduct(getuploadfileList?.data.totalCount);
    } else {
      setTableData([]);
      setLoading(false);
      setTotalCountproduct(0);
    }

    if (getuploadfileBanner?.data && getuploadfileBanner?.data.result.length > 0) {
      getuploadfileBanner?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != 'Invalid date') {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableDataBanner(getuploadfileBanner?.data.result);
      setLoading(false);
      setTotalCountBanner(getuploadfileBanner?.data.totalCount);
    } else {
      setTableDataBanner([]);
      setLoading(false);
      setTotalCountBanner(0);
    }


    if (getuploadfileMobileBanner?.data && getuploadfileMobileBanner?.data.result.length > 0) {
      getuploadfileMobileBanner?.data.result.map((item) => {
        if (moment(item.updatedAt).format("DD/MM/YYYY") != 'Invalid date') {
          item.updatedAt = moment(item.updatedAt).format("DD/MM/YYYY");
        }
        return item;
      });
      setTableMobileBanner(getuploadfileMobileBanner?.data.result);
      setLoading(false);
      setTotalCountMobileBanner(getuploadfileMobileBanner?.data.totalCount);
    } else {
      setTableMobileBanner([]);
      setLoading(false);
      setTotalCountMobileBanner(0);
    }
  }, [
    getuploadfileList,
    getuploadfileBanner,
    getuploadfileMobileBanner,
    pageproduct,
    dataupdated,
  ]);

  const totalPagesproduct =
    tableData &&
    Math.ceil(parseInt(totalCountproduct) / parseInt(rowsPerPageproduct));

  const totalPageBanner =
    tableDataBanner &&
    Math.ceil(
      parseInt(totalCountBanner) / parseInt(rowsPerPageBanner)
    )
  const totalPageMobileBanner =
    tableDataMobileBanner &&
    Math.ceil(
      parseInt(totalCountMobileBanner) / parseInt(rowsPerPageMobileBanner)
    )

  React.useEffect(() => {
    const timerproduct = setInterval(() => {
      setProgressproduct((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerproduct);
    };
  }, []);

  React.useEffect(() => {
    const timerspecialprice = setInterval(() => {
      setProgressspecialprice((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerspecialprice);
    };
  }, []);
  React.useEffect(() => {
    const timerprice = setInterval(() => {
      setProgressprice((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerprice);
    };
  }, []);
  React.useEffect(() => {
    const timerstock = setInterval(() => {
      setProgressstock((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timerstock);
    };
  }, []);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleClickPCBanner = (event) => {
    hiddenFileInputprice.current.click();
  };

  const handleClickMobileBanner = (event) => {
    hiddenFileInputspecialprice.current.click();
  };
  const handleChangeproduct = (event) => {
    const fileUploaded = event.target.files[0];
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (types.every((type) => event.target.files[0].type !== type)) {
      setWrongFileUploadproduct(true);
      return;
    } else {
      setWrongFileUploadproduct(false);
      setRightfileuploadedproduct(true);
    }
    setFilenameproduct(fileUploaded);
  };
  const handleChangeMobileBanner = (event) => {
    const fileUploadedspecialprice = event.target.files;
    const types = [
      "image/png",
    ];
    const filename = [
      'Promotion_Banner_Mobile-01.png',
      'Promotion_Banner_Mobile-02.png',
      'Promotion_Banner_Mobile-03.png'
    ]
    Array.from(event.target.files).forEach(file => {
      if (filename.indexOf(file.name) <= -1) {
        setWrongFileUploadspecialprice(true);
        return;
      }
      if (types.every((type) => file.type !== type)) {
        setWrongFileUploadspecialprice(true);
        return;
      } else {
        setWrongFileUploadspecialprice(false);
        setRightfileuploadedspecialprice(true);
      }
    })
    setFilenameMobileBanner(fileUploadedspecialprice);
  };
  const handleChangeBanner = (event) => {
    const fileUploadedspecialprice = event.target.files;
    const types = [
      "image/png",
    ];
    const filename = [
      'Promotion_Banner_Desktop-01.png',
      'Promotion_Banner_Desktop-02.png',
      'Promotion_Banner_Desktop-03.png'
    ]
    Array.from(event.target.files).forEach(file => {
      if (filename.indexOf(file.name) <= -1) {
        setWrongFileUploadprice(true);
        return;
      }
      if (types.every((type) => file.type !== type)) {
        setWrongFileUploadprice(true);
        return;
      } else {
        setWrongFileUploadprice(false);
        setRightfileuploadedprice(true);
      }
    })
    setFilenameBanner(fileUploadedspecialprice);
  };

  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };

  const handleDragproduct = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActiveproduct(true);
    } else if (e.type === "dragleave") {
      setDragActiveproduct(false);
    }
  };

  // triggers when file is dropped
  const handleDropproduct = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActiveproduct(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileUploaded = e.dataTransfer.files[0];
      const types = [
        "image/png",
      ];
      if (types.every((type) => e.dataTransfer.files[0].type !== type)) {
        setWrongFileUploadproduct(true);
        return;
      } else {
        setWrongFileUploadproduct(false);
        setRightfileuploadedproduct(true);
      }
      setFilenameproduct(fileUploaded);
    }
  };

  const handleDragBanner = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActiveprice(true);
    } else if (e.type === "dragleave") {
      setDragActiveprice(false);
    }
  };

  // triggers when file is dropped
  const handleDropBanner = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActiveprice(false);
    const fileUploadedspecialprice = e.target.files;
    const types = [
      "image/png",
    ];
    const filename = [
      'Promotion_Banner_Desktop-01.png',
      'Promotion_Banner_Desktop-02.png',
      'Promotion_Banner_Desktop-03.png'
    ]
    Array.from(e.target.files).forEach(file => {
      if (filename.indexOf(file.name) <= -1) {
        setWrongFileUploadprice(true);
        return;
      }
      if (types.every((type) => file.type !== type)) {
        setWrongFileUploadprice(true);
        return;
      } else {
        setWrongFileUploadprice(false);
        setRightfileuploadedprice(true);
      }
    })
    setFilenameBanner(fileUploadedspecialprice);
  };

  const handleDragMobileBanner = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActivespecialprice(true);
    } else if (e.type === "dragleave") {
      setDragActivespecialprice(false);
    }
  };

  // triggers when file is dropped
  const handleDropMobileBanner = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActivespecialprice(false);
    const fileUploadedspecialprice = e.target.files;
    const types = [
      "image/png",
    ];
    const filename = [
      'Promotion_Banner_Mobile-01.png',
      'Promotion_Banner_Mobile-02.png',
      'Promotion_Banner_Mobile-03.png'
    ]
    Array.from(event.target.files).forEach(file => {
      if (filename.indexOf(file.name) <= -1) {
        setWrongFileUploadspecialprice(true);
        return;
      }
      if (types.every((type) => file.type !== type)) {
        setWrongFileUploadspecialprice(true);
        return;
      } else {
        setWrongFileUploadspecialprice(false);
        setRightfileuploadedspecialprice(true);
      }
    })
    setFilenameMobileBanner(fileUploadedspecialprice);
  };



  // triggers when file is dropped

  const saveFileproduct = (event) => {
    setDataupdated(false);
    setShowprogressproduct(true);
    event.preventDefault();
    //const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameproduct);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/product/upload/products",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessprouct(response.message);
        setRightfileuploadedproductsuccess(true);
        setRightfileuploadedproduct(false);
        setShowprogressproduct(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureproduct(error.message);
        setRightfileuploadedproductfailure(true);
        setRightfileuploadedproduct(false);
        setShowprogressproduct(false);
        setDataupdated(true);
        error;
      });
  };
  const reuploadFileproduct = (event) => {
    setDataupdated(false);
    setWrongFileUploadproduct(false);
    setRightfileuploadedproductsuccess(false);
    setRightfileuploadedproductfailure(false);
    setRightfileuploadedproduct(true);
    setShowprogressproduct(true);
    event.preventDefault();
    //const payload = "";
    const payload = new FormData();
    payload.append("objects", filenameproduct);
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/product/upload/products",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessprouct(response.message);
        setRightfileuploadedproductsuccess(true);
        setRightfileuploadedproduct(false);
        setShowprogressproduct(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureproduct(error.message);
        setRightfileuploadedproductfailure(true);
        setRightfileuploadedproduct(false);
        setShowprogressproduct(false);
        setDataupdated(true);
        error;
      });
  };

  const reuploadFileMobileBanner = (event) => {
    setDataupdated(false);
    setWrongFileUploadspecialprice(false);
    setRightfileuploadedspecialprice(true);
    setRightfileuploadedspecialpricesuccess(false);
    setRightfileuploadedspecialpricefailure(false);
    setShowprogressspecialprice(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    Array.from(filenameMobileBanner).forEach(item => {
      payload.append("objects", item);
    })
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/uploadfile/uploadBanner",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessspecialprice(response.message);
        setRightfileuploadedspecialpricesuccess(true);
        setRightfileuploadedspecialprice(false);
        setShowprogressspecialprice(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailurespecialprice(error.message);
        setRightfileuploadedspecialpricefailure(true);
        setRightfileuploadedspecialprice(false);
        setShowprogressspecialprice(false);
        setDataupdated(true);
        error;
      });
  };
  const saveFileMobileBanner = (event) => {

    setDataupdated(false);
    setShowprogressspecialprice(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    Array.from(filenameMobileBanner).forEach(item => {
      payload.append("objects", item);
    })
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/uploadfile/uploadBanner",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessspecialprice(response.message);
        setRightfileuploadedspecialpricesuccess(true);
        setRightfileuploadedspecialprice(false);
        setShowprogressspecialprice(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailurespecialprice(error.message);
        setRightfileuploadedspecialpricefailure(true);
        setRightfileuploadedspecialprice(false);
        setShowprogressspecialprice(false);
        setDataupdated(true);
        error;
      });
  }
  const saveFileBanner = (event) => {
    setDataupdated(false);
    setShowprogressspecialprice(true);
    event.preventDefault();
    const payload = new FormData();
    Array.from(filenameBanner).forEach(item => {
      payload.append("objects", item);
    })
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/uploadfile/uploadBanner",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessspecialprice(response.message);
        setRightfileuploadedpricesuccess(true);
        setRightfileuploadedprice(false);
        setShowprogressprice(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureprice(error.message);
        setRightfileuploadedpricefailure(true);
        setRightfileuploadedprice(false);
        setShowprogressprice(false);
        setDataupdated(true);
        error;
      });
  };


  const reuploadFileBanner = (event) => {
    setDataupdated(false);
    setWrongFileUploadprice(false);
    setRightfileuploadedprice(true);
    setRightfileuploadedpricesuccess(false);
    setRightfileuploadedpricefailure(false);
    setShowprogressprice(true);
    event.preventDefault();
    // const payload = "";
    const payload = new FormData();
    Array.from(filenameBanner).forEach(item => {
      payload.append("objects", item);
    })
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/uploadfile/uploadBanner",
      payload,
      requestOptions
    )
      .then((response) => {
        // reasonforsuccessspecialprice(response.message);
        setRightfileuploadedpricesuccess(true);
        setRightfileuploadedprice(false);
        setShowprogressprice(false);
        setDataupdated(true);
        return response;
      })
      .catch((error) => {
        setReasonforfailureprice(error.message);
        setRightfileuploadedpricefailure(true);
        setRightfileuploadedprice(false);
        setShowprogressprice(false);
        setDataupdated(true);
        error;
      });
  };

  const handleChangePageBanner = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPageBanner;
    const payload = {
      type: "Desktop",
    };

    setPageBanner(pages);
    setCurrentPageBanner(newPage);
  };
  const handleChangePageMobileBanner = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPageMobileBanner;
    const payload = {
      type: "Mobile",
    };

    setPageMobileBanner(pages);
    setCurrentPageMobileBanner(newPage);
  };
  const handleChangePageproduct = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPageproduct;
    const payload = {
      type: "Product",
    };

    setPageproduct(pages);
    setCurrentPageproduct(newPage);
  };
  const handleBannerType = (statusValue) => {
    setBannerType(statusValue)
  }
  const clearFileproduct = (event) => {
    setFilenameproduct("");
    setWrongFileUploadproduct(false);
    // setRightfileuploadedproduct(false);
    setRightfileuploadedproductsuccess(false);
    setRightfileuploadedproductfailure(false);
  };

  const clearFileBanner = (event) => {
    setFilenameBanner("");
    setWrongFileUploadprice(false);
    // setRightfileuploadedprice(false);
    setRightfileuploadedpricesuccess(false);
    setRightfileuploadedpricefailure(false);
  };

  const clearFileMobileBanner = (event) => {
    setFilenameMobileBanner("");
    setWrongFileUploadspecialprice(false);
    // setRightfileuploadedspecialprice(false);
    setRightfileuploadedspecialpricesuccess(false);
    setRightfileuploadedspecialpricefailure(false);
  };


  const toggleHandler = (eventKey, cb, tcb) => {
    if (eventKey === true) {
      cb(!eventKey);
    } else {
      cb(eventKey);
    }
    tcb(!eventKey);
  };
  return (
    <>
      <CommonTableHeader />
      <Helmet>
        <title>Betagro Bulk Upload</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container-fluid" data-testid="bulk-upload-screen-loading">
        <div className="row">
          <HamburgerTableMenu screen={props?.location?.state?.screen} />
          <div className="col-sm-9" style={{ paddingTop: "100px", backgroundColor: "#F5F5F5" }}>
            <div className="bulkUpload">
              <div className="bulkUploadHead">
                <h2 data-testid="bulk-upload-heading">Marketing Tools</h2>
              </div>
              <div className="bulkUploadCard">
                <h3 data-testid="upload-product-file-heading">Upload Product File</h3>
                <div className="row">
                  <div className="col-lg-12">
                    <form
                      onSubmit={(event) => saveFileproduct(event)}
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <div
                        className="bulkUploadCard-uploadFile"
                        onDragEnter={handleDragproduct}
                      >
                        <input
                          id="input-file-upload-product"
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleChangeproduct}
                          style={{ display: "none" }}
                          accept=".xlsx,.xlx"
                        />
                        <label
                          title="Drag and Drop Product file here"
                          id="label-file-upload"
                          htmlFor="input-file-upload"
                          className={
                            dragActiveproduct ? "drag-activeproduct" : ""
                          }
                        >
                          <img
                            titel="Click to upload"
                            src={UploadFile}
                            data-testid="click-to-upload-image"
                            onClick={handleClick}
                            className="bulkUploadCard-uploadFile-img cursor-pointer"
                          />
                          <p>
                            {isNull(filenameproduct?.name)
                              ? <span onClick={handleClick}> Choose a file here</span>
                              : filenameproduct?.name}
                          </p>
                          {wrongFileUploadproduct && (
                            <h6 className="invalid-feedback">
                              "Please uplaod a valid file of format .xlsx or
                              .xlx"
                            </h6>
                          )}
                        </label>
                      </div>
                      {dragActiveproduct && (
                        <div
                          className="drag-file-element"
                          onDragEnter={handleDragproduct}
                          onDragLeave={handleDragproduct}
                          onDragOver={handleDragproduct}
                          onDrop={handleDropproduct}
                        ></div>
                      )}
                      {rightfileuploadedproductsuccess ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileSuccess">
                          <div className="row">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div>
                                <h4>{filenameproduct.name}</h4>
                                <h4>
                                  <span>{filenameproduct.size} mb</span>
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              <img
                                src={CircleCheck}
                                className="mr-4 cursor-pointer"
                              />
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileproduct(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {rightfileuploadedproduct ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileProgress">
                          <div className="row">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div style={{ width: "95%" }}>
                                <h4>{filenameproduct.name}</h4>
                                <div className="d-flex align-items-center justify-content-between">
                                  {showprogressproduct &&
                                    !rightfileuploadedproductsuccess &&
                                    !rightfileuploadedproductfailure ? (
                                    <>
                                      <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                        <LinearProgress sx={{
                                          width: "100%",
                                          backgroundColor: "#D3D6DA",
                                        }} color="success" />
                                      </Stack>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileproduct(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {rightfileuploadedproductfailure ? (
                        <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileFail">
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-10 d-flex align-items-center justify-content-start">
                              <div>
                                <img src={MoneyBills} className="mr-4" />
                              </div>
                              <div>
                                <h4>{filenameproduct.name}</h4>
                                <h4>
                                  <span>{reasonforsuccessproduct}&nbsp;{reasonforfailureproduct} ( Fail)</span>
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-2 d-flex align-items-center justify-content-end">
                              <button
                                type="button"
                                title="Click and Re Upload  a Product file "
                                onClick={(event) => reuploadFileproduct(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={Reload}
                                  className="mr-4 cursor-pointer"
                                />
                              </button>
                              <button
                                type="button"
                                title="Click to  Clear"
                                onClick={(event) => clearFileproduct(event)}
                                className="bulkUploadCard-uploadFileStatus-btn"
                              >
                                <img
                                  src={CrossGrey}
                                  className="cursor-pointer"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="bulkUploadCard-uploadFileHistory">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <div className="d-flex align-items-baseline justify-content-between">
                              <Accordion.Header>
                                <div className="bulkUploadCard-uploadFileHistoryHead">
                                  <h5 onClick={() => toggleHandler(togglePro, setToggleValuePro, setTogglePro)}>
                                    {" "}
                                    {toggleValuePro !== togglePro
                                      ? `Hide Upload History`
                                      : `See Upload History`}
                                  </h5>
                                </div>
                              </Accordion.Header>
                              {rightfileuploadedproduct && (
                                <button
                                  type="submit"
                                  className="bulkUploadCard-uploadBtn"
                                >
                                  Upload
                                </button>
                              )}

                              {!rightfileuploadedproduct && (
                                <div
                                  title="Click and upload a Product file to activate the button"
                                  className="bulkUploadCard-uploadBtnDsbld"
                                >
                                  Upload
                                </div>
                              )}
                            </div>
                            <Accordion.Body>
                              <h3 className="mt-5 mb-5">
                                Upload History ({totalCountproduct})
                              </h3>
                              <div className="bulkUploadCard-uploadFileHistoryDesc">
                                <div className="bulkUploadCard-uploadFileHistoryDesc-head">
                                  <div className="row">
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      <h6>Date</h6>
                                    </div>
                                    <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                      <h6>File Name</h6>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                      <h6>Uploaded By</h6>
                                    </div>
                                    <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                      <h6>Status</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="bulkUploadCard-uploadFileHistoryDesc-body">
                                  {tableData &&
                                    tableData.length > 0 &&
                                    tableData.map((items, index1) => (
                                      <div className="row bulkUploadCard-uploadFileHistoryDesc-body-in">
                                        <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                          <h6>{items.updatedAt}</h6>
                                        </div>
                                        <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                          <h6 title={items.filename} className="my-bulk-upload-file-name-truncate">
                                            {items.filename &&
                                              items.filename.length > 35
                                              ? items.filename.substring(
                                                0,
                                                35
                                              ) + "..."
                                              : items.filename}
                                          </h6>
                                        </div>
                                        <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                          <h6
                                            title={
                                              items.firstNameEN +
                                              " " +
                                              items.lastNameEN
                                            }
                                          >
                                            <img
                                              src={gearImage}
                                              className="gearImage"
                                            />
                                            &nbsp;{items.firstNameEN}&nbsp;
                                            {items.lastNameEN &&
                                              items.lastNameEN.length > 4
                                              ? items.lastNameEN.substring(
                                                0,
                                                4
                                              ) + "..."
                                              : items.lastNameEN}
                                          </h6>
                                        </div>
                                        <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                          {items.status === true ? (
                                            <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                              Success
                                            </h6>
                                          ) : (
                                            <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                              Failed
                                            </h6>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                {!tableData ||
                                  (tableData.length === 0 && (
                                    <div className="bulkUploadCard-uploadFileHistoryDesc-bodyEmpty">
                                      <img src={Empty} />
                                      <p>No data available</p>
                                    </div>
                                  ))}
                              </div>
                              {tableData && tableData.length > 0 && (
                                <>
                                  <div className="row pagination-main-container common-display-none-mobile mt-4 mb-2" data-testid="pagination-container">
                                    <div className="col-xll-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex justify-content-end">
                                      <Stack spacing={2}>
                                        <Pagination
                                          count={totalPagesproduct}
                                          page={currentPageproduct}
                                          variant="outlined"
                                          shape="rounded"
                                          onChange={(event, page) =>
                                            handleChangePageproduct(event, page)
                                          }
                                        />
                                      </Stack>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="bulkUploadCard">
                <h3>Upload Promotion Banner</h3>
                <FormControl>

                  <div className="row m-0">
                    <div className="col-lg-6 p-0 pr-3">
                      <div
                        // className="bulkUploadCardRadio  d-flex align-items-center"
                        className={`${bannerType == 'PC' ? "bulkUploadCardRadio-focus" : ""} bulkUploadCardRadio  d-flex align-items-center`}
                      >

                        <input
                          type="radio"
                          name="banner"
                          checked={bannerType == "PC" ? true : false}
                          value="PC"
                          onChange={() => handleBannerType("PC")}
                          className={
                            bannerType == "PC"
                              ? "selected-banner"
                              : "unselected-banner "

                          }


                        />
                        <label className="ml-2">Desktop</label>
                      </div>
                    </div>
                    <div className="col-lg-6 p-0 pl-3">
                      <div
                        className={`${bannerType !== 'PC' ? "bulkUploadCardRadio-focus" : ""} bulkUploadCardRadio d-flex align-items-center`}
                      >
                        <input
                          type="radio"
                          name="banner"
                          value="Mobile"
                          checked={bannerType == "Mobile" ? true : false}
                          onChange={() => handleBannerType("Mobile")}
                          className={
                            bannerType == "Mobile"
                              ? "selected-banner"
                              : "unselected-banner "
                          }

                        ></input>
                        <label className="ml-2">Mobile</label>
                      </div>
                    </div>
                  </div>
                </FormControl>
                {bannerType == "PC" ? (
                  <form
                    onSubmit={(event) => saveFileBanner(event)}
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <div
                      className="bulkUploadCard-uploadFile"
                      onDragEnter={handleDragBanner}
                    >
                      <input
                        type="file"
                        ref={hiddenFileInputprice}
                        onChange={handleChangeBanner}
                        style={{ display: "none" }}
                        accept=".png"
                        multiple
                      />
                      <label
                        title="Drag and Drop PC Banner file here"
                        id="label-file-upload"
                        htmlFor="input-file-upload"
                        className={dragActiveprice ? "drag-activeprice" : ""}
                      >
                        <img
                          title="Click to Upload a Price file"
                          src={UploadFile}
                          data-testid="click-to-upload-image"
                          onClick={handleClickPCBanner}
                          className="bulkUploadCard-uploadFile-img cursor-pointer"
                        />
                        <p>
                          {filenameBanner.length === 0 && <span onClick={handleClickPCBanner}>Choose a file here</span>}
                        </p>
                        {Array.from(filenameBanner).map((items, index) =>
                          <p>
                            {isNull(items?.name)
                              ? ""
                              : items?.name}
                          </p>
                        )
                        }
                        {wrongFileUploadprice && (
                          <h6 className="invalid-feedback">
                            "Please uplaod a valid file of format .xlsx or .xlx"
                          </h6>
                        )}
                      </label>
                    </div>
                    {dragActiveprice && (
                      <div
                        className="drag-file-element"
                        onDragEnter={handleDragBanner}
                        onDragLeave={handleDragBanner}
                        onDragOver={handleDragBanner}
                        onDrop={handleDropBanner}
                      ></div>
                    )}
                    {rightfileuploadedpricesuccess ? (
                      <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileSuccess">
                        <div className="row">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            <div>
                              <img src={MoneyBills} className="mr-4" />
                            </div>
                            <div>
                              {Array.from(filenameBanner).map((items, index) =>
                                <>
                                  <h4>{items.name}</h4>
                                  <h4>
                                    <span>{items.size} mb</span>
                                  </h4>
                                </>
                              )
                              }

                            </div>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center justify-content-end">
                            <img src={CircleCheck} className="mr-4" />
                            <button
                              title="Click to Clear"
                              onClick={(event) => clearFileBanner(event)}
                              className="bulkUploadCard-uploadFileStatus-btn"
                            >
                              <img src={CrossGrey} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {rightfileuploadedprice ? (
                      <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileProgress">
                        <div className="row">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            <div>
                              <img src={MoneyBills} className="mr-4" />
                            </div>
                            <div style={{ width: "95%" }}>
                              {Array.from(filenameBanner).map((items, index) =>
                                <h4>{items.name}</h4>)
                              }
                              <div className="d-flex align-items-center justify-content-between">
                                {showprogressprice &&
                                  !rightfileuploadedpricesuccess &&
                                  !rightfileuploadedpricefailure ? (
                                  <>
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                      <LinearProgress sx={{
                                        width: "100%",
                                        backgroundColor: "#D3D6DA",
                                      }} color="success" />
                                    </Stack>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center justify-content-end">
                            <button
                              title="Click to Clear"
                              onClick={(event) => clearFileBanner(event)}
                              className="bulkUploadCard-uploadFileStatus-btn"
                            >
                              <img src={CrossGrey} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {rightfileuploadedpricefailure ? (
                      <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileFail">
                        <div className="row d-flex align-items-center">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            <div>
                              <img src={MoneyBills} className="mr-4" />
                            </div>
                            <div>
                              {Array.from(filenameBanner).map((items, index) =>
                                <h4>{items.name}</h4>)
                              }

                              <h4>
                                <span>{reasonforsuccessprice}&nbsp; {reasonforfailureprice} (Fail)</span>
                              </h4>
                            </div>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center justify-content-end">
                            <button
                              title="Click and Re Upload  a Price file "
                              onClick={(event) => reuploadFileBanner(event)}
                              className="bulkUploadCard-uploadFileStatus-btn"
                            >
                              <img src={Reload} className="mr-4" />
                            </button>
                            <button
                              title="Click to Clear"
                              onClick={(event) => clearFileBanner(event)}
                              className="bulkUploadCard-uploadFileStatus-btn"
                            >
                              <img src={CrossGrey} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="bulkUploadCard-uploadFileHistory">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <div className="d-flex align-items-baseline justify-content-between">
                            <Accordion.Header>
                              <div className="bulkUploadCard-uploadFileHistoryHead">
                                <h5 onClick={() => toggleHandler(togglePri, setToggleValuePri, setTogglePri)}>
                                  {" "}
                                  {toggleValuePri !== togglePri
                                    ? `Hide Upload History`
                                    : `See Upload History`}
                                </h5>
                              </div>
                            </Accordion.Header>
                            {rightfileuploadedprice && (
                              <button
                                type="submit"
                                className="bulkUploadCard-uploadBtn"
                              >
                                Upload
                              </button>
                            )}

                            {!rightfileuploadedprice && (
                              <div
                                title="Click and upload a Price file to activate the button"
                                className="bulkUploadCard-uploadBtnDsbld"
                              >
                                Upload
                              </div>
                            )}
                          </div>
                          <Accordion.Body>
                            <h3 className="mt-5 mb-5">
                              Upload History ({totalCountMobileBanner})
                            </h3>
                            <div className="bulkUploadCard-uploadFileHistoryDesc">
                              <div className="bulkUploadCard-uploadFileHistoryDesc-head">
                                <div className="row">
                                  <div className="col-lg-2  d-flex align-items-center justify-content-start">
                                    <h6>Date</h6>
                                  </div>
                                  <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                    <h6>File Name</h6>
                                  </div>
                                  <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                    <h6>Uploaded By</h6>
                                  </div>
                                  <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                    <h6>Status</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="bulkUploadCard-uploadFileHistoryDesc-body">
                                {tableDataBanner &&
                                  tableDataBanner.length > 0 &&
                                  tableDataBanner.map((items, index1) => (
                                    <div className="row bulkUploadCard-uploadFileHistoryDesc-body-in">
                                      <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                        <h6>{items.updatedAt}</h6>
                                      </div>
                                      <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                        <h6 title={items.filename} className="my-bulk-upload-file-name-truncate">
                                          {items.filename &&
                                            items.filename.length > 35
                                            ? items.filename.substring(0, 35) +
                                            "..."
                                            : items.filename}
                                        </h6>
                                      </div>
                                      <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                        <h6
                                          title={
                                            items.firstNameEN +
                                            " " +
                                            items.lastNameEN
                                          }
                                        >
                                          <img
                                            src={gearImage}
                                            className="gearImage"
                                          />
                                          &nbsp;{items.firstNameEN}&nbsp;
                                          {items.lastNameEN &&
                                            items.lastNameEN.length > 4
                                            ? items.lastNameEN.substring(0, 4) +
                                            "..."
                                            : items.lastNameEN}
                                        </h6>
                                      </div>
                                      <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                        {items.status === true ? (
                                          <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                            Success
                                          </h6>
                                        ) : (
                                          <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                            Failed
                                          </h6>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              {!tableDataBanner ||
                                (tableDataBanner.length === 0 && (
                                  <div className="bulkUploadCard-uploadFileHistoryDesc-bodyEmpty">
                                    <img src={Empty} />
                                    <p>No data available</p>
                                  </div>
                                ))}
                            </div>
                            {tableDataBanner && tableDataBanner.length > 0 && (
                              <>
                                <div className="row pagination-main-container common-display-none-mobile mt-4 mb-2">
                                  <div className="col-xll-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex justify-content-end">
                                    <Stack spacing={2}>
                                      <Pagination
                                        count={totalPageBanner}
                                        page={currentPageBanner}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={(event, page) =>
                                          handleChangePageBanner(event, page)
                                        }
                                      />
                                    </Stack>
                                  </div>
                                </div>
                              </>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </form>
                ) : (
                  <form
                    onSubmit={(event) => saveFileMobileBanner(event)}
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <div
                      className="bulkUploadCard-uploadFile"
                      onDragEnter={handleDragMobileBanner}
                    >
                      <input
                        type="file"
                        ref={hiddenFileInputspecialprice}
                        onChange={handleChangeMobileBanner}
                        style={{ display: "none" }}
                        accept=".png"
                        multiple
                      />
                      <label
                        title="Drag and Drop Mobile Banner file  here"
                        id="label-file-upload"
                        htmlFor="input-file-upload"
                        className={
                          dragActivespecialprice
                            ? "drag-activespecialprice"
                            : ""
                        }
                      >
                        <img
                          title="Click to Upload Special price file  here"
                          src={UploadFile}
                          data-testid="click-to-upload-image"
                          onClick={handleClickMobileBanner}
                          className="bulkUploadCard-uploadFile-img cursor-pointer"
                        />{filenameMobileBanner.length === 0 &&
                          <p>
                            <span onClick={handleClickMobileBanner}>Choose a file here</span>
                          </p>
                        }
                        {Array.from(filenameMobileBanner).map((items, index1) => (
                          <p>
                            {isNull(items?.name)
                              ? items?.name
                              : items?.name}
                          </p>))}
                        {wrongFileUploadspecialprice && (
                          <h6 className="invalid-feedback">
                            "Please uplaod a valid file of format .xlsx or .xlx"
                          </h6>
                        )}
                      </label>
                    </div>
                    {dragActivespecialprice && (
                      <div
                        className="drag-file-element"
                        onDragEnter={handleDragMobileBanner}
                        onDragLeave={handleDragMobileBanner}
                        onDragOver={handleDragMobileBanner}
                        onDrop={handleDropMobileBanner}
                      ></div>
                    )}
                    {rightfileuploadedspecialpricesuccess ? (
                      <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileSuccess">
                        <div className="row">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            <div>
                              <img src={MoneyBills} className="mr-4" />
                            </div>
                            <div>
                              {
                                Array.from(filenameMobileBanner).map((items, index1) => (
                                  <>
                                    <h4>{items.name}</h4>
                                    <h4>
                                      <span>{items.size} mb</span>
                                    </h4>
                                  </>))
                              }

                            </div>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center justify-content-end">
                            <img src={CircleCheck} className="mr-4" />
                            <button
                              title="Click to  Clear"
                              onClick={(event) => clearFileMobileBanner(event)}
                              className="bulkUploadCard-uploadFileStatus-btn"
                            >
                              <img src={CrossGrey} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {rightfileuploadedspecialprice ? (
                      <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileProgress">
                        <div className="row">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            <div>
                              <img src={MoneyBills} className="mr-4" />
                            </div>
                            <div style={{ width: "95%" }}>
                              {
                                Array.from(filenameMobileBanner).map((items, index1) => (
                                  <>
                                    <h4>{items.name}</h4>
                                  </>))
                              }
                              <div className="d-flex align-items-center justify-content-between">
                                {showprogressspecialprice &&
                                  !rightfileuploadedspecialpricesuccess &&
                                  !rightfileuploadedspecialpricefailure ? (
                                  <>
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                                      <LinearProgress sx={{
                                        width: "100%",
                                        backgroundColor: "#D3D6DA",
                                      }} color="success" />
                                    </Stack>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center justify-content-end">
                            <button
                              title="Click to  Clear"
                              onClick={(event) => clearFileMobileBanner(event)}
                              className="bulkUploadCard-uploadFileStatus-btn"
                            >
                              <img src={CrossGrey} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {rightfileuploadedspecialpricefailure ? (
                      <div className="bulkUploadCard-uploadFileStatus bulkUploadCard-uploadFileFail">
                        <div className="row d-flex align-items-center">
                          <div className="col-lg-10 d-flex align-items-center justify-content-start">
                            <div>
                              <img src={MoneyBills} className="mr-4" />
                            </div>
                            <div>{
                              Array.from(filenameMobileBanner).map((items, index1) => (
                                <>
                                  <h4>{items.name}</h4>
                                </>))
                            }
                              <h4>
                                <span> {reasonforsuccessspecialprice} &nbsp; {reasonforfailurespecialprice} ( Fail)</span>
                              </h4>
                            </div>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center justify-content-end">
                            <button
                              title="Click and Re Upload  a Special Price file "
                              onClick={(event) =>
                                reuploadFileMobileBanner(event)
                              }
                              className="bulkUploadCard-uploadFileStatus-btn"
                            >
                              <img src={Reload} className="mr-4" />
                            </button>
                            <button
                              title="Click to Clear"
                              onClick={(event) => clearFileMobileBanner(event)}
                              className="bulkUploadCard-uploadFileStatus-btn"
                            >
                              <img src={CrossGrey} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="bulkUploadCard-uploadFileHistory">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <div className="d-flex align-items-baseline justify-content-between">
                            <Accordion.Header>
                              <div className="bulkUploadCard-uploadFileHistoryHead">
                                <h5 onClick={() => toggleHandler(toggleSp, setToggleValueSp, setToggleSp)}>
                                  {" "}
                                  {toggleValueSp !== toggleSp
                                    ? `Hide Upload History`
                                    : `See Upload History`}
                                </h5>

                              </div>
                            </Accordion.Header>
                            {rightfileuploadedspecialprice &&
                              (<button
                                type="submit"
                                className="bulkUploadCard-uploadBtn"
                              >
                                Upload
                              </button>)}
                            {!rightfileuploadedspecialprice &&
                              (<div
                                title="Click and upload a  Special Price file to activate the button"
                                className="bulkUploadCard-uploadBtnDsbld"
                              >
                                Upload
                              </div>)}
                          </div>
                          <Accordion.Body>
                            <h3 className="mt-5 mb-5">
                              Upload History ({totalCountMobileBanner})
                            </h3>
                            <div className="bulkUploadCard-uploadFileHistoryDesc">
                              <div className="bulkUploadCard-uploadFileHistoryDesc-head">
                                <div className="row">
                                  <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                    <h6>Date</h6>
                                  </div>
                                  <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                    <h6>File Name</h6>
                                  </div>
                                  <div className="col-lg-3 d-flex align-items-center justify-content-start">
                                    <h6>Uploaded By</h6>
                                  </div>
                                  <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                    <h6>Status</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="bulkUploadCard-uploadFileHistoryDesc-body">
                                {tableDataMobileBanner &&
                                  tableDataMobileBanner.length > 0 &&
                                  tableDataMobileBanner.map((items, index1) => (
                                    <div className="row bulkUploadCard-uploadFileHistoryDesc-body-in">
                                      <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                        <h6>{items.updatedAt}</h6>
                                      </div>
                                      <div className="col-lg-5 d-flex align-items-center justify-content-start">
                                        <h6 title={items.filename} className="my-bulk-upload-file-name-truncate">
                                          {items.filename &&
                                            items.filename.length > 35
                                            ? items.filename.substring(0, 35) +
                                            "..."
                                            : items.filename}
                                        </h6>
                                      </div>
                                      <div className="col-lg-3 d-flex align-items-center justify-content-start pl-0">
                                        <h6
                                          title={
                                            items.firstNameEN +
                                            " " +
                                            items.lastNameEN
                                          }
                                        >
                                          &nbsp;
                                          <img
                                            src={gearImage}
                                            className="gearImage"
                                          />
                                          &nbsp;{items.firstNameEN}&nbsp;
                                          {items.lastNameEN &&
                                            items.lastNameEN.length > 4
                                            ? items.lastNameEN.substring(0, 4) +
                                            "..."
                                            : items.lastNameEN}
                                        </h6>
                                      </div>
                                      <div className="col-lg-2 d-flex align-items-center justify-content-start">
                                        {items.status === true ? (
                                          <h6 className="bulkUploadCard-uploadFileHistoryDesc-success">
                                            Success
                                          </h6>
                                        ) : (
                                          <h6 className="bulkUploadCard-uploadFileHistoryDesc-fail">
                                            Failed
                                          </h6>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              {!tableDataMobileBanner ||
                                (tableDataMobileBanner.length === 0 && (
                                  <div className="bulkUploadCard-uploadFileHistoryDesc-bodyEmpty">
                                    <img src={Empty} />
                                    <p>No data available</p>
                                  </div>
                                ))}
                            </div>
                            {tableDataMobileBanner &&
                              tableDataMobileBanner.length > 0 && (
                                <>
                                  <div className="row pagination-main-container common-display-none-mobile mt-4 mb-2">
                                    <div className="col-xll-12 col-xl-12 col-lg-12 col-md-12 col-12 d-flex justify-content-end">
                                      <Stack spacing={2}>
                                        <Pagination
                                          count={totalPageMobileBanner}
                                          page={currentPageMobileBanner}
                                          variant="outlined"
                                          shape="rounded"
                                          onChange={(event, page) =>
                                            handleChangePageMobileBanner(
                                              event,
                                              page
                                            )
                                          }
                                        />
                                      </Stack>
                                    </div>
                                  </div>
                                </>
                              )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { MarkentingManagement as MarkentingManagement };

