import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from "moment";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../../SharedComponent/Loader/Loader";
import { orderActions } from "../../_actions/order.actions";
import ApiList from "../../_constants/const.api";
import { convertToCurrencyFormat } from '../../_helpers/commanFunction';
import ApiProvider from "../../_services/ApiProvider";
import emptyTableData from '../../images/empty-table-data.png';
import exportButton from '../../images/export-button.png';
import arrowIcon from '../../images/small-chevron-down.png';
import { OrderManagementFilter } from './OrderManagementFilter';
import Table from './Table';
let data;



function createData(order_no, customer, total, order_date, created_by, channel, status, id) {
    return {
        order_no,
        customer,
        total,
        order_date,
        created_by,
        channel,
        status,
        id
    };
}

const rows = [
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 123),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 344),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 456),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 4565),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 75),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 789),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 99),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 88),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 66),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 445),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 667),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 766),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 6656),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 777),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 777),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 7777),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 7775),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 9797),
    createData(1234567890123, 'Sandee Usanachitt', '฿ 2,000.00', 11 / 11 / 2022, 'Saranyoot', 'Food Service', 'To Confirm', 898),
];

const headCells = [
    {
        id: 'orderNo',
        numeric: false,
        disablePadding: true,
        label: 'Order No.',
        bold: false,
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Customer',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'totalOrderPrice',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'orderCreateDate',
        numeric: true,
        disablePadding: false,
        label: 'Order Date',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'createduserfirstnameen',
        numeric: true,
        disablePadding: false,
        label: 'Created by',
        align: "left",
        headerAlign: "left",
        sortable: false,
    },
    {
        id: 'userchannel',
        numeric: true,
        disablePadding: false,
        label: 'Channel',
        align: "left",
        headerAlign: "left",
        sortable: false,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'action',
        label: '',
        disablePadding: false,
        align: "right",
        headerAlign: "right",
    },
];

const NewOrderList = (props) => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    const _exporter = React.createRef();
    const excelExport = () => {
        save(_exporter);
    };
    const save = component => {
        const options = component.current.workbookOptions();
        options.sheets[0].name = "New Orders";
        const rows = options.sheets[0].rows;
        let altIdx = 0;
        rows.forEach(row => {
            if (row.type === 'data') {
                if (altIdx % 2 !== 0) {
                    row.cells.forEach(cell => {
                        cell.background = '#aabbcc';
                    });
                }
                altIdx++;
            }
        });
        component.current.save(options);
    };

    const { t } = useTranslation();
    const lancode = localStorage.getItem("lancode");
    const newOrderList = useSelector((state) => state.order.newOrderList);
    const newOrderLoading = useSelector((state) => state.order.newOrderLoading);
    const newOrderdateTime = useSelector((state) => state.order.newOrderdateTime);
    const [selected, setSelected] = React.useState([]);
    const [excelselectedData, setExcelselectedData] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [pageSkip, setPageSkip] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const detaulsSort = { id: "createdAt", orderBy: -1 }
    const [sortDetails, setsetSort] = React.useState(detaulsSort);
    const [loading, setLoading] = useState(false);
    const [orderStatusUpdated, setOrderStatusUpdated] = useState(false);
    const userData = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : undefined;
    const store = userData ? userData.user.stores[0].storeCode : ''
    const dispatch = useDispatch();
    const history = useHistory();
    const [attrFilter, setAttrFilter] = useState({});
    const [filtersFields, setFiltersFields] = useState({});
    const [todaysdate, setTodaysdate] = React.useState(moment().format("DDMMYYYY"));
    const [filenames, setFilenames] = React.useState("new_orders_" + todaysdate + ".xlsx");

    React.useEffect(() => {
        setLoading(true)
        if (!userData) {
            history.push('/login')
        } else {
            if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
                history.push('/')
            }
        }
        if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
            const payload = {
                "storeCode": store,

            }
            dispatch(orderActions.getNewOrderList("", payload, `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))

        }

    }, [])

    React.useEffect(() => {
        if (newOrderList?.data?.result) {
            const filterData = newOrderList.data.result.map(items => {
                items.orderCreateDate = moment(items.createdAt).format("DD/MM/YYYY")
                items.name = items.userfirstnameen + " " + items.userlastnameen;
                items.id = items.orderId;
                return items
            })
            setTableData(filterData)
            setLoading(false)
            props.setNewOrderCount(newOrderList.data.totalCount)
            setTotalCount(newOrderList.data.totalCount)
        } else {
            props.setNewOrderCount(0)
            setTotalCount(0)
            setTableData([])
            setLoading(false)
            setSelected([])
        }

    }, [newOrderList, newOrderdateTime])


    const handleChangeRowsPerPage = (event) => {
        setLoading(true)
        const payload = {
            "storeCode": store,
        }
        let merged = { ...payload, ...attrFilter, ...filtersFields };
        dispatch(orderActions.getNewOrderList("", merged, `limit=${parseInt(event.target.value)}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))


        setRowsPerPage(parseInt(event.target.value));
        setPage(1);


    };


    const handleChangePage = (event, newPage) => {
        setLoading(true)
        let pages = (newPage - 1) * rowsPerPage
        const payload = {
            "storeCode": store,
        }

        setPage(newPage);
        let merged = { ...payload, ...attrFilter, ...filtersFields };
        dispatch(orderActions.getNewOrderList("", merged, `limit=${parseInt(rowsPerPage)}&skip=${pages}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
        setPageSkip(pages)

    };
    const setsetSortFn = (id, sortBy) => {
        setLoading(true)
        let columnKey = id
        if (id === 'name' || id === "name") {
            columnKey = 'firstNameEN'
        }
        else if (id === 'orderCreateDate' || id === "orderCreateDate") {
            columnKey = 'createdAt'
        }

        const payload = {
            "storeCode": store,
        }
        let merged = { ...payload, ...attrFilter, ...filtersFields };
        dispatch(orderActions.getNewOrderList("", merged, `limit=${rowsPerPage}&skip=${pageSkip}&sort=${encodeURIComponent(JSON.stringify({ [columnKey]: sortBy }))}`))
        setsetSort({ ...sortDetails, id: columnKey, orderBy: sortBy })
    }

    if (excelselectedData.length > 0) {
        data = excelselectedData;
    }
    else {

        data = tableData;
    }

    data.map((dataItem) => {
        dataItem.status2 = 'To Confirm';
        dataItem.orderCreateDate2 = moment(dataItem.ordercreatedat).format("DD.MM.YYYY");
        dataItem.totalOrderPrice2 = convertToCurrencyFormat(Number(dataItem.totalOrderPrice));
        return dataItem;
    });



    const orderUpdate = (orderDetails, status) => {
        let payload = {
            "orderId": [orderDetails.orderId],
            "status": status
        };
        orderUpdateApi(payload);
        props.updateorderbadges();
    };

    const fullFillMultiple = () => {
        let payload = {
            "orderId": selected,
            "status": "to_confirm"
        };
        orderUpdateApi(payload)
        props.updateorderbadges();
    };

    const orderUpdateApi = (payload) => {
        setLoading(true)
        ApiProvider.post(ApiList.API_URL_TO_UPDATE_ORDER, payload).then((response) => {
            if (response) {
                const payload = {
                    "storeCode": store,
                }
                setLoading(false)
                setOrderStatusUpdated(true)
                dispatch(orderActions.getNewOrderList("", payload, `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
                setSelected([])
            }

        })
    };

    const filterNewOrderList = (selectedItem) => {

        let payload = {
            storeCode: store,
        }
        if (selectedItem?.channelDefaultValue) {
            payload.userChannel = selectedItem?.channelDefaultValue
        }
        if (selectedItem?.deliveryTime) {
            payload.deliveryTime = selectedItem?.deliveryTime
        }
        if (selectedItem?.deliveryDate?.startDate) {
            payload.deliveryDate = {
                from: moment(selectedItem?.deliveryDate?.startDate).format("YYYY-MM-DD"),
                to: moment(selectedItem?.deliveryDate?.endDate).format("YYYY-MM-DD")
            }
        }
        if (selectedItem?.orderDate?.startDate) {
            payload.createdAt = {
                from: moment(selectedItem?.orderDate?.startDate).format("YYYY-MM-DD"),
                to: moment(selectedItem?.orderDate?.endDate).format("YYYY-MM-DD")
            }

        }
        setLoading(true)
        setFiltersFields(payload)
        let merged = { ...payload, ...attrFilter };
        dispatch(orderActions.getNewOrderList("", merged, `limit=${rowsPerPage}&skip=${pageSkip}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))


    }


    const searchItemByAttr = (query) => {
        let payload = query
        payload.storeCode = store
        let merged = { ...payload, ...filtersFields };
        dispatch(orderActions.getNewOrderList("", merged, `limit=${rowsPerPage}&skip=${pageSkip}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))

        setAttrFilter(payload)
    }

    return (

        <div>
            {/* <CancelOrderModal /> */}
            {loading && (<Loading />)}

            <OrderManagementFilter
                filterNewOrderList={filterNewOrderList}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                searchItemByAttr={searchItemByAttr}
            />

            <div className='row' id="NewOrderList">
                <div className='col-sm-8 col-md-8'>
                    <div className='new-order-list-table-main-container'>
                        <div className="table-order-counts">
                            <h6 className='new-order-list-table-order-counts'>{totalCount === 0 || !totalCount || totalCount === 1 ? totalCount + " Order" : totalCount + " Orders"} </h6>
                            {totalCount !== 0 && selected.length > 0 && (
                                <h6 className="table-selected-counts">({selected.length} selected)</h6>
                            )
                            }

                        </div>
                    </div>
                </div>
                {/* export-button-section start from here */}
                <div className='col-sm-2 col-md-2 pr-0'>
                    <button className='export-button-main-container' onClick={excelExport}>
                        <img src={exportButton} className='export-button-image' />
                        <span className="export-button-heading">Export</span>
                    </button>
                    <ExcelExport data={data} fileName={filenames} ref={_exporter}>
                        <ExcelExportColumn field="orderNo" title="Order No." cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="name" title="Customer" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="totalOrderPrice2" title="Total(Baht)" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="orderCreateDate2" title="Order Date" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="createduserfirstnameen" title="Created by" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="userchannel" title="Channel" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="status2" title="Status" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />

                    </ExcelExport>
                </div>

                <div className='col-sm-2 col-md-2 text-center'>
                    {selected.length > 0 ?
                        <button className='to-full-button-main-container' onClick={fullFillMultiple} >
                            <span className="to-full-button-heading">To fulfill</span>
                        </button> :
                        <button className="to-full-button-main-container-deactivated" title=" Select Orders to Activate To FullFill Button " >
                            <span className="to-full-button-heading-deactivated">To fulfill</span>
                        </button>}
                </div>
                {/* export-button-section end */}
            </div>

            <div className='row'>
                {tableData?.length > 0 ?
                    <div className='col-sm-12 col-md-12 to-comfirm-table-main-container'>
                        {/* table will come */}
                        <Table
                            tableData={tableData}
                            headCells={headCells}
                            setSelected={setSelected}
                            selected={selected}
                            setExcelselectedData={setExcelselectedData}
                            excelselectedData={excelselectedData}
                            totalCount={totalCount}
                            setPage={setPage}
                            page={page}
                            setRowsPerPage={setRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            setsetSortFn={setsetSortFn}
                            sortDetails={sortDetails}
                            orderUpdate={orderUpdate}

                        />

                    </div> :
                    <>
                        {!newOrderList && !newOrderLoading && (
                            <>
                                <div className='col-sm-12 col-md-12'>
                                    <div className='table-empty-data-main-container'>
                                        <img src={emptyTableData} className='table-empty-data-image' />
                                        <h6 className='table-empty-data-heading'>No new order yet</h6>
                                    </div>
                                </div>


                                <div className='row order-table-pagination-main-container'>
                                    <div className='col-sm-7 order-table-pagination-inner-container'>
                                        <div className="d-flex flex-row">
                                            <h6 className='order-table-pagination-count'>1-{rowsPerPage} of 0</h6>
                                            <h6 className='order-table-order-per-page'>Order per page</h6>
                                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                                <Select
                                                    variant="outlined"
                                                    value={rowsPerPage}
                                                    // onChange={props.handleChangeRowsPerPage}
                                                    displayEmpty
                                                    sx={{
                                                        "&:hover": {
                                                            "&& fieldset": {
                                                                border: "2px solid #61A60E;"
                                                            }
                                                        }
                                                    }}
                                                    inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ position: "absolute", width: 11, height: 7, left: 37, bottom: 37, cursor: "pointer" }} /> }}
                                                >
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={20}>20</MenuItem>
                                                    <MenuItem value={30}>30</MenuItem>
                                                    <MenuItem value={40}>40</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                </div>

                            </>
                        )}
                    </>
                }
                {/*No-new-order-yet-section end */}
            </div>
        </div>
    )

}

export default NewOrderList