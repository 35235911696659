import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const CookieSettingsmodal = (props) => {
  return <>
    <Grid container spacing={0} className="SearchBarContainer" style={{ paddingTop: "6%", paddingBottom: "6%" }} >
      <Grid item xs={6} >
        <Typography gutterBottom variant="h5" className="cookies-head" component="span">
          Cookies Settings
        </Typography>
      </Grid >
      <Grid item xs={6} style={{ textAlign: "right" }}>
        <Typography gutterBottom variant="h5" component="span">
          <CloseIcon onClick={() => props.showModal()} />
        </Typography>
      </Grid >
    </Grid>
    <Grid container spacing={0} className="SearchBarContainer">
      <Grid item xs={6}>
        <Typography gutterBottom variant="h5" component="span">
          Required Cookies
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Required to enable core site functionality
        </Typography>
      </Grid >
      <Grid item xs={6} justifyContent="flex-end" style={{ textAlign: "right" }}>
        <Switch {...label} style={{ color: "#fff" }} />
      </Grid >
    </Grid>
    <Grid container spacing={0} className="SearchBarContainer">
      <Grid item xs={6}>
        <Typography gutterBottom variant="h5" component="span">
          Helpful Cookies
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Allow us to analyze site usage so we can
          measure and improve performance
        </Typography>
      </Grid >
      <Grid item xs={6} style={{ textAlign: "right" }}>
        <Switch {...label} style={{ color: "#fff" }} />
      </Grid >
    </Grid>
    <Grid container spacing={0} className="SearchBarContainer">
      <Grid item xs={6} >
        <Typography gutterBottom variant="h5" component="span">
          Advertising Cookies
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Used by advertising companies to serve ads that are relevant to your interests
        </Typography>
      </Grid >
      <Grid item xs={6} style={{ textAlign: "right" }}>
        <Switch {...label} style={{ color: "#fff" }} />
      </Grid >
    </Grid>
    <Grid container spacing={0} className="SearchBarContainer">
      <Grid item xs={12}>
        <div className="promo-btn-rt" >
          <button className="promo-btn-addtocart" onClick={() => props.showModal()}>
            Accept & Continue
          </button>
        </div>
      </Grid >
    </Grid>
  </>
}
export { CookieSettingsmodal as CookieSettingsmodal };
