import React, { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import "./thankYouForPuchaseModal.css";
import { useState } from "react";
import thankYouPurchaseImage from "../../images/thank-you-puchase-image.png";

import deleteWhite from "../../images/delete-white.png";
import Canel_Draft from "../../images/cancel-order-error.png";
import DicardOrder from "../../images/dicard-order.png"

const OrderEditModalPopUP = (props) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    setOpenModal(false);
  };
  useEffect(() => {}, [props.orderNumber]);
  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };
  return (
    <>
      {props.open ? (
        <div className="thank-you-purchase-modal-main-container">
          <div className="thank-you-purchase-modal-inner-container">
            <div className="thank-you-purchase-modal">
              {!props.buttonClickedName && (
                <img
                src={isNull(props.image) ? thankYouPurchaseImage : props.image}
                className="thank-you-purchase-modal-image"
              />
              )}

            {props.buttonClickedName && (
                <img
                src={Canel_Draft}
                className="thank-you-purchase-modal-image"
              />
              )}
              
              <h6 className="thank-you-purchase-modal-heading">
                {t(props.mainHeading)}
              </h6>
              <h6 className="thank-you-purchase-modal-main-heading">
                {t(props.subHeading)}
              </h6>
              <h6 className="thank-you-purchase-modal-paragraph">
                {props.innerHeading}
              </h6>
              {props.subHeading2 && (
                <>
                  <h6 className="thank-you-purchase-modal-inner-paragraph">
                    {props.subHeading2}
                  </h6>
                </>
              )}
              {props.buttonLabel1 !== "" && (
                <button
                  className="thank-you-purchase-modal-button"
                  onClick={() => props.ClickAction1()}
                >
                  <span>{props.buttonLabel}</span>
                </button>
              )}
              <div className="row">
                {!props.buttonClickedName && (
                  <>
                    <div className="col-sm-6">
                      <button
                        className="cancel-order-modal-cancel-discard-button"
                        onClick={() => props.btnDiscardFn()}
                      >
                        {/* <img
                        //   src={deleteWhite}
                          className="cancel-order-modal-delete-image"
                        /> */}
                        {props.btnDiscard}
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <button
                        className="cancel-order-modal-keep-discard-button"
                        onClick={() => props.btnSaveFn()}
                      >
                        {props.btnSave}
                      </button>
                    </div>
                  </>
                )}

                {props.buttonClickedName && (
                  <>
                  <div className="col-sm-6">
                      <button
                        className="cancel-order-modal-cancel-order-button"
                        onClick={() => props.btnKeepDraft()}
                      >
                        {props.btnSave}
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <button
                        className="cancel-order-modal-keep-order-button"
                        onClick={() => props.draftDiscared()}
                      >
                        <img
                          src={deleteWhite}
                          className="cancel-order-modal-delete-image"
                        />
                        {props.btnDiscard}
                      </button>
                    </div>                    
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OrderEditModalPopUP;
