import React from "react";
import { textAlign } from "@mui/system";
import "./Footer.css";
import FooterLogo from "./Assets/SVG/FooterLogo.svg";
import FooterLogoMob from "./Assets/SVG/FooterLogoMob.svg";
import FBIcon from "./Assets/SVG/FBIcon.svg";
import InstaIcon from "./Assets/SVG/InstaIcon.svg";
import LineIcon from "./Assets/SVG/LineIcon.svg";
import WebIcon from "./Assets/SVG/WebIcon.svg";
import TelIcon from "./Assets/SVG/TelIcon.svg";
import MailIcon from "./Assets/SVG/MailIcon.svg";
import DBimg from "./Assets/SVG/DBimg.svg";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import  dbdbutton from "../images/dbdbutton.png";
import script from "../images/script.txt";

const Footer = () => {

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const history = useHistory();
  const [showDbd, setShowDbd] = React.useState(false);
  const dbdbuttonopen = () => {
    alert("script will be here.......... ");
}
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = 'https://www.trustmarkthai.com/callbackData/initialize.js?t=feb87491a-33-6-e4a49f8d9440e0c34e37029f104411969ada8';
    script.id = 'dbd-init';
    document.body.appendChild(script);
    setShowDbd(true);
    return () => {
      document.body.removeChild(script);
    };
  },[])
  return (
    <>
          <div className="footer-container" data-testid="footer-screen-loading">
            <div className="bg-footer">
              <div className="row d-flex">
                <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                  <Link to="/" className="bg-footer-logo hidden-xs">
                    <img src={FooterLogo} data-testid="footer-screen-logo" /></Link>
                    <Link to="/" className="visible-xs bg-footer-logo">
                    <img src={FooterLogoMob} /></Link>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 footer-order3 hidden-sm">
                      <div className="bg-footer-in">
                        <h5 className="">{t("About.label")}</h5>
                        <ul className="list-unstyled ">
                          <li>
                            <a href="https://www.betagro.com" target="_blank">{t("AboutBetagro.label")}</a>
                          </li>
                          <li>
                            <a href="#!">{t("BetagroRecipe.label")}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 footer-order2">
                      <div className="bg-footer-in">
                        <h5 className="">{t("Followus.label")}</h5>
                        <ul className="list-unstyled">
                          <li>
                            <a href="https://www.facebook.com/BetagroBrand" target="_blank">
                            <img src={FBIcon} className="footer-logo-image" data-testid="footer-screen-facebook-logo" />
                              {t("Facebook.label")}
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/betagrobrand/" target="_blank">
                              <img src={InstaIcon} className="footer-logo-image" data-testid="footer-screen-instragram-logo" />
                              {t("Instagram.label")}
                            </a>
                          </li>
                          <li>
                            <a href="https://qr-official.line.me/sid/M/943umipt.png?shortenUrl=true" target="_blank">
                              <img src={LineIcon} className="footer-logo-image" data-testid="footer-screen-line-logo" />
                              {t("@betagroonline.label")}
                            </a>
                          </li>
                          <li>
                            <a href="https://www.betagro-food.com/" target="_blank">
                              <img src={WebIcon} className="footer-logo-image" data-testid="footer-screen-betagro-logo" />
                              {t("@betagro-food.com.label")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 footer-order1">
                      <div className="bg-footer-in">
                        <h5 className="hidden-xs">{t("Contact.label")}</h5>
                        <h5 className="visible-xs">{t("Support.label")}</h5>
                        <ul className="list-unstyled">
                          <li>
                            <a href="tel:1482" className="visible-sm">
                              <img src={TelIcon} className="footer-logo-image" />
                              1482
                            </a>
                            <a className="hidden-sm cursor-default">
                              <img src={TelIcon} className="footer-logo-image" />
                              1482
                            </a>
                          </li>
                          <li>
                            <a href="mailto:contactonline@betagro.com">
                              <img src={MailIcon} className="footer-logo-image" />
                              {t("ContactUs.label")}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="bg-footer-in visible-sm mt-4">
                        <h5 className="">{t("About.label")}</h5>
                        <ul className="list-unstyled ">
                          <li>
                            <a href="https://www.betagro.com" target="_blank">{t("AboutBetagro.label")}</a>
                          </li>
                          <li>
                            <a href="#!">{t("BetagroRecipe.label")}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="bg-footer-copyright"  data-testid="copy-rights-footer-screen-loading">
              <div className="footer-terms">
                <ul className="list-unstyled">
                  <li className="d-flex">
                    © 2022 {t("BetagroShopAllrightsreserved.label")}.
                  </li>
                  <li className="d-flex">
                    <span className="hidden-sm"></span>{t("TermsofService.label")}
                  </li>
                  <li className="d-flex">
                    <span></span>{t("PrivacyPolicy.label")}
                  </li>
                <li className="d-flex">
                  <span></span>{t("CookiesSetting.label")}
                </li>
                <li className="d-flex dbd-company-logo-main-container">
                  <span></span> {showDbd && <div id="Certificate-banners" className="dbd-company-logo"></div>}
                 
                </li>
                </ul>
              </div>
            </div>
          </div>
          </>
  );
};

export { Footer as Footer };
