import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { orderActions } from "../_actions/order.actions";
import KeyDownIcon from "./Assets/SVG/KeyDownIcon.svg";
import KeyUpIcon from "./Assets/SVG/KeyUpIcon.svg";
import SortIcon from "./Assets/SVG/SortIcon.svg";
import UserIconBlack from "./Assets/SVG/UserIconBlack.svg";
import "./OrderManagementByProduct.css";

import { REACT_APP_BASE_URL } from "../constant";
// import { OrderManagementFilter } from '../OrderManagementFilter';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { isNull } from "lodash";
import ThankYouForPurchaseModal from "../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal";
import Loading from "../SharedComponent/Loader/Loader";
import ApiList from "../_constants/const.api";
import { convertToCurrencyFormat } from '../_helpers/commanFunction';
import ApiProvider from "../_services/ApiProvider";
import AlertImage from "../images/alert1.png";
import blankImage from "../images/empty-image.png";
import emptyTableData from "../images/empty-table-data.png";
import arrowIcon from '../images/small-chevron-down.png';
import { OrderManagementFilter } from "./FilterComponent/OrderManagementFilter";



const theme = createTheme({
  palette: {
    success: {
      main: "#61A60E",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => `
        h1 {
          color: ${themeParam.palette.success.main};
        }
      `,
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [allocatedQtd, setAllocatedQtd] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);
  const [errors, setErrors] = useState([]);
  const [validInputs, setValidInputs] = useState({});
  const [updatedData, setUpdatedData] = useState([]);
  const [onHandQuantity, setOnHandQuantity] = useState('');
  const [orderedList, setOrderedListData] = useState([]);
  const [updateEffect, setUpdateEffect] = useState(false);
  const [isItemSelectedstate, setIsItemSelectedstate] = useState(false);
  const [defaultLoad, setDefaultLoad] = useState(false);
  const [userData, setUserData] = useState(props.userData);


  React.useEffect(() => {
    const dummyArray = [];
    setDefaultLoad(true);
    if (selected.length == 0) {
      onSelectAllClick(selected, onHandQuantity);
    }
  }, []);

  React.useEffect(() => {
    const dummyArray = [];
    if (selected.length >= 0 && !defaultLoad) {
      onSelectAllClick(selected, onHandQuantity);
    }
  }, [selected]);

  React.useEffect(() => {
    if (props.remainingQuantity > -1) {
      let updateData = userData.map(i => {
        if (!i.allocatedQtd) {
          if (i.allocatedQtd !== 0) {
            i.allocatedQtd = i.cartQuantity
          }
        }
        return i
      })
    }
  }, [])
  React.useEffect(() => {
    userData.map(items => {
      items.createdAt = moment(items.updatedAt).format('DD/MM/YYYY');
      return items;
    });
    setOrderedListData(userData)
  }, [orderedList, updateEffect])

  React.useEffect(() => {
    setSelected(props.selectedData);
  }, [props.selectedData]);

  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };

  const handleClick = (event, orderItemId, allocatedQtd, item) => {
    setDefaultLoad(false);
    if (!event.target.checked) {
      setIsItemSelectedstate(false)
      // setSelected(prevSelectedData => prevSelectedData.filter(item => item.orderItemId !== orderItemId));
    }
    const index = selected.findIndex(item => item.orderItemId === orderItemId);
    if (index === -1) {
      setSelected([...selected, { orderItemId, allocatedQtd: allocatedQtd ? allocatedQtd : 0 }]);
    } else {
      setSelected(selected.filter(item => item.orderItemId !== orderItemId));
      let temp = item
      temp.totalPrice = temp.price;
      setOrderedListData([...orderedList, temp])
    }
    // props.onTableDataChange(selected);

  };

  const handleChange = (event, orderItemId, index, item) => {

    // setAllocatedQtd(Number(event.target.value));
    setDefaultLoad(false);
    let temp1 = item;

    const allocatedQtd = event.target.value;

    const allocatedQtd2 = (event.target.value) ? event.target.value : temp1.cartQuantity;
    item.allocatedQtd = allocatedQtd

    let temp2 = props.allUserData

    let previousPrice = temp1.price;
    let packSize = 1;
    if (temp2.packSize) {
      packSize = parseFloat(temp2.packSize);
    }

    let price = Number(temp2.price1Scale1);
    if (isNull(temp1.conditionAmount)) {

      // price = previousPrice; 

      if (Number(temp2.quantity1Scale1) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2.quantity1Scale1) != 0) {
        price = Number(temp2.price1Scale1)
      }
      if (Number(temp2.quantity2Scale2) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2.quantity2Scale2) != 0) {
        price = Number(temp2.price2Scale2)
      }
      if (Number(temp2.quantity3Scale3) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2.quantity3Scale3) != 0) {
        price = Number(temp2.price3Scale3)
      }
      if (Number(temp2.quantity4Scale4) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2.quantity4Scale4) != 0) {
        price = Number(temp2.price4Scale4)
      }
      if (Number(temp2.quantity5Scale5) <= (parseFloat(allocatedQtd2) * packSize) && Number(temp2.quantity5Scale5) != 0) {
        price = Number(temp2.price5Scale5)
      }
      // if (allocatedQtd < Number(temp2.quantity5Scale5)) {
      //   price = Number(temp2.price5Scale5)
      // }
      temp1.price = price
      temp1.totalPrice = price * parseFloat(allocatedQtd2) * packSize;
      // console.log('pppp', price, parseFloat(allocatedQtd2), packSize)      

      setOrderedListData([...orderedList, temp1])
    } else {
      // let price = previousPrice; 
      temp1.price = price
      temp1.totalPrice = temp1.conditionAmount * parseFloat(allocatedQtd2) * packSize
      setOrderedListData([...orderedList, temp1])
    }


    setSelected(prevSelectedData => {
      const index = prevSelectedData.findIndex(item => item.orderItemId === orderItemId);
      if (index === -1) {
        return [...prevSelectedData, { orderItemId, allocatedQtd }];
      } else {
        const updatedData = [...prevSelectedData];
        updatedData[index] = { orderItemId, allocatedQtd };
        return updatedData;
      }
    });

    // setValidInputs(prevValidInputs => {
    //   const newValidInputs = { ...prevValidInputs };
    //   newValidInputs[orderItemId] = allocatedQtd >= 0;
    //   return newValidInputs;
    // });

  };



  const isSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };
  const handleSelectAllClick = (event) => {
    setDefaultLoad(false);
    if (event.target.checked) {
      setIsItemSelectedstate(true)
      let select = orderedList.map(i => {
        return { orderItemId: i.orderItemId, allocatedQtd: i.allocatedQtd }
      });
      setSelected([...selected, ...select])
      return;
    } else {
      setIsItemSelectedstate(false)
      let select = orderedList.map(i => i.orderItemId);
      let filtereddata = selected.filter(i => !select.includes(i.orderItemId));
      setSelected(filtereddata)
    }

  };

  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
        </ThemeProvider>
      </StyledEngineProvider>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow className="OrderManagementByProduct-bg-grey" style={{ borderRadius: '8px' }}>
                  <TableCell colSpan={1} align="left" style={{ borderBottom: 'none' }}>
                    <Checkbox
                      color="primary"
                      onChange={handleSelectAllClick}
                      // indeterminate={selected.length > 0 && selected.length < props.userData?.length}
                      // checked={props.userData?.length > 0 && selected.length === props.userData?.length}                   
                      checked={isItemSelectedstate}

                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" style={{ borderBottom: 'none' }} colSpan={6} sx={{ width: "600px" }}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h4>
                        Order No.&nbsp;&nbsp;&nbsp;
                        <img src={SortIcon} />
                      </h4>
                    </div>
                  </TableCell>
                  <TableCell align="center" style={{ borderBottom: 'none' }} colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h4>Ordered Quantity</h4>
                    </div>
                  </TableCell>
                  <TableCell align="center" style={{ borderBottom: 'none' }} colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h4>Qty</h4>
                    </div>
                  </TableCell>
                  <TableCell align="center" style={{ borderBottom: 'none' }} colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h4>Unit Price</h4>
                    </div>
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }} colSpan={1}>
                    <div className="odmProduct-ProductDetails-desc">
                      <h4>Total</h4>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderedList &&
                  orderedList.map((item, index) => {
                    const isItemSelected = selected.some(select => select.orderItemId === item.orderItemId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return <>
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" colSpan={1}>
                          <Checkbox
                            align="left"
                            color="primary"
                            onClick={(event) => handleClick(event, item.orderItemId, item.allocatedQtd, item)}
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          // checked={isItemSelected}
                          // inputProps={{
                          //     'aria-labelledby': labelId,
                          // }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="left" colSpan={6} sx={{ width: "600px" }}>
                          <div className="odmProduct-UserDetails">
                            <div className="odmProduct-UserDetails-userInfo-product">
                              <h4>
                                <img src={UserIconBlack} />
                                <span style={{ lineHeight: "1.2" }}>
                                  &nbsp;&nbsp;&nbsp;{item.userfirstnameen}{" "}
                                  {item.userlastnameen}
                                </span>
                              </h4>
                              <p style={{ display: "block" }}>
                                Order No.{item.orderNo}
                              </p>
                            </div>
                            <div className="odmProduct-UserDetails-userInfoDesc">
                              <h6><span>{item.userfirstnameen}{" "}
                                {item.userlastnameen}</span></h6>
                              <h6>Channel: {item.userchannel}</h6>
                              <h6>Created by <img src={UserIconBlack} />{item.usercustomercode.trim() == "" ? <span>Sales Admin</span> : <span>Customer</span>}</h6>
                              <h6>Creation Date {item.createdAt}</h6>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc">
                            <h4>{item.cartQuantity}</h4>
                          </div>
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc">
                            <input type="text"
                              name={`allocatedQtd-${index}`}
                              // className={invalidInputClass(item.orderItemId)}
                              className={"form-control" + (item.allocatedQtd ? "" : " is-invalid")}
                              // value={alocatedItems} props.remainingQuantity > 0 ? item.allocatedQtd : 
                              value={selected.find(select => select.orderItemId === item.orderItemId)?.allocatedQtd || item.allocatedQtd}
                              onChange={(event) => handleChange(event, item.orderItemId, index, item)}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc">
                            <h4>
                              <span>฿ {convertToCurrencyFormat(item.price)}</span>
                            </h4>
                          </div>
                        </TableCell>
                        <TableCell align="right" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc">
                            <h4>
                              <span>฿ {convertToCurrencyFormat(item.totalPrice)}</span>
                            </h4>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <div className="odmProduct-ProductDetails-ViewMore">
            <IconButton
              aria-label="expand row"
              size="large"
              onClick={() => setOpen(!open)}
            >
              {open ? <p>View less({orderedList.length})</p> : <p>View all orders({orderedList.length})</p>}
              {open ? <img src={KeyDownIcon} /> : <img src={KeyUpIcon} />}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];
const OrderManagementByProduct = (props) => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  const _exporter = React.createRef();
  const excelExport = () => {
    save(_exporter);
  };
  const save = (component) => {
    const options = component.current.workbookOptions();
    options.sheets[0].name = "To Be Confirmed";
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    rows.forEach((row) => {
      if (row.type === "data") {
        if (altIdx % 2 !== 0) {
          row.cells.forEach((cell) => {
            cell.background = "#aabbcc";
          });
        }
        altIdx++;
      }
    });
    component.current.save(options);
  };
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // const { t } = useTranslation();
  const lancode = localStorage.getItem("lancode");
  const toconfirmedOrderList = useSelector(
    (state) => state.order.toconfirmedOrderList
  );

  const toConfirmByProduct = useSelector(
    (state) => state.order.toConfirmByProductList
  );

  const toconfirmedOrderLoading = useSelector(
    (state) => state.order.toconfirmedOrderLoading
  );
  const [selected, setSelected] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const detaulsSort = { id: "orderCreateDate", orderBy: 1 };
  const [sortDetails, setsetSort] = React.useState(detaulsSort);
  const [loading, setLoading] = useState(false);
  const userData = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
  const store = userData ? userData.user.stores[0].storeCode : "";
  const [filtersFields, setFiltersFields] = useState({});
  const [attrFilter, setAttrFilter] = useState({});
  const [openAlert, setOpenAlert] = useState(false);

  const [mainHeading, setMainHeading] = useState("");
  const [subHeading1, setSubHeading1] = useState("");

  const [onHandQuantity, setOnHandQuantity] = useState('');
  const [updateVal, setUpdateVal] = useState(false);

  const [products, setProducts] = useState([]);
  const [showViewByProduct, setShowViewByProduct] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      history.push("/login");
    } else {
      if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
        history.push("/");
      }
    }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      const payload = {
        storeCode: store,
      };
      dispatch(
        orderActions.getToConfirmListByProduct(
          "",
          payload,
          `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(
            JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
          )}`
        )
      );
    }
  }, []);

  React.useEffect(() => {
    if (toConfirmByProduct?.data) {
      const newData = toConfirmByProduct?.data.result.map(items => {
        items.remaining = items.stock - items.onOrder
      })
      setProducts(newData);
      setLoading(false);
    } else {
      setProducts([]);
      setLoading(false);
    }
  }, [toConfirmByProduct?.data]);

  React.useEffect(() => {
    //

    if (toConfirmByProduct?.data) {
      setProducts(toConfirmByProduct.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [products]);

  React.useEffect(() => {

  }, [selected]);


  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    const payload = {
      storeCode: store,
      // "flags": { "product": true }
    };

    dispatch(
      orderActions.getToConfirmListByProduct(
        "",
        payload,
        `limit=${parseInt(
          event.target.value
        )}&skip=${page}&sort=${encodeURIComponent(
          JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
        )}`
      )
    );

    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    let pages = (newPage - 1) * rowsPerPage;
    const payload = {
      storeCode: store,
    };

    setPage(pages);
    setCurrentPage(newPage);
    dispatch(
      orderActions.getToConfirmListByProduct(
        "",
        payload,
        `limit=${parseInt(rowsPerPage)}&skip=${pages}&sort=${encodeURIComponent(
          JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
        )}`
      )
    );
  };
  // console.log('vvvv22',page)
  // console.log('vvvv3',rowsPerPage)
  const setsetSortFn = (id, sortBy) => {
    setLoading(true);
    let columnKey = id;
    if (id === "name" || id === "name") {
      columnKey = "userfirstnameen";
    } else if (id === "orderCreateDate" || id === "orderCreateDate") {
      columnKey = "ordercreatedat";
    }

    const payload = {
      storeCode: store,
    };
    dispatch(
      orderActions.getToConfirmListByProduct(
        "",
        payload,
        `limit=${parseInt(rowsPerPage)}&skip=${pages}&sort=${encodeURIComponent(
          JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
        )}`
      )
    );
    setsetSort({ ...sortDetails, id: columnKey, orderBy: sortBy });
  };

  const group = [
    {
      field: "orderitems.articaleCode",
    },
  ];

  const filterShippedList = (selectedItem) => {

    let payload = {
      storeCode: store
    }
    if (selectedItem?.channelDefaultValue) {
      payload.userChannel = selectedItem?.channelDefaultValue
    }
    if (selectedItem?.deliveryTime) {
      payload.deliveryTime = selectedItem?.deliveryTime
    }
    if (selectedItem?.deliveryDate?.startDate) {
      payload.deliveryDate = {
        from: moment(selectedItem?.deliveryDate?.startDate).format("YYYY-MM-DD"),
        to: moment(selectedItem?.deliveryDate?.endDate).format("YYYY-MM-DD")
      }
    }
    if (selectedItem?.orderDate?.startDate) {
      payload.createdAt = {
        from: moment(selectedItem?.orderDate?.startDate).format("YYYY-MM-DD"),
        to: moment(selectedItem?.orderDate?.endDate).format("YYYY-MM-DD")
      }

    }
    if (selectedItem?.categorySlug) {
      const categorySlugs = selectedItem.categorySlug[0].map(item => item.categorySlug);
      payload.categorySlug = categorySlugs
    }
    if (selectedItem?.subCategorySlug) {
      const subCategorySlug = selectedItem?.subCategorySlug.map(item => item.key);
      payload.subCategorySlug = subCategorySlug
    }
    setFiltersFields(payload)
    let merged = { ...payload, ...attrFilter };
    // dispatch(orderActions.getToConfirmListByProduct("", merged, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
    dispatch(orderActions.getToConfirmListByProduct("", merged, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
  };

  const searchItemByAttr = (query) => {
    let payload = query;
    payload.storeCode = store
    // dispatch(orderActions.getToConfirmListByProduct("", payload));
    let merged = { ...payload, ...filtersFields };
    dispatch(orderActions.getToConfirmListByProduct("", merged, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))

    setAttrFilter(payload)
  };

  React.useEffect(() => {
  }, [showViewByProduct]);

  const toggleViewByProduct = () => {
    setShowViewByProduct(true);
    // setViewByOrderActive(false)
    dispatch(orderActions.toggleComponent(true));
    // alert("toggle product");
  };

  const toggleViewByOrder = () => {
    setShowViewByProduct(false);
    // setViewByOrderActive(true)
    dispatch(orderActions.toggleComponent(false));
  };

  const handleClick = (event, onHandQuantity) => {
    // console.log("EE", event);
    // setSelected(selected);
    setSelected(event);
    setOnHandQuantity(onHandQuantity)
  };

  const handleTableDataChange = (data) => {
    setSelected(data);
    // console.log('Selected data:', data);
  };


  const saveOrder = () => {

    // const filteredData = selected.filter(item => item.length > 0);


    selected.forEach(item => {
      if (item.allocatedQtd === null || item.allocatedQtd === undefined) {
        setOpenAlert(true)
        setMainHeading("Allocated Quantity Can't be Null")
        setSubHeading1("Allocated Quantity Can't be Null. Please check your input.")
      } else if (onHandQuantity === null || onHandQuantity === 0) {
        setOpenAlert(true)
        setMainHeading("You don't have order on hand")
        setSubHeading1("You don't have order on hand, please give input when you have order on hand")
      }
      else {
        let payload = {
          storeCode: store,
          orderItems: selected,
        };
        orderUpdateApi(payload);
      }

    });

    // const flatArray = selected.flatMap((subArray) =>
    //   subArray.flatMap((innerArray) => innerArray)
    // );

    // const uniqueArray = flatArray.reduce((acc, curr) => {
    //   const existingObject = acc.find((obj) => obj.orderItemId === curr.orderItemId);
    //   if (!existingObject) {
    //     acc.push({ orderItemId: curr.orderItemId, allocatedQtd: curr.allocatedQtd });
    //   }
    //   return acc;
    // }, []);

    // console.log('aaa', uniqueArray);


  };

  const orderUpdateApi = (payload) => {
    setLoading(true);
    ApiProvider.post(ApiList.API_URL_TO_UPDATE_STATUS_SAVE, payload).then(
      (response) => {
        if (response) {
          const payload = {
            storeCode: store,
          };
          setLoading(false);
          setSelected([]);
          // setOrderStatusUpdated(true)
          dispatch(
            orderActions.getToConfirmListByProduct(
              "",
              payload,
              `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(
                JSON.stringify({ [sortDetails.id]: sortDetails.orderBy })
              )}`
            )
          );
          setLoading(false);
          // setOpenAlert(true);
          // setMainHeading("Order Saved")
          // setSubHeading1("Your order saved successfully")                              
        } else {
          setLoading(false);
          setSelected([]);
        }
      }
    ).catch(err => {
      setLoading(false);
      setOpenAlert(true);
      setMainHeading("Missing fields!")
      setSubHeading1("One of the required field is empty. Please check your input.")
      setSelected([]);
    });
  };

  const handleAlert = () => {
    setOpenAlert(false);
  };

  const data = tableData;

  const totalPages =
    products && products.totalCount
      ? Math.ceil(parseInt(products.totalCount) / parseInt(rowsPerPage))
      : null;


  const updateProductValue = (tableData /* itemsinorder.product */) => {
    setUpdateVal(!updateVal)
    // props.updatedProductsValue(products)
  }

  const setUpCartandSpecialPrices = (temp, count) => {
    // debugger
    let price;
    count = (count) ? parseInt(count) : 1;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    // if (isNull(temp.conditionAmount)) {
    if ((Number(temp.quantity1Scale1) <= (count * packSize) || Number(temp.quantity1Scale1) > (count * packSize)) && Number(temp.quantity1Scale1) != 0) {
      price = Number(temp.price1Scale1)
    }
    if (Number(temp.quantity2Scale2) <= (count * packSize) && Number(temp.quantity2Scale2) != 0) {
      price = Number(temp.price2Scale2)
    }
    if (Number(temp.quantity3Scale3) <= (count * packSize) && Number(temp.quantity3Scale3) != 0) {
      price = Number(temp.price3Scale3)
    }
    if (Number(temp.quantity4Scale4) <= (count * packSize) && Number(temp.quantity4Scale4) != 0) {
      price = Number(temp.price4Scale4)
    }
    if (Number(temp.quantity5Scale5) <= (count * packSize) && Number(temp.quantity5Scale5) != 0) {
      price = Number(temp.price5Scale5)
    }
    // if (count < Number(temp.quantity5Scale5)) {
    //   price = Number(temp.price5Scale5)
    // }
    // }
    return price;

  }

  return (
    <>
      <ThankYouForPurchaseModal
        image={AlertImage}
        cart={true}
        buttonLabel={"Ok"}
        mainHeading={mainHeading}
        subHeading1={subHeading1}
        open={openAlert}
        ClickAction={handleAlert}
      />

      <OrderManagementFilter
        filterShippedList={filterShippedList}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        searchItemByAttr={searchItemByAttr}
        toggleViewByProduct={toggleViewByProduct}
        toggleViewByOrder={toggleViewByOrder}
      />
      {/* <OrderManagementFilter /> */}
      {loading && <Loading />}
      <div className="OrderManagementByProduct">
        {/* {products && products.totalCount && (
          <h1>{products.totalCount} &nbsp; Orders</h1>
        )}
        <button
          className="export-button-main-container"
          style={{ backgroundColor: "#61A60E", width: "124px" }}
          onClick={saveOrder}
        >
          <span className="export-button-heading" style={{ color: "#ffff" }}>
            Save
          </span>
        </button> */}
        {toConfirmByProduct == null && (
          <div className="col-sm-12 col-md-12">
            <div className="table-empty-data-main-container">
              <img src={emptyTableData} className="table-empty-data-image" />
              <h6 className="table-empty-data-heading">No new order yet</h6>
            </div>
          </div>
        )}
        <div className="OrderManagementByProduct-head">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-6 col-md-6">
              {products && products.totalCount && (
                <h4>
                  {products.totalCount === 0 || !products.totalCount || products.totalCount === 1 ? products.totalCount + " Order" : products.totalCount + " Orders"}
                  {/* {products.totalCount} Orders&nbsp; */}
                  {selected && selected.length > 0 && (
                    <span className="to-confirmed-table-selected-counts">({selected.length} selected)</span>
                  )}
                </h4>
              )}

            </div>
            {toConfirmByProduct !== null && (
              <>
                <div className="col-lg-6 col-md-6 text-right">
                  <button disabled={selected.length == 0} onClick={saveOrder}>Save</button>
                </div>
              </>

            )}
          </div>
        </div>

        <TableContainer component={Paper} style={{ borderRadius: '8px', boxShadow: 'none' }}>
          {products &&
            products.result &&
            products.result.map((items, index) => (
              <>
                {filtersFields.categorySlug && filtersFields.categorySlug.length > 0 && <div className="OrderManagementByProduct-head" style={{ padding: '10px 0' }}>
                  <h5>{items.categoryEN} &nbsp;<span>({items?.orderedUserList?.length})</span></h5>
                </div>}
                <Table key={index} aria-label="collapsible table" style={{ border: '1px solid #D3D6DA', borderRadius: '8px' }}>
                  <TableHead>
                    <TableRow className="OrderManagementByProduct-bg-grey">
                      <TableCell align="left" colSpan={1}></TableCell>
                      <TableCell align="left" colSpan={3}>
                        <div className="odmProduct-ProductDetails-desc">
                          <h3>Product</h3>
                        </div>
                      </TableCell>
                      <TableCell align="left" colSpan={3}>
                        <div className="odmProduct-ProductDetails-desc">
                          <h3>Description</h3>
                        </div>
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        <div className="odmProduct-ProductDetails-desc">
                          <h3>On hand</h3>
                        </div>
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        <div className="odmProduct-ProductDetails-desc">
                          <h3>On order</h3>
                        </div>
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        <div className="odmProduct-ProductDetails-desc">
                          <h3>Remaining</h3>
                        </div>
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        <div className="odmProduct-ProductDetails-desc">
                          <h3>Unit</h3>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" colSpan={1}></TableCell>
                        <TableCell align="left" component="th" scope="row" colSpan={3}>
                          <div className="odmProduct-ProductDetails">
                            <div className="row d-flex align-items-center">
                              <div className="col-lg-4 col-md-4 pr-0">
                                <div className="odmProduct-ProductDetails-Img">
                                  <img
                                    src={
                                      (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                        (REACT_APP_BASE_URL +
                                          "/api/download?" +
                                          items.picture.split(",")[0]
                                          ? REACT_APP_BASE_URL +
                                          "/api/download?" +
                                          items.picture.split(",")[0]
                                          : blankImage)
                                        : blankImage
                                    }
                                  />
                                </div></div>
                              <div className="col-lg-8 col-md-8">
                                <h5>{items.consumerNameEN}</h5>
                              </div>
                            </div>
                            {/* <div className="odmProduct-UserDetails-userInfoDesc">
                              <h6><span>{items.userfirstnameen}{" "}
                                {items.userlastnameen}</span></h6>
                              <h6>Channel: {items.userchannel}</h6>
                              <h6>Created by <img src={UserIconBlack}/>{items.usercustomercode.trim() == "" ? <span>Sales Admin</span>:<span>Customer</span>}</h6>
                              <h6>Creation Date {items.createdAt}</h6>
                            </div> */}
                          </div>
                        </TableCell>
                        <TableCell key={index} align="left" colSpan={3}>
                          <>
                            <div className="auto-complete-order-by-product-table">
                              {items.selectValues.length > 1 ?
                                <select style={{ fontSize: "16px", }}
                                  value={items.articaleCode}
                                  onChange={(e) => {
                                    let temp = items.selectValues
                                    let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                    let temp2 = items
                                    temp2.consumerNameEN = selectedItem.consumerNameEN;
                                    temp2.productNameEN = selectedItem.productNameEN;
                                    temp2.picture = selectedItem.picture;
                                    temp2.articaleCode = selectedItem.articaleCode;
                                    temp2.price1Scale1 = selectedItem.price1Scale1;
                                    temp2.packSizeEN = selectedItem.packSizeEN;
                                    temp2.stock = selectedItem.stock;
                                    //  
                                    temp2.packSize = selectedItem.packSize;
                                    temp2.price1Scale1 = selectedItem.price1Scale1;
                                    temp2.price2Scale2 = selectedItem.price2Scale2;
                                    temp2.price3Scale3 = selectedItem.price3Scale3;
                                    temp2.price4Scale4 = selectedItem.price4Scale4;
                                    temp2.price5Scale5 = selectedItem.price5Scale5;
                                    temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                    temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                    temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                    temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                    temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                    temp2.conditionAmount = selectedItem.conditionAmount;

                                    temp2.orderedUserList.map(i => {

                                      i.price = isNull(i.conditionAmount) ? setUpCartandSpecialPrices(selectedItem, i.cartQuantity) : i.conditionAmount;
                                      i.totalPrice = (isNull(i.conditionAmount) ? setUpCartandSpecialPrices(selectedItem, i.cartQuantity) : i.conditionAmount) * (i.allocatedQtd ? parseFloat(i.allocatedQtd) : parseFloat(i.cartQuantity)) * (selectedItem.packSize ? parseFloat(selectedItem.packSize) : 1);
                                      return i;
                                    })
                                    // 
                                    temp.map((val) => {
                                      return val?.articaleCode == selectedItem?.articaleCode ? val.select = true : val.select = false
                                    })

                                    updateProductValue(true)
                                  }}
                                >
                                  {
                                    items.selectValues.map((i, ind) => {
                                      return (<option selected={i.select} value={i.articaleCode} key={ind}>{i.articaleCode + "  " + i.productNameTH}</option>);
                                    })
                                  }
                                </select> :
                                lancode === "en" ? <h3><span>{items.selectValues[0].articaleCode}</span><br></br>{items.selectValues[0].productNameEN}</h3> : <h3> <span>{items.selectValues[0].articaleCode}</span><br></br>{items.selectValues[0].productNameTH}</h3>}
                            </div>
                          </>
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc">
                            {items.stock ? <h4>{items.stock}</h4> : <h4>0</h4>}
                          </div>
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc">
                            {items.onOrder ? (
                              <h4>{items.onOrder}</h4>
                            ) : (
                              <h4>0</h4>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc">
                            {/* const remainingQty = items.stock - items.cartQuantity */}
                            <h4>{items.remaining}</h4>
                          </div>
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          <div className="odmProduct-ProductDetails-desc">
                            <h4>{items.saleUnitEN}</h4>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                  <TableBody>
                    <Row
                      userData={items.orderedUserList}
                      allUserData={items}
                      onSelectAllClick={handleClick}
                      rowCount={items.length}
                      onTableDataChange={handleTableDataChange}
                      selectedData={selected}
                      remainingQuantity={items.remaining}
                    />
                  </TableBody>
                </Table>
              </>
            ))}
        </TableContainer>
        {/* pagination-section start from here */}
        <div className='row order-table-pagination-main-container'>
          <div className='col-sm-7 order-table-pagination-inner-container'>
            <div className="d-flex flex-row">
              <h6 className='order-table-pagination-count'>1-{props.rowsPerPage} of {props.totalCount}</h6>
              <h6 className='order-table-order-per-page'>Product per page</h6>
              <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                <Select
                  variant="outlined"
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  displayEmpty
                  sx={{
                    "&:hover": {
                      "&& fieldset": {
                        border: "2px solid #61A60E;"
                      }
                    }
                  }}
                  inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ position: "absolute", width: 11, height: 7, left: 37, bottom: 37, cursor: "pointer" }} /> }}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {/* pagination-section start from here */}
          <div className='col-sm-5 order-table-pagination-inner-container p-0 m-0'>
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                variant="outlined"
                shape="rounded"
                onChange={(event, page) => handleChangePage(event, page)}
              // onChange={props.handleChangePage}
              />
            </Stack>
          </div>
          {/* pagination-section end */}
        </div>
        {/* pagination-section end */}
      </div>
    </>
  );
};

export { OrderManagementByProduct as OrderManagementByProduct };


