import { orderConstants } from "../_constants";
import { customLogout } from '../_helpers/commanFunction';
import { orderService } from "../_services";

export const orderActions = {
    createcustomerdraftorder,
    createcustomerorder,
    getPostalCodeInfo,
    getNewOrderList,
    getConfrimedOrderList,
    getToConfirmList,
    getShippedList,
    getCompletedList,
    getCancelledList,
    getDraftList,
    getConfrimedOrderListexcel,
    getToConfirmListexcel,
    getToConfirmListByProduct,
    toggleComponent,
    addOrderItems,
    updateItemStatus,
    updateStatus,
    activeList,
    historyList,
    toReview,
    userReorder,
    updateCancellationDetails,
    getbyorderID,
    reOrderProduct,
    orderDiscard,
    getOrderItemsByOrder,
    orderSubmitDraft,
    orderSubmitConfirm,
    removeOrderItems,

};


function orderDiscard(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.orderDiscard(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.ORDER_DISCARD__ALL };
    }
    function success(data) {
        return { type: orderConstants.ORDER_DISCARD__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.ORDER_DISCARD__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.ORDER_DISCARD__ALL_FAILURE, error };

    }
}

function getOrderItemsByOrder(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.getOrderItemsByOrder(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_ORDER_ITEMS_BY_ORDER__ALL };
    }
    function success(data) {
        return { type: orderConstants.GET_ORDER_ITEMS_BY_ORDER__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.GET_ORDER_ITEMS_BY_ORDER__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.GET_ORDER_ITEMS_BY_ORDER__ALL_FAILURE, error };

    }
}

function orderSubmitDraft(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.orderSubmitDraft(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.ORDER_SUBMIT_DRAFT__ALL };
    }
    function success(data) {
        return { type: orderConstants.ORDER_SUBMIT_DRAFT__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.ORDER_SUBMIT_DRAFT__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.ORDER_SUBMIT_DRAFT__ALL_FAILURE, error };

    }
}

function orderSubmitConfirm(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.orderSubmitConfirm(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.ORDER_SUBMIT_CONFIRM__ALL };
    }
    function success(data) {
        return { type: orderConstants.ORDER_SUBMIT_CONFIRM__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.ORDER_SUBMIT_CONFIRM__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.ORDER_SUBMIT_CONFIRM__ALL_FAILURE, error };

    }
}

function removeOrderItems(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.removeOrderItems(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.REMOVE_ORDER_ITEMS__ALL };
    }
    function success(data) {
        return { type: orderConstants.REMOVE_ORDER_ITEMS__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.REMOVE_ORDER_ITEMS__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.REMOVE_ORDER_ITEMS__ALL_FAILURE, error };

    }
}


function activeList(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.activeList(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 400) {
                    dispatch(noDataFound(res.response.data));
                }
                else if(res?.response?.data?.code && res.response.data.code===409){
                    // customLogout()
                  }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.ACTIVELIST_ORDER__ALL };
    }
    function success(data) {
        return { type: orderConstants.ACTIVELIST_ORDER__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.ACTIVELIST_ORDER__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.ACTIVELIST_ORDER__ALL_FAILURE, error };

    }
}


function historyList(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.historyList(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 400) {
                    dispatch(noDataFound(res.response.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.HISTORYLIST_ORDER__ALL };
    }
    function success(data) {
        return { type: orderConstants.HISTORYLIST_ORDER__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.HISTORYLIST_ORDER__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.HISTORYLIST_ORDER__ALL_FAILURE, error };

    }
}


function toReview(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.toReview(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 400) {
                    dispatch(noDataFound(res.response.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.TOREVIEW_ORDER__ALL };
    }
    function success(data) {
        return { type: orderConstants.TOREVIEW_ORDER__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.TOREVIEW_ORDER__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.TOREVIEW_ORDER__ALL_FAILURE, error };

    }
}


function userReorder(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.userReorder(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.USER_REORDER_ORDER__ALL };
    }
    function success(data) {
        return { type: orderConstants.USER_REORDER_ORDER__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.USER_REORDER_ORDER__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.USER_REORDER_ORDER__ALL_FAILURE, error };

    }
}


function updateCancellationDetails(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.updateCancellationDetails(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.UPDATE_CANCELLATION_DETAILS_ORDER__ALL };
    }
    function success(data) {
        return { type: orderConstants.UPDATE_CANCELLATION_DETAILS_ORDER__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.UPDATE_CANCELLATION_DETAILS_ORDER__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.UPDATE_CANCELLATION_DETAILS_ORDER__ALL_FAILURE, error };

    }
}


function getbyorderID(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        orderService.getbyorderID(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_BY_ORDER_ID__ALL };
    }
    function success(data) {
        return { type: orderConstants.GET_BY_ORDER_ID__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.GET_BY_ORDER_ID__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.GET_BY_ORDER_ID__ALL_FAILURE, error };

    }
}


function updateStatus(type, payload) {
    return (dispatch) => {
        dispatch(request());
        orderService.updateStatus(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.UPDATE_ORDER_STATUS__ALL };
    }
    function success(data) {
        return { type: orderConstants.UPDATE_ORDER_STATUS__ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.UPDATE_ORDER_STATUS__ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.UPDATE_ORDER_STATUS__ALL_FAILURE, error };

    }
}


function updateItemStatus(type, payload) {
    return (dispatch) => {
        dispatch(request());
        orderService.updateItemStatus(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.UPDATE_ITEMS_STATUS_ORDER_ALL };
    }
    function success(data) {
        return { type: orderConstants.UPDATE_ITEMS_STATUS_ORDER_ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.UPDATE_ITEMS_STATUS_ORDER_ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.UPDATE_ITEMS_STATUS_ORDER_ALL_FAILURE, error };

    }
}

function addOrderItems(type, payload) {
    return (dispatch) => {
        dispatch(request());
        orderService.addOrderItems(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.ADD_TO_CONFIRM_ITEMS_ORDER_ALL };
    }
    function success(data) {
        return { type: orderConstants.ADD_TO_CONFIRM_ITEMS_ORDER_ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.ADD_TO_CONFIRM_ITEMS_ORDER_ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.ADD_TO_CONFIRM_ITEMS_ORDER_ALL_FAILURE, error };

    }
}

function createcustomerdraftorder(type, payload) {
    return (dispatch) => {
        dispatch(request());
        orderService.createcustomerdraftorder(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.CREATE_CUSTOMER_DRAFT_ORDER_ALL };
    }
    function success(data) {
        return { type: orderConstants.CREATE_CUSTOMER_DRAFT_ORDER_ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.CREATE_CUSTOMER_DRAFT_ORDER_ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.CREATE_CUSTOMER_DRAFT_ORDER_ALL_FAILURE, error };

    }
}

function createcustomerorder(type, payload) {
    return (dispatch) => {
        dispatch(request());
        orderService.createcustomerorder(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.CREATE_CUSTOMER_ORDER_ALL };
    }
    function success(data) {
        return { type: orderConstants.CREATE_CUSTOMER_ORDER_ALL_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.CREATE_CUSTOMER_ORDER_ALL_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.CREATE_CUSTOMER_ORDER_ALL_FAILURE, error };

    }
}




function getPostalCodeInfo(type, payload) {

    return (dispatch) => {
        dispatch(request());
        orderService.getPostalCodeInfo(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_POSTAL_INFO };
    }
    function success(data) {
        return { type: orderConstants.GET_POSTAL_INFO_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.GET_POSTAL_INFO_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.GET_POSTAL_INFO_FAILURE, error };

    }
}

function getNewOrderList(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getnewOrderList(payload, queryString)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound());
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => {
                dispatch(failure(error))
            });
    }
    function request() {
        return { type: orderConstants.GET_ALL_NEW_ORDER_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_NEW_ORDER_SUCCESS, data };
    }
    function noDataFound() {
        return { type: orderConstants.GET_ALL_NEW_ORDER_NODATA };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_NEW_ORDER_FAILURE, error };

    }
}



function getShippedList(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getShippedList(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound());
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => {

                dispatch(failure(error))
            });
    }
    function request() {
        return { type: orderConstants.GET_ALL_SHIPPED_ORDER_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_SHIPPED_ORDER_SUCCESS, data };
    }
    function noDataFound() {
        return { type: orderConstants.GET_ALL_SHIPPED_ORDER_NODATA };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_SHIPPED_ORDER_FAILURE, error };

    }
}


function getCompletedList(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getCompletedList(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound());
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_ALL_COMPLETED_ORDER_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_COMPLETED_ORDER_SUCCESS, data };
    }
    function noDataFound() {
        return { type: orderConstants.GET_ALL_COMPLETED_ORDER_NODATA };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_COMPLETED_ORDER_FAILURE, error };

    }
}


function getCancelledList(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getCancelledList(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_ALL_CANCELLED_ORDER_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_CANCELLED_ORDER_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.GET_ALL_CANCELLED_ORDER_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_CANCELLED_ORDER_FAILURE, error };

    }
}


function getDraftList(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getDraftList(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound());
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_ALL_DRAFT_ORDER_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_DRAFT_ORDER_SUCCESS, data };
    }
    function noDataFound() {
        return { type: orderConstants.GET_ALL_DRAFT_ORDER_NODATA };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_DRAFT_ORDER_FAILURE, error };

    }
}


function getToConfirmList(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getToConfirmList(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_ALL_TO_CONFIRM_ORDER_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_TO_CONFIRM_ORDER_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.GET_ALL_TO_CONFIRM_ORDER_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_TO_CONFIRM_ORDER_FAILURE, error };

    }
}



function getToConfirmListByProduct(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getToConfirmListByProduct(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound());
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_TO_CONFIRM_VIEW_BY_PRODUCT_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_TO_CONFIRM_VIEW_BY_PRODUCT_SUCCESS, data };
    }
    function noDataFound() {
        return { type: orderConstants.GET_TO_CONFIRM_VIEW_BY_PRODUCT_NODATA };
    }
    function failure(error) {
        return { type: orderConstants.GET_TO_CONFIRM_VIEW_BY_PRODUCT_FAILURE, error };

    }
}

function getConfrimedOrderList(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getConfrimedOrderList(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound());
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_ALL_CONFIRMED_ORDER_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_CONFIRMED_ORDER_SUCCESS, data };
    }
    function noDataFound() {
        return { type: orderConstants.GET_ALL_CONFIRMED_ORDER_NODATA };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_CONFIRMED_ORDER_FAILURE, error };

    }
}

function getShippedOrders(type, payload) {
    return (dispatch) => {
        dispatch(request());
        orderService.getShippedOrderList(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound());
                }
            })
            .catch((error) => {

                dispatch(failure(error))
            });
    }
    function request() {
        return { type: orderConstants.GET_ALL_SHIPPED_ORDER_REQUEST };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_SHIPPED_ORDER_SUCCESS, data };
    }
    function noDataFound() {
        return { type: orderConstants.GET_ALL_SHIPPED_ORDER_NODATA };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_SHIPPED_ORDER_FAILURE, error };

    }
}


function getToConfirmListexcel(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getToConfirmListexcel(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_ALL_TO_CONFIRMED_ORDER_REQUEST_EXCEL };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_TO_CONFIRMED_ORDER_SUCCESS_EXCEL, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.GET_ALL_TO_CONFIRMED_ORDER_NODATA_EXCEL, data };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_TO_CONFIRMED_ORDER_FAILURE_EXCEL, error };

    }
}

function toggleComponent(data) {
    if (data == true) {
        return (dispatch) => {
            dispatch(successTrue(data));
        }
    }
    if (data == false) {
        return (dispatch) => {
            dispatch(successFalse(data));
        }
    }
    function successTrue(data) {
        return { type: orderConstants.TOGGLE_VALUE_TRUE, data }
    }

    function successFalse(data) {
        return { type: orderConstants.TOGGLE_VALUE_FALSE, data }
    }
}


function getConfrimedOrderListexcel(type, payload, queryString) {

    return (dispatch) => {
        dispatch(request());
        orderService.getConfrimedOrderListexcel(payload, queryString)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.GET_ALL_CONFIRMED_ORDER_REQUEST_EXCEL };
    }
    function success(data) {
        return { type: orderConstants.GET_ALL_CONFIRMED_ORDER_SUCCESS_EXCEL, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.GET_ALL_CONFIRMED_ORDER_NODATA_EXCEL, data };
    }
    function failure(error) {
        return { type: orderConstants.GET_ALL_CONFIRMED_ORDER_FAILURE_EXCEL, error };

    }
}

function reOrderProduct(type, payload) {
    return (dispatch) => {
        dispatch(request());
        orderService.reOrderProduct(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: orderConstants.RE_ORDER_PRODUCT };
    }
    function success(data) {
        return { type: orderConstants.RE_ORDER_PRODUCT_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: orderConstants.RE_ORDER_PRODUCT_NODATA, data };
    }
    function failure(error) {
        return { type: orderConstants.RE_ORDER_PRODUCT_FAILURE, error };

    }
}



