import { Popper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { StyledEngineProvider } from '@mui/material/styles';
import _ from 'lodash';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; //
import ApiProvider from "../../../_services/ApiProvider";
import { REACT_APP_BASE_URL } from "../../../constant";
import Search from '../../../images/Search.png';
import arrowIcon from '../../../images/small-chevron-down.png';
import ArrowGrey from "./Assets/SVG/ArrowGrey.svg";
import Calander from "./Assets/SVG/Calander.svg";
import "./OrderManagementFilter.css";

const OrderManagementFilter = (props) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [showDateRangePicker2, setShowDateRangePicker2] = useState(false);
  const [selectionDate, setSectionDate] = useState(false);
  const [currentSelectionStartDate, setCurrentSelectionStartDate] = useState();
  const [currentSelectionEndDate, setCurrentSelectionEndDate] = useState();
  const [selectionDate2, setSectionDate2] = useState(false);
  const [currentSelectionStartDate2, setCurrentSelectionStartDate2] = useState();
  const [currentSelectionEndDate2, setCurrentSelectionEndDate2] = useState();
  const [viewByOrderActive, setViewByOrderActive] = useState(true);
  const [Category, setCategory] = useState([])
  const [categoryDefaultValue, setCategoryDefaultValue] = useState([])
  const [SabCategory, setSabCategory] = useState([])
  const [sabCategoryDefaultValue, setSabCategoryDefaultValue] = useState([])
  const [channel, setChannel] = useState(["Food Service", "Manager-Shop"])
  const [channelDefaultValue, setChannelDefaultValue] = useState("")
  const [deliveryTime, setDeliveryTime] = useState("")
  const [selectionForSearch, setSelectionForSearch] = useState("Order No.")
  // const [searchSelection, setSearchSelection] = useState();
  const lancode = localStorage.getItem("lancode");


  const sendQuery = (query, selectionForSearch) => { SearchAlgo(query, selectionForSearch) };
  const delayedQuery = useCallback(_.debounce((q, selectionForSearch) => sendQuery(q, selectionForSearch), 500), []);

  const handleSelect = (ranges) => {
    setSectionDate(true);
    setCurrentSelectionStartDate(ranges.selection.startDate);
    setCurrentSelectionEndDate(ranges.selection.endDate);
    props.filterShippedList({
      "channelDefaultValue": channelDefaultValue,
      "orderDate": { "startDate": ranges.selection.startDate, "endDate": ranges.selection.endDate },
      "deliveryDate": { "startDate": currentSelectionStartDate2, "endDate": currentSelectionEndDate2 },
      "deliveryTime": deliveryTime
    })
    setShowDateRangePicker(false);

  };
  const handleSelect2 = (ranges) => {
    setSectionDate2(true);
    setCurrentSelectionStartDate2(ranges.selection.startDate);
    setCurrentSelectionEndDate2(ranges.selection.endDate);
    props.filterShippedList({
      "channelDefaultValue": channelDefaultValue,
      "orderDate": { "startDate": currentSelectionStartDate, "endDate": currentSelectionEndDate },
      "deliveryDate": { "startDate": ranges.selection.startDate, "endDate": ranges.selection.endDate },
      "deliveryTime": deliveryTime
    })
    setShowDateRangePicker2(false);
  };
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const selectionRange2 = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  // const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    props.filterShippedList({
      "channelDefaultValue": channelDefaultValue,
      "orderDate": { "startDate": currentSelectionStartDate, "endDate": currentSelectionEndDate },
      "deliveryDate": { "startDate": ranges.selection.startDate, "endDate": ranges.selection.endDate },
      "deliveryTime": event.target.value

    })

    setAge(event.target.value);
  };

  const [orderStartDate, setOrderStartDate] = useState(new Date());
  const [orderEndDate, setOrderEndDate] = useState(new Date());

  const [userQuery, setUserQuery] = useState("");

  const userData = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
  const storeCode = userData && userData.user ? userData.user.stores[0].storeCode : '';
  useEffect(() => {
    // getCategories()
  }, [])

  const getCategories = () => {
    const payload = {
      "storeCode": userData && userData.user ? userData.user.stores[0].storeCode : '',
    }
    ApiProvider.post(REACT_APP_BASE_URL + "/api/product/filters", payload).then((response) => {
      setCategory(response.data.data.category)
    })
  }
  const isNull = (value) => {
    return value == "" || value == undefined || value == null
  }

  const SearchAlgo = (query, selectionForSearch) => {
    if (query.length !== 0 && query.length > 2) {

      if (selectionForSearch === "Order No.") {
        props.searchItemByAttr({
          "searchOrderNo": query,
          "storeCode": storeCode
        })
      } else if (selectionForSearch === "Customer Name") {
        props.searchItemByAttr({
          "searchCustomerName": query,
          "storeCode": storeCode
        })
      } else if (selectionForSearch === "Customer ID") {
        props.searchItemByAttr({
          "searchCustomerCode": query,
          "storeCode": storeCode
        })
      } else if (selectionForSearch === "Product") {
        props.searchItemByAttr({
          "searchItem": query,
          "storeCode": storeCode
        })
      }
    } else {
      props.searchItemByAttr({
        "storeCode": storeCode
      })
    }
  }

  return <>
    <StyledEngineProvider injectFirst>
      <div className="OrderManagementFilter">
        {/* row one start here */}
        <div className="row2 d-flex align-items-center marginTop30">
          <div className="col-lg-8 col-md-8">
            <div className="OrderManagementFilter-order">
              <div className="row">
                <div className="col-lg-3 col-md-3 pr-0">
                  <div className="OrderManagementFilter-orderType">

                    <FormControl variant="standard" sx={{}}>
                      <Select
                        variant="outlined"
                        sx={{
                          height: "44px",
                          fontSize: "20px",
                        }}
                        value={selectionForSearch}
                        onChange={(e) => {

                          setSelectionForSearch(e.target.value)
                          setUserQuery("")
                        }}
                        displayEmpty
                        inputProps={{ IconComponent: () => <img src={arrowIcon} className="order-number-search-arrow-icon" /> }}
                      >
                        <MenuItem
                          value="Order No."
                          sx={{
                            fontSize: "20px",
                            color: "#989CA6",
                          }}
                        >
                          Order No.
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontSize: "20px",
                            color: "#989CA6",
                          }}
                          value={"Customer Name"}
                        >
                          Customer Name
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontSize: "20px",
                            color: "#989CA6",
                          }}
                          value={"Customer ID"}
                        >
                          Customer ID
                        </MenuItem>
                        {/* <MenuItem
                            sx={{
                              fontSize: "20px",
                              color: "#989CA6",
                            }}
                            value={"Product"}
                          >
                            Product
                          </MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 pl-0 order-management-autocomplete">
                  <input type="text" value={userQuery} onChange={(e) => {
                    setUserQuery(e.target.value);
                    delayedQuery(e.target.value, selectionForSearch);
                  }} placeholder={"Search by " + selectionForSearch} className="search-by-order-number-input" />
                  <img src={Search} className="search-by-order-number-input-icon" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-4 8 pl-0">
              <div className="OrderManagementFilter-orderBtn">
                <button className={viewByOrderActive ? "view-by-order-active-button view-by-order-border-radius" : "view-by-order-disable-button view-by-order-border-radius"} onClick={() => setViewByOrderActive(true)}>View By Order</button>
                <button className={!viewByOrderActive ? "view-by-order-active-button view-by-product-border-radius" : "view-by-order-disable-button view-by-product-border-radius"} onClick={() => setViewByOrderActive(false)}>View By Product</button>
              </div>
            </div> */}
        </div>

        {/* row one end here */}
        {/* row two start here */}
        <div className="row mt-25">
          <div className="col-lg-8 col-md-8">
            <div className="row">
              <div className="col-lg-6 col-md-6 category-filter-main-container">
                <label>Category</label>
                <Autocomplete
                  multiple
                  id={Math.random().toString(36).substring(7)}
                  onOpen={() => {
                  }}
                  options={Category}
                  popupIcon={<img src={arrowIcon} className="category-filter-arrow-icon" />}
                  getOptionLabel={option => option['categoryEN']}
                  defaultValue={categoryDefaultValue}
                  onChange={(e, value) => {
                    props.filterShippedList({ "categoryDefaultValue": value })
                    setCategoryDefaultValue(value)
                    setSabCategory(value[0].subCategory)
                    setSabCategoryDefaultValue(null)
                  }}
                  disabled={true}
                  openOnFocus
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                    />
                  )}
                  PopperComponent={(props) => {
                    return <Popper {...props} placement="bottom" />;
                  }}
                />

                {/* <Autocomplete
                  sx={{
                    fontSize: "20px",
                    borderRadius: "5px",
                    color: "#464B55",
                    padding: "5px",
                    display: "flex",
                    height: "44px",
                  }}
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={Category}
                  getOptionLabel={(option) => option.categoryEN}
                  renderInput={(params) => <TextField {...params} label="" />}
                /> */}
              </div>
              <div className="col-lg-6 col-md-6 pl-0 sub-category-filter-main-container">
                <label>Subcategory</label>
                <Autocomplete
                  multiple
                  id={Math.random().toString(36).substring(7)}
                  onOpen={() => {
                  }}
                  disabled={true}
                  popupIcon={<img src={arrowIcon} className="category-filter-arrow-icon" />}
                  defaultValue={sabCategoryDefaultValue}
                  options={SabCategory}
                  getOptionLabel={option => option['subCategoryEN']}
                  onChange={(e, value) => {
                    // 
                    props.filterShippedList({
                      "categoryDefaultValue": categoryDefaultValue,
                      "sabCategoryDefaultValue": value
                    })
                    setSabCategoryDefaultValue(value)
                  }}
                  openOnFocus
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                    />
                  )}
                  PopperComponent={(props) => {
                    return <Popper {...props} placement="bottom" />;
                  }}
                />
                {/* <Autocomplete
                  sx={{
                    fontSize: "20px",
                    borderRadius: "5px",
                    color: "#464B55",
                    padding: "5px",
                    display: "flex",
                  }}
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={Category}
                  getOptionLabel={(option) => option.categoryEN}
                  defaultValue={[Category[1], Category[2], Category[3]]}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        fontSize: "20px",
                        color: "#989CA6",
                        display: "block",
                      }}
                      {...params}
                      label=""
                      placeholder=""
                    />
                  )}
                /> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="row">
              <div className="col-lg-7 col-md-7 pl-0 channel-category-main-container">
                <label>Channel</label>
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: "150px",
                    fontSize: "20px",
                    height: "36px",
                  }}>
                  <Select
                    variant="outlined"
                    sx={{
                      minWidth: "150px",
                      fontSize: "20px",
                      borderRadius: "8px",
                      color: "#464B55",
                      padding: "0",
                      height: "36px",
                    }}
                    onChange={(e) => {
                      props.filterShippedList({
                        "channelDefaultValue": e.target.value,
                        "orderDate": { "startDate": currentSelectionStartDate, "endDate": currentSelectionEndDate },
                        "deliveryDate": { "startDate": currentSelectionStartDate2, "endDate": currentSelectionEndDate2 },
                        "deliveryTime": deliveryTime
                      })
                      setChannelDefaultValue(e.target.value)

                    }}
                    value={channelDefaultValue}
                    displayEmpty
                    inputProps={{ IconComponent: () => <img src={arrowIcon} className="delivery-time-arrow-image" /> }}
                  >
                    <MenuItem value="">All Channel</MenuItem>
                    <MenuItem value="Food Service">Food Service</MenuItem>
                    <MenuItem value="Industry">Industry</MenuItem>
                    <MenuItem value="Traditional-Trade">Traditional Trade</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* <div className="col-lg-7 col-md-7 pl-0 channel-category-main-container">
                  <label>Channel</label>
                  <Autocomplete
                    // multiple
                    id={Math.random().toString(36).substring(7)}
                    onOpen={() => {
                    }}
                    defaultValue={channelDefaultValue}
                    options={channel}
                    getOptionLabel={option => {
                      return option
                    }}
                    onChange={(e, value) => {
                      //
                      props.filterShippedList({
                        "channelDefaultValue": value,
                        "orderDate": { "startDate": currentSelectionStartDate, "endDate": currentSelectionEndDate },
                        "deliveryDate": { "startDate": currentSelectionStartDate2, "endDate": currentSelectionEndDate2 },
                        "deliveryTime": deliveryTime
                      })
                      setChannelDefaultValue(value)
                    }}
                    openOnFocus
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                      />
                    )}
                    PopperComponent={(props) => {
                      return <Popper {...props} placement="bottom" />;
                    }}
                  />
                </div> */}
            </div>
          </div>
        </div>

        {/* row two end here */}
        <div className="row mt-25">
          <div className="row">
            <div className="col-lg-3 col-md-3 order-date-filter-main-container">
              <div className="OrderManagementFilter-datepicker">
                <label>Order Date</label>
                <div className="OrderManagementFilter-datepicker-date">
                  <button
                    className="OrderManagementFilter-datepicker-dateButton"
                    onClick={() => setShowDateRangePicker(true)}
                  >
                    <img src={Calander} />
                    {selectionDate
                      ? `${moment(currentSelectionStartDate).format("DD/MM/YYYY")} - ${moment(currentSelectionEndDate).format("DD/MM/YYYY")}`
                      : "Select Date"}
                    <img src={ArrowGrey} />
                  </button>
                  {showDateRangePicker ? (
                    <div className="order-date-calendar">
                      <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 pl-0 delivery-date-filter-main-container">
              <div className="OrderManagementFilter-dateicker">
                <label>Delivery Date</label>
                <div className="OrderManagementFilter-datepicker-date">
                  <button
                    className="OrderManagementFilter-datepicker-dateButton"
                    onClick={() => setShowDateRangePicker2(true)}
                  >
                    <img src={Calander} />
                    {selectionDate2
                      ? `${moment(currentSelectionStartDate2).format("DD/MM/YYYY")} - ${moment(currentSelectionEndDate2).format("DD/MM/YYYY")}`
                      : "Select Date"}
                    <img src={ArrowGrey} />
                  </button>
                  {showDateRangePicker2 ? (
                    <div className="delivery-date-calendar">
                      <DateRangePicker
                        ranges={[selectionRange2]}
                        onChange={handleSelect2}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 pl-0 delivery-inner-date-filter-main-container">
              <div className="OrderManagementFilter-select">
                <label>Delivery Time</label>
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: "150px",
                    fontSize: "20px",
                    height: "36px",
                  }}>
                  <Select
                    variant="outlined"
                    value={deliveryTime}
                    // onChange={handleChange}
                    onChange={(e) => {
                      props.filterShippedList({
                        "channelDefaultValue": channelDefaultValue,
                        "orderDate": { "startDate": currentSelectionStartDate, "endDate": currentSelectionEndDate },
                        "deliveryDate": { "startDate": currentSelectionStartDate2, "endDate": currentSelectionEndDate2 },
                        "deliveryTime": e.target.value
                      })
                      setDeliveryTime(e.target.value)
                      //
                    }}
                    displayEmpty
                    inputProps={{ IconComponent: () => <img src={arrowIcon} className="delivery-time-arrow-image" /> }}
                    sx={{
                      width: "150px",
                      fontSize: "20px",
                      borderRadius: "8px",
                      color: "#464B55",
                      padding: "0",
                      height: "36px",
                    }}>
                    <MenuItem value="">All Time</MenuItem>
                    <MenuItem value={"9.00 - 12.00"}>09.00 - 12.00</MenuItem>
                    <MenuItem value={"12.00 - 15.00"}>12.00 - 15.00</MenuItem>
                    <MenuItem value={"15.00 - 18.00"}>15.00 - 18.00</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 pl-0 d-flex align-items-center justify-content-end mt-5 p-0 filter-order-per-page-image-main-container">
              <label style={{ width: "unset" }} className="m-0">
                Order per page &nbsp;
              </label>
              <FormControl
                variant="outlined"
                sx={{
                  width: "60px",
                  fontSize: "20px",
                  borderRadius: "8px",
                  color: "#464B55",
                  padding: "0",
                  height: "36px",
                }}>
                <Select
                  variant="outlined"
                  value={props.rowsPerPage}
                  onChange={props.handleChangeRowsPerPage}
                  displayEmpty
                  inputProps={{ IconComponent: () => <img src={arrowIcon} className="filter-order-per-page-image" /> }}
                  sx={{
                    minWidth: "60px",
                    fontSize: "20px",
                    borderRadius: "8px",
                    color: "#464B55",
                    padding: "0",
                    height: "36px",
                    "&:hover": {
                      "&& fieldset": {
                        border: "2px solid #61A60E;"
                      }
                    }
                  }}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </StyledEngineProvider>
  </>;
};

export { OrderManagementFilter as OrderManagementFilter };

