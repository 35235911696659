import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../SharedComponent/Loader/Loader";
import { orderActions } from "../_actions";
import ApiList from "../_constants/const.api";
import ApiProvider from "../_services/ApiProvider";
import { REACT_APP_BASE_URL } from "../constant";
import arrowIcon from "../images/small-chevron-down.png";
import Add from "./Assets/SVG/Add.svg";
import Delete from "./Assets/SVG/Delete.svg";
import Edit from "./Assets/SVG/Edit.svg";
import TaxInvoicee from "./Assets/SVG/TaxInvoicee.svg";
import "./CustomerCheckout.css";

const TaxInvoice = (props) => {
  const { t } = useTranslation();
  const [deliveryTime, setDeliveryTime] = React.useState(null);
  const [enableSaveBtn, setEnableSaveBtn] = useState(true);
  const [selectedPayment, setSelectedPayment] =
    React.useState("Cash on delivery");
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));
  const orderData = useSelector((state) => state.order);
  const [loading, setLoading] = useState(false);
  const [taxInvoiceData, setTaxInvoiceData] = React.useState([
    {
      label: "Company name",
      value: "",
      error: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Tax ID",
      value: "",
      error: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Phone number",
      value: "",
      error: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Address",
      value: "",
      error: false,
      disabled: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Province",
      value: "",
      error: false,
      disabled: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "District",
      value: "",
      error: false,
      disabled: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Sub-District",
      value: "",
      error: false,
      disabled: false,
      errorMessage: "Please fill the required field!",
    },
    {
      label: "Postal Code",
      value: "",
      error: false,
      disabled: true,
      errorMessage: "Please fill the required field!",
    },
  ]);
  const [customerProvince, setCustomerProvince] = React.useState(null);
  const [paymentMethod, setPaymentMethod] = React.useState("Cash on delivery");
  const [showInvoiceForm, setShowInvoiceForm] = React.useState(false);

  const [customerDistrict, setCustomerDistrict] = React.useState(null);
  const [customerSubDistrict, setCustomerSubDistrict] = React.useState(null);

  const [provinceDdOptions, setProvinceDdOptions] = React.useState([]);
  const [districtDdOptions, setDistrictDdOptions] = React.useState([]);
  const [subDistrictDdOptions, setSubDistrictDdOptions] = React.useState([]);
  let [taxInvoice, setTaxInvoice] = React.useState([]);
  const [editTaxInvoice, setEditTaxInvoice] = React.useState(false);
  const [invoiceFlag, setInvoiceFlag] = React.useState(true);
  const [defaultAddressFlag, setDefaultAddressFlag] = useState(false);
  const [isInvoiceAddress, setIsInvoiceAddress] = useState(false);

  const [taxId, setTaxId] = React.useState(null);
  // const [provinceDdOptions, setProvinceDdOptions] = React.useState([])
  const lancode = localStorage.getItem("lancode");

  useEffect(() => {
    getProvinceData("province", null);
  }, []);


  useEffect(() => {
    props &&
      props.userDetail &&
      props.userDetail.data &&
      getTaxInfoOfUser(props.userDetail.data.userId);
  }, [props.addressForInvoice, props.userDetail]);
  useEffect(() => { }, [taxInvoiceData]);

  const getProvinceData = (type, selectedItem) => {
    let payload = {};
    if (type == "district") {
      payload = {
        provinceTH: selectedItem.provinceTH,
      };
    }
    if (type == "subdistrict") {
      payload = {
        provinceTH: taxInvoiceData[4].value.provinceTH,
        districtTH: selectedItem.districtTH,
      };
    }
    if (type == "postalCode") {
      payload = {
        provinceTH: taxInvoiceData[4].value.provinceTH,
        districtTH: taxInvoiceData[5].value.districtTH,
        subDistrictTH: selectedItem.subDistrictTH,
      };
    }
    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${token}`
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/postcode/",
      payload,
      requestOptions
    )
      .then((response) => {
        if (type == "province") {
          setProvinceDdOptions(response.data.data.province);
        } else if (type == "district") {
          setDistrictDdOptions(response.data.data.district);
        } else if (type == "subdistrict") {
          setSubDistrictDdOptions(response.data.data.subDistrict);
        } else if (type == "postalCode") {
          let temp = taxInvoiceData;
          temp[7].value = response.data.data.postalCode.postalCodeTH;
          temp[7].error = isNull(response.data.data.postalCode.postalCodeTH)
            ? true
            : false;
          setTaxInvoiceData(temp);
        }
        // return response
      })
      .catch((error) => error);
  };

  // useEffect(() => {
  //
  //     if (!isNull(orderData.postalInfo)) {
  //         setDistrictDdOptions(orderData.postalInfo.data.district)
  //         setSubDistrictDdOptions(orderData.postalInfo.data.subDistrict)
  //     }
  // }, [taxInvoiceData[4].value])
  const getTaxInfoOfUser = (userid) => {
    setLoading(true)
    let payload = {};
    ApiProvider.get(
      ApiList.API_URL_FOR_GET_USER_DETAILS + "/" + userid,
      payload,
      true
    ).then((response) => {
      setLoading(false)
      let defaultAddress = response.data.data.address.filter((item) => {
        return item.isInvoiceAddress && item;
      });
      if (!isNull(defaultAddress) && defaultAddress.length > 0) {
        let temp = taxInvoiceData;
        if (!isNull(response.data?.data?.taxInvoice) || response.data.data?.taxInvoice?.length > 0) {
          temp[0].value = response.data.data.taxInvoice?.name;
          temp[1].value = response.data.data.taxInvoice?.taxID;
          temp[2].value = response.data.data.taxInvoice?.phone;
        }
        setIsInvoiceAddress(true)
        temp[3].value = defaultAddress[0]?.addressTH;
        temp[3].disabled = true;
        temp[4].value = { provinceTH: defaultAddress[0]?.provinceTH };
        temp[4].disabled = true;
        temp[5].value = { districtTH: defaultAddress[0]?.districtTH };
        temp[5].disabled = true;
        temp[6].value = { subDistrictTH: defaultAddress[0]?.subDistrictTH };
        temp[6].disabled = true;
        temp[7].value = defaultAddress[0]?.postalCodeTH;
        temp[7].disabled = true;
        setTaxInvoiceData({ ...temp });
      } else if (!isNull(response.data?.data?.taxInvoice) || response.data.data?.taxInvoice?.length > 0) {
        let taxInvoiceAddress = response.data.data.taxInvoice
        setIsInvoiceAddress(false)
        let temp = taxInvoiceData;
        temp[0].value = taxInvoiceAddress.name;
        temp[1].value = taxInvoiceAddress.taxID;
        temp[2].value = taxInvoiceAddress.phone;
        temp[3].value = taxInvoiceAddress?.addressTH;
        temp[3].disabled = false;
        temp[4].value = { provinceTH: taxInvoiceAddress?.provinceTH };
        temp[4].disabled = false;
        temp[5].value = { districtTH: taxInvoiceAddress?.districtTH };
        temp[5].disabled = false;
        temp[6].value = { subDistrictTH: taxInvoiceAddress?.subDistrictTH };
        temp[6].disabled = false;
        temp[7].value = taxInvoiceAddress?.postalCodeTH;
        temp[7].disabled = false;
        setTaxInvoiceData({ ...temp });
      } else {
        setIsInvoiceAddress(false)
      }
      setShowInvoiceForm(false);
      setTaxInvoice(response.data.data.taxInvoice);
      setEditTaxInvoice(false);
    });
  };
  const handleCustomerDistrict = (event) => {
    setCustomerDistrict(event.target.value);
  };
  const isNull = (value) => {
    return value === "" || value === undefined || value === null;
  };

  const handleCustomerSubDistrict = (event) => {
    setCustomerSubDistrict(event.target.value);
  };

  const handleCustomerProvince = (event) => {
    setCustomerProvince(event.target.value);
  };
  const handlePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };
  const [taxAuthority, setTaxAuthority] = React.useState("Company");

  const handleTaxAuthority = (event) => {
    setTaxAuthority(event.target.value);
  };

  const clearFields = () => {
    let temp = taxInvoiceData;
    temp[0].value = "";
    temp[1].value = "";
    temp[2].value = "";
    if (!isInvoiceAddress) {
      temp[3].value = "";
      temp[4].value = "";
      temp[5].value = "";
      temp[6].value = "";
      temp[7].value = "";
      temp[3].disabled = false;
      temp[4].disabled = false;
      temp[5].disabled = false;
      temp[6].disabled = false;
      // temp[7].disabled = false;
    }
    setTaxInvoiceData({ ...temp });
  };
  const getFieldValidation = () => {
    let flag = false;
    let temp = taxInvoiceData;
    let length = Object.keys(taxInvoiceData).length;
    let step = true;

    for (let i = 0; i < length; i++) {
      if (temp[i].error || isNull(temp[i].value)) {
        flag = true;
        step = false;
        temp[i].errorMessage = "Please fill the required fields!";
        temp[i].error = true;
      } else {
        step = true;
      }
    }
    if (step) {
      if (temp[1].value.length < 10 || temp[1].value.length > 13) {
        flag = true;
        temp[1].error = true;
        temp[1].errorMessage =
          "Please enter a valid tax id between 10 to 13 digits";
      }
      if (temp[2].value.length < 9 || temp[2].value.length > 10) {
        flag = true;
        temp[2].error = true;
        temp[2].errorMessage =
          "Please enter a valid phone number between 9 to 10 digits";
      }
      if (temp[7].value.length != 5) {
        flag = true;
        temp[7].error = true;
        temp[7].errorMessage = "Please enter a valid 5 digit postal code";
      }
    }
    setTaxInvoiceData({ ...temp });
    return flag;
  };

  const submitTaxData = () => {
    // setLoading(true)
    if (getFieldValidation()) {
      return;
    }
    let payload = {
      userId: props.userDetail.data.userId,
      type: taxAuthority,
      name: taxInvoiceData[0].value,
      taxID: taxInvoiceData[1].value,
      phone: taxInvoiceData[2].value,
      addressTH: taxInvoiceData[3].value,
      provinceTH: taxInvoiceData[4].value.provinceTH,
      districtTH: taxInvoiceData[5].value.districtTH,
      subDistrictTH: taxInvoiceData[6].value.subDistrictTH,
      postalCodeTH: taxInvoiceData[7].value,
    };
    if (!isNull(taxInvoice)) {
      payload.taxInvoiceID = taxInvoice.taxInvoiceID;
    }
    // alert("saved successfully");
    saveTaxInformationService(payload);
  };

  function saveTaxInformationService(payload) {
    setLoading(true);
    ApiProvider.post(ApiList.API_URL_TO_SAVE_TAX_INVOICE, payload).then(
      (response) => {
        setLoading(false);
        getTaxInfoOfUser(props.userDetail.data.userId);
      }
    );
  }

  const handleDelete = () => {
    setLoading(true);
    let payload = {
      taxInvoiceID: taxInvoice.taxInvoiceID,
      userId: props?.userDetail?.data?.userId,
    };

    const token = localStorage.getItem("authorization");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios.post(
      REACT_APP_BASE_URL + "/api/users/taxinvoice/remove",
      payload,
      requestOptions
    )
      .then((response) => {
        setLoading(false);
        getBtnFlagStatus();
        getTaxInfoOfUser(props?.userDetail?.data?.userId);
      })
      .catch((error) => {
        setLoading(false);
        return error;
      });
  };
  const handleEdit = () => {
    let temp = taxInvoiceData;
    // temp[0].value = taxInvoice.name;
    // temp[1].value = taxInvoice.taxID;
    // temp[2].value = taxInvoice.phone;
    // temp[3].value = taxInvoice.addressTH;
    // temp[4].value = { provinceTH: taxInvoice.provinceTH };
    // temp[5].value = { districtTH: taxInvoice.districtTH };
    // temp[6].value = { subDistrictTH: taxInvoice.subDistrictTH };
    // temp[7].value = taxInvoice.postalCodeTH;
    // temp[3].disabled = true;
    // temp[4].disabled = true;
    // temp[5].disabled = true;
    // temp[6].disabled = true;
    temp[7].disabled = true;
    setTaxInvoiceData({ ...temp });
    setEditTaxInvoice(true);
    setInvoiceFlag(true);
    setTaxAuthority(taxInvoice.type);
    getBtnFlagStatus();
  };
  const getDropdownData = (selectedItem, type) => {
    let payload = {};
    if (type == "district") {
      payload = {
        provinceTH: selectedItem.provinceTH,
      };
      dispatch(orderActions.getPostalCodeInfo("payload", payload));
    } else if (type == "subdistrict") {
      payload = {
        provinceTH: selectedItem.districtTH,
      };
      dispatch(orderActions.getPostalCodeInfo("payload", payload));
    }
  };
  const handleOnchangeDd = (selectedItem, type) => {
    let temp = taxInvoiceData;
    if (type == "province") {
      temp[4].value = selectedItem;
      temp[4].error = false;
      temp[5].value = "";
      temp[6].value = "";
      temp[7].value = "";
      setTaxInvoiceData({ ...temp });
      getProvinceData("district", selectedItem);
    } else if (type == "district") {
      temp[5].value = selectedItem;
      temp[5].error = false;
      temp[6].value = "";
      temp[7].value = "";
      setTaxInvoiceData({ ...temp });
      getProvinceData("subdistrict", selectedItem);
    } else if (type == "subdistrict") {
      temp[6].value = selectedItem;
      temp[6].error = false;
      temp[7].value = "";
      setTaxInvoiceData({ ...temp });
      getProvinceData("postalCode", selectedItem);
    }
  };
  const getBtnFlagStatus = () => {
    let flag = false;
    let length = Object.keys(taxInvoiceData).length;
    for (let i = 0; i < length; i++) {
      if (
        (isNull(taxInvoiceData[i].value) || taxInvoiceData[i].error) &&
        i != 7
      ) {
        flag = true;
      }
    }
    if (flag) {
      setEnableSaveBtn(true);
    } else {
      setEnableSaveBtn(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      {!showInvoiceForm && !editTaxInvoice && isNull(taxInvoice) && (
        <div
          className="customerCheckoutCard"
          onClick={() => {
            setShowInvoiceForm(true);
            clearFields();
            setEnableSaveBtn(true);
            setInvoiceFlag(true);
            // getTaxInfoOfUser(props?.userDetail?.data?.userId)
          }}
        >
          <h3 style={{ cursor: "pointer" }}>
            <img src={Add} />
            {t("AddTaxInvoiceInformation.label")}
          </h3>
        </div>
      )}
      {(showInvoiceForm || editTaxInvoice) && invoiceFlag && (
        <>
          <div className="customerCheckoutCard">
            <div className="customerCheckoutCardHead">
              <h3>
                <img src={TaxInvoicee} />
                {t("TaxInvoiceInformation.label")}
              </h3>
            </div>
            <div className="row">
              {/* <div className="customerCheckoutCardLabelWithoutBorder">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      value={taxAuthority}
                      onChange={handleTaxAuthority}
                      defaultValue="Company"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Company"
                        control={<Radio />}
                        label={t("Company.label")}
                      // checked={false}
                      sx={{display: "inline-flex"}}
                      />
                      <FormControlLabel
                        value="Personal"
                        control={<Radio />}
                        label={t("Personal.label")}
                      // checked={true}
                      sx={{display: "inline-flex"}}
                      />
                    </RadioGroup>
                  </FormControl>
                </div> */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-2">
                <div className="customerCheckoutCardLabelWithoutBorder">
                  <input
                    type="radio"
                    id="company"
                    name="taxAuthorty"
                    value={"Company"}
                    checked={taxAuthority == "Company" ? true : false}
                    // onChange={handleTaxAuthority}
                    onChange={(e) => {
                      setTaxAuthority(e.target.value)
                      let temp = taxInvoiceData;
                      temp[1].value = ""
                      setTaxInvoiceData({ ...temp });
                      getBtnFlagStatus();
                    }}
                    defaultValue="Company"
                    className="mt-0 cursor-pointer"
                  />
                  <label htmlFor="company" className="m-0 ml-2 mr-4">
                    {t("Company.label")}
                  </label>
                  <input
                    type="radio"
                    id="personal"
                    name="taxAuthorty"
                    value={"Personal"}
                    checked={taxAuthority == "Personal" ? true : false}
                    onChange={(e) => {
                      // handleTaxAuthority(e);
                      setTaxAuthority(e.target.value)

                      let temp = taxInvoiceData;
                      temp[1].value = ""
                      setTaxInvoiceData({ ...temp });
                      getBtnFlagStatus();
                    }}
                    className="mt-0 cursor-pointer"
                  />
                  <label htmlFor="personal" className="m-0 ml-2">
                    {t("Personal.label")}
                  </label>
                </div>
              </div>
              <form className="d-flex flex-wrap">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                  {taxAuthority == "Company" ? (
                    <label>{t("CompanyName.label")}</label>
                  ) : (
                    <label>{t("Name.label")}</label>
                  )}
                  <input
                    type="text"
                    value={taxInvoiceData[0].value}
                    onChange={(e) => {
                      let temp = taxInvoiceData;

                      if (!isNull(e.target.value)) {
                        temp[0].value = e.target.value;
                        temp[0].error = false;
                        setTaxInvoiceData({ ...temp });
                      } else {
                        temp[0].value = e.target.value;
                        temp[0].error = true;
                        setTaxInvoiceData({ ...temp });
                      }
                      getBtnFlagStatus();
                    }}
                  />
                  {/* {taxInvoiceData[0].error && (
                    <div className="invalid-feedback-tax">
                      {taxInvoiceData[0].errorMessage}
                    </div>
                  )} */}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                  <label>{t("TaxID.label")}</label>
                  <input
                    type="text"
                    maxlength={taxAuthority == "Company" ? 10 : 13}
                    value={taxInvoiceData[1].value}
                    className={taxInvoiceData[1].error && "customerCheckoutCard-errorBorder"}
                    onChange={(e) => {
                      let temp = taxInvoiceData;
                      if (!isNull(e.target.value)) {
                        temp[1].value = e.target.value.replace(/[^0-9]/g, "");
                        temp[1].error = false;

                        setTaxInvoiceData({ ...temp });
                      } else {
                        temp[1].value = e.target.value.replace(/[^0-9]/g, "");
                        temp[1].error = true;
                        setTaxInvoiceData({ ...temp });
                      }
                      getBtnFlagStatus();
                    }}
                  />
                  {taxInvoiceData[1].error && (
                    <div className="invalid-feedback-tax">
                      {taxInvoiceData[1].errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 marginTop-10">
                  <label>{t("Phonenumber.label")}</label>
                  <input
                    type="text"
                    maxlength={10}
                    value={taxInvoiceData[2].value}
                    className={taxInvoiceData[2].error && "customerCheckoutCard-errorBorder"}
                    onChange={(e) => {
                      let temp = taxInvoiceData;

                      if (!isNull(e.target.value)) {
                        temp[2].value = e.target.value.replace(/[^0-9]/g, "");
                        temp[2].error = false;
                        setTaxInvoiceData({ ...temp });
                      } else {
                        temp[2].value = e.target.value.replace(/[^0-9]/g, "");
                        temp[2].error = true;
                        setTaxInvoiceData({ ...temp });
                      }
                      getBtnFlagStatus();
                    }}
                  />
                  {taxInvoiceData[2].error && (
                    <div className="invalid-feedback-tax">
                      {taxInvoiceData[2].errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-10">
                  <label>{t("Address.label")}</label>
                  <input
                    type="text"
                    value={taxInvoiceData[3].value}
                    disabled={taxInvoiceData[3].disabled}
                    onChange={(e) => {
                      let temp = taxInvoiceData;
                      if (!isNull(e.target.value)) {
                        temp[3].value = e.target.value;
                        temp[3].error = false;
                        setTaxInvoiceData({ ...temp });
                      } else {
                        temp[3].value = e.target.value;
                        temp[3].error = true;
                        setTaxInvoiceData({ ...temp });
                      }
                      getBtnFlagStatus();
                    }}
                  />
                  {/* {taxInvoiceData[3].error && (
                    <div className="invalid-feedback-tax">
                      {taxInvoiceData[3].errorMessage}
                    </div>
                  )} */}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-6 customerCheckoutCard-select marginTop-10">
                  <label>{t("Province.label")}</label>
                  <Autocomplete
                    disablePortal
                    noOptionsText={""}
                    id={Math.random().toString(36).substring(7)}
                    options={provinceDdOptions}
                    autoHighlight
                    disableClearable
                    popupIcon={<img src={arrowIcon} width={12} height={8} style={{ marginRight: "16px", marginTop: "12px", cursor: "pointer" }} />}
                    disabled={taxInvoiceData[4].disabled}
                    getOptionLabel={(option) => option.provinceTH || ""}
                    value={taxInvoiceData[4].value}
                    onChange={(e, selectedItem) => {
                      handleOnchangeDd(selectedItem, "province");
                      getBtnFlagStatus();
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <p className="checkout-screen-dropdown-options">{option.provinceTH}</p>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        error={taxInvoiceData[4].error}
                        helperText={
                          taxInvoiceData[4].error
                            ? taxInvoiceData[4].errorMessage
                            : ""
                        }
                        {...params}

                      // label="Province"
                      />
                    )}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-6 customerCheckoutCard-select marginTop-10">
                  <label>{t("District.label")}</label>
                  <Autocomplete
                    disablePortal
                    noOptionsText={""}
                    id={Math.random().toString(36).substring(7)}
                    options={districtDdOptions}
                    autoHighlight
                    disableClearable
                    disabled={taxInvoiceData[5].disabled}
                    getOptionLabel={(option) => option.districtTH || ""}
                    value={taxInvoiceData[5].value}
                    popupIcon={<img src={arrowIcon} width={12} height={8} style={{ marginRight: "16px", marginTop: "12px", cursor: "pointer" }} />}
                    onChange={(e, selectedItem) => {
                      handleOnchangeDd(selectedItem, "district");
                      getBtnFlagStatus();
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <p className="checkout-screen-dropdown-options">{option.districtTH}</p>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        error={taxInvoiceData[5].error}
                        helperText={
                          taxInvoiceData[5].error
                            ? taxInvoiceData[5].errorMessage
                            : ""
                        }
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          // autoComplete: 'new-password',
                        }}
                      // label="Province"
                      />
                    )}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-6 customerCheckoutCard-select marginTop-10">
                  <label>{t("Sub-district.label")}</label>
                  <Autocomplete
                    disablePortal
                    noOptionsText={""}
                    id={Math.random().toString(36).substring(7)}
                    options={subDistrictDdOptions}
                    autoHighlight
                    disableClearable
                    popupIcon={<img src={arrowIcon} width={12} height={8} style={{ marginRight: "16px", marginTop: "12px", cursor: "pointer" }} />}
                    disabled={taxInvoiceData[6].disabled}
                    getOptionLabel={(option) => option.subDistrictTH || ""}
                    value={taxInvoiceData[6].value}
                    onChange={(e, selectedItem) => {
                      handleOnchangeDd(selectedItem, "subdistrict");
                      getBtnFlagStatus();
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <p className="checkout-screen-dropdown-options">{option.subDistrictTH}</p>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        error={taxInvoiceData[6].error}
                        helperText={
                          taxInvoiceData[6].error
                            ? taxInvoiceData[6].errorMessage
                            : ""
                        }
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          // autoComplete: 'new-password',
                        }}
                      // label="Province"
                      />
                    )}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-6 marginTop-10">
                  <label> {t("PostalCode.label")}</label>
                  <input
                    type="text"
                    maxlength={5}
                    // disabled={defaultAddressFlag}
                    value={taxInvoiceData[7].value}
                    disabled={taxInvoiceData[7].disabled}
                    onChange={(e) => {
                      let temp = taxInvoiceData;
                      if (!isNull(e.target.value)) {
                        temp[7].value = e.target.value.replace(/[^0-9]/g, "");
                        temp[7].error = false;
                        setTaxInvoiceData({ ...temp });
                      } else {
                        temp[7].value = e.target.value.replace(/[^0-9]/g, "");
                        temp[7].error = true;
                        setTaxInvoiceData({ ...temp });
                      }
                      getBtnFlagStatus();
                    }}
                  />
                  {taxInvoiceData[7].error && (
                    <div className="invalid-feedback-tax">
                      {taxInvoiceData[7].errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 marginTop-20">
                  <div className="customerCheckoutCardBtn">
                    {editTaxInvoice ? (
                      <button
                        type={"button"}
                        className="customerCheckoutCardCancelBtn"
                        onClick={() => handleDelete()}
                      >
                        <img src={Delete} />
                        {t("Delete.label")}
                      </button>
                    ) : (
                      <button
                        type={"button"}
                        className="customerCheckoutCardCancelBtn"
                        onClick={() => {
                          // handleDelete()
                          setShowInvoiceForm(false);
                          clearFields();
                          setEnableSaveBtn(false);
                          setInvoiceFlag(false);
                        }}
                      >
                        {/* <img src={Delete} /> */}
                        {/* Delete */}
                        {t("Cancel.label")}
                      </button>
                    )}
                    <button
                      type="button"
                      className={`${enableSaveBtn
                        ? "customerCheckoutCardSaveBtnDisable"
                        : "customerCheckoutCardSaveBtn"
                        }`}
                      disabled={enableSaveBtn}
                      onClick={() => {
                        submitTaxData();
                      }}
                    >
                      {editTaxInvoice ? t("SaveChanges.label") : t("Save.label")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}

      {!isNull(taxInvoice) && !editTaxInvoice && (
        <div className="customerCheckoutCard">
          <div className="customerCheckoutCardHead">
            <div className="customerCheckoutCardHeadLt">
              <h3>
                <img src={TaxInvoicee} />
                {t("TaxInvoiceInformation.label")}
              </h3>
            </div>
            <div
              className="customerCheckoutCardHeadRt"
              onClick={() => handleEdit()}
            >
              <h2>
                <img src={Edit} />
                <span>{t("Edit.label")}</span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-5 col-sm-4 col-5">
              <p>{t("CompanyName.label")}</p>
            </div>
            <div className="col-lg-9 col-md-7 col-sm-8 col-7">
              {" "}
              <h4>{taxInvoiceData[0].value}</h4>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-4 col-5">
              <p>{t("TaxID.label")}</p>
            </div>
            <div className="col-lg-9 col-md-7 col-sm-8 col-7">
              <h4>{taxInvoiceData[1].value}</h4>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-4 col-5">
              <p>{t("phonenosmall.label")}</p>
            </div>
            <div className="col-lg-9 col-md-7 col-sm-8 col-7">
              <h4>{taxInvoiceData[2].value}</h4>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-4 col-5">
              <p>{t("Address.label")}</p>
            </div>
            <div className="col-lg-9 col-md-7 col-sm-8 col-7">
              <h4>
                {" "}
                {lancode == "th1"
                  ? taxInvoiceData[3].value +
                  ", " +
                  taxInvoiceData[6].value.subDistrictEN +
                  ", " +
                  taxInvoiceData[5].value.districtEN +
                  ", " +
                  taxInvoiceData[4].value.provinceEN +
                  " " +
                  taxInvoiceData[7].value
                  : taxInvoiceData[3].value +
                  ", " +
                  taxInvoiceData[6].value.subDistrictTH +
                  ", " +
                  taxInvoiceData[5].value.districtTH +
                  ", " +
                  taxInvoiceData[4].value.provinceTH +
                  " " +
                  taxInvoiceData[7].value}
              </h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { TaxInvoice as TaxInvoice };
