import { landingConstants } from '../_constants';
import { customLogout } from '../_helpers/commanFunction';
import { landingService } from '../_services';

export const landingPageActions = {
  searchWithoutLogin,
  searchWithLogin,
  getAllCatSubAction,
  getPromotionWithLogin,
  getPromotionWithoutLogin,
  getNewItemsDataWithOutLogin,
  getNewItemsDataWithLogin
};

function searchWithoutLogin(type, payload) {
  return (dispatch) => {
    dispatch(request());
    landingService.searchWithoutLogin(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.response.data.code === 400) {
          dispatch(noDataFound());
        } else if (res?.response?.data?.code && res.response.data.code === 409) {
          customLogout()
        }
      })
      .catch((error) => {
        return dispatch(failure(error))
      });
  };

  function request() {
    return { type: landingConstants.SEARCH_WITHOUT_LOGIN };
  }
  function success(data) {
    return { type: landingConstants.SEARCH_WITHOUT_LOGIN_SUCCESS, data };
  }
  function noDataFound() {
    return { type: landingConstants.SEARCH_WITHOUT_LOGIN_NO_DATA_FOUND };
  }
  function failure(error) {
    return { type: landingConstants.SEARCH_WITHOUT_LOGIN_FAILURE, error };

  }
}

function getPromotionWithLogin(type, payload) {
  return (dispatch) => {
    dispatch(request());
    landingService.getPromotionWithLogin(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.response.data.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request(data) {
    return { type: landingConstants.PROMOTION_WITH_LOGIN, data };
  }
  function success(data) {
    return { type: landingConstants.PROMOTION_WITH_LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: landingConstants.PROMOTION_WITH_LOGIN_FAILURE, error };

  }
}

function getPromotionWithoutLogin(type, payload) {
  return (dispatch) => {
    dispatch(request());
    landingService.getPromotionWithoutLogin(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.response.data.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: landingConstants.PROMOTION_WITHOUT_LOGIN };
  }
  function success(data) {
    return { type: landingConstants.PROMOTION_WITHOUT_LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: landingConstants.PROMOTION_WITHOUT_LOGIN_NODATAFOUND, error };

  }
}

// new item funcion with login
function getNewItemsDataWithLogin(type, payload) {
  return (dispatch) => {
    dispatch(request());
    landingService.getNewItemsDataWithLogin(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.response.data.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: landingConstants.NEW_ITEM_WITH_LOGIN };
  }
  function success(data) {
    return { type: landingConstants.NEW_ITEM_WITH_LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: landingConstants.NEW_ITEM_WITH_LOGIN_NODATAFOUND, error };

  }
}

// new item function without login

function getNewItemsDataWithOutLogin(type, payload) {
  return (dispatch) => {
    dispatch(request());
    landingService.getNewItemsDataWithOutLogin(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.response.data.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: landingConstants.NEW_ITEM_WITHOUT_LOGIN };
  }
  function success(data) {
    return { type: landingConstants.NEW_ITEM_WITHOUT_LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: landingConstants.NEW_ITEM_WITHOUT_LOGIN_NODATAFOUND, error };

  }
}


function searchWithLogin(type, payload) {
  return (dispatch) => {
  };

  function request() {
    return { type: landingConstants.SEARCH_WITH_LOGIN };
  }

}

function getAllCatSubAction() {
  return (dispatch) => {
    dispatch(request());
    landingService
      .getAllCatSub()
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res?.response?.data?.code && res.response.data.code === 409) {
          customLogout()
        }
        else {
          dispatch(failure(res));
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: landingConstants.GET_ALL_MENU_REQUEST };
  }
  function success(data) {
    return { type: landingConstants.GET_ALL_MENU_SUCCESS, data };
  }
  function failure(error) {
    return { type: landingConstants.GET_ALL_MENU_FAIL, error };
  }
}
