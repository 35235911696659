/* import image1 from "./assets/carouselImage1.jpg";
import image2 from "./assets/carouselImage2.jpg";
import image3 from "./assets/carouselImage3.jpg";
import image4 from "./assets/carouselImage4.jpg";
import image5 from "./assets/carouselImage5.jpg";
import image6 from "./assets/carouselImage6.jpg"; */

import porkpromo from '../images/porkpromo.png';
import newitemchicken from '../images/newitemchicken.png';
import braisedchickenproduct from '../images/braisedchickenproduct.png';
import chickenpromo from '../images/chickenpromo.png';
import oreonewitem from '../images/oreonewitem.png';
import newitempic from '../images/newitempic.png';
import healthychickenpromo from '../images/healthychickenpromo.png';
import sweetnewitem from '../images/sweetnewitem.png';

/* import multicar1 from "./assets/multicar1.jpeg";
import multicar2 from "./assets/multicar2.jpeg";
import multicar3 from "./assets/multicar3.jpeg";
import multicar4 from "./assets/multicar4.jpeg";
import multicar5 from "./assets/multicar5.jpeg";
import multicar6 from "./assets/multicar6.jpeg";
import multicar7 from "./assets/multicar7.jpeg";
import multicar8 from "./assets/multicar8.jpeg";
import multicar9 from "./assets/multicar9.jpeg";
import multicar10 from "./assets/multicar10.jpeg";
import multicar11 from "./assets/multicar11.jpeg"; */

export const data = [porkpromo, newitemchicken, braisedchickenproduct, chickenpromo, oreonewitem, newitempic];
export const multiData = [
  porkpromo,
  newitemchicken,
  braisedchickenproduct,
  chickenpromo,
  oreonewitem,
  newitempic,
  healthychickenpromo,
  sweetnewitem,
  porkpromo,
  newitemchicken,
  braisedchickenproduct,
  chickenpromo,
  oreonewitem,
  newitempic,
  healthychickenpromo,
  sweetnewitem,
  porkpromo,
  newitemchicken,
  braisedchickenproduct,
  chickenpromo,
  oreonewitem,
  newitempic,
  healthychickenpromo,
  sweetnewitem
 ];
