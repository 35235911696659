import { createOrderConstant } from "../_constants";

const initReducers = {  
  orderDetails: null, 
  orderDetailsLoading:false,
  orderDetailsdateTime: new Date().getMilliseconds(),
  orderCartDetails:null,
  orderCartDetailsLoading:false,
  orderDetailsById:null
};

export function createOrder(state = initReducers, action) {
  switch (action.type) {
    case createOrderConstant.ORDER_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        orderDetailsLoading: true,
      };
    case createOrderConstant.ORDER_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.data,
        orderDetailsLoading: false,
      };
    case createOrderConstant.ORDER_PRODUCT_DETAILS_NODATA:
      return {
        ...state,
        orderDetails: error,
        orderDetailsLoading: false,
        orderDetailsdateTime: new Date().getMilliseconds()
      };

    case createOrderConstant.ORDER_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        orderDetails: null,
        orderDetailsLoading: false,
        orderDetailsdateTime: new Date().getMilliseconds()
      };
      //
      case createOrderConstant.ORDER_PRODUCT_QTY_UPDATE_REQUEST:
        return {
          ...state,
          orderCartDetailsLoading: true,
        };
      case createOrderConstant.ORDER_PRODUCT_QTY_UPDATE_SUCCESS:
        return {
          ...state,
          orderCartDetails: action.data,
          orderCartDetailsLoading: false,
        };
      case createOrderConstant.ORDER_PRODUCT_QTY_UPDATE_NODATA:
        return {
          ...state,
          orderCartDetails: error,
          orderCartDetailsLoading: false
        };
  
      case createOrderConstant.ORDER_PRODUCT_QTY_UPDATE_FAILURE:
        return {
          ...state,
          orderCartDetails: null,
          orderCartDetailsLoading: false
        };
      //

      case createOrderConstant.ORDER_PRODUCT_DETAILS_ID_REQUEST:
      return {
        ...state,
        orderDetailsLoading: true,
      };
    case createOrderConstant.ORDER_PRODUCT_DETAILS_ID_SUCCESS:
      return {
        ...state,
        orderDetailsById: action.data,
        orderDetailsLoading: false,
      };
    case createOrderConstant.ORDER_PRODUCT_DETAILS_ID_NODATA:
      return {
        ...state,
        orderDetailsById: error,
        orderDetailsLoading: false
      };

    case createOrderConstant.ORDER_PRODUCT_DETAILS_ID_FAILURE:
      return {
        ...state,
        orderDetailsById: null,
        orderDetailsLoading: false
      };

    default:
      return state;
  }
}
