export const orderConstants = {

    ORDER_DISCARD__ALL:'ORDER_DISCARD__ALL',
    ORDER_DISCARD__ALL_SUCCESS:'ORDER_DISCARD__ALL_SUCCESS',
    ORDER_DISCARD__ALL_NODATA:'ORDER_DISCARD__ALL_NODATA',
    ORDER_DISCARD__ALL_FAILURE:'ORDER_DISCARD__ALL_FAILURE',

    GET_ORDER_ITEMS_BY_ORDER__ALL:'GET_ORDER_ITEMS_BY_ORDER__ALL',
    GET_ORDER_ITEMS_BY_ORDER__ALL_SUCCESS:'GET_ORDER_ITEMS_BY_ORDER__ALL_SUCCESS',
    GET_ORDER_ITEMS_BY_ORDER__ALL_NODATA:'GET_ORDER_ITEMS_BY_ORDER__ALL_NODATA',
    GET_ORDER_ITEMS_BY_ORDER__ALL_FAILURE:'GET_ORDER_ITEMS_BY_ORDER__ALL_FAILURE',

    ORDER_SUBMIT_DRAFT__ALL:'ORDER_SUBMIT_DRAFT__ALL',
    ORDER_SUBMIT_DRAFT__ALL_SUCCESS:'ORDER_SUBMIT_DRAFT__ALL_SUCCESS',
    ORDER_SUBMIT_DRAFT__ALL_NODATA:'ORDER_SUBMIT_DRAFT__ALL_NODATA',
    ORDER_SUBMIT_DRAFT__ALL_FAILURE:'ORDER_SUBMIT_DRAFT__ALL_FAILURE',

    ORDER_SUBMIT_CONFIRM__ALL:'ORDER_SUBMIT_CONFIRM__ALL',
    ORDER_SUBMIT_CONFIRM__ALL_SUCCESS:'ORDER_SUBMIT_CONFIRM__ALL_SUCCESS',
    ORDER_SUBMIT_CONFIRM__ALL_NODATA:'ORDER_SUBMIT_CONFIRM__ALL_NODATA',
    ORDER_SUBMIT_CONFIRM__ALL_FAILURE:'ORDER_SUBMIT_CONFIRM__ALL_FAILURE',

    REMOVE_ORDER_ITEMS__ALL:'REMOVE_ORDER_ITEMS__ALL',
    REMOVE_ORDER_ITEMS__ALL_SUCCESS:'REMOVE_ORDER_ITEMS__ALL_SUCCESS',
    REMOVE_ORDER_ITEMS__ALL_NODATA:'REMOVE_ORDER_ITEMS__ALL_NODATA',
    REMOVE_ORDER_ITEMS__ALL_FAILURE:'REMOVE_ORDER_ITEMS__ALL_FAILURE',



    CREATE_CUSTOMER_DRAFT_ORDER_ALL: 'CREATE_CUSTOMER_DRAFT_ORDER_ALL',
    CREATE_CUSTOMER_DRAFT_ORDER_ALL_SUCCESS: 'CREATE_CUSTOMER_DRAFT_ORDER_ALL_SUCCESS',
    CREATE_CUSTOMER_DRAFT_ORDER_ALL_NODATA: 'CREATE_CUSTOMER_DRAFT_ORDER_ALL_NODATA',
    CREATE_CUSTOMER_DRAFT_ORDER_ALL_FAILURE: 'CREATE_CUSTOMER_DRAFT_ORDER_ALL_FAILURE',

    CREATE_CUSTOMER_ORDER_ALL: 'CREATE_CUSTOMER_ORDER_ALL',
    CREATE_CUSTOMER_ORDER_ALL_SUCCESS: 'CREATE_CUSTOMER_ORDER_ALL_SUCCESS',
    CREATE_CUSTOMER_ORDER_ALL_NODATA: 'CREATE_CUSTOMER_ORDER_ALL_NODATA',
    CREATE_CUSTOMER_ORDER_ALL_FAILURE: 'CREATE_CUSTOMER_ORDER_ALL_FAILURE',

    ADD_EDIT_TAX_INVOICE_ALL: 'ADD_EDIT_TAX_INVOICE_ALL',
    ADD_EDIT_TAX_INVOICE_ALL_SUCCESS: 'ADD_EDIT_TAX_INVOICE_ALL_SUCCESS',
    ADD_EDIT_TAX_INVOICE_ALL_NODATA: 'ADD_EDIT_TAX_INVOICE_ALL_NODATA',
    ADD_EDIT_TAX_INVOICE_ALL_FAILURE: 'ADD_EDIT_TAX_INVOICE_ALL_FAILURE',


    GET_POSTAL_INFO: 'GET_POSTAL_INFO',
    GET_POSTAL_INFO_SUCCESS: 'GET_POSTAL_INFO_SUCCESS',
    GET_POSTAL_INFO_NODATA: 'GET_POSTAL_INFO_NODATA',
    GET_POSTAL_INFO_FAILURE: 'GET_POSTAL_INFO_FAILURE',

    GET_ALL_NEW_ORDER_REQUEST: 'GET_ALL_NEW_ORDER_REQUEST',
    GET_ALL_NEW_ORDER_SUCCESS: 'GET_ALL_NEW_ORDER_SUCCESS',
    GET_ALL_NEW_ORDER_NODATA: 'GET_ALL_NEW_ORDER_NODATA',
    GET_ALL_NEW_ORDER_FAILURE: 'GET_ALL_NEW_ORDER_FAILURE',

    GET_ALL_SHIPPED_ORDER_REQUEST: 'GET_ALL_SHIPPED_ORDER_REQUEST',
    GET_ALL_SHIPPED_ORDER_SUCCESS: 'GET_ALL_SHIPPED_ORDER_SUCCESS',
    GET_ALL_SHIPPED_ORDER_NODATA: 'GET_ALL_SHIPPED_ORDER_NODATA',
    GET_ALL_SHIPPED_ORDER_FAILURE: 'GET_ALL_SHIPPED_ORDER_FAILURE',


    GET_ALL_COMPLETED_ORDER_REQUEST: 'GET_ALL_COMPLETED_ORDER_REQUEST',
    GET_ALL_COMPLETED_ORDER_SUCCESS: 'GET_ALL_COMPLETED_ORDER_SUCCESS',
    GET_ALL_COMPLETED_ORDER_NODATA: 'GET_ALL_COMPLETED_ORDER_NODATA',
    GET_ALL_COMPLETED_ORDER_FAILURE: 'GET_ALL_COMPLETED_ORDER_FAILURE',

    GET_ALL_TO_CONFIRM_ORDER_REQUEST: 'GET_ALL_TO_CONFIRM_ORDER_REQUEST',
    GET_ALL_TO_CONFIRM_ORDER_SUCCESS: 'GET_ALL_TO_CONFIRM_ORDER_SUCCESS',
    GET_ALL_TO_CONFIRM_ORDER_NODATA: 'GET_ALL_TO_CONFIRM_ORDER_NODATA',
    GET_ALL_TO_CONFIRM_ORDER_FAILURE: 'GET_ALL_TO_CONFIRM_ORDER_FAILURE',

    GET_ALL_CANCELLED_ORDER_REQUEST: 'GET_ALL_CANCELLED_ORDER_REQUEST',
    GET_ALL_CANCELLED_ORDER_SUCCESS: 'GET_ALL_CANCELLED_ORDER_SUCCESS',
    GET_ALL_CANCELLED_ORDER_NODATA: 'GET_ALL_CANCELLED_ORDER_NODATA',
    GET_ALL_CANCELLED_ORDER_FAILURE: 'GET_ALL_CANCELLED_ORDER_FAILURE',

    GET_ALL_DRAFT_ORDER_REQUEST: 'GET_ALL_DRAFT_ORDER_REQUEST',
    GET_ALL_DRAFT_ORDER_SUCCESS: 'GET_ALL_DRAFT_ORDER_SUCCESS',
    GET_ALL_DRAFT_ORDER_NODATA: 'GET_ALL_DRAFT_ORDER_NODATA',
    GET_ALL_DRAFT_ORDER_FAILURE: 'GET_ALL_DRAFT_ORDER_FAILURE',

    GET_ALL_CONFIRMED_ORDER_REQUEST: 'GET_ALL_CONFIRMED_ORDER_REQUEST',
    GET_ALL_CONFIRMED_ORDER_SUCCESS: 'GET_ALL_CONFIRMED_ORDER_SUCCESS',
    GET_ALL_CONFIRMED_ORDER_NODATA: 'GET_ALL_CONFIRMED_ORDER_NODATA',
    GET_ALL_CONFIRMED_ORDER_FAILURE: 'GET_ALL_CONFIRMED_ORDER_FAILURE',

    GET_ALL_TO_CONFIRMED_ORDER_REQUEST_EXCEL: 'GET_ALL_TO_CONFIRMED_ORDER_REQUEST_EXCEL',
    GET_ALL_TO_CONFIRMED_ORDER_SUCCESS_EXCEL: 'GET_ALL_TO_CONFIRMED_ORDER_SUCCESS_EXCEL',
    GET_ALL_TO_CONFIRMED_ORDER_NODATA_EXCEL: 'GET_ALL_TO_CONFIRMED_ORDER_NODATA_EXCEL',
    GET_ALL_TO_CONFIRMED_ORDER_FAILURE_EXCEL: 'GET_ALL_TO_CONFIRMED_ORDER_FAILURE_EXCEL',

    GET_ALL_CONFIRMED_ORDER_REQUEST_EXCEL: 'GET_ALL_CONFIRMED_ORDER_REQUEST_EXCEL',
    GET_ALL_CONFIRMED_ORDER_SUCCESS_EXCEL: 'GET_ALL_CONFIRMED_ORDER_SUCCESS_EXCEL',
    GET_ALL_CONFIRMED_ORDER_NODATA_EXCEL: 'GET_ALL_CONFIRMED_ORDER_NODATA_EXCEL',
    GET_ALL_CONFIRMED_ORDER_FAILURE_EXCEL: 'GET_ALL_CONFIRMED_ORDER_FAILURE_EXCEL',

    GET_TO_CONFIRM_VIEW_BY_PRODUCT_REQUEST: 'GET_TO_CONFIRM_VIEW_BY_PRODUCT_REQUEST',
    GET_TO_CONFIRM_VIEW_BY_PRODUCT_SUCCESS: 'GET_TO_CONFIRM_VIEW_BY_PRODUCT_SUCCESS',
    GET_TO_CONFIRM_VIEW_BY_PRODUCT_NODATA: 'GET_TO_CONFIRM_VIEW_BY_PRODUCT_NODATA',
    GET_TO_CONFIRM_VIEW_BY_PRODUCT_FAILURE: 'GET_TO_CONFIRM_VIEW_BY_PRODUCT_FAILURE',


    GET_ALL_CONFIRMED_ORDER_REQUEST_EXCEL: 'GET_ALL_CONFIRMED_ORDER_REQUEST_EXCEL',
    GET_ALL_CONFIRMED_ORDER_SUCCESS_EXCEL: 'GET_ALL_CONFIRMED_ORDER_SUCCESS_EXCEL',
    GET_ALL_CONFIRMED_ORDER_NODATA_EXCEL: 'GET_ALL_CONFIRMED_ORDER_NODATA_EXCEL',
    GET_ALL_CONFIRMED_ORDER_FAILURE_EXCEL: 'GET_ALL_CONFIRMED_ORDER_FAILURE_EXCEL',

    ADD_TO_CONFIRM_ITEMS_ORDER_ALL: 'ADD_TO_CONFIRM_ITEMS_ORDER_ALL',
    ADD_TO_CONFIRM_ITEMS_ORDER_ALL_SUCCESS: 'ADD_TO_CONFIRM_ITEMS_ORDER_ALL_SUCCESS',
    ADD_TO_CONFIRM_ITEMS_ORDER_ALL_NODATA: 'ADD_TO_CONFIRM_ITEMS_ORDER_ALL_NODATA',
    ADD_TO_CONFIRM_ITEMS_ORDER_ALL_FAILURE: 'ADD_TO_CONFIRM_ITEMS_ORDER_ALL_FAILURE',

    UPDATE_ITEMS_STATUS_ORDER_ALL: 'UPDATE_ITEMS_STATUS_ORDER_ALL',
    UPDATE_ITEMS_STATUS_ORDER_ALL_SUCCESS: 'UPDATE_ITEMS_STATUS_ORDER_ALL_SUCCESS',
    UPDATE_ITEMS_STATUS_ORDER_ALL_NODATA: 'UPDATE_ITEMS_STATUS_ORDER_ALL_NODATA',
    UPDATE_ITEMS_STATUS_ORDER_ALL_FAILURE: 'UPDATE_ITEMS_STATUS_ORDER_ALL_FAILURE',


    UPDATE_ORDER_STATUS__ALL: 'UPDATE_ORDER_STATUS__ALL',
    UPDATE_ORDER_STATUS__ALL_SUCCESS: 'UPDATE_ORDER_STATUS__ALL_SUCCESS',
    UPDATE_ORDER_STATUS__ALL_NODATA: 'UPDATE_ORDER_STATUS__ALL_NODATA',
    UPDATE_ORDER_STATUS__ALL_FAILURE: 'UPDATE_ORDER_STATUS__ALL_FAILURE',



    TOGGLE_VALUE_TRUE: 'TOGGLE_VALUE_TRUE',
    TOGGLE_VALUE_FALSE: 'TOGGLE_VALUE_FALSE',

    ACTIVELIST_ORDER__ALL:'ACTIVELIST_ORDER__ALL',
    ACTIVELIST_ORDER__ALL_SUCCESS:'ACTIVELIST_ORDER__ALL_SUCCESS',
    ACTIVELIST_ORDER__ALL_NODATA:'ACTIVELIST_ORDER__ALL_NODATA',
    ACTIVELIST_ORDER__ALL_FAILURE:'ACTIVELIST_ORDER__ALL_FAILURE',

    HISTORYLIST_ORDER__ALL:'HISTORYLIST_ORDER__ALL',
    HISTORYLIST_ORDER__ALL_SUCCESS:'HISTORYLIST_ORDER__ALL_SUCCESS',
    HISTORYLIST_ORDER__ALL_NODATA:'HISTORYLIST_ORDER__ALL_NODATA',
    HISTORYLIST_ORDER__ALL_FAILURE:'HISTORYLIST_ORDER__ALL_FAILURE',

    TOREVIEW_ORDER__ALL:'TOREVIEW_ORDER__ALL',
    TOREVIEW_ORDER__ALL_SUCCESS:'TOREVIEW_ORDER__ALL_SUCCESS',
    TOREVIEW_ORDER__ALL_NODATA:'TOREVIEW_ORDER__ALL_NODATA',
    TOREVIEW_ORDER__ALL_FAILURE:'TOREVIEW_ORDER__ALL_FAILURE',

    USER_REORDER_ORDER__ALL:'USER_REORDER_ORDER__ALL',
    USER_REORDER_ORDER__ALL_SUCCESS:'USER_REORDER_ORDER__ALL_SUCCESS',
    USER_REORDER_ORDER__ALL_NODATA:'USER_REORDER_ORDER__ALL_NODATA',
    USER_REORDER_ORDER__ALL_FAILURE:'USER_REORDER_ORDER__ALL_FAILURE',

    UPDATE_CANCELLATION_DETAILS_ORDER__ALL:'UPDATE_CANCELLATION_DETAILS_ORDER__ALL',
    UPDATE_CANCELLATION_DETAILS_ORDER__ALL_SUCCESS:'UPDATE_CANCELLATION_DETAILS_ORDER__ALL_SUCCESS',
    UPDATE_CANCELLATION_DETAILS_ORDER__ALL_NODATA:'UPDATE_CANCELLATION_DETAILS_ORDER__ALL_NODATA',
    UPDATE_CANCELLATION_DETAILS_ORDER__ALL_FAILURE:'UPDATE_CANCELLATION_DETAILS_ORDER__ALL_FAILURE',

    GET_BY_ORDER_ID__ALL:'GET_BY_ORDER_ID__ALL',
    GET_BY_ORDER_ID__ALL_SUCCESS:'GET_BY_ORDER_ID__ALL_SUCCESS',
    GET_BY_ORDER_ID__ALL_NODATA:'GET_BY_ORDER_ID__ALL_NODATA',
    GET_BY_ORDER_ID__ALL_FAILURE:'GET_BY_ORDER_ID__ALL_FAILURE',
    

    RE_ORDER_PRODUCT: 'RE_ORDER_PRODUCT',
    RE_ORDER_PRODUCT_SUCCESS: 'RE_ORDER_PRODUCT_SUCCESS',
    RE_ORDER_PRODUCT_NODATA: 'RE_ORDER_PRODUCT_NODATA',
    RE_ORDER_PRODUCT_FAILURE: 'RE_ORDER_PRODUCT_FAILURE',


};


// GET_ALL_SHIPPED_ORDER_REQUEST: 'GET_ALL_SHIPPED_ORDER_REQUEST',
// GET_ALL_SHIPPED_ORDER_SUCCESS: 'GET_ALL_SHIPPED_ORDER_SUCCESS',
// GET_ALL_SHIPPED_ORDER_NODATA: 'GET_ALL_SHIPPED_ORDER_NODATA',
// GET_ALL_SHIPPED_ORDER_FAILURE: 'GET_ALL_SHIPPED_ORDER_FAILURE',
// GET_ALL_TO_CONFIRMED_ORDER_REQUEST_EXCEL: 'GET_ALL_TO_CONFIRMED_ORDER_REQUEST_EXCEL',
// GET_ALL_TO_CONFIRMED_ORDER_SUCCESS_EXCEL: 'GET_ALL_TO_CONFIRMED_ORDER_SUCCESS_EXCEL',
// GET_ALL_TO_CONFIRMED_ORDER_NODATA_EXCEL: 'GET_ALL_TO_CONFIRMED_ORDER_NODATA_EXCEL',
// GET_ALL_TO_CONFIRMED_ORDER_FAILURE_EXCEL: 'GET_ALL_TO_CONFIRMED_ORDER_FAILURE_EXCEL',
