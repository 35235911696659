import AccountCircle from '@mui/icons-material/AccountCircle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { productActions } from "../_actions/product.actions";
import { convertToCurrencyFormat, nospaces } from '../_helpers/commanFunction';
import { REACT_APP_BASE_URL } from "../constant";
import { Footer } from '../Footer';
import { Header } from '../Header';
import alertTriangleImage from '../images/alert-triangle.png';
import AlertImage from '../images/alert1.png';
import customerAvatar from '../images/customer-avatar.png';
import crossImage from '../images/delete-icon.png';
import blankImage from "../images/empty-image.png";
import myCartBackButton from '../images/my-cart-back-button.png';
import minusIcon from '../images/my-cart-minus.png';
import minusPlus from '../images/my-cart-plus.png';
import promoBanner from "../images/PromoIconRed.svg";
import verticalLine from '../images/vertical-line.png';
import myCartIsEmpty from '../images/your-cart-is-empty.png';
import ThankYouForPurchaseModal from '../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal';
import Loading from "../SharedComponent/Loader/Loader";
import './MyCart.css';
const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'Monty Python and the Holy Grail', year: 1975 },
];
const MyCart = (props) => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);
    const { t } = useTranslation();
    const lancode = localStorage.getItem("lancode");
    const getAlluserscustomer = useSelector((state) => state.users.getAlluserscustomer);
    const getAllusers = useSelector((state) => state.users.getAllusers);
    const cartDetails = useSelector((state) => state.product.cartDetails);
    const Cartloading = useSelector((state) => state.product.productLoading);
    const cartAddedDetails = useSelector((state) => state.product.cartAdded);
    const cartremoveDetails = useSelector((state) => state.product.cartRemove);
    const UserListData = useSelector((state) => state.product.UserList);
    const [inputValue, setInputValue] = React.useState("");
    const [selectedValue, setSelectedValue] = React.useState(null);
    const userData = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : undefined;
    const [subtotal, setSubtotal] = React.useState(0);
    const [products, setProducts] = React.useState([]);
    const [selectedProducts, setSelectedProducts] = React.useState([]);
    const [cartdataforUser, setCartdataforUser] = React.useState([]);
    const [selectedProductforproicechange, setSelectedProductforproicechange] = React.useState([]);
    const [cartdataforuserprice, setCartdataforuserprice] = React.useState(0);
    const [users, setUsers] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [customerSelectErr, setCustomerSelectErr] = useState(false);
    const dispatch = useDispatch();
    const [isParentChecked, setIsParentChecked] = useState(false);
    const [cartUpdated, setCartUpdated] = useState(false);
    const [customerNameInput, setCustomerNameInput] = useState("");
    const [openPriceUpdate, setOpenPriceUpdate] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);
    const [defaultLoad, setDefaultLoad] = useState(false);
    const [selectedCartId, setSelectedCartId] = useState([]);
    const [mainHeading, setMainHeading] = useState("");
    const [subHeading1, setSubHeading1] = useState("");
    const history = useHistory();


    const setUpCartandSpecialPrices = (count, temp) => {
        let price;
        count = (count) ? parseInt(count) : 1;
        let packSize = 1;
        if (temp.packSize) {
            packSize = parseFloat(temp.packSize);
        }
        // if (isNull(temp.conditionAmount)) {
        if ((Number(temp.quantity1Scale1) <= (count * packSize) || (count * packSize) < 1) && Number(temp.quantity1Scale1) != 0) {
            price = Number(temp.price1Scale1)
        }
        if (Number(temp.quantity2Scale2) <= (count * packSize) && Number(temp.quantity2Scale2) != 0) {
            price = Number(temp.price2Scale2)
        }
        if (Number(temp.quantity3Scale3) <= (count * packSize) && Number(temp.quantity3Scale3) != 0) {
            price = Number(temp.price3Scale3)
        }
        if (Number(temp.quantity4Scale4) <= (count * packSize) && Number(temp.quantity4Scale4) != 0) {
            price = Number(temp.price4Scale4)
        }
        if (Number(temp.quantity5Scale5) <= (count * packSize) && Number(temp.quantity5Scale5) != 0) {
            price = Number(temp.price5Scale5)
        }
        // if (count < Number(temp.quantity5Scale5)) {
        //     price = Number(temp.price5Scale5)
        // }
        return price
        // }

    }

    const getselectedCartId = (products) => {
        const selectedProduct = products.filter(item => item.isChecked === true)
        if (selectedProduct) {
            let cartIds = selectedProduct.map(items => items.cartId)
            setSelectedCartId(cartIds)
        }


    }
    React.useEffect(() => {
        setLoading(false)
        setCartUpdated(false)
        if (cartDetails && cartDetails.data && cartDetails.data.length > 0) {
            const newData = cartDetails.data.map(items => {
                items.currentUnitPrice = setUpCartandSpecialPrices(items.cartQuantity, items)

                if (isNull(items.conditionAmount)) {
                    items.currentUnitPrice = setUpCartandSpecialPrices(items.cartQuantity, items)
                } else {
                    items.currentUnitPrice = items.conditionAmount
                }


                if (defaultLoad) {
                    items.isChecked = true
                } else {
                    items.isChecked = selectedCartId.includes(items.cartId)
                }

                if (items.price_createdat !== items.price_updatedat) {
                    setCartUpdated(true)
                }



                return items
            })
            if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
                if (defaultLoad) {
                    setIsParentChecked(true)
                } else {
                    const isAllChildsChecked = newData.every(
                        (items) => items.isChecked === true
                    );
                    if (isAllChildsChecked) {
                        setIsParentChecked(true);
                    } else {
                        setIsParentChecked(false);
                    }
                }

            }
            if (userData?.user?.role == "Customer") {
                const isAllChildsChecked = newData.every(
                    (items) => items.isChecked === true
                );
                if (isAllChildsChecked) {
                    setIsParentChecked(true);
                } else {
                    setIsParentChecked(false);
                }
            }

            setProducts(newData)
        } else {
            setProducts([])
        }

        if (getAllusers && getAllusers.data && getAllusers.data.length > 0) {

            setUsers(getAllusers.data);
        } else {
            setUsers([])
        }

        if (getAlluserscustomer && getAlluserscustomer.data && getAlluserscustomer.data.length > 0) {

            setCustomers(getAlluserscustomer.data);
        } else {
            setCustomers([])
        }
    }, [cartDetails, getAllusers, getAlluserscustomer])

    React.useEffect(() => {
        const selectedProduct = products && products.filter(item => item.isChecked === true)
        setSelectedProducts(selectedProduct)
        if (selectedProduct && selectedProduct.length > 0) {
            let priceArray = selectedProduct.map(items => items.packSize !== "" ? Number(items.packSize) * items.currentUnitPrice * items.cartQuantity : items.currentUnitPrice * items.cartQuantity)
            priceArray && priceArray.length > 0 ? setSubtotal(priceArray.reduce(function (a, b) { return a + b; }, 0)) : setSubtotal(0)
        } else {
            setSubtotal(0)
        }

    }, [products])

    React.useEffect(() => {
        if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && selectedValue?.userId) {
            dispatch(productActions.getCartData("payload", { userId: selectedValue.userId }))
            // CartDetailsByCustomer()
        } else {
            dispatch(productActions.getCartData("payload", {}))
        }

        setIsParentChecked(false);

    }, [cartAddedDetails, cartremoveDetails])

    React.useEffect(() => {
        if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && selectedValue?.userId) {
            setLoading(true)
            dispatch(productActions.getCartData("payload", { userId: selectedValue.userId }))
            // CartDetailsByCustomer()
        }
    }, [selectedValue])



    React.useEffect(() => {
        setDefaultLoad(true)
        if (!userData) {
            history.push('/login')
        }
        if (userData?.user?.role === 'Saleshop' || userData?.user?.role == 'Admin') {
            dispatch(productActions.salesUserListAction({}))
        }
    }, [])

    const changeCheckboxStatus = (e, id) => {
        setDefaultLoad(false)

        const productsCopy = [...products];
        const { checked } = e.target;
        productsCopy.map((items) => {
            if (id === "p1") {
                setIsParentChecked(checked);
                items.isChecked = checked;
                // if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
                //     setIsParentChecked(checked);
                //     items.isChecked = checked;
                // }
                // if (userData?.user?.role == "Customer" && items.stock && items.stock !== null) {
                //     setIsParentChecked(checked);
                //     items.isChecked = checked;
                // }

            } else {
                if (items.cartId === id) {
                    items.isChecked = checked;
                }
                const isAllChildsChecked = productsCopy.every(
                    (items) => items.isChecked === true
                );
                if (isAllChildsChecked) {
                    setIsParentChecked(checked);
                } else {
                    setIsParentChecked(false);
                }
            }
            return items;
        });
        getselectedCartId(JSON.parse(JSON.stringify(productsCopy)))
        setProducts([...productsCopy])
    };




    const updateQuantity = (e, type, itemDetails) => {
        e.preventDefault()
        // setDefaultLoad(false)
        // getselectedCartId(JSON.parse(JSON.stringify(products)))
        let count = itemDetails.cartQuantity;
        if (type == "custom") {
            if ((isNaN(e.target.value) || !nospaces(e.target.value))) { e.target.value = itemDetails.cartQuantity; return false; }
            count = e.target.value
        } else {
            count = type == "add" ? count + 1 : count - 1
        }

        if (count && count !== "0") {
            setLoading(true)
            let payload = {
                "articaleCode": itemDetails.articaleCode,
                "storeCode": itemDetails.storeCode,
                "cartQuantity": count
            }
            dispatch(productActions.addProductToCart("payload", payload))

        }
        if ((count == "0" || count < 1) && count !== "") {
            removeCart(itemDetails)
        }

    }
    const removeCart = (items) => {
        setLoading(true)
        setDefaultLoad(false)
        getselectedCartId(JSON.parse(JSON.stringify(products)))
        const payload = {
            cartId: items.cartId
        }
        dispatch(productActions.removeCartAction(payload))
    }
    const routeToProductDetailsPage = (items) => {
        history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });
        // history.push("/productdetailscustomer", { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
    }


    const isNull = (value) => {
        return value == "" || value == undefined || value == null
    }

    const checkout = () => {
        //inputValue
        setCustomerSelectErr(false)
        const selectedProduct = products.filter(item => item.isChecked === true)
        if (selectedProduct && selectedProduct.length > 0) {

            if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
                if (selectedValue && selectedValue.userId) {
                    history.push("/CustomerCheckout", {
                        selectedProducts: selectedProduct,
                        userId: selectedValue.userId
                    });

                } else {
                    setMainHeading(t("EnterCustomeVal.label"))
                    setSubHeading1(t("EnterCustomeVal2.label"))
                    setOpenAlert(true)

                }
            }
            if (userData?.user?.role == "Customer") {
                history.push("/CustomerCheckout", {
                    selectedProducts: selectedProduct,
                    userId: userData?.user?.userId
                });
            }
        } else {
            setMainHeading("selectIteam.label")
            setSubHeading1("notselectedCart.label")
            setOpenAlert(true)
        }

    }
    const handleAlert = () => {
        setOpenAlert(false)
    }

    const Highlighted = ({ text = '', highlight = '' }) => {
        if (!highlight.trim()) {
            return <span>{text}</span>
        }
        const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
        const parts = text.split(regex)
        return (
            <span>
                {parts.filter(part => part).map((part, i) => (
                    regex.test(part) ? <mark key={i} style={{ background: "none", color: "black", "padding": "0px" }}>{part}</mark> : <span key={i}>{part}</span>
                ))}
            </span>
        )
    }

    const routeToGoBack = () => {
        if (history?.location?.state?.from === "checkout") {
            history.push('/')
        } else {
            history.goBack();
        }
    }

    // const onChangeHandleCustomer = (value) => {
    //     // debugger;
    //     setCustomerNameInput(value)

    // }
    // const onChangepriceCustomer = (value) => {
    //     let selectedProductforproicechangelocal = [];
    //     const token = localStorage.getItem("authorization");
    //     // setLoading(true);
    //     const requestOptions = {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         },
    //     };

    //     Axios.get(REACT_APP_BASE_URL + '/api/cart/getUserCart?userId=' + value.userId,
    //         requestOptions
    //     ).then(response => {
    //         setCartdataforUser(response.data.data);
    //         if (response.data.data && response.data.data.length > 0) {
    //             response.data.data.map((itemprice) => {
    //                 products.map((items) => {
    //                    if ((items.articaleCode === itemprice.articaleCode) && (itemprice.conditionAmount > 0))
    //                    {
    //                     selectedProductforproicechangelocal = itemprice;
    //                    }
    //                 })
    //             });
    //             // setProducts
    //             setSelectedProductforproicechange(selectedProductforproicechangelocal);
    //         }

    //         if (response.status === 200) {
    //             setCartdataforUser(response.data.data);
    //             setCartdataforuserprice(response.data.data.totalCount);


    //         } else {
    //             setCartdataforUser([])
    //             setCartdataforuserprice(0)

    //         }

    //     })
    //         .catch((error) => {
    //             setCartdataforUser([])
    //             setCartdataforuserprice(0)

    //         });

    //     // setLoading(false);

    // }
    return <>
        <Helmet>
            <title>Betagro My Cart</title>
        </Helmet>
        {loading && (<Loading />)}
        <div className='my-cart-mobile-view'>
            <Header />
        </div>
        <ThankYouForPurchaseModal
            image={AlertImage}
            cart={true}
            buttonLabel={t("ok.label")}
            mainHeading={mainHeading}
            subHeading1={subHeading1}
            open={openAlert}
            ClickAction={handleAlert}
            screen={"my-cart"}
        />
        <div className='container-fluid my-cart-overflow-hidden' style={{ backgroundColor: 'white' }}>
            <div className='row my-cart-back-button-main-container'>
                <div className='col-sm-4 col-4 my-cart-desktop-view' onClick={() => routeToGoBack()}>
                    <img src={myCartBackButton} className='my-cart-back-button' />
                </div>
                <div className='col-sm-4 col-4 my-cart-desktop-view'>
                    <h6 className='my-cart-back-heading'>{t("mycart.label")}</h6>
                </div>
            </div>
            <div className='row my-cart-left-container-padding'>
                <div className='col-sm-8 my-cart-main-container'>
                    <div className='row'>
                        <div className='col-sm-10 my-cart-mobile-view'>
                            <h6 className='my-cart-main-heading' data-testid="my-cart-heading">{t("mycart.label")}</h6>
                        </div>
                        <div className='col-sm-2 my-cart-mobile-view'>
                            <h6 className='my-cart-items-heading' data-testid="select-product-count">{selectedProducts && selectedProducts.length} {t("items.label")}</h6>
                        </div>
                    </div>

                    {/* Customer-dropdown-section start from here */}
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='my-cart-dropdown'>
                                <h6 className='my-cart-customer-name-heading' data-testid="customer-name-label">{t("customerName.label")}</h6>


                                {userData && userData.user && userData.user.role == "Customer" && (
                                    <TextField sx={{ width: 400 }}
                                        disabled
                                        value={
                                            lancode === "en" ? userData.user.firstNameEN + "   " + userData.user.lastNameEN + " - ID  " + userData.user.customerCode + "  -  " + userData.user.phone : userData.user.firstNameTH + "   " + userData.user.lastNameTH + " - ID  " + userData.user.customerCode + "  -  " + userData.user.phone
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                <img src={customerAvatar} className='customer-name-avatar' data-testid="inside-input-customer-image" />
                                {userData && userData.user && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (
                                    <>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={UserListData?.data || []}
                                            forcePopupIcon={false}
                                            autoComplete={false}
                                            disableClearable={false}
                                            // getOptionSelected={(option, value) => option.firstNameEN === value.firstNameEN}
                                            isOptionEqualToValue={(option, value) => option.userId === value.userId}
                                            getOptionLabel={(option) =>
                                                lancode === "en" ?
                                                    option.firstNameEN === 'New Customer' ? option.firstNameEN : option.firstNameEN + " " + option.lastNameEN + " - " + "ID" + option.customerCode + " - " + option.phone
                                                    : option.firstNameEN === 'New Customer' ? option.firstNameEN : option.firstNameTH + " " + option.lastNameTH + " - " + "ID" + option.customerCode + " - " + option.phone
                                            }

                                            sx={{ width: 300, border: 'none' }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    InputProps={{ ...params.InputProps, style: { border: 'none' } }}
                                                    onChange={(ev) => {
                                                        if (ev.target.value !== "" || ev.target.value !== null) {
                                                            setInputValue(ev.target.value)
                                                            // onChangeHandleCustomer(ev.target.value)
                                                        }
                                                    }
                                                    }
                                                    sx={{
                                                        "& fieldset": { border: 'none' },
                                                    }}
                                                    variant="outlined"
                                                    placeholder={t("EnterCustome.label")} />
                                            }
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} style={{ fontSize: 'large' }}>
                                                    <Highlighted
                                                        text={
                                                            lancode === "en" ?
                                                                option.firstNameEN === 'New Customer' ? option.firstNameEN : option.firstNameEN + " " + option.lastNameEN + " - " + "ID" + option.customerCode + " - " + option.phone
                                                                : option.firstNameEN === 'New Customer' ? option.firstNameEN : option.firstNameTH + " " + option.lastNameTH + " - " + "ID" + option.customerCode + " - " + option.phone
                                                        }
                                                        highlight={customerNameInput} />
                                                    {/* {option.firstNameEN + " " + option.lastNameEN + " - " + "ID" + option.customerCode + " - " + option.phone} */}
                                                </Box>
                                            )}

                                            onChange={(e, value) => {
                                                // autoSelectFn(value, name)
                                                if (value && value.label) {
                                                    setInputValue(value.firstNameEN)
                                                } else {
                                                    setInputValue("")
                                                }
                                                setSelectedValue(value);
                                                // onChangepriceCustomer(value);
                                            }}
                                        />

                                        {/* {customerSelectErr && (
                                        <ThankYouForPurchaseModal
                                        image={AlertImage}
                                        cart={true}
                                        buttonLabel={t("ok.label")}
                                        mainHeading={t("EnterCustomeVal.label")}
                                        subHeading1={t("EnterCustomeVal2.label")}
                                        open={openAlert}
                                        ClickAction={handleAlert}
                                         />
                                    )} */}

                                    </>
                                )}




                            </div>

                        </div>
                    </div>
                    {/* Customer-dropdown-section end */}
                    {/* price-increase-section start from here */}
                    {openPriceUpdate && products && products.length ? (
                        <div className='container-fluid'>
                            <div className='row price-change-banner'>
                                <div className='col-sm-1 col-1 p-0 m-0 d-flex align-items-center'>
                                    <img src={verticalLine} className='my-cart-vertical-line' />
                                    <img src={alertTriangleImage} className='my-cart-alert-image' />
                                </div>
                                <div className='col-sm-10 col-10 p-0 m-0 d-flex align-items-center'>
                                    <span className='price-banner-heading'>
                                        {
                                            t("cartPriceAlert.label")
                                        }
                                    </span>
                                </div>
                                <div className='col-sm-1 col-1 p-0 m-0 text-center'>
                                    <div className='price-banner-common-padding'>
                                        <img src={crossImage} className='price-banner-cross-icon' onClick={() => setOpenPriceUpdate(false)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {/* price-increase-section end */}
                    {/* My-cart-product-content start from here */}
                    <div className='row'>
                        <div className='col-sm-6 p-0 m-0 my-cart-mobile-view'>
                            <div className='table-my-cart'>
                                <Checkbox
                                    value="parent"
                                    onChange={(e) => changeCheckboxStatus(e, "p1")}
                                    checked={isParentChecked}
                                    indeterminate={selectedProducts.length > 0 && !isParentChecked}
                                    data-testid="my-cart-selected-checkbox"
                                />
                                <span className='table-my-cart-header-heading'>{t("productSort.label")}</span>
                            </div>

                        </div>
                        <div className='col-sm-2 table-my-cart my-cart-text-center my-cart-mobile-view'>
                            <div className='table-my-cart'>
                                <span style={{ top: '5px' }} className='table-my-cart-header-heading'>{t("unitPrice.label")}</span>
                            </div>
                        </div>
                        <div className='col-sm-2 table-my-cart my-cart-text-center my-cart-mobile-view'>
                            <div className='table-my-cart'>
                                <span style={{ top: '5px', left: '45px' }} className='table-my-cart-header-heading my-cart-text-center'>{t("Quantity.label")}</span>
                            </div>
                        </div>
                        <div className='col-sm-2 table-my-cart my-cart-text-center my-cart-mobile-view'>
                            <div className='table-my-cart'>
                                <span style={{ top: '5px' }} className='table-my-cart-header-heading my-cart-text-center'>{t("TotalText.label")}</span>

                            </div>
                        </div>
                    </div>
                    <div className='my-card-gray-line my-cart-mobile-view'></div>
                    {
                        products && products.length ? products.map((items) => {
                            return (
                                <>

                                    <div className='row'>
                                        <div className={(userData.user.role === 'Saleshop' || userData.user.role === 'Admin') ? 'col-sm-4 p-0 m-0' : 'col-sm-6 p-0 m-0'}>
                                            <div className='my-card-table-all-product-content-main'>
                                                <div className='d-flex flex-row align-items-center'>
                                                    <Checkbox
                                                        checked={items && items.isChecked && items.isChecked === true ? true : false}
                                                        value="child"
                                                        onChange={(e) => changeCheckboxStatus(e, items.cartId)}
                                                    // disabled={userData?.user?.role == "Customer" && !items.stock && items.stock == null ? true : false

                                                    // }
                                                    />
                                                    <div className='my-cart-product-image-border'>
                                                        <div className="my-cart-new-promo-badge">
                                                            {items && items.typeCode === "2" && (
                                                                <img alt="" src={promoBanner} style={{ width: 30 }} />
                                                            )}
                                                            {items && items.typeCode === "3" && (
                                                                <div className="my-cart-new-items-badge">
                                                                    <img alt="" src='../NewItemYellow.svg' style={{ width: 20 }} />
                                                                </div>

                                                            )}

                                                        </div>
                                                        <img onClick={() => routeToProductDetailsPage(items)} src={
                                                            (items.picture && items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                                                (REACT_APP_BASE_URL +
                                                                    "/api/download?" +
                                                                    items.picture.split(",")[0]
                                                                    ? REACT_APP_BASE_URL +
                                                                    "/api/download?" +
                                                                    items.picture.split(",")[0]
                                                                    : blankImage)
                                                                : blankImage} className='my-card-product-image' />
                                                    </div>

                                                    <div className='my-cart-product-name-id'>
                                                        {/* artical code for Saleshop */}
                                                        {userData && userData.user && userData.user.role && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (
                                                            <span className='my-cart-sales-artical-code'>{items.articaleCode}</span>
                                                        )}
                                                        <span className='my-cart-delete-image-mobile'>
                                                            <img src={crossImage} className='my-cart-delete-image' onClick={() => { removeCart(items) }} />
                                                        </span>
                                                        {/* offer-information-indicator-section start from here */}

                                                        {/* <span className='my-cart-information-indicator'>-10% <img src={informationIndicator} className='my-cart-information-indicator-image' /></span> */}
                                                        {/* offer-information-indicator-section end */}
                                                        <h6 className="my-cart-product-content-heading-product-name" onClick={() => routeToProductDetailsPage(items)} title={items?.consumerNameEN?.length > 32 ? items.consumerNameEN + " " + items?.packSizeEN : null}>
                                                            {lancode === "en" && (
                                                                <>{items.consumerNameEN} {items?.packSizeEN}</>
                                                            )}
                                                        </h6>
                                                        <h6 className="my-cart-product-content-heading-product-name" onClick={() => routeToProductDetailsPage(items)} title={items?.consumerNameTH?.length > 32 ? items.consumerNameTH + " " + items?.packSizeTH : null}>
                                                            {lancode === "th" && (
                                                                <>{items.consumerNameTH} {items?.packSizeTH}</>
                                                            )}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') ?
                                            <div className='col-sm-2 col-2 my-cart-table-inner-padding text-center'>
                                                {(userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') && (
                                                    <>
                                                        {items.stock && items.stock !== null ?
                                                            <div className='out-of-stock-container-green my-cart-mobile-view my-card-table-all-product-content'>
                                                                <span className='out-of-stock-heading-green'>{items.stock} {t("inStock.label")}</span>
                                                            </div>
                                                            :
                                                            <div className='out-of-stock-container-disable my-cart-mobile-view my-card-table-all-product-content'>
                                                                <span className='out-of-stock-heading-disable'>{t("OutofStock.label")}</span>
                                                            </div>
                                                            //  <div className='d-flex flex-row my-cart-table-inner-padding'>
                                                            //  <img src={outOfStock} className='my-cart-out-of-stock-image' />
                                                            //  <h6 className='my-cart-out-of-stock-heading'>{t("OutofStock.label")}</h6>
                                                            // </div>

                                                        }
                                                    </>
                                                    // <div className='out-of-stock-container my-cart-mobile-view'>
                                                    //     <span className='out-of-stock-heading'>{items.stock} {t("inStock.label")}</span>
                                                    // </div>
                                                )}
                                            </div> : null}
                                        <div className='col-sm-2 col-6 my-cart-text-center my-cart-table-inner-padding'>
                                            <div className='my-card-table-all-product-content'>
                                                {/* <h6 className='my-cart-product-content-delete-heading'>฿ {items.currentUnitPrice}.00</h6> */}
                                                {/*  <h6 className='my-cart-product-content-heading'>฿ {cartdataforUser && cartdataforUser.filter((itemsincartuser) => { ((itemsincartuser.articaleCode === items.articaleCode) && (itemsincartuser.conditionAmount > 0)) ? <><span className='my-cart-information-indicator'>only for you <img src={informationIndicator} className='my-cart-information-indicator-image' /></span><span style={{ textDecorationLine: "line-through", color: "red" }}>{items.currentUnitPrice}.00</span><span>{itemsincartuser.conditionAmount}.00</span></> : <span>{items.currentUnitPrice}.00</span> })} {isNull(cartdataforUser) ? <span>{items.currentUnitPrice}.00</span> : <span></span>} </h6> */}
                                                <h6 className='my-cart-product-content-heading'>฿ {convertToCurrencyFormat(items.currentUnitPrice)}</h6>
                                            </div>

                                        </div>
                                        <div className='col-sm-2 col-4 my-cart-text-center my-cart-table-plus-minus-padding pl-2 pr-2'>
                                            <div className='my-card-table-all-product-content'>

                                                {/* {userData?.user?.role == "Customer" && (
                                                    <>
                                                        {items.stock && items.stock !== null ?
                                                            <div className='d-flex flex-row my-cart-add-minus-background'>
                                                                <img src={minusIcon} className='my-cart-add-plus-image' onClick={(e) => { updateQuantity(e, "minus", items) }} />
                                                                <input type="text" className='my-cart-counts-input-group my-cart-input-group' defaultValue={items.cartQuantity} onChange={(e) => {
                                                                    updateQuantity(e, "custom", items)
                                                                }} />
                                                                <img src={minusPlus} className='my-cart-add-plus-image' onClick={(e) => {
                                                                    updateQuantity(e, "add", items)
                                                                }} />
                                                            </div>

                                                            :
                                                            <div className='d-flex flex-row quantity-out-of-stock-container'>
                                                                <img src={outOfStock} className='my-cart-out-of-stock-image' />
                                                                <h6 className='my-cart-out-of-stock-heading'>{t("OutofStock.label")}</h6>
                                                            </div>


                                                        }
                                                    </>

                                                )}

                                                {(userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (
                                                    <>
                                                        <div className='d-flex flex-row my-cart-add-minus-background'>
                                                            <img src={minusIcon} className='my-cart-add-plus-image' onClick={(e) => { updateQuantity(e, "minus", items) }} />
                                                            <input type="text" className='my-cart-counts-input-group my-cart-input-group' defaultValue={items.cartQuantity} onChange={(e) => {
                                                                updateQuantity(e, "custom", items)
                                                            }} />
                                                            <img src={minusPlus} className='my-cart-add-plus-image' onClick={(e) => {
                                                                updateQuantity(e, "add", items)
                                                            }} />
                                                        </div>
                                                    </>
                                                )} */}

                                                <div className='d-flex flex-row my-cart-add-minus-background'>
                                                    <img src={minusIcon} className='my-cart-add-plus-image' onClick={(e) => { updateQuantity(e, "minus", items) }} />
                                                    <input type="text" className='my-cart-input-group' defaultValue={items.cartQuantity} onChange={(e) => {
                                                        updateQuantity(e, "custom", items)
                                                    }} />
                                                    <img src={minusPlus} className='my-cart-add-plus-image' onClick={(e) => {
                                                        updateQuantity(e, "add", items)
                                                    }} />
                                                </div>



                                            </div>
                                            {/* out-of-stock-section start from here */}
                                            {/* <div className='d-flex flex-row my-cart-table-inner-padding'>
                                                <img src={outOfStock} className='my-cart-out-of-stock-image' />
                                                <h6 className='my-cart-out-of-stock-heading'>Out of Stock</h6>
                                            </div> */}
                                            {/* out-of-stock-section end */}
                                        </div>
                                        <div className='col-sm-2 my-cart-text-center my-cart-table-inner-padding my-cart-mobile-view'>
                                            <div className='my-card-table-all-product-content'>
                                                <div className='d-flex flex-row'>
                                                    <h6 className='my-cart-product-content-heading'>฿
                                                        {convertToCurrencyFormat(items.packSize !== "" ? Number(items.packSize) * items.currentUnitPrice * items.cartQuantity : items.currentUnitPrice * items.cartQuantity)}</h6>
                                                    <img src={crossImage} className='my-cart-delete-image' onClick={() => { removeCart(items) }} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* offer-green-banner-section start from here */}

                                    {/* 
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className='offer-green-banner-section'>
                                                <img src={offerTagImage} className='green-banner-image' />
                                                <span className='green-banner-heading'>Add 1 more to get 5% off</span>
                                            </div>
                                        </div>
                                    </div>
                                */}

                                    {/* offer-green-banner-section end */}
                                    <div className='my-card-gray-line'></div>
                                </>)
                        }) :
                            <>
                                {
                                    !cartDetails && !Cartloading ?
                                        <div className='row'>
                                            <div>
                                                <div className='my-cart-is-empty-main-container'>
                                                    <div className='my-cart-is-empty-inner-container'>
                                                        <img src={myCartIsEmpty} className='my-cart-is-empty-inner-container' height={88} width={88} />
                                                        <h6 className='my-cart-is-empty-heading'>{t("emptyCart.label")}</h6>
                                                        <div className='my-cart-is-empty-button'>
                                                            <Link to={"/allproducts"}> <span className='my-cart-is-empty-text'>{t("Continueshopping.label")}</span></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }

                            </>

                    }
                    {/* empty-shopping-section start from here */}




                    {/* empty-shopping-section start from here */}


                </div>
                {/* My-cart-product-content end */}
                {/* promo-apply-button mobile view section start from here*/}
                <div className='col-sm-12 col-12 my-cart-desktop-view'>
                    <h6 className='my-cart-promo-code'>Promo code</h6>
                </div>
                <div className='col-sm-12 col-12 my-cart-desktop-view'>
                    <div className='d-flex flex-row my-cart-appy-button-container'>
                        <input type="text" className='my-cart-promo-input-mobile-view' placeholder={t("enterPromo.label")} />
                        <div className='my-cart-apply-disable-button-mobile-view'>
                            <span className='my-cart-appy-active-button-text'>{t("Apply.label")}</span>
                        </div>
                    </div>
                </div>
                {/* promo-apply-button mobile view section end*/}
                {/* summary-section start from here */}
                <div className='col-sm-4 my-cart-summary-section'>
                    <div className='my-cart-summary-padding my-cart-stricky-right-section'>
                        <div className='row'>
                            <div className="col-sm-12 my-cart-summary-section-main-container my-cart-mobile-view">
                                <h6 className='my-cart-summary-heading'>{t("Summary.label")}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-sm-6 my-cart-sub-total-container col-6'>
                                <h6 className='my-cart-quantity-subtitle'>{t("Subtotal.label")}&nbsp;
                                    {
                                        selectedProducts && selectedProducts.length ? (
                                            <>
                                                ({selectedProducts && selectedProducts.length})
                                            </>
                                        ) : <>
                                            {selectedProducts}
                                        </>
                                    }

                                </h6>
                            </div>
                            <div className='col-sm-6 my-cart-sub-total-container col-6'>
                                <h6 className='my-cart-quantity-subtitle-price'>฿ {convertToCurrencyFormat(subtotal)}</h6>
                            </div>
                            <div className='col-sm-6 my-cart-sub-total-container col-6'>
                                <h6 className='my-cart-quantity-subtitle'>{t("shippingFee.label")}</h6>
                            </div>
                            <div className='col-sm-6 my-cart-sub-total-container col-6'>
                                <h6 className='my-cart-quantity-subtitle-price'>฿ 0.00</h6>
                            </div>
                            <div className='col-sm-6 my-cart-sub-total-container col-6'>
                                <h6 className='my-cart-quantity-subtitle'>{t("Promotion.label")}</h6>
                            </div>
                            <div className='col-sm-6 my-cart-sub-total-container col-6'>
                                <h6 className='my-cart-quantity-subtitle-price'>฿ 0.00</h6>
                            </div>
                        </div>
                        {/* apply-button-section start from here */}
                        <div className='row'>
                            <div className='col-sm-12 my-cart-mobile-view'>
                                <div className='d-flex flex-row my-cart-appy-button-container'>
                                    <input type="text" className='my-cart-promo-input' placeholder={t("enterPromo.label")} />
                                    <div className='my-cart-apply-disable-button'>
                                        <span className='my-cart-appy-active-button-text'>{t("Apply.label")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* apply-button-section end */}
                        {/* <h6 className='my-cart-error-message'>The promo code is invalid</h6> */}
                        <div className="my-cart-summary-section-gray-line my-cart-mobile-view" />
                        <div className='row'>
                            <div className='col-sm-6 my-cart-total-price-container my-cart-mobile-view'>
                                <h6 className='my-cart-quantity-subtitle'>{t("Total.label")}</h6>
                            </div>
                            <div className='col-sm-6 my-cart-total-price-container my-cart-mobile-view'>
                                <h6 className='my-cart-quantity-subtitle-price'>฿ {convertToCurrencyFormat(subtotal)}</h6>
                            </div>
                        </div>
                        {/* checkout-button-section start from here */}
                        <div className='row'>
                            <div className='col-sm-12 my-cart-mobile-view'>
                                <div className='my-cart-checkout-button' onClick={checkout}>
                                    <span className='my-cart-appy-button-text' data-testid="payment-button-text">{t("Checkoutmyorder.label")}</span>
                                </div>
                            </div>
                        </div>
                        {/* checkout-button-section end */}
                    </div>
                </div>
                {/* summary-section end */}
                {/* my-cart-total-price-mobile-view-section start from here */}
                <div className='row my-cart-total-mobile-view'>
                    <div className='col-sm-5 col-5 text-left my-cart-desktop-view'>
                        <div>
                            <Checkbox
                                value="parent"
                                onChange={(e) => changeCheckboxStatus(e, "p1")}
                                checked={isParentChecked}
                                indeterminate={selectedProducts.length > 0 && !isParentChecked}
                            /><span className='table-my-cart-header-heading-mobile-view'>{t("All.label")}</span>
                        </div>
                    </div>
                    <div className='col-sm-3 col-3 p-0 my-cart-desktop-view'>
                        <div className='d-flex flex-row'>
                            <h6 className='my-cart-total-heading-mobile-view'>{t("GrandTotal.label")}</h6> <span><h6 className='my-cart-total-price-mobile-view'>฿ {subtotal}.00</h6></span>
                        </div>
                    </div>
                    <div className='col-sm-4 col-4 p-0 my-cart-desktop-view'>
                        <div className='my-cart-checkout-button-mobile-view'>
                            <span className='my-cart-appy-button-text' onClick={checkout}>{t("Payment.label")}</span>
                        </div>
                    </div>
                </div>
                {/* my-cart-total-price-mobile-view-section end */}
            </div>
        </div>
        <div className='my-cart-mobile-view'>
            <Footer />
        </div>

    </>
}
// const MyCarts = withTranslation()(MyCart);
export { MyCart as MyCart };

