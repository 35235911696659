import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { landing } from './landing.reducer';
import { product } from './product.reducer';
import { order } from './order.reducer';
import { postcode } from './postcode.reducer';
import { myAccount } from './myAccount.reducer';
import { createOrder } from './createOrder.reducer';
import { stock } from './stock.reducer';
import { specialprice } from './specialprice.reducer';
import { uploadfile } from './uploadfile.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  landing,
  product,
  order,
  postcode,
  myAccount,
  createOrder,
  stock,
  specialprice,
  uploadfile,
});

export default rootReducer;