import React, { useState } from "react"
import { flexbox } from "@mui/system";
import styles from "./CatagoryComponent.module.css";
import "./CatagorySlidder.css"
import Carousel from 'react-bootstrap/Carousel';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GreaterThan, SmallerThan } from "../CommonStyle/ExportSVGImage";

const CategorySlider = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)

  }

  const _handlePrevIcon = () => {
    return(<SmallerThan />)
  }

  const _handleNextIcon = () => {
    return (<GreaterThan />)
  }

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <div className="container-fluid slider-main-container category-slider-mobile-view">
        <div className="container">
          <div className="row">
            <div className="col-sm-12" style={{ paddingLeft: "0px" }}>
              <label className="product-category-heading">{t("ProductCategory.label")}</label>
            </div>
          </div>
          <div className="row category-slider-image-section">
            <div>
              <Carousel slide={true} pause={false} indicators={false} prevIcon={_handlePrevIcon()} nextIcon={_handleNextIcon()} activeIndex={index} onSelect={handleSelect} className="categorybanner category-slider-mobile-view">
                <Carousel.Item><>
                  <div className="col-sm-1 slider-card">
                    <div className={styles.desktopcategory + " pl-cat"}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/pork") }} className={styles.maskGroup}>
                          {/* <Link to={"/pork"}> */}
                          <img
                            onClick={() => { history.push("/pork") }}
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/pork.svg"
                            style={{ borderRadius: "100px" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/pork") }}>{t("Pork.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/pork">{t("Pork.label")}</Link></label> */}
                    </div>
                  </div>
                  <div className="col-sm-1 slider-card">
                    <div className={styles.desktopcategory + " pl-cat"}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/chicken") }} className={styles.maskGroup}><Link to={"/chicken"}>
                          <img
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/chicken.svg"
                            style={{ borderRadius: "100px" }}
                          /></Link>
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/chicken") }}>{t("Chicken.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/chicken">{t("Chicken.label")}</Link></label> */}
                    </div>
                  </div>
                  <div className="col-sm-1 slider-card">
                    <div className={styles.desktopcategory + " pl-cat"}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/duck") }} className={styles.maskGroup}><Link to="/duck">
                          <img
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/duck.svg"
                            style={{ borderRadius: "100px" }}
                          /></Link>
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/duck") }} >{t("Duck.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/duck">{t("Duck.label")}</Link></label> */}
                    </div>
                  </div>
                  <div className="col-sm-1 slider-card">
                    <div className={styles.desktopcategory + " pl-cat"}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/fish") }} className={styles.maskGroup}><Link to={"/fish"}>
                          <img
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/fish.svg"
                            style={{ borderRadius: "100px" }}
                          /></Link>
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/fish") }} >{t("Fish.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/fish">{t("Fish.label")}</Link></label> */}
                    </div>
                  </div>
                  <div className="col-sm-1 slider-card">
                    <div className={styles.desktopcategory + " pl-cat"}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/egg") }} className={styles.maskGroup}><Link to={"/egg"}>
                          <img
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/egg.svg"
                            style={{ borderRadius: "100px" }}
                          /></Link>
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/egg") }} >{t("Egg.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/egg">{t("Egg.label")}</Link></label> */}
                    </div>
                  </div>
                  <div className="col-sm-1 slider-card">
                    <div className={styles.desktopcategory}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/processed-meat") }} className={styles.maskGroup}><Link to={"/processedmeat"}>
                          <img
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/processedmeat.svg"
                            style={{ borderRadius: "100px" }}
                          /></Link>
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/processed-meat") }} >{t("ProcessedMeat.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/processedmeat">{t("ProcessedMeat.label")} </Link></label> */}
                    </div>
                  </div>
                  <div className="col-sm-1 slider-card p-0">
                    <div className={styles.desktopcategory + " pl-cat"}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/ready-to-eat") }} className={styles.maskGroup}><Link to={"/readytoeat"}>
                          <img
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/readytoeat.svg"
                            style={{ borderRadius: "100px" }}
                          /></Link>
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/ready-to-eat") }} >{t("ReadytoEat.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/readytoeat">{t("ReadyToEat.label")}</Link></label> */}
                    </div>
                  </div>
                  <div className="col-sm-1 slider-card p-0">
                    <div className={styles.desktopcategory + " pl-cat"}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/ready-to-cook") }} className={styles.maskGroup}><Link to={"/readytocook"}>
                          <img
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/readytocook.svg"
                            style={{ borderRadius: "100px" }}
                          /></Link>
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/ready-to-cook") }} >{t("ReadytoCook.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/readytoeat">{t("ReadyToEat.label")}</Link></label> */}
                    </div>
                  </div>
                </>
                </Carousel.Item>
                <Carousel.Item><>
                  <div className="col-sm-1 slider-card">
                    <div className={styles.desktopcategory + " pl-cat"}>
                      <div className={styles.group + " borderRadius"}>
                        <div onClick={() => { history.push("/others") }} className={styles.maskGroup}>
                          {/* <Link to={"/pork"}> */}
                          <img
                            onClick={() => { history.push("/others") }}
                            className={styles.vectorIcon}
                            alt=""
                            src="../categoryimages/others.svg"
                            style={{ borderRadius: "100px" }}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                      <label className="slider-card-heading"><a onClick={() => { history.push("/others") }}>{t("Other.label")}</a></label>
                      {/* <label className={styles.pork + " label-card-font"}><Link to="/pork">{t("Pork.label")}</Link></label> */}
                    </div>
                  </div>
                </>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="container category-slider-desktop-view">
        <div className="row category-carousel-main-container-mobile">
          <div className="col-sm-12 col-12">
            <label className="product-category-heading">{t("ProductCategory.label")}</label>
          </div>
        </div>
        <div className="category-carousel-main-button-container-mobile">
        <Carousel className="category-slider-desktop-view category-carousel-inner-container-mobile" autoPlay={true} slide={true} pause={true} indicators={false} prevIcon={_handlePrevIcon()} nextIcon={_handlePrevIcon()} activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <div className="row">
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory + " pl-cat"}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/pork") }} className={styles.maskGroup}><Link to={"/pork"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/pork.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/pork") }}>{t("Pork.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/pork">{t("Pork.label")}</Link></label> */}
                </div>
              </div>
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory + " pl-cat"}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/chicken") }} className={styles.maskGroup}><Link to={"/chicken"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/chicken.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/chicken") }}>{t("Chicken.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/chicken">{t("Chicken.label")}</Link></label> */}
                </div>
              </div>
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory + " pl-cat"}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/duck") }} className={styles.maskGroup}><Link to={"/duck"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/duck.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/duck") }} >{t("Duck.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/duck">{t("Duck.label")}</Link></label> */}
                </div>
              </div>
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory + " pl-cat"}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/fish") }} className={styles.maskGroup}><Link to={"/fish"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/fish.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/fish") }} >{t("Fish.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/fish">{t("Fish.label")}</Link></label> */}
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory + " pl-cat"}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/egg") }} className={styles.maskGroup}><Link to={"/egg"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/egg.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/egg") }} >{t("Egg.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/egg">{t("Egg.label")}</Link></label> */}
                </div>
              </div>
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/processed-meat") }} className={styles.maskGroup}><Link to={"/processedmeat"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/processedmeat.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/processed-meat") }} >{t("ProcessedMeat.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/processedmeat">{t("ProcessedMeat.label")} </Link></label> */}
                </div>
              </div>
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory + " pl-cat"}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/ready-to-eat") }} className={styles.maskGroup}><Link to={"/readytoeat"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/readytoeat.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/ready-to-eat") }} >{t("ReadytoEat.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/readytoeat">{t("ReadyToEat.label")}</Link></label> */}
                </div>
              </div>
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory + " pl-cat"}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/ready-to-cook") }} className={styles.maskGroup}><Link to={"/readytocook"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/readytocook.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/ready-to-cook") }} >{t("ReadytoCook.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/readytoeat">{t("ReadyToEat.label")}</Link></label> */}
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-sm-3 col-3">
                <div className={styles.desktopcategory + " pl-cat"}>
                  <div className={styles.group + " borderRadius"}>
                    <div onClick={() => { history.push("/others") }} className={styles.maskGroup}><Link to={"/others"}>
                      <img
                        className={styles.vectorIcon}
                        alt=""
                        src="../categoryimages/others.svg"
                        style={{ borderRadius: "100px" }}
                      /></Link>
                    </div>
                  </div>
                  <label className="slider-card-heading slider-card-heading-min-height"><a onClick={() => { history.push("/others") }} >{t("Other.label")}</a></label>
                  {/* <label className={styles.pork + " label-card-font"}><Link to="/egg">{t("Egg.label")}</Link></label> */}
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        </div>
      </div>
    </>
  );
};

export default CategorySlider;
