import Axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import '../CommonStyle/mediaQuery.css';
import { Footer } from "../Footer";
import { Header } from "../Header";
import Loading from "../SharedComponent/Loader/Loader";
import { REACT_APP_BASE_URL } from "../constant";
import AllProductBanner from '../images/all-product-top-banner.png';
import "./AllProductCards.css";
import Filter from './Filters';
import ProductCards from './ProductCards';


const AllProduct = (props) => {
  const [visible, setVisible] = useState(false)
  const location = useLocation();

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };


  window.addEventListener('scroll', toggleVisible);
  const { t } = useTranslation();
  const token = localStorage.getItem("authorization");
  const [openSortByDropDown, setOpenSortByDropDown] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [defaultLoad, setDefaultLoad] = useState(true);
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalProduct, setTotalProduct] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productsPerPage] = useState(32);
  const [firstParams, setFirstParams] = useState("");
  const [secondParams, setSecondParams] = useState("");
  const history = useHistory();
  const [firstLoad, setFirstLoad] = useState(true)
  let match = useRouteMatch();
  const [iscartactive, setIscartactive] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(1);
  // const { firstParam, secondParam } = useParams();

  let paramValue = match.params.chicken;
  // const passwordReset = params.get("resetpassword");
  const userState = useSelector((state) => state.users);
  const lancode = localStorage.getItem("lancode");
  // const url = props.location.pathname;
  const [firstParam, secondParam] = props?.location?.pathname?.split('/').slice(1);

  const userData = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
  // let promoAndNewDefault=props?.pageType==='promoproducts' ?

  const defaultFilter = {
    brand: [],
    specialProduct: [],
    channel: [],
    categorySlug: [],
    subCategorySlug: [],
    storeCode: userData ? userData.user.stores[0].storeCode : ''
  }
  const [filters, setFilters] = useState(defaultFilter);

  const setPage = (page) => {
    let pages = (page - 1) * 33
    setCurrentPage(pages)
    // setCurrentPage(page)

  }




  React.useEffect(() => {
    if (!userData && userState.userLogin === null) {
      history.push('/login')
    }
    if (firstLoad) {
      let payload
      if (firstLoad)
        payload = {
          "storeCode": userData && userData.user ? userData.user.stores[0].storeCode : '',
          brand: history.location.state && history.location.state.brand ? history.location.state.brand : []
        }
      else
        payload = {
          "storeCode": userData && userData.user ? userData.user.stores[0].storeCode : '',
        }
      if (props.pageType === 'allProduct') {
        getFilters(payload)
        getProducts(payload)
      } else if (props.pageType === 'newproducts') {
        getProducts({ ...payload, specialProduct: ["3"] })
        getFilters({ ...payload, specialProduct: ["3"] })
      }
      else if (props.pageType === 'promoproducts') {
        getProducts({ ...payload, specialProduct: ["2"] })
        getFilters({ ...payload, specialProduct: ["2"] })
      }
      setFilters({
        ...filters,
        brand: history.location.state && history.location.state.brand ? history.location.state.brand : []
      })
    }
    else {
      if (props.pageType === 'allProduct') {
        getProducts(filters)
      } else if (props.pageType === 'newproducts') {
        getProducts({ ...filters, specialProduct: ["3"] })
      }
      else if (props.pageType === 'promoproducts') {
        getProducts({ ...filters, specialProduct: ["2"] })
      }
    }
    setFirstLoad(false)
  }, [firstParam, currentPage, userState, isLiked, iscartactive, cartQuantity]);

  React.useEffect(() => {
    if (!defaultLoad) {
      if (props.pageType === 'allProduct') {
        //getFilters(filters)
        getProducts(filters)
        const payloadFilter = JSON.parse(JSON.stringify(filters))
        // getFilters({ ...payloadFilter, filter: currentFilter })

      } else if (props.pageType === 'newproducts') {
        //getFilters({ ...filters, specialProduct: ["3"] })
        getProducts({ ...filters, specialProduct: ["3"] })
        const payloadFilter = JSON.parse(JSON.stringify(filters))
        // getFilters({ ...payloadFilter, filter: currentFilter,specialProduct:["3"] })
      }
      else if (props.pageType === 'promoproducts') {
        // getFilters({ ...filters, specialProduct: ["2"] })
        getProducts({ ...filters, specialProduct: ["2"] })
        const payloadFilter = JSON.parse(JSON.stringify(filters))
        // getFilters({ ...payloadFilter, filter: currentFilter,specialProduct:["2"] })
      }

    }
    // else {
    //   getProducts(filters)
    // }
    setPage(1)
  }, [filters, selectedValue]);


  const handleChange = (event, data) => {
    if (event.target.checked) {
      if (!filters[data].includes(event.target.name)) {
        setFilters(filters => ({ ...filters, [data]: [...filters[data], event.target.name] }))
        setCurrentFilter(data)
      }
    } else {
      setFilters(filters => ({ ...filters, [data]: filters[data].filter(day => day !== event.target.name) }));
      setCurrentFilter(data)
    }
    setDefaultLoad(false)
  }


  const getCatSlug = (arrr) => {
    const SubCat = arrr.map(item => item.subCategorySlug);
    return SubCat
  }

  const removeSubCatSlug = (oldArray, removeItems) => {
    const newArray = oldArray.filter((value) => {
      return !removeItems.includes(value)
    })
    return newArray
  }

  const CategoryChange = (event, data, subCat) => {
    if (event.target.checked) {
      if (!filters[data].includes(event.target.name)) {
        setFilters(filters => ({ ...filters, [data]: [...filters[data], event.target.name], subCategorySlug: [...filters["subCategorySlug"], ...getCatSlug(subCat)] }))
        setCurrentFilter(data)
      }
    } else {
      setFilters(filters => ({ ...filters, [data]: filters[data].filter(filData => filData !== event.target.name), subCategorySlug: removeSubCatSlug(filters.subCategorySlug, getCatSlug(subCat)) }));
      setCurrentFilter(data)
    }
    setDefaultLoad(false)
  }



  const resetFilter = (e, filterTYpe) => {
    if (!filterTYpe) {
      setFilters(defaultFilter)
    } else {
      if (filterTYpe === "categorySlug") {
        setFilters(filters => ({ ...filters, categorySlug: [], subCategorySlug: [] }))
      } else {
        setFilters(filters => ({ ...filters, [filterTYpe]: [] }))
      }

    }

  }

  const handleValueSelected = (value) => {
    setDefaultLoad(false)
    setSelectedValue(value);
  };

  const handleIsLiked = (value) => {
    setIsLiked(value)
  }

  const handlecarttoggle = (value) => {
    setIscartactive(value)

  }

  const handleupdateQuantity = (value) => {
    setCartQuantity(value)
  }


  const handleValueWithRadio = (value) => {
    setDefaultLoad(false)
    setSelectedValue(value);
  }

  const getFilters = (payload) => {
    setLoading(true)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    };
    Axios.post(
      REACT_APP_BASE_URL + "/api/product/filters",
      payload,
      requestOptions
    )
      .then(response => {
        if (response.data.code === 200) {
          setFilterData(response.data.data)
          setLoading(false)

        } else {

        }

      })
      .catch((error) => {

      });
  }

  const getProducts = (payload) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    };
    if (selectedValue === "Price high to low") {
      setLoading(true)
      const sort = { "price": -1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setLoading(false)
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Price low to high") {
      setLoading(true)
      const sort = { "price": 1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "A to Z") {
      setLoading(true)
      const sort = { "consumerNameEN": 1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Z to A") {
      setLoading(true)
      const sort = { "consumerNameEN": -1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            // setFilterData(response.data.data)
            setLoading(false)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Clear") {
      setLoading(true)
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else {
      setLoading(true)
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>

          {props.pageType === 'allProduct' ? 'Betagro All Product' : props.pageType === 'newproducts' ? 'Betagro New Items' : props.pageType === 'promoproducts' ? 'Betagro Promotion' : ''}
        </title>
      </Helmet>
      {/* header-section start from here */}
      <div className="header-section">
        <Header />
      </div>
      {/* header-section end */}
      {loading && (<Loading />)}
      <div className="white-background">
        {/* Top-banner-section start */}
        <div className="all-product-section">
          <div className="top-banner-section">
            <img
              src={props.pageType === 'allProduct' ? AllProductBanner : props.pageType === 'newproducts' ? AllProductBanner : props.pageType === 'promoproducts' ? AllProductBanner : AllProductBanner}
              className="img-fluid"
            />
            <h6 className="all-product-text">
              {
                props.pageType === 'allProduct' ? t("allProduct.label") : props.pageType === 'newproducts' ? t("NewItems.label") : props.pageType === 'promoproducts' ? t("Promotion.label") : ''

              }
            </h6>
            {/* { lancode === 'undefined' ? (
              <h6 className="all-product-text">All Products</h6>
            ): (
              <h6 className="all-product-text">{paramValue}</h6>
            )} */}

          </div>
        </div>
        {/* Top-banner-section end */}
        {/* All-product-counts-mobile-section start from here */}
        <div className="container">
          <div className="row">
            <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 common-display-block-mobile">
              <h6 className="All-products-mobile-counts-main-heading">{
                props.pageType === 'allProduct' ? t("allProduct.label") : props.pageType === 'newproducts' ? t("NewItems.label") : props.pageType === 'promoproducts' ? t("Promotion.label") : ''

              }</h6>
              <h6 className="All-products-mobile-counts-inner-heading">
                {lancode === "en" && (
                  <>{totalProduct + " " + t("productRound.label")}</>
                )}
                {lancode === "th" && (
                  <>
                    {t("product.label") +
                      " " +
                      totalProduct +
                      " " +
                      t("productfound.label")}
                  </>
                )}
              </h6>
            </div>
          </div>
        </div>

        {/* All-product-counts-mobile-section end */}

        {/* All-product-section start from here */}
        <div className="container all-product-inner-section">
          <div className="row">
            {/* filter-section start from here */}
            <Filter
              filterData={filterData}
              filters={filters}
              handleChange={handleChange}
              CategoryChange={CategoryChange}
              resetFilter={resetFilter}
              handleValueWithRadio={handleValueWithRadio}
              currentPage={currentPage}
              totalProduct={totalProduct}
            />
            <div className="col-sm-1"></div>

            {/* filter-section end */}
            {/* prouduct-card-section start from here */}

            <ProductCards
              productData={productData}
              setPage={setPage}
              totalProduct={totalProduct}
              onValueSelected={handleValueSelected}
              paramValue={paramValue}
              handleIsLiked={handleIsLiked}
              handlecarttoggle={handlecarttoggle}
              handleupdateQuantity={handleupdateQuantity}
              {...props}
            />

            {/* prouduct-card-section end */}
          </div>
        </div>
        {/* All-product-section start from here */}
      </div>
      {/* footer-section start from here */}
      <div className="footer-section">
        <Footer />
      </div>
      {/* footer-section start end */}
    </>
  )
}

// const AllProducts = withTranslation()(AllProduct);
export { AllProduct as AllProduct };

