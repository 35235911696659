import { productConstants } from '../_constants';
import { customLogout } from '../_helpers/commanFunction';
import { productService } from '../_services';

export const productActions = {
    getProductDetails,
    getFilterAction,
    getProductAction,
    getCartData,
    getWishList,
    addProductToCart,
    addProductToWishList,
    removeCartAction,
    salesUserListAction,
    saveTaxInformation,
    saveUserAddressFromCheckOut,
    getWishListInformation,
    postuploadproducts,

};




function postuploadproducts(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        productService.postuploadproducts(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: productConstants.POST_UPLOAD_PRODUCTS_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: productConstants.POST_UPLOAD_PRODUCTS_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: productConstants.POST_UPLOAD_PRODUCTS_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: productConstants.POST_UPLOAD_PRODUCTS_FAILURE_INFORMATION, error };

    }
}

function getWishListInformation(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        productService.getWishListInformation(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.response.status === 400) {
                    dispatch(noDataFound(res.data));
                }
                else if (res?.response?.data?.code && res.response.data.code === 409) {
                    //customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: productConstants.GET_WISH_LIST_REQUEST_INFORMATION };
    }
    function success(data) {
        return { type: productConstants.GET_WISH_LIST_SUCCESS_INFORMATION, data };
    }
    function noDataFound(data) {
        return { type: productConstants.GET_WISH_LIST_NODATA_FOUND_INFORMATION, data };
    }
    function failure(error) {
        return { type: productConstants.GET_WISH_LIST_FAILURE_INFORMATION, error };

    }
}


function getProductDetails(type, payload) {
    return (dispatch) => {
        dispatch(request());
        productService.getProductDetailsService(payload)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: productConstants.GET_PRODUCT_DETAILS };
    }
    function success(data) {
        return { type: productConstants.GET_PRODUCT_DETAILS_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: productConstants.GET_PRODUCT_DETAILS_NO_DATA_FOUND, data };
    }
    function failure(error) {
        return { type: productConstants.GET_PRODUCT_DETAILS_FAILURE, error };

    }
}
// function getFilterAction(payload) {
//     return (dispatch) => {
//         dispatch(request());
//         productService.getFilter(payload)
//             .then((res) => {
//                 if (res.status === 200 && res.data) {
//                     dispatch(success(res.data))
//                 } else {

//                 }
//             })
//             .catch((error) => dispatch(failure(error)));
//     };

//     function request() {
//         return { type: productConstants.PRODUCT_GET_FILTER_REQUEST };
//     }
//     function success(data) {
//         return { type: productConstants.PRODUCT_GET_FILTER_SUCCESS, data };
//     }

//     function failure(error) {
//         return { type: productConstants.PRODUCT_GET_FILTER_FAIL, error };

//     }
//     function noDataFound(data) {
//         return { type: productConstants.GET_PRODUCT_DETAILS_NO_DATA_FOUND, data };
//     }
//     function failure(error) {
//         return { type: productConstants.GET_PRODUCT_DETAILS_FAILURE, error };

//     }
// }
function getFilterAction(type, payload) {
    return (dispatch) => {
        dispatch(request());
        productService.getFilter(payload)
            .then((res) => {
                if (res.status === 200 && res.data) {


                } else {

                }
            })
            .catch((error) => dispatch(failure(error)));
    };

    function request() {
        return { type: productService.PRODUCT_GET_FILTER_REQUEST };
    }
    function success(data) {
        return { type: productService.PRODUCT_GET_FILTER_SUCCESS, data };
    }

    function failure(error) {
        return { type: productService.PRODUCT_GET_FILTER_SUCCESS, error };

    }

}

function getProductAction(type, payload, pagePayload) {
    return (dispatch) => {
        dispatch(request());
        productService.getProduct(payload, pagePayload)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    };

    function request() {
        return { type: productConstants.PRODUCT_GET_REQUEST };
    }
    function success(data) {
        return { type: productConstants.PRODUCT_GET_SUCCESS, data };
    }

    function failure(error) {
        return { type: productConstants.PRODUCT_GET_FAIL, error };

    }

    function failure(error) {
        return { type: productService.PRODUCT_GET_FAIL, error };

    }

}
function getCartData(type, payload) {
    return (dispatch) => {
        dispatch(request());
        productService.getCartCountService(payload)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.response.status === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    //customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: productConstants.GET_CART_COUNT };
    }
    function success(data) {
        return { type: productConstants.GET_CART_COUNT_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: productConstants.GET_CART_COUNT_NODATA, data };
    }
    function failure(error) {
        return { type: productConstants.GET_CART_COUNT_FAILURE, error };

    }
}

function getWishList(type, payload, queryString) {
    return (dispatch) => {
        dispatch(request());
        productService.getWishListCountService(payload, queryString)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: productConstants.GET_WISH_LIST_REQUEST };
    }
    function success(data) {
        return { type: productConstants.GET_WISH_LIST_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: productConstants.GET_WISH_LIST_NODATA_FOUND, data };
    }
    function failure(error) {
        return { type: productConstants.GET_WISH_LIST_FAILURE, error };

    }
}

function addProductToCart(type, payload) {
    return (dispatch) => {
        dispatch(request());
        productService.addProductToCart(payload)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: productConstants.ADD_PRODUCT_TO_CART };
    }
    function success(data) {
        return { type: productConstants.ADD_PRODUCT_TO_CART_SUCCESS, data };
    }
    // function noDataFound(data) {
    //     return { type: productConstants.GET_CART_COUNT_NO, data };
    // }
    function failure(error) {
        return { type: productConstants.ADD_PRODUCT_TO_CART_FAILURE, error };

    }
}

function addProductToWishList(type, payload) {
    return (dispatch) => {
        dispatch(request());
        productService.addProductToWishList(payload)
            .then((res) => {
                let payload2 = {
                    storeCode: payload.storeCode,
                    articleCode: payload.articaleCode
                }
                dispatch(productActions.getProductDetails("payload", payload2));
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                }
                else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
                else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: productConstants.ADD_PRODUCT_TO_WISHLIST };
    }
    function success(data) {
        return { type: productConstants.ADD_PRODUCT_TO_WISHLIST_SUCCESS, data };
    }
    function noDataFound(data) {
        return { type: productConstants.ADD_PRODUCT_TO_WISHLIST_NOT_FOUND, data };
    }
    function failure(error) {
        return { type: productConstants.ADD_PRODUCT_TO_WISHLIST_FAILURE, error };

    }
}


function removeCartAction(payload) {
    return (dispatch) => {
        dispatch(request());
        productService.removeToCart(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                } else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: productConstants.REMOVE_CART_REQUEST };
    }
    function success(data) {
        return { type: productConstants.REMOVE_CART_SUCCESS, data };
    }
    function failure(error) {
        return { type: productConstants.REMOVE_CART_FAIL, error };

    }
}

function salesUserListAction(payload) {
    return (dispatch) => {
        dispatch(request());
        productService.getSalesUserLisr(payload)
            .then((res) => {

                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));
    }
    function request() {
        return { type: productConstants.GETSALES_USERS_REQUEST };
    }
    function success(data) {
        return { type: productConstants.GETSALES_USERS_SUCCESS, data };
    }
    function failure(error) {
        return { type: productConstants.GETSALES_USERS_FAIL, error };

    }
}

function saveTaxInformation(type, payload) {
    return (dispatch) => {
        dispatch(request());
        productService.saveTaxInformationService(payload)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound(res.data));
                }
                else if (res?.response?.data?.code && res.response.data.code === 409) {
                    customLogout()
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: productConstants.SAVE_TAX_INFO };
    }
    function success(data) {
        return { type: productConstants.SAVE_TAX_INFO_SUCCESS, data };
    }
    function failure(error) {
        return { type: productConstants.SAVE_TAX_INFO_FAILURE, error };

    }
}

function saveUserAddressFromCheckOut(type, payload) {
    return (dispatch) => {
        dispatch(request());
        productService.saveUserAddressFromCheckOutService(payload)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    dispatch(success(res.data));
                } else if (res.status === undefined && res.response.data.code === 400) {
                    dispatch(noDataFound(res.data));
                }
            })
            .catch((error) => dispatch(failure(error)));

    }
    function request() {
        return { type: productConstants.SAVE_USERADDRESS_INFO };
    }
    function success(data) {
        return { type: productConstants.SAVE_USERADDRESS_SUCCESS, data };
    }
    function failure(error) {
        return { type: productConstants.SAVE_USERADDRESS_FAILURE, error };

    }
}