import { REACT_APP_BASE_URL } from "../constant";
import { Axios } from "./axiosProvider";
export const uploadfileService = {
  getuploadfilelistproduct,
  getuploadfilelistprice,
  getuploadfilelistspecialprice,
  getuploadfileliststock,
  getuploadfilelistsale,
  getuploadFileListBanner,
  getuploadFileListMobileBanner
};
function getuploadFileListBanner(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/uploadfile/list" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}
function getuploadFileListMobileBanner(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/uploadfile/list" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}
function getuploadfilelistproduct(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/uploadfile/list" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getuploadfilelistprice(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/uploadfile/list" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}



function getuploadfilelistspecialprice(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/uploadfile/list" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}



function getuploadfileliststock(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/uploadfile/list" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getuploadfilelistsale(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/uploadfile/list" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}