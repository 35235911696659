import { Axios } from "./axiosProvider";
import { REACT_APP_BASE_URL } from "../constant"
export const orderService = {
  getSaleorder
}
function getSaleorder(orderNO)
{
  const token = localStorage.getItem("authorization");
  const requestOptions = {
      headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
      
      },
  };
  return Axios.get(
      REACT_APP_BASE_URL + `/api/saleorders/getSaleOrder/byOrder/${orderNO}`,
      requestOptions
  )
      .then((response) => response)
      .catch((error) => error);
}
