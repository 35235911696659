import FormControl from '@mui/material/FormControl';
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from "moment";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ThankYouForPurchaseModal from "../../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal";
import Loading from "../../SharedComponent/Loader/Loader";
import { orderActions } from "../../_actions/order.actions";
import AlertImage from "../../images/alert1.png";
import emptyTableData from '../../images/empty-table-data.png';
import exportButton from '../../images/export-button.png';
import arrowIcon from '../../images/small-chevron-down.png';
import { OrderManagementFilter } from "./FilterComponent/OrderManagementFilter";
import Table from './Table';

let data


const headCells = [
    {
        id: 'orderNo',
        numeric: false,
        disablePadding: true,
        label: 'Order No.',
        bold: false,
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Customer',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'totalOrderPrice',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'orderCreateDate',
        numeric: true,
        disablePadding: false,
        label: 'Order Date',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'createduserfirstnameen',
        numeric: true,
        disablePadding: false,
        label: 'Created by',
        align: "left",
        headerAlign: "left",
        sortable: false,
    },
    {
        id: 'userchannel',
        numeric: true,
        disablePadding: false,
        label: 'Channel',
        align: "left",
        headerAlign: "left",
        sortable: false,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'action',
        label: '',
        disablePadding: false,
        align: "right",
        headerAlign: "right",
    },
];
const CancellationOrder = (props) => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };


    window.addEventListener('scroll', toggleVisible);
    const _exporter = React.createRef();
    const excelExport = () => {
        save(_exporter);
    };
    const save = component => {
        const options = component.current.workbookOptions();
        options.sheets[0].name = "Cancellation";
        const rows = options.sheets[0].rows;
        let altIdx = 0;
        rows.forEach(row => {
            if (row.type === 'data') {
                if (altIdx % 2 !== 0) {
                    row.cells.forEach(cell => {
                        cell.background = '#aabbcc';
                    });
                }
                altIdx++;
            }
        });
        component.current.save(options);
    };

    const { t } = useTranslation();
    const lancode = localStorage.getItem("lancode");
    const CancelledOrderList = useSelector((state) => state.order.CancelledOrderList);
    const CancelledOrderLoading = useSelector((state) => state.order.CancelledOrderLoading);
    const CancelledOrderdateTime = useSelector((state) => state.order.CancelledOrderdateTime
    );

    const allmenurequest = useSelector((state) => state.landing.allmenurequest);
    const [selected, setSelected] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [pageSkip, setPageSkip] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const detaulsSort = { id: "createdAt", orderBy: -1 }
    const [sortDetails, setsetSort] = React.useState(detaulsSort);
    const [excelselectedData, setExcelselectedData] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const userData = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : undefined;
    const [mainHeading, setMainHeading] = useState("");
    const [subHeading1, setSubHeading1] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const store = userData ? userData.user.stores[0].storeCode : "";
    const dispatch = useDispatch();
    const history = useHistory();
    const [attrFilter, setAttrFilter] = useState({});
    const [filtersFields, setFiltersFields] = useState({});
    const [todaysdate, setTodaysdate] = React.useState(moment().format("DDMMYYYY"));
    const [filenames, setFilenames] = React.useState("cancellation_" + todaysdate + ".xlsx");

    React.useEffect(() => {
        setLoading(true);
        if (!userData) {
            history.push('/login')
        } else {
            if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
                history.push('/')
            }
        }
        if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
            const payload = {
                "storeCode": store,

            }
            dispatch(orderActions.getCancelledList("", payload, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))

        }
        setLoading(false);

    }, [])



    React.useEffect(() => {
        if (CancelledOrderList?.data?.result) {
            const filterData = CancelledOrderList.data.result.map(items => {
                items.orderCreateDate = moment(items.ordercreatedat).format("DD/MM/YYYY")
                items.name = items.userfirstnameen + " " + items.userlastnameen;
                items.id = items.orderId;
                return items
            })
            setTableData(filterData)
            setLoading(false)
            props.setCancelledOrderCount(CancelledOrderList.data.totalCount)
            setTotalCount(CancelledOrderList.data.totalCount)
        } else {
            setTableData([])
            setLoading(false)
            props.setCancelledOrderCount(0)
            setTotalCount(0)
        }


    }, [CancelledOrderList, CancelledOrderdateTime, page])

    const handleChangeRowsPerPage = (event) => {
        setLoading(true)
        const payload = {
            "storeCode": store,
        }
        let merged = { ...payload, ...attrFilter, ...filtersFields };
        dispatch(orderActions.getCancelledList("", merged, `limit=${parseInt(event.target.value)}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);


    };


    const handleChangePage = (event, newPage) => {
        setLoading(true)
        let page = (newPage - 1) * rowsPerPage
        const payload = {
            "storeCode": store,
        }
        setPage(newPage);
        let merged = { ...payload, ...attrFilter, ...filtersFields };
        dispatch(orderActions.getCancelledList("", merged, `limit=${parseInt(rowsPerPage)}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
        setLoading(false)
        setPageSkip(page)
    };
    const setsetSortFn = (id, sortBy) => {
        setLoading(true)
        let columnKey = id
        if (id === 'name' || id === "name") {
            columnKey = 'firstNameEN'
        }
        else if (id === 'orderCreateDate' || id === "orderCreateDate") {
            columnKey = 'createdAt'
        }
        const payload = {
            "storeCode": store,
        }
        let merged = { ...payload, ...attrFilter, ...filtersFields };
        dispatch(orderActions.getCancelledList("", merged, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [columnKey]: sortBy }))}`))
        setsetSort({ ...sortDetails, id: columnKey, orderBy: sortBy })
        setLoading(false);
    }

    if (excelselectedData.length > 0) {
        data = excelselectedData;
    }
    else {

        data = tableData;
    }
    const convertToCurrencyFormatNew = (value = 0) => {
        value = value === "null" ? 0 : value;

        const currency = Intl.NumberFormat("en-us").format(Number(value));

        let afterDecimal = currency.includes(".") ? currency.split(".")[1] : "00";

        if (afterDecimal.length === 1) {

            afterDecimal = `${afterDecimal}0`;

        }

        return `${currency.split(".")[0]}`;
    };
    data.map((items) => {
        items.orderCreateDate = moment(items.ordercreatedat).format("DD/MM/YYYY");
        items.orderCreateDate2 = moment(items.ordercreatedat).format("DD.MM.YYYY");
        items.totalOrderPrice2 = convertToCurrencyFormatNew(Number(items.totalOrderPrice));
        if (items.status === 'new') { items.status2 = 'Expired'; }
        else { items.status2 = items.status; }
        return items;
    })




    const filterCancelledList = (selectedItem) => {
        //debugger;
        let payload = {
            storeCode: store,
        }
        if (selectedItem?.channelDefaultValue) {
            payload.userChannel = selectedItem?.channelDefaultValue
        }
        if (selectedItem?.deliveryTime) {
            payload.deliveryTime = selectedItem?.deliveryTime
        }
        if (selectedItem?.deliveryDate?.startDate) {
            payload.deliveryDate = {
                from: moment(selectedItem?.deliveryDate?.startDate).format("YYYY-MM-DD"),
                to: moment(selectedItem?.deliveryDate?.endDate).format("YYYY-MM-DD")
            }
        }
        if (selectedItem?.orderDate?.startDate) {
            payload.createdAt = {
                from: moment(selectedItem?.orderDate?.startDate).format("YYYY-MM-DD"),
                to: moment(selectedItem?.orderDate?.endDate).format("YYYY-MM-DD")
            }

        }
        setLoading(true)
        setFiltersFields(payload)
        let merged = { ...payload, ...attrFilter };
        dispatch(orderActions.getCancelledList("", merged, `limit=${parseInt(rowsPerPage)}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
        setLoading(false);
    }
    const handleAlert = () => {
        setOpenAlert(false)
    }

    const orderUpdate = () => {
        setLoading(true);
        const payload = {
            storeCode: store,
            flags: { product: true },
        };
        dispatch(orderActions.getCancelledList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`));
        props.updateorderbadges();
        setLoading(false);

    }

    const searchItemByAttr = (query) => {
        setLoading(true);
        let payload = query
        payload.storeCode = store
        let merged = { ...payload, ...filtersFields };
        dispatch(
            orderActions.getCancelledList("", merged, `limit=${parseInt(rowsPerPage)}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`));
        setAttrFilter(payload)
        setLoading(false);
    }

    return (
        <div>
            <ThankYouForPurchaseModal
                image={AlertImage}
                cart={true}
                buttonLabel={t("ok.label")}
                mainHeading={mainHeading}
                subHeading1={subHeading1}
                open={openAlert}
                ClickAction={handleAlert}
            />
            {loading && (<Loading />)}
            <OrderManagementFilter
                filterCancelledList={filterCancelledList}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                searchItemByAttr={searchItemByAttr}
            />

            <div className='row'>
                <div className='col-sm-8 col-md-8'>
                    <div className='new-order-list-table-main-container'>
                        <div className="table-order-counts">
                            <h6 className='new-order-list-table-order-counts'>
                                {/* {totalCount === 0 || !totalCount ? totalCount + " Order" : totalCount === 1 ? totalCount + " Order" : totalCount + " Orders"} */}
                                {totalCount === 0 || !totalCount || totalCount === 1 ? totalCount + " Order" : totalCount + " Orders"}
                            </h6>
                            {totalCount !== 0 && selected.length > 0 && (
                                <h6 className="table-selected-counts">({selected.length} selected)</h6>
                            )
                            }

                        </div>
                    </div>
                </div>
                {/* export-button-section start from here */}
                <div className='col-sm-4 col-md-4'>
                    <button className='export-button-main-container' onClick={excelExport}>
                        <img src={exportButton} className='export-button-image' />
                        <span className="export-button-heading">Export</span>
                    </button>
                    <ExcelExport data={data} fileName={filenames} ref={_exporter}>
                        <ExcelExportColumn field="orderNo" title="Order No." cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="name" title="Customer" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="totalOrderPrice2" title="Total(Baht)" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="orderCreateDate2" title="Order Date" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="createduserfirstnameen" title="Created by" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="userchannel" title="Channel" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="status2" title="Status" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />

                    </ExcelExport>
                </div>
                {/* {selected.length > 0 ? <div className='col-sm-2 col-md-2'>
                    <button className='to-full-button-main-container' onClick={(event) =>
                        ReorderMultiple(event)} >
                        <span className="to-full-button-heading"> ReOrder</span>
                    </button>
                </div> : <div className='col-sm-2 col-md-2'>
                    <button className="to-confirm-button-main-container-diactivated" title=" Select Orders to Activate ReOrder Button " onClick={ReorderMultiple} >
                        <span className="to-full-button-heading"> ReOrder</span>
                    </button>
                </div>} */}

                {/* export-button-section end */}
            </div>
            <div className='row'>
                {tableData?.length > 0 ?
                    <div className='col-sm-12 col-md-12 to-comfirm-table-main-container'>
                        {/* table will come */}
                        <Table
                            tableData={tableData}
                            headCells={headCells}
                            setSelected={setSelected}
                            selected={selected}
                            setExcelselectedData={setExcelselectedData}
                            excelselectedData={excelselectedData}
                            totalCount={totalCount}
                            setPage={setPage}
                            page={page}
                            setRowsPerPage={setRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            setsetSortFn={setsetSortFn}
                            sortDetails={sortDetails}
                            orderUpdate={orderUpdate}
                        />

                    </div> :
                    <>
                        {!CancelledOrderList && !CancelledOrderLoading && (
                            <>
                                <div className='col-sm-12 col-md-12'>
                                    <div className='table-empty-data-main-container'>
                                        <img src={emptyTableData} className='table-empty-data-image' />
                                        <h6 className='table-empty-data-heading'>No cancelled/expired order yet</h6>
                                    </div>
                                </div>

                                {tableData && tableData.length > 0 &&
                                    <div className='row order-table-pagination-main-container'>
                                        <div className='col-sm-7 order-table-pagination-inner-container'>
                                            <div className="d-flex flex-row">
                                                <h6 className='order-table-pagination-count'>1-{rowsPerPage} of 0</h6>
                                                <h6 className='order-table-order-per-page'>Order per page</h6>
                                                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                                    <Select
                                                        variant="outlined"
                                                        value={rowsPerPage}
                                                        // onChange={props.handleChangeRowsPerPage}
                                                        displayEmpty
                                                        sx={{
                                                            "&:hover": {
                                                                "&& fieldset": {
                                                                    border: "2px solid #61A60E;"
                                                                }
                                                            }
                                                        }}
                                                        inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ position: "absolute", width: 11, height: 7, left: 37, bottom: 37, cursor: "pointer" }} /> }}

                                                    >
                                                        <MenuItem value={10}>10</MenuItem>
                                                        <MenuItem value={20}>20</MenuItem>
                                                        <MenuItem value={30}>30</MenuItem>
                                                        <MenuItem value={40}>40</MenuItem>
                                                        <MenuItem value={50}>50</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                    </div>

                                }

                            </>
                        )}
                    </>
                }
            </div>
        </div>
    )

}

export default CancellationOrder