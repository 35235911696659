import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button';
import './letsShop.css'
import FirstImage from "./1st.svg";
import secondI from "./2.svg";
import thirdI from "./3.svg";
import fourthI from "./4.svg";
import bgImage from "./bg.svg";
import { useHistory } from "react-router-dom";
import { fontWeight } from '@mui/system';


const LetsShop = (props) => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <>
            <div className='container-fluid let-start-main-container'>
                <div className='container p-0'>
                <div
                    className='letsShop lets-betagro-background-image'
                >
                    <div className='letShopheader'>
                        <h1 className='let-go-heading'>{t("Let'sshopatBetagroShop.label")}</h1>
                        <Button onClick={() => {
                            history.push("/allproducts")
                        }} size="lg" className="find-out-more">
                         {t("Findoutmore.label")}  &gt;
                        </Button>
                    </div>
                    <div className="row lestShop">
                        <div className="col-sm-6 col-md-3 col-lg-3 col-6 cardCenter">
                            <div className="" >
                                <img src={FirstImage} className="card-img-top letShopImage" alt="..." />
                                <div className="card-body p-0">
                                    <h3 className="card-text letshopText my-translated-paragraph-nextline">{t("Varietyofhighquality&safetyproducts.label")}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-6 cardCenter pl-0">
                            <div className="" >
                                <img src={secondI} className="card-img-top letShopImage" alt="..." />
                                <div className="card-body p-0">
                                    <h3 className="card-text letshopText my-translated-paragraph-nextline">{t("Allproductin-storewithsupplyconsistency.label")}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-6 cardCenter">
                            <div className="" >
                                <img src={thirdI} className="card-img-top letShopImage" alt="..." />
                                <div className="card-body p-0">
                                    <h3 className="card-text letshopText my-translated-paragraph-nextline">{t("On-timedeliveryservice.label")}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 col-6 cardCenter">
                            <div className="" >
                                <img src={fourthI} className="card-img-top letShopImage" alt="..." />
                                <div className="card-body p-0">
                                    <h3 className="card-text letshopText my-translated-paragraph-nextline">{t("FoodServicebusinesssolutionprovider.label")}</h3>
                                </div>
                            </div>
                        </div>
                        {/* Find out more button */}
                            <div className='find-out-button-mobile-view'>
                                <Button onClick={() => {
                                    history.push("/allproducts")
                                }} size="lg" className="find-out-more-mobile-view">
                                    {t("Findoutmore.label")}  <span>&gt;</span>
                                </Button>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )

}

export default LetsShop;