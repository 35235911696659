import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import AddToCartModalMobile from "../ModalScreens/AddToCartModal/addToCardMobileModal";
import { AddToCartModal } from "../ModalScreens/AddToCartModal/addToCardModal";
import { landingPageActions } from "../_actions/landing.actions";
import { productActions } from "../_actions/product.actions";
import { REACT_APP_BASE_URL } from "../constant";
import blankImage from "../images/empty-image.png";
import minusProdouctButton from "../images/minus-product-button.png";
import addProdouctButton from "../images/plus-product-button.png";
import ActiveWishlist from "../images/white-heart.svg";
import WishListGrey from "./SVG/WishListGrey.svg";
import "./style.css";

const NewItemCard = () => {
  const [newItemDatavalue, setNewItemDatavalue] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [updateVal, setUpdateVal] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const [addedToCardModal, setAddedToCardModal] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(0);
  const [productData, setProductData] = useState([]);
  const cartDetails = useSelector((state) => state.product.cartDetails);
  // const newItemData = useSelector((state) => state.landing.newItemData);
  const [cartUpdated, setCartUpdated] = useState(false);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [iscartactive, setIscartactive] = useState(false);
  const [cartupdateQuantity, setCartupdateQuantity] = useState(1);
  const [wishlistStatus, setwishlistStatus] = useState(false);
  const [carttoggleStatus, setCarttoggleStatus] = useState(true);
  const userState = useSelector((state) => state.users);
  const [isLiked, setIsLiked] = useState(false);


  const newItemData = useSelector((state) => state.landing.newItemData);

  const lancode = localStorage.getItem("lancode");

  const authUser = sessionStorage.getItem("authorization");

  const addWishListDetails = useSelector(
    (state) => state.product.addWishlistDetails
  );

  const cartAddedDetails = useSelector(
    (state) => state.product.cartAdded
  );
  const cartRemoveDetails = useSelector(
    (state) => state.product.cartRemove
  );

  React.useEffect(() => {
    if (addWishListDetails?.data)
      dispatch(productActions.getWishListInformation("payload", {}, ''));
    // let payload2 = {
    //   storeCode: storeCodeNew,
    //   articleCode: items.articaleCode
    // }
    // dispatch(productActions.getProductDetails("payload", payload2));
  }, [addWishListDetails])

  React.useEffect(() => {
    if (cartAddedDetails?.data)
      dispatch(productActions.getCartData("payload", {}))
  }, [cartAddedDetails])

  React.useEffect(() => {
    if (cartRemoveDetails?.data)
      dispatch(productActions.getCartData("payload", {}))
  }, [cartRemoveDetails])

  const setUpCartandSpecialPrices = (count, temp) => {
    let price;
    count = (count) ? parseInt(count) : 1;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }

    if (isNull(temp.conditionAmount)) {
      if ((Number(temp.quantity1Scale1) <= (count * packSize) || Number(temp.quantity1Scale1) > (count * packSize)) && Number(temp.quantity1Scale1) != 0) {
        price = Number(temp.price1Scale1)
      }
      if (Number(temp.quantity2Scale2) <= (count * packSize) && Number(temp.quantity2Scale2) != 0) {
        price = Number(temp.price2Scale2)
      }
      if (Number(temp.quantity3Scale3) <= (count * packSize) && Number(temp.quantity3Scale3) != 0) {
        price = Number(temp.price3Scale3)
      }
      if (Number(temp.quantity4Scale4) <= (count * packSize) && Number(temp.quantity4Scale4) != 0) {
        price = Number(temp.price4Scale4)
      }
      if (Number(temp.quantity5Scale5) <= (count * packSize) && Number(temp.quantity5Scale5) != 0) {
        price = Number(temp.price5Scale5)
      }
      // if (count < Number(temp.quantity5Scale5)) {
      //   price = Number(temp.price5Scale5)
      // }
    }
    else {
      price = temp.conditionAmount;
    }
    return price
    // }

  }

  useEffect(() => {
    getNewItemsData();

    setLoading(false)
    setCartUpdated(false)
    if (cartDetails && cartDetails.data && cartDetails.data.length > 0) {
      const newData = cartDetails.data.map(items => {
        items.currentUnitPrice = setUpCartandSpecialPrices(items.cartQuantity, items)
        if (items.price_createdat !== items.price_updatedat) {
          setCartUpdated(true)
        }
        return items
      })
      setProducts(newData)
    } else {
      setProducts([])
    }


  }, []);

  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };

  useEffect(() => {
    if (!isNull(newItemData) && (newItemData.data.result.length > 0)) {
      const newData = newItemData.data.result.map(items => {
        items.currentUnitPrice = setUpCartandSpecialPrices(items.cartQuantity, items)

        if (isNull(items.conditionAmount)) {
          items.currentUnitPrice = setUpCartandSpecialPrices(items.cartQuantity, items)
        } else {
          items.currentUnitPrice = items.conditionAmount
        }
        return items;
      });
      setNewItemDatavalue(newData);
    }
    else {
      setNewItemDatavalue(null);
    }
    /* ? setNewItemDatavalue(getNewItemData.newItemData.data)
    : setNewItemDatavalue(null); */

  }, [newItemData]);

  const getNewItemsData = () => {
    const payloadWithOutLogin = {
      typeCode: "3",
    };
    const payloadWithLogin = {
      typeCode: "3",
      storeCode: userData ? userData.user.stores[0].storeCode : "",
    };
    if (!authUser) {
      dispatch(
        landingPageActions.getNewItemsDataWithOutLogin(
          "newItem",
          payloadWithOutLogin
        )
      );
    } else {
      dispatch(
        landingPageActions.getNewItemsDataWithLogin(
          "newItem",
          payloadWithLogin
        )
      );
    }
  };

  const routeToProductDetailsPage = (items) => {
    if (!isNull(userData)) {
      history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice2025 = async (items, index) => {
    index = index + 20
    let temp = newItemDatavalue;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      setNewItemDatavalue(temp)
      setProductData(items)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      // setCarttoggleStatus(!carttoggleStatus)
      // handlecarttoggle(carttoggleStatus)
      // handleupdateQuantity(cartQuantity)


    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToCartslice1520 = async (items, index) => {
    setAddedToCardModal(true)
    index = index + 15
    let temp = newItemDatavalue;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      setNewItemDatavalue(temp)
      setProductData(items)
    }
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      // dispatch(productActions.getCartData("payload", payload))
      // setCarttoggleStatus(!carttoggleStatus)
      // handlecarttoggle(carttoggleStatus)
      // handleupdateQuantity(cartQuantity)


    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }



  const AddItemToCartslice1015 = async (items, index) => {
    index = index + 10
    let temp = newItemDatavalue;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      setNewItemDatavalue(temp)
      setProductData(items)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      //dispatch(productActions.getCartData("payload", payload))
      // setCarttoggleStatus(!carttoggleStatus)
      // handlecarttoggle(carttoggleStatus)
      // handleupdateQuantity(cartQuantity)


    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }


  const AddItemToCartslice510 = async (items, index) => {
    index = index + 5
    let temp = newItemDatavalue;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      setNewItemDatavalue(temp)
      setProductData(items)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      //dispatch(productActions.getCartData("payload", payload))
      // setCarttoggleStatus(!carttoggleStatus)
      // handlecarttoggle(carttoggleStatus)
      // handleupdateQuantity(cartQuantity)


    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }
  const AddItemToCartslice05 = async (items, index) => {
    index = index
    let temp = newItemDatavalue;
    let count = isNull(temp[index].cartQuantity) ? 0 : parseFloat(temp[index].cartQuantity)
    let price = productPrice;
    count = count + 1
    count >= 1 && setCartQuantity(count)
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      setNewItemDatavalue(temp)
      setProductData(items)
    }
    setAddedToCardModal(true)
    if (!isNull(userData)) {
      let message = "Item Added To Cart!";
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": count
      }
      dispatch(productActions.addProductToCart("payload", payload))
      //dispatch(productActions.getCartData("payload", payload))
      // setCarttoggleStatus(!carttoggleStatus)
      // handlecarttoggle(carttoggleStatus)
      // handleupdateQuantity(cartQuantity)


    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToWishListslice05 = async (status, items, index) => {

    let temp = newItemDatavalue;
    ////// additions to be done by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setNewItemDatavalue(temp);
      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToWishListslice510 = async (status, items, index) => {

    let temp = newItemDatavalue;
    index = index + 5;
    ////// additions to be done by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setNewItemDatavalue(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToWishListslice1015 = async (status, items, index) => {

    let temp = newItemDatavalue;
    index = index + 10;
    ////// additions to be done by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setNewItemDatavalue(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToWishListslice1520 = async (status, items, index) => {

    let temp = newItemDatavalue;
    index = index + 15;
    ////// additions to be done by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setNewItemDatavalue(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  const AddItemToWishListslice2025 = async (status, items, index) => {

    let temp = newItemDatavalue;
    index = index + 20;
    ////// additions to be done by shahbaz
    temp[index].wishlistStatus = status

    if (!isNull(userData)) {
      //debugger
      // alert("Item added to wishlist")
      let message = status ? "Item Added To Wishlist!" : "Item Removed From Wishlist!"
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      temp[index].selectValues = updatePD
      setNewItemDatavalue(temp);
      // setPromoData(temp);

      dispatch(productActions.addProductToWishList("payload", payload))
      // dispatch(productActions.getWishListInformation("payload",{},`?limit=100000&skip=0`));
      // let payload2 = {
      //   storeCode: items.storeCode,
      //   articleCode: items.articaleCode
      // }
      // dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }


  const handleIsLiked = (value) => {
    setIsLiked(value)
  }

  const handlecarttoggle = (value) => {
    setIscartactive(value)

  }

  const handleupdateQuantity = (value) => {
    setCartupdateQuantity(value)
  }

  const updateProductValue = (newItemDatavalue) => {
    setUpdateVal(!updateVal)
    // props.updatedProductsValue(products)
  }

  // Handling added to cart modal close
  const addedToCartModalClose = () => {
    setAddedToCardModal(false);
    setCarttoggleStatus(carttoggleStatus)
  }
  const updateQuantityslice2025 = async (e, type, itemDetails, index) => {
    //debugger;
    index = index + 20;
    e.preventDefault()
    let temp = newItemDatavalue;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      // setProducts(temp);
      //       
      // 
      setNewItemDatavalue(temp)
      // setNewItemDatavalue(temp)

    }
    // if ((count == 0) && (type == "minus")) { /////////////// remove from cart called

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   //dispatch(productActions.getCartData("payload", payload));

    //   // setCarttoggleStatus(!carttoggleStatus)
    //   // handleupdateQuantity(count)
    //   // handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    //dispatch(productActions.getCartData("payload", payload));
    // handleupdateQuantity(count)


    //   }
    // }

  }


  const updateQuantityslice1520 = async (e, type, itemDetails, index) => {
    //debugger;
    index = index + 15;
    e.preventDefault()
    let temp = newItemDatavalue;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    // 
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      // setProducts(temp);
      //       
      // 
      setNewItemDatavalue(temp)
      // setNewItemDatavalue(temp)

    }
    // if ((count == 0) && (type == "minus")) { /////////////// remove from cart called

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //  //dispatch(productActions.getCartData("payload", payload));

    //   // setCarttoggleStatus(!carttoggleStatus)
    //   // handleupdateQuantity(count)
    //   // handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    //dispatch(productActions.getCartData("payload", payload));
    // handleupdateQuantity(count)


    //   }
    // }

  }


  const updateQuantityslice510 = async (e, type, itemDetails, index) => {
    //debugger;
    index = index + 5;
    e.preventDefault()
    let temp = newItemDatavalue;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      // setProducts(temp);
      //       
      // 
      setNewItemDatavalue(temp)
      // setNewItemDatavalue(temp)

    }
    // if ((count == 0) && (type == "minus")) { /////////////// remove from cart called

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   //dispatch(productActions.getCartData("payload", payload));

    //   // setCarttoggleStatus(!carttoggleStatus)
    //   // handleupdateQuantity(count)
    //   // handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    //dispatch(productActions.getCartData("payload", payload));
    // handleupdateQuantity(count)


    //   }
    // }

  }

  const updateQuantityslice1015 = async (e, type, itemDetails, index) => {
    //debugger;
    index = index + 10;
    e.preventDefault()
    let temp = newItemDatavalue;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      // setProducts(temp);
      //       
      // 
      setNewItemDatavalue(temp)
      // setNewItemDatavalue(temp)

    }
    // if ((count == 0) && (type == "minus")) { /////////////// remove from cart called

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   //dispatch(productActions.getCartData("payload", payload));

    //   // setCarttoggleStatus(!carttoggleStatus)
    //   // handleupdateQuantity(count)
    //   // handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    // dispatch(productActions.getCartData("payload", payload));
    // handleupdateQuantity(count)


    //   }
    // }

  }
  const updateQuantityslice05 = async (e, type, itemDetails, index) => {
    //debugger;
    e.preventDefault()
    let temp = newItemDatavalue;
    let count = parseFloat(temp[index].cartQuantity)
    let price = Number(temp[index].currentUnitPrice)
    count = type == "add" ? count + 1 : count >= 1 ? count - 1 : count
    // 
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    //
    count >= 1 && setCartQuantity(count)

    if (isNull(temp[index].conditionAmount)) {
      if (Number(temp[index].quantity1Scale1) <= (count * packSize) && Number(temp[index].quantity1Scale1) != 0) {
        price = Number(temp[index].price1Scale1)
      }
      if (Number(temp[index].quantity2Scale2) <= (count * packSize) && Number(temp[index].quantity2Scale2) != 0) {
        price = Number(temp[index].price2Scale2)
      }
      if (Number(temp[index].quantity3Scale3) <= (count * packSize) && Number(temp[index].quantity3Scale3) != 0) {
        price = Number(temp[index].price3Scale3)
      }
      if (Number(temp[index].quantity4Scale4) <= (count * packSize) && Number(temp[index].quantity4Scale4) != 0) {
        price = Number(temp[index].price4Scale4)
      }
      if (Number(temp[index].quantity5Scale5) <= (count * packSize) && Number(temp[index].quantity5Scale5) != 0) {
        price = Number(temp[index].price5Scale5)
      }
      // if (count < Number(temp[index].quantity5Scale5)) {
      //   price = Number(temp[index].price5Scale5)
      // }
      // temp[index].cartQuantity = count
      // temp[index].currentUnitPrice = price
      // setProductPrice(price)
      // 
      temp[index].cartQuantity = count
      temp[index].currentUnitPrice = price
      setProductPrice(price)
      // 
      let updatePD = [];
      let x = temp[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      // setProducts(temp);
      //       
      // 
      setNewItemDatavalue(temp)

    }
    // if ((count == 0) && (type == "minus")) { /////////////// remove from cart called

    //   setLoading(true)
    //   const payload = {
    //     cartId: itemDetails.cartId
    //   }
    //   dispatch(productActions.removeCartAction(payload))
    //   //dispatch(productActions.getCartData("payload", payload));

    //   // setCarttoggleStatus(!carttoggleStatus)
    //   // handleupdateQuantity(count)
    //   // handlecarttoggle(carttoggleStatus)

    // } else {
    //   if (count) {
    setLoading(true)
    let payload = {
      "articaleCode": itemDetails.articaleCode,
      "storeCode": itemDetails.storeCode,
      "cartQuantity": count
    }
    dispatch(productActions.addProductToCart("payload", payload))
    //dispatch(productActions.getCartData("payload", payload));
    // handleupdateQuantity(count)


    //   }
    // }

  }


  return (
    <>
      <div className="container-fluid">
        <div className="newitem-wraper">
          <div className="new-item-parent">
            <div className="promohead">
              <div className="promohead-lt">
                <img src="../newitem.svg" alt="" />
                <h1 className="new-items-heading">{t("NewItems.label")}</h1>
              </div>
              <div className="promohead-rt">
                <Link to="/newproducts">{t("Seeallnewitems.label")}</Link>
                <img
                  src="../greenarrowright.svg"
                  alt=""
                  className="arror-image-style"
                />
              </div>
            </div>
            <Carousel className="newitem-card" interval={null} slide={false} controls={false} pause={false}>
              <Carousel.Item>
                <div className="promotions">
                  {newItemDatavalue &&
                    newItemDatavalue.length > 0 &&
                    newItemDatavalue.slice(0, 5).map((items, index) => (
                      <div className="promo" key={index}>
                        <div className="promo-img" title=" Click to Product Details" onClick={() => routeToProductDetailsPage(items)}>
                          <div className="promo-img-badge1">
                            <img alt="" src="../NewItemYellow.svg" />
                          </div>
                          <img
                            src={
                              (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                (REACT_APP_BASE_URL +
                                  "/api/download?" +
                                  items.picture.split(",")[0]
                                  ? REACT_APP_BASE_URL +
                                  "/api/download?" +
                                  items.picture.split(",")[0]
                                  : blankImage)
                                : blankImage
                            }
                          />
                        </div>
                        <div className="promo-price">
                          <div className="promo-price-lt">
                            {lancode === "en" && (
                              <span
                                key={items.id}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={items.consumerNameEN}
                                onClick={() => routeToProductDetailsPage(items)}
                              >
                                {items.consumerNameEN &&
                                  items.consumerNameEN.length > 31
                                  ? items.consumerNameEN.substring(0, 31) +
                                  "..."
                                  : items.consumerNameEN}
                              </span>
                            )}

                            {lancode === "th" && (
                              <span
                                key={items.id}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={items.consumerNameTH}
                                onClick={() => routeToProductDetailsPage(items)}
                              >
                                {items.consumerNameTH &&
                                  items.consumerNameTH.length > 31
                                  ? items.consumerNameTH.substring(0, 31) +
                                  "..."
                                  : items.consumerNameTH}
                              </span>
                            )}
                          </div>
                          <div className="promo-price-rt">
                            {authUser && (
                              <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                            )}
                            {lancode === "en" && (
                              <h5 key={items.id}>{items.packSizeEN}</h5>
                            )}
                            {lancode === "th" && (
                              <h5 key={items.id}>{items.packSizeTH}</h5>
                            )}
                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                              (!isNull(items.stock) ? (
                                <span className="stock-field-in">
                                  {" "}
                                  {items.stock} in Stock{" "}
                                </span>
                              ) : (
                                <></>
                              ))}
                            {!isNull(userData) &&
                              (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                              (isNull(items.stock) ? (
                                <span className="stock-field-out hidden-sm">
                                  {" "}
                                  {t("OutofStock.label")}
                                </span>
                              ) : (
                                <></>
                              ))}
                          </div>
                        </div>

                        {/* <div className="col-sm-12 col-12"> */}
                        {!isNull(userData) && <div className="promo-desc">
                          {/* {items.selectValues && items.selectValues.length > 1 ? */}
                          <select
                            defaultValue={null}
                            onChange={(e) => {

                              let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                              let temp2 = items
                              temp2.picture = selectedItem.picture
                              temp2.price1Scale1 = selectedItem.price1Scale1
                              //                             
                              temp2.price2Scale2 = selectedItem.price2Scale2;
                              temp2.price3Scale3 = selectedItem.price3Scale3;
                              temp2.price4Scale4 = selectedItem.price4Scale4;
                              temp2.price5Scale5 = selectedItem.price5Scale5;
                              temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                              temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                              temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                              temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                              temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                              // 
                              temp2.articaleCode = selectedItem.articaleCode
                              temp2.cartQuantity = selectedItem.cartQuantity
                              temp2.wishlistStatus = selectedItem.wishlistStatus
                              temp2.stock = selectedItem.stock
                              temp2.cartId = selectedItem.cartId
                              // 
                              temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                              temp2.packSize = selectedItem.packSize
                              temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                              // 
                              temp2.conditionAmount = selectedItem.conditionAmount
                              temp2.consumerNameEN = selectedItem.consumerNameEN
                              temp2.packSizeEN = selectedItem.packSizeEN
                              temp2.productTypeEN = selectedItem.productTypeEN
                              // temp2.aboutProductEN = selectedItem.abutProductEN
                              temp2.aboutProductEN = selectedItem.aboutProductEN
                              temp2.shelfLifeEN = selectedItem.shelfLifeEN
                              temp2.otherProductEN = selectedItem.otherProductEN
                              temp2.consumerNameTH = selectedItem.consumerNameTH
                              temp2.packSizeTH = selectedItem.packSizeTH
                              temp2.productTypeTH = selectedItem.productTypeTH
                              temp2.aboutProductTH = selectedItem.aboutProductTH
                              temp2.instructionTH = selectedItem.instructionTH
                              temp2.shelfLifeTH = selectedItem.shelfLifeTH
                              temp2.otherProductTH = selectedItem.otherProductTH
                              // let temp = items.selectValues
                              // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                              // let temp2 = items
                              // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                              // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                              // temp2.picture = selectedItem.picture;
                              // temp2.articaleCode = selectedItem.articaleCode;
                              // temp2.price1Scale1 = selectedItem.price1Scale1;
                              // temp2.packSizeEN = selectedItem.packSizeEN;
                              // temp2.stock = selectedItem.stock;
                              // temp.map((val) => {
                              //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                              // })

                              updateProductValue(true)
                            }}
                          >
                            {
                              items.selectValues.map((i, ind) => {
                                // let selected = (i.select)?'selected':'';
                                // return(<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ?i.valueEN:i.valueTH}</option>);
                                return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                              })
                            }
                          </select>
                          {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                        </div>}

                        {/* <select
                            onChange={(e) => {
                              alert("WIP");
                            }}
                          >
                            {items.selectValues &&
                              items.selectValues.map((value) => {
                                let selected = value.select ? "selected" : "";
                                let result = [
                                  value.articaleCode,
                                  value.valueEN,
                                  value.valueTH,
                                  value.packSizeEN,
                                  value.productTypeEN,
                                  value.consumerNameEN,
                                ];
                                return (
                                  <option value={result} {...selected}>
                                    {lancode === "en"
                                      ? value.valueEN
                                      : value.valueTH}
                                  </option>
                                );
                              })}
                          </select> */}
                        {/*  </div> */}
                        <div className="promo-btn">
                          <div className="promo-btn-lt">
                            {items && !items.wishlistStatus &&
                              <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice05(true, items, index)}>
                                <img src={WishListGrey} />
                                {/* <span className="hidden-xs">Add To Whishlist</span> */}
                              </button>
                            }
                            {items && items.wishlistStatus &&
                              <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice05(false, items, index)}>
                                <img src={ActiveWishlist} />
                                {/* <img className="visible-xs" src={WishListWhite} /> */}
                                {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                              </button>
                            }
                          </div>
                          {(items.cartQuantity == 0 || items.cartQuantity == null || items.cartQuantity == "" || items.cartQuantity == undefined) ?
                            <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                              <div className="promo-btn-right">
                                <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice05(items, index)}>
                                  <img alt="" src="../AddToCartWhite.svg" />
                                  {t("Addtocart.label")}
                                </button></div>
                            </div> : null}
                          {items.cartQuantity > 0 ?
                            <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                              <div className="promo-btn-addtocart-add-minus">
                                <div className="row text-center">
                                  <div className="col-sm-5 col-5 justify-content-start">
                                    <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice05(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                  </div>
                                  <div className="col-sm-2 col-2 p-0 m-0 justify-content-center">
                                    <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                  </div>
                                  <div className="col-sm-5 col-5 justify-content-end">
                                    <img src={addProdouctButton} onClick={(e) => { updateQuantityslice05(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                  </div>
                                </div>
                              </div>
                            </div> : null}
                        </div>
                      </div>
                    ))}
                </div>
              </Carousel.Item>

              {newItemDatavalue &&
                newItemDatavalue.length > 5 && (
                  <Carousel.Item>
                    <div className="promotions">
                      {newItemDatavalue &&
                        newItemDatavalue.length > 5 &&
                        newItemDatavalue.slice(5, 10).map((items, index) => (
                          <div className="promo" key={index}>
                            <div className="promo-img" onClick={() => routeToProductDetailsPage(items)}>
                              <div className="promo-img-badge1">
                                <img alt="" src="../NewItemYellow.svg" />
                              </div>
                              <img
                                src={
                                  REACT_APP_BASE_URL +
                                    (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                    (REACT_APP_BASE_URL +
                                      "/api/download?" +
                                      items.picture.split(",")[0]
                                      ? REACT_APP_BASE_URL +
                                      "/api/download?" +
                                      items.picture.split(",")[0]
                                      : blankImage)
                                    : blankImage
                                }
                              />
                            </div>
                            <div className="promo-price">
                              <div className="promo-price-lt">
                                {lancode === "en" && (
                                  <span
                                    key={items.id}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={items.consumerNameEN}
                                    onClick={() => routeToProductDetailsPage(items)}
                                  >
                                    {items.consumerNameEN &&
                                      items.consumerNameEN.length > 31
                                      ? items.consumerNameEN.substring(0, 31) +
                                      "..."
                                      : items.consumerNameEN}
                                  </span>
                                )}

                                {lancode === "th" && (
                                  <span
                                    key={items.id}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={items.consumerNameTH}
                                    onClick={() => routeToProductDetailsPage(items)}
                                  >
                                    {items.consumerNameTH &&
                                      items.consumerNameTH.length > 31
                                      ? items.consumerNameTH.substring(0, 31) +
                                      "..."
                                      : items.consumerNameTH}
                                  </span>
                                )}
                              </div>
                              <div className="promo-price-rt">
                                {authUser && (
                                  <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                                )}
                                {lancode === "en" && (
                                  <h5 key={items.id}>{items.packSizeEN}</h5>
                                )}
                                {lancode === "th" && (
                                  <h5 key={items.id}>{items.packSizeTH}</h5>
                                )}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (!isNull(items.stock) ? (
                                    <span className="stock-field-in">
                                      {" "}
                                      {items.stock} in Stock{" "}
                                    </span>
                                  ) : (
                                    <></>
                                  ))}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (isNull(items.stock) ? (
                                    <span className="stock-field-out">
                                      {" "}
                                      {t("OutofStock.label")}
                                    </span>
                                  ) : (
                                    <></>
                                  ))}
                              </div>
                            </div>

                            {/* <div className="col-sm-12 col-12"> */}
                            {!isNull(userData) && <div className="promo-desc">
                              {/*  { items.selectValues && items.selectValues.length > 1 ? */}
                              <select
                                defaultValue={null}
                                onChange={(e) => {

                                  let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                  let temp2 = items
                                  temp2.picture = selectedItem.picture
                                  temp2.price1Scale1 = selectedItem.price1Scale1
                                  //                             
                                  temp2.price2Scale2 = selectedItem.price2Scale2;
                                  temp2.price3Scale3 = selectedItem.price3Scale3;
                                  temp2.price4Scale4 = selectedItem.price4Scale4;
                                  temp2.price5Scale5 = selectedItem.price5Scale5;
                                  temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                  temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                  temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                  temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                  temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                  // 
                                  temp2.articaleCode = selectedItem.articaleCode
                                  temp2.cartQuantity = selectedItem.cartQuantity
                                  temp2.wishlistStatus = selectedItem.wishlistStatus
                                  temp2.stock = selectedItem.stock
                                  temp2.cartId = selectedItem.cartId
                                  // 
                                  temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                  temp2.packSize = selectedItem.packSize
                                  temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                  // 
                                  temp2.conditionAmount = selectedItem.conditionAmount
                                  temp2.consumerNameEN = selectedItem.consumerNameEN
                                  temp2.packSizeEN = selectedItem.packSizeEN
                                  temp2.productTypeEN = selectedItem.productTypeEN
                                  // temp2.aboutProductEN = selectedItem.abutProductEN
                                  temp2.aboutProductEN = selectedItem.aboutProductEN
                                  temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                  temp2.otherProductEN = selectedItem.otherProductEN
                                  temp2.consumerNameTH = selectedItem.consumerNameTH
                                  temp2.packSizeTH = selectedItem.packSizeTH
                                  temp2.productTypeTH = selectedItem.productTypeTH
                                  temp2.aboutProductTH = selectedItem.aboutProductTH
                                  temp2.instructionTH = selectedItem.instructionTH
                                  temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                  temp2.otherProductTH = selectedItem.otherProductTH
                                  //  let temp = items.selectValues
                                  //  let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                  //  let temp2 = items
                                  //  temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                  //  temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                  //  temp2.picture = selectedItem.picture;
                                  //  temp2.articaleCode = selectedItem.articaleCode;
                                  //  temp2.price1Scale1 = selectedItem.price1Scale1;
                                  //  temp2.packSizeEN = selectedItem.packSizeEN;
                                  //  temp2.stock = selectedItem.stock;
                                  //  temp.map((val) => {
                                  //    return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                  //  })
                                  // setNewItemDatavalue(temp2)                                  

                                  updateProductValue(true)
                                }}
                              >
                                {
                                  items.selectValues.map((i, ind) => {
                                    //  let selected = (i.select)?'selected':'';
                                    //  return(<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ?i.valueEN:i.valueTH}</option>);
                                    return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  })
                                }
                              </select>
                              {/* : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>}  */}
                            </div>}
                            {/* <select
                                onChange={(e) => {
                                  alert("WIP");
                                }}
                              >
                                {items.selectValues &&
                                  items.selectValues.map((value) => {
                                    let selected = value.select
                                      ? "selected"
                                      : "";
                                    let result = [
                                      value.articaleCode,
                                      value.valueEN,
                                      value.valueTH,
                                      value.packSizeEN,
                                      value.productTypeEN,
                                      value.consumerNameEN,
                                    ];
                                    return (
                                      <option value={result} {...selected}>
                                        {lancode === "en"
                                          ? value.valueEN
                                          : value.valueTH}
                                      </option>
                                    );
                                  })}
                              </select> */}
                            {/*  </div> */}
                            <div className="promo-btn">
                              <div className="promo-btn-lt">
                                {items && !items.wishlistStatus &&
                                  <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice510(true, items, index)}>
                                    <img src={WishListGrey} />
                                    {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                  </button>
                                }
                                {items && items.wishlistStatus &&
                                  <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice510(false, items, index)}>
                                    <img src={ActiveWishlist} />
                                    {/* <img className="visible-xs" src={WishListWhite} /> */}
                                    {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                  </button>
                                }
                              </div>
                              {(items.cartQuantity == 0 || items.cartQuantity == null || items.cartQuantity == "" || items.cartQuantity == undefined) ?
                                <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                  <div className="promo-btn-right">
                                    <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice510(items, index)}>
                                      <img alt="" src="../AddToCartWhite.svg" />
                                      {t("Addtocart.label")}
                                    </button>
                                  </div>
                                </div> : null}

                              {items.cartQuantity > 0 ?
                                <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                  <div className="promo-btn-addtocart-add-minus">
                                    <div className="row text-center">
                                      <div className="col-sm-5 col-5 d-flex justify-content-start">
                                        <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice510(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                      </div>
                                      <div className="col-sm-2 col-2 p-0 m-0 d-flex justify-content-center">
                                        <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                      </div>
                                      <div className="col-sm-5 col-5 d-flex justify-content-end">
                                        <img src={addProdouctButton} onClick={(e) => { updateQuantityslice510(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div> : null}

                            </div>
                          </div>
                        ))}
                    </div>
                  </Carousel.Item>
                )}
              {newItemDatavalue &&
                newItemDatavalue.length > 10 && (
                  <Carousel.Item>
                    <div className="promotions">
                      {newItemDatavalue &&
                        newItemDatavalue.length > 10 &&
                        newItemDatavalue.slice(10, 15).map((items, index) => (
                          <div className="promo" key={index}>
                            <div className="promo-img" >
                              <div className="promo-img-badge1">
                                <img alt="" src="../NewItemYellow.svg" />
                              </div>
                              <img
                                onClick={() => routeToProductDetailsPage(items)}
                                src={
                                  (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                    (REACT_APP_BASE_URL +
                                      "/api/download?" +
                                      items.picture.split(",")[0]
                                      ? REACT_APP_BASE_URL +
                                      "/api/download?" +
                                      items.picture.split(",")[0]
                                      : blankImage)
                                    : blankImage
                                }
                              />
                            </div>
                            <div className="promo-price">
                              <div className="promo-price-lt">
                                {lancode === "en" && (
                                  <span
                                    key={items.id}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={items.consumerNameEN}
                                    onClick={() => routeToProductDetailsPage(items)}
                                  >
                                    {items.consumerNameEN &&
                                      items.consumerNameEN.length > 31
                                      ? items.consumerNameEN.substring(0, 31) +
                                      "..."
                                      : items.consumerNameEN}
                                  </span>
                                )}

                                {lancode === "th" && (
                                  <span
                                    key={items.id}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={items.consumerNameTH}
                                    onClick={() => routeToProductDetailsPage(items)}
                                  >
                                    {items.consumerNameTH &&
                                      items.consumerNameTH.length > 31
                                      ? items.consumerNameTH.substring(0, 31) +
                                      "..."
                                      : items.consumerNameTH}
                                  </span>
                                )}
                              </div>
                              <div className="promo-price-rt">
                                {authUser && (
                                  <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                                )}
                                {lancode === "en" && (
                                  <h5 key={items.id}>{items.packSizeEN}</h5>
                                )}
                                {lancode === "th" && (
                                  <h5 key={items.id}>{items.packSizeTH}</h5>
                                )}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (!isNull(items.stock) ? (
                                    <span className="stock-field-in">
                                      {" "}
                                      {items.stock} in Stock{" "}
                                    </span>
                                  ) : (
                                    <></>
                                  ))}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (isNull(items.stock) ? (
                                    <span className="stock-field-out">
                                      {" "}
                                      {t("OutofStock.label")}
                                    </span>
                                  ) : (
                                    <></>
                                  ))}
                              </div>
                            </div>

                            {/*  <div className="col-sm-12 col-12"> */}
                            {!isNull(userData) && <div className="promo-desc">
                              {/*  { items.selectValues && items.selectValues.length > 1 ? */}
                              <select
                                defaultValue={null}
                                onChange={(e) => {

                                  let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                  let temp2 = items
                                  temp2.picture = selectedItem.picture
                                  temp2.price1Scale1 = selectedItem.price1Scale1
                                  //                             
                                  temp2.price2Scale2 = selectedItem.price2Scale2;
                                  temp2.price3Scale3 = selectedItem.price3Scale3;
                                  temp2.price4Scale4 = selectedItem.price4Scale4;
                                  temp2.price5Scale5 = selectedItem.price5Scale5;
                                  temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                  temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                  temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                  temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                  temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                  // 
                                  temp2.articaleCode = selectedItem.articaleCode
                                  temp2.cartQuantity = selectedItem.cartQuantity
                                  temp2.wishlistStatus = selectedItem.wishlistStatus
                                  temp2.stock = selectedItem.stock
                                  temp2.cartId = selectedItem.cartId
                                  // 
                                  temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                  temp2.packSize = selectedItem.packSize
                                  temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                  // 
                                  temp2.conditionAmount = selectedItem.conditionAmount
                                  temp2.consumerNameEN = selectedItem.consumerNameEN
                                  temp2.packSizeEN = selectedItem.packSizeEN
                                  temp2.productTypeEN = selectedItem.productTypeEN
                                  // temp2.aboutProductEN = selectedItem.abutProductEN
                                  temp2.aboutProductEN = selectedItem.aboutProductEN
                                  temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                  temp2.otherProductEN = selectedItem.otherProductEN
                                  temp2.consumerNameTH = selectedItem.consumerNameTH
                                  temp2.packSizeTH = selectedItem.packSizeTH
                                  temp2.productTypeTH = selectedItem.productTypeTH
                                  temp2.aboutProductTH = selectedItem.aboutProductTH
                                  temp2.instructionTH = selectedItem.instructionTH
                                  temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                  temp2.otherProductTH = selectedItem.otherProductTH
                                  //  let temp = items.selectValues
                                  //  let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                  //  let temp2 = items
                                  //  temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                  //  temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                  //  temp2.picture = selectedItem.picture;
                                  //  temp2.articaleCode = selectedItem.articaleCode;
                                  //  temp2.price1Scale1 = selectedItem.price1Scale1;
                                  //  temp2.packSizeEN = selectedItem.packSizeEN;
                                  //  temp2.stock = selectedItem.stock;
                                  //  temp.map((val) => {
                                  //    return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                  //  })

                                  updateProductValue(true)
                                }}
                              >
                                {
                                  items.selectValues.map((i, ind) => {
                                    //  let selected = (i.select)?'selected':'';
                                    //  return(<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ?i.valueEN:i.valueTH}</option>);
                                    return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  })
                                }
                              </select>
                              {/*  :  lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>}  */}
                            </div>}
                            {/* <select
                                onChange={(e) => {
                                  alert("WIP");
                                }}
                              >
                                {items.selectValues &&
                                  items.selectValues.map((value) => {
                                    let selected = value.select
                                      ? "selected"
                                      : "";
                                    let result = [
                                      value.articaleCode,
                                      value.valueEN,
                                      value.valueTH,
                                      value.packSizeEN,
                                      value.productTypeEN,
                                      value.consumerNameEN,
                                    ];
                                    return (
                                      <option value={result} {...selected}>
                                        {lancode === "en"
                                          ? value.valueEN
                                          : value.valueTH}
                                      </option>
                                    );
                                  })}
                              </select> */}
                            {/*  </div> */}
                            <div className="promo-btn">
                              <div className="promo-btn-lt">
                                {items && !items.wishlistStatus &&
                                  <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice1015(true, items, index)}>
                                    <img src={WishListGrey} />
                                    {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                  </button>
                                }
                                {items && items.wishlistStatus &&
                                  <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice1015(false, items, index)}>
                                    <img src={ActiveWishlist} />
                                    {/* <img className="visible-xs" src={WishListWhite} /> */}
                                    {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                  </button>
                                }
                              </div>
                              {(items.cartQuantity == 0 || items.cartQuantity == null || items.cartQuantity == "" || items.cartQuantity == undefined) ?
                                <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                  <div className="promo-btn-right">
                                    <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice1015(items, index)}>
                                      <img alt="" src="../AddToCartWhite.svg" />
                                      {t("Addtocart.label")}
                                    </button></div>
                                </div> : null}
                              {items.cartQuantity > 0 ?
                                <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                  <button className="promo-btn-addtocart-add-minus">
                                    <div className="row text-center">
                                      <div className="col-sm-5 col-5 d-flex justify-content-start">
                                        <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice1015(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                      </div>
                                      <div className="col-sm-2 col-2 p-0 m-0 d-flex justify-content-center">
                                        <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                      </div>
                                      <div className="col-sm-5 col-5 d-flex justify-content-end">
                                        <img src={addProdouctButton} onClick={(e) => { updateQuantityslice1015(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                      </div>
                                    </div>
                                  </button>
                                </div> : null}
                            </div>
                          </div>
                        ))}
                    </div>
                  </Carousel.Item>
                )}
              {newItemDatavalue &&
                newItemDatavalue.length > 15 && (
                  <Carousel.Item>
                    <div className="promotions">
                      {newItemDatavalue &&
                        newItemDatavalue.length > 15 &&
                        newItemDatavalue.slice(15, 20).map((items, index) => (
                          <div className="promo" key={index}>
                            <div className="promo-img" onClick={() => routeToProductDetailsPage(items)}>
                              <div className="promo-img-badge1">
                                <img alt="" src="../NewItemYellow.svg" />
                              </div>
                              <img
                                src={
                                  (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                    (REACT_APP_BASE_URL +
                                      "/api/download?" +
                                      items.picture.split(",")[0]
                                      ? REACT_APP_BASE_URL +
                                      "/api/download?" +
                                      items.picture.split(",")[0]
                                      : blankImage)
                                    : blankImage
                                }
                              />
                            </div>
                            <div className="promo-price">
                              <div className="promo-price-lt">
                                {lancode === "en" && (
                                  <span
                                    key={items.id}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={items.consumerNameEN}
                                    onClick={() => routeToProductDetailsPage(items)}
                                  >
                                    {items.consumerNameEN &&
                                      items.consumerNameEN.length > 31
                                      ? items.consumerNameEN.substring(0, 31) +
                                      "..."
                                      : items.consumerNameEN}
                                  </span>
                                )}

                                {lancode === "th" && (
                                  <span
                                    key={items.id}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={items.consumerNameTH}
                                    onClick={() => routeToProductDetailsPage(items)}
                                  >
                                    {items.consumerNameTH &&
                                      items.consumerNameTH.length > 31
                                      ? items.consumerNameTH.substring(0, 31) +
                                      "..."
                                      : items.consumerNameTH}
                                  </span>
                                )}
                              </div>
                              <div className="promo-price-rt">
                                {authUser && (
                                  <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                                )}
                                {lancode === "en" && (
                                  <h5 key={items.id}>{items.packSizeEN}</h5>
                                )}
                                {lancode === "th" && (
                                  <h5 key={items.id}>{items.packSizeTH}</h5>
                                )}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (!isNull(items.stock) ? (
                                    <span className="stock-field-in">
                                      {" "}
                                      {items.stock} in Stock{" "}
                                    </span>
                                  ) : (
                                    <></>
                                  ))}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (isNull(items.stock) ? (
                                    <span className="stock-field-out">
                                      {" "}
                                      {t("OutofStock.label")}
                                    </span>
                                  ) : (
                                    <></>
                                  ))}
                              </div>
                            </div>

                            {/* <div className="col-sm-12 col-12"> */}
                            {!isNull(userData) && <div className="promo-desc">
                              {/*   { items.selectValues.length > 1 ? */}
                              <select
                                defaultValue={null}
                                onChange={(e) => {

                                  let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                  let temp2 = items
                                  temp2.picture = selectedItem.picture
                                  temp2.price1Scale1 = selectedItem.price1Scale1
                                  //                             
                                  temp2.price2Scale2 = selectedItem.price2Scale2;
                                  temp2.price3Scale3 = selectedItem.price3Scale3;
                                  temp2.price4Scale4 = selectedItem.price4Scale4;
                                  temp2.price5Scale5 = selectedItem.price5Scale5;
                                  temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                  temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                  temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                  temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                  temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                  // 
                                  temp2.articaleCode = selectedItem.articaleCode
                                  temp2.cartQuantity = selectedItem.cartQuantity
                                  temp2.wishlistStatus = selectedItem.wishlistStatus
                                  temp2.stock = selectedItem.stock
                                  temp2.cartId = selectedItem.cartId
                                  // 
                                  temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                  temp2.packSize = selectedItem.packSize
                                  temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                  // 
                                  temp2.conditionAmount = selectedItem.conditionAmount
                                  temp2.consumerNameEN = selectedItem.consumerNameEN
                                  temp2.packSizeEN = selectedItem.packSizeEN
                                  temp2.productTypeEN = selectedItem.productTypeEN
                                  // temp2.aboutProductEN = selectedItem.abutProductEN
                                  temp2.aboutProductEN = selectedItem.aboutProductEN
                                  temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                  temp2.otherProductEN = selectedItem.otherProductEN
                                  temp2.consumerNameTH = selectedItem.consumerNameTH
                                  temp2.packSizeTH = selectedItem.packSizeTH
                                  temp2.productTypeTH = selectedItem.productTypeTH
                                  temp2.aboutProductTH = selectedItem.aboutProductTH
                                  temp2.instructionTH = selectedItem.instructionTH
                                  temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                  temp2.otherProductTH = selectedItem.otherProductTH
                                  // let temp = items.selectValues
                                  // let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                  // let temp2 = items
                                  // temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                  // temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                  // temp2.picture = selectedItem.picture;
                                  // temp2.articaleCode = selectedItem.articaleCode;
                                  // temp2.price1Scale1 = selectedItem.price1Scale1;
                                  // temp2.packSizeEN = selectedItem.packSizeEN;
                                  // temp2.stock = selectedItem.stock;
                                  // temp.map((val) => {
                                  //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                  // })

                                  updateProductValue(true)
                                }}
                              >
                                {
                                  items.selectValues.map((i, ind) => {
                                    // let selected = (i.select)?'selected':'';
                                    // return(<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ?i.valueEN:i.valueTH}</option>);
                                    return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  })
                                }
                              </select>
                              {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                            </div>}
                            {/* <select
                                onChange={(e) => {
                                  alert("WIP");
                                }}
                              >
                                {items.selectValues &&
                                  items.selectValues.map((value) => {
                                    let selected = value.select
                                      ? "selected"
                                      : "";
                                    let result = [
                                      value.articaleCode,
                                      value.valueEN,
                                      value.valueTH,
                                      value.packSizeEN,
                                      value.productTypeEN,
                                      value.consumerNameEN,
                                    ];
                                    return (
                                      <option value={result} {...selected}>
                                        {lancode === "en"
                                          ? value.valueEN
                                          : value.valueTH}
                                      </option>
                                    );
                                  })}
                              </select> */}
                            {/*  </div> */}
                            <div className="promo-btn">
                              <div className="promo-btn-lt">
                                {items && !items.wishlistStatus &&
                                  <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice1520(true, items, index)}>
                                    <img src={WishListGrey} />
                                    {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                  </button>
                                }
                                {items && items.wishlistStatus &&
                                  <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice1520(false, items, index)}>
                                    <img src={ActiveWishlist} />
                                    {/* <img className="visible-xs" src={WishListWhite} /> */}
                                    {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                  </button>
                                }
                              </div>
                              {(items.cartQuantity == 0 || items.cartQuantity == null || items.cartQuantity == "" || items.cartQuantity == undefined) ?
                                <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                  <div className="promo-btn-right">
                                    <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice1520(items, index)}>
                                      <img alt="" src="../AddToCartWhite.svg" />
                                      {t("Addtocart.label")}
                                    </button>
                                  </div>
                                </div> : null}
                              {items.cartQuantity > 0 ?
                                <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                  <div className="promo-btn-addtocart-add-minus">
                                    <div className="row text-center">
                                      <div className="col-sm-5 col-5 d-flex justify-content-start">
                                        <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice1520(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                      </div>
                                      <div className="col-sm-2 col-2 p-0 m-0 d-flex justify-content-center">
                                        <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                      </div>
                                      <div className="col-sm-5 col-5 d-flex justify-content-end">
                                        <img src={addProdouctButton} onClick={(e) => { updateQuantityslice1520(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div> : null}
                            </div>
                          </div>
                        ))}
                    </div>
                  </Carousel.Item>
                )}

              {newItemDatavalue &&
                newItemDatavalue.length > 20 && (
                  <Carousel.Item>
                    <div className="promotions">
                      {newItemDatavalue &&
                        newItemDatavalue.length > 20 &&
                        newItemDatavalue.slice(20, 25).map((items, index) => (
                          <div className="promo" key={index}>
                            <div className="promo-img" onClick={() => routeToProductDetailsPage(items)}>
                              <div className="promo-img-badge1">
                                <img alt="" src="../NewItemYellow.svg" />
                              </div>
                              <img
                                src={
                                  (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                    (REACT_APP_BASE_URL +
                                      "/api/download?" +
                                      items.picture.split(",")[0]
                                      ? REACT_APP_BASE_URL +
                                      "/api/download?" +
                                      items.picture.split(",")[0]
                                      : blankImage)
                                    : blankImage
                                }
                              />
                            </div>
                            <div className="promo-price">
                              <div className="promo-price-lt">
                                {lancode === "en" && (
                                  <span
                                    key={items.id}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={items.consumerNameEN}
                                    onClick={() => routeToProductDetailsPage(items)}
                                  >
                                    {items.consumerNameEN &&
                                      items.consumerNameEN.length > 31
                                      ? items.consumerNameEN.substring(0, 31) +
                                      "..."
                                      : items.consumerNameEN}
                                  </span>
                                )}

                                {lancode === "th" && (
                                  <span
                                    key={items.id}
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={items.consumerNameTH}
                                    onClick={() => routeToProductDetailsPage(items)}
                                  >
                                    {items.consumerNameTH &&
                                      items.consumerNameTH.length > 31
                                      ? items.consumerNameTH.substring(0, 31) +
                                      "..."
                                      : items.consumerNameTH}
                                  </span>
                                )}
                              </div>
                              <div className="promo-price-rt">
                                {authUser && (
                                  <h4 key={items.id}>{"฿ " + (isNull(items.currentUnitPrice) ? isNull(items.conditionAmount) ? !isNull(items.price1Scale1) && items.price1Scale1 : items.conditionAmount : items.currentUnitPrice) + ".00"}</h4>
                                )}
                                {lancode === "en" && (
                                  <h5 key={items.id}>{items.packSizeEN}</h5>
                                )}
                                {lancode === "th" && (
                                  <h5 key={items.id}>{items.packSizeTH}</h5>
                                )}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (!isNull(items.stock) ? (
                                    <span className="stock-field-in">
                                      {" "}
                                      {items.stock} in Stock{" "}
                                    </span>
                                  ) : (
                                    <></>
                                  ))}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (isNull(items.stock) ? (
                                    <span className="stock-field-out">
                                      {" "}
                                      {t("OutofStock.label")}
                                    </span>
                                  ) : (
                                    <></>
                                  ))}
                              </div>
                            </div>

                            {/* <div className="col-sm-12 col-12"> */}
                            {!isNull(userData) && <div className="promo-desc">
                              {/*  { items.selectValues.length > 1 ? */}
                              <select
                                defaultValue={null}
                                onChange={(e) => {

                                  let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                  let temp2 = items
                                  temp2.picture = selectedItem.picture
                                  temp2.price1Scale1 = selectedItem.price1Scale1
                                  //                             
                                  temp2.price2Scale2 = selectedItem.price2Scale2;
                                  temp2.price3Scale3 = selectedItem.price3Scale3;
                                  temp2.price4Scale4 = selectedItem.price4Scale4;
                                  temp2.price5Scale5 = selectedItem.price5Scale5;
                                  temp2.quantity1Scale1 = selectedItem.quantity1Scale1;
                                  temp2.quantity2Scale2 = selectedItem.quantity2Scale2;
                                  temp2.quantity3Scale3 = selectedItem.quantity3Scale3;
                                  temp2.quantity4Scale4 = selectedItem.quantity4Scale4;
                                  temp2.quantity5Scale5 = selectedItem.quantity5Scale5;
                                  // 
                                  temp2.articaleCode = selectedItem.articaleCode
                                  temp2.cartQuantity = selectedItem.cartQuantity
                                  temp2.wishlistStatus = selectedItem.wishlistStatus
                                  temp2.stock = selectedItem.stock
                                  temp2.cartId = selectedItem.cartId
                                  // 
                                  temp2.productPrice = isNull(selectedItem.conditionAmount) ? setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem) : selectedItem.conditionAmount;
                                  temp2.packSize = selectedItem.packSize
                                  temp2.currentUnitPrice = setUpCartandSpecialPrices(selectedItem.cartQuantity, selectedItem)
                                  // 
                                  temp2.conditionAmount = selectedItem.conditionAmount
                                  temp2.consumerNameEN = selectedItem.consumerNameEN
                                  temp2.packSizeEN = selectedItem.packSizeEN
                                  temp2.productTypeEN = selectedItem.productTypeEN
                                  // temp2.aboutProductEN = selectedItem.abutProductEN
                                  temp2.aboutProductEN = selectedItem.aboutProductEN
                                  temp2.shelfLifeEN = selectedItem.shelfLifeEN
                                  temp2.otherProductEN = selectedItem.otherProductEN
                                  temp2.consumerNameTH = selectedItem.consumerNameTH
                                  temp2.packSizeTH = selectedItem.packSizeTH
                                  temp2.productTypeTH = selectedItem.productTypeTH
                                  temp2.aboutProductTH = selectedItem.aboutProductTH
                                  temp2.instructionTH = selectedItem.instructionTH
                                  temp2.shelfLifeTH = selectedItem.shelfLifeTH
                                  temp2.otherProductTH = selectedItem.otherProductTH
                                  //  let temp = items.selectValues
                                  //  let selectedItem = items.selectValues.find(f => f.articaleCode === e.target.value);
                                  //  let temp2 = items
                                  //  temp2.consumerNameEN = lancode === "en" ? selectedItem.consumerNameEN : selectedItem.consumerNameTH;
                                  //  temp2.productNameEN = lancode === "en" ? selectedItem.productNameEN : selectedItem.productNameEN;
                                  //  temp2.picture = selectedItem.picture;
                                  //  temp2.articaleCode = selectedItem.articaleCode;
                                  //  temp2.price1Scale1 = selectedItem.price1Scale1;
                                  //  temp2.packSizeEN = selectedItem.packSizeEN;
                                  //  temp2.stock = selectedItem.stock;
                                  //  temp.map((val) => {
                                  //    return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                  //  })

                                  updateProductValue(true)
                                }}
                              >
                                {
                                  items.selectValues.map((i, ind) => {
                                    //  let selected = (i.select)?'selected':'';
                                    //  return(<option {...selected} value={i.articaleCode} key={ind}>{lancode === "en" ?i.valueEN:i.valueTH}</option>);
                                    return (<option value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                  })
                                }
                              </select>
                              {/*  : lancode === "en" ? <h3>{items.selectValues[0].valueEN}</h3> : <h3> {items.selectValues[0].valueTH}</h3>} */}
                            </div>}
                            {/* <select
                                onChange={(e) => {
                                  alert("WIP");
                                }}
                              >
                                {items.selectValues &&
                                  items.selectValues.map((value) => {
                                    let selected = value.select
                                      ? "selected"
                                      : "";
                                    let result = [
                                      value.articaleCode,
                                      value.valueEN,
                                      value.valueTH,
                                      value.packSizeEN,
                                      value.productTypeEN,
                                      value.consumerNameEN,
                                    ];
                                    return (
                                      <option value={result} {...selected}>
                                        {lancode === "en"
                                          ? value.valueEN
                                          : value.valueTH}
                                      </option>
                                    );
                                  })}
                              </select> */}
                            {/*  </div> */}
                            <div className="promo-btn">
                              <div className="promo-btn-lt">
                                {items && !items.wishlistStatus &&
                                  <button className="promo-btn-wishlist" title="Click to add to wishlist" border="none" onClick={() => AddItemToWishListslice2025(true, items, index)}>
                                    <img src={WishListGrey} />
                                    {/* <span className="hidden-xs">Add To Whishlist</span> */}
                                  </button>
                                }
                                {items && items.wishlistStatus &&
                                  <button className="promo-btn-wishlist-active" title="Click to remove to wishlist" border="none" onClick={() => AddItemToWishListslice2025(false, items, index)}>
                                    <img src={ActiveWishlist} />
                                    {/* <img className="visible-xs" src={WishListWhite} /> */}
                                    {/*  <span className="hidden-xs">Added To Whishlist</span> */}
                                  </button>
                                }
                              </div>
                              {(items.cartQuantity == 0 || items.cartQuantity == null || items.cartQuantity == "" || items.cartQuantity == undefined) ?
                                <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 pr-0 pl-2">
                                  <div className="promo-btn-right">
                                    <button className="promo-btn-addtocart" onClick={() => AddItemToCartslice2025(items, index)}>
                                      <img alt="" src="../AddToCartWhite.svg" />
                                      {t("Addtocart.label")}
                                    </button></div>
                                </div> : null}

                              {items.cartQuantity > 0 ?
                                <div className="col-sm-10 col-10 all-product-add-to-cart-button pl-0 ml-1">
                                  <div className="promo-btn-addtocart-add-minus">
                                    <div className="row text-center">
                                      <div className="col-sm-5 col-5 d-flex d-flex justify-content-start">
                                        <img src={minusProdouctButton} onClick={(e) => { updateQuantityslice2025(e, "minus", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                      </div>
                                      <div className="col-sm-2 col-2 p-0 m-0 d-flex justify-content-center">
                                        <h6 className="add-to-chart-count-promotion-card">{items.cartQuantity}</h6>
                                      </div>
                                      {/* <div className="col-sm-2 col-2" >
                                        <img src={addProdouctButton} onClick={(e) => { updateQuantityslice2025(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer" />
                                      </div> */}
                                      <div className="col-sm-5 col-5 d-flex justify-content-end">
                                        <img src={addProdouctButton} onClick={(e) => { updateQuantityslice2025(e, "add", items, index) }} className="all-products-add-minus-cursor-pointer add-minus-icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div> : null}

                            </div>
                          </div>
                        ))}
                    </div>
                  </Carousel.Item>
                )}
            </Carousel>
          </div>
        </div>
        {/* Add-to-cart-section start from here */}
        <div className="add-to-cart-modal-desktop-block">
          {addedToCardModal ? <AddToCartModal visible={addedToCardModal} product={productData} quantity={cartQuantity} priceofproductchosen={productPrice} close={addedToCartModalClose} /> : null}
        </div>
        <div className="common-display-block-mobile">
          {addedToCardModal ? <AddToCartModalMobile visible={addedToCardModal} product={productData} quantity={cartQuantity} priceofproductchosen={productPrice} close={addedToCartModalClose} /> : null}
        </div>
        {/* Add-to-cart-section end */}

        {/* <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored" /> */}
      </div>
    </>
  );
};

export { NewItemCard as NewItemCard };

