import { landingConstants } from "../_constants";
const initReducers = {
  searchWithoutLogin: null,
  searchWithLogin: null,
  menuDetails: null,
  promotionWithLogin: null,
  promotionWithoutLogin: null,
  newItemWithoutLogin: null,
  newItemWithLogin: null,
  promotionData: null,
  newItemData: null,
  allmenurequest:null,
};
export function landing(state = initReducers, action) {
  switch (action.type) {
    case landingConstants.GET_ALL_MENU_REQUEST:
      return {
        ...state,
        allmenurequest: action.data,
        
      };
    // case landingConstants.GET_ALL_MENU_SUCCESS:
    //   return {
    //     ...state,
    //     allmenurequest: action.data,
        
    //   };
    case landingConstants.GET_ALL_MENU_FAIL:
      return {
        ...state,
        allmenurequest: action.data,
        
      };
    case landingConstants.GET_ALL_MENU_NO_DATA_FOUND:
      return {
        ...state,
        allmenurequest: null,
       
      };


    case landingConstants.SEARCH_WITHOUT_LOGIN:
      return {
        ...state,
        searchWithoutLogin: action.data,
      };
    case landingConstants.SEARCH_WITH_LOGIN:
      return {
        ...state,
        searchWithLogin: action.data,
      };
    case landingConstants.SEARCH_WITHOUT_LOGIN_SUCCESS:
      return {
        ...state,
        searchWithoutLogin: action.data,
      };
    case landingConstants.SEARCH_WITHOUT_LOGIN_FAILURE:
      return {
        ...state,
        searchWithoutLogin: action.response,
      };
    case landingConstants.SEARCH_WITHOUT_LOGIN_NO_DATA_FOUND:
      return {
        ...state,
        searchWithoutLogin: "no_data",
      };
    case landingConstants.GET_ALL_MENU_SUCCESS:
      return {
        ...state,
        menuDetails: action.data,

      };
    case landingConstants.PROMOTION_WITH_LOGIN:
      return {
        ...state,
        promotionWithLogin: action.data,
      };
    case landingConstants.PROMOTION_WITH_LOGIN_SUCCESS:
      return {
        ...state,
        promotionData: action.data,
      };
    case landingConstants.PROMOTION_WITHOUT_LOGIN_SUCCESS:
      return {
        ...state,
        promotionData: action.data,
      };
    case landingConstants.PROMOTION_WITHOUT_LOGIN:
      return {
        ...state,
        promotionWithoutLogin: action.data,
      };
    case landingConstants.NEW_ITEM_WITHOUT_LOGIN:
      return {
        ...state,
        newItemData: action.data,
      };
    case landingConstants.NEW_ITEM_WITHOUT_LOGIN_SUCCESS:
      return {
        ...state,
        newItemData: action.data,
      };

    case landingConstants.NEW_ITEM_WITH_LOGIN:
      return {
        ...state,
        newItemData: action.data,
      };
    case landingConstants.NEW_ITEM_WITH_LOGIN_SUCCESS:
      return {
        ...state,
        newItemData: action.data,
      };
    default:
      return state;
  }
}
