import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory, useLocation } from "react-router-dom";
import { Base64 } from '../../_helpers/commanFunction'
import CancelOrderModal from '../../ModalScreens/CancelOrderModal/CancelOrderModal'

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCancell, setOpenCancel] = useState(false);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fulfill = () => {
    handleClose();
  };
  const cancel = () => {
    setOpenCancel(true)
    handleClose();
  };
  const toConfirm = () => {
    setOpenCancel(false)
  }
  const toCancelConfirm = (rowDetails) => {
    props.orderUpdate(rowDetails, "cancelled")
    setOpenCancel(false)
  }

  const routeToOrderDetailsPage = (items, event) => {
    event.stopPropagation()
    history.push("/createOrder/" + Base64.encode(items.orderId.toString()), {orderDetails:items,from:5});
  }
  
  const style = {
    menu: {
      borderRadius: 12
    },
    menuItem: {
      width: 160,
      boxShadow: 'none',
      color: "#989CA6",
      fontSize: 20
    },
    listStyle: {
      backgroundColor: 'red',
      borderRadius: 0,
      boxShadow: 'none'
    },
    innerDivStyle: {
      borderRadius: 0,
      boxShadow: 'none'
    }
  };

  return (
    <>
      <div id="vvvvvv">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{marginTop: 4}}
        >
          <MoreVertIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem key="1" 
          // onClick={fulfill}
          onClick={(event) => routeToOrderDetailsPage(props.row, event)}
          >
            Create Order
          </MenuItem>
          <MenuItem key="2" onClick={cancel}>
            Cancel Draft
          </MenuItem>
        </Menu>
      </div>
      {openCancell && (
        <CancelOrderModal
          row={props.row}
          open={openCancell}
          setOpenCancel={setOpenCancel}
          firstLabel="Cancel Draft?"
          secondLabel="Are you sure you want to cancel this draft?"
          firstBtnLabel="No, keep draft"
          secondBtnLabel="Yes, cancel draft"
          toCancelConfirm={toCancelConfirm}
        />
      )}
    </>
  );
}
