import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AddToCartModal } from "../ModalScreens/AddToCartModal/addToCardModal";
import { landingPageActions } from "../_actions";
import { productActions } from "../_actions/product.actions";
import { landingConstants } from '../_constants';
import { REACT_APP_BASE_URL } from "../constant";
import promoBanner from "../images/PromoIconRed.svg";
import SearchImage from "../images/Search-black.png";
import SelectImage from "../images/all-status-chevron.png";
import AddToCardImage from "../images/cart-shopping-white.png";
import CheckMarkImage from "../images/check.png";
import crossImage from "../images/cross-black.png";
import blankImage from "../images/empty-image.png";
import heartGray from "../images/gray-heart.png";
import minusProdouctButton from "../images/minus-product-button.png";
import NoResultFound from '../images/no-result-found.png';
import addProdouctButton from "../images/plus-product-button.png";
import WishListRed from "../images/white-heart.svg";
import "./SearchBar.css";

const userInformation = JSON.parse(localStorage.getItem('user'))
const SearchBarMobile = (props) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState(false);

  const { t } = useTranslation();
  const [search, setSearch] = React.useState("");
  const [userQuery, setUserQuery] = useState("");
  const [emailErr, setEmailErr] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [userInfo, setUserInfo] = React.useState(userInformation);
  const [loginType, setLogintype] = React.useState("phone");
  const [packValue, setPackValue] = useState([]);
  const [openModalPork, setOpenModalPork] = useState(false);
  const [openModalChicken, setOpenModalChicken] = useState(false);
  const [openModalDuck, setOpenModalDuck] = useState(false);
  const [openModalFish, setOpenModalFish] = useState(false);
  const [openModalEgg, setOpenModalEgg] = useState(false);
  const [openModalProcessedMeat, setOpenModalProcessedMeat] = useState(false);
  const [openModalReadyToEat, setOpenModalReadyToEat] = useState(false);
  const [openModalReadyToCook, setOpenModalReadyToCook] = useState(false);
  const [openModalOthers, setOpenModalOthers] = useState(false);
  const [openModalOffSpec, setOpenModalOffSpec] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const promotionData = useSelector((state) => state.landing);
  const [addedToCardModal, setAddedToCardModal] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(0);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [iscartactive, setIscartactive] = useState(false);
  const [cartupdateQuantity, setCartupdateQuantity] = useState(1);
  const [wishlistStatus, setwishlistStatus] = useState(false);
  const [carttoggleStatus, setCarttoggleStatus] = useState(true);
  const userState = useSelector((state) => state.users);
  const [isLiked, setIsLiked] = useState(false);
  const [totalproductcountvalue, setTotalproductcountvalue] = useState(0);
  let [loadingLoader, setLoadingLoader] = useState(false);
  const [keywordsearch, setKeywordsearch] = React.useState("");
  // const { t } = useTranslation();

  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.landing);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const lancode = localStorage.getItem("lancode");
  const userData = JSON.parse(localStorage.getItem("user"));
  const [updated, setUpdated] = useState("");
  const [selectedDropdown, setSelectedDropdown] = useState([])
  const sendQuery = query => { searchItem(query) };
  const delayedQuery = useCallback(_.debounce(q => sendQuery(q), 500), []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setUpdated(keywordsearch);
      setOverlayOpen(false);
      setOpenModalPork(false);
      setOpenModalChicken(false);
      setOpenModalDuck(false);
      setOpenModalFish(false);
      setOpenModalEgg(false);
      setOpenModalProcessedMeat(false);
      setOpenModalReadyToEat(false);
      setOpenModalReadyToCook(false);
      setOpenModalOthers(false);
      setOpenModalOffSpec(false);
      history.push("/viewallsearchresults", {
        totalproductcountvalue: totalproductcountvalue,
        keywordsearch: keywordsearch
      });
      props.openSearchPopUpClosed()                        // history.push("/productdetailscustomer")                  
    } else {
      setUserQuery(e.target.value);
      delayedQuery(e.target.value);
      // props.openSearchPopUpClosed()  
    }
  };

  // Handling pack size dropdown value
  const _handleSelectedValue = (item) => {
    if (lancode === "en") {
      const selectedValueEnglish = item?.selectValues?.filter((data, i) => {
        return data.articaleCode == item.articaleCode
      })[0]?.valueEN;
      return selectedValueEnglish.length > 12 ? selectedValueEnglish.substring(0, 12) + ".." : selectedValueEnglish
    } else {
      const selectedValueThai =
        item?.selectValues?.filter((data, i) => {
          return data.articaleCode == item.articaleCode
        })[0]?.valueTH
      return selectedValueThai.length > 12 ? selectedValueThai.substring(0, 12) + ".." : selectedValueThai
    }
  }

  const isNull = (value) => {
    return value == "" || value == undefined || value == null || value == "no_data"
  }
  function noDataFound(data) {
    return { type: landingConstants.SEARCH_WITHOUT_LOGIN_NO_DATA_FOUND, data };
  }
  // const userData = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('user')))

    if (isNull(userQuery)) {
      dispatch(noDataFound());
      setProducts(null)
    }

    if (!isNull(searchState.searchWithoutLogin)) {
      setLoadingLoader(false)
      setTotalproductcountvalue(searchState.searchWithoutLogin.data.totalCount);
      let tempepory = searchState.searchWithoutLogin.data
      tempepory.result.map((item, index) => {
        if (isNull(item.productPrice)) {
          // if (isNull(item.cartQuantity)) {
          //   if (isNull(item.conditionAmount)) {
          //     item.productPrice = item.price1Scale1
          //   } else {
          //     item.productPrice = item.conditionAmount
          //   }
          //   return
          // }
          // let count = item.cartQuantity
          let count = isNull(item.cartQuantity) ? 1 : item.cartQuantity
          let packSize = 1
          if (item.packSize) {
            packSize = parseFloat(item.packSize);
          }
          let price = item.productPrice;
          if (isNull(item.conditionAmount)) {
            if (Number(item.quantity1Scale1) <= (count * packSize) && Number(item.quantity1Scale1) != 0) {
              price = Number(item.price1Scale1)
            }
            if (Number(item.quantity2Scale2) <= (count * packSize) && Number(item.quantity2Scale2) != 0) {
              price = Number(item.price2Scale2)
            }
            if (Number(item.quantity3Scale3) <= (count * packSize) && Number(item.quantity3Scale3) != 0) {
              price = Number(item.price3Scale3)
            }
            if (Number(item.quantity4Scale4) <= (count * packSize) && Number(item.quantity4Scale4) != 0) {
              price = Number(item.price4Scale4)
            }
            if (Number(item.quantity5Scale5) <= (count * packSize) && Number(item.quantity5Scale5) != 0) {
              price = Number(item.price5Scale5)
            }
            item.productPrice = price
          } else {
            item.productPrice = item.conditionAmount
          }

        }
      })
      setProducts(tempepory);
    } else if (searchState.searchWithoutLogin == "no_data") {
      setLoadingLoader(false)
      setProducts(null);
    } else {
      setProducts(null);
    }
  }, [searchState.searchWithoutLogin, products, userState, isLiked, iscartactive, cartQuantity, cartupdateQuantity])


  const searchItem = (query) => {
    setUserInfo(JSON.parse(localStorage.getItem('user')))
    var userData = JSON.parse(localStorage.getItem('user'))
    var storeCode = !isNull(userData) ? userData.user.stores[0].storeCode : "";
    var token = !isNull(userData) ? userData.token : "";
    const payload = !isNull(storeCode) ? { "searchItem": query, "storeCode": storeCode, token: token } : { "searchItem": query }
    if (query.length !== 0 && query.length > 2) {
      setLoadingLoader(true)
      try {
        dispatch(landingPageActions.searchWithoutLogin("search", payload));
        localStorage.setItem('keywordsearch', query);
        setKeywordsearch(query);
      } catch (error) {
        setLoadingLoader(false)
      }
    } else {
      setLoadingLoader(false)
      setProducts(null)
    }
  }




  const handleViewAllProduct = () => {
    props.openSearchPopUpClosed();
    history.push("/viewallsearchresults");
  }
  const clearInput = () => {
    setProducts(null)
    setUserQuery("")
  }

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
      <span className="search-bar-desktop-product-name">
        {parts.filter(part => part).map((part, i) => (
          regex.test(part) ? <mark key={i} className="search-bar-highlishted-text">{part}</mark> : <span key={i}>{part}</span>
        ))}
      </span>
    )
  }


  const AddItemToCart = async (items, index) => {
    let temp = products;
    let count = isNull(temp.result[index].cartQuantity) ? 0 : Number(temp.result[index].cartQuantity)
    let price = productPrice;
    let packSize = 1;
    if (temp.result[index].packSize) {
      packSize = parseFloat(temp.result[index].packSize);
    }
    count = count + 1
    count >= 1 && setCartQuantity(count)
    if (isNull(temp.result[index].conditionAmount)) {
      if (Number(temp.result[index].quantity1Scale1) <= (count * packSize) && Number(temp.result[index].quantity1Scale1) != 0) {
        price = Number(temp.result[index].price1Scale1)
      }
      if (Number(temp.result[index].quantity2Scale2) <= (count * packSize) && Number(temp.result[index].quantity2Scale2) != 0) {
        price = Number(temp.result[index].price2Scale2)
      }
      if (Number(temp.result[index].quantity3Scale3) <= (count * packSize) && Number(temp.result[index].quantity3Scale3) != 0) {
        price = Number(temp.result[index].price3Scale3)
      }
      if (Number(temp.result[index].quantity4Scale4) <= (count * packSize) && Number(temp.result[index].quantity4Scale4) != 0) {
        price = Number(temp.result[index].price4Scale4)
      }
      if (Number(temp.result[index].quantity5Scale5) <= (count * packSize) && Number(temp.result[index].quantity5Scale5) != 0) {
        price = Number(temp.result[index].price5Scale5)
      }
      temp.result[index].cartQuantity = count
      temp.result[index].productPrice = price
      setProductPrice(price)
      setProducts({ ...temp })
    } else {
      temp.result[index].cartQuantity = count
      temp.result[index].productPrice = temp.result[index].conditionAmount
      setProductPrice(temp.result[index].conditionAmount)
      setProducts({ ...temp })
    }

    if (!isNull(userData)) {
      let payload = {
        "articaleCode": items.articaleCode,
        "storeCode": items.storeCode,
        "cartQuantity": cartQuantity
      }
      let updatePD = [];
      let x = temp.result[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      items.cartQuantity = count;
      setProducts({ ...temp, selectValues: updatePD });
      dispatch(productActions.addProductToCart("payload", payload))
      dispatch(productActions.getCartData("payload", payload))

    } else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
    setProductData(items)

  }
  const isNullorZero = (value) => {
    return value == "" || value == undefined || value == null || value == false || value == 0;
  };


  const AddItemToWishList = async (status, items, index) => {
    let temp = products
    if (!isNull(userData)) {
      let payload = {
        "articaleCode": items.articaleCode,
        "wishlistStatus": status,
        "storeCode": items.storeCode,
      }

      let updatePD = [];
      let x = temp.result[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === items.articaleCode) {
          i.wishlistStatus = status
        }
        updatePD.push(i);
      }
      items.wishlistStatus = status;
      setProducts({ ...temp, selectValues: updatePD });
      dispatch(productActions.addProductToWishList("payload", payload))
      dispatch(productActions.getWishListInformation("payload", {}, `?limit=100000&skip=0`));

      let payload2 = {
        storeCode: items.articaleCode,
        articleCode: items.storeCode
      }
      dispatch(productActions.getProductDetails("payload", payload2));
      setwishlistStatus(!wishlistStatus)
      handleIsLiked(wishlistStatus)
    } else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }

  }

  const routeToProductDetailsPage = (items) => {
    if (!isNull(userData)) {
      history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
      window.location.reload();
      props.openSearchPopUpClosed();
    }
    else {
      if (location.pathname == "/login") {
        props.openSearchPopUpClosed()
      }
      history.push("/login")
    }
  }

  // Handling added to cart modal close
  const addedToCartModalClose = () => {
    setAddedToCardModal(false);
    setCarttoggleStatus(carttoggleStatus)
  }

  const updateQuantity = async (e, type, itemDetails, index) => {
    e.preventDefault()
    let temp = products;
    let count = Number(temp.result[index].cartQuantity)
    let price = productPrice;
    let packSize = 1;
    if (temp.result[index].packSize) {
      packSize = parseFloat(temp.result[index].packSize);
    }
    count = type == "add" ? count + 1 : count > 0 ? count - 1 : count
    count >= 1 && setCartQuantity(count)
    if (isNull(temp.result[index].conditionAmount)) {
      if (Number(temp.result[index].quantity1Scale1) <= (count * packSize) && Number(temp.result[index].quantity1Scale1) != 0) {
        price = Number(temp.result[index].price1Scale1)
      }
      if (Number(temp.result[index].quantity2Scale2) <= (count * packSize) && Number(temp.result[index].quantity2Scale2) != 0) {
        price = Number(temp.result[index].price2Scale2)
      }
      if (Number(temp.result[index].quantity3Scale3) <= (count * packSize) && Number(temp.result[index].quantity3Scale3) != 0) {
        price = Number(temp.result[index].price3Scale3)
      }
      if (Number(temp.result[index].quantity4Scale4) <= (count * packSize) && Number(temp.result[index].quantity4Scale4) != 0) {
        price = Number(temp.result[index].price4Scale4)
      }
      if (Number(temp.result[index].quantity5Scale5) <= (count * packSize) && Number(temp.result[index].quantity5Scale5) != 0) {
        price = Number(temp.result[index].price5Scale5)
      }
      temp.result[index].cartQuantity = count
      temp.result[index].productPrice = price
      setProductPrice(price)
      setProducts({ ...temp })

    } else {
      temp.result[index].cartQuantity = count
      temp.result[index].productPrice = temp.result[index].conditionAmount
      setProductPrice(temp.result[index].conditionAmount)
      setProducts({ ...temp })
    }
    if ((count == 1 || count == 0) && (type == "minus")) {
      setLoading(true)
      temp.result[index].cartQuantity = count
      // setProducts({ ...temp })
      let updatePD = [];
      let x = temp.result[index].selectValues
      for await (const i of x) {
        if (i.articaleCode === itemDetails.articaleCode) {
          i.cartQuantity = count
        }
        updatePD.push(i);
      }
      itemDetails.cartQuantity = count;
      setProducts({ ...temp, selectValues: updatePD });
      const payload = {
        cartId: itemDetails.cartId
      }
      dispatch(productActions.removeCartAction(payload))
      dispatch(productActions.getCartData("payload", payload));
    } else {
      if (count) {
        setLoading(true)
        let payload = {
          "articaleCode": itemDetails.articaleCode,
          "storeCode": itemDetails.storeCode,
          "cartQuantity": count
        }
        let updatePD = [];
        let x = temp.result[index].selectValues
        for await (const i of x) {
          if (i.articaleCode === itemDetails.articaleCode) {
            i.cartQuantity = count
          }
          updatePD.push(i);
        }
        itemDetails.cartQuantity = count;
        setProducts({ ...temp, selectValues: updatePD });
        dispatch(productActions.addProductToCart("payload", payload))
        dispatch(productActions.getCartData("payload", payload));

      }
    }
  }

  const handleIsLiked = (value) => {
    setIsLiked(value)
  }

  const handlecarttoggle = (value) => {
    setIscartactive(value)

  }

  const handleDropdown = (item, index, op) => {
    let temp2 = products;
    if (op == "open") {
      temp2.result[index].openDropDown = true;
    } else {
      temp2.result[index].openDropDown = false
      let selectedItem = item;
      temp2.result[index].picture = selectedItem.picture
      temp2.result[index].typeCode = selectedItem.typeCode
      temp2.result[index].price1Scale1 = selectedItem.price1Scale1
      temp2.result[index].articaleCode = selectedItem.articaleCode
      temp2.result[index].cartQuantity = selectedItem.cartQuantity
      temp2.result[index].wishlistStatus = selectedItem.wishlistStatus
      temp2.result[index].stock = selectedItem.stock
      temp2.result[index].productPrice = isNull(selectedItem.conditionAmount) ? selectedItem.price1Scale1 : selectedItem.conditionAmount
      temp2.result[index].conditionAmount = selectedItem.conditionAmount
      temp2.result[index].consumerNameEN = selectedItem.consumerNameEN
      temp2.result[index].packSizeEN = selectedItem.packSizeEN
      temp2.result[index].productTypeEN = selectedItem.productTypeEN
      temp2.result[index].aboutProductEN = selectedItem.aboutProductEN
      temp2.result[index].shelfLifeEN = selectedItem.shelfLifeEN
      temp2.result[index].otherProductEN = selectedItem.otherProductEN
      temp2.result[index].consumerNameTH = selectedItem.consumerNameTH
      temp2.result[index].packSizeTH = selectedItem.packSizeTH
      temp2.result[index].productTypeTH = selectedItem.productTypeTH
      temp2.result[index].aboutProductTH = selectedItem.aboutProductTH
      temp2.result[index].instructionTH = selectedItem.instructionTH
      temp2.result[index].shelfLifeTH = selectedItem.shelfLifeTH
      temp2.result[index].otherProductTH = selectedItem.otherProductTH

      temp2.result[index].selectValues.map((val) => {
        if (val.articaleCode == selectedItem.articaleCode) {
          val.select = true
        } else {
          val.select = false
        }
        val.openDropDown = false
        return val
      })
    }
    setProducts({ ...temp2 })
  }

  return <>
    {/* Search-bar-desktop-section start from here  */}
    <div className="search-bar-desktop-main-container common-display-none-mobile">
      <div className="search-bar-desktop-inner-container">
        <div className="search-bar-desktop-inner-container">
          <div className="search-bar-desktop-custom-container">
            <div className="row">
              <div className="col-xll-11 col-xl-11 col-md-11 col-sm-11 col-11">
                <div className="search-bar-mobile-inner">
                  <input
                    type="text"
                    className="search-bar-desktop-search-input"
                    value={userQuery}
                    placeholder={t("Search.label")}
                    onChange={(e) => {
                      setLoadingLoader(true)
                      setProducts(null)
                      setUserQuery(e.target.value);
                      delayedQuery(e.target.value);

                    }}
                    onKeyUp={(e) => {
                      setLoadingLoader(true)

                      // setProducts(null)
                      // setUserQuery(e.target.value);
                      // delayedQuery(e.target.value);
                      handleKeyDown(e);

                    }}
                    autoFocus={true}
                    data-testid="searchbar-input-loading"
                  />
                  <img src={SearchImage} className="search-bar-desktop-search-input-image" data-testid="searchbar-image-loading" />
                  {!isNull(userQuery) && <img src={crossImage} className="search-bar-desktop-search-cross-image" onClick={() => clearInput()} data-testid="searchbar-cross-image-loading" />}
                </div>
                {isNull(products) && (userQuery) && <>
                  <div className={"text-center"}>
                    <RotatingLines
                      strokeColor="#61A60E"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="96"
                      visible={loadingLoader}
                    />
                  </div>
                  {!loadingLoader &&
                    <div className="row">
                      <h6 className="all-product-inner-section-heading">{userQuery}</h6>
                      <h6 className="all-product-inner-section-inner-heading">{t("0productsfound.label")}</h6>
                      <div className="no-result-found-main-container">
                        <img src={NoResultFound} className="no-result-found-image" />
                        <h6 className="no-result-found-image-main-heading">{t("NoSearchResultFound.label")}</h6>
                      </div>
                    </div>
                  }

                  {!loadingLoader && <h6 className="search-bar-no-result-found-heading">{t("NoSearchResultFound.label")}</h6>}
                </>
                }
                {products && products.result && products.result.slice(0, 10).map((item, index) => {
                  return <>
                    <div className="row search-bar-desktop-product-main-container">
                      <div className="col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4">
                        <div className="d-flex flex-row align-items-center">
                          <div className="search-bar-desktop-image-border">
                            {products && item.typeCode === "2" && (
                              <img alt="" src={promoBanner} className="search-bar-promo-banner" />
                            )}
                            {products && item.typeCode === "3" && (
                              <img alt="" src='../NewItemYellow.svg' className="search-bar-promo-banner" />
                            )}
                            {/* </div> */}
                            <img
                              src={
                                REACT_APP_BASE_URL +
                                  "/api/download?" +
                                  item.picture.split(",")[0]
                                  ? REACT_APP_BASE_URL +
                                  "/api/download?" +
                                  item.picture.split(",")[0]
                                  : blankImage}
                              className="search-bar-desktop-image-border-image"
                              onClick={() => routeToProductDetailsPage(item)}
                              title=" Click to Product Details"
                              data-testid="searchbar-product-image"
                            />
                          </div>
                          <div className="search-bar-desktop-article-code-main-container">

                            {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && <h6 className="search-bar-desktop-article-code"><Highlighted text={item.articaleCode} highlight={userQuery} /></h6>}
                            <span className="search-bar-desktop-product-name" onClick={() => routeToProductDetailsPage(item)} ><Highlighted text={lancode === "en" ? item.consumerNameEN : item.consumerNameTH} highlight={userQuery} /></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2">
                        {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                          <div>
                            {(!isNull(item.stock)
                              ?
                              <div className="search-bar-desktop-in-stock-main-container">
                                <h6 className="search-bar-desktop-in-stock-heading">{item.stock + " " + t("inStock.label")}</h6></div>
                              :
                              <div className="search-bar-desktop-out-of-stock-main-container">
                                <h6 className="search-bar-desktop-out-of-stock-heading">{t("OutofStock.label")}</h6></div>
                            )}
                          </div>}
                      </div>
                      <div className="col-xll-1 col-xl-1 col-md-1 col-sm-1 col-1 p-0">
                        {!isNull(userData) && <h6 className="search-bar-desktop-price-heading">{"฿ " + item.productPrice + ".00"}</h6>}
                      </div>
                      <div className="col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2 search-bar-relative-position">
                        {!isNull(userData) && <>{item.selectValues.length > 1 ?
                          <div className="promo-desc mt-3">
                            <select
                              onChange={(e) => {
                                // let temp = products.result[index].selectValues
                                let selectedItem = item.selectValues.find(f => f.articaleCode === e.target.value);
                                let temp2 = products
                                temp2.result[index].picture = selectedItem.picture
                                temp2.result[index].typeCode = selectedItem.typeCode
                                temp2.result[index].price1Scale1 = selectedItem.price1Scale1
                                temp2.result[index].articaleCode = selectedItem.articaleCode
                                temp2.result[index].cartQuantity = selectedItem.cartQuantity
                                temp2.result[index].wishlistStatus = selectedItem.wishlistStatus
                                temp2.result[index].stock = selectedItem.stock
                                temp2.result[index].cartId = selectedItem.cartId
                                temp2.result[index].productPrice = isNull(selectedItem.conditionAmount) ? selectedItem.price1Scale1 : selectedItem.conditionAmount;
                                temp2.result[index].conditionAmount = selectedItem.conditionAmount
                                temp2.result[index].consumerNameEN = selectedItem.consumerNameEN
                                temp2.result[index].packSizeEN = selectedItem.packSizeEN
                                temp2.result[index].productTypeEN = selectedItem.productTypeEN
                                temp2.result[index].aboutProductEN = selectedItem.aboutProductEN
                                temp2.result[index].shelfLifeEN = selectedItem.shelfLifeEN
                                temp2.result[index].otherProductEN = selectedItem.otherProductEN
                                temp2.result[index].consumerNameTH = selectedItem.consumerNameTH
                                temp2.result[index].packSizeTH = selectedItem.packSizeTH
                                temp2.result[index].productTypeTH = selectedItem.productTypeTH
                                temp2.result[index].aboutProductTH = selectedItem.aboutProductTH
                                temp2.result[index].instructionTH = selectedItem.instructionTH
                                temp2.result[index].shelfLifeTH = selectedItem.shelfLifeTH
                                temp2.result[index].otherProductTH = selectedItem.otherProductTH
                                temp2.result[index].packSize = selectedItem.packSize
                                let count = isNullorZero(temp2.result[index].cartQuantity) ? 1 : Number(temp2.result[index].cartQuantity)
                                let packSize = 1;
                                if (temp2.result[index].packSize) {
                                  packSize = parseFloat(temp2.result[index].packSize);
                                }
                                let price = isNullorZero(temp2.result[index].productPrice) ? 0 : temp2.result[index].productPrice;
                                if (isNull(temp2.result[index].conditionAmount)) {
                                  if (
                                    Number(temp2.result[index].quantity1Scale1) <= (count * packSize) &&
                                    Number(temp2.result[index].quantity1Scale1) != 0
                                  ) {
                                    price = Number(temp2.result[index].price1Scale1);
                                  }
                                  if (
                                    Number(temp2.result[index].quantity2Scale2) <= (count * packSize) &&
                                    Number(temp2.result[index].quantity2Scale2) != 0
                                  ) {
                                    price = Number(temp2.result[index].price2Scale2);
                                  }
                                  if (
                                    Number(temp2.result[index].quantity3Scale3) <= (count * packSize) &&
                                    Number(temp2.result[index].quantity3Scale3) != 0
                                  ) {
                                    price = Number(temp2.result[index].price3Scale3);
                                  }
                                  if (
                                    Number(temp2.result[index].quantity4Scale4) <= (count * packSize) &&
                                    Number(temp2.result[index].quantity4Scale4) != 0
                                  ) {
                                    price = Number(temp2.result[index].price4Scale4);
                                  }
                                  if (
                                    Number(temp2.result[index].quantity5Scale5) <= (count * packSize) &&
                                    Number(temp2.result[index].quantity5Scale5) != 0
                                  ) {
                                    price = Number(temp2.result[index].price5Scale5);
                                  }
                                  setProductPrice(price);
                                }
                                temp2.result[index].productPrice = price;
                                setProducts({ ...temp2 })
                              }}
                            >
                              {
                                item.selectValues.map((i, ind) => {
                                  // let selected = (i.select) ? 'selected' : '';
                                  return (<option selected={i.articaleCode === item
                                    .articaleCode} value={i.articaleCode} key={ind}>{lancode === "en" ? i.valueEN : i.valueTH}</option>);
                                })
                              }
                            </select>
                          </div>
                          : lancode === "en" ? <h3>{item.selectValues[0].valueEN}</h3> : <h3> {item.selectValues[0].valueTH}</h3>}


                        </>}
                      </div>
                      <div className="col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3">
                        <div className="d-flex flex-row">
                          {item && !item.wishlistStatus &&
                            <div className="search-bar-desktop-wishlist-border" onClick={() => AddItemToWishList(true, item, index)}>
                              <img src={heartGray} className="search-bar-desktop-heart-gray" />
                            </div>
                          }
                          {item && item.wishlistStatus &&
                            <div className="search-bar-desktop-wishlist-border-active" onClick={() => AddItemToWishList(false, item, index)}>
                              <img src={WishListRed} className="search-bar-desktop-heart-gray" />
                            </div>
                          }
                          {(isNullorZero(item.cartQuantity)) ?
                            <div className="search-bar-desktop-add-to-cart-button" onClick={() => { AddItemToCart(item, index) }}>
                              <img src={AddToCardImage} className="search-bar-desktop-add-to-cart-main-container" />
                              <span className="search-bar-desktop-add-to-cart-heading">{t("Addtocart.label")}</span>
                            </div>
                            : null
                          }
                          {item.cartQuantity >= 1 ?
                            <div className="search-bar-desktop-add-to-cart-button-main-container d-flex flex-row justify-content-between">
                              <img src={minusProdouctButton} className="search-bar-desktop-add-button" onClick={(e) => { updateQuantity(e, "minus", item, index) }} />
                              <h6 className="search-bar-desktop-heading">{item.cartQuantity}</h6>
                              <img src={addProdouctButton} className="search-bar-desktop-minus-button" onClick={(e) => { updateQuantity(e, "add", item, index) }} />
                            </div>
                            : null}
                        </div>
                      </div>
                      <div className="search-bar-desktop-gray-line" />
                    </div>
                  </>
                })}

                <div className="search-bar-view-all-search-result-main-container">
                  {products && products?.totalCount > 10 && <h6
                    onClick={() => {
                      setOverlayOpen(false),
                        setOpenModalPork(false),
                        setOpenModalChicken(false),
                        setOpenModalDuck(false),
                        setOpenModalFish(false),
                        setOpenModalEgg(false),
                        setOpenModalProcessedMeat(false),
                        setOpenModalReadyToEat(false),
                        setOpenModalReadyToCook(false),
                        setOpenModalOthers(false),
                        setOpenModalOffSpec(false)
                      history.push("/viewallsearchresults", {
                        totalproductcountvalue: totalproductcountvalue,
                        keywordsearch: keywordsearch
                      });
                      props.openSearchPopUpClosed()
                    }}
                    className="search-bar-view-all-search-result-heading">{`${t("viewallsearchresults.label") + " (" + totalproductcountvalue})`}</h6>}
                  {/* </Link> */}
                </div>
              </div>
              <div className="col-xll-1 col-xl-1 col-md-1 col-sm-1 col-1 pr-0">
                <h6 className="search-bar-desktop-search-cancel-heading" onClick={() => {
                  props.openSearchPopUpClosed()
                }}>{t("Cancel.label")}</h6>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    {/* Search-bar-desktop-section end */}
    {/* Search-bar-mobile-section start from here  */}
    <div className='search-bar-mobile-main-container my-order-display-block-mobile'>
      <div className='search-bar-mobile-inner-container'>
        <div className='search-bar-mobile-custom-container'>
          <div className="row">
            <div className="col-xll-10 col-xl-10 col-md-10 col-sm-10 col-10">
              <div className="search-bar-mobile-inner">
                <input
                  type="text"
                  className="search-bar-mobile-search-input"
                  value={userQuery}
                  placeholder={t("Search.label")}
                  onChange={(e) => {
                    setLoadingLoader(true)
                    setProducts(null)
                    setUserQuery(e.target.value);
                    delayedQuery(e.target.value);
                  }}
                  // onKeyDown={(e) => {
                  //   setLoadingLoader(true)
                  //   setProducts(null)
                  //   setUserQuery(e.target.value);
                  //   delayedQuery(e.target.value);
                  //   handleKeyDown(e);
                  // }}
                  onKeyUp={(e) => {
                    setLoadingLoader(true)
                    handleKeyDown(e);

                  }}
                  autoFocus={true}
                />
                <img src={SearchImage} className="search-bar-mobile-search-input-image" />
                {!isNull(userQuery) && <img src={crossImage} className="search-bar-mobile-search-cross-image" onClick={() => clearInput()} />}
              </div>
            </div>
            <div className="col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2">
              <h6 className="search-bar-mobile-search-cancel-heading" onClick={() => props.openSearchPopUpClosed()}>{t("Cancel.label")}</h6>
            </div>
          </div>
          {isNull(products) && (userQuery) && <>
            <div className={"text-center"}>
              <RotatingLines
                strokeColor="#61A60E"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={loadingLoader}
              />
            </div>
            {!loadingLoader &&
              <div className="row">
                <h6 className="all-product-inner-section-heading">{userQuery}</h6>
                <h6 className="all-product-inner-section-inner-heading">{t("0productsfound.label")}</h6>
                <div className="no-result-found-main-container">
                  <img src={NoResultFound} className="no-result-found-image" />
                  <h6 className="no-result-found-image-main-heading">{t("NoSearchResultFound.label")}</h6>
                </div>
              </div>
            }
            {!loadingLoader && <h6 className="search-bar-no-result-found-heading">{t("NoSearchResultFound.label")}</h6>}
          </>
          }

          {products && products.result && products.result.slice(0, 10).map((item, index) => {
            return <>
              <div className="row">
                <div className="col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 mt-20">
                  <div className="search-bar-mobile-image-border">
                    {products && item.typeCode === "2" && (
                      <img alt="" src={promoBanner} className="search-bar-mobile-promo-banner" />
                    )}
                    {products && item.typeCode === "3" && (
                      <img alt="" src='../NewItemYellow.svg' className="search-bar-mobile-promo-banner" />
                    )}
                    {/* </div> */}
                    <img
                      src={
                        REACT_APP_BASE_URL +
                          "/api/download?" +
                          item.picture.split(",")[0]
                          ? REACT_APP_BASE_URL +
                          "/api/download?" +
                          item.picture.split(",")[0]
                          : blankImage}
                      className="search-bar-mobile-image-border-image"
                      onClick={() => routeToProductDetailsPage(item)}
                      title=" Click to Product Details"
                    />
                  </div>
                </div>
                <div className="col-xll-9 col-xl-9 col-md-9 col-sm-9 col-9 mt-20">
                  <div className="row">
                    <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12">
                      {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && <h6 className="search-bar-mobile-article-code"><Highlighted text={item.articaleCode} highlight={userQuery} /></h6>}
                      {/* <h6 className="search-bar-mobile-article-code">1000007160</h6> */}
                    </div>
                    <div className={!isNull(userData) ? "col-xll-8 col-xl-8 col-md-8 col-sm-8 col-8" : "col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12"}>

                      <h6 onClick={() => routeToProductDetailsPage(item)} className="search-bar-right-product-name"><Highlighted text={lancode === "en" ? item.consumerNameEN.length > 20 ? item.consumerNameEN.substring(0, 20) +
                        "..." : item.consumerNameEN : item.consumerNameTH.length > 20 ? item.consumerNameTH.substring(0, 20) +
                          "..." : item.consumerNameTH} highlight={userQuery} /></h6>
                    </div>
                    <div className={!isNull(userData) ? "col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pl-0" : "col-xll-0 col-xl-0 col-md-0 col-sm-0 col-0 pl-0"}>
                      {!isNull(userData) && <h6 className="search-bar-right-product-price">{"฿ " + item.productPrice + ".00"}</h6>}
                      {/* <h6 className="search-bar-right-product-price">฿ 200.00</h6> */}
                    </div>
                    <div className="col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2">
                      {item && !item.wishlistStatus &&
                        <div className="search-bar-mobile-wishlist-border">
                          <img src={heartGray} className="search-bar-mobile-heart-gray" onClick={() => AddItemToWishList(true, item, index)} />
                        </div>

                      }
                      {item && item.wishlistStatus &&
                        <div className="search-bar-mobile-wishlist-border-active">
                          <img src={WishListRed} className="search-bar-mobile-heart-gray" onClick={() => AddItemToWishList(false, item, index)} />
                        </div>
                      }
                    </div>
                    {!isNull(userData) ?
                      <div className="col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 pl-2 pr-2">
                        {!isNull(userData) && <>
                          <div className="search-bar-mobile-select-main-container align-items-center" onClick={() => { handleDropdown(item, index, "open") }}>
                            <h6 className="search-bar-mobile-select-heading">{_handleSelectedValue(item)}</h6>
                            <img src={SelectImage} className="search-bar-mobile-select-image" />
                          </div>
                          {!isNull(item.openDropDown) && item.openDropDown ?
                            <div className='order-all-status-section-modal'>
                              <div className='order-all-status-inner-section-modal'>
                                <div className='order-all-status-custom-section-modal'>
                                  <div className='order-all-status-gray-line' />
                                  <div className="order-all-status-inner-custom-modal">
                                    <Dropdown>
                                      {
                                        item?.selectValues?.map((i, ind) => {
                                          return <Dropdown.Item
                                            className={i.articaleCode == item.articaleCode ? "order-all-status-inner-custom-heading-active" : "order-all-status-inner-custom-heading-disable"}
                                            onClick={() => handleDropdown(i, index, "close")}
                                          >
                                            {lancode === "en" ? i.valueEN : i.valueTH}
                                            {i.articaleCode == item.articaleCode && <span>
                                              <img src={CheckMarkImage} className='order-all-status-inner-custom-checkmark-image' />
                                            </span>
                                            }
                                          </Dropdown.Item>
                                        })
                                      }
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div> : null
                          }
                        </>}


                      </div> : null}
                    <div className={!isNull(userData) ? "col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 pl-1" : "col-xll-10 col-xl-10 col-md-10 col-sm-10 col-10 pl-1"}>
                      {(isNullorZero(item.cartQuantity)) ?
                        <div className="search-bar-mobile-add-to-cart-button-main-container d-flex flex-row" onClick={() => { AddItemToCart(item, index) }}>
                          <img src={AddToCardImage} className="search-bar-mobile-add-to-cart-image" />
                          <h6 className="search-bar-mobile-add-to-cart-heading">{t("Addtocart.label")}</h6>
                        </div> : null}


                      {item.cartQuantity >= 1 ?
                        //  <div className="col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 pl-1">
                        <div className="search-bar-mobile-add-to-cart-button-main-container d-flex flex-row justify-content-between">
                          <img src={minusProdouctButton} className="search-bar-mobile-add-button" onClick={(e) => { updateQuantity(e, "minus", item, index) }} />
                          <h6 className="search-bar-mobile-heading">{item.cartQuantity}</h6>
                          <img src={addProdouctButton} className="search-bar-mobile-minus-button" onClick={(e) => { updateQuantity(e, "add", item, index) }} />
                        </div>
                        // </div>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-bar-gray-line" />
            </>
          })}
          <div className="search-bar-view-all-search-result-main-container">
            {products && products?.totalCount > 10 && <h6
              onClick={() => {
                setOverlayOpen(false),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false)
                history.push("/viewallsearchresults", {
                  totalproductcountvalue: totalproductcountvalue,
                  keywordsearch: keywordsearch
                });
                props.openSearchPopUpClosed()
              }}
              className="search-bar-view-all-search-result-heading">{`${t("viewallsearchresults.label") + " (" + totalproductcountvalue})`}</h6>}

          </div>
        </div>
        {/* <div className="col-xll-1 col-xl-1 col-md-1 col-sm-1 col-1 pr-0">
          <h6 className="search-bar-desktop-search-cancel-heading" onClick={() => {

            props.openSearchPopUpClosed()
          }}>{t("Cancel.label")}</h6>
        </div> */}
      </div>
    </div>
    {/* Search-bar-mobile-section end*/}
    {/* Search-bar-mobile-dropdown-section start from here */}
    {addedToCardModal ? <AddToCartModal visible={addedToCardModal} product={productData} quantity={cartQuantity} priceofproductchosen={productPrice} close={addedToCartModalClose} /> : null}

    {/* Search-bar-mobile-dropdown-section end */}
  </>
}

export default SearchBarMobile;