import { postcodeConstants } from "../_constants";
const initReducers = {
  getcustomerpostalcode: null,
  getcustomerpostalcodeError:null

};
export function postcode(state = initReducers, action) {
  switch (action.type) {
    case postcodeConstants.GET_CUSTOMER_POSTCODE_ALL:
      return {
        ...state,
        getcustomerpostalcode: action.data,
        getcustomerpostalcodeError: action.error,
      };
    case postcodeConstants.GET_CUSTOMER_POSTCODE_ALL_SUCCESS:
      return {
        ...state,
        getcustomerpostalcode: action.data,
        getcustomerpostalcodeError: action.error,
      };
    case postcodeConstants.GET_CUSTOMER_POSTCODE_ALL_NODATA:
      return {
        ...state,
        getcustomerpostalcode: action.data,
        getcustomerpostalcodeError: action.error,
      };
    case postcodeConstants.GET_CUSTOMER_POSTCODE_ALL_FAILUR:
      return {
        ...state,
        getcustomerpostalcode: action.data,
        getcustomerpostalcodeError: action.error,
      };
    
    
    default:
      return state;
  }
}
