import React, { useState } from "react";
import "./OrderListHead.css";

const OrderListHead = () => {
  const [show, setShow] = useState(false);


  return (
    <>
      <div className="OrderListHead">
        <h1>Order List</h1>
        {/* <button>
          <span>&#x2b;</span>&nbsp;&nbsp;Confirm new order
        </button> */}

      </div>
    </>
  );
};
export { OrderListHead as OrderListHead };
