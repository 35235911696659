export const createOrderConstant = {
    ORDER_PRODUCT_DETAILS_REQUEST: 'ORDER_PRODUCT_DETAILS_REQUEST',
    ORDER_PRODUCT_DETAILS_SUCCESS: 'ORDER_PRODUCT_DETAILS_SUCCESS',
    ORDER_PRODUCT_DETAILS_NODATA: 'ORDER_PRODUCT_DETAILS_NODATA',
    ORDER_PRODUCT_DETAILS_FAILURE: 'ORDER_PRODUCT_DETAILS_FAILURE',
    ORDER_PRODUCT_QTY_UPDATE_REQUEST: 'ORDER_PRODUCT_QTY_UPDATE_REQUEST',
    ORDER_PRODUCT_QTY_UPDATE_SUCCESS: 'ORDER_PRODUCT_QTY_UPDATE_SUCCESS',
    ORDER_PRODUCT_QTY_UPDATE_NODATA: 'ORDER_PRODUCT_QTY_UPDATE_NODATA',
    ORDER_PRODUCT_QTY_UPDATE_FAILURE: 'ORDER_PRODUCT_QTY_UPDATE_FAILURE',

    ORDER_PRODUCT_DETAILS_ID_REQUEST: 'ORDER_PRODUCT_DETAILS_ID_REQUEST',
    ORDER_PRODUCT_DETAILS_ID_SUCCESS: 'ORDER_PRODUCT_DETAILS_ID_SUCCESS',
    ORDER_PRODUCT_DETAILS_ID_NODATA: 'ORDER_PRODUCT_DETAILS_ID_NODATA',
    ORDER_PRODUCT_DETAILS_ID_FAILURE: 'ORDER_PRODUCT_DETAILS_ID_FAILURE',

};

