import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

// import "../font/DB Helvethaica X Bd Cond It v3.2.ttf";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";
import SearchBar from "../SearchBar/SearchBar";
import { StoreLocation } from "../StoreLocation";
import "./Header.css";
// import { ReactComponent as Logo } from "./Assets/SVG/Logo.svg";
// import { ReactComponent as LoginBtn } from "./Assets/SVG/LoginBtn.svg";
// import { ReactComponent as AddToCart } from "./Assets/SVG/AddToCart.svg";
// import { ReactComponent as WishList } from "./Assets/SVG/WishList.svg";
// import { ReactComponent as VerticleLine } from "./Assets/SVG/VerticleLine.svg";
// import { ReactComponent as UserIcon } from "./Assets/SVG/UserIcon.svg";
// import { ReactComponent as Dashboard } from "./Assets/SVG/Dashboard.svg";
// import { ReactComponent as Orders } from "./Assets/SVG/Orders.svg";
// import { ReactComponent as Password } from "./Assets/SVG/Password.svg";
// import { ReactComponent as Logout } from "./Assets/SVG/Logout.svg";
// import { ReactComponent as UserGrey } from "./Assets/SVG/UserGrey.svg";
// import { ReactComponent as WishListGrey } from "./Assets/SVG/WishListGrey.svg";
// import { ReactComponent as StoreLocationGreen } from "./Assets/SVG/StoreLocationGreen.svg";
// import { ReactComponent as DownArrowWhite } from "./Assets/SVG/DownArrowWhite.svg";
// import { ReactComponent as Web } from "./Assets/SVG/Web.svg";
import UserId from "./Assets/SVG/UserId.svg";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddToCart, Dashboard, Logo, Logout, Orders, Password, StoreLocationGreen, UserGrey, UserIcon, VerticleLine, Web, WishList } from "../CommonStyle/ExportSVGImage";
import {
  landingPageActions,
  productActions,
  userActions
} from "../_actions";
import OffSpec from "../images/Offspecformenu.png";
import Chicken from "./Assets/Images/Chicken.png";
import Duck from "./Assets/Images/Duck.png";
import Egg from "./Assets/Images/Egg.png";
import Fish from "./Assets/Images/Fish.png";
import Others from "./Assets/Images/Others.png";
import Pork from "./Assets/Images/Pork.png";
import ProcessedMeat from "./Assets/Images/ProcessedMeat.png";
import ReadyToCook from "./Assets/Images/ReadyToCook.png";
import ReadyToEat from "./Assets/Images/ReadyToEat.png";
import SearchIconMob from "./Assets/SVG/SearchIconMob.svg";
import UserIconMob from "./Assets/SVG/UserIconMob.svg";
// import SearchBarMobile from "../SearchBar/SearchBarMobile";
// import { PorkMob } from "../HeaderMob";
const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [openSearchBarMobile, setOpenSearchMobile] = useState(false);

  const languageText = localStorage.getItem("lancode");
  const [openSearch, setOpenSearch] = React.useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);
  const [openModalPork, setOpenModalPork] = useState(false);
  const [openModalChicken, setOpenModalChicken] = useState(false);
  const [openModalDuck, setOpenModalDuck] = useState(false);
  const [openModalFish, setOpenModalFish] = useState(false);
  const [openModalEgg, setOpenModalEgg] = useState(false);
  const [openModalProcessedMeat, setOpenModalProcessedMeat] = useState(false);
  const [openModalReadyToEat, setOpenModalReadyToEat] = useState(false);
  const [openModalReadyToCook, setOpenModalReadyToCook] = useState(false);
  const [openModalOthers, setOpenModalOthers] = useState(false);
  const [openModalOffSpec, setOpenModalOffSpec] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const location = useLocation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [toBackEnd, setToBackEnd] = useState('/orderDetails')
  const dispatch = useDispatch();
  const [cartCount, setCartCount] = useState(0);
  const [totalQuantityCount, setTotalQuantityCount] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);
  const whishlistDetailsInformationList = useSelector((state) => state.product.whishlistDetailsInformationList);
  const cartDetails = useSelector((state) => state.product.cartDetails);
  const history = useHistory();
  const [totalCountactivelist, setTotalCountactivelist] = React.useState(0);
  const activelistorderList = useSelector(
    (state) => state.order.activelistorderList
  );

  const menuDetails = useSelector((state) => state.landing.menuDetails);
  const cartData = useSelector((state) => state.product);

  const getMenus = (cat) => {
    const menuMain =
      menuDetails &&
      menuDetails.data &&
      menuDetails.data.filter((item) => item.categoryEN == cat);
    const subCategory =
      menuMain && menuMain.length > 0 && menuMain[0].subCategory;
    return subCategory;
  };

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const sessionUser = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : undefined;

  const changeLanguage = (e, value) => {
    localStorage.setItem("lancode", value);
    i18n.changeLanguage(value);
  };
  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };
  const store = userData ? userData.user.stores[0].storeCode : "";

  React.useEffect(() => {
    dispatch(landingPageActions.getAllCatSubAction());
    let payload = {};
    if (userData) {
      dispatch(productActions.getCartData("payload", payload));
      dispatch(
        productActions.getWishListInformation(
          "",
          payload,
          ""
        )
      );
    }
    if (userData && userData.user && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin'))
      setToBackEnd('/orderDetails')
    else if (userData && userData.user && userData.user.role === 'Marketing')
      setToBackEnd('/marketingmanagement')

    setTimeout(() => {
      if (
        localStorage.getItem("lancode") &&
        localStorage.getItem("lancode") !== ""
      ) {
      } else {
        localStorage.setItem("lancode", "th");
        i18n.changeLanguage("th");
      }
    }, 2000);
  }, []);
  React.useEffect(() => {
    if (userData && userData.user && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin'))
      setToBackEnd('/orderDetails')
    else if (userData && userData.user && userData.user.role === 'Marketing')
      setToBackEnd('/marketingmanagement')
  }, [userData])
  React.useEffect(() => {
    setWishlistCount(whishlistDetailsInformationList?.data?.totalCount ? whishlistDetailsInformationList?.data?.totalCount : "");

  }, [whishlistDetailsInformationList]);

  React.useEffect(() => {
    let totalquantity = 0;
    setCartCount(cartData?.cartDetails?.data?.length ? cartData?.cartDetails?.data?.length : "");
    cartData?.cartDetails?.data?.map((items) => {
      totalquantity = totalquantity + items?.cartQuantity;
    });
    setTotalQuantityCount(totalquantity);

  }, [cartDetails]);


  // React.useEffect(() => {
  //   let payload = {};
  //   let totalquantity = 0;
  //   isNull(cartData.cartDetails) &&
  //     location.pathname !== "/mycart" &&
  //     dispatch(productActions.getCartData("payload", payload));
  //   isNull(cartData.whishlistDetailsInformationList) &&
  //     dispatch(
  //       productActions.getWishListInformation(
  //         "",
  //         payload,
  //         `?limit=100000&skip=0`
  //       )
  //     );
  //   if (!isNull(cartData.cartDetails)) {
  //     setCartCount(cartData.cartDetails.data.length);
  //     cartData.cartDetails.data.map((items) => {
  //       totalquantity = totalquantity + items.cartQuantity;
  //     });
  //     setTotalQuantityCount(totalquantity);
  //   }
  //   if (cartData.whishlistDetailsInformationList) {
  //     setWishlistCount(
  //       cartData.whishlistDetailsInformationList?.data?.totalCount ? cartData.whishlistDetailsInformationList.data.totalCount : 0
  //     );
  //   }
  //   setTimeout(() => {
  //     if (
  //       localStorage.getItem("lancode") &&
  //       localStorage.getItem("lancode") !== ""
  //     ) {
  //     } else {
  //       localStorage.setItem("lancode", "th");
  //       i18n.changeLanguage("th");
  //     }
  //   }, 2000);
  // }, [
  //   cartData.cartDetails,
  //   cartData.whishlistDetailsInformationList,
  //   cartData.cartQuantity,
  // ]);

  React.useEffect(() => {
    if (sessionUser && sessionUser.user && sessionUser.user.isVerified) {
      setIsUserLoggedIn(sessionUser.user.isVerified);
    }
    if (activelistorderList?.data) {
      if (
        activelistorderList.data &&
        activelistorderList?.data.result.length > 0
      ) {
        setTotalCountactivelist(activelistorderList?.data.totalCount);
      } else {
        setTotalCountactivelist(0);
      }
    }
  }, [isUserLoggedIn, userData, activelistorderList]);

  const openSearchPopUp = () => {
    setOpenSearch(true);
  };

  const openSearchPopUpClosed = () => {
    setOpenSearch(false);
  };

  const logOut = () => {
    const payloadWithOutLogin = {
      typeCode: "2",
    };
    const payloadWithOutLoginNew = {
      typeCode: "3",
    };
    dispatch(userActions.logout(localStorage.getItem("authorization")));
    setIsUserLoggedIn(false);
    localStorage.removeItem("user");
    localStorage.removeItem("authorization");
    localStorage.removeItem("keepMeLoggedIn");
    localStorage.removeItem("phone");
    localStorage.removeItem("password");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("authorization");
    dispatch(
      landingPageActions.getPromotionWithoutLogin(
        "promotion",
        payloadWithOutLogin
      )
    );
    dispatch(
      landingPageActions.getNewItemsDataWithOutLogin(
        "newItem",
        payloadWithOutLoginNew
      )
    );
    history.push("/");
  };

  const userDetails = sessionStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : undefined;

  const redirecttowishlist = () => {
    if (!isNull(userData)) {
      history.push("/MyAccountWishlist");
    } else {
      localStorage.setItem('prevPath', '/MyAccountWishlist')
      history.push("/login");
    }
  };

  const redirecttocart = () => {
    if (!isNull(userData)) {
      history.push("/mycart");
    } else {
      localStorage.setItem('prevPath', '/mycart')
      history.push("/login");
    }
  };
  const showInMapClicked = () => {
    // window.open("https://maps.google.com?q="+13.163550127386392+","+100.93023178465853 );
  };
  return (
    <>
      {/* <LatestOffer /> */}
      {/* <Popup
          title=""
          openPopup={openSearch}
          setOpenPopup={setOpenSearch}
          maxWidth="100%"
          width={"100%"}
          height={"auto"}
          pt={0}
          pb={5}
          pl={0}
          pr={0}
        >
          <SearchBar openSearchPopUpClosed={openSearchPopUpClosed} />
        </Popup> */}
      {openSearch && (
        <SearchBar openSearchPopUpClosed={openSearchPopUpClosed} />
      )}
      <div className="header-container" id="loading-header-screen-landing-screen">
        <div className="bg-header d-flex">
          <div className="bg-header-left d-flex mr-auto justify-content-start">
            <Link to="/">
              <Logo className="bg-header-logo" />
            </Link>
          </div>
          <div className="bg-header-right d-flex justify-content-end">
            <ul className="d-flex">
              <li>
                <form>
                  <input
                    type="text"
                    placeholder={t("Search.label")}
                    onClick={openSearchPopUp}
                    onChange={openSearchPopUp}
                    className="hidden-md"
                    value={""}
                    data-testid="search-label-loading"
                  />

                  <img
                    src={SearchIconMob}
                    className="visible-md searchMobImg"
                    onClick={openSearchPopUp}
                  />
                </form>
              </li>
              {isUserLoggedIn ? (
                <li className="hidden-xs bg-header-icon">
                  <Dropdown
                    className="bg-Header-dropdown"
                    style={{ zIndex: 99999 }}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <UserIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">
                        <Link to="/MyAccountProfile">
                          <span className="submenu-icon">
                            <UserGrey />
                          </span>
                          {t("MyProfile.label")}
                        </Link>
                      </Dropdown.Item>
                      {/* {sessionUser && sessionUser.user.role === "Customer" ?
                          <Dropdown.Item href="#/action-2" className='my-account-hamburger-menu-section-heading-active cursor-pointer-none'>
                            <span className="submenu-icon">
                              <Dashboard />
                            </span>
                            {t("MyDashboard.label")}
                          </Dropdown.Item> :
                          <Dropdown.Item href="#/action-2">
                            <Link to="/comingsoon">
                              <span className="submenu-icon">
                                <Dashboard />
                              </span>
                              {t("MyBackend.label")}
                            </Link>
                          </Dropdown.Item>
                        } */}

                      {sessionUser && sessionUser.user.role != "Customer" && (
                        <Dropdown.Item href="#/action-2">
                          <Link to={toBackEnd}>
                            <span className="submenu-icon">
                              <Dashboard />
                            </span>
                            {t("MyBackend.label")}
                          </Link>
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item href="#/action-3">
                        <Link to="/myorder">
                          <span className="submenu-icon">
                            <Orders />
                          </span>
                          {t("MyOrders.label")}

                          {isUserLoggedIn ? (
                            <>
                              {" "}
                              {/*   <span className="active-count">
                                  {totalCountactivelist}</span> */}
                            </>
                          ) : (
                            <></>
                          )}
                        </Link>
                      </Dropdown.Item>
                      {/*  <Dropdown.Item href="#/action-4">
                          <Link to="/MyAccountWishlist">
                            <span className="submenu-icon">
                              <WishListGrey />
                            </span>
                            {t("MyWishlist.label")}
                            {isUserLoggedIn && wishlistCount > 0 ? (
                              <>
                                {/*  <span
                                  className="active-count"
                                  style={{ color: "#FFFFFF" }}
                                >
                                   {wishlistCount} 
                                </span> 
                              </>
                            ) : (
                              <></>
                            )}
                          </Link>
                        </Dropdown.Item> */}
                      <Dropdown.Item href="#/action-6">
                        <Link to="/MyAccountChangePassword">
                          <span className="submenu-icon">
                            <Password />
                          </span>
                          {t("ChangePassword.label")}
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-6">
                        <Link onClick={logOut} to='/'>
                          <span className="submenu-icon">
                            <Logout />
                          </span>
                          {t("Logout.label")}
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              ) : (
                <></>
              )}
              <li className="hidden-xs bg-header-icon">
                <button
                  onClick={() => redirecttowishlist()}
                  className="location-button"
                >
                  <WishList />
                  {isUserLoggedIn && wishlistCount > 0 ? (
                    <>
                      <span className="item-count">{wishlistCount}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </button>
              </li>
              <li className="bg-header-icon">
                <button
                  onClick={() => redirecttocart()}
                  className="location-button"
                >
                  <AddToCart />
                  {isUserLoggedIn && totalQuantityCount > 0 ? (
                    <>
                      <span className="item-count">{totalQuantityCount}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </button>
              </li>
              <li className="hidden-xs">
                <VerticleLine />
              </li>
              {/* <div onClick={changeLanguage}>{languageText.toUpperCase()} </div> */}
              <li className="hidden-xs">
                {languageText === "en" && (
                  <span onClick={(e) => changeLanguage(e, "th")}>EN</span>
                )}
                {languageText === "th" && (
                  <span onClick={(e) => changeLanguage(e, "en")}>TH</span>
                )}
              </li>
              {isUserLoggedIn ? (
                <>
                  {userDetails &&
                    userDetails.user &&
                    userDetails.user.stores &&
                    userDetails.user.stores[0] && (
                      <li
                        key={userDetails.user.stores[0].storeId}
                        className="d-flex"
                      >
                        <button
                          onClick={() => showInMapClicked()}
                          className="location-button hidden-xs"
                        >
                          <StoreLocationGreen />
                        </button>
                        <Link
                          className="store-loc-link d-flex flex-row align-items-center hidden-xs "
                          onClick={() => setToggle(!toggle)}
                          to={location.pathname}
                        >
                          {languageText === "en" && (
                            <p className="default-store hidden-xs">
                              {userDetails.user.stores[0].storeNameEN
                                ? userDetails.user.stores[0].storeNameEN.split(
                                  " "
                                )[2]
                                : ""}
                            </p>
                          )}
                          {languageText === "th" && (
                            <p className="default-store hidden-xs">
                              {userDetails.user.stores[0].storeNameTH
                                ? userDetails.user.stores[0].storeNameTH.split(
                                  " "
                                )[1]
                                : ""}
                            </p>
                          )}
                        </Link>

                        <div className="store-location-details">
                          {toggle && (
                            <StoreLocation
                              storeDetails={userDetails.user.stores[0]}
                            />
                          )}
                        </div>
                      </li>
                    )}
                </>
              ) : (
                <li className="hidden-xs">
                  {" "}
                  <Link to="/login">
                    {" "}
                    <button to="/login" className="logIn-Btn">
                      {t("Login.label")}
                    </button>
                  </Link>{" "}
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="visible-xs bg-menu-mob">
          {["sm"].map((expand) => (
            <Navbar key={expand} expand={expand} className="">
              <Container fluid>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                  className="bg-menu-mob-body"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    ></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1">
                      {userDetails && userDetails.user && (
                        <StoreLocation
                          storeDetails={userDetails.user.stores[0]}
                        />
                      )}
                      {!isUserLoggedIn && (
                        <Nav.Link as={Link} to="/login">
                          <div className="header-userNameMob align-items-center">
                            <div>
                              <img src={UserIconMob} />
                            </div>
                            <div>
                              <h4>{t("Login.label")}</h4>
                            </div>
                          </div>
                        </Nav.Link>
                      )}
                      {isUserLoggedIn && userDetails && userDetails.user && (
                        <Nav.Link as={Link} to="/MyAccountMob">
                          {" "}
                          <div className="header-userNameMob" style={{ paddingTop: "10px", paddingBottom: "5px" }}>
                            <div>
                              <img src={UserIconMob} />
                            </div>
                            <div>
                              {languageText === "en" ? (
                                <h4>
                                  {userDetails.user.firstNameEN}{" "}
                                  {userDetails.user.lastNameEN}{" "}
                                </h4>
                              ) : (
                                <h4>
                                  {userDetails.user.firstNameTH}{" "}
                                  {userDetails.user.lastNameTH}{" "}
                                </h4>
                              )}
                              {/* {languageText === "th" && (
                                                                  
                                  )} */}
                              {sessionUser &&
                                sessionUser.user.role === "Customer" && (
                                  <p>
                                    {/* <img src={UserId} /> */}
                                    <span>
                                      {userDetails.user.customerCode}
                                    </span>
                                  </p>
                                )}
                              {sessionUser &&
                                sessionUser.user.role === "Saleshop" && (
                                  <p>
                                    <img src={UserId} />
                                    <span>
                                      {userDetails.user.customerCode}
                                    </span>
                                  </p>
                                )}
                            </div>
                          </div>
                        </Nav.Link>
                      )}
                      <div style={{ padding: "0 20px" }}>
                        <hr />
                      </div>
                      <Nav.Link
                        to="/allproducts"
                        style={{ padding: "0 20px" }}
                      >
                        {t("allProduct.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="/PorkMob"
                        onClick={() => {

                          history.push("/PorkMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("Pork.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="/ChickenMob"
                        onClick={() => {
                          history.push("/ChickenMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("Chicken.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="/DuckMob"
                        onClick={() => {
                          history.push("/DuckMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("Duck.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="/FishMob"
                        onClick={() => {
                          history.push("/FishMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("Fish.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="/EggMob"
                        onClick={() => {
                          history.push("/EggMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("Egg.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="/ProcessedMeatMob"
                        onClick={() => {
                          history.push("/ProcessedMeatMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("ProcessedMeat.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="/ReadyToEatMob"
                        onClick={() => {
                          history.push("/ReadyToEatMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("ReadytoEat.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="/ReadyToCookMob"
                        onClick={() => {
                          history.push("/ReadyToCookMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("ReadytoCook.label")}
                      </Nav.Link>
                      <Nav.Link
                        to="OthersMob"
                        onClick={() => {
                          history.push("/OthersMob");
                        }}
                        style={{ padding: "0 20px" }}
                      >
                        {t("Others.label")}
                      </Nav.Link>
                      {/* <NavDropdown
                          title={t("Pork.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          <NavDropdown.Item href="/pork"></NavDropdown.Item>
                          {getMenus("pork") &&
                            getMenus("pork").length > 0 &&
                            getMenus("pork").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link to={"/pork/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown> */}
                      {/* <NavDropdown
                          title={t("Chicken.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        //Done
                        >
                          <NavDropdown.Item href="/chicken"></NavDropdown.Item>
                          {getMenus("chicken") &&
                            getMenus("chicken").length > 0 &&
                            getMenus("chicken").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link to={"/chicken/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown>
                        <NavDropdown
                          title={t("Duck.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          <NavDropdown.Item href="/duck"></NavDropdown.Item>
                          {getMenus("duck") &&
                            getMenus("duck").length > 0 &&
                            getMenus("duck").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link to={"/duck/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown>
                        <NavDropdown
                          title={t("Fish.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          <NavDropdown.Item href="/fish"></NavDropdown.Item>
                          {getMenus("fish") &&
                            getMenus("fish").length > 0 &&
                            getMenus("fish").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link to={"/fish/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown>
                        <NavDropdown
                          title={t("Egg.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          <NavDropdown.Item href="/egg"></NavDropdown.Item>
                          {getMenus("egg") &&
                            getMenus("egg").length > 0 &&
                            getMenus("egg").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link to={"/egg/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown>
                        <NavDropdown
                          title={t("ProcessedMeat.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          <NavDropdown.Item to="/processedmeat"></NavDropdown.Item>
                          {getMenus("processed meat") &&
                            getMenus("processed meat").length > 0 &&
                            getMenus("processed meat").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link
                                    to={
                                      "/processed-meat/" + item.subCategorySlug
                                    }
                                  >
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown>
                        <NavDropdown
                          title={t("ReadytoEat.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        //done
                        >
                          <NavDropdown.Item></NavDropdown.Item>
                          {getMenus("ready to eat") &&
                            getMenus("ready to eat").length > 0 &&
                            getMenus("ready to eat").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link
                                    to={"/ready to eat/" + item.subCategorySlug}
                                  >
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown>
                        <NavDropdown
                          title={t("ReadytoCook.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        //done
                        >
                          <NavDropdown.Item href="/ready to cook"></NavDropdown.Item>
                          {getMenus("ready to cook") &&
                            getMenus("ready to cook").length > 0 &&
                            getMenus("ready to cook").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link
                                    to={
                                      "/ready to cook/" + item.subCategorySlug
                                    }
                                  >
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown>
                        <NavDropdown
                          title={t("Others.label")}
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          <NavDropdown.Item href="/othersproduct"></NavDropdown.Item>
                          {getMenus("others") &&
                            getMenus("others").length > 0 &&
                            getMenus("others").map((item) => {
                              return (
                                <NavDropdown.Item>
                                  <Link to={"/others/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </NavDropdown.Item>
                              );
                            })}
                        </NavDropdown> */}
                      {sessionUser && sessionUser.user.role == "Saleshop" && (
                        // <NavDropdown
                        //   title={t("offspec.label")}
                        //   id={`offcanvasNavbarDropdown-expand-${expand}`}
                        // >
                        //   <NavDropdown.Item href="/OffSpec"></NavDropdown.Item>
                        //   {getMenus("others") &&
                        //     getMenus("others").length > 0 &&
                        //     getMenus("others").map((item) => {
                        //       return (
                        //         <NavDropdown.Item>
                        //           <Link to={"/others/" + item.subCategorySlug}>
                        //             {languageText === "en"
                        //               ? item.subCategoryEN
                        //               : item.subCategoryTH}
                        //           </Link>
                        //         </NavDropdown.Item>
                        //       );
                        //     })}
                        // </NavDropdown>
                        <Nav.Link
                          onClick={() => {
                            history.push("/OffspecMob");
                          }}
                          style={{ padding: "0 20px" }}
                          to="/OffspecMob"
                        >
                          {t("offspec.label")}
                        </Nav.Link>
                      )}
                      <div style={{ padding: "0 20px" }}>
                        <hr />
                      </div>
                      <Nav.Link href="#action12" className="visible-xs" to="#action12">
                        <div className="navLink">
                          <div className="navLink-lt">
                            <p>{t("changeLanguage.label")}</p>
                          </div>
                          <div className="navLink-rt">
                            {languageText === "en" && (
                              <span onClick={(e) => changeLanguage(e, "th")}>
                                <Web /> &nbsp;&nbsp;EN
                              </span>
                            )}
                            {languageText === "th" && (
                              <span onClick={(e) => changeLanguage(e, "en")}>
                                <Web /> &nbsp;&nbsp;TH
                              </span>
                            )}
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </div>
        <div className="bg-menu hidden-xs">
          <ul>
            <li>
              <Link
                to="/allproducts"
                onClick={() => [
                  setOverlayOpen(false),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                ]}
              >
                {t("ShopAll.label")}
              </Link>
            </li>
            <li>
              <Link
                to={location.pathname}
                onClick={() => [
                  setOpenModalPork(!openModalPork),
                  setOverlayOpen(!overlayOpen),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                ]}
              >
                {t("Pork.label")}
              </Link>
              {openModalPork ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 9999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={Pork} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalPork(false)}>
                          <Link to="/pork">{t("Pork.label")}</Link>
                        </h4>
                        <ul>
                          {getMenus("pork") &&
                            getMenus("pork").length > 0 &&
                            getMenus("pork").map((item) => {
                              return (
                                <li onClick={() => setOpenModalPork(false)}>
                                  <Link to={"/pork/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            <li>
              <Link
                to={location.pathname}
                onClick={() => [
                  setOpenModalChicken(!openModalChicken),
                  setOpenModalPork(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                  setOverlayOpen(!overlayOpen),
                ]}
              >
                {t("Chicken.label")}
              </Link>
              {openModalChicken ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 9999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={Chicken} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalChicken(false)}>
                          <Link to="/chicken">{t("Chicken.label")}</Link>
                        </h4>
                        <ul>
                          {getMenus("chicken") &&
                            getMenus("chicken").length > 0 &&
                            getMenus("chicken").map((item) => {
                              return (
                                <li
                                  onClick={() => setOpenModalChicken(false)}
                                >
                                  <Link
                                    to={"/chicken/" + item.subCategorySlug}
                                  >
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            <li>
              <Link
                to={location.pathname}

                onClick={() => [
                  setOpenModalDuck(!openModalDuck),
                  setOverlayOpen(!overlayOpen),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                ]}
              >
                {t("Duck.label")}
              </Link>
              {openModalDuck ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 99999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={Duck} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalDuck(false)}>
                          <Link to="/duck">{t("Duck.label")}</Link>
                        </h4>
                        <ul>
                          {getMenus("duck") &&
                            getMenus("duck").length > 0 &&
                            getMenus("duck").map((item) => {
                              return (
                                <li onClick={() => setOpenModalDuck(false)}>
                                  <Link to={"/duck/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            <li>
              <Link
                to={location.pathname}
                onClick={() => [
                  setOpenModalFish(!openModalFish),
                  setOverlayOpen(!overlayOpen),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                ]}
              >
                {t("Fish.label")}
              </Link>
              {openModalFish ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 99999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={Fish} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalFish(false)}>
                          <Link to="/fish">{t("Fish.label")}</Link>
                        </h4>
                        <ul>
                          {getMenus("fish") &&
                            getMenus("fish").length > 0 &&
                            getMenus("fish").map((item) => {
                              return (
                                <li onClick={() => setOpenModalFish(false)}>
                                  <Link to={"/fish/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            <li>
              <Link
                to={location.pathname}
                onClick={() => [
                  setOpenModalEgg(!openModalEgg),
                  setOverlayOpen(!overlayOpen),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                ]}
              >
                {t("Egg.label")}
              </Link>
              {openModalEgg ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 99999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={Egg} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalEgg(false)}>
                          <Link to="/egg">{t("Egg.label")}</Link>
                        </h4>
                        <ul>
                          {getMenus("egg") &&
                            getMenus("egg").length > 0 &&
                            getMenus("egg").map((item) => {
                              return (
                                <li onClick={() => setOpenModalEgg(false)}>
                                  <Link to={"/egg/" + item.subCategorySlug}>
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            <li>
              <Link
                to={location.pathname}
                onClick={() => [
                  setOpenModalProcessedMeat(!openModalProcessedMeat),
                  setOverlayOpen(!overlayOpen),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                ]}
              >
                {t("ProcessedMeat.label")}
              </Link>
              {openModalProcessedMeat ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 99999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={ProcessedMeat} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalProcessedMeat(false)}>
                          <Link to="/processed-meat">
                            {t("ProcessedMeat.label")}
                          </Link>
                        </h4>
                        <ul>
                          {getMenus("processed meat") &&
                            getMenus("processed meat").length > 0 &&
                            getMenus("processed meat").map((item) => {
                              return (
                                <li
                                  onClick={() =>
                                    setOpenModalProcessedMeat(false)
                                  }
                                >
                                  <Link
                                    to={
                                      "/processed-meat/" +
                                      item.subCategorySlug
                                    }
                                  >
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            <li>
              <Link
                to={location.pathname}
                onClick={() => [
                  setOpenModalReadyToEat(!openModalReadyToEat),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                  setOverlayOpen(!overlayOpen),
                ]}
              >
                {t("ReadytoEat.label")}
              </Link>
              {openModalReadyToEat ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 99999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={ReadyToEat} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalReadyToEat(false)}>
                          <Link to="/ready-to-eat">
                            {t("ReadytoEat.label")}
                          </Link>
                        </h4>
                        <ul>
                          {getMenus("ready to eat") &&
                            getMenus("ready to eat").length > 0 &&
                            getMenus("ready to eat").map((item) => {
                              return (
                                <li
                                  onClick={() =>
                                    setOpenModalReadyToEat(false)
                                  }
                                >
                                  <Link
                                    to={
                                      "/ready-to-eat/" + item.subCategorySlug
                                    }
                                  >
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            <li>
              <Link
                to={location.pathname}
                onClick={() => [
                  setOpenModalReadyToCook(!openModalReadyToCook),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalOthers(false),
                  setOpenModalOffSpec(false),
                  setOverlayOpen(!overlayOpen),
                ]}
              >
                {t("ReadytoCook.label")}
              </Link>
              {openModalReadyToCook ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 99999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={ReadyToCook} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalReadyToCook(false)}>
                          <Link to="/ready-to-cook">
                            {t("ReadytoCook.label")}
                          </Link>
                        </h4>
                        <ul>
                          {getMenus("ready to cook") &&
                            getMenus("ready to cook").length > 0 &&
                            getMenus("ready to cook").map((item) => {
                              return (
                                <li
                                  onClick={() =>
                                    setOpenModalReadyToCook(false)
                                  }
                                >
                                  <Link
                                    to={
                                      "/ready-to-cook/" + item.subCategorySlug
                                    }
                                  >
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            <li>
              <Link
                to={location.pathname}
                onClick={() => [
                  setOpenModalOthers(!openModalOthers),
                  setOpenModalPork(false),
                  setOpenModalChicken(false),
                  setOpenModalDuck(false),
                  setOpenModalFish(false),
                  setOpenModalEgg(false),
                  setOpenModalProcessedMeat(false),
                  setOpenModalReadyToEat(false),
                  setOpenModalReadyToCook(false),
                  setOpenModalOffSpec(false),
                  setOverlayOpen(!overlayOpen),
                ]}
              >
                {t("Other.label")}
              </Link>
              {openModalOthers ? (
                <ul className="bg-menu-submenu" style={{ zIndex: 99999 }}>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                      <div className="bg-menu-submenu-img">
                        <img src={Others} />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="bg-menu-submenu-list">
                        <h4 onClick={() => setOpenModalOthers(false)}>
                          <Link to="/others">{t("Other.label")}</Link>
                        </h4>
                        <ul>
                          {getMenus("others") &&
                            getMenus("others").length > 0 &&
                            getMenus("others").map((item) => {
                              return (
                                <li onClick={() => setOpenModalOthers(false)}>
                                  <Link
                                    to={"/others/" + item.subCategorySlug}
                                  >
                                    {languageText === "en"
                                      ? item.subCategoryEN
                                      : item.subCategoryTH}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              ) : null}
            </li>
            {sessionUser && sessionUser.user.role == "Saleshop" && (
              <li>
                <Link
                  onClick={() => [
                    setOpenModalOffSpec(!openModalOffSpec),
                    setOpenModalPork(false),
                    setOpenModalChicken(false),
                    setOpenModalDuck(false),
                    setOpenModalFish(false),
                    setOpenModalEgg(false),
                    setOpenModalProcessedMeat(false),
                    setOpenModalReadyToEat(false),
                    setOpenModalReadyToCook(false),
                    setOpenModalOthers(false),
                    setOverlayOpen(!overlayOpen),
                  ]}
                  to={location.pathname}
                >

                  {t("offspec.label")}
                </Link>
                {openModalOffSpec ? (
                  <ul className="bg-menu-submenu" style={{ zIndex: 99999 }}>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 displayNoneMD">
                        <div className="bg-menu-submenu-img">
                          <img src={OffSpec} />
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div className="bg-menu-submenu-list">
                          <h4 onClick={() => setOpenModalOffSpec(false)}>
                            <Link to="/off-spec">{t("offspec.label")}</Link>
                          </h4>
                          <ul>
                            {getMenus("off spec") &&
                              getMenus("off spec").length > 0 &&
                              getMenus("off spec").map((item) => {
                                return (
                                  <li
                                    onClick={() => setOpenModalOffSpec(false)}
                                  >
                                    <Link
                                      to={"/off-spec/" + item.subCategorySlug}
                                    >
                                      {languageText === "en"
                                        ? item.subCategoryEN
                                        : item.subCategoryTH}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ul>
                ) : null}
              </li>
            )}
          </ul>
        </div>
      </div>
      {openModalPork ||
        openModalChicken ||
        openModalDuck ||
        openModalFish ||
        openModalEgg ||
        openModalProcessedMeat ||
        openModalReadyToEat ||
        openModalReadyToCook ||
        openModalOthers ||
        openModalOffSpec ? (
        <div id="overlay" className="hidden-xs"></div>
      ) : null}
    </>
  );
};

export { Header as Header };

