import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { MarkentingManagement } from '../BannerUpload';
import { LoginCheckEmailFirst } from '../LoginCheckEmailFirst';
import { LoginCheckEmailPassword } from '../LoginCheckEmailPassword';
import { LoginFirstTime } from '../LoginFirstTime';
import { LoginPage } from '../LoginPage';
import { LoginResetPassword } from '../LoginResetPassword';
import { LoginResetPasswordSuccess } from '../LoginResetPasswordSuccess';
import { MyDashBoard } from '../MyDashBoard';
import { MyOrder } from '../MyOrder';
import { MyProfile } from '../MyProfile';
import { MyProfileChangePassword } from '../MyProfileChangePassword';
import { MyProfileChangePasswordSuccess } from '../MyProfileChangePasswordSuccess';
import { MyWishList } from '../MyWishList';
import { RegisterPage } from '../RegisterPage';
import { alertActions } from '../_actions';
import { history } from '../_helpers';
import "../i18n";
// import { MyOrderDetails } from '../MyOrderDetails';
import { AllProduct } from '../AllProducts/AllProduct';
import { BulkUpload } from '../BulkUpload';
import { Category } from '../Category/Category';
import { CustomerCheckout } from '../CustomerCheckout';
import { ForgotOtpVerification } from '../ForgotOtpVerification';
import { ForgotPassword } from '../ForgotPassword';
import { ChickenMob } from '../HeaderMob/ChickenMob';
import { DuckMob } from '../HeaderMob/DuckMob';
import { EggMob } from '../HeaderMob/EggMob';
import { FishMob } from '../HeaderMob/FishMob';
import { MyAccountMob } from '../HeaderMob/MyAccountMob';
import { OffspecMob } from '../HeaderMob/OffspecMob';
import { OthersMob } from '../HeaderMob/OthersMob';
import { PorkMob } from '../HeaderMob/PorkMob';
import { ProcessedMeatMob } from '../HeaderMob/ProcessedMeatMob';
import { ReadyToCookMob } from '../HeaderMob/ReadyToCookMob';
import { ReadyToEatMob } from '../HeaderMob/ReadyToEatMob';
import { HomeScreen } from '../HomeScreen';
import { LandingPage } from '../LandingPage';
import { LatestOffer } from '../LatestOffer';
import { LoginCreatePassword } from '../LoginCreatePassword';
import { LoginMailVerification } from '../Mailverification/LoginMailVerification';
import { MyAccountChangePassword } from '../MyAccountChangePassword';
import { MyAccountProfile } from '../MyAccountProfile';
import { MyAccountWishlist } from '../MyAccountWishlist';
import { MyCart } from '../MyCart';
import { MyCartCheckOut } from '../MyCartCheckOut';
import { MyOrderDetails } from '../MyOrderDetails/MyOrderDetails';
import { OneTimePassword } from '../OneTimePassword';
import { OrderDetailsSales } from '../OrderDetailsSales';
import ComingSoon from '../OrderList/ComingSoon/ComingSoon';
import CreateOrder from '../OrderList/CreateOrder/Index';
import OrderList from '../OrderList/Index';
import { OrderManagementCreateOrder } from '../OrderManagementCreateOrder';
import { ProductDetailsCustomer } from '../ProductDetailsCustomer';
import { ProductReview } from '../ProductReview';
import { SalesReport } from '../SalesReport';
import { SalesReportReview } from '../SalesReportReview';
import { ViewAllSearchResults } from '../SearchViewAllResults';
import { ShopByBrand } from '../ShopByBrand';
import { StoreLocation } from '../StoreLocation';
import { UserManagement } from '../UserManagement';
const routes = [
    { path: "/", name: "Home", privateRoute: false, Component: HomeScreen },
    { path: "/login", name: "Login", privateRoute: false, Component: LoginPage },
    { path: "/loginfirsttime", name: "Login First Time", privateRoute: false, Component: LoginFirstTime },
    { path: "/loginotpverification", name: "One Time Password", privateRoute: false, Component: OneTimePassword },
    { path: "/logincreatepassword", name: "Login Create Password", privateRoute: false, Component: LoginCreatePassword },
    { path: "/productdetailscustomer/:articaleCode", name: "Product Details", privateRoute: true, Component: ProductDetailsCustomer },
    { path: "/allproducts", name: "All Products", pageType: 'allProduct', privateRoute: true, Component: AllProduct },
    { path: "/newproducts", name: "New Products", pageType: 'newproducts', privateRoute: false, Component: AllProduct },
    { path: "/promoproducts", name: "Promo Products", pageType: 'promoproducts', privateRoute: false, Component: AllProduct },
    { path: "/pork/:subcategory?", name: "Pork", privateRoute: false, Component: Category },
    { path: "/chicken/:subcategory?", name: "Chicken", privateRoute: false, Component: Category },
    { path: "/fish/:subcategory?", name: "Fish", privateRoute: false, Component: Category },
    { path: "/processed-meat/:subcategory?", name: "Processed Meat", privateRout6e: false, Component: Category },
    { path: "/ready-to-eat/:subcategory?", name: "Ready To Eat", privateRoute: false, Component: Category },
    { path: "/ready-to-cook/:subcategory?", name: "Ready To Cook", privateRoute: false, Component: Category },
    { path: "/others/:subcategory?", name: "Other", privateRoute: false, Component: Category },
    { path: "/duck/:subcategory?", name: "Duck", privateRoute: false, Component: Category },
    { path: "/egg/:subcategory?", name: "Egg", privateRoute: false, Component: Category },
    { path: "/off-spec/:subcategory?", name: "Offsec", privateRoute: false, Component: Category },
    { path: "/offspecproduct/:subcategory?", name: "Offspec Product", privateRoute: false, Component: Category },
    { path: "/butchery/:subcategory?", name: "Butchery", privateRoute: false, Component: Category },
    { path: "/productreview", name: "Product Review", privateRoute: false, Component: ProductReview },
    { path: "/ShopByBrand", name: "Shop By Brand", privateRoute: false, Component: ShopByBrand },
    { path: "/latestoffer", name: "Latest Offer", privateRoute: false, Component: LatestOffer },
    { path: "/storelocation", name: "Store Location", privateRoute: false, Component: StoreLocation },
    { path: "/forgototpverification", name: "Forgot OTP Verification", privateRoute: false, Component: ForgotOtpVerification },
    { path: "/forgotpassword", name: "Forgot Password", privateRoute: false, Component: ForgotPassword },
    { path: "/logincheckemailfirst", name: "Login Check Email First", privateRoute: false, Component: LoginCheckEmailFirst },
    { path: "/logincheckemailpassword", name: "Login Check Email Password", privateRoute: false, Component: LoginCheckEmailPassword },
    { path: "/emailverification", name: "Login Mail Verification", privateRoute: false, Component: LoginMailVerification },
    { path: "/loginresetpasswordsuccess", name: "Login Reset Password Success", privateRoute: false, Component: LoginResetPasswordSuccess },
    { path: "/loginresetpassword", name: "Login Reset Password", privateRoute: false, Component: LoginResetPassword },
    { path: "/viewallsearchresults", name: "View Search Results", privateRoute: true, pageType: 'viewallsearchresults', Component: ViewAllSearchResults },
    { path: "/newproducts", name: "View Search Results - New Products", pageType: 'newproducts', privateRoute: false, Component: ViewAllSearchResults },
    { path: "/promoproducts", name: "View Search Results - Promo Products", pageType: 'promoproducts', privateRoute: false, Component: ViewAllSearchResults },
    { path: "/mycart", name: "My Cart", pageType: 'mycart', privateRoute: false, Component: MyCart },
    { path: "/CustomerCheckout", name: "View Order Details - Customer Checkout", pageType: 'CustomerCheckout', privateRoute: true, Component: CustomerCheckout },
    { path: "/orderDetails", name: "NewOrderListTable", pageType: 'NewOrderListTable', privateRoute: true, Component: OrderList },
    { path: "/mydashboard", name: "MyDashBoard", pageType: 'MyDashBoard', privateRoute: true, Component: MyDashBoard },
    { path: "/salesreport", name: "SalesReport", pageType: 'SalesReport', privateRoute: true, Component: SalesReport },
    { path: "/comingsoon", name: "ComingSoon", pageType: 'ComingSoon', privateRoute: true, Component: ComingSoon },

    { path: "/ChickenMob", name: "ChickenMob", pageType: 'ChickenMob', privateRoute: true, Component: ChickenMob },
    { path: "/DuckMob", name: "DuckMob", pageType: 'DuckMob', privateRoute: true, Component: DuckMob },
    { path: "/EggMob", name: "EggMob", pageType: 'EggMob', privateRoute: true, Component: EggMob },
    { path: "/FishMob", name: "FishMob", pageType: 'FishMob', privateRoute: true, Component: FishMob },
    { path: "/OffspecMob", name: "OffspecMob", pageType: 'OffspecMob', privateRoute: true, Component: OffspecMob },
    { path: "/OthersMob", name: "OthersMob", pageType: 'OthersMob', privateRoute: true, Component: OthersMob },
    { path: "/PorkMob", name: "PorkMob", pageType: 'PorkMob', privateRoute: true, Component: PorkMob },
    { path: "/ProcessedMeatMob", name: "ProcessedMeatMob", pageType: 'ProcessedMeatMob', privateRoute: true, Component: ProcessedMeatMob },
    { path: "/ReadyToCookMob", name: "ReadyToCookMob", pageType: 'ReadyToCookMob', privateRoute: true, Component: ReadyToCookMob },
    { path: "/ReadyToEatMob", name: "ReadyToEatMob", pageType: 'ReadyToEatMob', privateRoute: true, Component: ReadyToEatMob },
    { path: "/MyAccountMob", name: "MyAccountMob", pageType: 'MyAccountMob', privateRoute: true, Component: MyAccountMob },
    { path: "/MyAccountProfile", name: "MyAccountProfile", pageType: 'MyAccountProfile', privateRoute: true, Component: MyAccountProfile },
    { path: "/MyAccountWishlist", name: "MyAccountWishlist", pageType: 'MyAccountWishlist', privateRoute: true, Component: MyAccountWishlist },
    { path: "/MyAccountChangePassword", name: "MyAccountChangePassword", pageType: 'MyAccountChangePassword', privateRoute: true, Component: MyAccountChangePassword },
    { path: "/myorder", name: "Myorder", pageType: 'Myorder', privateRoute: true, Component: MyOrder },
    { path: "/myorderdetails/:orderId", name: "MyOrderDetails", pageType: 'MyOrderDetails', privateRoute: true, Component: MyOrderDetails },
    { path: "/bulkupload", name: "BulkUpload", pageType: 'BulkUpload', privateRoute: true, Component: BulkUpload },
    { path: "/createOrder/:orderId", name: "createOrder", pageType: 'createOrder', privateRoute: true, Component: CreateOrder },
    { path: "/usermanagement", name: "UserManagement", pageType: 'UserManagement', privateRoute: true, Component: UserManagement },
    { path: "/marketingmanagement", name: "MarkentingManagement", pageType: 'BannerManagemer', privateRoute: true, Component: MarkentingManagement }

];

const App = (props) => {
    // componentDidMount () {
    //     if ( document.cookie.indexOf('CookieConsent=true') === -1 ) {
    //         const script = document.createElement("script");
    //         script.id = "cookieWow";
    //         script.type = "text/javascript";
    //         script.src = "https://cookiecdn.com/configs/D7BuwrXYntYTqLLQYK5Hkr6v";
    //         script.setAttribute('data-cwcid', 'D7BuwrXYntYTqLLQYK5Hkr6v');
    //         script.async = true;
    //         document.body.appendChild(script);
    //     }
    // }
    /*<style
        data-emotion={`${style.key} ${style.ids.join(' ')}`}
        nonce={'NGY1OTViZjAtNWY0Ni00NDJlLWEwNDgtMjRlMTY1ZWViNzRj'}
        dangerouslySetInnerHTML={{ __html: style.css }}
    />*/

    return (
        <>
            <React.Suspense fallback={null}>

                <Router history={history}>
                    <Switch>
                        {routes.map(({ path, name, Component, pageType, privateRoute }, key) => (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={props => {
                                    const crumbs = routes
                                        .filter(({ path }) => props.match.path.includes(path))
                                        .map(({ path, ...rest }) => ({
                                            path: Object.keys(props.match.params).length
                                                ? Object.keys(props.match.params).reduce(
                                                    (path, param) =>
                                                        path.replace(`:${param}`, props.match.params[param]),
                                                    path
                                                )
                                                : path,
                                            ...rest
                                        }));
                                    return (
                                        <div className="p-8">
                                            {privateRoute
                                                ? sessionStorage.getItem('user')
                                                    ? <Component {...props} pageType={pageType} crumbs={crumbs} />
                                                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                                                : <Component {...props} pageType={pageType} crumbs={crumbs} />}
                                        </div>
                                    );
                                }}
                            />
                        ))}
                        <Redirect from="*" to="/" />

                        {/* Unused Routes and files and folders */}
                        <Route path="/myprofile" component={MyProfile} />
                        <Route path="/mydashboard" component={MyDashBoard} />
                        <Route path="/mywishlist" component={MyWishList} />
                        <Route path="/myprofilechangepassword" component={MyProfileChangePassword} />
                        <Route path="/myprofilechangepasswordsuccess" component={MyProfileChangePasswordSuccess} />
                        <Route path="/myorder" component={MyOrder} />
                        <Route path="/myorderdetails" component={MyOrderDetails} />
                        <Route path="/ordermanageentcreateorder" component={OrderManagementCreateOrder} />
                        <Route path="/salesreport" component={SalesReport} />
                        <Route path="/salesreportreview" component={SalesReportReview} />
                        <Route path="/orderdetailssales" component={OrderDetailsSales} />
                        <Route path="/landing" component={LandingPage} />
                        <Route path="/mycart" component={MyCart} />
                        <Route path="/mycartcheckout" component={MyCartCheckOut} />
                        <Route path="/register" component={RegisterPage} />
                    </Switch>
                </Router>

            </React.Suspense>

        </>
    );
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };

