import { authHeader } from "../_helpers";
import { Axios } from  "./axiosProvider";
import { REACT_APP_BASE_URL } from "../constant"
export const createOrderService = {  
  getOrderProductDetails,
  qtyUpdate,
  getOrderProductDetailsById
};

function getOrderProductDetails(orderId,payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/"+orderId,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getOrderProductDetailsById(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getOrderItemsByOrder",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function qtyUpdate(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/addOrderItems",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

