import React, { useEffect } from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import './thankYouForPuchaseModal.css';
import { useState } from 'react';
import thankYouPurchaseImage from '../../images/thank-you-puchase-image.png';
import deleteWhite from '../../images/delete-white.png';

const OrderEditModal = (props) => {

    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(true);
    const handleClose = () => {
        setOpenModal(false);
    }
    useEffect(() => {

    }, [props.orderNumber])
    const isNull = (value) => {
        return value == "" || value == undefined || value == null;
    };
    return <>
        {props.open ?
            <div className='thank-you-purchase-modal-main-container'>
                <div className='thank-you-purchase-modal-inner-container'>
                    <div className='thank-you-purchase-modal'>
                        <img src={isNull(props.image) ? thankYouPurchaseImage : props.image} className='thank-you-purchase-modal-image' />
                        <h6 className='thank-you-purchase-modal-heading'>{t(props.mainHeading)}</h6>
                        <h6 className='thank-you-purchase-modal-paragraph'>{t(props.subHeading)}</h6>
                        <h6 className='thank-you-purchase-modal-paragraph'>{props.innerHeading}</h6>
                        {props.subHeading2 && (
                            <>
                                <h6 className='thank-you-purchase-modal-inner-paragraph'>{props.subHeading2}</h6></>

                        )}
                        
                    </div>
                </div>
            </div> : null}
    </>
}

export default OrderEditModal