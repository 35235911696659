import React from "react";
import { Helmet } from "react-helmet";
import { userActions } from "../_actions";
import betagoapplicationname from "./betagroLogin.svg";
import { ReactComponent as InfoIcon } from "./info-circle.svg";
import { Header } from "../Header";
import { Footer } from "../Footer";
import OtpInput from "./lib";
import "./styles.css";
import Alert from "react-bootstrap/Alert";
import { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../SharedComponent/Loader/Loader";
import { FullscreenExit } from "@mui/icons-material";
import WarningIcon from '../SVG/WarningIcon.svg';

const OneTimePassword = (props) => {
  const location = useLocation();
  const LoginDetails = location?.state;
  // const { otpDetail } = LoginDetails;
  const  otpDetail  = LoginDetails?.otpDetail;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userState = useSelector((state) => state.users);
  const [loading, setLoading] = useState(true);

  const [otp, setOtp] = useState();
  const [numInputs, setNumInputs] = useState(6);
  const [separator, setSeparator] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasErrored, setHasError] = useState(false);
  const [isInputNum, setIsInputNum] = useState(false);
  const [isInputSecure, setIsInputSecure] = useState(false);
  const [minLength, setMinLength] = useState(0);
  const [maxLength, setMaxLength] = useState(40);
  const [placeholder, setPlaceholder] = useState("");
  const [show, setShow] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  const isNull = (value) => {
    return value === undefined || value === "" || value === null;
  };
  const clearOtp = () => {
    setOtp("");
  };
  useEffect(() => {
    setLoading(false);
    if (isNull(location.state)) {
      <Redirect to="/loginfirsttime" />;
    }
    if (!isNull(userState.otpVerificationError)) {
      setHasError(true);
      setLoading(false);
    } else {
      setHasError(false);
      setLoading(false);
    }
    if (!isNull(userState.otpVerification)) {
      localStorage.setItem(
        "x-auth-token",
        userState.otpVerification.data.token
      );
      setLoading(false);
      clearOtp();
    }
  }, [
    userState.otpVerification,
    userState.otpVerificationError,
    userState.firstTimeLoginwithPhone,
  ]);

  const handleOtpChange = (otp) => {
    setHasError(false);
    setOtp(otp);
    otp.length == 6 ? setIsConfirmDisabled(false) : setIsConfirmDisabled(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasError(false);
    setShow(true);
    if (otp.length < 6) {
      setHasError(true);
      return;
    }
    let payload = {
      userId: otpDetail?.data?.userId,
      otp: Number(otp),
    };
    setLoading(true);
    dispatch(userActions.otpVerification("otp", payload));
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    clearOtp();
    setLoading(true);
    setHasError(false);
    const payload = {
      type: "phone",
      phone: location.state && location.state.phone,
    };
    dispatch(userActions.firtTimeLogin("phone", payload));
  };

  return (
    <>
      <Helmet>
        <title>Betagro OTP Verification</title>
      </Helmet>
      <div className="headerFixed">
      <Header />
      </div>
      {loading && <Loader />}
      <div className="otp-form-container-outer headerFixed-margin">
        <div className="otp-form-container">
          <form onSubmit={handleSubmit}>
            <div className="otp-form-container-headLogo">
              <img src={betagoapplicationname} alt="React Logo" className="hidden-xs"/>
            </div>
            <h2>{t("OTPVerification.label")}</h2>
            <div className="form-group otp-form-otpSection">
              <label>
                {t("EntertheOTPsentto.label")}{" "}
                {!isNull(location.state) &&
                  location.state.phone &&
                  location.state.phone.substring(0, 2) +
                    "xxxxxx" +
                    location.state.phone.substring(8, 10)}
              </label>
              <OtpInput
                inputStyle="inputStyle"
                numInputs={numInputs}
                isDisabled={isDisabled}
                hasErrored={hasErrored}
                errorStyle="error"
                onChange={handleOtpChange}
                separator={<span>{separator}</span>}
                isInputNum={isInputNum}
                isInputSecure={isInputSecure}
                shouldAutoFocus
                value={otp}
                placeholder={placeholder}
              />
              {hasErrored && (
                <Alert
                  variant="danger"
                  className="alert-error-lbl wrongOTP_alert"
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    border: "0px",
                  }}
                >
                  <Alert.Heading style={{ textAlign: "center", fontSize: "20px", color: "#EF3145", marginTop: "10px"}}>
                  <img src={WarningIcon} className="mr-2"/>{t("WrongOTP.label")}
                  </Alert.Heading>
                </Alert>
              )}
              <div className="not-recieve-otp">
                <h6 className="not-recieved-text">
                  {t("mailnotreceive.label")}
                  <span onClick={handleResendOtp} className="resend-text">&nbsp;{t("resend.label")}</span>
                </h6>
              </div>
            </div>
            <div className="enterOtp_btn">
              <button disabled={isConfirmDisabled}>{t("Confirm.label")}</button>
            </div>
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
            <br className="visible-sm" />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
export { OneTimePassword as OneTimePassword };
