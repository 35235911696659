import Grid from '@mui/material/Grid';
import React from "react";
import Begagrow from "./Assets/SVG/Betagro.svg";
import Itohum from "./Assets/SVG/Itohum.svg";
import SPure from "./Assets/SVG/Spure.svg";
import './ShopByBrand.css';

const ShopByBrand = (props) => {
  const setting = [
    {
      breakpoint: 800,
      cols: 1,
      rows: 1,
      gap: 10,
      loop: false
    },
  ]
  return (
    <>
      <div className="sbb-carousel">
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={0}
        >
          <Grid item>
            <div className="sbb-carousel-item">
              <div className="sbb-carousel-item-img" >
                <img src={Begagrow} onClick={() => props.Onclick('BETAGRO')} />
                <p className="gray-line-shop-brand"></p>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className="sbb-carousel-item">
              <div className="sbb-carousel-item-img">
                <img src={SPure} onClick={() => props.Onclick('S-Pure')} />
                <p className="gray-line-shop-brand"></p>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className="sbb-carousel-item">
              <div className="sbb-carousel-item-img">
                <img src={Itohum} onClick={() => props.Onclick('Itoham')} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export { ShopByBrand as ShopByBrand };

