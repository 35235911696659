import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { productActions } from "../../_actions";
import { REACT_APP_BASE_URL } from "../../constant";
import checkMarkGreen from '../../images/circle-check.png';
import blankImage from "../../images/empty-image.png";
import promoBanner from "../../images/PromoIconRed.svg";
const AddToCartModalMobile = (props) => {
    const [open, setOpen] = useState(props.visible); // show or hide added to cart modal
    const lancode = localStorage.getItem("lancode");
    const [cartCount, setCartCount] = useState(0);
    const [cartQuantityCount, setCartQuantityCount] = useState(0);
    const [messageforcartlimit, setMessageforcartlimit] = useState("");
    const cartData = useSelector((state) => state.product);
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = JSON.parse(sessionStorage.getItem("user"));
    const { t } = useTranslation();
    const isNull = (value) => {
        return value == "" || value == undefined || value == null;
    };

    // handling close added to cart modal
    const handleClose = () => {
        setOpen(false);
    }


    React.useEffect(() => {
        let quantitycount = 0;
        let payload = {};
        isNull(cartData.cartDetails) &&
            dispatch(productActions.getCartData("payload", payload));

        if (!isNull(cartData.cartDetails)) {
            if (cartData.cartDetails.data.length === 30) {
                setMessageforcartlimit("Cart limit Reached of 30 Items, Remaining , Not Added");
            }
            setCartCount(cartData.cartDetails.data.length);

            cartData.cartDetails.data.map(items => {
                quantitycount = quantitycount + items.cartQuantity;
            });

            setCartQuantityCount(quantitycount);
        }

    }, [props.product, cartData.cartDetails]);

    const redirecttocart = () => {
        if (!isNull(userData)) {
            history.push("/mycart");
        } else {
            localStorage.setItem('prevPath', '/mycart')
            history.push("/login");
        }
    };
    return <>
        <div className='common-add-to-cart-modal-mobile-main-container common-display-block-mobile'>
            <div className='common-add-to-cart-modal-mobile-inner-container'>
                <div className='common-add-to-cart-modal-gray-line' />
                <div className='d-flex flex-row align-items-center mt-4'>
                    <img src={checkMarkGreen} className='common-add-to-cart-modal-mobile-check-image' />
                    <h1 className='common-add-to-cart-modal-mobile-add-to-cart-heading'>{t("AddedtoCart.label")}</h1>
                </div>
                <div className='common-add-to-cart-modal-gray-inner-line' />
                <div className='row align-items-center'>
                    <div className='col-md-3 col-sm-3 col-3'>
                        <div className='common-add-to-cart-modal-product-border'>
                            <div className="common-add-to-cart-image-badge">
                                {props.product && props.product.typeCode === "2" && (
                                    <img alt="" src={promoBanner} className='common-add-to-cart-promo-banner' />
                                )}
                                {props.product && props.product.typeCode === "3" && (
                                    <div className="promo-img-badge1">
                                        <img alt="" src='../NewItemYellow.svg' className='common-add-to-cart-promo-banner' />
                                    </div>

                                )}
                            </div>
                            <img src={
                                (props.product.picture && props?.product?.picture.split(",")[0] && props?.product?.picture.split(",")[0] != undefined && props?.product?.picture.split(",")[0] != '') ?
                                    (REACT_APP_BASE_URL +
                                        "/api/download?" +
                                        props?.product?.picture.split(",")[0]
                                        ? REACT_APP_BASE_URL +
                                        "/api/download?" +
                                        props?.product?.picture.split(",")[0]
                                        : blankImage)
                                    : blankImage
                            } className='common-add-to-cart-modal-product-image' />

                        </div>

                    </div>
                    <div className='col-md-5 col-sm-5 col-5 p-0'>
                        <h1 className='common-add-to-cart-modal-product-heading'>{props.product && lancode === "en" ? props.product.consumerNameEN : props.product.consumerNameTH}</h1>
                    </div>
                    <div className='col-md-4 col-sm-4 col-4 pl-0'>
                        <h1 className='common-add-to-cart-modal-product-price'>฿ {(props.product && (props.priceofproductchosen > 0)) ? props.priceofproductchosen : props.product.price1Scale1}.00 </h1>
                        <h1 className='common-add-to-cart-modal-product-quantity'>x {props.quantity}</h1>
                    </div>
                </div>
                <div className='common-add-to-cart-modal-view-cart-button' onClick={() => redirecttocart()}>
                    <span>{t("Viewcart.label")} &nbsp;({cartQuantityCount > 0 ? <span>{cartQuantityCount}</span> : <span>{props.quantity}</span>})</span>
                </div>
                <h1 className='common-add-to-cart-modal-continue-shopping-heading' onClick={() => props.close()}>
                    {t("Continueshopping.label")}
                </h1>
                <div className='common-add-to-cart-modal-continue-shopping-bottom-line' />
            </div>
        </div>

    </>
}
export default AddToCartModalMobile;