import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";

import Card from "@mui/joy/Card";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { userActions } from "../_actions";
import Eye from "../images/Eye.svg";
import betagoapplicationname from "../images/betagroLogin.svg";
import CrossEye from "../images/crossEye.svg";
// import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LoginPageBanner } from "../LoginPageBanner";
import Loader from "../SharedComponent/Loader/Loader";
import { Base64 } from '../_helpers/commanFunction';
import { REACT_APP_BASE_URL } from "../constant";
import "./LoginPage.css";
const defaultFormFields = {
  phone: "",
  password: "",
};

const LoginPage = () => {
  // const [formFields, setFormFields] = useState(defaultFormFields);
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [phoneErr, setPhoneErr] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState("password");
  const [submitted, setSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showMePassword, setShowMePassword] = useState(false);
  const [isADLogin, setIsADLogin] = useState(true)
  const [isMobileLogin, setIsMobileLogin] = useState(true)
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const inputRef = useRef(null);

  const inputRef2 = useRef(null);

  const { t } = useTranslation();
  const history = useHistory();
  // const { phone, password } = formFields;

  const userState = useSelector((state) => state.users);

  const dispatch = useDispatch();
  const location = useLocation()
  useEffect(() => {
    setLoading(false);
    if (
      userState?.userLogin !== null &&
      userState?.userLogin?.status === 200 &&
      userState?.userLogin?.data !== null
    ) {
      history.push("/");
    } else if (
      userState?.userLogin !== null &&
      userState?.userLogin?.response?.status !== 200
    ) {
      setPhoneErr(true);
      setLoading(false);
    }

    return () => {
      dispatch(userActions.unMountLogin());
    }

  }, [userState.userLogin]);

  useEffect(() => {
    if (inputRef) {
      inputRef.current.addEventListener('focus', () => {
        inputRef.current.classList.add('custom-focus');
      });

      inputRef.current.addEventListener('blur', () => {
        inputRef.current.classList.remove('custom-focus');
      });
    }

    if (inputRef2) {
      inputRef2.current.addEventListener('focus', () => {
        inputRef2.current.classList.add('custom-focus');
      });

      inputRef2.current.addEventListener('blur', () => {
        inputRef2.current.classList.remove('custom-focus');
      });
    }
    setIsMobileLogin(false)
  }, []);

  // useEffect( () => {

  //   inputRef2.current.addEventListener('focus', () => {
  //     inputRef.current.classList.add('custom-focus1');
  //   });

  //   inputRef2.current.addEventListener('blur', () => {
  //     inputRef.current.classList.remove('custom-focus1');
  //   });

  // }, []);

  useEffect(() => {
    // const phone = localStorage.getItem('phone');
    // const password = localStorage.getItem('password');
    const keepMeLoggedIn = localStorage.getItem('keepMeLoggedIn');

    if (keepMeLoggedIn) {
      // setFormFields({ phone, password });
      // setPhone(phone)
      // setPassword(password)
      setKeepMeLoggedIn(true);
    }

  }, []);

  const resetFormFields = () => {
    // setFormFields(defaultFormFields);
  };

  const signInwithPhone = (phone, password) => {
    const payload = {
      phone: phone,
      password: password,
    };
    setLoading(true);
    dispatch(userActions.login("phone", payload));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (keepMeLoggedIn) {
      localStorage.setItem('phone', phone);
      localStorage.setItem('loginType', Base64.encode(password.toString()));
      localStorage.setItem('keepMeLoggedIn', keepMeLoggedIn);
    } else if (!keepMeLoggedIn) {
      localStorage.removeItem('phone', phone);
      localStorage.removeItem('loginType', password);
      localStorage.removeItem('keepMeLoggedIn', keepMeLoggedIn);
    }
    if (!phone || !password) {
      setPhoneErr(true);
    } else {
      try {
        signInwithPhone(phone, password);
        // resetFormFields();
      } catch (error) {
        setSubmitted(true);
      }
    }
  };

  const handleMouseDown = () => {
    setShowPassword(true);
    setType("text");
  };

  const handleMouseUp = () => {
    setShowPassword(false);
    setType("password");
  };

  const handleChange = (event) => {
    setPhoneErr(false);
    const { name, value } = event.target;
    const re = /^(([^<>()\[\]\\,;:\s"]+))/
    if (event.target.name === "phone") {
      if (!re.test(event.target.value) && event.target.value !== "") { return; }
      //if (isNaN(event.target.value)) { return; }

      setPhone(event.target.value);
    }
    // setFormFields(formFields.password);
    if (event.target.name === "password")
      setPassword(event.target.value.replace(/[^0-9a-zA-Z]+/ig, ""));
  };

  const handleShowPassowrd = () => {
    setShowMePassword(!showMePassword);
  }

  //   const toggleChange = () => {
  //     setKeepMeLoggedIn(!keepMeLoggedIn);
  //   };
  const loginAD = (e) => {
    window.location.replace(REACT_APP_BASE_URL + "/api/users/loginAD?continue=" + window.location.origin)
  }
  return (
    <>
      <Helmet>
        <title>Betagro Log in</title>
      </Helmet>
      {loading && <Loader />}
      <div className="headerFixed">
        <Header />
      </div>
      <div className="LogIn-Card LogIn-Card-margin">
        <Card>
          <div className="row logincard">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 loginImage">
              {/*  <img src={loginpagepic} /> */}
              <LoginPageBanner />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 loginInput">
              <div className="form-main-container">
                <div className="loginheaderimage hidden-sm">
                  <img
                    src={betagoapplicationname}
                    alt="Betagro Logo"
                    style={{ marginTop: "30px" }}
                    data-testid="betagro-shop-logo"
                  />
                </div>
                <h2 data-testid="login-heading">{t("Login.label")}</h2>
                {isADLogin && <div>
                  <button
                    className="loginBtn"
                    data-testid="login-button"
                    type="button"
                    onClick={(e) => {
                      setIsADLogin(false)
                      setIsMobileLogin(true)
                    }}
                  >
                    {t("LoginMobile.label")}
                  </button>
                  <div className="firstTimeLogin">
                    <br className="hidden-sm" />
                    <br className="hidden-sm" />
                    <Link
                      className="first-time-login-text"
                      data-testid="first-time-login-link"
                      onClick={loginAD}
                      to={location.pathname}
                    >
                      {t("LoginAD.label")}
                    </Link>
                    <br className="visible-sm" />
                  </div>
                </div>}
                {isMobileLogin && <div>
                  <form onSubmit={handleSubmit} method="post" style={{ position: "relative" }}>
                    <div className={"form-group"}>
                      <p htmlFor="phone" className="input-label-st">
                        {t("phoneno.label")}
                      </p>
                      <input
                        data-testid="form-field-phone"
                        ref={inputRef2}
                        type="text"
                        // className={
                        //   "form-control" + (phoneErr ? " is-invalid" : "")
                        // }
                        className={
                          "form-control" + (phoneErr ? " is-invalid" : "")
                        }
                        placeholder={"081-234-5678"/*t("Enterphonenumber.label")*/}
                        name="phone"
                        value={phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={"form-group login-forgotPW"}>
                      <p htmlFor="password" className="input-label-st">
                        {t("password.label")}
                      </p>
                      <input
                        data-testid="form-field-password"
                        ref={inputRef}
                        type={showMePassword ? "text" : "password"}
                        className={"form-control" + (phoneErr ? " is-invalid" : "")}
                        placeholder={t("Enterpassword.label")}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        maxLength="12"
                      />

                      <div onClick={handleShowPassowrd}>
                        {showMePassword ? (
                          <img src={CrossEye} alt="eye" className={phoneErr ? "passwordIconShowInValid" : "passwordIconShow"} />
                        ) : (
                          <img src={Eye} alt="crosseye" className={phoneErr ? "passwordIconShowInValid" : "passwordIconShow"} />
                        )}
                      </div>
                      {phoneErr && (
                        <div className="invalid-feedback login-invalid-feedback">
                          {t("invalidphoneorpassword.label")}
                        </div>
                      )}

                      <div className="loginForgot">
                        <input
                          data-testid="checkbox-keep-logged-in"
                          type="checkbox"
                          id="keepmeloggedin"
                          name="keepmeloggedin"
                          value="keepmeloggedin"
                          checked={keepMeLoggedIn}
                          onChange={() => setKeepMeLoggedIn(!keepMeLoggedIn)}
                        />
                        <div className="loggin-text">
                          <span> &nbsp; &nbsp;{t("Keepmeloggedin.label")}</span>
                          <Link className="forgot-password" to="/forgotpassword">{t("Forgotpassword?.label")}</Link>
                        </div>
                      </div>
                    </div>
                    <button
                      className="loginBtn"
                      type="submit"
                      data-testid="login-button"
                    >
                      {t("Login.label")}
                    </button>
                  </form>
                  <div className="firstTimeLogin">

                    <br className="hidden-sm" />
                    <br className="hidden-sm" />
                    <Link
                      to="/loginfirsttime"
                      className="first-time-login-text"
                      data-testid="first-time-login-link"
                    >
                      {t("FirstTimeLogin.label")}
                    </Link>
                    <br className="visible-sm" />
                  </div>
                </div>}
              </div>
            </div>
          </div>
          {/*
                    <div className="col-md-12">
                        <form name="form" onSubmit={this.handleSubmit}>
                            <center><table ><tr><td><img src={loginpagepic} style={{ width: 250, height: 250 }} /></td><td>
                                <table width="100%" >
                                    <tr><td> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <img src={betagoapplicationname} alt="React Logo" style={{ width: 150, height: 25 }} /></td></tr>
                                    <tr><td><h2>Log in</h2></td></tr>
                                    <tr><td>
                                        <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                            <label htmlFor="username" style={{ alignItems: 'left', }}>Phone number</label>
                                            <input type="text" className="form-control" placeholder="000-000-0000" name="username" value={username} onChange={this.handleChange} />
                                            {submitted && !username &&
                                                <div className="help-block">Username is required</div>
                                            }
                                        </div></td></tr><tr><td>
                                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                                <label htmlFor="password" style={{ alignItems: 'left', }}>Password</label>
                                                <input type="password" className="form-control" placeholder="Enter password" name="password" value={password} onChange={this.handleChange} />
                                                {submitted && !password &&
                                                    <div className="help-block">Password is required</div>
                                                }

                                       

                                            </div></td></tr><tr>
                                        <td><input type="checkbox" id="keepmeloggedin" name="keepmeloggedin" value="keepmeloggedin" 
                                         defaultChecked={this.state.isChecked}
                                         onChange={this.toggleChange} />&nbsp;&nbsp;&nbsp;&nbsp;Keep me logged in &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to="/register" className="btn btn-link" style={{ color: 'green' }}><u>Forgot password ? </u></Link></td></tr>
                                    <tr><td>
                                        <div className="form-group">
                                            <center>  <img src={loginbutton} alt="React Logo" style={{ width: 300, height: 40 }} /></center>
                                            {loggingIn &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                            <center><Link to="/register" className="btn btn-link" style={{ color: 'green' }}><u>First Time Login.</u></Link></center>
                                        </div></td></tr></table></td></tr></table></center>

                        </form>
                    </div>*/}
        </Card>
      </div>
      <Footer />
    </>
  );
};

export { LoginPage as LoginPage };

// class LoginPage extends React.Component {
//     constructor(props) {
//         super(props);

//         // reset login status
//         this.props.logout();

//         this.state = {
//             username: '',
//             password: '',
//             submitted: false,
//             isChecked: true,
//         };

//         this.handleChange = this.handleChange.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);
//     }

//     toggleChange = () => {
//         this.setState({
//             isChecked: !this.state.isChecked,
//         });
//     }

//     handleChange(e) {
//         const { name, value } = e.target;
//         this.setState({ [name]: value });
//     }

//     handleSubmit(e) {
//         e.preventDefault();

//         this.setState({ submitted: true });
//         const { username, password } = this.state;
//         if (username && password) {
//             this.props.login(username, password);
//         }
//     }

//     render() {
//         const { loggingIn } = this.props;
//         const { username, password, submitted } = this.state;
//         return (
//         );
//     }
// }

// function mapState(state) {
//     const { loggingIn } = state.authentication;
//     return { loggingIn };
// }

// const actionCreators = {
//     login: userActions.login,
//     logout: userActions.logout
// };

// const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
// export { connectedLoginPage as LoginPage };
