import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import Carousel from 'react-bootstrap/Carousel';
import { ReactComponent as CartIcon } from "../images/cart-icon.svg"
import BrandComponent from './BrandComponent';
import { ShopByBrand } from '../ShopByBrand';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeroBanner1 from '../images/HeroBanner1.jpg';
import HeroBanner2 from '../images/HeroBanner2.jpg';
import HeroBanner3 from '../images/HeroBanner3.jpg';
import HeroBanner4 from '../images/HeroBanner4.jpg';
import HeroBanner5 from '../images/HeroBanner5.jpg';

import Heromobile1 from '../images/Herobannermobileoriginal1.png';
import Heromobile2 from '../images/Herobannermobileoriginal2.png';
import Heromobile3 from '../images/HerobannerMobileoriginal3.jpg';
import Heromobile4 from '../images/HerobannerMobileoriginal4.jpg';
import Heromobile5 from '../images/Herobannermobileoriginal5.jpg';
import { isMobile } from 'react-device-detect';
import './HeroBanner.css';
import { GreaterThan, SmallerThan } from "../CommonStyle/ExportSVGImage";



const HeroBanner = () => {
    const [index, setIndex] = React.useState("0");
    const history = useHistory()
    const handleSelect = (selectedIndex, e) => {
        this.setState({ index: selectedIndex + 1 })
    };

    const OnClick = (brand) =>{
        history.push('/allproducts',{brand:[brand]})
    }

    return (
        <>
            <Carousel slide={false} pause={false} prevIcon={<SmallerThan />} nextIcon={<GreaterThan />} /* fade activeIndex={this.index} */ /* onSelect={this.handleSelect} */ className="homepageHeroBanner">
                <Carousel.Item>
                    <div className="header-container" data-testid="hero-banner-screen-loading">
                        {isMobile ? <img
                            className="d-block w-100"
                            src={Heromobile1}
                            alt="First slide"
                            style={{ "margin": "auto", height: "500px" }}
                        /> : <img
                            className="d-block w-100 landing-page-top-banner-image"
                            src={HeroBanner1}
                            alt="First slide"
                        />}
                        {/* <div className="wrapper">

                            <div className="hero-slide-text">
                                <div className="label-text-bring">
                                    <p className="label-store-add">{t("Webringthestoretoyourdoor.label")}!</p>
                                    <p className="label-store-discount">{t("Now!get25%offonallitems.label")}</p>
                                </div>
                                <div className="promo-btn-rt show-now-btn">
                                    <button className="promo-btn-addtocart">
                                        <img alt="" src="../AddToCartWhite.svg" />
                                        {t("ShopNow.label")}
                                    </button>
                                </div>

                            </div>
                        </div> */}
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="header-container">
                        {isMobile ? <img
                            className="d-block w-100"
                            src={Heromobile2}
                            alt="First slide"
                            style={{ "margin": "auto", height: "500px" }}
                        /> : <img
                            className="d-block w-100 landing-page-top-banner-image"
                            src={HeroBanner2}
                            alt="First slide"
                        />}
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="header-container">
                        {isMobile ? <img
                            className="d-block w-100"
                            src={Heromobile3}
                            alt="First slide"
                            style={{ "margin": "auto", height: "500px" }}
                        /> : <img
                            className="d-block w-100 landing-page-top-banner-image"
                            src={HeroBanner3}
                            alt="First slide"
                        />}

                    </div>

                </Carousel.Item>
                <Carousel.Item>
                    <div className="header-container">
                        {isMobile ? <img
                            className="d-block w-100"
                            src={Heromobile4}
                            alt="First slide"
                            style={{ "margin": "auto", height: "500px" }}
                        /> : <img
                            className="d-block w-100 landing-page-top-banner-image"
                            src={HeroBanner4}
                            alt="First slide"
                        />}

                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="header-container">
                        {isMobile ? <img
                            className="d-block w-100"
                            src={Heromobile5}
                            alt="First slide"
                        /> : <img
                            className="d-block w-100 landing-page-top-banner-image img-fluid"
                            src={HeroBanner5}
                            alt="First slide"
                        />}

                    </div>
                </Carousel.Item>
            </Carousel>
            <div className="shopByBrand-slider-section container-fluid">
                <ShopByBrand 
                Onclick={OnClick}/>
            </div>
        </>
    );
}




//const connectedHeroBanner = connect(mapState, actionCreators)(HeroBanner);
export { HeroBanner as HeroBanner };








































