import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import Card from '@mui/joy/Card';
import { Footer } from '../Footer';
import { Header } from '../Header';

import saucedge from '../images/saucedge.png';
import smokedpepper from '../images/smokedpepper.png';
import Button from 'react-bootstrap/Button';

import { BsCart3 } from "react-icons/bs";
import { RiHeart3Fill } from 'react-icons/ri';
import { MDBBtn } from 'mdb-react-ui-kit';
import { IncDecCounter } from './IncDecCounter';

class MyWishList extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        // this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            isChecked: true,



        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }


    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;
        return (<center>
            <Header />
            <table><tr><td><h2>My Wishlist</h2></td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td><h5>10 items</h5></td></tr></table>
            <Card variant="outlined" sx={{ width: 1010, backgroundColor: "white", border: "none", }}>
                <div className="col-md-12">
                    <form name="form" onSubmit={this.handleSubmit}>
                        <center>
                            <table width="100%">
                                <tr><td></td><td>Product</td><td></td><td>Unit Price</td></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={saucedge} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td><td>
                                    <IncDecCounter /><MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></td></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /><MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /> <MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /><MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /> <MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /> <MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /> <MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /> <MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /> <MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                                <tr><td colSpan={5}><hr></hr></td></tr>
                                <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /></td><td align="left"><img src={smokedpepper} alt="React Logo" style={{ width: 40, height: 40 }} /> </td><td>Product Description</td><td>$ 200.00</td>
                                    <IncDecCounter /> <MDBBtn filled rounded color='success' size='lg' > <BsCart3 /> Add to Cart </MDBBtn></tr>
                            </table>
                        </center>
                    </form>
                </div></Card>
            <Footer /></center>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedMyWishList = connect(mapState, actionCreators)(MyWishList);
export { connectedMyWishList as MyWishList };