import React, { useState } from 'react';
import './HeaderCheckout.css';
import Logo from './Assets/SVG/Logo.svg'
import CrossIcon from './Assets/SVG/CrossIcon.svg';
import { useTranslation } from "react-i18next";
import { useHistory,Link } from "react-router-dom";

import { useLocation } from "react-router-dom";

const HeaderCheckout = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [currentLocation, setCurrentLocation]=useState("");
    const [originalLocation, setOriginalLocation]=useState("");
    
    const lastLocation=(loc)=>{

history.push("/mycart", {from: "checkout"});

    }
  return (
    <>
    <div className="HeaderCheckout HeaderCheckoutPositionFixed">
    <div className="HeaderCheckout-lt">
        <Link to="/"><img src={Logo} className="hidden-sm"/></Link>
        <h4 className="visible-sm">{t("Checkout.label")}</h4>
        <img src={CrossIcon} className="visible-sm" onClick={() => [setOriginalLocation(history.location.pathname), lastLocation(history.location.pathname)]}/>
        </div>
        <div className="HeaderCheckout-rt hidden-sm">
        <img src={CrossIcon} onClick={() => [setOriginalLocation(history.location.pathname), lastLocation(history.location.pathname)]}/>
        </div>
    </div>
    </>
  )
}

export {HeaderCheckout as HeaderCheckout };