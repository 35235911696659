import React, { useState } from "react";
// import Carousel from "react-bootstrap/Carousel";
import Image1 from "./Assets/Image/Image1.png";
import Image2 from "./Assets/Image/Image2.png";
import Star from "./Assets/SVG/Star.svg";
import Img from "./Assets/SVG/Img.svg";
import user from "../images/user.png";
import reviewStar from "../images/review-star.png";
// import Carousel from "react-grid-carousel";
import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { isMobile } from "react-device-detect";

import Productrecipe1 from "./Assets/Image/Image1.png";
import Productrecipe2 from "./Assets/Image/Image2.png";


import Productmobile1 from "../images/PromotionRecipeMobile120x200Pix-04test2.png";
import Productmobile2 from "../images/PromotionRecipeMobile120x200Pix-05test2.png";
import Productmobile3 from "../images/PromotionRecipeMobile120x200Pix-06test2.png";
import ProductReviewImageOne from "../images/desktop-product-one.jpg";
import ProductReviewImageTwo from "../images/desktop-product-two.jpg";
import ProductReviewImageThree from "../images/desktop-product-three.jpg";

import "./ProductReview.css";

const ProductReview = (props) => {
  [
    {
      breakpoint: 991,
      cols: 2,
      rows: 1,
      gap: 10,
      loop: true,
      autoplay: 1000
    },
    {
      breakpoint: 768,
      cols: 1,
      rows: 2,
      gap: 10,
      loop: true,
      autoplay: 1000
    }
  ]
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [imageValue, setProductImage] = useState();
  const [ratingValue, setProductRating] = useState();
  const [ratingCountValue, setProductRatingCount] = useState();
  const [headingValue, setProductHeading] = useState();
  const [paragraphValue, setProductparagraph] = useState();
  const [dateValue, setProductDate] = useState();
  const [clientValue, setProductClientName] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: "0px 4px 20px rgb(0 0 0 / 8%)",
    borderRadius: 5,
    p: 2,
    '@media (max-width: 767px)': {
      width: "90%"
    },
  };
  
  
  return (
    <>
      <div className="container-fluid product-review-container">
        <div className="container product-review-container-mobile-screen">
          <div className="pr-carousel">
            <div className="pr-carousel-Head p-0">
              <img src={Star} alt="" />
              <h4>{t("ProductReview.label")}</h4>
            </div>
            <div className="product-review-desktop-main-container">
            <Carousel indicators={false}>
              <Carousel.Item>
                <div className="row product-review-shadow-main-container">
                  <div className="col-sm-4 cursor-pointer" onClick={() => [setProductImage(ProductReviewImageOne), props.openModalPR()]}>
                    <div className="product-review-image-main-shadow">
                      <img src={ProductReviewImageOne} className="img-fluid product-review-image" />
                    </div>
                  </div>
                  <div className="col-sm-4 cursor-pointer" onClick={() => [setProductImage(ProductReviewImageTwo), props.openModalPR()]}>
                    <div className="product-review-image-main-shadow">
                      <img src={ProductReviewImageTwo} className="img-fluid product-review-image" />
                    </div>
                  </div>
                  <div className="col-sm-4 cursor-pointer" onClick={() => [setProductImage(ProductReviewImageThree), props.openModalPR()]}>
                    <div className="product-review-image-main-shadow">
                      <img src={ProductReviewImageThree} className="img-fluid product-review-image" />
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
            </div>
            <div className="product-review-mobile-main-container">
            <Carousel
              interval={5000}
              slide={true}
              fade
              className="pr-carousel-container">
              <Carousel.Item>
                <div className="row product-review-shadow-main-container">
                  <div className="col-sm-4 cursor-pointer" onClick={() => [setProductImage(ProductReviewImageOne), props.openModalPR()]}>
                    <div className="product-review-image-main-shadow">
                      <img src={ProductReviewImageOne} className="img-fluid product-review-image" />
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="row product-review-shadow-main-container">
                  <div className="col-sm-4 cursor-pointer" onClick={() => [setProductImage(ProductReviewImageTwo), props.openModalPR()]}>
                    <div className="product-review-image-main-shadow">
                      <img src={ProductReviewImageTwo} className="img-fluid product-review-image" />
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="row product-review-shadow-main-container">
                  <div className="col-sm-4 cursor-pointer" onClick={() => [setProductImage(ProductReviewImageThree), props.openModalPR()]}>
                    <div className="product-review-image-main-shadow">
                      <img src={ProductReviewImageThree} className="img-fluid product-review-image" />
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
            </div>
            {props.openProductReview ? (
              <Modal
                open={props.openProductReview}
                onClose={props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div onClick={() => props.openModalPR()}>
                    <img src={imageValue} className="img-fluid" />
                  </div>
                </Box>
              </Modal>
            ) : (
              ""
            )}

            {/* THIS CODE IS COMMENT FOR FUTURE REFERENCE START FROM HERE */}
            {/* <Carousel
             dot
             interval={5000}
             slide={true}
             fade
              className="pr-carousel-container"
            >
              <Carousel.Item className="d-flex flex-wrap align-items-center justify-content-center">
                <div
                  className="pr-carousel-item"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile1 : Productrecipe1),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile1} />
                    ) : (
                      <img src={Productrecipe1} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry s
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item hidden-sm"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile2 : Productrecipe2),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile2} />
                    ) : (
                      <img src={Productrecipe2} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item hidden-md"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile3 : Productrecipe1),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile3} />
                    ) : (
                      <img src={Productrecipe1} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="pr-carousel-item hidden-xs"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile1 : Productrecipe2),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile1} />
                    ) : (
                      <img src={Productrecipe2} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry s
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile2 : Productrecipe1),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile2} />
                    ) : (
                      <img src={Productrecipe1} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item hidden-md"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile3 : Productrecipe2),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile3} />
                    ) : (
                      <img src={Productrecipe2} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="pr-carousel-item hidden-sm"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile1 : Productrecipe1),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile1} />
                    ) : (
                      <img src={Productrecipe1} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry s
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item hidden-sm"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile2 : Productrecipe2),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile2} />
                    ) : (
                      <img src={Productrecipe2} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile3 : Productrecipe1),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile3} />
                    ) : (
                      <img src={Productrecipe1} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="pr-carousel-item"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile1 : Productrecipe2),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile1} />
                    ) : (
                      <img src={Productrecipe2} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry s
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item hidden-sm"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile2 : Productrecipe1),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile2} />
                    ) : (
                      <img src={Productrecipe1} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item hidden-md"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile3 : Productrecipe2),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile3} />
                    ) : (
                      <img src={Productrecipe2} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="pr-carousel-item hidden-sm"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile1 : Productrecipe1),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile1} />
                    ) : (
                      <img src={Productrecipe1} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry s
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile2 : Productrecipe2),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile2} />
                    ) : (
                      <img src={Productrecipe2} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="pr-carousel-item hidden-md"
                  onClick={() => [
                    props.openModalPR(),
                    setProductImage(isMobile ? Productmobile3 : Productrecipe1),
                    setProductRating(4.8),
                    setProductRatingCount(130),
                    setProductHeading(t("ChickenBreastwithSkin.label")),
                    setProductDate("July 13, 2022"),
                    setProductClientName("Tara Gibson"),
                    setProductparagraph(
                      "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry sstandard dummy text ever since the 1500s"
                    ),
                  ]}
                >
                  <div className="pr-carousel-item-img">
                    {isMobile ? (
                      <img src={Productmobile3} />
                    ) : (
                      <img src={Productrecipe1} />
                    )}
                  </div>
                  <div className="pr-carousel-item-rating">
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <img src={reviewStar} className="startImg" />
                    <p className="rating-count">&nbsp;4.8</p>&nbsp;
                    <p className="total-rating">(130)</p>
                  </div>
                  <div className="pr-carousel-item-text">
                    <h4>{t("ChickenBreastwithSkin.label")}</h4>
                    <p className="product-reviwing-paragraph">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="pr-carousel-item-profile">
                    <div className="pr-carousel-item-profile-lt">
                      <img src={user} />
                    </div>
                    <div className="pr-carousel-item-profile-rt">
                      <h4>Tara Gibson</h4>
                      <p>July 13, 2022</p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel> */}
            {/* {props.openProductReview ? (
              <Modal
                open={props.openProductReview}
                onClose={props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div onClick={() => props.openModalPR()}>
                    <div className="pr-carousel-item-img">
                      <img src={imageValue} />
                    </div>
                    <div className="pr-carousel-item-rating">
                      <img src={reviewStar} className="startImg" />
                      <img src={reviewStar} className="startImg" />
                      <img src={reviewStar} className="startImg" />
                      <img src={reviewStar} className="startImg" />
                      <img src={reviewStar} className="startImg" />
                      <p className="rating-count">&nbsp;{ratingValue}</p>&nbsp;
                      <p className="total-rating">({ratingCountValue})</p>
                    </div>
                    <div className="pr-carousel-item-text">
                      <h4>{headingValue}</h4>
                      <p className="product-reviwing-paragraph">
                        {paragraphValue}
                      </p>
                    </div>
                    <div className="pr-carousel-item-profile">
                      <div className="pr-carousel-item-profile-lt">
                        <img src={user} />
                      </div>
                      <div className="pr-carousel-item-profile-rt">
                        <h4>{clientValue}</h4>
                        <p>{dateValue}</p>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>
            ) : (
              ""
            )} */}
            {/* THIS CODE IS COMMENT FOR FUTURE REFERENCE END */}
          </div>
        </div>
      </div>
    </>
  );
};

export { ProductReview as ProductReview };
