import { Helmet } from "react-helmet";
import { CustomBreadcrumb } from "../CustomBreadcrumb";
import { Footer } from "../Footer";
import { Header } from "../Header";
// import Carousel from "react-grid-carousel";
import { useTranslation } from "react-i18next";
// import ProductImg from "./Assets/Image/ProductImg.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AddToCart from "./Assets/SVG/AddToCart.svg";
import WishList from "./Assets/SVG/WishList.svg";
import WishListGreen from "./Assets/SVG/WishListGreen.svg";
import WishListGrey from "./Assets/SVG/WishListGrey.svg";
import WishListRed from "./Assets/SVG/WishListRed.svg";
import WishListWhite from "./Assets/SVG/WishListWhite.svg";
// import { REACT_APP_BASE_URL } from "../constant";

import { RecommendedForYou } from "../RecommendedForYou";
import { REACT_APP_BASE_URL } from "../constant";
import promoBanner from "../images/PromoIconRed.svg";

import { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../SharedComponent/Loader/Loader";
import { productActions } from "../_actions";
import "./ProductDetailsCustomer.css";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router-dom";

import AddToCartModalMobile from "../ModalScreens/AddToCartModal/addToCardMobileModal";
import { AddToCartModal } from "../ModalScreens/AddToCartModal/addToCardModal";
import ApiList from "../_constants/const.api";
import ApiProvider from "../_services/ApiProvider";
import minusProdouctButton from "../images/minus-product-button.png";
import addProdouctButton from "../images/plus-product-button.png";

const ProductDetailsCustomer = (props) => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);
  const input1 = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lancode = localStorage.getItem("lancode");
  const productState = useSelector((state) => state.product);
  // const cartAdded = useSelector((state) => state.cartAdded);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [packSizeData, setPackSizeData] = useState();
  const [productType, setProductType] = useState();
  const [totalCost, setTotalCost] = useState();
  const location = useLocation();
  const history = useHistory();
  const [cartQuantity, setCartQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(0);
  const [addedToCardModal, setAddedToCardModal] = useState(false);
  const [countonclicksonaddtocartbutton, setCountonclicksonaddtocartbutton] =
    useState(0);
  const [wishlistBtnStatus, setWishlistBtnStatus] = useState(false);

  function showModal(item) {
    setOpen(!open);
  }

  const isNull = (value) => {
    return value == "" || value == undefined || value == null;
  };
  const isNullorZero = (value) => {
    return (
      value == "" ||
      value == undefined ||
      value == null ||
      value == false ||
      value == 0
    );
  };
  const paramsName = props?.location?.pathname.split("/").slice(1);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    if (!isNull(productState.productDetail)) {
      setLoading(false);
      setProductData(productState.productDetail.data);
      if (
        productState.productDetail.data.cartQuantity > 0 &&
        productState.productDetail.data.articaleCode == paramsName[1]
      ) {
        setCartQuantity(productState.productDetail.data.cartQuantity);
      }
      const uniquePackSize = new Set();
      const selectedPackSize =
        props.location.state.items.selectValues.filter((p) => {
          const packSizeValue = lancode == "en" ? p.packSizeEN : p.packSizeTH;
          if (!uniquePackSize.has(packSizeValue)) {
            uniquePackSize.add(packSizeValue);
            return p;
          }
        });
      selectedPackSize.map((item, index) => {
        let flag = true;
        if (lancode == "en") {
          if (item.packSizeEN == productState.productDetail.data.packSizeEN) {
            setProductTypeOptions(item.packSizeEN);
            flag = false;
            return item;
          }
        } else {
          if (item.packSizeTH == productState.productDetail.data.packSizeTH) {
            setProductTypeOptions(item.packSizeTH);
            flag = false;
            return item;
          }
        }
        return item;
      });
      selectedPackSize.sort(
        (a, b) => parseInt(a.packSizeEN) - parseInt(b.packSizeEN)
      );
      setPackSizeData(selectedPackSize);
    }
  }, [productState.productDetail]);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (isNull(localStorage.getItem("authorization"))) {
      history.push("/login");
    }
    let payload = {
      storeCode: location?.state?.storeCode,
      // articleCode: location.state.articaleCode
      articleCode: paramsName && paramsName[1],
    };
    isNull(productData) &&
      dispatch(productActions.getProductDetails("payload", payload));
    if (!isNull(productState.productDetail)) {
      setLoading(false);
      setWishlistBtnStatus(false);
      setProductData(productState.productDetail.data);
      if (
        productData.productPrice == 0 ||
        isNullorZero(productData.productPrice)
      ) {
        getSpecialAndScalePrice();
      }
    }
  }, [productData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // getSpecialAndScalePrice();
  }, [productData]);

  const setProductTypeOptions = (packSize) => {
    const uniqueValue = new Set();
    let filteredRecords = [];
    if (packSize != null) {
      filteredRecords = props.location.state.items.selectValues.filter(
        (item) => {
          return lancode == "en"
            ? item.packSizeEN == packSize
            : item.packSizeTH == packSize;
        }
      );
    } else {
      filteredRecords = productState.productDetail.data.selectValues;
    }
    setProductType(filteredRecords);
  };

  const updateSelectedProductTypeValue = (item) => {
    let element = productType.map((value) => {
      return value.productId == item.productId
        ? { ...value, select: true }
        : { ...value, select: false };
    });
    setProductType(element);
  };

  const getSpecialAndScalePrice = () => {
    var temp = productData;
    // if(isNullorZero(temp.cartQuantity)){
    //   temp.cartQuantity=1
    // }
    // if (isNull(temp.cartQuantity)) {
    //   temp.productPrice=temp.price1Scale1
    //   setProductData({...temp})
    //   // setProductPrice(temp.price1Scale1)
    //   return
    // }
    let count = cartQuantity;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    // setCartQuantity(count)
    let price = temp.productPrice;
    if (isNull(temp.conditionAmount)) {
      if (
        Number(temp.quantity1Scale1) <= (count * packSize) &&
        Number(temp.quantity1Scale1) != 0
      ) {
        price = Number(temp.price1Scale1);
      }
      if (
        Number(temp.quantity2Scale2) <= (count * packSize) &&
        Number(temp.quantity2Scale2) != 0
      ) {
        price = Number(temp.price2Scale2);
      }
      if (
        Number(temp.quantity3Scale3) <= (count * packSize) &&
        Number(temp.quantity3Scale3) != 0
      ) {
        price = Number(temp.price3Scale3);
      }
      if (
        Number(temp.quantity4Scale4) <= (count * packSize) &&
        Number(temp.quantity4Scale4) != 0
      ) {
        price = Number(temp.price4Scale4);
      }
      if (
        Number(temp.quantity5Scale5) <= (count * packSize) &&
        Number(temp.quantity5Scale5) != 0
      ) {
        price = Number(temp.price5Scale5);
      }
      // if (count < Number(temp.quantity5Scale5)) {
      //   price = Number(temp.price5Scale5)
      // }
      temp.productPrice = price;
      setProductData({ ...temp });
      // setProductPrice(price)
    } else {
      temp.productPrice = temp.conditionAmount;
      setProductData({ ...temp });
      // setProductPrice(temp.conditionAmount)
    }
  };
  const priceCalc = (prod) => {
    var temp = prod;
    let count = (temp.cartQuantity) ? temp.cartQuantity : 1;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    let price = temp.price1Scale1;
    if (isNull(temp.conditionAmount)) {
      if (
        Number(temp.quantity1Scale1) <= (count * packSize) &&
        Number(temp.quantity1Scale1) != 0
      ) {
        price = Number(temp.price1Scale1);
      }
      if (
        Number(temp.quantity2Scale2) <= (count * packSize) &&
        Number(temp.quantity2Scale2) != 0
      ) {
        price = Number(temp.price2Scale2);
      }
      if (
        Number(temp.quantity3Scale3) <= (count * packSize) &&
        Number(temp.quantity3Scale3) != 0
      ) {
        price = Number(temp.price3Scale3);
      }
      if (
        Number(temp.quantity4Scale4) <= (count * packSize) &&
        Number(temp.quantity4Scale4) != 0
      ) {
        price = Number(temp.price4Scale4);
      }
      if (
        Number(temp.quantity5Scale5) <= (count * packSize) &&
        Number(temp.quantity5Scale5) != 0
      ) {
        price = Number(temp.price5Scale5);
      }
      // if (count < Number(temp.quantity5Scale5)) {
      //   price = Number(temp.price5Scale5)
      // }
    } else {
      price = temp.conditionAmount;
    }
    return price;
  };

  const AddItemToCart = async () => {
    let payload = {
      articaleCode: productData.articaleCode,
      storeCode: productData.storeCode,
      cartQuantity: cartQuantity,
    };
    let updatePD = [];
    for await (const i of productData.selectValues) {
      if (i.articaleCode === productData.articaleCode) {
        i.cartQuantity = cartQuantity;
      }
      updatePD.push(i);
    }
    productData.cartQuantity = cartQuantity;
    setProductData({ ...productData, selectValues: updatePD });
    ApiProvider.post(ApiList.API_URL_FOR_ADD_ITEM_TO_CART, payload).then(
      (response) => {
        dispatch(productActions.getCartData("payload", payload));
      }
    );
    // dispatch(productActions.addProductToCart("payload", payload))
  };

  const AddItemToWishList = (status) => {
    setWishlistBtnStatus(true);
    let message = status
    let payload = {
      articaleCode: productData.articaleCode,
      wishlistStatus: status,
      storeCode: productData.storeCode,
    };
    // dispatch(productActions.addProductToWishList("payload", payload));
    ApiProvider.post(ApiList.API_URL_FOR_ADD_ITEM_TO_WISHLIST, payload).then((response) => {
      let payload2 = {
        storeCode: payload.storeCode,
        articleCode: payload.articaleCode
      }
      dispatch(productActions.getProductDetails("payload", payload2));
      dispatch(
        productActions.getWishListInformation(
          "payload",
          {},
          `?limit=100000&skip=0`
        )
      );
    }
    );

  };

  const setUpCartandSpecialPrices = async (operation) => {
    var temp = productData;
    let count = cartQuantity;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    let price = isNullorZero(productData.productPrice) ? 0 : productData.productPrice;
    count = operation == "add" ? count + 1 : count > 1 ? count - 1 : count
    count >= 1 && setCartQuantity(count)
    if (isNull(temp.conditionAmount)) {
      if (
        Number(temp.quantity1Scale1) <= (count * packSize) &&
        Number(temp.quantity1Scale1) != 0
      ) {
        price = Number(temp.price1Scale1);
      }
      if (
        Number(temp.quantity2Scale2) <= (count * packSize) &&
        Number(temp.quantity2Scale2) != 0
      ) {
        price = Number(temp.price2Scale2);
      }
      if (
        Number(temp.quantity3Scale3) <= (count * packSize) &&
        Number(temp.quantity3Scale3) != 0
      ) {
        price = Number(temp.price3Scale3);
      }
      if (
        Number(temp.quantity4Scale4) <= (count * packSize) &&
        Number(temp.quantity4Scale4) != 0
      ) {
        price = Number(temp.price4Scale4);
      }
      if (
        Number(temp.quantity5Scale5) <= (count * packSize) &&
        Number(temp.quantity5Scale5) != 0
      ) {
        price = Number(temp.price5Scale5);
      }
      // if (count < Number(temp.quantity5Scale5)) {
      //   price = Number(temp.price5Scale5)
      // }
      setProductPrice(price);
    }
    temp.productPrice = price;
    // temp.cartQuantity=count
    setCartQuantity(count);
    for (let i = 0; i < temp.selectValues.length; i++) {
      if (temp.selectValues[i].articaleCode == temp.articaleCode) {
        temp.selectValues[i].cartQuantity = count;
        temp.selectValues[i].productPrice = price;
        temp.selectValues[i].wishlistStatus = temp.wishlistStatus;
      }
    }
    // setCartQuantity((temp.cartQuantity)?temp.cartQuantity:1);
    // setProductData({ ...temp })

    // Update the cart quantity in mobile screen
    // (window.innerWidth <= 768) && AddItemToCart(count)
    if (window.innerWidth <= 768) {
      if (cartQuantity === 1 && operation != "add") {
        count = 0
      }
      let payload = {
        "articaleCode": temp.articaleCode,
        "storeCode": temp.storeCode,
        "cartQuantity": count
      }
      temp.cartQuantity = (count > 0) ? count : null;
      setProductData({ ...temp });
      ApiProvider.post(ApiList.API_URL_FOR_ADD_ITEM_TO_CART, payload).then((response) => {
        dispatch(productActions.getCartData("payload", payload))
      }
      );
    } else {
      setProductData({ ...temp })
    }
  };

  // Handling added to cart modal close

  const addedToCartModalClose = () => {
    setAddedToCardModal(false);
  };
  return (
    <>
      {productData && productData.consumerNameEN && (
        <Helmet>
          <title>
            {" "}
            {productData && productData.consumerNameEN
              ? productData.consumerNameEN
              : ""}
          </title>
        </Helmet>
      )}

      <div className="headerFixed">
        <Header />
      </div>
      {/* <AddToCartModalMobile /> */}
      <div className="container-fluid cstmrProdDeatils headerFixed-margin" data-testid="product-category-loading">
        {loading && <Loader />}
        {!loading && (
          <div className="container">
            <CustomBreadcrumb props={productData} />
            <div className="row position-relative">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 sidenav">
                <div className="cstmrProdDeatilsCarousel">
                  <Carousel>
                    {productData &&
                      productData.picture &&
                      productData.picture.split(",").map((item) => {
                        return (
                          <div className="cstmrProdDeatilsCarousel-Img">
                            <div className="product-card-image-badge">
                              {productData && productData.typeCode === "2" && (
                                <img
                                  alt=""
                                  src={promoBanner}
                                  style={{ width: 80 }}
                                />
                              )}
                              {productData && productData.typeCode === "3" && (
                                /*  <div className="promo-img-badge1"> */
                                <img alt="" src="../NewItemYellow.svg" />
                                /*  </div> */
                                // <img alt="" src={promoBanner} style={{ width: 80 }} />
                              )}
                            </div>
                            <img
                              src={REACT_APP_BASE_URL + "/api/download?" + item}
                            />
                          </div>
                        );
                      })}
                  </Carousel>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 padding-lt main">
                <div className="cstmrProdDeatilsText">
                  <div className="cstmrProdDeatilsText-head">
                    {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && (
                      <h2>Article Code: {productData.articaleCode}</h2>
                    )}

                    <h3>
                      {productData && lancode === "en"
                        ? productData.consumerNameEN
                        : productData.consumerNameTH}
                    </h3>
                    <div className="cstmrProdDeatilsText-headPrice hidden-sm">
                      <h4>
                        ฿ {productData.productPrice}.00
                        <span>
                          {" "}
                          &nbsp;/
                          {productData && lancode === "en"
                            ? productData.saleUnitEN
                            : productData.saleUnitTH}
                        </span>
                      </h4>
                    </div>
                    <div className="cstmrProdDeatilsText-headPrice-count">
                      <div className="cstmrProdDeatilsText-headPrice">
                        <h4>
                          ฿ {productData.productPrice}.00
                          <span>
                            {" "}
                            &nbsp;/
                            {productData && lancode === "en"
                              ? productData.saleUnitEN
                              : productData.saleUnitTH}
                          </span>
                        </h4>
                      </div>
                      {!isNull(userData) &&
                        (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                        productData &&
                        !isNull(productData.stock) ? (
                        <span className="cstmrProdDeatilsText-quantity-inStock">
                          {productData.stock + " " + t("inStock.label")}
                        </span>
                      ) : (
                        (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && <span className="cstmrProdDeatilsText-quantity-outOfStock">
                          {t("OutofStock.label")}
                        </span>
                      )}{" "}
                    </div>
                  </div>
                  <div className="cstmrProdDeatilsText-desc_u ">
                    <h4>{t("PackSize.label")}</h4>
                    {packSizeData &&
                      packSizeData.map((item, index) => {
                        return (
                          <button
                            onClick={() => {
                              let temp = productData;
                              temp.picture = item.picture;
                              temp.price1Scale1 = item.price1Scale1;
                              temp.price2Scale2 = item.price2Scale2;
                              temp.price3Scale3 = item.price3Scale3;
                              temp.price4Scale4 = item.price4Scale4;
                              temp.price5Scale5 = item.price5Scale5;
                              temp.quantity1Scale1 = item.quantity1Scale1;
                              temp.quantity2Scale2 = item.quantity2Scale2;
                              temp.quantity3Scale3 = item.quantity3Scale3;
                              temp.quantity4Scale4 = item.quantity4Scale4;
                              temp.quantity5Scale5 = item.quantity5Scale5;
                              temp.typeCode = item.typeCode;
                              temp.saleUnitEN = item.saleUnitEN;
                              temp.saleUnitTH = item.saleUnitTH;
                              temp.articaleCode = item.articaleCode;
                              temp.cartQuantity = item.cartQuantity;
                              temp.wishlistStatus = item.wishlistStatus;
                              temp.stock = item.stock;
                              temp.consumerNameEN = item.consumerNameEN;
                              temp.packSize = item.packSize;
                              temp.packSizeEN = item.packSizeEN;
                              temp.productTypeEN = item.productTypeEN;
                              temp.instructionEN = item.instructionEN;
                              temp.aboutProductEN = item.aboutProductEN;
                              temp.shelfLifeEN = item.shelfLifeEN;
                              temp.otherProductEN = item.otherProductEN;
                              temp.consumerNameTH = item.consumerNameTH;
                              temp.packSizeTH = item.packSizeTH;
                              temp.productTypeTH = item.productTypeTH;
                              temp.aboutProductTH = item.aboutProductTH;
                              temp.instructionTH = item.instructionTH;
                              temp.shelfLifeTH = item.shelfLifeTH;
                              temp.otherProductTH = item.otherProductTH;
                              temp.conditionAmount = item.conditionAmount;
                              setProductTypeOptions(lancode == "en" ? item.packSizeEN : item.packSizeTH);
                              setCartQuantity(
                                item.cartQuantity ? item.cartQuantity : 1
                              );
                              temp.productPrice = priceCalc(item);
                              setProductData({ ...temp });
                            }}
                            className={`${item.packSizeEN == productData.packSizeEN
                              ? "cstmrProdDeatilsText-desc-focus"
                              : ""
                              }`}
                          >
                            {lancode === "en"
                              ? item.packSizeEN
                              : item.packSizeTH}
                          </button>
                        );
                      })}
                  </div>
                  <div className="cstmrProdDeatilsText-desc">
                    <h4>{t("ProductType.label")}</h4>
                    {productType &&
                      productType.map((item, index) => {
                        return (
                          <button
                            onClick={() => {
                              let temp = productData;
                              temp.picture = item.picture;
                              temp.price1Scale1 = item.price1Scale1;
                              temp.price2Scale2 = item.price2Scale2;
                              temp.price3Scale3 = item.price3Scale3;
                              temp.price4Scale4 = item.price4Scale4;
                              temp.price5Scale5 = item.price5Scale5;
                              temp.quantity1Scale1 = item.quantity1Scale1;
                              temp.quantity2Scale2 = item.quantity2Scale2;
                              temp.quantity3Scale3 = item.quantity3Scale3;
                              temp.quantity4Scale4 = item.quantity4Scale4;
                              temp.quantity5Scale5 = item.quantity5Scale5;
                              temp.typeCode = item.typeCode;
                              temp.saleUnitEN = item.saleUnitEN;
                              temp.saleUnitTH = item.saleUnitTH;
                              temp.articaleCode = item.articaleCode;
                              temp.cartQuantity = item.cartQuantity;
                              temp.wishlistStatus = item.wishlistStatus;
                              temp.stock = item.stock;
                              temp.consumerNameEN = item.consumerNameEN;
                              temp.packSize = item.packSize;
                              temp.packSizeEN = item.packSizeEN;
                              temp.productTypeEN = item.productTypeEN;
                              temp.instructionEN = item.instructionEN;
                              temp.aboutProductEN = item.aboutProductEN;
                              temp.shelfLifeEN = item.shelfLifeEN;
                              temp.otherProductEN = item.otherProductEN;
                              temp.consumerNameTH = item.consumerNameTH;
                              temp.packSizeTH = item.packSizeTH;
                              temp.productTypeTH = item.productTypeTH;
                              temp.aboutProductTH = item.aboutProductTH;
                              temp.instructionTH = item.instructionTH;
                              temp.shelfLifeTH = item.shelfLifeTH;
                              temp.otherProductTH = item.otherProductTH;
                              temp.conditionAmount = item.conditionAmount;
                              setCartQuantity(
                                item.cartQuantity ? item.cartQuantity : 1
                              );
                              temp.productPrice = priceCalc(item);
                              setProductData({ ...temp });
                            }}
                            className={`${item.productTypeEN == productData.productTypeEN
                              ? "cstmrProdDeatilsTexts-desc-focus"
                              : ""
                              }`}
                          >
                            {lancode === "en"
                              ? item.productTypeEN
                              : item.productTypeTH}
                          </button>
                        );
                      })}
                  </div>
                  <div className="cstmrProdDeatilsText-quantity hidden-sm">
                    <h4>{t("Quantity.label")}</h4>
                    {/* {productData && !isNull(productData.stock) && */}
                    <button>
                      <span
                        onClick={() => {
                          setUpCartandSpecialPrices("minus");
                        }}
                      >
                        -
                      </span>
                      <span>{cartQuantity}</span>
                      <span
                        onClick={() => {
                          setUpCartandSpecialPrices("add");
                        }}
                      >
                        +
                      </span>
                    </button>
                    {/* {!isNull(userData) && (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && productData && <small>{!isNull(productData.stock) ? productData.stock + " " + t("inStock.label") : <span className={"cstmrProdDeatilsText-quantity-outOfStock"}> {t("OutofStock.label")}</span>} </small>} */}
                    {!isNull(userData) &&
                      (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                      productData &&
                      !isNull(productData.stock) ? (
                      <span className="cstmrProdDeatilsText-quantity-inStock">
                        {productData.stock + " " + t("inStock.label")}
                      </span>
                    ) : (
                      (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && <span className="cstmrProdDeatilsText-quantity-outOfStock">
                        {t("OutofStock.label")}
                      </span>
                    )}{" "}
                  </div>

                  <div className="cstmrProdDeatilsText-btn">
                    {window.innerWidth <= 768 && (
                      <div className="col-sm-12 col-12">
                        <div className="row p-0 m-0">
                          <div className="col-sm-2 col-2">
                            {productData &&
                              productData.wishlistStatus !== true && (
                                <button
                                  className="cstmrProdDeatilsText-wishList"
                                  onClick={() => AddItemToWishList(true)}
                                >
                                  {/* <CircularProgress size="80px" color="primary"></CircularProgress> */}
                                  <img className="hidden-sm" src={WishList} />
                                  <img
                                    className="visible-sm"
                                    src={WishListGrey}
                                  />
                                  <span className="hidden-sm">
                                    {t("AddToWhishlist.label")}
                                  </span>
                                </button>
                              )}
                            {productData &&
                              productData.wishlistStatus === true && (
                                <button
                                  className="cstmrProdDeatilsText-wishList hidden-sm"
                                  onClick={() => AddItemToWishList(false)}
                                >
                                  {/* <CircularProgress size="80px" color="primary"></CircularProgress> */}
                                  <img className="hidden-sm" src={WishList} />
                                  {/* <img
                                    className="visible-sm"
                                    src={WishListRed}
                                  /> */}
                                  <span className="hidden-sm">
                                    {t("AddedToWhishlist.label")}
                                  </span>
                                </button>
                              )}
                            {productData &&
                              productData.wishlistStatus === true && (
                                <button
                                  className="cstmrProdDeatilsText-wishListRed visible-sm"
                                  onClick={() => AddItemToWishList(false)}
                                >
                                  <img src={WishListWhite} />
                                </button>
                              )}
                          </div>
                          {productData?.cartQuantity >= 1 ? (
                            <div className="col-sm-10 col-10 pl-0">
                              <button className="add-sub-button">
                                <div className="row">
                                  {/* <div className="col-sm-4 col-4 d-flex justify-content-start" onClick={() => {
                                  setUpCartandSpecialPrices("add")
                                }}>
                                  <img src={addProdouctButton} height={28} width={28} />
                                </div> */}
                                  <div
                                    className="col-sm-4 col-4 d-flex justify-content-start"
                                    onClick={() => {
                                      setUpCartandSpecialPrices("minus");
                                    }}
                                  >
                                    <img
                                      src={minusProdouctButton}
                                      height={35}
                                      width={35}
                                    />
                                  </div>
                                  <div className="col-sm-4 col-4">
                                    <h6 className="add-sub-button-count">
                                      {cartQuantity}
                                    </h6>
                                  </div>
                                  <div
                                    className="col-sm-4 col-4 d-flex justify-content-end"
                                    onClick={() => {
                                      setUpCartandSpecialPrices("add");
                                    }}
                                  >
                                    <img
                                      src={addProdouctButton}
                                      height={35}
                                      width={35}
                                    />
                                  </div>
                                </div>
                              </button>
                            </div>
                          ) : (
                            <button
                              className="cstmrProdDeatilsText-addToCart"
                              onClick={() => [
                                AddItemToCart(),
                                setAddedToCardModal(true),
                                setCountonclicksonaddtocartbutton(1),
                              ]}
                            >
                              <img
                                src={AddToCart}
                                onClick={() => setAddedToCardModal(true)}
                              />
                              {/* {(productData?.cartQuantity) ? 
                             <span>{t("Updatetocart.label")}</span> :  */}
                              <span>{t("Addtocart.label")}</span>
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    {window.innerWidth >= 768 && (
                      <button
                        type="button"
                        className="cstmrProdDeatilsText-addToCart"
                        onClick={() => [
                          AddItemToCart(),
                          setAddedToCardModal(true),
                          setCountonclicksonaddtocartbutton(1),
                        ]}
                      >
                        <img src={AddToCart} />
                        {productData?.cartQuantity ? (
                          <span>{t("Updatetocart.label")}</span>
                        ) : (
                          <span>{t("Addtocart.label")}</span>
                        )}
                      </button>
                    )}
                    {/* Add-to-cart-section start from here */}
                    <div className="add-to-cart-modal-desktop-block">
                      {addedToCardModal ? (
                        <AddToCartModal
                          visible={addedToCardModal}
                          product={productData}
                          quantity={cartQuantity}
                          priceofproductchosen={productData.productPrice}
                          close={addedToCartModalClose}
                        />
                      ) : null}
                    </div>
                    <div className="common-display-block-mobile">
                      {addedToCardModal ? (
                        <AddToCartModalMobile
                          visible={addedToCardModal}
                          product={productData}
                          quantity={cartQuantity}
                          priceofproductchosen={productData.productPrice}
                          close={addedToCartModalClose}
                        />
                      ) : null}
                    </div>
                    {/* Add-to-cart-section end */}
                    {window.innerWidth >= 768 &&
                      productData &&
                      productData.wishlistStatus !== true && (
                        <button
                          type="button"
                          disabled={wishlistBtnStatus}
                          className="cstmrProdDeatilsText-wishList"
                          onClick={() => AddItemToWishList(true)}
                        >
                          {/* <img src={WishListGrey} /> */}
                          <img src={WishListGreen} />
                          <span className="hidden-xs">
                            {wishlistBtnStatus ? (
                              <span className="cir-prg-sty">
                                <CircularProgress size="15px" color="success" />
                              </span>
                            ) : (
                              t("AddToWhishlist.label")
                            )}
                          </span>
                        </button>
                      )}
                    {window.innerWidth >= 768 &&
                      productData &&
                      productData.wishlistStatus === true && (
                        <button
                          type="button"
                          disabled={wishlistBtnStatus}
                          className="cstmrProdDeatilsText-wishListRed"
                          onClick={() => AddItemToWishList(false)}
                        >
                          <img src={WishListRed} />
                          <span className="hidden-xs">
                            {wishlistBtnStatus ? (
                              <span className="cir-prg-sty">
                                <CircularProgress size="15px" color="success" />{" "}
                              </span>
                            ) : (
                              t("AddedToWhishlist.label")
                            )}
                          </span>
                        </button>
                      )}
                    {/* <p><img src={AddMore}></img>Add 1 more to get 5% off</p> */}
                  </div>
                </div>
                <div className="cstmrProdDeatilsText-about">
                  <Accordion defaultActiveKey="0">
                    <hr className="product-category-details-gray-line"></hr>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {t("AbouttheProduct.label")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          {productData && lancode === "en"
                            ? productData.aboutProductEN
                            : productData.aboutProductTH}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr></hr>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {t("Instruction.label")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            {productData && lancode === "en"
                              ? productData.instructionEN
                              : productData.instructionTH}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr></hr>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        {t("ShelfLife.label")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            {productData && lancode === "en"
                              ? productData.shelfLifeEN
                              : productData.shelfLifeTH}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr></hr>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        {t("OtherProductInfo.label")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          {productData && lancode === "en"
                            ? productData.otherProductEN
                            : productData.otherProductTH}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr></hr>
                  </Accordion>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 hidden-md"></div> */}
              {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 padding-lt">
              <div className="cstmrProdDeatilsText-about">
                <Accordion defaultActiveKey="0">
                  <hr className="product-category-details-gray-line"></hr>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("AbouttheProduct.label")}</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        {productData && lancode === "en"
                          ? productData.aboutProductEN
                          : productData.aboutProductTH}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <hr></hr>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{t("Instruction.label")}</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          {productData && lancode === "en"
                            ? productData.instructionEN
                            : productData.instructionTH}
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <hr></hr>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>{t("ShelfLife.label")}</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          {productData && lancode === "en"
                            ? productData.shelfLifeEN
                            : productData.shelfLifeTH}
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <hr></hr>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{t("OtherProductInfo.label")}</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        {productData && lancode === "en"
                          ? productData.otherProductEN
                          : productData.otherProductTH}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <hr></hr>
                </Accordion>
              </div>
            </div> */}
            </div>
          </div>
        )}
      </div>
      {!loading && <RecommendedForYou />}
      {!loading && <Footer />}
    </>
  );
};
export { ProductDetailsCustomer as ProductDetailsCustomer };

