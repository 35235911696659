import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelOrderModal from '../ModalScreens/CancelOrderModal/CancelOrderModal';
import { useTranslation, withTranslation } from "react-i18next";
import AlertImage from '../images/alert1.png';
import ThankYouForPurchaseModal from '../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal';
import ProductInfoModel from '../ModalScreens/ProductInfoModel/ProductInfoModel';
import { orderActions } from "../_actions/order.actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCancell, setOpenCancel] = useState(false);
  const [mainHeading, setMainHeading] = useState("");
  const [subHeading1, setSubHeading1] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [customerSelectErr, setCustomerSelectErr] = useState(false);
  const [productmodal, setProductmodal] = useState(false);
  const [selecteditemproducts, setSelecteditemproducts] = useState([]);
  const [validationflag, setValidationflag] = useState("false");
  const [updateData, setUpdateData] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
   // props.updateorder();

  };

  useEffect(() => {

  }, [props.selectedQuantity]);

  //console.log("the value of  setProductmodal..................", productmodal);
  const confirmSingleOrder = () => {
    props.toconfirmsingle(props.selectedQuantity, props.rowvalue);
    handleClose();
  }

  const confirmorder = () => {
    
    let valiFlag;
    // setCustomerSelectErr(false)
    if (props.rowvalue) {
      const orignalNull = props.rowvalue?.orderitems.filter(items => items.allocatedQtd === null)
      if (orignalNull.length === 0) {
        let filterselectedproduct = props.rowvalue?.orderitems.filter(items => items.allocatedQtd >= 0);
        if (filterselectedproduct.length === props.rowvalue.orderitems.length) {
          valiFlag = "true";
        }
      }
      if (orignalNull.length === props.selectedQuantity.length) {
        orignalNull.map(item2 => {
          let filterArray = props.selectedQuantity.filter(items22 => items22.orderItemId === item2.orderItemId)
          if (filterArray && filterArray[0].allocatedQtd >= 0) {
            valiFlag = "true"
            setValidationflag("true");

          } else {
            valiFlag = "false"
            setValidationflag("false");
          }
        })

      } else {
        valiFlag = "false"
        setValidationflag("false");
      }

    }

    if (valiFlag === "true") {
      setMainHeading("ConfirmOrder.label")
      setSubHeading1("ConfirmOrder2.label")
      setOpenAlert(true);
      props.toconfirmsingle(props.selectedQuantity, props.rowvalue);
      handleClose();
    }
    if (valiFlag === "false") {
      setMainHeading("MissingFields.label");
      setSubHeading1("MissingFields2.label");
      setOpenAlert(true);
      handleClose();

    }
  };
  const handleAlert = () => {
    setOpenAlert(false)
  }
  const closeproductmodal = () => {
    setProductmodal(false);
    handleClose();
  }
  const addproduct = () => {
    //debugger;
    // console.log("i a in add product  ..........", props.rowvalue);
    setProductmodal(true);
    handleClose();
   // props.updateorder();
  };
  const cancel = () => {
    // console.log("i a in cancel product  ..........", props.rowvalue);
    setOpenCancel(true)
    handleClose();
  };
  const toConfirm = () => {

  }

  const toCancelConfirm = () => {
    //props.tocancellsingle(props.rowvalue);
    const payload =
    {
      "orderId": [props.rowvalue.orderId],
      "status": "cancelled"
    }
    dispatch(orderActions.updateStatus("payload", payload));
    setOpenCancel(false);
    //window.location.reload(false);
    props.updateorder();

  }

  const updateorder = () => {
    props.updateorder();

  }

  const updateTabelData = () => {    
    setUpdateData(!updateData)
    props.updateOrderList(updateData);
  }

  return (
    <><ThankYouForPurchaseModal
      image={AlertImage}
      cart={true}
      buttonLabel={t("ok.label")}
      mainHeading={mainHeading}
      subHeading1={subHeading1}
      open={openAlert}
      ClickAction={handleAlert}
      updateorder={updateorder}
    />
      <div id="vvvvvv">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{marginTop: 4}}
        >
          <MoreVertIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem key="1" onClick={confirmSingleOrder}>
            Confirm Order
          </MenuItem>
          <MenuItem key="2" onClick={addproduct}>
            Add Product
          </MenuItem>
          <MenuItem key="3" onClick={cancel}>
            Cancel Order
          </MenuItem>
        </Menu>
      </div>
      {openCancell && (
        <CancelOrderModal
          row={props.rowvalue}
          open={openCancell}
          setOpenCancel={setOpenCancel}
          firstLabel="Cancel order?"
          secondLabel="Are you sure you want to cancel this order?"
          firstBtnLabel="No, keep order"
          secondBtnLabel="Yes, cancel order"
          toCancelConfirm={toCancelConfirm}
          updateorder={updateorder}

        />
      )}
      {productmodal ? <ProductInfoModel showmemodal={productmodal} updateTabelData={updateTabelData} selecteditemproducts={selecteditemproducts} row={props.rowvalue} updateorder={updateorder} closeproductmodal={closeproductmodal} rowvalue={props.rowvalue}
      /> : null}

    </>
  );
}

