import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { landingPageActions, orderActions, productActions, userActions } from "../../_actions";
import MyChangePasswordImage from '../../images/change-password-black.png';
import ChangePasswordGreenImage from '../../images/change-password-green.png';
import LogoutBlackImage from '../../images/logout-black.png';
import MyOrderBlackImage from '../../images/my-orders-black.png';
import OrderGreenImage from '../../images/my-orders-green.png';
import MyProfileGreen from '../../images/my-profile-green.png';
import MyProfileImage from '../../images/my-profile.png';
import WishlistGreenImage from '../../images/my-wishlist-green.png';
import MyWhishlistBlackImage from '../../images/my-wishlist.png';

const MyAccountHamburgerMenu = (props) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [activeMenu, setActiveMenu] = useState(props.activeMenu);
  const location = useLocation();
  const history = useHistory();
  const whishlistDetailsInformationList = useSelector((state) => state.product.whishlistDetailsInformationList);
  const newOrderList = useSelector((state) => state.order.newOrderList);
  const activeOrderList = useSelector((state) => state.order.activelistorderList);
  const dispatch = useDispatch();
  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem('user'))
  const store = userData ? userData.user.stores[0].storeCode : "";
  const [totalCountWishList, setTotalCountWishList] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [tableDataactivelist, setTableDataactivelist] = React.useState([]);
  const [totalCountactivelist, setTotalCountactivelist] = React.useState(0);


  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      history.push("/login");
    }
    let payload = {};
    const payload_new_order = {
      "storeCode": store,
      "flags": {
        "product": true
      }
    }
    dispatch(productActions.getWishListInformation("", payload, `?limit=1000000&skip=0`));
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      dispatch(orderActions.getNewOrderList("", payload_new_order, `limit=1000000&skip=0`));
    } else {
      let queryString = `limit=1000000&skip=0`;
      dispatch(orderActions.activeList("", payload_new_order, queryString));
    }

  }, []);



  React.useEffect(() => {
    setTotalCountWishList(whishlistDetailsInformationList?.data?.totalCount ? whishlistDetailsInformationList.data.totalCount : 0);

    if (newOrderList?.data && (userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      if (newOrderList.data && newOrderList?.data.result.length > 0) {
        setTableDataactivelist(newOrderList?.data.result);
      }
      setLoading(false);
      setTotalCountactivelist(newOrderList?.data.totalCount);
    } else {
      setTableDataactivelist([]);
      setLoading(false);
      setTotalCountactivelist(0);
    }

    if (activeOrderList?.data && userData?.user?.role !== "Saleshop" && userData?.user?.role !== "Admin") {
      const activeListItem = activeOrderList?.data.result.filter(item => item.status === "new")
      setTotalCountactivelist(activeListItem.length);
    }

  }, [whishlistDetailsInformationList, newOrderList, activeOrderList]);
  const logOut = () => {
    const payloadWithOutLogin = {
      typeCode: "2",
    };
    const payloadWithOutLoginNew = {
      typeCode: "3",
    };
    dispatch(userActions.logout());
    // setIsUserLoggedIn(false);
    localStorage.removeItem("user");
    localStorage.removeItem("authorization");
    localStorage.removeItem("keepMeLoggedIn");
    localStorage.removeItem("phone");
    localStorage.removeItem("password");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("authorization");
    dispatch(
      landingPageActions.getPromotionWithoutLogin(
        "promotion",
        payloadWithOutLogin
      )
    );
    dispatch(
      landingPageActions.getNewItemsDataWithOutLogin(
        "newItem",
        payloadWithOutLoginNew
      )
    );
    history.push("/");
  };
  const _handleActiveMenu = (event) => {
    if (event == 'my-profile') {
      history.push("/MyAccountProfile");
    }
    else if (event == 'my-orders') {
      history.push("/myorder");
    }
    else if (event == 'my-wishlist') {
      history.push("/MyAccountWishlist");
    }
    else if (event == 'change-password') {
      history.push("/MyAccountChangePassword");
    }
    setActiveMenu(event);
  }

  // console.log('ccccc',totalCountWishList,whishlistDetailsInformationList)
  return <>
    <div className='my-account-hamburger-main-container my-account-left-menu-stricky'>
      <div className='my-account-hamburger-inner-container'>
        <h6 className='my-account-hamburger-main-heading'> {t("MyAccount.label")}</h6>
        <div className='my-account-hamburger-menu'>
          <div className='col-xl-12 p-0'>
            <Link to={"/MyAccountProfile"}> <div className='my-account-hamburger-menu-section' onClick={() => _handleActiveMenu('my-profile')}>
              <div className='d-flex flex-row'>
                {activeMenu === 'my-profile' ? <span className='my-account-hamburger-hover' /> : null}
                <img src={activeMenu === 'my-profile' ? MyProfileGreen : MyProfileImage} className='my-account-hamburger-menu-section-image' />
                <h6 className={activeMenu === 'my-profile' ? 'my-account-hamburger-menu-section-heading-active' : 'my-account-hamburger-menu-section-heading'}> {t("MyProfile.label")}</h6>
              </div>
            </div></Link>
            {/* <div className='my-account-hamburger-menu-section cursor-pointer-none' >
              <div className='d-flex flex-row cursor-pointer-none'>
                {activeMenu === 'my-dashboard' ? <span className='my-account-hamburger-hover' /> : null}
                <img src={activeMenu === 'my-dashboard' ? DashboardGreenImage : DashboardBlackImage} className='my-account-hamburger-menu-section-image-dashboard' />
                <h6 className={activeMenu === 'my-dashboard' ? 'my-account-hamburger-menu-section-heading-active cursor-pointer-none' : 'my-account-hamburger-menu-section-heading-dashboard'}>{t("MyDashboard.label")}</h6>
              </div>
            </div> */}
            <Link to={"/myorder"}> <div className='my-account-hamburger-menu-section' onClick={() => _handleActiveMenu('my-orders')}>
              <div className='d-flex flex-row'>
                {activeMenu === 'my-orders' ? <span className='my-account-hamburger-hover' /> : null}
                <img src={activeMenu === 'my-orders' ? OrderGreenImage : MyOrderBlackImage} className='my-account-hamburger-menu-section-image' />
                <h6 className={activeMenu === 'my-orders' ? 'my-account-hamburger-menu-section-heading-active' : 'my-account-hamburger-menu-section-heading'}>{t("MyOrders.label")}</h6>
                {totalCountactivelist > 0 ? <span className='my-account-hamburger-menu-counts'>{totalCountactivelist}</span> : null}
              </div>
            </div></Link>
            <Link to={"/MyAccountWishlist"}>
              <div className='my-account-hamburger-menu-section' onClick={() => _handleActiveMenu('my-wishlist')}>
                <div className='d-flex flex-row'>
                  {activeMenu === 'my-wishlist' ? <span className='my-account-hamburger-hover' /> : null}
                  <img src={activeMenu === 'my-wishlist' ? WishlistGreenImage : MyWhishlistBlackImage} className='my-account-hamburger-menu-section-image' />
                  <h6 className={activeMenu === 'my-wishlist' ? 'my-account-hamburger-menu-section-heading-active' : 'my-account-hamburger-menu-section-heading'}>{t("MyWishlist.label")}</h6>
                  {totalCountWishList > 0 ? <span className='my-account-hamburger-menu-counts'>{totalCountWishList}</span> : null}
                </div>
              </div></Link>
            <Link to={"/MyAccountChangePassword"}>
              <div className='my-account-hamburger-menu-section' onClick={() => _handleActiveMenu('change-password')}>
                <div className='d-flex flex-row'>
                  {activeMenu === 'change-password' ? <span className='my-account-hamburger-hover' /> : null}
                  <img src={activeMenu === 'change-password' ? ChangePasswordGreenImage : MyChangePasswordImage} className='my-account-hamburger-menu-section-image' />
                  <h6 className={activeMenu === 'change-password' ? 'my-account-hamburger-menu-section-heading-active' : 'my-account-hamburger-menu-section-heading'}>{t("ChangePassword.label")}</h6>
                </div>
              </div></Link>
            <div className='my-account-hamburger-logout-menu-section' onClick={() => _handleActiveMenu('log-out')}>
              <div className='d-flex flex-row'>
                {activeMenu === 'log-out' ? <span className='my-account-hamburger-hover' /> : null}
                <img src={LogoutBlackImage} className='my-account-hamburger-menu-section-image' />
                <h6 className={activeMenu === 'log-out' ? 'my-account-hamburger-menu-section-heading-active' : 'my-account-hamburger-menu-section-heading'} onClick={logOut}>{t("Logout.label")}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}
export default MyAccountHamburgerMenu;