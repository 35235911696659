import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import {
    MDBCarousel,
    MDBCarouselItem,
} from 'mdb-react-ui-kit';

import fish1pic from '../images/fish1pic.png';
import fishpic2 from '../images/fishpic2.png';
import fishpic3 from '../images/fishpic3.png';
import otherbanner from '../images/otherbanner.png';
import otherspic3 from '../images/otherspic3.png';
import otherspic4 from '../images/otherspic4.png';
import shopnowbutton from '../images/shopnowbutton.png';
import { MDBBtn } from 'mdb-react-ui-kit';
import { BsCart3 } from "react-icons/bs";
import { IconContext } from "react-icons";


class OthersBanner extends React.Component {

    componentDidMount() {
        this.props.getUsers();
    }

    handleDeleteUser(id) {
        return (e) => this.props.deleteUser(id);
    }
    render() {
        const { user, users } = this.props;

        return (

            <div>
                <MDBCarousel showControls showIndicators light fade>
                    <MDBCarouselItem
                        className='w-50 d-block'
                        itemId={1}
                        src={fish1pic}
                        alt='...'
                    >
                        <h2 style={{ color: 'white', }}>We Bring the store<br></br> to your door !</h2>
                        <p style={{ color: 'red', fontSize:'25' }}>Now ! get 25% off on all items
                       <br></br> <MDBBtn filled rounded color='success' size='lg' title="click to shop now and see all the categories"><IconContext.Provider value={{ color: "white", className: "global-class-name" }}><BsCart3 /></IconContext.Provider>&nbsp;&nbsp;&nbsp;Shop Now</MDBBtn><br></br>
                        </p>
                    </MDBCarouselItem>
                    <MDBCarouselItem
                        className='w-50 d-block'
                        itemId={2}
                        src={fishpic2}
                        alt='...'
                    >
                        <h2 style={{ color: 'white', }}>We Bring the store<br></br> to your door !</h2>
                        <p style={{ color: 'green', fontSize:'25' }}>Now ! get 25% off on all items<br></br> 
                        <MDBBtn filled rounded color='success' size='lg' title="click to shop now and see all the categories"><IconContext.Provider value={{ color: "white", className: "global-class-name" }}><BsCart3 /></IconContext.Provider>&nbsp;&nbsp;&nbsp;Shop Now</MDBBtn><br></br>
                        </p>
                    </MDBCarouselItem>

                    <MDBCarouselItem
                        className='w-50 d-block'
                        itemId={3}
                        src={fishpic3}
                        alt='...'
                    ><center>
                         <h2 style={{ color: 'white',}}>We Bring the store<br></br> to your door !</h2>
                        <p style={{ color: 'blue', fontSize:'25'}}>Now ! get 25% off on all items
                        <br></br><MDBBtn filled rounded color='success' size='lg' title="click to shop now and see all the categories"><IconContext.Provider value={{ color: "white", className: "global-class-name" }}><BsCart3 /></IconContext.Provider>&nbsp;&nbsp;&nbsp;Shop Now</MDBBtn>
                        <br></br></p>
                        </center>
                    </MDBCarouselItem>
                    <MDBCarouselItem
                        className='w-50 d-block'
                        itemId={4}
                        src={otherbanner}
                        alt='...'
                    >
                        <h2 style={{ color: 'black', }}>We Bring the store<br></br> to your door !</h2>
                        <p style={{ color: 'yellow', fontSize:'25'}}>Now ! get 25% off on all items
                        <br></br> <MDBBtn filled rounded color='success' size='lg' title="click to shop now and see all the categories"><IconContext.Provider value={{ color: "white", className: "global-class-name" }}><BsCart3 /></IconContext.Provider>&nbsp;&nbsp;&nbsp;Shop Now</MDBBtn>
                        <br></br></p>
                    </MDBCarouselItem>
                    <MDBCarouselItem
                        className='w-50 d-block'
                        itemId={5}
                        src={otherspic3}
                        alt='...'
                    >
                        <h2 style={{ color: 'white', }}>We Bring the store<br></br> to your door !</h2>
                        <p style={{ color: 'yellow', fontSize:'25'}}>Now ! get 25% off on all items
                        <br></br> <MDBBtn filled rounded color='success' size='lg' title="click to shop now and see all the categories"><IconContext.Provider value={{ color: "white", className: "global-class-name" }}><BsCart3 /></IconContext.Provider>&nbsp;&nbsp;&nbsp;Shop Now</MDBBtn>
                        </p>
                    </MDBCarouselItem>
                    <MDBCarouselItem
                        className='w-50 d-block'
                        itemId={6}
                        src={otherspic4}
                        alt='...'
                    >
                        <h2 style={{ color: 'white', }}>We Bring the store<br></br> to your door !</h2>
                        <p style={{ color: 'yellow', fontSize:'25'}}>Now ! get 25% off on all items
                        <br></br><MDBBtn filled rounded color='success' size='lg' title="click to shop now and see all the categories"><IconContext.Provider value={{ color: "white", className: "global-class-name" }}><BsCart3 /></IconContext.Provider>&nbsp;&nbsp;&nbsp;Shop Now</MDBBtn>
                        </p>
                    </MDBCarouselItem>
                </MDBCarousel>  </div>

        );
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    getUsers: userActions.getAll,
    deleteUser: userActions.delete
}

const connectedOthersBanner = connect(mapState, actionCreators)(OthersBanner);
export { connectedOthersBanner as OthersBanner };