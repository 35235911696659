import styled from 'styled-components';
  
export const Heading = styled.h1`
   text-align: center;
   color: green;
`;
  
export const Content = styled.div`
   overflowY: scroll;
   height: 2500px;
`;
  
export const Button = styled.div`
position: fixed !important;
width: 100% !important;
left: 95% !important;
bottom: 40px !important;
height: 40px !important;
font-size: 3rem !important;
z-index: 999 !important;
cursor: pointer !important;
color: #61A60E !important;
`