import React from "react";
import { useState } from "react";
import Axios from "axios";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { productActions } from "../_actions";
import "./AllProductCards.css";

import TopBanner from '../images/all-product-top-banner.png';
import Pork from '../images/pork.png';
import Chicken from '../images/chicken.png';
import Duck from '../images/duck.png';
import Fish from '../images/fish.png';
import Egg from '../images/egg.png';
import ProcessedMeat from '../images/processed-meat.png';
import ReadyToEat from '../images/ready-to-eat.png';
import ReadyToCook from '../images/ready-to-cook.png';
import Others from '../images/others.png';
import OffSpec from '../images/offspec.png';
// 
import Filter from './Filters'
import ProductCards from './ProductCards'
import { REACT_APP_BASE_URL } from "../constant"
import { useRouteMatch, useParams } from 'react-router-dom';
import { Link, useHistory } from "react-router-dom";
import Loading from "../SharedComponent/Loader/Loader";
import { FaArrowCircleUp } from 'react-icons/fa';
import { Button } from './Styles';


const subCategoryItems = [
  { name: 'pork', category: 'pork.label' },
  { name: 'chicken', category: 'chicken.label' },
  { name: 'duck', category: 'duck.label' },
  { name: 'fish', category: 'fish.label' },
  { name: 'egg', category: 'egg.label' },
  { name: 'processed-meat', category: 'processed-meat.label' },
  { name: 'ready-to-eat', category: 'ready-to-eat.label' },
  { name: 'ready-to-cook', category: 'ready-to-cook.label' },
  { name: 'others', category: 'other.label' },
  { name: 'off-spec', category: 'offspec.label' }
]


const Category = (props) => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
          setVisible(true)
      }
      else if (scrolled <= 300) {
          setVisible(false)
      }
  };

  const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
      });
  };

  window.addEventListener('scroll', toggleVisible);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("authorization");
  const [openSortByDropDown, setOpenSortByDropDown] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [defaultLoad, setDefaultLoad] = useState(true);
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalProduct, setTotalProduct] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [productsPerPage] = useState(32);
  const [firstParams, setFirstParams] = useState("");
  const [secondParams, setSecondParams] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const history = useHistory();
  let match = useRouteMatch();
  const [iscartactive, setIscartactive] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(0);
  // const { firstParam, secondParam } = useParams();
  const lancode = localStorage.getItem("lancode");

  let paramValue = match.params.chicken;
  const userState = useSelector((state) => state.users);
  const [firstParam, secondParam] = props.location.pathname.split('/').slice(1);
  const paramsName = props.location.pathname.split('/').slice(1);

  const filteredCategory = subCategoryItems.filter(item => item.name === firstParam)


  const userData = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;

  const defaultFilter = {
    brand: [],
    specialProduct: [],
    channel: [],
    categorySlug: [firstParam],
    subCategorySlug: secondParam ? [secondParam] : [],
    storeCode: userData ? userData.user.stores[0].storeCode : ''
  }
  const [filters, setFilters] = useState(defaultFilter);

  const setPage = (page) => {
    let pages = (page - 1) * 33
    setCurrentPage(pages)

  }
  // React.useEffect(() => {

  //   getProducts(filters, 1)
  // }, []);

 
  React.useEffect(() => {
    if (!userData && userState.userLogin === null) {
      history.push('/login')
    }      
    setFirstParams(firstParam)
    setSecondParams(secondParam);
    // if( userData && userData.user) {}     
    const payload = {
      "storeCode": userData && userData.user ? userData.user.stores[0].storeCode : '',
      "categorySlug": [firstParam],
      "subCategorySlug": secondParam ? [secondParam] : [] || secondParam === undefined ? [] : [secondParam],
      "specialProduct": []
    }
    if(secondParam) {
      const updatedFilters = { ...filters };
      updatedFilters.subCategorySlug = secondParam ? [secondParam] : [] || secondParam === undefined ? [] : [secondParam]
      setFilters(updatedFilters);
    }    
    getFilters(payload)
    getProducts(payload, 1)


  }, [firstParam, secondParam, currentPage, userState, isLiked, iscartactive, cartQuantity]);
  // firstParam, secondParam, selectedValue,firstParams, secondParams, currentPage

  React.useEffect(() => {
    if (!defaultLoad) {      
      getProducts(filters)
      // const payloadFilter = JSON.parse(JSON.stringify(filters))
      // getFilters({ ...payloadFilter, filter: currentFilter })      

    } else {      
      getProducts(filters)
    }

    // setCategoryValue(filterData && filterData.category[0].categorySlug)

  }, [filters, currentFilter,selectedValue]);

  const handleChange = (event, data) => {
    if (event.target.checked) {
      if (!filters[data].includes(event.target.name)) {
        setFilters(filters => ({ ...filters, [data]: [...filters[data], event.target.name] }))
        setCurrentFilter(data)
      }
    } else {
      setFilters(filters => ({ ...filters, [data]: filters[data].filter(day => day !== event.target.name) }));
      setCurrentFilter(data)
    }
    setDefaultLoad(false)
  }

  const handleChangeCategory = (event, data, cate) => {

    if (event.target.checked) {
      if (!filters[data].includes(event.target.name)) {
        setFilters(filters => ({ ...filters, [data]: [...filters[data], event.target.name] }))
        setCurrentFilter(data)
      }
    } else {
      // [...filters["categorySlug"], filterData.category[0].categorySlug       
      setFilters(filters => ({ ...filters, [data]: filters[data].filter(day => day !== event.target.name), categorySlug: [cate] }));
      setCurrentFilter(data)
    }
    setDefaultLoad(false)
  }


  const getCatSlug = (arrr) => {
    const SubCat = arrr.map(item => item.subCategorySlug);
    return SubCat
  }

  const removeSubCatSlug = (oldArray, removeItems) => {
    const newArray = oldArray.filter((value) => {
      return !removeItems.includes(value)
    })
    return newArray
  }

  const CategoryChange = (event, data, subCat) => {
    if (event.target.checked) {
      if (!filters[data].includes(event.target.name)) {
        setFilters(filters => ({ ...filters, [data]: [...filters[data], event.target.name], subCategorySlug: [...filters["subCategorySlug"], ...getCatSlug(subCat)] }))
        setCurrentFilter(data)
      }
    } else {
      setFilters(filters => ({ ...filters, [data]: filters[data].filter(filData => filData !== event.target.name), subCategorySlug: removeSubCatSlug(filters.subCategorySlug, getCatSlug(subCat)) }));
      setCurrentFilter(data)
    }
    setDefaultLoad(false)
  }



  const resetFilter = (e, filterTYpe) => {
    if (!filterTYpe) {
      setFilters(filters => ({ ...filters, categorySlug: [filterData.category[0].categorySlug], subCategorySlug: [], specialProduct: [], brand: [], channel: [] }))
    } else {
      if (filterTYpe === "categorySlug") {
        setFilters(filters => ({ ...filters, categorySlug: [filterData.category[0].categorySlug], subCategorySlug: [] }))
      } else {
        setFilters(filters => ({ ...filters, [filterTYpe]: [], categorySlug: [filterData.category[0].categorySlug] }))
      }

    }

  }

  const handleValueSelected = (value) => {
    setSelectedValue(value);
  };

  const handleValueWithRadio = (value) => {
    setSelectedValue(value);
  }

  const handleIsLiked = (value) => {
    setIsLiked(value)
  }

  const handlecarttoggle = (value) => {
    setIscartactive(value)

  }

  const handleupdateQuantity = (value) => {
    setCartQuantity(value)
  }

  const getFilters = (payload) => {
    setLoading(true)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    };
    Axios.post(
      REACT_APP_BASE_URL + "/api/product/filters",
      payload,
      requestOptions
    )
      .then(response => {
        if (response.data.code === 200) {
          setFilterData(response.data.data)
          setLoading(false)

        } else {

        }

      })
      .catch((error) => {
        setLoading(false)
      });
  }

  const getProducts = (payload) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    };
    if (selectedValue === "Price high to low") {
      setLoading(true)
      const sort = { "price": -1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Price low to high") {
      setLoading(true)
      const sort = { "price": 1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "A to Z") {
      setLoading(true)
      const sort = { "consumerNameEN": 1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Z to A") {
      setLoading(true)
      const sort = { "consumerNameEN": -1 };
      const queryString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage + "&" + queryString,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            // setFilterData(response.data.data)
            setLoading(false)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else if (selectedValue === "Clear") {
      setLoading(true)
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    } else {
      setLoading(true)
      Axios.post(
        REACT_APP_BASE_URL + "/api/product/search?limit=33&skip=" + currentPage,
        payload,
        requestOptions
      )
        .then(response => {
          if (response.data.code === 200) {
            setProductData(response.data.data && response.data.data)
            setTotalProduct(response.data.data.totalCount)
            setLoading(false)
            // setFilterData(response.data.data)

          } else {
            setProductData([])
            setTotalProduct(0)

          }

        })
        .catch((error) => {
          setLoading(false)
          setProductData([])
          setTotalProduct(0)
        });
    }
  }

  return (
    <>
      {/* header-section start from here */}
      <div className="header-section">
        <Header />
      </div>
      {/* header-section end */}
      {loading && (<Loading />)}
      <div className="white-background">
        {/* Top-banner-section start */}
        <div className="all-product-section">
          <div className="top-banner-section">
            {firstParam === 'allproducts' && (
              <img src={TopBanner} className="img-fluid" />
            )}
            {firstParam === 'pork' && (
              <img src={Pork} className="img-fluid" />
            )}
            {firstParam === 'chicken' && (
              <img src={Chicken} className="img-fluid" />
            )}
            {firstParam === 'duck' && (
              <img src={Duck} className="img-fluid" />
            )}
            {firstParam === 'fish' && (
              <img src={Fish} className="img-fluid" />
            )}
            {firstParam === 'egg' && (
              <img src={Egg} className="img-fluid" />
            )}
            {firstParam === 'processed-meat' && (
              <img src={ProcessedMeat} className="img-fluid" />
            )}
            {firstParam === 'ready-to-eat' && (
              <img src={ReadyToEat} className="img-fluid" />
            )}
            {firstParam === 'ready-to-cook' && (
              <img src={ReadyToCook} className="img-fluid" />
            )}
            {firstParam === 'others' && (
              <img src={Others} className="img-fluid" />
            )}
            {firstParam === 'off-spec' && (
              <img src={OffSpec} className="img-fluid" />
            )}

            {filteredCategory && filteredCategory.map(item => (
              <h6 className="all-product-text">{t(item.category)}</h6>
            ))}


            {/* { firstParam === 'allproducts' ? (
              <h6 className="all-product-text">{t("allProduct.label")}</h6>
            ): (
              <h6 className="all-product-text">{t(firstParams+".label")}</h6>
            )} */}

          </div>
        </div>
        {/* Top-banner-section end */}
        {/* All-product-counts-mobile-section start from here */}
        <div className="container">
          <div className="row">
            <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 common-display-block-mobile">
              {filteredCategory && filteredCategory.map(item => (
                <h6 className="All-products-mobile-counts-main-heading">
                  {t(item.category)}</h6>
              ))}
              <h6 className="All-products-mobile-counts-inner-heading">
                {lancode === "en" && (
                  <>{totalProduct + " " + t("productRound.label")}</>
                )}
                {lancode === "th" && (
                  <>
                    {t("product.label") +
                      " " +
                      totalProduct +
                      " " +
                      t("productfound.label")}
                  </>
                )}
              </h6>
            </div>
          </div>
        </div>

        {/* All-product-counts-mobile-section end */}

        {/* All-product-section start from here */}
        <div className="container all-product-inner-section">
          <div className="row">
            {/* filter-section start from here */}
            <Filter
              filterData={filterData}
              filters={filters}
              handleChange={handleChange}
              CategoryChange={CategoryChange}
              resetFilter={resetFilter}
              currentPage={currentPage}
              handleChangeCategory={handleChangeCategory}
              handleValueWithRadio={handleValueWithRadio}
            />
            <div className="col-sm-1"></div>

            {/* filter-section end */}
            {/* prouduct-card-section start from here */}

            <ProductCards
              productData={productData}
              setPage={setPage}
              totalProduct={totalProduct}
              onValueSelected={handleValueSelected}
              paramValue={firstParams}
              handleIsLiked={handleIsLiked}
              handlecarttoggle={handlecarttoggle}
              handleupdateQuantity={handleupdateQuantity}
            />

            {/* prouduct-card-section end */}
          </div>
        </div>
        {/* All-product-section start from here */}
      </div>
      {/* footer-section start from here */}
      <div className="footer-section">
        <Footer />
      </div>
      {/* footer-section start end */}
    </>
  )
}

const Categories = withTranslation()(Category);
export { Categories as Category };