import React from "react";
import Carousel from "react-bootstrap/Carousel";
import thumb from "./Assets/SVG/thumb.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import GreyHeart from "../SVG/GreyHeart.svg";
import ComingSoonImg from "../SVG/ComingSoonImg.png";
import ComingSoonBadge from "../SVG/ComingSoonBadge.svg";

import "./RecommendedForYou.css";
[
  {
    breakpoint: 991,
    cols: 3,
    rows: 1,
    gap: 10,
    loop: true,
    // autoplay: 1000,
    autoplay: false
  },
  {
    breakpoint: 768,
    cols: 2,
    rows: 2,
    gap: 10,
    loop: true,
    autoplay: 1000,
  },
];

const RecommendedForYou = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <div className="container-fluid recommendedForYou-bg-outer">
        <div className="container recommendedForYou-bg">
          <div className="recommendedForYou-wraper">
            <div className="recommendedForYou-head">
              <div className="recommendedForYou-head-lt">
                <img src={thumb} alt="" />
                <h1 className="hidden-sm">{t("RecommendedForYou.label")}</h1>
                <h1 className="visible-sm">{t("Recommended.label")}</h1>
              </div>
              <div className="recommendedForYou-head-rt"></div>
            </div>
            <Carousel dot className="recommendedForYou-card">
              <Carousel.Item>
                <div className="recommendedForYou">
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item hidden-sm">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="recommendedForYou">
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item hidden-sm">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="recommendedForYou">
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                  <div className="recommendedForYou-item hidden-sm">
                    <div className="recommendedForYou-img">
                      <div className="recommendedForYou-img-badge">
                      <img src={ComingSoonBadge} />
                      </div>
                      <img src={ComingSoonImg} />
                    </div>
                    <div className="recommendedForYou-content">
                      <div className="recommendedForYou-content-40"><p></p></div>
                      <div className="recommendedForYou-content-20"><p></p></div>
                      </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-50"><p></p></div>
                      <div className="recommendedForYou-content-30"><p></p></div>
                    </div>
                    <div className="recommendedForYou-content">
                    <div className="recommendedForYou-content-100"><h6></h6></div>
                    </div>
                    <div className="recommendedForYou-btn">
                      <div className="recommendedForYou-btn-lt">
                        <button>
                          <img src={GreyHeart} />
                        </button>
                      </div>
                      <div className="recommendedForYou-btn-rt">
                        <button></button>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export { RecommendedForYou as RecommendedForYou };
