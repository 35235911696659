import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { HeroBanner } from '../HeroBanner'
import CategorySlider from '../_components/CategorySlider';
import { HeroBannerSecond } from '../HeroBannerSecond/HeroBannerSecond';
import { Base64 } from '../_helpers/commanFunction'
import LetsShop from "../LetsShop/LetsShop"
import { PromotionCard } from "../PromotionCard/PromotionCard";
import { NewItemCard } from "../NewItemCard";
import { ProductReview } from '../ProductReview';
import { LatestOffer } from '../LatestOffer';
import CookieConsent from "react-cookie-consent";
import { CookieSettingsmodal } from '../ModalScreens/CookieSettingsmodal';
import Popup from "../SharedComponent/Popup/Popup";
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import "../HeroBannerSecond/style.css";
import "../CommonStyle/mediaQuery.css";
import { userActions } from "../_actions";
import { FaArrowCircleUp } from 'react-icons/fa';
import { Button } from './Styles';
import { useHistory,useParams } from 'react-router-dom'
import queryString from 'query-string'
import { isEmpty } from 'lodash';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'
const HomeScreen = (props) => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  const [open, setOpen] = useState(false)
  const [openProductReview, setopenProductReview] = useState(false);
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const [isLoginError,setLoginError] = useState(false)
  const {token} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const showModal = e => {
    setOpen(!open)
  }
  const openModalPR = () => {
    setopenProductReview(true);
  }
  const closeModal = () => {
    setopenProductReview(false);
  }
  useEffect(() => {

    const parsed = queryString.parse(props.location.search)
    if (!isEmpty(parsed))
    {
      if (parsed.token === undefined)
      {       
        setLoginError(true)
        history.push('/')
      }
      else
      {
        const user = {
          token: parsed.token,
          user: JSON.parse(parsed.user),
        }
        localStorage.setItem("authorization", parsed.token);
        localStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("authorization",  parsed.token);
        sessionStorage.setItem("user", JSON.stringify(user));
        const prevPath = localStorage.getItem('prevPath');
        if (prevPath !== null && prevPath !== '' && prevPath !== undefined) {
          history.push(prevPath);
          localStorage.removeItem('prevPath')
        }
        else
          history.push('/')
      }
    }
  }, [open])

  useEffect(() => {
    const phone = localStorage.getItem('phone');
    const password = localStorage.getItem('loginType') ? Base64.decode(localStorage.getItem('loginType')) : "";
    const keepMeLoggedIn = localStorage.getItem('keepMeLoggedIn');
    const sessionUser = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : undefined;
    // setKeepMeLoggedIn(true);

    if (keepMeLoggedIn && !sessionUser) {
      const payload = {
        phone: phone,
        password: password,
      };
      // setLoading(true);
      dispatch(userActions.login("phone", payload));
    }


  }, [keepMeLoggedIn]);
  const handleClose = () => {
    setLoginError(false);
  };
  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical:"top", horizontal:"center" }}
        open={isLoginError}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ fontSize:'20px' }}
        >
          Unauthorized
        </Alert>
      </Snackbar>
      <Helmet>
        <title>Betagro Home page</title>
      </Helmet>
      <div className="white-background overflow-hidden" data-testid="home-screen-loading">
        <Header />
        <HeroBanner />
        <CategorySlider />
        <HeroBannerSecond />
        <PromotionCard />
        <NewItemCard />
        <LetsShop />
        <ProductReview openProductReview={openProductReview} openModalPR={openModalPR} closeModal={closeModal} />
        {/* THIS CODE COMMENT FOR FUTURE PREFRENCE START FROM HERE */}
        {/* <Button>
                    <FaArrowCircleUp onClick={scrollToTop}
                        style={{ display: visible ? 'inline' : 'none' }} />
                </Button>  */}
        {/* THIS CODE COMMENT FOR FUTURE PREFRENCE START FROM HERE */}
        <Footer />
      </div>
    </>
  );
}

export { HomeScreen as HomeScreen };
