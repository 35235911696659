import axios from 'axios';
import { REACT_APP_BASE_URL } from "../constant";

class ApiProvider {
    constructor() {
        this.api = axios.create({
            baseURL: REACT_APP_BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
    isNull = (value) => {
        return value == "" || value == undefined || value == null;
    };
    async post(url, data) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem("authorization");
            if (!this.isNull(token)) {
                this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            }
            this.api.post(url, data).then(response => {
                resolve(response)
            })
                .catch(error => {
                    reject(error)
                });
        })
    }
    async get(url, payload, isToken) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem("authorization");
            if (isToken) {
                this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            }
            this.api.get(url).then(response => {
                resolve(response)
            })
                .catch(error => {
                    resolve(error)
                });
        })
    }
}

const api = new ApiProvider();
export default api;
