export const productConstants = {

    POST_UPLOAD_PRODUCTS_REQUEST_INFORMATION:'POST_UPLOAD_PRODUCTS_REQUEST_INFORMATION',
    POST_UPLOAD_PRODUCTS_SUCCESS_INFORMATION:'POST_UPLOAD_PRODUCTS_SUCCESS_INFORMATION',
    POST_UPLOAD_PRODUCTS_NODATA_FOUND_INFORMATION:'POST_UPLOAD_PRODUCTS_NODATA_FOUND_INFORMATION',
    POST_UPLOAD_PRODUCTS_FAILURE_INFORMATION:'POST_UPLOAD_PRODUCTS_FAILURE_INFORMATION',

    GET_PRODUCT_DETAILS: 'GET_PRODUCT_DETAILS',
    GET_PRODUCT_DETAILS_SUCCESS: 'GET_PRODUCT_DETAILS_SUCCESS',
    GET_PRODUCT_DETAILS_NO_DATA_FOUND: 'GET_PRODUCT_DETAILS_NO_DATA_FOUND',
    GET_PRODUCT_DETAILS_FAILURE: 'GET_PRODUCT_DETAILS_FAILURE',
    SEARCH_WITHOUT_LOGIN: 'SEARCH_WITHOUT_LOGIN',
    PRODUCT_GET_FILTER_REQUEST: 'PRODUCT_GET_FILTER_REQUEST',
    PRODUCT_GET_FILTER_SUCCESS: 'PRODUCT_GET_FILTER_SUCCESS',
    PRODUCT_GET_FILTER_FAIL: 'PRODUCT_GET_FILTER_FAIL',
    PRODUCT_GET_REQUEST: 'PRODUCT_GET_REQUEST',
    PRODUCT_GET_SUCCESS: 'PRODUCT_GET_SUCCESS',
    PRODUCT_GET_FAIL: 'PRODUCT_GET_FAIL',
    GET_CART_COUNT: 'GET_CART_COUNT',
    GET_CART_COUNT_SUCCESS: 'GET_CART_COUNT_SUCCESS',
    GET_CART_COUNT_FAILURE: 'GET_CART_COUNT_FAILURE',
    GET_CART_COUNT_NODATA: 'GET_CART_COUNT_NODATA',

    GET_WISH_LIST_REQUEST: 'GET_WISH_LIST_REQUEST',
    GET_WISH_LIST_SUCCESS: 'GET_WISH_LIST_SUCCESS',
    GET_WISH_LIST_FAILURE: 'GET_WISH_LIST_FAILURE',
    GET_WISH_LIST_NODATA_FOUND: 'GET_WISH_LIST_NODATA_FOUND',

    GET_WISH_LIST_REQUEST_INFORMATION:'GET_WISH_LIST_REQUEST_INFORMATION',
    GET_WISH_LIST_SUCCESS_INFORMATION:'GET_WISH_LIST_SUCCESS_INFORMATION',
    GET_WISH_LIST_NODATA_FOUND_INFORMATION:'GET_WISH_LIST_NODATA_FOUND_INFORMATION',
    GET_WISH_LIST_FAILURE_INFORMATION:'GET_WISH_LIST_FAILURE_INFORMATION',


    ADD_PRODUCT_TO_CART: 'ADD_PRODUCT_TO_CART',
    ADD_PRODUCT_TO_CART_SUCCESS: 'ADD_PRODUCT_TO_CART_SUCCESS',
    ADD_PRODUCT_TO_CART_FAILURE: 'ADD_PRODUCT_TO_CART_FAILURE',
    ADD_PRODUCT_TO_WISHLIST: 'ADD_PRODUCT_TO_WISHLIST',
    ADD_PRODUCT_TO_WISHLIST_SUCCESS: 'ADD_PRODUCT_TO_WISHLIST_SUCCESS',
    ADD_PRODUCT_TO_WISHLIST_FAILURE: 'ADD_PRODUCT_TO_WISHLIST_FAILURE',
    ADD_PRODUCT_TO_WISHLIST_NOT_FOUND: 'ADD_PRODUCT_TO_WISHLIST_NOT_FOUND',
    REMOVE_CART_REQUEST: 'REMOVE_CART_REQUEST',
    REMOVE_CART_FAIL: 'REMOVE_CART_FAIL',
    REMOVE_CART_SUCCESS: 'REMOVE_CART_SUCCESS',
    GETSALES_USERS_REQUEST: 'GETSALES_USERS_REQUEST',
    GETSALES_USERS_FAIL: 'GETSALES_USERS_FAIL',
    GETSALES_USERS_SUCCESS: 'GETSALES_USERS_SUCCESS',
    SAVE_TAX_INFO: "SAVE_TAX_INFO",
    SAVE_TAX_INFO_SUCCESS: "SAVE_TAX_INFO_SUCCESS",
    SAVE_TAX_INFO_FAILURE: "SAVE_TAX_INFO_FAILURE",

    SAVE_USERADDRESS_INFO: "SAVE_USERADDRESS_INFO",
    SAVE_USERADDRESS_SUCCESS: "SAVE_USERADDRESS_SUCCESS",
    SAVE_USERADDRESS_FAILURE: "SAVE_USERADDRESS_FAILURE"
};

export const PRODUCT_CATEGORY = {
    PORK: 'pork',
    CHICKEN: 'chicken',
    DUCK: 'duck',
    FISH: 'fish',
    EGG: 'egg',
    PROCESSED_MEAT: 'ready to eat',
    OTHERS: 'others',
    OFFSPEC: 'offspec'

}