import { orderConstants } from "../_constants";
const initReducers = {
  createcustomerdraftorder: null,
  createcustomerdraftorderError: null,
  createcustomerorder: null,
  createcustomerorderError: null,
  addedittaxinvoice: null,
  addedittaxinvoiceError: null,
  postalInfo: null,
  newOrderLoading: null,
  newOrderList: null,
  confirmedOrderLoading: null,
  confirmedOrderList: null,
  shippedOrderList: null,
  confirmedOrderLoadingexcel: null,
  confirmedOrderListexcel: null,
  toconfirmedOrderLoading: null,
  toconfirmedOrderList: null,
  toconfirmedOrderLoadingexcel: null,
  toconfirmedOrderListexcel: null,
  ShippedOrderLoading: null,
  ShippedOrderList: null,
  CompletedOrderLoading: null,
  CompletedOrderList: null,
  CancelledOrderLoading: null,
  CancelledOrderList: null,
  DraftOrderLoading: null,
  DraftOrderList: null,
  toConfirmByProductLoading: null,
  toConfirmByProductList: null,
  AdditemstoOrderLoading: null,
  AdditemstoOrderList: null,
  updateItemsStatsuOrderLoading: null,
  updateItemsStatsuOrderList: null,
  updateOrderStatsuOrderLoading: null,
  updateOrderStatsuOrderList: null,
  toggleValue: false,
  newOrderdateTime: new Date().getMilliseconds(),
  ToConfirmOrderdateTime: new Date().getMilliseconds(),
  CancelledOrderdateTime: new Date().getMilliseconds(),
  activelistorderList: null,
  activelistorderError: null,
  historylistorderList: null,
  historylistorderError: null,
  torevieworderList: null,
  torevieworderError: null,
  userreorderList: null,
  userreorderError: null,
  updatecancellationdetailsList: null,
  updatecancellationdetailsError: null,
  getbyorderidList: null,
  getbyorderidError: null,
  reOrderProduct: null,

  orderdiscardList: null,
  orderdiscardError: null,
  getOrderItemsByOrderList: null,
  getOrderItemsByOrderError: null,
  orderSubmitDraftList: null,
  orderSubmitDraftError: null,
  orderSubmitConfirmList: null,
  orderSubmitConfirmError: null,
  removeOrderItemsList: null,
  removeOrderItemsError: null,
};
export function order(state = initReducers, action) {
  const error = 'error'
  switch (action.type) {

    case orderConstants.REMOVE_ORDER_ITEMS__ALL:
      return {
        ...state,
        removeOrderItemsList: action.data,
        removeOrderItemsError: action.error,
      };


    case orderConstants.REMOVE_ORDER_ITEMS__ALL_SUCCESS:
      return {
        ...state,
        removeOrderItemsList: action.data,
        removeOrderItemsError: action.error,
      };


    case orderConstants.REMOVE_ORDER_ITEMS__ALL_NODATA:
      return {
        ...state,
        removeOrderItemsList: action.data,
        removeOrderItemsError: action.error,
      };


    case orderConstants.REMOVE_ORDER_ITEMS__ALL_FAILURE:
      return {
        ...state,
        removeOrderItemsList: action.data,
        removeOrderItemsError: action.error,
      };


    case orderConstants.ORDER_SUBMIT_CONFIRM__ALL:
      return {
        ...state,
        orderSubmitConfirmList: action.data,
        orderSubmitConfirmError: action.error,
      };


    case orderConstants.ORDER_SUBMIT_CONFIRM__ALL_SUCCESS:
      return {
        ...state,
        orderSubmitConfirmList: action.data,
        orderSubmitConfirmError: action.error,
      };


    case orderConstants.ORDER_SUBMIT_CONFIRM__ALL_NODATA:
      return {
        ...state,
        orderSubmitConfirmList: action.data,
        orderSubmitConfirmError: action.error,
      };


    case orderConstants.ORDER_SUBMIT_CONFIRM__ALL_FAILURE:
      return {
        ...state,
        orderSubmitConfirmList: action.data,
        orderSubmitConfirmError: action.error,
      };


    case orderConstants.ORDER_SUBMIT_DRAFT__ALL:
      return {
        ...state,
        orderSubmitDraftList: action.data,
        orderSubmitDraftError: action.error,
      };


    case orderConstants.ORDER_SUBMIT_DRAFT__ALL_SUCCESS:
      return {
        ...state,
        orderSubmitDraftList: action.data,
        orderSubmitDraftError: action.error,
      };


    case orderConstants.ORDER_SUBMIT_DRAFT__ALL_NODATA:
      return {
        ...state,
        orderSubmitDraftList: action.data,
        orderSubmitDraftError: action.error,
      };


    case orderConstants.ORDER_SUBMIT_DRAFT__ALL_FAILURE:
      return {
        ...state,
        orderSubmitDraftList: action.data,
        orderSubmitDraftError: action.error,
      };

    case orderConstants.GET_ORDER_ITEMS_BY_ORDER__ALL:
      return {
        ...state,
        getOrderItemsByOrderList: action.data,
        getOrderItemsByOrderError: action.error,
      };


    case orderConstants.GET_ORDER_ITEMS_BY_ORDER__ALL_SUCCESS:
      return {
        ...state,
        getOrderItemsByOrderList: action.data,
        getOrderItemsByOrderError: action.error,
      };


    case orderConstants.GET_ORDER_ITEMS_BY_ORDER__ALL_NODATA:
      return {
        ...state,
        getOrderItemsByOrderList: action.data,
        getOrderItemsByOrderError: action.error,
      };


    case orderConstants.GET_ORDER_ITEMS_BY_ORDER__ALL_FAILURE:
      return {
        ...state,
        getOrderItemsByOrderList: action.data,
        getOrderItemsByOrderError: action.error,
      };

    case orderConstants.ORDER_DISCARD__ALL:
      return {
        ...state,
        orderdiscardList: action.data,
        orderdiscardError: action.error,
      };
    case orderConstants.ORDER_DISCARD__ALL_SUCCESS:
      return {
        ...state,
        orderdiscardList: action.data,
        orderdiscardError: action.error,
      };
    case orderConstants.ORDER_DISCARD__ALL_NODATA:
      return {
        ...state,
        orderdiscardList: action.data,
        orderdiscardError: action.error,
      };
    case orderConstants.ORDER_DISCARD__ALL_FAILURE:
      return {
        ...state,
        orderdiscardList: action.data,
        orderdiscardError: action.error,
      };

    case orderConstants.GET_BY_ORDER_ID__ALL:
      return {
        ...state,
        getbyorderidList: action.data,
        getbyorderidError: action.error,
      };
    case orderConstants.GET_BY_ORDER_ID__ALL_SUCCESS:
      return {
        ...state,
        getbyorderidList: action.data,
        getbyorderidError: action.error,
      };
    case orderConstants.GET_BY_ORDER_ID__ALL_NODATA:
      return {
        ...state,
        getbyorderidList: action.data,
        getbyorderidError: action.error,
      };
    case orderConstants.GET_BY_ORDER_ID__ALL_FAILURE:
      return {
        ...state,
        getbyorderidList: action.data,
        getbyorderidError: action.error,
      };
    case orderConstants.UPDATE_CANCELLATION_DETAILS_ORDER__ALL:
      return {
        ...state,
        updatecancellationdetailsList: action.data,
        updatecancellationdetailsError: action.error,
      };
    case orderConstants.UPDATE_CANCELLATION_DETAILS_ORDER__ALL_SUCCESS:
      return {
        ...state,
        updatecancellationdetailsList: action.data,
        updatecancellationdetailsError: action.error,
      };
    case orderConstants.UPDATE_CANCELLATION_DETAILS_ORDER__ALL_NODATA:
      return {
        ...state,
        updatecancellationdetailsList: action.data,
        updatecancellationdetailsError: action.error,
      };
    case orderConstants.UPDATE_CANCELLATION_DETAILS_ORDER__ALL_FAILURE:
      return {
        ...state,
        updatecancellationdetailsList: action.data,
        updatecancellationdetailsError: action.error,
      };
    case orderConstants.USER_REORDER_ORDER__ALL:
      return {
        ...state,
        userreorderList: action.data,
        updatecancellationdetailsError: action.error,
      };
    case orderConstants.USER_REORDER_ORDER__ALL_SUCCESS:
      return {
        ...state,
        userreorderList: action.data,
        userreorderError: action.error,
      };
    case orderConstants.USER_REORDER_ORDER__ALL_NODATA:
      return {
        ...state,
        userreorderList: action.data,
        userreorderError: action.error,
      };
    case orderConstants.USER_REORDER_ORDER__ALL_FAILURE:
      return {
        ...state,
        userreorderList: action.data,
        userreorderError: action.error,
      };
    case orderConstants.TOREVIEW_ORDER__ALL:
      return {
        ...state,
        torevieworderList: action.data,
        torevieworderError: action.error,
      };
    case orderConstants.TOREVIEW_ORDER__ALL_SUCCESS:
      return {
        ...state,
        torevieworderList: action.data,
        torevieworderError: action.error,
      };
    case orderConstants.TOREVIEW_ORDER__ALL_NODATA:
      return {
        ...state,
        torevieworderList: action.data,
        torevieworderError: action.error,
      };
    case orderConstants.TOREVIEW_ORDER__ALL_FAILURE:
      return {
        ...state,
        torevieworderList: action.data,
        torevieworderError: action.error,
      };
    case orderConstants.HISTORYLIST_ORDER__ALL:
      return {
        ...state,
        historylistorderList: action.data,
        historylistorderError: action.error,
      };
    case orderConstants.HISTORYLIST_ORDER__ALL_SUCCESS:
      return {
        ...state,
        historylistorderList: action.data,
        historylistorderError: action.error,
      };
    case orderConstants.HISTORYLIST_ORDER__ALL_NODATA:
      return {
        ...state,
        historylistorderList: action.data,
        historylistorderError: action.error,
      };
    case orderConstants.HISTORYLIST_ORDER__ALL_FAILURE:
      return {
        ...state,
        historylistorderList: action.data,
        historylistorderError: action.error,
      };
    case orderConstants.ACTIVELIST_ORDER__ALL:
      return {
        ...state,
        activelistorderList: action.data,
        activelistorderError: action.error,
      };
    case orderConstants.ACTIVELIST_ORDER__ALL_SUCCESS:
      return {
        ...state,
        activelistorderList: action.data,
        activelistorderError: action.error,
      };
    case orderConstants.ACTIVELIST_ORDER__ALL_NODATA:
      return {
        ...state,
        activelistorderList: action.data,
        activelistorderError: action.error,
      };
    case orderConstants.ACTIVELIST_ORDER__ALL_FAILURE:
      return {
        ...state,
        activelistorderList: action.data,
        activelistorderError: action.error,
      };
    case orderConstants.UPDATE_ORDER_STATUS__ALL:
      return {
        ...state,
        updateOrderStatsuOrderList: action.data,
        updateOrderStatsuOrderLoading: action.error,
      };
    case orderConstants.UPDATE_ORDER_STATUS__ALL_SUCCESS:
      return {
        ...state,
        updateOrderStatsuOrderList: action.data,
        updateOrderStatsuOrderLoading: action.error,
      };
    case orderConstants.UPDATE_ORDER_STATUS__ALL_NODATA:
      return {
        ...state,
        updateOrderStatsuOrderList: action.data,
        updateOrderStatsuOrderLoading: action.error,
      };
    case orderConstants.UPDATE_ORDER_STATUS__ALL_FAILURE:
      return {
        ...state,
        updateOrderStatsuOrderList: action.data,
        updateOrderStatsuOrderLoading: action.error,
      };

    case orderConstants.UPDATE_ITEMS_STATUS_ORDER_ALL:
      return {
        ...state,
        updateItemsStatsuOrderList: action.data,
        updateItemsStatsuOrderLoading: action.error,
      };
    case orderConstants.UPDATE_ITEMS_STATUS_ORDER_ALL_SUCCESS:
      return {
        ...state,
        updateItemsStatsuOrderList: action.data,
        updateItemsStatsuOrderLoading: action.error,
      };
    case orderConstants.UPDATE_ITEMS_STATUS_ORDER_ALL_NODATA:
      return {
        ...state,
        updateItemsStatsuOrderList: action.data,
        updateItemsStatsuOrderLoading: action.error,
      };
    case orderConstants.UPDATE_ITEMS_STATUS_ORDER_ALL_FAILURE:
      return {
        ...state,
        updateItemsStatsuOrderList: action.data,
        updateItemsStatsuOrderLoading: action.error,
      };

    case orderConstants.ADD_TO_CONFIRM_ITEMS_ORDER_ALL:
      return {
        ...state,
        AdditemstoOrderList: action.data,
        AdditemstoOrderLoading: action.error,
      };
    case orderConstants.ADD_TO_CONFIRM_ITEMS_ORDER_ALL_SUCCESS:
      return {
        ...state,
        AdditemstoOrderList: action.data,
        AdditemstoOrderLoading: action.error,
      };
    case orderConstants.ADD_TO_CONFIRM_ITEMS_ORDER_ALL_NODATA:
      return {
        ...state,
        AdditemstoOrderList: action.data,
        AdditemstoOrderLoading: action.error,
      };
    case orderConstants.ADD_TO_CONFIRM_ITEMS_ORDER_ALL_FAILURE:
      return {
        ...state,
        AdditemstoOrderList: action.data,
        AdditemstoOrderLoading: action.error,
      };

    case orderConstants.CREATE_CUSTOMER_DRAFT_ORDER_ALL:
      return {
        ...state,
        createcustomerdraftorder: action.data,
        createcustomerdraftorderError: action.error,
      };
    case orderConstants.CREATE_CUSTOMER_DRAFT_ORDER_ALL_SUCCESS:
      return {
        ...state,
        createcustomerdraftorder: action.data,
        createcustomerdraftorderError: action.error,
      };
    case orderConstants.CREATE_CUSTOMER_DRAFT_ORDER_ALL_NODATA:
      return {
        ...state,
        createcustomerdraftorder: action.data,
        createcustomerdraftorderError: action.error,
      };
    case orderConstants.CREATE_CUSTOMER_DRAFT_ORDER_ALL_FAILURE:
      return {
        ...state,
        createcustomerdraftorder: action.data,
        createcustomerdraftorderError: action.error,
      };
    case orderConstants.CREATE_CUSTOMER_ORDER_ALL:
      return {
        ...state,
        createcustomerorder: action.data,
        createcustomerorderError: action.error,
      };
    case orderConstants.CREATE_CUSTOMER_ORDER_ALL_SUCCESS:
      return {
        ...state,
        createcustomerorder: action.data,
        createcustomerorderError: action.error,
      };
    case orderConstants.CREATE_CUSTOMER_ORDER_ALL_NODATA:
      return {
        ...state,
        createcustomerorder: action.data,
        createcustomerorderError: action.error,
      };
    case orderConstants.CREATE_CUSTOMER_ORDER_ALL_FAILURE:
      return {
        ...state,
        createcustomerorder: action.data,
        createcustomerorderError: action.error,
      };
    case orderConstants.ADD_EDIT_TAX_INVOICE_ALL:
      return {
        ...state,
        addedittaxinvoice: action.data,
        addedittaxinvoiceError: action.error,
      };
    case orderConstants.ADD_EDIT_TAX_INVOICE_ALL_SUCCESS:
      return {
        ...state,
        addedittaxinvoice: action.data,
        addedittaxinvoiceError: action.error,
      };
    case orderConstants.ADD_EDIT_TAX_INVOICE_ALL_NODATA:
      return {
        ...state,
        addedittaxinvoice: action.data,
        addedittaxinvoiceError: action.error,
      };
    case orderConstants.ADD_EDIT_TAX_INVOICE_ALL_FAILURE:
      return {
        ...state,
        addedittaxinvoice: action.data,
        addedittaxinvoiceError: action.error,

      };
    case orderConstants.GET_POSTAL_INFO:
      return {
        ...state,
        postalInfo: action.data,
        // postalInfoError: action.error,

      };
    case orderConstants.GET_POSTAL_INFO_SUCCESS:
      return {
        ...state,
        postalInfo: action.data,
        // postalInfoError: action.error,

      };
    case orderConstants.GET_POSTAL_INFO_NODATA:
      return {
        ...state,
        postalInfo: action.data,
        // postalInfoError: action.error,

      };
    case orderConstants.GET_POSTAL_INFO_FAILURE:
      return {
        ...state,
        postalInfo: action.data,
        // postalInfoError: action.error,

      };
    case orderConstants.GET_ALL_NEW_ORDER_REQUEST:
      return {
        ...state,
        newOrderLoading: true,
      };
    case orderConstants.GET_ALL_NEW_ORDER_SUCCESS:
      return {
        ...state,
        newOrderList: action.data,
        newOrderLoading: false,
      };
    case orderConstants.GET_ALL_NEW_ORDER_FAILURE:
      return {
        ...state,
        newOrderList: error,
        newOrderLoading: false,
        newOrderdateTime: new Date().getMilliseconds()
      };

    case orderConstants.GET_ALL_NEW_ORDER_NODATA:
      return {
        ...state,
        newOrderList: null,
        newOrderLoading: false,
        newOrderdateTime: new Date().getMilliseconds()
      };

    case orderConstants.GET_ALL_SHIPPED_ORDER_REQUEST:
      return {
        ...state,
        ShippedOrderLoading: true,
      };
    case orderConstants.GET_ALL_SHIPPED_ORDER_SUCCESS:
      return {
        ...state,
        ShippedOrderList: action.data,
        ShippedOrderLoading: false,
      };
    case orderConstants.GET_ALL_SHIPPED_ORDER_FAILURE:
      return {
        ...state,
        ShippedOrderList: error,
        ShippedOrderLoading: false,
      };


    case orderConstants.GET_ALL_COMPLETED_ORDER_REQUEST:
      return {
        ...state,
        CompletedOrderLoading: true,
      };
    case orderConstants.GET_ALL_COMPLETED_ORDER_SUCCESS:
      return {
        ...state,
        CompletedOrderList: action.data,
        CompletedOrderLoading: false,
      };
    case orderConstants.GET_ALL_COMPLETED_ORDER_FAILURE:
      return {
        ...state,
        CompletedOrderList: error,
        CompletedOrderLoading: false,
      };
    case orderConstants.GET_ALL_COMPLETED_ORDER_NODATA:
      return {
        ...state,
        CompletedOrderList: null,
        CompletedOrderLoading: false,
      };


    case orderConstants.GET_ALL_CANCELLED_ORDER_REQUEST:
      return {
        ...state,
        CancelledOrderLoading: true,
      };
    case orderConstants.GET_ALL_CANCELLED_ORDER_SUCCESS:
      return {
        ...state,
        CancelledOrderList: action.data,
        CancelledOrderLoading: false,
      };
    case orderConstants.GET_ALL_CANCELLED_ORDER_NODATA:
      return {
        ...state,
        CancelledOrderList: null,
        CancelledOrderLoading: false,
        CancelledOrderdateTime: new Date().getMilliseconds()
      };
    case orderConstants.GET_ALL_CANCELLED_ORDER_FAILURE:
      return {
        ...state,
        CancelledOrderList: error,
        CancelledOrderLoading: false,
        CancelledOrderdateTime: new Date().getMilliseconds()
      };


    case orderConstants.GET_ALL_DRAFT_ORDER_REQUEST:
      return {
        ...state,
        DraftOrderLoading: true,
      };
    case orderConstants.GET_ALL_DRAFT_ORDER_SUCCESS:
      return {
        ...state,
        DraftOrderList: action.data,
        DraftOrderLoading: false,
      };
    case orderConstants.GET_ALL_DRAFT_ORDER_FAILURE:
      return {
        ...state,
        DraftOrderList: error,
        DraftOrderLoading: false,
      };



    case orderConstants.GET_ALL_COMPLETED_ORDER_REQUEST:
      return {
        ...state,
        CompletedOrderLoading: true,
      };
    case orderConstants.GET_ALL_COMPLETED_ORDER_SUCCESS:
      return {
        ...state,
        CompletedOrderList: action.data,
        CompletedOrderLoading: false,
      };
    case orderConstants.GET_ALL_COMPLETED_ORDER_FAILURE:
      return {
        ...state,
        CompletedOrderList: error,
        CompletedOrderLoading: false,
      };

    case orderConstants.GET_ALL_DRAFT_ORDER_REQUEST:
      return {
        ...state,
        DraftOrderLoading: true,
      };
    case orderConstants.GET_ALL_DRAFT_ORDER_SUCCESS:
      return {
        ...state,
        DraftOrderList: action.data,
        DraftOrderLoading: false,
      };
    case orderConstants.GET_ALL_DRAFT_ORDER_FAILURE:
      return {
        ...state,
        DraftOrderList: error,
        DraftOrderLoading: false,
      };
    case orderConstants.GET_ALL_DRAFT_ORDER_NODATA:
      return {
        ...state,
        DraftOrderList: null,
        DraftOrderLoading: false,
      };


    case orderConstants.GET_ALL_TO_CONFIRM_ORDER_REQUEST:
      return {
        ...state,
        toconfirmedOrderList: action.data,
        toconfirmedOrderLoading: true,
      };
    case orderConstants.GET_ALL_TO_CONFIRM_ORDER_SUCCESS:
      return {
        ...state,
        toconfirmedOrderList: action.data,
        toconfirmedOrderLoading: false,
      };
    case orderConstants.GET_ALL_TO_CONFIRM_ORDER_NODATA:
      return {
        ...state,
        toconfirmedOrderList: null,
        toconfirmedOrderLoading: false,
        ToConfirmOrderdateTime: new Date().getMilliseconds()
      };
    case orderConstants.GET_ALL_TO_CONFIRM_ORDER_FAILURE:
      return {
        ...state,
        toconfirmedOrderList: error,
        toconfirmedOrderLoading: false,
        ToConfirmOrderdateTime: new Date().getMilliseconds()
      };

    case orderConstants.GET_ALL_CONFIRMED_ORDER_REQUEST:
      return {
        ...state,
        confirmedOrderLoading: true,
      };
    case orderConstants.GET_ALL_CONFIRMED_ORDER_SUCCESS:
      return {
        ...state,
        confirmedOrderList: action.data,
        confirmedOrderLoading: false,
      };
    case orderConstants.GET_ALL_CONFIRMED_ORDER_NODATA:
      return {
        ...state,
        confirmedOrderList: null,
        confirmedOrderLoading: false,
      };
    case orderConstants.GET_ALL_CONFIRMED_ORDER_FAILURE:
      return {
        ...state,
        confirmedOrderList: error,
        confirmedOrderLoading: false,
      };

    case orderConstants.GET_TO_CONFIRM_VIEW_BY_PRODUCT_REQUEST:
      return {
        ...state,
        toConfirmByProductLoading: true,
      };
    case orderConstants.GET_TO_CONFIRM_VIEW_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        toConfirmByProductList: action.data,
        toConfirmByProductLoading: false,
      };
    case orderConstants.GET_TO_CONFIRM_VIEW_BY_PRODUCT_FAILURE:
      return {
        ...state,
        toConfirmByProductList: error,
        toConfirmByProductLoading: false
      };
    case orderConstants.GET_TO_CONFIRM_VIEW_BY_PRODUCT_NODATA:
      return {
        ...state,
        toConfirmByProductList: null,
        toConfirmByProductLoading: false
      };
    case orderConstants.GET_ALL_TO_CONFIRMED_ORDER_REQUEST_EXCEL:
      return {
        ...state,
        toconfirmedOrderLoadingexcel: true,
      };
    case orderConstants.GET_ALL_TO_CONFIRMED_ORDER_SUCCESS_EXCEL:
      return {
        ...state,
        toconfirmedOrderListexcel: action.data,
        toconfirmedOrderLoadingexcel: false,
      };
    case orderConstants.GET_ALL_TO_CONFIRMED_ORDER_FAILURE_EXCEL:
      return {
        ...state,
        toconfirmedOrderListexcel: error,
        toconfirmedOrderLoadingexcel: false,
      };

    case orderConstants.GET_ALL_CONFIRMED_ORDER_REQUEST_EXCEL:
      return {
        ...state,
        confirmedOrderLoadingexcel: true,
      };
    case orderConstants.GET_ALL_CONFIRMED_ORDER_SUCCESS_EXCEL:
      return {
        ...state,
        confirmedOrderListexcel: action.data,
      };
    case orderConstants.GET_ALL_CONFIRMED_ORDER_FAILURE_EXCEL:
      return {
        ...state,
        confirmedOrderListexcel: error,
      };
    case orderConstants.GET_ALL_SHIPPED_ORDER_SUCCESS:
      return {
        ...state,
        shippedOrderList: action.data,
      };
    case orderConstants.GET_ALL_SHIPPED_ORDER_FAILURE:
      return {
        ...state,
        shippedOrderList: error,
      }
    case orderConstants.GET_ALL_SHIPPED_ORDER_NODATA:
      return {
        ...state,
        ShippedOrderList: null,
      }
    case orderConstants.TOGGLE_VALUE_TRUE:
      return {
        ...state,
        toggleValue: true
      }

    case orderConstants.TOGGLE_VALUE_FALSE:
      return {
        ...state,
        toggleValue: false
      }

    case orderConstants.RE_ORDER_PRODUCT_SUCCESS:
      return {
        ...state,
        reOrderProduct: action.data,
      };
    case orderConstants.RE_ORDER_PRODUCT_FAILURE:
      return {
        ...state,
        reOrderProduct: error,
      }
    case orderConstants.RE_ORDER_PRODUCT_NODATA:
      return {
        ...state,
        reOrderProduct: null,
      }

    default:
      return state;
  }
}
