import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export  function Quantityselect() {
  const [quantity, setquantity] = React.useState("");

  const handleChange = (event) => {
    setquantity(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 40 }}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="demo-simple-select-label">Quantity</InputLabel>
        <Select
          variant="standard"
          sx={{
            "&:hover": {
              "&& fieldset": {
                border: "3px solid green",
                borderRadius: "40px"
              }
            }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "yellow",
                  color:"green",
                  fontSize:13
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: ""
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "yellow",
                    color:"green",
                    fontSize:13
                   
                }
              }
            }
          }}
          value={quantity}
          label=""
          onChange={handleChange}
        >
          <MenuItem value={10}>1 Kg.,Frozen</MenuItem>
          <MenuItem value={20}>2 Kg.,Frozen</MenuItem>
          <MenuItem value={30}>5 Kg.,Frozen</MenuItem>
          <MenuItem value={40}>1 Kg.,Chilled</MenuItem>
          <MenuItem value={50}>2 Kg.,Chilled</MenuItem>
          <MenuItem value={60}>5 Kg.,Chilled</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}


