import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelOrderModal from '../../ModalScreens/CancelOrderModal/CancelOrderModal'

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCancell, setOpenCancel] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fulfill = () => {
    // console.log("Add: ", data.name);
    props.orderUpdate(props.row,"to_confirm")
    handleClose();
  }; 
  const cancel = () => {
    // console.log("remove: ", data.name);
    setOpenCancel(true)
    handleClose();
  };
  const toCancelConfirm = (rowDetails) =>{
    props.orderUpdate(rowDetails,"cancelled")
    setOpenCancel(false)
  }
  
  const style = {
    menuItem: {
      width: 160,
      boxShadow: 'none',
      color: "#989CA6",
      fontSize: 20
    },
    listStyle: {
      backgroundColor: 'red',
      borderRadius: 0,
      boxShadow: 'none'
    },
    innerDivStyle: {
      borderRadius: 0,
      boxShadow: 'none'
    }
  };

  return (
    <>
    <div id="vvvvvv">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{marginTop: 4}}
      >
        <MoreVertIcon  sx={{ fontSize: 24 }}/>
      </IconButton>
      <Menu
        id="long-menu"
        style={style.menu}
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem key="1" onClick={fulfill} style={style.menuItem}>
        To fulfill
        </MenuItem>
        <MenuItem key="2" onClick={cancel} style={style.menuItem}>
        Cancel order
        </MenuItem>
      </Menu>
    </div>
    {openCancell && (
      <CancelOrderModal 
      row={props.row} 
      open={openCancell}
      setOpenCancel={setOpenCancel}
      firstLabel="Cancel order?"
      secondLabel="Are you sure you want to cancel this order?"
      firstBtnLabel="No, keep order"
      secondBtnLabel="Yes, cancel order"
      toCancelConfirm={toCancelConfirm}
       />
    )}
    </>
  );
}
