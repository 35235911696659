import { postcodeConstants } from "../_constants";
import { postcodeService } from "../_services";
import { alertActions } from ".";
import { history } from "../_helpers";

export const postcodeActions = {
   getAll
 
};

function getAll(type, payload) {
  return (dispatch) => {
      dispatch(request());
      postcodeService.getAll(payload)
          .then((res) => {

              if (res.status === 200 && res.data) {
                  dispatch(success(res.data));
              } else if (res.status === undefined && res.code === 400) {
                  dispatch(noDataFound(res.data));
              }
          })
          .catch((error) => dispatch(failure(error)));
  }
  function request() {
      return { type: postcodeConstants.GET_CUSTOMER_POSTCODE_ALL};
  }
  function success(data) {
      return { type: postcodeConstants.GET_CUSTOMER_POSTCODE_ALL_SUCCESS, data };
  }
  function noDataFound(data) {
      return { type: postcodeConstants.GET_CUSTOMER_POSTCODE_ALL_NODATA, data };
  }
  function failure(error) {
      return { type: postcodeConstants.GET_CUSTOMER_POSTCODE_ALL_FAILURE, error };

  }
}




