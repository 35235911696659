const ApiList = {
    API_URL_FOR_GET_PRODUCT: '/api/product/',
    API_URL_FOR_GET_USER_DETAILS: '/api/users/getDetails',
    API_URL_TO_SAVE_TAX_INVOICE: '/api/users/taxinvoice',
    API_URL_TO_GET_SHIPPED_ORDERS: '/api/order/getUserOrder/shipped',
    API_URL_TO_UPDATE_ORDER: '/api/order/updateStatus',
    API_URL_TO_UPDATE_ITEM_STATUS: '/api/order/updatItemStatus',
    API_URL_TO_UPDATE_STATUS_SAVE: '/api/order/updateItemStatusSave',
    API_URL_TO_CREATE_PASSWORD: '/api/users/setPassword',
    API_URL_FOR_POSTCODE: "/api/postcode/",
    API_URL_TO_GET_CANCEL_ORDER: '/api/order/updateCancellationDetails',
    API_URL_FOR_USER_ADDRESS:"/api/users/address",
    API_URL_TO_UPDATE_USER:"/api/users/updateUser",
    API_URL_TO_REMOVE_USER:"/api/users/address/remove",
    API_URL_TO_REMVOE_ORDER_ITES: '/api/order/removeOrderItems',
    API_URL_TO_SEARCH_PRODUCT: '/api/product/search',
    API_URL_TO_ADD_ORDER_PRODUCT: '/api/order/addOrderItems',
    API_URL_FOR_GET_ORDER:"/api/order/",
    API_URL_FOR_ADMIN_REORDER:"/api/order/adminReorder",
    API_URL_FOR_SUBMIT_ORDER:"/api/order/orderSubmitConfirm",
    API_URL_TO_REMOVE_TAX_INVOICE:"/api/users/taxinvoice/remove",
    API_URL_FOR_SAVE_DRAFT_ORDER:"/api/order/orderSubmitDraft",
    API_URL_FOR_DISCARD_ORDER:"/api/order/orderDiscard",
    API_URL_FOR_ADD_ITEM_TO_CART:"/api/cart/create",
    API_URL_FOR_ADD_ITEM_TO_WISHLIST:"/api/wishlist/create"
    
}

export default ApiList