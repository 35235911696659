import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from "moment";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { orderActions } from "../../_actions/order.actions";
import emptyTableData from '../../images/empty-table-data.png';
import exportButton from '../../images/export-button.png';
import Table from './ShippedOrderTable';
// import { OrderManagementFilter } from './OrderManagementFilter';
import FormControl from "@mui/material/FormControl";
import MenuItem from '@mui/material/MenuItem';
import Select from "@mui/material/Select";
import ApiList from "../../_constants/const.api";
import ApiProvider from "../../_services/ApiProvider";
import arrowIcon from '../../images/small-chevron-down.png';
import { OrderManagementFilter } from './FilterComponent/OrderManagementFilter';
let data;

const headCells = [
    {
        id: 'orderNo',
        numeric: false,
        disablePadding: true,
        label: 'Order No.',
        bold: false,
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Customer',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'totalOrderPrice',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'orderCreateDate',
        numeric: true,
        disablePadding: false,
        label: 'Order Date',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'createduserfirstnameen',
        numeric: true,
        disablePadding: false,
        label: 'Created by',
        align: "left",
        headerAlign: "left",
        sortable: false,
    },
    {
        id: 'userchannel',
        numeric: true,
        disablePadding: false,
        label: 'Channel',
        align: "left",
        headerAlign: "left",
        sortable: false,
    },
    {
        id: 'saleorder',
        numeric: true,
        disablePadding: false,
        label: 'SO ID',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: ' ',
        align: "left",
        headerAlign: "left",
        sortable: true,
    },
];

const ShippedOrder = () => {

    const [visible, setVisible] = useState(false)
    const [updateShippedDetails, setUpdateShippedDetails] = useState(false)
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);
    const _exporter = React.createRef();
    const excelExport = () => {
        save(_exporter);
    };
    const save = component => {
        const options = component.current.workbookOptions();
        options.sheets[0].name = "Shipped";
        const rows = options.sheets[0].rows;
        let altIdx = 0;
        rows.forEach(row => {
            if (row.type === 'data') {
                if (altIdx % 2 !== 0) {
                    row.cells.forEach(cell => {
                        cell.background = '#aabbcc';
                    });
                }
                altIdx++;
            }
        });
        component.current.save(options);
    };

    const { t } = useTranslation();
    const lancode = localStorage.getItem("lancode");
    // const shippedOrderList = useSelector((state) => state.order.shippedOrderList);
    // const shippedOrderListLoading = useSelector((state) => state.order.shippedOrderListLoading);
    const ShippedOrderList = useSelector((state) => state.order.ShippedOrderList);
    const ShippedOrderLoading = useSelector((state) => state.order.ShippedOrderLoading);
    const [selected, setSelected] = React.useState([]);
    const [orderIds, setOrderId] = useState([])
    const [tableData, setTableData] = React.useState([]);
    const [excelselectedData, setExcelselectedData] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const detaulsSort = { id: "createdAt", orderBy: -1 }
    const [sortDetails, setsetSort] = React.useState(detaulsSort);
    const [loading, setLoading] = useState(false);
    const [attrFilter, setAttrFilter] = useState({});
    const [filtersFields, setFiltersFields] = useState({});
    const [todaysdate, setTodaysdate] = React.useState(moment().format("DDMMYYYY"));
    const [filenames, setFilenames] = React.useState("shipped_" + todaysdate + ".xlsx");
    const userData = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : undefined;

    const dispatch = useDispatch();
    const history = useHistory();


    const isNull = (value) => {
        return value == "" || value == undefined || value == null
    }
    var storeCode = !isNull(userData) ? userData.user.stores[0].storeCode : "";



    React.useEffect(() => {
        setLoading(true)
        if (!userData) {
            history.push('/login')
        } else {
            if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
                history.push('/')
            }
        }
        if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
            const payload = {
                storeCode: storeCode,
            }
            dispatch(orderActions.getShippedList("", payload, `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
        }
    }, [updateShippedDetails])
    React.useEffect(() => {
        setLoading(true)
        if (ShippedOrderList?.data?.result) {
            const filterData = ShippedOrderList.data.result.map(items => {
                items.orderCreateDate = moment(items.ordercreatedat).format("DD/MM/YYYY")
                items.name = items.userfirstnameen + " " + items.userlastnameen;
                items.id = items.orderId;
                items.saleorder = items.saleorder
                return items
            })
            setTableData(filterData)
            setLoading(false)
            setTotalCount(ShippedOrderList.data.totalCount)
        } else {
            setTableData([])
            setTotalCount(0)
            setLoading(false)
        }


    }, [ShippedOrderList])
    React.useEffect(() => {

    }, [tableData])
    const handleChangeRowsPerPage = (event) => {
        setLoading(true)
        const payload = {
            "storeCode": storeCode,
        }

        dispatch(orderActions.getShippedList("", payload, `limit=${parseInt(event.target.value)}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))


        setRowsPerPage(parseInt(event.target.value));
        setPage(0);


    };


    const handleChangePage = (event, newPage) => {
        setLoading(true)
        let pages = (newPage - 1) * rowsPerPage
        const payload = {
            "storeCode": storeCode,
        }

        setPage(pages);
        dispatch(orderActions.getShippedList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${pages}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))

    };
    const setsetSortFn = (id, sortBy) => {
        setLoading(true)
        let columnKey = id
        if (id === 'name' || id === "name") {
            columnKey = 'firstNameEN'
        }
        else if (id === 'orderCreateDate' || id === "orderCreateDate") {
            columnKey = 'createdAt'
        }
        const payload = {
            "storeCode": storeCode,
        }
        dispatch(orderActions.getShippedList("", payload, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [columnKey]: sortBy }))}`))
        setsetSort({ ...sortDetails, id: columnKey, orderBy: sortBy })
    }
    if (excelselectedData.length > 0) {
        data = excelselectedData;
    }
    else {

        data = tableData;
    }
    const convertToCurrencyFormatNew = (value = 0) => {
        value = value === "null" ? 0 : value;

        const currency = Intl.NumberFormat("en-us").format(Number(value));

        let afterDecimal = currency.includes(".") ? currency.split(".")[1] : "00";

        if (afterDecimal.length === 1) {

            afterDecimal = `${afterDecimal}0`;

        }

        return `${currency.split(".")[0]}`;
    };

    data.map((items) => {
        items.orderCreateDate = moment(items.ordercreatedat).format("DD/MM/YYYY");
        items.orderCreateDate2 = moment(items.ordercreatedat).format("DD.MM.YYYY");
        items.totalOrderPrice2 = convertToCurrencyFormatNew(Number(items.totalOrderPrice));
        return items;
    })
    const filterShippedList = (selectedItem) => {
        let payload = {
            storeCode: storeCode,
        }
        if (selectedItem?.channelDefaultValue) {
            payload.userChannel = selectedItem?.channelDefaultValue
        }
        if (selectedItem?.deliveryTime) {
            payload.deliveryTime = selectedItem?.deliveryTime
        }
        if (selectedItem?.deliveryDate?.startDate) {
            payload.deliveryDate = {
                from: moment(selectedItem?.deliveryDate?.startDate).format("YYYY-MM-DD"),
                to: moment(selectedItem?.deliveryDate?.endDate).format("YYYY-MM-DD")
            }
        }
        if (selectedItem?.orderDate?.startDate) {
            payload.createdAt = {
                from: moment(selectedItem?.orderDate?.startDate).format("YYYY-MM-DD"),
                to: moment(selectedItem?.orderDate?.endDate).format("YYYY-MM-DD")
            }

        }
        setFiltersFields(payload)
        let merged = { ...payload, ...attrFilter };
        dispatch(orderActions.getShippedList("", merged, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
    }


    const searchItemByAttr = (query) => {
        let payload = { ...query, ...filtersFields };
        dispatch(orderActions.getShippedList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
        setAttrFilter(query)
    }
    const handleMarkAsCompleted = () => {
        let temp = orderIds.map(Number)
        let payload = {
            "orderId": temp,
            "status": "completed"
        };
        ApiProvider.post(ApiList.API_URL_TO_UPDATE_ORDER, payload).then((response) => {
            if (response) {
                const payload = {
                    "storeCode": storeCode,
                }
                setSelected([])
                dispatch(orderActions.getShippedList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
                dispatch(orderActions.getCompletedList("", payload,));
            }

        })
    }
    const updateShippedDetailsFn = () => {
        setUpdateShippedDetails(!updateShippedDetails)
    }

    return (
        <div>
            {/* {loading && (<Loader />)} */}

            <OrderManagementFilter
                filterShippedList={filterShippedList}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                searchItemByAttr={searchItemByAttr}
                totalCount={totalCount}
            />

            <div className='row'>
                <div className='col-sm-7 col-md-7'>
                    <div className='new-order-list-table-main-container'>
                        <div className="table-order-counts">
                            <h6 className='new-order-list-table-order-counts'>
                                {totalCount === 0 || !totalCount || totalCount === 1 ? totalCount + " Order" : totalCount + " Orders"}
                                {/* {totalCount} Orders */}
                            </h6>
                            {/* {totalCount != 0 && <h6 className="table-selected-counts">({selected.length} selected)</h6>} */}
                            {totalCount !== 0 && selected.length > 0 && (
                                <h6 className="table-selected-counts">({selected.length} selected)</h6>
                            )
                            }




                        </div>
                    </div>
                </div>
                {/* <div className='col-sm-4 col-md-4'>
                    <button type="button" className='export-button-main-container' disabled={selected.length == 0} onClick={() => handleMarkAsCompleted()}>
                       
                        <span className="export-button-heading">Mark as Completed</span>
                    </button>
                </div> */}
                {/* export-button-section start from here */}
                <div className='col-sm-2 col-md-2 pr-3 pl-0'>
                    <button className='export-button-main-container' onClick={excelExport}>
                        <img src={exportButton} className='export-button-image' />
                        <span className="export-button-heading">Export</span>
                    </button>
                    <ExcelExport data={data} fileName={filenames} ref={_exporter}>
                        <ExcelExportColumn field="orderNo" title="Order No." cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="name" title="Customer" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="totalOrderPrice2" title="Total(Baht)" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="orderCreateDate2" title="Order Date" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="createduserfirstnameen" title="Created by" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="userchannel" title="Channel" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
                        <ExcelExportColumn field="status" title="Status" cellOptions={{ textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />

                    </ExcelExport>
                </div>
                {/* export-button-section end */}
                <div className='col-sm-3 col-md-3 text-right'>
                    <button className={`${selected.length == 0 ? 'mark-as-skipped-button-main-container-disable' : 'mark-as-skipped-button-main-container'}`} disabled={orderIds.length == 0} onClick={() => handleMarkAsCompleted()} >
                        <span className={`${selected.length == 0 ? 'mark-as-skipped-button-heading-disable' : 'mark-as-skipped-button-heading'}`}>Mark as Completed</span>
                    </button>
                </div>
            </div>
            <div className='row'>
                {tableData?.length > 0 ?
                    <div className='col-sm-12 col-md-12 to-comfirm-table-main-container'>
                        {/* table will come */}
                        <Table
                            tableData={tableData}
                            headCells={headCells}
                            setSelected={setSelected}
                            setExcelselectedData={setExcelselectedData}
                            excelselectedData={excelselectedData}
                            selected={selected}
                            totalCount={totalCount}
                            setPage={setPage}
                            page={page}
                            setRowsPerPage={setRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            setsetSortFn={setsetSortFn}
                            sortDetails={sortDetails}
                            setOrderId={setOrderId}
                            orderIds={orderIds}
                            updateShippedDetails={updateShippedDetailsFn}
                        // orderUpdate={orderUpdate}
                        />

                    </div> :
                    <>

                        <div className='col-sm-12 col-md-12'>
                            <div className='table-empty-data-main-container'>
                                <img src={emptyTableData} className='table-empty-data-image' />
                                <h6 className='table-empty-data-heading'>No shipped order yet</h6>
                            </div>
                        </div>


                        <div className='row order-table-pagination-main-container'>
                            <div className='col-sm-7 order-table-pagination-inner-container'>
                                <div className="d-flex flex-row">
                                    <h6 className='order-table-pagination-count'>1-{rowsPerPage} of 0</h6>
                                    <h6 className='order-table-order-per-page'>Order per page</h6>
                                    <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                        <Select
                                            variant="outlined"
                                            value={rowsPerPage}
                                            displayEmpty
                                            disabled={totalCount == 0}
                                            sx={{
                                                "&:hover": {
                                                    "&& fieldset": {
                                                        border: "2px solid #61A60E;"
                                                    }
                                                }
                                            }}
                                            inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ position: "absolute", width: 11, height: 7, left: 37, bottom: 37, cursor: "pointer" }} /> }}
                                        >

                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={40}>40</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                        </div>


                    </>
                }
            </div>
        </div>
    )

}

export default ShippedOrder