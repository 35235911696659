import _ from 'lodash';
import React, { useCallback, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../SharedComponent/Loader/Loader";
import { createOrderAction } from "../../../_actions";
import ApiList from "../../../_constants/const.api";
import { convertToCurrencyFormat, isNull, setUpCartandSpecialPrices } from '../../../_helpers/commanFunction';
import ApiProvider from "../../../_services/ApiProvider";
import { REACT_APP_BASE_URL } from "../../../constant";
import SearchImage from "../../../images/Search.png";
import CrossImage from "../../../images/cross-black.png";
import blankImage from "../../../images/empty-image.png";
import minusProdouctButton from "../../../images/minus-product-button.png";
import addProdouctButton from "../../../images/plus-product-button.png";



const ProductInfoSearchInfo = (props) => {
    const dispatch = useDispatch();
    const [openSearchBox, setOpenSearchBox] = useState(false);
    const [loadingLoader, setLoadingLoader] = useState(false);
    const [customerCodeState, setCustomerCodeState] = useState(props.customerCode);
    const [loading, setLoading] = useState(false);
    const [cartQuantity, setCartQuantity] = useState(1);
    const [productPrice, setProductPrice] = useState(0);
    const [updateVal, setUpdateVal] = useState(false);
    // const orderDetails = useSelector((state) => state.createOrder.orderDetails);
    const orderDetailsByIdState = useSelector((state) => state.createOrder.orderDetailsById);
    const [products, setProducts] = useState([]);
    const [userQuery, setUserQuery] = useState("");
    const userData = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : undefined;

    const store = userData ? userData.user.stores[0].storeCode : "";

    const sendQuery = (query, customerCode) => { searchItem(query, customerCode) };
    const delayedQuery = useCallback(_.debounce((q, customerCode) => sendQuery(q, customerCode), 500), []);
    React.useEffect(() => {
        if (userQuery !== "") {
            // searchItem(userQuery,props.customerCode)
            setLoadingLoader(false)
            setLoading(false)
        }
    }, [orderDetailsByIdState])

    const onChangeHandler = (e) => {
        setLoadingLoader(true)
        setProducts([])
        setUserQuery(e.target.value);
        delayedQuery(e.target.value, props.customerCode);
    }

    const updateProductValue = (products) => {
        setUpdateVal(!updateVal)
        // props.updatedProductsValue(products)
    }

    const searchItem = (query, customerCode) => {
        const payload = { "searchItem": query, "storeCode": store, customerCode: customerCode, orderId: props.orderId }
        if (query.length !== 0 && query.length > 2) {
            setLoadingLoader(true)
            setOpenSearchBox(true)
            ApiProvider.post(ApiList.API_URL_TO_SEARCH_PRODUCT, payload).then((response) => {
                if (response) {
                    const filterArray = response?.data?.data?.result.map(items => {
                        if (isNull(items.conditionAmount)) {
                            items.currentUnitPrice = items.orderedquantity ? setUpCartandSpecialPrices(items.orderedquantity, items) : items.price1Scale1
                        } else {
                            items.currentUnitPrice = items.conditionAmount
                        }
                        return items;
                    })
                    setProducts(filterArray ? filterArray : [])

                    setLoadingLoader(false)
                } else {
                    setLoadingLoader(false)
                }

            })

        } else {
            setLoadingLoader(false)
            setProducts([])
        }
    }
    const addToCart = (e, item, index) => {
        e.preventDefault()
        setLoading(true)
        let temp = products;
        let count = cartQuantity
        temp[index].orderedquantity = count
        setProducts(temp)
        let x = [];
        x = temp[index].selectValues && temp[index].selectValues.length > 0 && temp[index].selectValues.map(selItem => {
            if (selItem.articaleCode === item.articaleCode) {
                selItem.orderedquantity = count
            }
            return selItem
        })
        let cartPayload = {
            orderId: props.orderId,
            orderItems: [
                {
                    articaleCode: item.articaleCode,
                    cartQuantity: 1
                }
            ]
        }
        ApiProvider.post(ApiList.API_URL_TO_ADD_ORDER_PRODUCT, cartPayload).then((response) => {
            if (response) {
                // let payload = {
                //     "storeCode": store,
                //     "flags":{"product":true}
                // }
                // dispatch(createOrderAction.getOrderDetails(props.orderId,payload))  
                let payload = {
                    "orderId": props.orderId,
                    "userId": props.customerUserId
                }
                dispatch(createOrderAction.getOrderDetailsByOrderId(payload))
                setLoading(true)
            } else {
                setLoading(false)
                // setLoadingLoader(false)
            }

        })
    }

    const updateQuantity = (e, type, itemDetails, index) => {
        e.preventDefault()
        // getselectedCartId(JSON.parse(JSON.stringify(products)))
        let count = itemDetails.orderedquantity;
        count = type == "add" ? count + 1 : count - 1
        let temp = products;
        let currentPrice = itemDetails.price1Scale1
        if (isNull(itemDetails.conditionAmount)) {
            currentPrice = itemDetails.orderedquantity ? setUpCartandSpecialPrices(count, itemDetails) : itemDetails.price1Scale1
        } else {
            currentPrice = itemDetails.conditionAmount
        }

        temp[index].orderedquantity = count
        setProducts(temp)
        let x = [];
        x = temp[index].selectValues && temp[index].selectValues.length > 0 && temp[index].selectValues.map(selItem => {
            if (selItem.articaleCode === itemDetails.articaleCode) {
                selItem.orderedquantity = count
                // selItem.currentUnitPrice = currentPrice
            }
            return selItem
        })

        if (count && count !== "0") {
            temp[index].currentUnitPrice = currentPrice
            setLoading(true)
            let payload = {
                orderId: props.orderId,
                orderItems: [
                    {
                        articaleCode: itemDetails.articaleCode,
                        cartQuantity: count
                    }
                ]
            }
            dispatch(createOrderAction.updateQty(payload))
        }
        if ((count == "0" || count < 1) && count !== "") {
            removeCart(e, props.orderId, itemDetails.articaleCode)
        }

    }

    const removeCart = (e, orderId, articaleCode) => {
        e.preventDefault()
        const payload = {
            "orderId": orderId,
            "articaleCode": articaleCode
        }

        setLoading(true)
        ApiProvider.post(ApiList.API_URL_TO_REMVOE_ORDER_ITES, payload).then((response) => {
            if (response) {
                // let payload = {
                //     "storeCode": store,
                //     "flags":{"product":true}
                // }
                // dispatch(createOrderAction.getOrderDetails(orderId,payload))  
                let payload = {
                    "orderId": props.orderId,
                    "userId": props.customerUserId
                }
                dispatch(createOrderAction.getOrderDetailsByOrderId(payload))
                setLoading(true)
            } else {
                setLoading(false)
            }

        })

    };

    const onChangeUnitPrice = (count, item) => {
        if (isNull(item.conditionAmount)) {
            return item.orderedquantity ? setUpCartandSpecialPrices(count, item) : item.price1Scale1
        } else {
            return item.conditionAmount
        }
    }

    const Highlighted = ({ text = '', highlight = '' }) => {
        if (!highlight.trim()) {
            return <span>{text}</span>
        }
        const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
        const parts = text.split(regex)
        return (
            <span>
                {parts.filter(part => part).map((part, i) => (
                    regex.test(part) ? <mark key={i} style={{ background: "none", color: "black", "padding": "0px" }}>{part}</mark> : <span key={i}>{part}</span>
                ))}
            </span>
        )
    }
    React.useEffect(() => {
        if (customerCodeState !== props.customerCode) {
            setOpenSearchBox(false)
            setProducts([])
            setUserQuery("")
        }
    }, [props.customerCode])
    return <>
        {loading && (<Loading />)}
        <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 p-0 product-info-search-bar-input-main-container">
            <div className="product-info-search-bar-main-container">
                <input type="text"
                    className="product-info-search-bar-input"
                    placeholder="Search product by Name, SKU, Category, Article Code"
                    onChange={onChangeHandler}
                    value={userQuery}
                //onClick={() => setOpenSearchBox(true)}
                />
                <img src={SearchImage} className="product-info-search-bar-image" />
                {openSearchBox ? <img src={CrossImage} className="product-info-search-cross-image"
                    onClick={() => {
                        setOpenSearchBox(false)
                        setProducts([])
                        setUserQuery("")
                    }} /> : null}
            </div>
            {/* search-bar-modal-section start from here */}
            {openSearchBox ?
                <div className="row">
                    <div className="col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12">
                        <div className="product-info-search-bar-dropdown-main-container">
                            {/* search-bar-result-section start from here */}
                            <div className="product-info-search-bar-information-main-container">

                                {isNull(products) && (userQuery) && <>
                                    <div className={"text-center"}>
                                        <RotatingLines
                                            strokeColor="#61A60E"
                                            strokeWidth="5"
                                            animationDuration="0.75"
                                            width="96"
                                            visible={loadingLoader}
                                        />
                                    </div>
                                    {/* {!loadingLoader &&
                    <div className="row">
                      <h6 className="all-product-inner-section-heading">{userQuery}</h6>
                      <h6 className="all-product-inner-section-inner-heading">{t("0productsfound.label")}</h6>
                      <div className="no-result-found-main-container">
                        <img src={NoResultFound} className="no-result-found-image" />
                        <h6 className="no-result-found-image-main-heading">{t("NoSearchResultFound.label")}</h6>
                      </div>
                    </div>
                  } */}

                                    {!loadingLoader && <h6 className="search-bar-no-result-found-heading">No Search Result Found</h6>}
                                </>
                                }

                                {products && products.length > 0 ?
                                    <>
                                        {products.map((item, index) => {
                                            return (
                                                <div className="row align-items-center">
                                                    {/* product image and product name */}
                                                    <div className='col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3'>
                                                        <div className='d-flex flex-row'>
                                                            <div className='product-info-search-bar-product-image-border'>
                                                                <img
                                                                    src={
                                                                        (item.picture.split(",")[0] && item.picture.split(",")[0] != undefined && item.picture.split(",")[0] != '') ?
                                                                            (REACT_APP_BASE_URL +
                                                                                "/api/download?" +
                                                                                item.picture.split(",")[0]
                                                                                ? REACT_APP_BASE_URL +
                                                                                "/api/download?" +
                                                                                item.picture.split(",")[0]
                                                                                : blankImage)
                                                                            : blankImage}
                                                                    className='product-info-search-bar-product-image' />
                                                            </div>
                                                            <div className="product-info-search-bar-product-heading-main-container">
                                                                <h6 className="product-info-search-bar-product-article-code">
                                                                    <Highlighted text={item.articaleCode} highlight={userQuery} />

                                                                </h6>
                                                                <span className='product-info-search-bar-product-product-name'>
                                                                    <Highlighted text={item.consumerNameTH} highlight={userQuery} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* stock */}
                                                    <div className="col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2">
                                                        {item.stock && item.stock !== null ?
                                                            <div className="product-info-search-bar-in-stock-border">
                                                                <h6 className="product-info-search-bar-in-stock-heading">{item.stock} in stock</h6>
                                                            </div>
                                                            :

                                                            <div className="product-info-search-bar-out-stock-border">
                                                                <h6 className="product-info-search-bar-out-stock-heading">Out of stock</h6>
                                                            </div>
                                                        }

                                                    </div>
                                                    {/* price */}
                                                    <div className="col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2">
                                                        <h6 className="product-info-search-bar-price-heading">฿
                                                            {
                                                                convertToCurrencyFormat(item.currentUnitPrice)
                                                            }
                                                        </h6>
                                                    </div>
                                                    {/* selection */}
                                                    <div className="col-xll-2 col-xl-2 col-md-2 col-sm-2 col-2 p-0">
                                                        {/* <h6 className="product-info-search-bar-select-heading text-center">1,300 g.</h6> */}
                                                        {
                                                            item && item.selectValues && item.selectValues.length > 1 ?
                                                                <div className="promo-desc">
                                                                    <select
                                                                        onChange={(e) => {
                                                                            let temp = item.selectValues
                                                                            let selectedItem = item.selectValues.find(f => f.articaleCode === e.target.value);
                                                                            let temp2 = item
                                                                            temp2.consumerNameEN = selectedItem.consumerNameEN;
                                                                            temp2.productNameEN = selectedItem.productNameEN;
                                                                            temp2.picture = selectedItem.picture;
                                                                            temp2.articaleCode = selectedItem.articaleCode;
                                                                            temp2.price1Scale1 = selectedItem.price1Scale1;
                                                                            temp2.price2Scale2 = selectedItem.price2Scale2;
                                                                            temp2.price3Scale3 = selectedItem.price3Scale3;
                                                                            temp2.price4Scale4 = selectedItem.price4Scale4;
                                                                            temp2.price5Scale5 = selectedItem.price5Scale5;
                                                                            temp2.packSizeEN = selectedItem.packSizeEN;
                                                                            temp2.stock = selectedItem.stock;
                                                                            temp2.orderedquantity = selectedItem.orderedquantity;
                                                                            temp2.currentUnitPrice = onChangeUnitPrice(selectedItem.orderedquantity, selectedItem)
                                                                            // temp2.selectValues.map((val) => {
                                                                            //   return val?.valueEN == selectedItem?.valueEN ? val.select = true : val.select = false
                                                                            // })

                                                                            updateProductValue(true)
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.selectValues.map((i, ind) => {
                                                                                return (<option selected={i.articaleCode === item
                                                                                    .articaleCode} value={i.articaleCode} key={ind}>{i.valueEN}</option>);

                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                :
                                                                <h6 className="product-info-search-bar-select-heading text-center">
                                                                    {
                                                                        item?.selectValues && item?.selectValues[0].valueEN
                                                                    }
                                                                </h6>
                                                        }

                                                    </div>
                                                    {/* add-minus-button */}
                                                    <div className="col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3">
                                                        {(item.orderedquantity == 0 || item.orderedquantity == null) ?

                                                            <button className="added-to-card-button"
                                                                onClick={(e) => addToCart(e, item, index)}
                                                            >
                                                                Add to cart
                                                            </button>
                                                            :
                                                            <div className="search-bar-desktop-add-to-cart-button-main-containers d-flex flex-row justify-content-between">
                                                                <img
                                                                    src={minusProdouctButton}
                                                                    className="search-bar-desktop-add-buttons"
                                                                    onClick={(e) => { updateQuantity(e, "minus", item, index) }}
                                                                />
                                                                <h6 className="search-bar-desktop-headings">{item.orderedquantity}</h6>
                                                                <img
                                                                    src={addProdouctButton}
                                                                    className="search-bar-desktop-minus-buttons"
                                                                    onClick={(e) => {
                                                                        updateQuantity(e, "add", item, index)
                                                                    }}
                                                                />
                                                            </div>
                                                        }

                                                    </div>
                                                    <div className="product-info-search-bar-gray-line" />
                                                </div>
                                            )
                                        })}
                                    </>
                                    :
                                    <></>
                                }


                            </div>
                            {/* search-bar-result-section start from here */}

                        </div>
                    </div>
                </div>

                : null
            }
            {/* search-bar-modal-section end*/}
        </div>
    </>
}
export default ProductInfoSearchInfo;