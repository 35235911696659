import { authHeader } from "../_helpers";
import { Axios } from "./axiosProvider";
import { REACT_APP_BASE_URL } from "../constant"
import ApiList from "../_constants/const.api";
import ApiProvider from "./ApiProvider";
export const orderService = {
  createcustomerdraftorder,
  createcustomerorder,
  // addedittaxinvoice,
  getPostalCodeInfo,
  getnewOrderList,
  getConfrimedOrderList,
  getShippedOrderList,
  getToConfirmList,
  getShippedList,
  getCompletedList,
  getCancelledList,
  getDraftList,
  getConfrimedOrderListexcel,
  getToConfirmListexcel,
  getToConfirmListByProduct,
  addOrderItems,
  updateItemStatus,
  updateStatus,
  activeList,
  historyList,
  toReview,
  userReorder,
  updateCancellationDetails,
  getbyorderID,
  reOrderProduct,
  orderDiscard,
  getOrderItemsByOrder,
  orderSubmitDraft,
  orderSubmitConfirm,
  removeOrderItems,

};

function orderDiscard(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/orderDiscard" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getOrderItemsByOrder(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getOrderItemsByOrder" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function orderSubmitDraft(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/orderSubmitDraft" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function orderSubmitConfirm(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/orderSubmitConfirm" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function removeOrderItems(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/removeOrderItems" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function activeList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/activeList?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function historyList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/historyList?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function toReview(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/toReview?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function userReorder(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/userReorder" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function updateCancellationDetails(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/updateCancellationDetails" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getbyorderID(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function updateStatus(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/updateStatus",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function updateItemStatus(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/updateItemStatus",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function addOrderItems(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/addOrderItems",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}



function createcustomerdraftorder(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.get(
    REACT_APP_BASE_URL + "/api/users",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}



function createcustomerorder(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.get(
    REACT_APP_BASE_URL + "/api/users",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}






function getPostalCodeInfo(payload) {

  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/postcode/",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getnewOrderList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/new?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getShippedList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/shipped?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getCompletedList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/completed?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getCancelledList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/cancelled?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getDraftList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/draft?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}


function getToConfirmList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/to-confirm?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getToConfirmListByProduct(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/byProduct?" + queryString,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

function getConfrimedOrderList(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/confirmed?" + queryString,
    payload,
    requestOptions
  )

    .then((response) => response)
    .catch((error) => error);
}


function getToConfirmListexcel(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/confirmed?" + queryString,
    payload,
    requestOptions
  )

    .then((response) => response)
    .catch((error) => error);
}

function getConfrimedOrderListexcel(payload, queryString) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/confirmedOrderExcel?" + queryString,
    payload,
    requestOptions
  )

    .then((response) => response)
    .catch((error) => error);
}



function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
// function getShippedOrderList(payload) {
//   ApiProvider.post(ApiList.API_URL_TO_GET_SHIPPED_ORDERS + "?limit=10&skip=0", payload).then((response) => response)
// }
function getShippedOrderList(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/getUserOrder/shipped?limit=10&skip=0",
    payload,
    requestOptions
  )
    .then((response) => {
      return response
    })
    .catch((error) => error);
}

function reOrderProduct(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/userReorder",
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}

