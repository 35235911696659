export const landingConstants = {
    SEARCH_WITHOUT_LOGIN: 'SEARCH_WITHOUT_LOGIN',
    SEARCH_WITH_LOGIN: 'SEARCH_WITH_LOGIN',
    SEARCH_WITHOUT_LOGIN_SUCCESS: "SEARCH_WITHOUT_LOGIN_SUCCESS",
    SEARCH_WITHOUT_LOGIN_FAILURE: "SEARCH_WITHOUT_LOGIN_FAILURE",
    SEARCH_WITHOUT_LOGIN_NO_DATA_FOUND: "SEARCH_WITHOUT_LOGIN_NO_DATA_FOUND",
    GET_ALL_MENU_REQUEST: 'GET_ALL_MENU_REQUEST',
    GET_ALL_MENU_SUCCESS: 'GET_ALL_MENU_SUCCESS',
    GET_ALL_MENU_FAIL: 'GET_ALL_MENU_FAIL',
    GET_ALL_MENU_NO_DATA_FOUND:'GET_ALL_MENU_NO_DATA_FOUND',

    PROMOTION_WITH_LOGIN: 'PROMOTION_WITH_LOGIN',
    PROMOTION_WITH_LOGIN_SUCCESS: 'PROMOTION_WITH_LOGIN_SUCCESS',
    PROMOTION_WITH_LOGIN_FAILURE: 'PROMOTION_WITH_LOGIN_FAILURE',
    PROMOTION_WITH_LOGIN_NODATAFOUND: 'PROMOTION_WITH_LOGIN_NODATAFOUND',

    PROMOTION_WITHOUT_LOGIN: 'PROMOTION_WITHOUT_LOGIN',
    PROMOTION_WITHOUT_LOGIN_SUCCESS: 'PROMOTION_WITHOUT_LOGIN_SUCCESS',
    PROMOTION_WITHOUT_LOGIN_FAILURE: 'PROMOTION_WITHOUT_LOGIN_FAILURE',
    PROMOTION_WITHOUT_LOGIN_NODATAFOUND: 'PROMOTION_WITHOUT_LOGIN_NODATAFOUND',

    NEW_ITEM_WITHOUT_LOGIN: 'NEW_ITEM_WITHOUT_LOGIN',
    NEW_ITEM_WITHOUT_LOGIN_SUCCESS: 'NEW_ITEM_WITHOUT_LOGIN_SUCCESS',
    NEW_ITEM_WITHOUT_LOGIN_FAILURE: 'NEW_ITEM_WITHOUT_LOGIN_FAILURE',
    NEW_ITEM_WITHOUT_LOGIN_NODATAFOUND: 'NEW_ITEM_WITHOUT_LOGIN_FAILURE',

    NEW_ITEM_WITH_LOGIN: 'NEW_ITEM_WITH_LOGIN',
    NEW_ITEM_WITH_LOGIN_SUCCESS: 'NEW_ITEM_WITH_LOGIN_SUCCESS',
    NEW_ITEM_WITH_LOGIN_FAILURE: 'NEW_ITEM_WITH_LOGIN_FAILURE',
    NEW_ITEM_WITH_LOGIN_NODATAFOUND: 'NEW_ITEM_WITH_LOGIN_NODATAFOUND'

};