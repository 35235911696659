import React from 'react';
import { connect } from 'react-redux';
import { Footer } from '../Footer';
import email from '../images/email.png';
import Card from '@mui/joy/Card';
import { Header } from '../Header';
import { Link, useHistory } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { userActions } from "../_actions";

const LoginCheckEmailFirst = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const useremailvalue = localStorage.setItem("emailactiveuser", email); 
    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);


    const resentemailAction = (e) => { 
              
          const payload = {
            type: "email",
            email: useremailvalue,
          };
          setSubmitted(true)
          setLoading(true)
          dispatch(userActions.forgotPassword("email", payload));
               
    
      };

    return (
        <center><Header />
            <Card variant="outlined" sx={{ width: 720, backgroundColor: "white" }}>

                <div className="col-md-6 col-md-offset-3">
                    <br></br>
                    <center><img src={email} alt="React Logo" style={{ width: 100, height: 60 }} /></center>
                    <br></br>

                    <h2><center>{t("checkEmail.label")}</center></h2>
                    <h5><center>{t("emailSent1.label")}.</center></h5>
                    <h5><center>{t("emailSent2.label")}.</center></h5>
                    <br></br>

                    <h5><center>{t("Didn'treceivecode.label")}<u><a href="#"   onClick={resentemailAction} style={{ fontSize: '15px', color: 'green', FontFace: 'underline' }}>{t("resend.label")}.</a></u></center></h5>

                </div></Card><Footer /></center>
    );

}




export { LoginCheckEmailFirst as LoginCheckEmailFirst };