import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import Card from '@mui/joy/Card';
import { Footer } from '../Footer';
import { Header } from '../Header';

import myprofileicon from '../images/myprofileicon.png';
import locationicon from '../images/locationicon.png';



class MyProfileChangePasswordSuccess extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        // this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            isChecked: true,
         
           
           
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
     
    }
  
    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }
     

    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;
        return ( <center>
       <Header />
                <h3>My Profile</h3>
                <Card variant="outlined" sx={{ width: 620, backgroundColor: "white" }}>
                    <div className="col-md-12">
                        <form name="form" onSubmit={this.handleSubmit}>
                            <center>
                                <table width="100%">
                                    <tr><td><h4><img src={myprofileicon} alt="React Logo" style={{ width: 20, height: 15 }} />Personal Information</h4></td></tr>
                                    <tr><td>
                                        <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                            <label htmlFor="username" style={{ alignItems: 'left', }}>Name</label> <label htmlFor="profilename" style={{ alignItems: 'left', }}>Sandee Usanachitt</label>
                                        </div></td></tr><tr><td>
                                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                                <label htmlFor="password" style={{ alignItems: 'left', }}>Customer ID</label><label htmlFor="password" style={{ alignItems: 'left', }}>JD 1343429023</label>
                                            </div></td></tr>
                                    <tr><td>
                                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                            <label htmlFor="password" style={{ alignItems: 'left', }}>Phone Number</label><label htmlFor="password" style={{ alignItems: 'left', }}>081-123-3456</label>
                                        </div></td></tr>
                                        <tr><td>
                                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                            <label htmlFor="password" style={{ alignItems: 'left', }}>Assigned Branch</label><label htmlFor="password" style={{ alignItems: 'left', }}><img src={locationicon} alt="React Logo" style={{ width: 20, height: 20 }} />323 location Province Bangok District Thung Song Hung Subdistrict Lak si Postal Code 10210 note optional </label>
                                        </div></td></tr>
                                    <tr><td>
                                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                            <label htmlFor="password" style={{ alignItems: 'left', }}>Email address</label><label htmlFor="password" style={{ alignItems: 'left', }}>sandee@gmail.com</label>
                                        </div></td></tr>
                                </table></center>
                        </form>
                    </div></Card>
                <Footer /></center>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedMyProfileChangePasswordSuccess = connect(mapState, actionCreators)(MyProfileChangePasswordSuccess);
export { connectedMyProfileChangePasswordSuccess as MyProfileChangePasswordSuccess };