import Axios from "axios";

Axios.interceptors.response.use(
  (response) => {
    if (
      response.headers["server"] === "cloudflare" &&
      response.status === 403
    ) {
      window.location.reload();
    }
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.headers["server"] === "cloudflare" &&
      error.response.status === 403
    ) {
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export { Axios };
