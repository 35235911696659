
import braisedchickenreviewpic from '../images/braisedchickenreviewpic.png';
import breakfastreviewpic from '../images/breakfastreviewpic.png';
import chickenreviewpic from '../images/chickenreviewpic.png';
import eggboxreviewpic from '../images/eggboxreviewpic.png';
import eggsingletrayreviewpic from '../images/eggsingletrayreviewpic.png';
import mincedmeatreviewpic from '../images/mincedmeatreviewpic.png';
import soupreviewpic from '../images/healthychickenpromo.png';





export const data = [braisedchickenreviewpic, breakfastreviewpic, chickenreviewpic, eggboxreviewpic, eggsingletrayreviewpic, mincedmeatreviewpic];
export const multiData = [
  braisedchickenreviewpic,
  breakfastreviewpic,
  chickenreviewpic,
  eggboxreviewpic,
  eggsingletrayreviewpic,
  mincedmeatreviewpic,
  soupreviewpic,
  braisedchickenreviewpic,
  breakfastreviewpic,
  chickenreviewpic,
  eggboxreviewpic,
  eggsingletrayreviewpic,
  mincedmeatreviewpic,
  soupreviewpic,
  braisedchickenreviewpic,
  breakfastreviewpic,
  chickenreviewpic,
  eggboxreviewpic,
  eggsingletrayreviewpic,
  mincedmeatreviewpic,
  soupreviewpic,
  braisedchickenreviewpic,
  breakfastreviewpic,
  chickenreviewpic,
  eggboxreviewpic,
  eggsingletrayreviewpic,
  mincedmeatreviewpic,
  soupreviewpic
  
 ];
