import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { userActions } from "../_actions";
import passwordchangedcheck from "../images/passwordchangedcheck.png";
import donebutton from "../images/donebutton.png";
import Card from "@mui/joy/Card";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Link, useHistory } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import "../LoginFirstTime/loginFirstTime.css";
import "./style.css";

const LoginResetPasswordSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Betagro Password Changed</title>
      </Helmet>
      <div className="hidden-sm">
        <Header />
      </div>
      <center>
        <div className="logIn-firstTime-outer loginResetScreen">
          <div className="logIn-firstTime">
            <img
              src={passwordchangedcheck}
              alt="React Logo"
              style={{ width: 80 }}
            />
            <h2>{t("Passwordchanged.label")}</h2>
            <h6>{t("Youhavesuccessfullyupdatedyourpassword.label")}</h6>
            <br></br>
            <button
              onClick={() => history.push("/login")}
              className="logIn-firstTimeBtn"
            >
              {t("Done.label")}
            </button>
            <br className="hidden-sm"/>
            <br className="hidden-sm"/>
          </div>
        </div>
      </center>
      <div className="hidden-sm">
        <Footer />
      </div>
    </>
  );
};

//const connectedLoginResetPasswordSuccess = connect(mapState, actionCreators)(LoginResetPasswordSuccess);
export { LoginResetPasswordSuccess as LoginResetPasswordSuccess };
