import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelOrderModal from '../../ModalScreens/CancelOrderModal/CancelOrderModal'
import ApiProvider from "../../_services/ApiProvider";
import ApiList from "../../_constants/const.api";
import Loading from "../../SharedComponent/Loader/Loader";
import { Base64 } from '../../_helpers/commanFunction'
import { useHistory } from "react-router-dom";
export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingLoader, setLoadingLoader] = useState(false);
  const [openCancell, setOpenCancel] = useState(false);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const reorder = () => {
    // console.log("Add: ", data.name);
    const payload = {orderId:props.row.orderId }       
    setLoadingLoader(true) 
    ApiProvider.post(ApiList.API_URL_FOR_ADMIN_REORDER, payload).then((response) => {
      if (response) {     
        setLoadingLoader(false)   
        history.push("/createOrder/" + Base64.encode(response?.data?.data?.orderId.toString()),{from:4});                    
      }else{
          setLoadingLoader(false)
      }})
    handleClose();
  };
  const taxData = () => {
    // console.log("remove: ", data.name);
    setAnchorEl(null);
    props.handleTaxData(props.row)

  };
  const toConfirm = () =>{
    
  }
  const isNull = (value) => {
    return value == "" || value == undefined || value == null
}
  return (
    <>
    {loadingLoader && (<Loading />)}
    <div id="vvvvvv">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{marginTop: 4}}
      >
        <MoreVertIcon  sx={{ fontSize: 24 }}/>
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="1" onClick={reorder}>
          Reorder
        </MenuItem>
        {!isNull(props.row["taxInvoiceDetails"]) && <span>
          <MenuItem key="2" onClick={taxData}>
            Tax INV
          </MenuItem>
        </span>}
      </Menu>
    </div>
    {openCancell && (
      <CancelOrderModal 
      row={props.row} 
      open={openCancell}
      setOpenCancel={setOpenCancel}
      firstLabel="Cancel order?"
      secondLabel="Are you sure you want to cancel this order?"
      firstBtnLabel="No, keep order"
      secondBtnLabel="Yes, cancel order"
      toConfirm={toConfirm}
       />
    )}
    </>
  );
}
