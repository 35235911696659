import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postcodeActions } from "../_actions/postcode.actions";
import { productActions } from "../_actions/product.actions";
import { userActions } from "../_actions/user.actions";
import { convertToCurrencyFormat } from '../_helpers/commanFunction';
import { REACT_APP_BASE_URL } from "../constant";
import blankImage from "../images/empty-image.png";
import promoBanner from "../images/PromoIconRed.svg";
import DownArrow from './Assets/SVG/DownArrow.svg';
import UpArrow from './Assets/SVG/UpArrow.svg';
import "./OrderSummary.css";
const OrderSummary = (props) => {
  const { t } = useTranslation();
  const lancode = localStorage.getItem("lancode");
  const userData = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : undefined;
  const [products, setProducts] = React.useState([]);
  const [showproductremaing, setShowproductremaing] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const cartDetails = useSelector((state) => state.product.cartDetails);
  const [subtotal, setSubtotal] = React.useState(0);
  let selecteduserincart = localStorage.getItem("selecteduserincart");
  const getcustomerdetails = useSelector((state) => state.users.getcustomerdetails);
  const getcustomeraddressdetails = useSelector((state) => state.users.getcustomeraddressdetails);
  const getcustomerpostalcode = useSelector((state) => state.postcode.getcustomerpostalcode);
  const createcustomerdraftorder = useSelector((state) => state.order.createcustomerdraftorder);
  const createcustomerorder = useSelector((state) => state.order.createcustomerorder);
  const addedittaxinvoice = useSelector((state) => state.order.addedittaxinvoice);
  const cartAddedDetails = useSelector((state) => state.product.cartAdded);
  const cartremoveDetails = useSelector((state) => state.product.cartRemove);
  const [selecteduserinfo, setSelecteduserinfo] = React.useState([]);
  const [selecteduseraddressesinfo, setSelecteduseraddressesinfo] =
    React.useState([]);
  const [postcodes, setPostcodes] = React.useState([]);
  const [customersdraftorder, setCustomersdraftorder] = React.useState([]);
  const [customersorder, setCustomersorder] = React.useState([]);
  const [addedittaxinvoiceinfo, setAddedittaxinvoiceinfo] = React.useState([]);


  React.useEffect(() => {
    dispatch(productActions.getCartData("payload", { userId: props.userId }))

    // dispatch(productActions.getCartData("payload", {}));
    dispatch(postcodeActions.getAll("payload", {}));

    let payload = {
      "selecteduserincart": selecteduserincart
    }
    dispatch(userActions.getcustomerdetailsbyid("payload", payload));
    dispatch(userActions.getcustomeraddressdetails("payload", {}));

  }, [cartAddedDetails, cartremoveDetails])

  const isNull = (value) => {
    return value == "" || value == undefined || value == null
  }
  React.useEffect(() => {
    //debugger;
    setLoading(false)
    /*  if (cartDetails && cartDetails.data && cartDetails.data.length > 0) {
       const newData = cartDetails.data.map((items) => {
         items.currentUnitPrice = setUpCartandSpecialPrices(
           items.cartQuantity,
           items
         );
         return items;
       });
       setProducts(newData);
     } else {
       setProducts([]);
     }
  */

    if (props?.selecteddata?.selectedProducts && props?.selecteddata?.selectedProducts?.length > 0) {
      const newData = props?.selecteddata?.selectedProducts?.map((items) => {
        items.currentUnitPrice = setUpCartandSpecialPrices(
          items.cartQuantity,
          items
        );
        return items;
      });
      setProducts(newData);
    } else {
      setProducts([]);
    }

    if (
      getcustomerpostalcode &&
      getcustomerpostalcode.data &&
      getcustomerpostalcode.data.length > 0
    ) {
      setPostcodes(getcustomerpostalcode.data);
    } else {
      setPostcodes([]);
    }

    if (getcustomerdetails && getcustomerdetails.data && getcustomerdetails.data.length > 0) {

      setSelecteduserinfo(getcustomerdetails.data);
    } else {
      setSelecteduserinfo();
    }


    if (getcustomeraddressdetails && getcustomeraddressdetails.data && getcustomeraddressdetails.data.length > 0) {

      setSelecteduseraddressesinfo(getcustomeraddressdetails.data);
    } else {
      setSelecteduseraddressesinfo([]);
    }


    let subtotalcalculation = 0;
    if (props?.selecteddata?.selectedProducts && props?.selecteddata?.selectedProducts.length > 0) {
      const newData = props?.selecteddata?.selectedProducts.map(items => {

        if (isNull(items.conditionAmount)) {
          items.currentUnitPrice = setUpCartandSpecialPrices(items.cartQuantity, items)
        } else {
          items.currentUnitPrice = items.conditionAmount
        }
        // items.currentUnitPrice = setUpCartandSpecialPrices(          
        //   items.cartQuantity,
        //   items
        // );
        return items;
      });
      newData.map(items => {
        subtotalcalculation = items.packSize !== "" ? subtotalcalculation + (items.currentUnitPrice * items.cartQuantity * Number(items.packSize)) : subtotalcalculation + (items.currentUnitPrice * items.cartQuantity);
      });
      setSubtotal(convertToCurrencyFormat(subtotalcalculation));
    } else { setSubtotal(0); }

  }, [cartDetails, subtotal, getcustomerpostalcode, getcustomerdetails])



  const setUpCartandSpecialPrices = (count, temp) => {
    let price;
    count = (count) ? parseInt(count) : 1;
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    // if (isNull(temp.conditionAmount)) {
    if ((Number(temp.quantity1Scale1) <= (count * packSize) || (count * packSize) < 1) && Number(temp.quantity1Scale1) != 0) {
      price = Number(temp.price1Scale1);
    }
    if (
      Number(temp.quantity2Scale2) <= (count * packSize) &&
      Number(temp.quantity2Scale2) != 0
    ) {
      price = Number(temp.price2Scale2);
    }
    if (
      Number(temp.quantity3Scale3) <= (count * packSize) &&
      Number(temp.quantity3Scale3) != 0
    ) {
      price = Number(temp.price3Scale3);
    }
    if (
      Number(temp.quantity4Scale4) <= (count * packSize) &&
      Number(temp.quantity4Scale4) != 0
    ) {
      price = Number(temp.price4Scale4);
    }
    if (
      Number(temp.quantity5Scale5) <= (count * packSize) &&
      Number(temp.quantity5Scale5) != 0
    ) {
      price = Number(temp.price5Scale5);
    }
    // if (count < Number(temp.quantity5Scale5)) {
    //   price = Number(temp.price5Scale5);
    // }
    return price;
    // }
  };

  // const routeToProductDetailsPage = (items) => {
  //   history.push("/productdetailscustomer", {
  //     articaleCode: items.articaleCode,
  //     storeCode: items.storeCode,
  //   }); // history.push("/productdetailscustomer")
  // };

  const routeToProductDetailsPage = (items) => {
    history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });
    // history.push("/productdetailscustomer", { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
  }

  const togglebutton = () => {
    if (showproductremaing == true) {
      setShowproductremaing(false);
    } else {
      setShowproductremaing(true);
    }
  };
  return (
    <>
      <div className="orderSummary">
        <h3 className="hidden-sm">{t("Summary.label")}</h3>
        <div className="orderSummaryProduct">
          {products &&
            products.slice(0, 3).map((items, i) => {
              return (
                <>
                  <div className="row orderSummaryProductCard align-items-center">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                      <div className="orderSummaryProductCardBadge">
                        {items && items.typeCode === "2" && (
                          <img
                            className="orderSummaryProductCardBadge-Img1"
                            alt=""
                            src={promoBanner}
                          />
                        )}
                        {items && items.typeCode === "3" && (
                          <img
                            className="orderSummaryProductCardBadge-Img2"
                            alt=""
                            src="../NewItemYellow.svg"
                          />
                        )}
                        {/* <img alt="" src={promoBanner} style={{ width: 80 }} /> */}
                      </div>
                      <div className="orderSummaryProductCardImg">
                        <img
                          onClick={() => routeToProductDetailsPage(items)}
                          src={
                            (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                              (REACT_APP_BASE_URL +
                                "/api/download?" +
                                items.picture.split(",")[0]
                                ? REACT_APP_BASE_URL +
                                "/api/download?" +
                                items.picture.split(",")[0]
                                : blankImage)
                              : blankImage
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6 col-6 padZero"

                    >
                      <h4 onClick={() => routeToProductDetailsPage(items)}>
                        {lancode === "en" && <>{items.consumerNameEN}</>}
                        {lancode === "th" && <>{items.consumerNameTH}</>}
                      </h4>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 pr-0">
                      <p>
                        ฿ {items.currentUnitPrice}.00
                        <span>x {items.cartQuantity}</span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          {showproductremaing &&
            products &&
            products.slice(3, products.length).map((items, i) => {
              return (
                <>
                  <div className="row orderSummaryProductCard">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                      <div className="orderSummaryProductCardBadge">
                        {items && items.typeCode === "2" && (
                          <img
                            className="orderSummaryProductCardBadge-Img1"
                            alt=""
                            src={promoBanner}
                          />
                        )}
                        {items && items.typeCode === "3" && (
                          <img
                            className="orderSummaryProductCardBadge-Img2"
                            alt=""
                            src="../NewItemYellow.svg"
                          />
                        )}
                        {/* <img alt="" src={promoBanner} style={{ width: 80 }} /> */}
                      </div>
                      <div className="orderSummaryProductCardImg">
                        <img
                          onClick={() => routeToProductDetailsPage(items)}
                          src={
                            (items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                              (REACT_APP_BASE_URL +
                                "/api/download?" +
                                items.picture.split(",")[0]
                                ? REACT_APP_BASE_URL +
                                "/api/download?" +
                                items.picture.split(",")[0]
                                : blankImage)
                              : blankImage
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-6 col-6 padZero"
                      onClick={() => routeToProductDetailsPage(items)}
                    >
                      <h4 className="card-product-name">
                        {lancode === "en" && <>{items.consumerNameEN}</>}
                        {lancode === "th" && <>{items.consumerNameTH}</>}
                      </h4>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                      <p>
                        ฿ {items.currentUnitPrice}.00
                        <span>x {items.cartQuantity}</span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        {
          products &&
          products.length > 3 && (
            <button
              type="button"
              className="hidden-sm orderSummaryViewMore"
              onClick={() => togglebutton()}
            >
              {showproductremaing ? (
                <span>{t("Viewless.label")}<img src={UpArrow} /></span>
              ) : (
                <span>{t("Viewmore.label")}<img src={DownArrow} /></span>
              )}
            </button>
          )}


        <div className="orderSummaryProductPrice">
          <div className="orderSummaryProductPriceSubtotal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <p>{t("Subtotal.label")}</p>
                <p>{t("shippingFee.label")}</p>
                <p>{t("Promotion.label")}</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <h4>฿ {subtotal}</h4>
                <h4>฿ 0.00</h4>
                <h4>฿ 0.00</h4>
              </div>
            </div>
          </div>
          <div className="orderSummaryProductPriceTotal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <p>{t("Total.label")}</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <h4>฿ {subtotal}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex flex-wrap align-items-center justify-content-center">
            {props.isFormValid && (
              props?.userDetail?.data?.role !== "New Customer" && <button
                onClick={() => props.handleConfrimOrder()}
                type="submit"
                className="orderSummaryConfirmBtn"
              >
                {(userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && t("CreateSaleOrderbtn.label")}
                {(userData.user.role !== 'Saleshop' && userData.user.role !== 'Admin') && t("Checkoutbtn.label")}
              </button>
            )}
            {!props.isFormValid &&
              (
                props?.userDetail?.data?.role !== "New Customer" && <button disabled className="orderSummaryConfirmBtnDisabled">
                  {(userData.user.role === 'Saleshop' || userData.user.role === 'Admin') && t("CreateSaleOrderbtn.label")}
                  {(userData.user.role !== 'Saleshop' && userData.user.role !== 'Admin') && t("Checkoutbtn.label")}
                </button>
              )}



            {/* <button className="orderSummaryConfirmBtnDisabled">Confirm Order</button> */}
            {(props?.userDetails?.user?.role === "Saleshop" || props?.userDetails?.user?.role === "Admin") && <>
              <button
                type="button"
                disabled={!props.isFormValid}
                className={`${props?.userDetail?.data?.role === "New Customer" ? (!props.isFormValid) ? "saveDraftDisabledBtn" : "orderSummaryConfirmBtn" : "orderSummarySaveDraftBtn"}`}
                onClick={() => props.handleDraftOrder()}
              >
                {t("Savedraft.label")}
              </button>
            </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export { OrderSummary as OrderSummary };

