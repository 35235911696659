import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { orderActions } from "../_actions/order.actions";
import { OrderListHead } from '../OrderListHead';
import { OrderManagementByOrder } from '../OrderManagementByOrder';
import { OrderManagementByProduct } from '../OrderManagementByProduct';
import CancellationOrder from './CancellationTable/CancellationOrder';
import CompletedOrder from './CompletedTable/CompletedOrder';
import Confirmed from './Confirmed/Confirmed';
import DraftOrder from './DraftTable/DraftOrder';
import { HamburgerTableMenu } from './HamburgerTableMenu/HamburgerTableMenu';
import './Index.css';
import NewOrderList from './NewOrderList/NewOrderList';
import ShippedOrder from './ShippedTable/ShippedOrder';
import { CommonTableHeader } from './TableHeader/TableHeader';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const [open, setOpen] = useState(true);


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const OrderList = (props) => {
    const [value, setValue] = React.useState(0);
    const [newOrderCount, setNewOrderCount] = React.useState();
    const [showViewByProduct, setShowViewByProduct] = useState(false);
    const [toConfirmCount, setToConfirmCount] = React.useState();
    const [confirmOrderCount, setConfirmOrderCount] = React.useState();
    const [shippedOrderCount, setShippedOrderCount] = React.useState();
    const [draftOrderCount, setDraftOrderCount] = React.useState();
    const [cancelledOrderCount, setCancelledOrderCount] = React.useState();
    const [completedOrderCount, setCompletedOrderCount] = React.useState();
    const CancelledOrderList = useSelector((state) => state.order.CancelledOrderList);
    const DraftOrderList = useSelector((state) => state.order.DraftOrderList);
    const toconfirmedOrderList = useSelector((state) => state.order.toconfirmedOrderList);
    const ShippedOrderList = useSelector((state) => state.order.ShippedOrderList);
    const CompletedOrderList = useSelector((state) => state.order.CompletedOrderList);
    const confirmedOrderList = useSelector((state) => state.order.confirmedOrderList);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const userData = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
    const store = userData ? userData.user.stores[0].storeCode : "";
    const dispatch = useDispatch();
    const history = useHistory();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tabValue = params.get('tab');

    const toggleValue = useSelector((state) => state.order.toggleValue);

    React.useEffect(() => {

    }, [toggleValue]);

    React.useEffect(() => {
        setLoading(true)
        if (!userData) {
            history.push('/login')
        } else {
            if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
                history.push('/')
            }
        }
        if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin') || userData?.user?.role === "Admin") {
            const payload = {
                "storeCode": store,

            }
            dispatch(orderActions.getCancelledList("", payload,));
            dispatch(orderActions.getConfrimedOrderList("", payload,));
            dispatch(orderActions.getToConfirmList("", payload,));
            dispatch(orderActions.getShippedList("", payload,));
            dispatch(orderActions.getCompletedList("", payload,));
            dispatch(orderActions.getDraftList("", payload,));

        }
        if (tabValue) {
            setValue(parseInt(tabValue));
        }
    }, [])

    React.useEffect(() => {
        if (CancelledOrderList?.data?.result) {
            setCancelledOrderCount(CancelledOrderList.data.totalCount);
        } else {
            setCancelledOrderCount(0);
        }
        if (DraftOrderList?.data?.result) {
            setDraftOrderCount(DraftOrderList.data.totalCount);
        } else {
            setDraftOrderCount(0);
        }
        if (toconfirmedOrderList?.data?.result) {
            setToConfirmCount(toconfirmedOrderList.data.totalCount);
        } else {
            setToConfirmCount(0);
        }
        if (ShippedOrderList?.data?.result) {
            setShippedOrderCount(ShippedOrderList.data.totalCount);
        } else {
            setShippedOrderCount(0);
        }
        if (CompletedOrderList?.data?.result) {
            setCompletedOrderCount(CompletedOrderList.data.totalCount);
        } else {
            setCompletedOrderCount(0);
        }

        if (confirmedOrderList?.data?.result) {
            setConfirmOrderCount(confirmedOrderList.data.totalCount);
        } else {
            setConfirmOrderCount(0);
        }


    }, [CancelledOrderList, DraftOrderList, toconfirmedOrderList, ShippedOrderList, CompletedOrderList, confirmedOrderList])

    const updateorderbadges = () => {
        if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
            const payload = {
                "storeCode": store,

            }
            dispatch(orderActions.getNewOrderList("", payload,));
            dispatch(orderActions.getCancelledList("", payload,));
            dispatch(orderActions.getConfrimedOrderList("", payload,));
            dispatch(orderActions.getToConfirmList("", payload,));
            dispatch(orderActions.getShippedList("", payload,));
            dispatch(orderActions.getCompletedList("", payload,));
            dispatch(orderActions.getDraftList("", payload,));
        }
    }

    const toggleViewByProduct = () => {
        setShowViewByProduct(true)
        // alert("toggle product");
    }

    const toggleViewByOrder = () => {
        setShowViewByProduct(false)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return <>
        <Helmet>
            <title>Betagro Order List</title>
        </Helmet>
        <CommonTableHeader />
        <div className='container-fluid' data-testid="order-list-screen-loading">
            <div className='row'>
                <HamburgerTableMenu />
                {/* table-section start from here */}
                <div className='col-sm-9 col-sm-9 col-9 pl-0 pr-0 common-gray-background'>
                    <div className='main-table-section'>
                        {/* <h6 className='order-list-section-main-heading'>Order List</h6> */}
                        <OrderListHead />
                        <div className='table-container-border'>
                            <div className='table-active-tabs' data-testid="tabs-menu-section">
                                {/* Tabs-menu-section start from here */}
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label={<span>New Orders
                                                {newOrderCount ? (
                                                    <span className='tabs-active-count'>
                                                        {(newOrderCount > 99 && newOrderCount > 0) ? "99+" : newOrderCount}
                                                    </span>) : null}

                                            </span>} {...a11yProps(0)} />
                                            <Tab label={<span>To Confirm {toConfirmCount ? (<span className='tabs-active-count'> {(toConfirmCount > 99 && toConfirmCount > 0) ? "99+" : toConfirmCount}</span>) : null}</span>}{...a11yProps(1)} />
                                            <Tab label={<span>Confirmed{confirmOrderCount ? (<span className='tabs-active-count'> {(confirmOrderCount > 99 && confirmOrderCount > 0) ? "99+" : confirmOrderCount}</span>) : null}</span>}{...a11yProps(2)} />
                                            <Tab label={<span>Shipped {shippedOrderCount ? (<span className='tabs-active-count'> {(shippedOrderCount > 99 && shippedOrderCount > 0) ? "99+" : shippedOrderCount}</span>) : null}</span>}{...a11yProps(3)} />
                                            <Tab label={<span>Completed
                                                {/* {completedOrderCount ? (<span className='tabs-active-count'> {(completedOrderCount > 99 && completedOrderCount > 0) ? "99+" : completedOrderCount}</span>) : null} */}
                                            </span>}{...a11yProps(4)} />
                                            <Tab label={<span>Draft{draftOrderCount ? (<span className='tabs-active-count'> {(draftOrderCount > 99 && draftOrderCount > 0) ? "99+" : draftOrderCount}</span>) : null}</span>}{...a11yProps(5)} />
                                            {/* <Tab label={<span>Cancellation{cancelledOrderCount ? (<span className='tabs-active-count'> {(cancelledOrderCount > 99 && cancelledOrderCount > 0) ? "99+" : cancelledOrderCount}</span>) : null}</span>}{...a11yProps(6)} /> */}
                                            <Tab label={<span>Cancellation</span>}{...a11yProps(6)} />
                                        </Tabs>
                                    </Box>
                                    {/* <OrderManagementFilter toggleViewByProduct={toggleViewByProduct} toggleViewByOrder={toggleViewByOrder} /> */}
                                    <TabPanel value={value} index={0}>
                                        <NewOrderList
                                            setNewOrderCount={setNewOrderCount} updateorderbadges={updateorderbadges}
                                            {...props}
                                        />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {/* showViewByProduct */}
                                        {toggleValue ? (
                                            <OrderManagementByOrder showviewproduct={showViewByProduct} setToConfirmCount={setToConfirmCount}
                                                {...props} updateorderbadges={updateorderbadges} />

                                        ) : (
                                            <OrderManagementByProduct {...props} updateorderbadges={updateorderbadges} />
                                        )}

                                        {/* <OrderManagementByOrder showviewproduct={showViewByProduct} setToConfirmCount={setToConfirmCount}
                                                {...props} />                                                                                 */}

                                        {/* <OrderManagementByOrder /> */}
                                        {/* <EnhancedTable /> */}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <Confirmed setConfirmOrderCount={setConfirmOrderCount} updateorderbadges={updateorderbadges} {...props} />
                                    </TabPanel>
                                    {/* Shipped Table */}
                                    <TabPanel value={value} index={3}>
                                        <ShippedOrder setShippedOrderCount={setShippedOrderCount} updateorderbadges={updateorderbadges} {...props} />
                                    </TabPanel>
                                    {/* Completed Table */}
                                    <TabPanel value={value} index={4}>
                                        <CompletedOrder setCompletedOrderCount={setCompletedOrderCount} updateorderbadges={updateorderbadges} {...props} />
                                    </TabPanel>
                                    {/* Draft Table */}
                                    <TabPanel value={value} index={5}>
                                        <DraftOrder setDraftOrderCount={setDraftOrderCount} updateorderbadges={updateorderbadges} {...props} />
                                    </TabPanel>
                                    {/* Cancellation Table */}
                                    <TabPanel value={value} index={6}>
                                        <CancellationOrder setCancelledOrderCount={setCancelledOrderCount} updateorderbadges={updateorderbadges} {...props} />
                                    </TabPanel>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
                {/* table-section start end */}
            </div>
        </div>
        {/* <CancelOrderModal /> */}
    </>
}
export default OrderList