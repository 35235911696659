import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';
import { data, multiData } from './data';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';
import Card from '@mui/joy/Card';
import {RiHeart3Fill} from 'react-icons/ri';
import userprofileicon from '../images/userprofileicon.png';
import ReactStars from "react-rating-stars-component";
import React from "react";
import { render } from "react-dom";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import me from '../images/me.jpg';



let slidesToShow = 3;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <ArrowBackIos style={{ color: 'blue', fontSize: '30px' }} />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <ArrowForwardIos style={{ color: 'blue', fontSize: '30px' }} />
        </div>
      )}
    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

const MultiCarouselReview = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 2;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 3;
  } else {
    slidesToShow = 3;
  }

  return (
    <div style={{ margin: '10px' }} className='carousel'>
      <Slider {...carouselProperties}>
        {multiData.map((item) => (
          <Card1 item={item} />
        ))}
      </Slider>
    </div>
  );
};

const ratingChanged = (newRating) => {
};
 
const Card1 = ({ item }) => {
  return (
    <Card variant="outlined" sx={{ width: 240, backgroundColor: "white", border:"none" ,}}>
        <img
        className='multi__image'
        src={item}
        alt=''
        style={{
          width: '100%',
          height: '90px',
          objectFit: 'contain',
          marginBottom: '5px',
        }}
      />
     <table >
     <tr><td>
     <ReactStars
    count={5}
    onChange={ratingChanged}
    size={24}
    isHalf={true}
    emptyIcon={<i className="far fa-star"></i>}
    halfIcon={<i className="fa fa-star-half-alt"></i>}
    fullIcon={<i className="fa fa-star"></i>}
    activeColor="#ffd700"
  /></td></tr><tr><td colSpan={2}><h5>Betagro Title</h5></td></tr>
  <tr><td>Betgro Description</td></tr><tr><td>Betgro Descriptio</td></tr>
     <tr><td><table><tr><td> <Stack direction="row" spacing={2}>
      <Avatar alt="Remy Sharp" src={me} style={{ width: 30, height: 30 }} />
       </Stack></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td><table><tr><td>Tara Gibson</td></tr> <tr><td>July 13 2022 </td></tr></table></td></tr></table></td><td></td></tr>
     </table>
    </Card>
  );
};


export { MultiCarouselReview as MultiCarouselReview };
