import { authHeader } from "../_helpers";
import { Axios } from "./axiosProvider";
import { REACT_APP_BASE_URL } from "../constant"
export const myAccountService = {  
  getOrderDetails
};

function getOrderDetails(orderId,payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.post(
    REACT_APP_BASE_URL + "/api/order/"+orderId,
    payload,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}
