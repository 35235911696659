import React, { useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { landingPageActions, userActions } from "../../_actions";
import arrowRight from '../../images/arrow-right-from-bracket.png';
import cartShoppingWhiteImage from '../../images/cart-shopping-white.png';
import cartShoppingImage from '../../images/cart-shopping.png';
import productManagementWhite from '../../images/money-bills-white.png';
import moneybills from '../../images/money-bills.png';
import settingIcon from '../../images/setting-icon.png';
import squareInfo from '../../images/square-info.png';
import userAvatar from '../../images/user-avatar-black.png';
import userAvatarWhite from '../../images/user-avatar-white.png';

const Active = () => {
    const location = useLocation();
    if (location.pathname == '/bulkupload') {
        return 'product'
    }
    if (location.pathname == '/usermanagement') {
        return 'usermanagement'
    }
    if (location.pathname == '/marketingmanagement') {
        return 'marketingmanagement'
    }
}

const HamburgerTableMenu = (props) => {
    var userData = JSON.parse(localStorage.getItem("user"));
    const location = useLocation();
    const dispatch = useDispatch();
    const [activeButton, setActiveButton] = useState(location.pathname != '/comingsoon' ? Active() : 'dashboard');
    const [activeGreenLine, setActiveGreenLine] = useState('');

    const history = useHistory();
    const orderlistredirect = () => {
        setActiveButton('order');
        setActiveGreenLine('orderlist');
        history.push("/orderDetails", {});
    }
    const ordercreateredirect = () => {
        setActiveButton('order');
        setActiveGreenLine('createorder');
        history.push("/createOrder", {});
    }


    const salesreportredirect = () => {

        history.push("/comingsoon", {});
        _handleActiveTab('product')

    }

    React.useEffect(() => {
        if (location.pathname == '/orderDetails' || props.page == "createOrder") {
            setActiveButton('order');
            setActiveGreenLine('orderlist')
        }
    }, [location.pathname])


    const _handleActiveTab = (active) => {
        setActiveButton(active);
        history.push("/comingsoon", { screen: active });
    }
    // Redirect bulk upload
    const _handleActiveProductTab = (active) => {
        setActiveButton(active);
        history.push("/bulkupload", { screen: active });
    }
    const _handleActiveUserManagementTab = (active) => {
        setActiveButton(active);
        history.push("/usermanagement", { screen: active });
    }
    const _handleActiveMarketingManagementTab = (active) => {
        setActiveButton(active);
        history.push("/marketingmanagement", { screen: active });
    }
    const logOut = () => {
        const payloadWithOutLogin = {
            typeCode: "2",
        };
        const payloadWithOutLoginNew = {
            typeCode: "3",
        };
        dispatch(userActions.logout(localStorage.getItem("authorization")));
        // setIsUserLoggedIn(false);
        localStorage.removeItem("user");
        localStorage.removeItem("authorization");
        localStorage.removeItem("keepMeLoggedIn");
        localStorage.removeItem("phone");
        localStorage.removeItem("password");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("authorization");
        dispatch(
            landingPageActions.getPromotionWithoutLogin(
                "promotion",
                payloadWithOutLogin
            )
        );
        dispatch(
            landingPageActions.getNewItemsDataWithOutLogin(
                "newItem",
                payloadWithOutLoginNew
            )
        );
        history.push("/");
    };
    return <>
        {/* new order menu section start from here */}
        <div className='col-sm-3 col-md-3 pl-0 common-gray-background'>
            <div className='new-order-menu'>
                <div className="new-order-menu-inner-container">
                    <div className="row2">
                        {/*<div className={activeButton == 'dashboard' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <div className="d-flex flex-row" onClick={() => _handleActiveTab('dashboard')}>
                                {activeButton == 'dashboard' ? <img src={dashboardWhite} className="humburger-menu-setting-icon" height={16} width={14} /> : <img src={dashboard} className="humburger-menu-setting-icon" height={16} width={14} />}
                                <h6 className={activeButton == 'dashboard' ? "actions-main-heading-active" : "actions-main-heading"}>Dashboard</h6>
                            </div>
                        </div>*/}
                        {userData && userData.user && ((userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) && <div className={activeButton == "order" ? "col-sm-12 left-menu-accordion-padding mt-20 active-accordion-color" : "col-sm-12 left-menu-accordion-padding  mt-20"}>
                            <Accordion defaultActiveKey={['13']} alwaysOpen className={activeGreenLine == 'orderlist' ? "left-accordion-menu-icon-active" : "left-accordion-menu-icon"}>
                                <Accordion.Item eventKey="14" expanded="true">
                                    <Accordion.Header>{activeButton == 'order' ? <img src={cartShoppingWhiteImage} className="order-management-shopping-icon" height={16} width={18} /> : <img src={cartShoppingImage} className="order-management-shopping-icon" height={16} width={18} />}<span className={activeButton == 'order' ? "order-menu-main-heading-active" : "order-menu-main-heading"}>Order Management</span></Accordion.Header>
                                    <Accordion.Body>

                                        <div className="action-menu-hover">
                                            {/* <span onClick={() => _handleActiveGreenLine()}><span className={activeGreenLine == 'createorder' ? "hover-green-color" : null}/>
                                        <h6 className="order-management-inner-heading">Create Order</h6></span> */}
                                            {/* <span onClick={() => ordercreateredirect()}><span className={activeGreenLine == 'createorder' ? "hover-green-color" : null} /><h6 className="order-management-inner-heading"  >Create Order</h6></span> */}
                                        </div>
                                        <div className="action-menu-hover">
                                            {/* <span onClick={() => _handleActiveGreenLine()}><span className={activeGreenLine == 'createorder' ? "hover-green-color" : null}/>
                                        <h6 className="order-management-inner-heading">Create Order</h6></span> */}
                                            <span onClick={() => orderlistredirect()}><span className={activeGreenLine == 'orderlist' ? "hover-green-color" : null} /><h6 className={activeGreenLine == 'orderlist' ? "order-management-inner-heading-active" : "order-management-inner-heading"}>Order List</h6></span>
                                        </div>


                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>}
                        {userData && userData.user && (userData.user.role == 'Saleshop' || userData.user.role == 'Admin') && <div className={activeButton == 'product' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <Link className="d-flex flex-row" onClick={() => _handleActiveProductTab('product')} to='#'>
                                {activeButton == 'product' ? <img src={productManagementWhite} className="humburger-menu-setting-icon" height={16} width={14} /> : <img src={moneybills} className="humburger-menu-setting-icon" height={16} width={14} />}
                                <h6 className={activeButton == 'product' ? "actions-main-heading-active" : "actions-main-heading"}>Product Management</h6>
                            </Link>
                        </div>}
                        {userData && userData.user && (userData.user.role == 'Saleshop' || userData.user.role == 'Admin') && <div className={activeButton == 'usermanagement' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <Link className="d-flex flex-row" onClick={() => _handleActiveUserManagementTab('usermanagement')} to='#'>
                                {activeButton == 'usermanagement' ? <img src={userAvatarWhite} className="humburger-menu-setting-icon" height={16} width={14} /> : <img src={userAvatar} className="humburger-menu-setting-icon" height={16} width={14} />}
                                <h6 className={activeButton == 'usermanagement' ? "actions-main-heading-active" : "actions-main-heading"}>User Management</h6>
                            </Link>
                        </div>}
                        {userData && userData.user && (userData.user.role == 'Marketing' || userData.user.role == 'Admin') && <div className={activeButton == 'marketingmanagement' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <Link className="d-flex flex-row" onClick={() => _handleActiveMarketingManagementTab('marketingmanagement')} to='#'>
                                {activeButton == 'marketingmanagement' ? <img src={userAvatarWhite} className="humburger-menu-setting-icon" height={16} width={14} /> : <img src={userAvatar} className="humburger-menu-setting-icon" height={16} width={14} />}
                                <h6 className={activeButton == 'marketingmanagement' ? "actions-main-heading-active" : "actions-main-heading"}>Marketing Tools</h6>
                            </Link>
                        </div>}
                        {/* <div className={ activeButton == 'review' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <div className="d-flex flex-row">
                            {activeButton == 'review' ? <img src={reviewWhite} className="humburger-menu-setting-icon" /> : <img src={fileImport} className="humburger-menu-setting-icon" />} 
                                <h6 className={activeButton == 'review' ? "actions-main-heading-active" : "actions-main-heading"}>Review</h6>
                            </div>
                        </div> */}
                        {/* <div className={ activeButton == 'sales' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <div className="d-flex flex-row">
                                {activeButton == 'sales' ? <img src={salesReportWhite} className="humburger-menu-setting-icon" /> : <img src={circleDollar} className="humburger-menu-setting-icon" />}
                                <h6 className={activeButton == 'sales' ? "actions-main-heading-active" : "actions-main-heading cursor-pointer-none"}>Sales Report</h6>
                            </div>
                        </div> */}
                    </div>
                    <div className="row humbuger-menu-setting-action">
                        <div className={activeButton == 'shop' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <div className="d-flex flex-row">
                                <img src={settingIcon} className="humburger-menu-setting-icon" height={16} width={16} />
                                <h6 className={activeButton == 'shop' ? "actions-main-heading-active" : "actions-main-heading cursor-pointer-none"}>Shop Setting</h6>
                            </div>
                        </div>
                        <div className={activeButton == 'help' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <div className="d-flex flex-row">
                                <img src={squareInfo} className="humburger-menu-setting-icon" height={16} width={14} />
                                <h6 className={activeButton == 'help' ? "actions-main-heading-active" : "actions-main-heading cursor-pointer-none"}>Help Center</h6>
                            </div>
                        </div>
                        <div className={activeButton == 'logout' ? "col-sm-12 col-md-12 setting-action-icon-active" : "col-sm-12 col-md-12 setting-action-icon-margin"}>
                            <div className="d-flex flex-row">
                                <img src={arrowRight} className="humburger-menu-setting-icon" height={16} width={16} />
                                <h6 className={activeButton == 'logout' ? "actions-main-heading-active" : "actions-main-heading"} onClick={logOut}>Log out</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* new order menu section end */}
    </>
}
export { HamburgerTableMenu as HamburgerTableMenu };

