import Axios from "axios";
import React from 'react';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { userActions } from '../_actions';
import { Footer } from '../Footer';
import { Header } from '../Header';
import Loader from '../SharedComponent/Loader/Loader';

import { REACT_APP_BASE_URL } from "../constant";



const LoginMailVerification = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const userState = useSelector((state) => state.users);
  const LoginDetails = location.state

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');


  const resendMail = (e) => {
    e.preventDefault();
    const payload = {
      type: "email",
      email: LoginDetails.email,
    };
    setSubmitted(true)
    setLoading(true)
    dispatch(userActions.firtTimeLogin("email", payload));
  }


  React.useEffect(() => {
    setLoading(false)



  }, [userState.forgotwithEmail]);

  React.useEffect(() => {
    setLoading(true)
    if (token) {
      Axios.post(
        REACT_APP_BASE_URL + "/api/users/verifyEmail",
        { "token": token }
      )
        .then(response => {
          if (response.data.code === 200) {
            setLoading(false)
            localStorage.setItem("x-auth-token", response.data.data.token)
            let forgot = localStorage.getItem("resetpassword");
            if (forgot === "true" || forgot === true) {
              history.push("/logincreatepassword?resetpassword=true", { userDetails: response.data.data });
            } else {
              history.push("/logincreatepassword", { userDetails: response.data.data });
            }


          } else {

          }

        })
        .catch((error) => {

        });
      //
    }


  }, []);

  return (
    <>

      <Helmet>
        <title>Betagro Email verification</title>
      </Helmet>
      {loading && (<Loader />)}
      <Header />
      <div style={{ minHeight: "200px" }}></div>
      <Footer />

    </>

  )
}

export { LoginMailVerification as LoginMailVerification };

