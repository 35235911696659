import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import './index.css';
import { store } from './_helpers';
import { App } from './App';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/css/bootstrap.css';
import { datadogMonitoring } from './utils/monitoring';
import { ENVIRONMENT } from './constant';
const domNode = document.getElementById('app');
const root = createRoot(domNode);
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
if (ENVIRONMENT !== 'local')
{
    console.log = () => {};
    console.info = () => {};
}
setTimeout(() => {
    datadogMonitoring();
}, 0);
 