import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import AddToCartModalMobile from "../ModalScreens/AddToCartModal/addToCardMobileModal";
import { AddToCartModal } from "../ModalScreens/AddToCartModal/addToCardModal";
import ThankYouForPurchaseModal from "../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal";
import MyAccountHamburgerMenu from "../MyOrder/MyAccountHamburgerMenu/MyAccountHamburgerMenu";
import Loading from "../SharedComponent/Loader/Loader";
import { productActions } from "../_actions";
import { REACT_APP_BASE_URL } from "../constant";
import promoBanner from "../images/PromoIconRed.svg";
import AlertImage from "../images/alert1.png";
import blankImage from "../images/empty-image.png";
import EmptyWishlist from "./Assets/Images/EmptyWishlist.png";
import AddToCart from "./Assets/SVG/AddToCart.svg";
import AddToCartBlack from "./Assets/SVG/AddToCartBlack.svg";
import Wishlist from "./Assets/SVG/Wishlist.svg";
import "./MyAccountWishlist.css";

const MyAccountWishlist = () => {
  const [visible, setVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState("my-wishlist");

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [wishlistdata, setWishlistdata] = React.useState([]);
  const userState = useSelector((state) => state.users);
  const searchState = useSelector((state) => state.landing);
  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const isNull = (value) => {
    return value === "" || value === undefined || value === null;
  };
  const dispatch = useDispatch();
  var storeCode = !isNull(userData) ? userData.user.stores[0].storeCode : "";
  var token = !isNull(userData) ? userData.token : "";
  const whishlistDetailsInformationList = useSelector(
    (state) => state.product.whishlistDetailsInformationList
  );
  const cartDetails = useSelector((state) => state.product.cartDetails);
  const addWishlistDetails = useSelector(
    (state) => state.product.addWishlistDetails
  );
  const [tableData, setTableData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [productPrice, setProductPrice] = React.useState(0);
  const [cartQuantity, setCartQuantity] = React.useState(0);
  const [productData, setProductData] = React.useState([]);
  const [wishlistStatus, setWishlistStatus] = React.useState(true);
  const [mainHeading, setMainHeading] = useState("");
  const [subHeading1, setSubHeading1] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [addedToCardModal, setAddedToCardModal] = useState(false);
  const defaultsSort = { id: "orderCreateDate", orderBy: 1 };
  const [sortDetails, setsetSort] = React.useState(defaultsSort);
  const [updateEffect, setUpdateEffect] = useState(false);
  const [cartcountitems, setCartcountitems] = React.useState(0);

  const cartAddedDetails = useSelector((state) => state.product.cartAdded);

  const setUpCartandSpecialPrices = (temp, count) => {
    let price = temp.price1Scale1;
    if (count === null || count === undefined) {
      count = Number(1);
    }
    let packSize = 1;
    if (temp.packSize) {
      packSize = parseFloat(temp.packSize);
    }
    if (isNull(temp.conditionAmount)) {
      if (
        Number(temp.quantity1Scale1) <= (count * packSize) &&
        Number(temp.quantity1Scale1) != 0
      ) {
        price = Number(temp.price1Scale1);
      }
      if (
        Number(temp.quantity2Scale2) <= (count * packSize) &&
        Number(temp.quantity2Scale2) != 0
      ) {
        price = Number(temp.price2Scale2);
      }
      if (
        Number(temp.quantity3Scale3) <= (count * packSize) &&
        Number(temp.quantity3Scale3) != 0
      ) {
        price = Number(temp.price3Scale3);
      }
      if (
        Number(temp.quantity4Scale4) <= (count * packSize) &&
        Number(temp.quantity4Scale4) != 0
      ) {
        price = Number(temp.price4Scale4);
      }
      if (
        Number(temp.quantity5Scale5) <= (count * packSize) &&
        Number(temp.quantity5Scale5) != 0
      ) {
        price = Number(temp.price5Scale5);
      }
    } else {
      price = Number(temp.conditionAmount);
    }

    return price;
  };

  const store = userData ? userData.user.stores[0].storeCode : "";
  const loadingHide = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  React.useEffect(() => {
    if (addWishlistDetails?.data)
      dispatch(productActions.getWishListInformation("payload", {}, ""));
  }, [addWishlistDetails]);

  React.useEffect(() => {
    if (cartAddedDetails?.data)
      dispatch(productActions.getCartData("payload", {}));
  }, [cartAddedDetails]);
  const location = useLocation()
  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      localStorage.setItem('prevPath', location.pathname)
      history.push("/login");
    }
    if (userData) {
      let payload = {};
      let queryString = "?limit=60&skip=0";
      // dispatch(
      //   productActions.getWishListInformation("",payload,`?limit=100000&skip=0`));
      // dispatch(productActions.getCartData("payload", payload));
    }
    loadingHide();
  }, [updateEffect, cartQuantity]);

  React.useEffect(() => {
    // setLoading(true);
    //debugger;
    if (cartDetails?.data) {
      setCartcountitems(cartDetails?.data.totalCount);
    }
    if (whishlistDetailsInformationList?.data) {
      if (
        whishlistDetailsInformationList.data &&
        whishlistDetailsInformationList.data.result.length > 0
      ) {
        const newData = whishlistDetailsInformationList.data.result.map(
          (items, index) => {
            items.currentUnitPrice = setUpCartandSpecialPrices(
              items,
              items.cartQuantity
            );
            if (isNull(items.conditionAmount)) {
              items.currentUnitPrice = setUpCartandSpecialPrices(
                items,
                items.cartQuantity
              );
            } else {
              items.currentUnitPrice = items.conditionAmount;
            }
            return items;
          }
        );
        setTableData(newData);
      }
      loadingHide();
      setTotalCount(whishlistDetailsInformationList?.data.totalCount);
    } else {
      setTableData([]);
      loadingHide();
      setTotalCount(0);
    }
  }, [
    whishlistDetailsInformationList,
    wishlistStatus,
    updateEffect,
    cartQuantity,
    cartDetails,
  ]);
  const routeToProductDetailsPage = (items) => {
    history.push("/productdetailscustomer/" + items.articaleCode, {
      articaleCode: items.articaleCode,
      storeCode: items.storeCode,
    }); // history.push("/productdetailscustomer")
  };
  const totalPages =
    whishlistDetailsInformationList?.data &&
      whishlistDetailsInformationList?.data.totalCount
      ? Math.ceil(
        parseInt(whishlistDetailsInformationList?.data.totalCount) /
        parseInt(rowsPerPage)
      )
      : null;

  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    let payload = {};
    //setRowsPerPage(parseInt(event.target.value));
    `?limit=100000&skip=0`;
    // dispatch(productActions.getWishListInformation("",payload,`?limit=${parseInt(event.target.value)}&skip=${page}`));
    dispatch(
      productActions.getWishListInformation("", payload, `?limit=100000&skip=0`)
    );
    setLoading(false);
    //eachtimeupdate();
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    //debugger;
    setLoading(true);
    let pages = (newPage - 1) * rowsPerPage;
    let payload = {};
    setPage(pages);
    dispatch(
      productActions.getWishListInformation("", payload, `?limit=100000&skip=0`)
    );
    // eachtimeupdate();
    setLoading(false);
  };

  const AddItemToCartslice04 = (items, index) => {
    setLoading(true);
    setAddedToCardModal(true);
    let temp = tableData;
    let count = 0;
    let price = productPrice;
    count = count + 1;
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    count >= 1 && setCartQuantity(count);
    if (isNull(temp[index].conditionAmount)) {
      if (
        Number(temp[index].quantity1Scale1) <= (count * packSize) &&
        Number(temp[index].quantity1Scale1) != 0
      ) {
        price = Number(temp[index].price1Scale1);
      }
      if (
        Number(temp[index].quantity2Scale2) <= (count * packSize) &&
        Number(temp[index].quantity2Scale2) != 0
      ) {
        price = Number(temp[index].price2Scale2);
      }
      if (
        Number(temp[index].quantity3Scale3) <= (count * packSize) &&
        Number(temp[index].quantity3Scale3) != 0
      ) {
        price = Number(temp[index].price3Scale3);
      }
      if (
        Number(temp[index].quantity4Scale4) <= (count * packSize) &&
        Number(temp[index].quantity4Scale4) != 0
      ) {
        price = Number(temp[index].price4Scale4);
      }
      if (
        Number(temp[index].quantity5Scale5) <= (count * packSize) &&
        Number(temp[index].quantity5Scale5) != 0
      ) {
        price = Number(temp[index].price5Scale5);
      }
    } else {
      price = temp[index].conditionAmount;
    }
    temp[index].cartQuantity = count;
    temp[index].currentUnitPrice = price;
    setProductPrice(price);
    setTableData(temp);
    setProductData(items);
    let payload = {
      articaleCode: items.articaleCode,
      storeCode: items.storeCode,
      cartQuantity: count,
    };

    dispatch(productActions.addProductToCart("payload", payload));
    let payload3 = {};
    // dispatch(
    //   productActions.getWishListInformation(
    //     "",
    //     payload3,
    //     `?limit=100000&skip=0`
    //   )
    // );
    // setLoading(false);
  };

  const AddItemToCartnextslice = (items, index) => {
    setAddedToCardModal(true);
    setLoading(true);
    index = index + 4;
    let temp = tableData;
    let count = 0;
    let price = productPrice;
    count = count + 1;
    let packSize = 1;
    if (temp[index].packSize) {
      packSize = parseFloat(temp[index].packSize);
    }
    count >= 1 && setCartQuantity(count);
    if (isNull(temp[index].conditionAmount)) {
      if (
        Number(temp[index].quantity1Scale1) <= (count * packSize) &&
        Number(temp[index].quantity1Scale1) != 0
      ) {
        price = Number(temp[index].price1Scale1);
      }
      if (
        Number(temp[index].quantity2Scale2) <= (count * packSize) &&
        Number(temp[index].quantity2Scale2) != 0
      ) {
        price = Number(temp[index].price2Scale2);
      }
      if (
        Number(temp[index].quantity3Scale3) <= (count * packSize) &&
        Number(temp[index].quantity3Scale3) != 0
      ) {
        price = Number(temp[index].price3Scale3);
      }
      if (
        Number(temp[index].quantity4Scale4) <= (count * packSize) &&
        Number(temp[index].quantity4Scale4) != 0
      ) {
        price = Number(temp[index].price4Scale4);
      }
      if (
        Number(temp[index].quantity5Scale5) <= (count * packSize) &&
        Number(temp[index].quantity5Scale5) != 0
      ) {
        price = Number(temp[index].price5Scale5);
      }
    } else {
      price = temp[index].conditionAmount;
    }
    temp[index].cartQuantity = count;
    temp[index].currentUnitPrice = price;
    setProductPrice(price);
    setTableData(temp);
    setProductData(items);
    let payload = {
      articaleCode: items.articaleCode,
      storeCode: items.storeCode,
      cartQuantity: count,
    };

    dispatch(productActions.addProductToCart("payload", payload));
    let payload3 = {};
    // dispatch(
    //   productActions.getWishListInformation(
    //     "",
    //     payload3,
    //     `?limit=100000&skip=0`
    //   )
    // );
    // setLoading(false);
  };

  const RemoveItemToWishListslicenext = (status, items, index) => {
    setLoading(true);
    let temp = tableData;
    index = index + 4;
    temp[index].wishlistStatus = status;

    let payload = {
      articaleCode: items.articaleCode,
      wishlistStatus: status,
      storeCode: items.storeCode,
    };
    dispatch(productActions.addProductToWishList("payload", payload));
    // let payload3 = {};
    // dispatch(
    //   productActions.getWishListInformation(
    //     "",
    //     payload3,
    //     `?limit=100000&skip=0`
    //   )
    // );
    // setLoading(false);
    /* setMainHeading("ItemRemovedwishlist.label");
    setSubHeading1("ItemRemovedwishlist2.label");
    setOpenAlert(true); */

    let payload2 = {
      storeCode: items.storeCode,
      articleCode: items.articaleCode,
    };
    //  dispatch(productActions.getProductDetails("payload", payload2));
    setWishlistStatus(!wishlistStatus);
    setUpdateEffect(!updateEffect);
  };

  const RemoveItemToWishListslice04 = (status, items, index) => {
    setLoading(true);
    let temp = tableData;

    temp[index].wishlistStatus = status;

    let payload = {
      articaleCode: items.articaleCode,
      wishlistStatus: status,
      storeCode: items.storeCode,
    };
    dispatch(productActions.addProductToWishList("payload", payload));
    let payload3 = {};
    // dispatch(
    //   productActions.getWishListInformation(
    //     "",
    //     payload3,
    //     `?limit=100000&skip=0`
    //   )
    // );
    // setLoading(false);
    /* setMainHeading("ItemRemovedwishlist.label");
    setSubHeading1("ItemRemovedwishlist2.label");
    setOpenAlert(true); */
    setTableData(temp);

    let payload2 = {
      storeCode: items.storeCode,
      articleCode: items.articaleCode,
    };
    //  dispatch(productActions.getProductDetails("payload", payload2));
    setWishlistStatus(!wishlistStatus);
    setUpdateEffect(!updateEffect);
  };
  // Handling added to cart modal close
  const addedToCartModalClose = () => {
    setAddedToCardModal(false);
  };
  const handleAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <div className="headerFixed">
        <Header />
      </div>
      <Helmet>
        <title>Betagro My Wishlist</title>
      </Helmet>
      <ThankYouForPurchaseModal
        image={AlertImage}
        cart={true}
        buttonLabel={t("ok.label")}
        mainHeading={mainHeading}
        subHeading1={subHeading1}
        open={openAlert}
        ClickAction={handleAlert}
      />
      {loading && <Loading />}
      <div className="container-fluid my-order-main-container headerFixed-margin" data-testid="my-whishlist-screen-loading">
        <div className="row">
          <div className="col-xll-3 col-xl-3 col-md-3 col-sm-12" data-testid="left-menu-section">
            <MyAccountHamburgerMenu activeMenu={activeMenu} />
          </div>
          <div className="col-xll-9 col-xl-9 col-md-9 col-sm-12 p-0">
            <div className="myAccount myAccount-padxs">
              <div className="myAccountHead myAccountHeadWishlist">
                <button
                  className="backBtn visible-sm"
                  onClick={() => history.goBack()}
                >
                  {t("Back.label")}
                </button>
                <h2 data-testid="my-whishlist-heading"> {t("MyWishlist.label")}</h2>
                <h4 className="hidden-sm">
                  {totalCount} {t("Items.label")}
                </h4>
              </div>
              <div className="myAccountCard">
                <div className="row hidden-sm hidden-xs">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                    <p>
                      <span data-testid="product-heading"> {t("Product.label")}</span>
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 pl-0">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center justify-content-start p-0">
                        <p>
                          <span data-testid="unit-price-heading">{t("UnitPrice.label")}</span>
                        </p>
                        {/* <p style={{ color: "transparent" }}>{t("UnitPrice.label")}</p> */}
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12 col-12"></div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <span className="myAccountCard-borderBottom"></span>
                  </div>
                </div>
                <div className="myAccountCardWishListHeight">
                  {tableData &&
                    tableData.length > 0 &&
                    tableData.slice(0, 4).map((items, index1) => (
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                          <div className="myAccountWishListCard">
                            <div className="row d-flex align-items-center">
                              <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                <div className="myAccountWishListCard-product">
                                  <div className="myAccountWishListCard-product1">
                                    <button
                                      className="myAccountWishListCard-wishlistBtn"
                                      title=" Click to Remove From WishList "
                                      onClick={() =>
                                        RemoveItemToWishListslice04(
                                          false,
                                          items,
                                          index1
                                        )
                                      }
                                    >
                                      <img src={Wishlist} />
                                    </button>
                                  </div>
                                  <div className="myAccountWishListCard-product2">
                                    <div className="myAccountWishListCard-productImg">
                                      <div className="odmProduct-ProductDetails-Img-badge">
                                        {items.typeCode && items.typeCode === "2" && (
                                          <img
                                            alt=""
                                            src={promoBanner}
                                            style={{ width: "35px" }}
                                          />
                                        )}
                                        {items.typeCode && items.typeCode === "3" && (
                                          <div className="promo-img-badge1">
                                            <img
                                              alt=""
                                              src="../NewItemYellow.svg"
                                              style={{ width: "25px" }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <img
                                        title=" Click to Product Details"
                                        onClick={() =>
                                          routeToProductDetailsPage(items)
                                        }
                                        src={
                                          (items.picture && items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                            (REACT_APP_BASE_URL +
                                              "/api/download?" +
                                              items.picture.split(",")[0]
                                              ? REACT_APP_BASE_URL +
                                              "/api/download?" +
                                              items.picture.split(",")[0]
                                              : blankImage)
                                            : blankImage
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="myAccountWishListCard-product3">
                                    <div className="myAccountWishListCard-productText">
                                      <p>
                                        {" "}
                                        {!isNull(userData) &&
                                          (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                          items.articaleCode}
                                      </p>
                                      {lancode === "en" && (
                                        <h3
                                          title=" Click to Product Details"
                                          onClick={() =>
                                            routeToProductDetailsPage(items)
                                          }
                                        >
                                          {items.consumerNameEN}
                                        </h3>
                                      )}

                                      {lancode === "th" && (
                                        <h3
                                          title=" Click to Product Details"
                                          onClick={() =>
                                            routeToProductDetailsPage(items)
                                          }
                                        >
                                          {items.consumerNameTH}
                                        </h3>
                                      )}
                                      <div className="myAccountWishListCard-productText-xs">
                                        <h6>
                                          {"฿ " +
                                            (isNull(items.currentUnitPrice)
                                              ? isNull(items.conditionAmount)
                                                ? !isNull(items.price1Scale1) &&
                                                items.price1Scale1
                                                : items.conditionAmount
                                              : items.currentUnitPrice) +
                                            ".00"}
                                        </h6>
                                        {items.cartQuantity == 0 ||
                                          items.cartQuantity == null ? (
                                          <button
                                            title=" Click to Add to Cart "
                                            className="myAccountWishListCard-AddToCartBtn"
                                            onClick={() =>
                                              AddItemToCartslice04(items, index1)
                                            }
                                          >
                                            <img src={AddToCart} />
                                            {t("Addtocart.label")}
                                          </button>
                                        ) : null}
                                        {items.cartQuantity > 0 ? (
                                          <button
                                            title=" Click to Add to Cart "
                                            className="myAccountWishListCard-outofStock"
                                          >
                                            <img src={AddToCartBlack} />
                                            {t("AddedtoCart.label")}
                                          </button>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-12 col-12 p-0 hidden-sm">
                                {" "}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (!isNull(items.stock) ? (
                                    <h4> {items.stock} in Stock </h4>
                                  ) : (
                                    <></>
                                  ))}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (isNull(items.stock) ? (
                                    <h5> {t("OutofStock.label")}</h5>
                                  ) : (
                                    <></>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 hidden-sm">
                          <div className="myAccountWishListCard">
                            <div className="row d-flex align-items-center">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-start pl-0">
                                {/* {" "}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (!isNull(items.stock) ? (
                                    <h4> {items.stock} in Stock </h4>
                                  ) : (
                                    <></>
                                  ))}
                                {!isNull(userData) &&
                                  (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                  (isNull(items.stock) ? (
                                    <h5> {t("OutofStock.label")}</h5>
                                  ) : (
                                    <></>
                                  ))} */}
                                <h6>
                                  {"฿ " +
                                    (isNull(items.currentUnitPrice)
                                      ? isNull(items.conditionAmount)
                                        ? !isNull(items.price1Scale1) &&
                                        items.price1Scale1
                                        : items.conditionAmount
                                      : items.currentUnitPrice) +
                                    ".00"}
                                </h6>
                              </div>
                              {items.cartQuantity == 0 ||
                                items.cartQuantity == null ? (
                                <div className="col-lg-8 col-md-8 col-sm-8 col-8 d-flex justify-content-end pl-0">
                                  <button
                                    title=" Click to Add to Cart "
                                    className="myAccountWishListCard-AddToCartBtn"
                                    onClick={() =>
                                      AddItemToCartslice04(items, index1)
                                    }
                                  >
                                    <img src={AddToCart} />
                                    {t("Addtocart.label")}
                                  </button>
                                </div>
                              ) : null}
                              {items.cartQuantity > 0 ? (
                                <div className="col-lg-8 col-md-8 col-sm-8 col-8 d-flex align-items-center justify-content-end pl-0">
                                  <Link to={"/mycart"}>
                                    <button
                                      title=" Click to Go to Cart "
                                      className="myAccountWishListCard-outofStock"
                                    >
                                      <img src={AddToCartBlack} />
                                      {t("AddedtoCart.label")}
                                    </button>
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <span className="myAccountCard-borderBottom"></span>
                        </div>
                      </div>
                    ))}
                  {tableData &&
                    tableData.length > 0 &&
                    tableData
                      .slice(4, tableData.length)
                      .map((items, index1) => (
                        <div className="row d-flex align-items-center">
                          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                            <div className="myAccountWishListCard">
                              <div className="row d-flex align-items-center">
                                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                  <div className="myAccountWishListCard-product">
                                    <div className="myAccountWishListCard-product1">
                                      <button
                                        className="myAccountWishListCard-wishlistBtn"
                                        title=" Click to Remove From WishList "
                                        onClick={() =>
                                          RemoveItemToWishListslicenext(
                                            false,
                                            items,
                                            index1
                                          )
                                        }
                                      >
                                        <img src={Wishlist} />
                                      </button>
                                    </div>
                                    <div className="myAccountWishListCard-product2">
                                      <div className="myAccountWishListCard-productImg">
                                        <div className="odmProduct-ProductDetails-Img-badge">
                                          {items.typeCode &&
                                            items.typeCode === "2" && (
                                              <img
                                                alt=""
                                                src={promoBanner}
                                                style={{ width: "35px" }}
                                              />
                                            )}
                                          {items.typeCode &&
                                            items.typeCode === "3" && (
                                              <div className="promo-img-badge1">
                                                <img
                                                  alt=""
                                                  src="../NewItemYellow.svg"
                                                  style={{ width: "25px" }}
                                                />
                                              </div>
                                            )}
                                        </div>
                                        <img
                                          title=" Click to Product Details"
                                          onClick={() =>
                                            routeToProductDetailsPage(items)
                                          }
                                          src={
                                            (items.picture && items.picture.split(",")[0] && items.picture.split(",")[0] != undefined && items.picture.split(",")[0] != '') ?
                                              (REACT_APP_BASE_URL +
                                                "/api/download?" +
                                                items.picture.split(",")[0]
                                                ? REACT_APP_BASE_URL +
                                                "/api/download?" +
                                                items.picture.split(",")[0]
                                                : blankImage)
                                              : blankImage
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="myAccountWishListCard-product3">

                                      <div className="myAccountWishListCard-productText">
                                        <p>
                                          {" "}
                                          {!isNull(userData) &&
                                            (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                            items.articaleCode}
                                        </p>
                                        {lancode === "en" && (
                                          <h3
                                            title=" Click to Product Details"
                                            onClick={() =>
                                              routeToProductDetailsPage(items)
                                            }
                                          >
                                            {items.consumerNameEN}
                                          </h3>
                                        )}

                                        {lancode === "th" && (
                                          <h3 className="card-product-name">
                                            {items.consumerNameTH}
                                          </h3>
                                        )}
                                        <div className="myAccountWishListCard-productText-xs">
                                          <h6>
                                            {"฿ " +
                                              (isNull(items.currentUnitPrice)
                                                ? isNull(items.conditionAmount)
                                                  ? !isNull(items.price1Scale1) &&
                                                  items.price1Scale1
                                                  : items.conditionAmount
                                                : items.currentUnitPrice) +
                                              ".00"}
                                          </h6>
                                          {items.cartQuantity == 0 ||
                                            items.cartQuantity == null ? (
                                            <button
                                              title=" Click to Add to Cart "
                                              className="myAccountWishListCard-AddToCartBtn"
                                              onClick={() =>
                                                AddItemToCartnextslice(items, index1)
                                              }
                                            >
                                              <img src={AddToCart} />
                                              {t("Addtocart.label")}
                                            </button>
                                          ) : null}
                                          {items.cartQuantity > 0 ? (
                                            <button
                                              title=" Click to Add to Cart "
                                              className="myAccountWishListCard-outofStock"
                                            >
                                              <img src={AddToCartBlack} />
                                              {t("AddedtoCart.label")}
                                            </button>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12 p-0 hidden-sm">
                                  {" "}
                                  {!isNull(userData) &&
                                    (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                    (!isNull(items.stock) ? (
                                      <h4> {items.stock} in Stock </h4>
                                    ) : (
                                      <></>
                                    ))}
                                  {!isNull(userData) &&
                                    (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                    (isNull(items.stock) ? (
                                      <h5> {t("OutofStock.label")}</h5>
                                    ) : (
                                      <></>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 col-4 hidden-sm">
                            <div className="myAccountWishListCard">
                              <div className="row d-flex align-items-center">
                                <div className="col-lg-4 col-md-4 col-sm-6 col-6 d-flex justify-content-start p-0">
                                  {/* {" "}
                                  {!isNull(userData) &&
                                    (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                    (!isNull(items.stock) ? (
                                      <h4> {items.stock} in Stock </h4>
                                    ) : (
                                      <></>
                                    ))}
                                  {!isNull(userData) &&
                                    (userData.user.role === 'Saleshop' || userData.user.role === 'Admin') &&
                                    (isNull(items.stock) ? (
                                      <h5> {t("OutofStock.label")}</h5>
                                    ) : (
                                      <></>
                                    ))} */}
                                  <h6>
                                    {"฿ " +
                                      (isNull(items.currentUnitPrice)
                                        ? isNull(items.conditionAmount)
                                          ? !isNull(items.price1Scale1) &&
                                          items.price1Scale1
                                          : items.conditionAmount
                                        : items.currentUnitPrice) +
                                      ".00"}
                                  </h6>
                                </div>
                                {items.cartQuantity == 0 ||
                                  items.cartQuantity == null ? (
                                  <div className="col-lg-8 col-md-8 col-sm-6 col-6 d-flex justify-content-end pl-0">
                                    <button
                                      title=" Click to Add to Cart "
                                      className="myAccountWishListCard-AddToCartBtn"
                                      onClick={() =>
                                        AddItemToCartnextslice(items, index1)
                                      }
                                    >
                                      <img src={AddToCart} />
                                      {t("Addtocart.label")}
                                    </button>
                                  </div>
                                ) : null}
                                {items.cartQuantity > 0 ? (
                                  <div className="col-lg-8 col-md-8 col-sm-6 col-6 d-flex justify-content-end pl-0">
                                    <Link to={"/mycart"}>
                                      <button
                                        title=" Click to Go to Cart "
                                        className="myAccountWishListCard-outofStock"
                                      >
                                        <img src={AddToCartBlack} />
                                        {t("AddedtoCart.label")}
                                      </button>
                                    </Link>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <span className="myAccountCard-borderBottom"></span>
                          </div>
                        </div>
                      ))}
                  {!tableData ||
                    (tableData.length === 0 && (
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 myAccountWishlist-EmptyWishlist">
                          <img
                            src={EmptyWishlist}
                            className="myAccountWishlist-EmptyWishlistImg"
                          />
                          <p>
                            <span>{t("Yourwishlistisempty.label")}</span>
                          </p>
                          <Link to={"/allproducts"}>
                            {" "}
                            <button className="myAccountWishlist-continueShoppingBtn m-0 mt-4">
                              {t("ContinueShopping.label")}
                            </button>
                          </Link>
                        </div>
                        {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 hidden-sm hidden-xs">
                        <span className="myAccountCard-borderBottom"></span>
                      </div> */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add-to-cart-section start from here */}
      <div className="add-to-cart-modal-desktop-block">
        {addedToCardModal ? (
          <AddToCartModal
            visible={addedToCardModal}
            product={productData}
            quantity={cartQuantity}
            priceofproductchosen={productPrice}
            close={addedToCartModalClose}
          />
        ) : null}
      </div>
      <div className="common-display-block-mobile">
        {addedToCardModal ? (
          <AddToCartModalMobile
            visible={addedToCardModal}
            product={productData}
            quantity={cartQuantity}
            priceofproductchosen={productPrice}
            close={addedToCartModalClose}
          />
        ) : null}
      </div>
      {/* Add-to-cart-section end */}

      {/* footer-section start from here */}
      <div className="common-display-none-mobile">
        <Footer />
      </div>
      {/* footer-section start end */}
    </>
  );
};

export { MyAccountWishlist as MyAccountWishlist };

