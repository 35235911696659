export const stockConstants = {
    

    POST_UPLOAD_STOCK_REQUEST_INFORMATION:'POST_UPLOAD_STOCK_REQUEST_INFORMATION',
    POST_UPLOAD_STOCK_SUCCESS_INFORMATION:'POST_UPLOAD_STOCK_SUCCESS_INFORMATION',
    POST_UPLOAD_STOCK_NODATA_FOUND_INFORMATION:'POST_UPLOAD_STOCK_NODATA_FOUND_INFORMATION',
    POST_UPLOAD_STOCK_FAILURE_INFORMATION:'POST_UPLOAD_STOCK_FAILURE_INFORMATION',


};

