import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from "moment";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../../SharedComponent/Loader/Loader";
import { orderActions } from "../../_actions/order.actions";
import ApiList from "../../_constants/const.api";
import { convertToCurrencyFormat } from '../../_helpers/commanFunction';
import ApiProvider from "../../_services/ApiProvider";
import emptyTableData from '../../images/empty-table-data.png';
import exportButton from '../../images/export-button.png';
import arrowIcon from '../../images/small-chevron-down.png';
import { OrderManagementFilter } from './OrderManagementFilter';
import Table from './Table';

let data;



const headCells = [
  {
    id: 'orderNo',
    numeric: false,
    disablePadding: true,
    label: 'Order No.',
    bold: false,
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Customer',
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    id: 'totalOrderPrice',
    numeric: true,
    disablePadding: false,
    label: 'Total',
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    id: 'orderCreateDate',
    numeric: true,
    disablePadding: false,
    label: 'Order Date',
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    id: 'createduserfirstnameen',
    numeric: true,
    disablePadding: false,
    label: 'Created by',
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    id: 'userchannel',
    numeric: true,
    disablePadding: false,
    label: 'Channel',
    align: "left",
    headerAlign: "left",
    sortable: false,
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    align: "left",
    headerAlign: "left",
    sortable: false,
  },
  {
    id: 'download',
    numeric: true,
    disablePadding: false,
    label: ' ',
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
];

const Confirmed = (props) => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };


  window.addEventListener('scroll', toggleVisible);
  const _exporter = React.createRef();
  const _exporterspecialprice = React.createRef();

  const excelExport = () => {
    save(_exporter);
    savespecialprice(_exporterspecialprice);
    /* if (_exporter.current) {
        _exporter.current.save();
      } */
  };
  const savespecialprice = component => {
    const options = component.current.workbookOptions();
    options.sheets[0].name = "ราคาพิเศษ-2-4";
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    rows.forEach(row => {
      if (row.type === 'data') {
        if (altIdx % 2 !== 0) {
          row.cells.forEach(cell => {
            cell.background = '#aabbcc';
          });
        }
        altIdx++;
      }
    });
    component.current.save(options);
  };

  const save = component => {
    const options = component.current.workbookOptions();
    options.sheets[0].name = "Confirmed";
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    rows.forEach(row => {
      if (row.type === 'data') {
        if (altIdx % 2 !== 0) {
          row.cells.forEach(cell => {
            cell.background = '#aabbcc';
          });
        }
        altIdx++;
      }
    });
    component.current.save(options);
  };

  const { t } = useTranslation();
  const lancode = localStorage.getItem("lancode");
  const confirmedOrderList = useSelector((state) => state.order.confirmedOrderList);
  const confirmedOrderListexcel = useSelector((state) => state.order.confirmedOrderListexcel);
  const [selected, setSelected] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [tableDataExcel, setTableDataExcel] = React.useState([]);
  const [totalCountExcel, setTotalCountExcel] = React.useState(0);
  const [excelselectedData, setExcelselectedData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const detaulsSort = { id: "createdAt", orderBy: -1 }
  const [sortDetails, setsetSort] = React.useState(detaulsSort);
  const [pageSkip, setPageSkip] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [orderStatusUpdated, setOrderStatusUpdated] = React.useState(false);
  const [todaysdate, setTodaysdate] = React.useState(moment().format("DDMMYYYY"));
  const [filenames, setFilenames] = React.useState("confirmed_special_price_" + todaysdate + ".xlsx");
  const [filenamespecial, setFilenamespecial] = React.useState("confirmed_order-SO_report_" + todaysdate + ".xlsx");
  const [updateShippedDetails, setUpdateShippedDetails] = useState(false)

  // console.log("file names are  ..................",filenames);
  //console.log("file names are  special..................",filenamespecial);

  const userData = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
  const store = userData ? userData.user.stores[0].storeCode : "";

  const dispatch = useDispatch();
  const history = useHistory();
  const [attrFilter, setAttrFilter] = useState({});
  const [filtersFields, setFiltersFields] = useState({});


  const updateShippedDetailsFn = () => {
    setUpdateShippedDetails(!updateShippedDetails)
  }

  React.useEffect(() => {
    setLoading(true)
    if (!userData) {
      history.push('/login')
    } else {
      if (userData && !(userData.user.role === 'Saleshop' || userData.user.role === 'Admin')) {
        history.push('/')
      }
    }
    if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
      const payload = {
        "storeCode": store
      }
      const payload_excel_by_product = {
        "storeCode": store,
        "flags": { "product": true }
      }
      dispatch(orderActions.getConfrimedOrderList("", payload, `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
      dispatch(orderActions.getConfrimedOrderListexcel("", payload_excel_by_product,));
    }
  }, [updateShippedDetails])

  React.useEffect(() => {
    if (confirmedOrderList?.data?.result) {
      const filterData = confirmedOrderList.data.result.map(items => {
        items.orderCreateDate = moment(items.createdAt).format("DD/MM/YYYY")
        items.name = items.userfirstnameen + " " + items.userlastnameen;
        items.id = items.orderId;
        items.saleorder = '123'
        return items
      })
      setTableData(filterData)
      setLoading(false)
      setTotalCount(confirmedOrderList.data.totalCount)
      props.setConfirmOrderCount(confirmedOrderList.data.totalCount)
    } else {
      setTableData([])
      setTotalCount(0)
      setLoading(false)
    }

    if (confirmedOrderListexcel?.data) {
      setTableDataExcel(confirmedOrderListexcel.data)
      setLoading(false)
      setTotalCountExcel(confirmedOrderListexcel.data.length)
    } else {
      setTableDataExcel([])
      setLoading(false)
    }

  }, [confirmedOrderList, confirmedOrderListexcel])

  const handleChangeRowsPerPage = (event) => {
    setLoading(true)
    const payload = {
      "storeCode": store,
    }

    dispatch(orderActions.getConfrimedOrderList("", payload, `limit=${parseInt(event.target.value)}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))


    setRowsPerPage(parseInt(event.target.value));
    setPage(1);


  };

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    let pages = (newPage - 1) * rowsPerPage
    const payload = {
      "storeCode": store,
    }

    setPage(newPage);
    dispatch(orderActions.getConfrimedOrderList("", payload, `limit=${parseInt(rowsPerPage)}&skip=${pages}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
    setPageSkip(pages)

  };

  const setsetSortFn = (id, sortBy) => {
    setLoading(true)
    const payload = {
      "storeCode": store,
    }
    dispatch(orderActions.getConfrimedOrderList("", payload, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [id]: sortBy }))}`))
    setsetSort({ ...sortDetails, id: id, orderBy: sortBy })
  }


  const group = [
    {
      field: "order.usercustomercode",
    },
  ];

  /* const data = process(tableDataExcel, {
   group: group,
  }).data;  */

  // const data = tableDataExcel;

  // data = tableDataExcel;

  if (excelselectedData.length > 0) {
    const ordernos = excelselectedData.map(i => i.orderNo);
    data = tableDataExcel.filter(i => ordernos.includes(i.orderNo));
  }
  else {
    data = tableDataExcel;
  }

  data.map((dataItem) => {
    if (moment(dataItem.createdAt).format('DD.MM.YYYY') != 'Invalid date') {
      // console.log(moment(dataItem.deliveryDate).format('DD.MM.YYYY'), dataItem.deliveryDate)
      // dataItem.deliveryDate = moment(dataItem.deliveryDate).format('DD.MM.YYYY');
      dataItem.createdAt = moment(dataItem.createdAt).format('DD.MM.YYYY');
      dataItem.orderCreateDate = moment(dataItem.ordercreatedat).format("DD.MM.YYYY");
      dataItem.totalOrderPrice2 = convertToCurrencyFormat(Number(dataItem.totalOrderPrice));
    }
    return dataItem;
  });

  const additionheaders = [{
    "doc_type": "",
    "sales_org": "",
    "dist_chan": "",
    "division": "",
    "part_role2": "O",
    "department": "O",
    "usercustomercode": "R",
    "usersaleoffice": "O",
    "usersalegroup": "R",
    "orderNo": "O",
    "deliveryDate": "R",
    "item": "R",
    "articaleCode": "R",
    "storeCode": "R",
    "allocatedQtd": "R",
    "saleUnit": "R",
    "category": "R",
    "incoterms1": "O",
    "incoterms3l": "O",
    "doc_date": "O",
    "req_time": "O",
    "price_list": "O",
    "shipping_type": "O",
    "cust_mat": "O",
    "net_weight": "O",
    "unitof_weight": "O",
    "cond_type": "O",
    "cond_value": "O",
    "cond_p_unit": "O",
    "cond_unit": "O",
    "mat": "O",
    "create_text": "O",
    "create_text2": "O",
    "create_text3": "O",
    "create_text4": "O",
    "external_11": "Char 50",
    "external12": "Char 50",
    "external13": "Char 50",
    "exteranl14": "Char 50",
    "external15": "Char 50",
    "external16": "Char 50",
    "external17": "Char 50",
    "external18": "Char 50",
    "external19": "Char 50",
    "external20": "Char 50",
    "external21": "Char 50",
    "external22": "Char 50",
    "external23": "Char 50",
    "external24": "Char 50",
    "external25": "Char 50",
    "external26": "Char 50",
    "external27": "Char 50",
    "external28": "Char 50",
    "external29": "Char 50",
    "external30": "Char 50",
    "external31": "Char 50",
    "external32": "Char 50",
    "external33": "Char 50",
    "external34": "Char 50",
    "external35": "Char 50",
    "external36": "Char 50",
    "external37": "Char 50",
    "external38": "Char 50",
    "external39": "Char 50",
    "external40": "Char 50",
  }, {
    "doc_type": "",
    "sales_org": "",
    "dist_chan": "",
    "division": "",
    "part_role2": "Saels Empolyee (ZE)",
    "department": "สำหรับกรณีที่ใช้ราคาขายตามแผนก",
    "usercustomercode": "Sold To",
    "usersaleoffice": "Sales Office",
    "usersalegroup": "Sales Group",
    "orderNo": "Purchase Order Number",
    "deliveryDate": "Req.Delivery Date",
    "item": "Item",
    "articaleCode": "Material",
    "storeCode": "Plant",
    "allocatedQtd": "Qty",
    "saleUnit": "Sales unit",
    "category": "Item Category",
    "incoterms1": "Incoterm",
    "incoterms3l": "Incoterms Location 2",
    "doc_date": "Document Date",
    "req_time": "Arrival time",
    "price_list": "Price List Type",
    "shipping_type": "Shipping Type",
    "cust_mat": "CusMat INFO",
    "net_weight": "Net Weight",
    "unitof_weight": "Unit Of Weight",
    "cond_type": "VAT",
    "cond_value": "ราคาขายต่อ unit",
    "cond_p_unit": "จำนวนต่อหน่วยขาย",
    "cond_unit": "หน่วยขาย",
    "mat": "Mat-Pricing Group",
    "create_text": "Sales Note For Customer",
    "create_text2": "Header note (Sales order)",
    "create_text3": "Sales Note (Internal)",
    "create_text4": "ETD Date",
    "external_11": "ราคา IncVat",
    "external12": "ราคา ExcVat",
    "external13": "vender(D)",
    "exteranl14": "GLN(D),site(D)",
    "external15": "แผนก(D)",
    "external16": "TRND(D) รหัสสินค้าลูกค้า",
    "external17": "ราคาต่อหน่วยที่ได้จากลูกค้า",
    "external18": "ราคาส่วนลดต่อหน่วยที่ได้จากลูกค้า",
    "external19": "ราคาส่วนลดทั้งหมด",
    "external20": "",
    "external21": "",
    "external22": "",
    "external23": "",
    "external24": "",
    "external25": "",


  }]
  const data_modified = additionheaders.concat(data);

  const filterShippedList = (selectedItem) => {
    let payload = {
      storeCode: store,
    }
    if (selectedItem?.channelDefaultValue) {
      payload.userChannel = selectedItem?.channelDefaultValue
    }
    if (selectedItem?.deliveryTime) {
      payload.deliveryTime = selectedItem?.deliveryTime
    }
    if (selectedItem?.deliveryDate?.startDate) {
      payload.deliveryDate = {
        from: moment(selectedItem?.deliveryDate?.startDate).format("YYYY-MM-DD"),
        to: moment(selectedItem?.deliveryDate?.endDate).format("YYYY-MM-DD")
      }
    }
    if (selectedItem?.orderDate?.startDate) {
      payload.createdAt = {
        from: moment(selectedItem?.orderDate?.startDate).format("YYYY-MM-DD"),
        to: moment(selectedItem?.orderDate?.endDate).format("YYYY-MM-DD")
      }

    }



    // if (selectedItem?.categoryDefaultValue?.length > 0) {
    //     payload.categorySlug = selectedItem.categoryDefaultValue.map((item) => {
    //         return item.categorySlug
    //     })
    // }
    // if (selectedItem?.sabCategoryDefaultValue?.length > 0) {
    //     payload.subCategorySlug = selectedItem.sabCategoryDefaultValue.map((item) => {
    //         return item.subCategorySlug
    //     })
    // }
    // dispatch(orderActions.getShippedList("", payload, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [id]: sortBy }))}`))
    setFiltersFields(payload)
    let merged = { ...payload, ...attrFilter };
    dispatch(orderActions.getConfrimedOrderList("", merged, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
  }

  const searchItemByAttr = (query) => {
    let payload = query
    payload.storeCode = store
    let merged = { ...payload, ...filtersFields };
    dispatch(orderActions.getConfrimedOrderList("", merged, `limit=${rowsPerPage}&skip=${page}&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))

    setAttrFilter(payload)
  }

  const fullFillMultiple = () => {
    let payload = {
      "orderId": selected,
      "status": "shipped"
    };
    orderUpdateApi(payload)
    props.updateorderbadges();
  };

  const orderUpdateApi = (payload) => {
    setLoading(true)
    ApiProvider.post(ApiList.API_URL_TO_UPDATE_ORDER, payload).then((response) => {
      if (response) {
        const payload = {
          "storeCode": store,
        }
        setLoading(false)
        setOrderStatusUpdated(true)
        dispatch(orderActions.getConfrimedOrderList("", payload, `limit=${rowsPerPage}&skip=0&sort=${encodeURIComponent(JSON.stringify({ [sortDetails.id]: sortDetails.orderBy }))}`))
        setSelected([]);
      }

    })
  };
  return (
    <div>
      {loading && (<Loading />)}

      <OrderManagementFilter
        filterShippedList={filterShippedList}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        searchItemByAttr={searchItemByAttr}
      />

      <div className='row'>
        <div className='col-sm-7 col-md-7'>
          <div className='new-order-list-table-main-container'>
            <div className="table-order-counts">
              <h6 className='new-order-list-table-order-counts'>
                {tableData.length === 0 || !tableData.length || tableData.length === 1 ? tableData.length + " Order" : tableData.length + " Orders"}
                {/* {tableData.length} Orders */}
              </h6>
              {totalCount !== 0 && selected.length > 0 && (
                <h6 className="table-selected-counts">({selected.length} selected)</h6>
              )
              }
              {/* <h6 className="table-selected-counts">({selected.length} selected)</h6> */}
            </div>
          </div>
        </div>
        {/* export-button-section start from here */}
        <div className='col-sm-2 col-md-2 pr-3 pl-0'>
          <button className='export-button-main-container' title="confirmed_order-SO_report_01032023.xlsx" onClick={excelExport}>
            <img src={exportButton} className='export-button-image' />
            <span className="export-button-heading">Export</span>
          </button>
          <ExcelExport data={data} fileName={filenames} ref={_exporterspecialprice}>
            <ExcelExportColumn field="priceLevel" title="Price Level 1: Site Level (ราคาระดับช็อป) 2: Site Group (ราคาตามกลุ่มสนง.) 3: Sales Org. & Dist Ch. Level (ราคากลาง) 4: Scale Price (ราคาตามขั้นหนึ่งที่เป็นสมาชิก) 5. ราคาสมาชิกเริ่มที่ 2-5 " cellOptions={{ wrap: true, color: "#FF0000", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#FF0000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="articaleCode" title="Article Code" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFF00", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="saleUnit" title="Sales Unit" headerCellOptions={{ wrap: true, background: "#FFFF00", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="salesOrganization" title="Sales Organization" cellOptions={{ wrap: true, color: "#FF0000", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#FF0000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="distributionChannel" title="Distribution Channel" cellOptions={{ wrap: true, color: "#FF0000", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#FF0000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="priceList" title="Price List" cellOptions={{ wrap: true, color: "#FF0000", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#FF0000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="storeCode" title="Site" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFF00", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="Site Group" headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="usercustomercode" title="Vendor (External) (Optional)" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFF00", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="deliveryDate" title="Valid from" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFF00", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="deliveryDate" title="Valid To" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFF00", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="Amount in Margin" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="price" title="Amount in Final Price" headerCellOptions={{ wrap: true, background: "#FFFF00", color: "#000000", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PQtyB1 (Scale 1)" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PMPrice1 (Scale 1)" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PQtyB2(Scale 2)" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PMPrice2 (Scale 2)" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PQtyB3(Scale 3)" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PMPrice3(Scale 3)" headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PPQtyB4(Scale 4)" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PMPrice4(Scale 4)" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PQtyB5(Scale 5))" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="PMPrice5(Scale 5)" headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field=" " title="Name" cellOptions={{ wrap: true, borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ wrap: true, background: "#FFFFFF", color: "#000000", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />

          </ExcelExport>
          <ExcelExport data={data_modified} fileName={filenamespecial} ref={_exporter}>
            <ExcelExportColumn field="doc_type" title="DOC_TYPE" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="sales_org" title="SALES_ORG" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="dist_chan" title="DISTR_CHAN" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="division" title="DIVISION" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="usercustomercode" title="PARTN_NUMB" cellOptions={{ background: "#ffb84d", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="usercustomercode" title="PARTN_ROLE" cellOptions={{ background: "#ffb84d", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="part_role2" title="PARTN_ROLE2" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="usersaleoffice" title="SALES_OFF" cellOptions={{ background: "#66d9ff", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="usersalegroup" title="SALES_GRP" cellOptions={{ background: "#66d9ff", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="department" title="Department" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="orderNo" title="PURCH_NO" cellOptions={{ background: "#ffff99", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="incoterms1" title="INCOTERMS1" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="incoterms3l" title="INCOTERMS3L" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="doc_date" title="DOC_DATE" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="deliveryDate" title="REQ_DATE_H" cellOptions={{ background: "#99ff99", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="req_time" title="REQ_TIME" headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="deliveryDate" title="PRICE_DATE" cellOptions={{ background: "#99ff99", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#7ae014", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="price_list" title="PRICE_LIST" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="shipping_type" title="SHIPPING_TYPE" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="item" title="ITM_NUMBER" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="articaleCode" title="MATERIAL" cellOptions={{ background: "#808080", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="cust_mat" title="CUST_MAT22" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="storeCode" title="PLANT" cellOptions={{ background: "#99e6ff", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="allocatedQtd" title="TARGET_QTY" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="saleUnit" title="TARGET_QU" cellOptions={{ background: "#808080", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="category" title="ITEM_CATEG" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="net_weight" title="NET_WGHT" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="unitof_weight" title="UNTOF_WGHT" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="cond_type" title="COND_TYPE" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="cond_value" title="COND_VALUE" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="cond_p_unit" title="COND_P_UNT" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="cond_unit" title="COND_UNIT" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="mat" title="Mat-PR_GRP" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="create_text" title="CREATE_TEXT" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="create_text2" title="CREATE_TEXT2" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="create_text3" title="CREATE_TEXT3" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="create_text4" title="CREATE_TEXT4" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external_11" title="External 11" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external12" title="External 12" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external13" title="External 13" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="exteranl14" title="External 14" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external15" title="External 15" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external16" title="External 16" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external17" title="External 17" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external18" title="External 18" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external19" title="External 19" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external20" title="External 20" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external21" title="External 21" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external22" title="External 22" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external23" title="External 23" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external24" title="External 24" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external25" title="External 25" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external26" title="External 26" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external27" title="External 27" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external28" title="External 28" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external29" title="External 29" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external30" title="External 30" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external31" title="External 31" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external32" title="External 32" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external33" title="External 33" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external34" title="External 34" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external35" title="External 35" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external36" title="External 36" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external37" title="External 37" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external38" title="External 38" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external39" title="External 39" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
            <ExcelExportColumn field="external40" title="External 40" headerCellOptions={{ background: "#0000FF", textAlign: "center", borderBottom: "true", borderTop: "true", borderRight: "true", borderLeft: "true", }} />
          </ExcelExport>
        </div>
        <div className='col-sm-3 col-md-3 text-right'>
          {selected.length > 0 ?

            <button className='mark-as-skipped-button-main-container' onClick={fullFillMultiple} >
              <span className="mark-as-skipped-button-heading">Created Sale Order</span>
            </button>
            :
            <button className='mark-as-skipped-button-main-container-disable'>
              <span className="mark-as-skipped-button-heading-disable">Created Sale Order</span>
            </button>
          }
        </div>
      </div>
      {/* export-button-section end */}
      <div className='row'>
        {tableData?.length > 0 ?
          <div className='col-sm-12 col-md-12 to-comfirm-table-main-container'>
            {/* table will come */}
            <Table
              tableData={tableData}
              headCells={headCells}
              setSelected={setSelected}
              selected={selected}
              setExcelselectedData={setExcelselectedData}
              excelselectedData={excelselectedData}
              totalCount={totalCount}
              setPage={setPage}
              page={page}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              setsetSortFn={setsetSortFn}
              sortDetails={sortDetails}
              updateShippedDetails={updateShippedDetailsFn}
            />

          </div> :
          <>
            {!confirmedOrderList && (
              <>
                <div className='col-sm-12 col-md-12'>
                  <div className='table-empty-data-main-container'>
                    <img src={emptyTableData} className='table-empty-data-image' />
                    <h6 className='table-empty-data-heading'>No confirmed order yet</h6>
                  </div>
                </div>

                <div className='row order-table-pagination-main-container'>
                  <div className='col-sm-7 order-table-pagination-inner-container'>
                    <div className="d-flex flex-row">
                      <h6 className='order-table-pagination-count'>1-{rowsPerPage} of 0</h6>
                      <h6 className='order-table-order-per-page'>Order per page</h6>
                      <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          variant="outlined"
                          value={rowsPerPage}
                          // onChange={props.handleChangeRowsPerPage}
                          displayEmpty
                          inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ position: "absolute", width: 11, height: 7, left: 37, bottom: 37, cursor: "pointer" }} /> }}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                          <MenuItem value={40}>40</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                </div>
              </>


            )}
          </>
        }
        {/*No-new-order-yet-section end */}
      </div>
    </div>
  )

}

export default Confirmed