import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from "@mui/material/Pagination";
import Select from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Axios from "axios";
import PropTypes from 'prop-types';
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
import { REACT_APP_BASE_URL } from '../../constant';
import File from "../../images/File.png";
import Tax from "../../images/Tax.svg";
import gearImage from '../../images/gear.png';
import arrowIcon from '../../images/small-chevron-down.png';
import tableCheckIcon from '../../images/table-circle-check.png';
import fileImport from '../../images/table-file-import.png';
import userAvatar from '../../images/table-user.png';
import ActionMenu from "./Action";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.headerAlign}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <>
                            {headCell.sortable ?
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel> : <>{headCell.label}</>

                            }
                        </>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export default function EnhancedTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('orderCreateDate');
    // const [selected, setSelected] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [age, setAge] = React.useState('');
    const [showTaxInvoiceModel, setShowTaxInvoiceModel] = React.useState(false);
    const [uploadededFile, setUploadedFile] = React.useState()
    const [wrongFileUpload, setWrongFileUpload] = React.useState(false)
    const [taxInvoiceData, setTaxInvoiceData] = React.useState({
        companyName: "",
        taxId: "",
        phoneNumber: "",
        address: "",
        orderId: "",
        error: false
    })
    const inputRef = React.useRef(null)
    // 
    const [file, setFile] = React.useState(null);
    const [uploaded, setUploaded] = React.useState(false);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    React.useEffect(() => {
        setRows(props.tableData)
    }, [props.tableData])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        props.setsetSortFn(property, isAsc ? -1 : 1);
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            const newSelectedexcel = rows;
            props.setExcelselectedData(newSelectedexcel);
            props.setSelected(newSelected);
            return;
        }
        props.setSelected([]);
        props.setExcelselectedData([]);
    };

    const handleClick = (event, name, row) => {

        const selectedIndex = props.selected.indexOf(name);
        let newSelected = [];
        let newSelectedexcel = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(props.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(props.selected.slice(1));
        } else if (selectedIndex === props.selected.length - 1) {
            newSelected = newSelected.concat(props.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                props.selected.slice(0, selectedIndex),
                props.selected.slice(selectedIndex + 1),
            );
        }

        if (selectedIndex === -1) {
            newSelectedexcel = newSelectedexcel.concat(props.excelselectedData, row);
        } else if (selectedIndex === 0) {
            newSelectedexcel = newSelectedexcel.concat(props.excelselectedData.slice(1));
        } else if (selectedIndex === props.excelselectedData.length - 1) {
            newSelectedexcel = newSelectedexcel.concat(props.excelselectedData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedexcel = newSelectedexcel.concat(
                props.excelselectedData.slice(0, selectedIndex),
                props.excelselectedData.slice(selectedIndex + 1),
            );
        }
        props.setSelected(newSelected);
        props.setExcelselectedData(newSelectedexcel);
    };


    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isNull = (value) => {
        return value == "" || value == undefined || value == null
    }

    const isSelected = (name) => props.selected.indexOf(name) !== -1;

    const convertToCurrencyFormat = (value = 0) => {
        value = value === "null" ? 0 : value;

        const currency = Intl.NumberFormat("en-us").format(Number(value).toFixed(2));

        let afterDecimal = currency.includes(".") ? currency.split(".")[1] : "00";

        if (afterDecimal.length === 1) {

            afterDecimal = `${afterDecimal}0`;

        }

        return `${currency.split(".")[0]}.${afterDecimal}`;
    };

    //   const handleChangeUpload = (event) => {
    //     const selectedFile = event.target.files[0];
    //     const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];

    //     if (selectedFile && allowedTypes.includes(selectedFile.type)) {
    //       setFile(selectedFile);
    //       setUploaded(false);
    //     } else {
    //       setFile(null);
    //       setUploaded(false);
    //       alert('Please select a PNG, JPG, or PDF file.');
    //     }
    //   };      

    const handleCanel = () => {

        setShowTaxInvoiceModel(false)
        // setUploaded(true);
    };

    const handleTaxData = (row) => {
        let temp = taxInvoiceData
        setShowTaxInvoiceModel(true)
        temp.orderId = row.orderId;
        row = JSON.parse(row.taxInvoiceDetails)

        temp.companyName = row.name;
        temp.taxId = row.taxID
        temp.phoneNumber = row.phone
        temp.address = row.addressTH + " " + row.provinceTH + " " + row.districtTH + " " + row.subDistrictTH + " " + row.postalCodeTH
        setTaxInvoiceData({ ...temp })
    }

    const handleChangeUpload = (e) => {
        inputRef.current.click()
    }

    const handleUpload = (e) => {
        const types = ['image/png', 'image/jpeg']
        // let temp = taxInvoiceData
        if (types.every(type => e?.target?.files[0]?.type !== type)) {
            setWrongFileUpload(true)
            return
        } else {
            setWrongFileUpload(false)
        }
        // setTaxInvoiceData({ ...temp })
        setUploadedFile(e.target.files[0])

    }

    const saveFile = (e, orderId) => {
        e.preventDefault();

        // setShowTaxInvoiceModel(false)
        const payload = new FormData()
        payload.append('objects', uploadededFile)
        payload.append('orderId', orderId)
        const token = localStorage.getItem("authorization");
        const requestOptions = {
            headers: {
                // "contetnt-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`,
            },
        };
        return Axios.post(
            REACT_APP_BASE_URL + "/api/order/uploadInvoice",
            payload,
            requestOptions
        )
            .then((response) => {
                setUploadedFile([])
                props.updateShippedDetails()
                setShowTaxInvoiceModel(false)
                return response
            })
            .catch((error) => error);

    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const renderCell = (row) => {
        return (
            <>
                {
                    props?.headCells && props?.headCells?.length > 0 && props.headCells.map((headObj, index) => {
                        if (headObj.id === "status") {
                            return (
                                <>
                                    {row[headObj.id] === 'new' && (
                                        <TableCell align={headObj.align}><span className='order-table-to-confirmed'>{row[headObj.id] === 'new' ? 'To Confirm' : null}</span></TableCell>
                                    )}
                                    {row[headObj.id] === 'confirmed' && (
                                        <TableCell key={index} align={headObj.align}><span className='order-table-to-confirmed'>{row[headObj.id] === 'confirmed' ? 'Confirmed' : null}</span>
                                        </TableCell>

                                    )}

                                </>
                            )
                        }
                        else if (headObj.id === "download") {
                            return (
                                <>
                                    <TableCell align={headObj.align} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                        {!isNull(row["taxInvoiceDetails"]) && <span>
                                            {!isNull(JSON.parse(row["taxInvoiceDetails"])?.filename) ?
                                                <img src={tableCheckIcon} className='table-check-icon' /> : null
                                            }
                                            <img src={fileImport} className='table-file-import' onClick={() => {
                                                handleTaxData(row)
                                            }}
                                            />
                                        </span>}
                                        {row["download"]}
                                    </TableCell>
                                </>
                            )
                        }
                        else if (headObj.id === "action") {
                            return (
                                <>
                                    <ActionMenu
                                        row={row}


                                    />
                                </>
                            )
                        }
                        else if (headObj.id === "totalOrderPrice") {
                            return (
                                <>
                                    <TableCell align={headObj.align}>฿ {convertToCurrencyFormat(row[headObj.id])}
                                    </TableCell>
                                </>
                            )
                        }

                        else if (headObj.id === "createduserfirstnameen") {
                            return (
                                <>
                                    <TableCell align={headObj.align}>
                                        {row["createduserrole"] === "Customer" ?
                                            <>
                                                <img src={userAvatar} className='table-created-employer' /> Customer </>
                                            :
                                            <>
                                                <img src={gearImage} className='table-created-employer' />
                                                {row[headObj.id]}
                                            </>
                                        }
                                    </TableCell>
                                </>
                            )
                        }
                        else {
                            let text = row[headObj.id]
                            if (headObj?.id.includes('+')) {
                                let colName = headObj.id.split("+");
                                text = row[colName[0]] + ' ' + row[colName[1]]
                            }
                            return (
                                <>
                                    <TableCell align={headObj.align} className='common-table-overflow-ellipsis'>{text}</TableCell>
                                </>
                            )
                        }


                    })
                }

            </>

        )
    }

    const totalPages = props && props.totalCount ? Math.ceil((parseInt(props.totalCount) / parseInt(props.rowsPerPage))) : null;
    //  props && props.totalCount ? Math.ceil((11 / parseInt(rowsPerPage))) : null;
    return (
        <>
            <div className='common-table-material'>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={props.selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={props.headCells}
                        />
                        <TableBody>
                            {rows?.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover

                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) => handleClick(event, row.id, row)}
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        {renderCell(row)}
                                        {/* <TableCell align="center">{row.order_no}</TableCell>
                                        <TableCell align="center">{row.customer}</TableCell>
                                        <TableCell align="center">{row.total}</TableCell>
                                        <TableCell align="center">{row.order_date}</TableCell>
                                        <TableCell align="center">{row.created_by}</TableCell>
                                        <TableCell align="center">{row.channel}</TableCell>
                                        <TableCell align="center"><span className='order-table-confirm'>{row.status}</span>
                                        </TableCell> */}
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
    <TableRow
        style={{
            height: (dense ? 33 : 53) * emptyRows,
        }}
    >
        <TableCell colSpan={6} />
    </TableRow>
)} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='row order-table-pagination-main-container'>
                    <div className='col-sm-7 order-table-pagination-inner-container'>
                        <div className="d-flex flex-row">
                            <h6 className='order-table-pagination-count'>1-{props.rowsPerPage} of {props.totalCount}</h6>
                            <h6 className='order-table-order-per-page'>Order per page</h6>
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    variant="outlined"
                                    value={props.rowsPerPage}
                                    onChange={props.handleChangeRowsPerPage}
                                    displayEmpty
                                    sx={{
                                        "&:hover": {
                                            "&& fieldset": {
                                                border: "2px solid #61A60E;"
                                            }
                                        }
                                    }}
                                    inputProps={{ IconComponent: () => <img src={arrowIcon} style={{ position: "absolute", width: 11, height: 7, left: 37, bottom: 37, cursor: "pointer" }} /> }}>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={40}>40</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {/* pagination-section start from here */}
                    <div className='col-sm-5 order-table-pagination-inner-container p-0 m-0'>
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                // page={props.page}
                                variant="outlined"
                                shape="rounded"
                                onChange={(event, page) => props.handleChangePage(event, page)}
                            // onChange={props.handleChangePage}
                            />
                        </Stack>
                    </div>
                    {/* pagination-section end */}
                </div>
                {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            </div>

            <Modal centered className="taxInvoice" show={showTaxInvoiceModel} onHide={() => setShowTaxInvoiceModel(false)}>
                <Modal.Header closeButton>
                    <h5>
                        <img src={Tax} />
                        <span>Tax Invoice Information</span>
                    </h5>
                </Modal.Header>
                <form onSubmit={(e) => saveFile(e, taxInvoiceData.orderId)} method="post" encType="multipart/form-data">
                    <Modal.Body>
                        <div className="taxInvoice-upload" onClick={handleChangeUpload}>
                            <img src={File} />

                            <input ref={inputRef} onChange={(e) => handleUpload(e)} type="file" style={{ display: "none" }} accept=".jpg,.jpeg,.png,.pdf" src={File} />
                            <h6>{isNull(uploadededFile?.name) ? "Upload a file here" : uploadededFile?.name}</h6>
                            {wrongFileUpload && <h6 className="invalid-feedback">"Please uplaod a valid file of format .png or .jpeg"</h6>}

                        </div>
                        <div className="taxInvoice-desc">
                            <div className="row">
                                <div className="col-lg-2 col-md-2">
                                    <p>Company name</p>
                                </div>
                                <div className="col-lg-10 col-md-10">
                                    <p>
                                        <span>{taxInvoiceData.companyName}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-md-2">
                                    <p>Tax ID</p>
                                </div>
                                <div className="col-lg-10 col-md-10">
                                    <p>
                                        <span>{taxInvoiceData.taxId}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-md-2">
                                    <p>Phone number</p>
                                </div>
                                <div className="col-lg-10 col-md-10">
                                    <p>
                                        <span>{taxInvoiceData.phoneNumber}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-md-2">
                                    <p>Address</p>
                                </div>
                                <div className="col-lg-10 col-md-10">
                                    <p>
                                        <span>
                                            {taxInvoiceData.address}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="taxInvoice-cancelBtn" onClick={handleCanel}> Cancel</button>
                        <button className="taxInvoice-saveBtn">Save</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
