import moment from "moment";
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { TbRulerOff } from 'react-icons/tb';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createOrderAction } from "../../_actions";
import ApiList from '../../_constants/const.api';
import { Base64 } from '../../_helpers/commanFunction';
import ApiProvider from '../../_services/ApiProvider';
import CalendarImage from '../../images/calendar-icon.png';
import Tag from '../../images/offer-tag.png';
import OrderPlaceImage from '../../images/pencil-gray.png';
import saveAsDraft from "../../images/saveasdraft.png";
import ThankYouImage from "../../images/thank-you-puchase-image.png";
import OrderEditModalPopUP from '../../ModalScreens/ThankYouforPurchase/OrderEditModal';
import OrderEditModal from '../../ModalScreens/ThankYouforPurchase/SubmitModal';
import ThankYouForPurchaseModal from '../../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal';
import Loading from "../../SharedComponent/Loader/Loader";
import { HamburgerTableMenu } from '../HamburgerTableMenu/HamburgerTableMenu';
import { CommonTableHeader } from '../TableHeader/TableHeader';
import './CreateOrder.css';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import ProductDetails from './ProductInfo/ProductDetails';
import ShippingDetails from './ShippingDetails/ShippingDetails';
import TaxInvoice from './TaxInvoice/TaxInvoice';
const CreateOrder = (props) => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);
    const lancode = localStorage.getItem("lancode");
    var userData = JSON.parse(localStorage.getItem("user"));
    const [loading, setLoading] = useState(false);
    const [orderSubmitClicked, setOrderSubmitClicked] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const store = userData ? userData.user.stores[0].storeCode : "";
    const [paymentMode, setPaymentMode] = useState("")
    const [showInvoiceForm, setShowInvoiceForm] = React.useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [confirmationPoPUP, setConfirmationPopUp] = useState(false);
    const [taxInvoiceData, setTaxInvoiceData] = React.useState([
        {
            label: "Company Name",
            value: "",
            error: false,
            errorMessage: "Please fill the required field!",
        },
        {
            label: "Tax ID",
            value: "",
            error: false,
            errorMessage: "Please fill the required field!",
        },
        {
            label: "Phone Number",
            value: "",
            error: false,
            errorMessage: "Please fill the required field!",
        },
        {
            label: "Address",
            value: "",
            error: false,
            disabled: false,
            errorMessage: "Please fill the required field!",
        },
        {
            label: "Province",
            value: "",
            error: false,
            disabled: false,
            errorMessage: "Please fill the required field!",
        },
        {
            label: "District",
            value: "",
            error: false,
            disabled: false,
            errorMessage: "Please fill the required field!",
        },
        {
            label: "Sub-District",
            value: "",
            error: false,
            disabled: false,
            errorMessage: "Please fill the required field!",
        },
        {
            label: "Postal Code",
            value: "",
            error: false,
            disabled: true,
            errorMessage: "Please fill the required field!",
        },
    ]);
    let [taxInvoice, setTaxInvoice] = React.useState([]);
    const [taxAuthority, setTaxAuthority] = React.useState("Company");
    const [productInfoUserNote, setProductInfoUserNote] = React.useState("");
    // 
    const [productInfoUserPhone, setProductInfoUserPhone] = React.useState("");
    const [productInfoUserChannel, setProductInfoUserChannel] = React.useState("");
    // 
    const [orderDetail, setOrderDetail] = React.useState([]);
    const orderDetails = useSelector((state) => state.createOrder.orderDetails);
    const isNull = (value) => {
        return value === "" || value === undefined || value === null;
    };
    const encodeOrderId = location.pathname.split('/').slice(1)[1];
    const orderId = encodeOrderId && Base64.decode(encodeOrderId)
    // const customerCode="900103746"
    const [customerCode, setCusotmerCode] = React.useState("");
    const [addressUpdated, setAddressUpdated] = React.useState(false)

    const [editTaxInvoice, setEditTaxInvoice] = React.useState(false);
    const [provinceDdOptions, setProvinceDdOptions] = React.useState([]);
    const [districtDdOptions, setDistrictDdOptions] = React.useState([]);
    const [subDistrictDdOptions, setSubDistrictDdOptions] = React.useState([]);
    const [deliveryDate, setDeliveryDate] = React.useState("");
    const [deliverTime, setDeliveryTime] = React.useState("");
    const [invoiceFlag, setInvoiceFlag] = React.useState(true);
    const [btnStatus, setBtnStatus] = useState([
        { name: 'Shipping Address', required: true, enableFlag: false },
        { name: 'Tax Info', required: false, enableFlag: false }
    ])
    const [isValidCustomerInfo, setIsValidCustomerInfo] = React.useState(false);
    const [isValidShippingInfo, setIsValidShippingInfo] = React.useState(false);
    const [isValidTaxInfo, setIsValidTaxInfo] = React.useState(true);

    const [addressId, setAddressId] = React.useState("");
    const [customerOrderId, setCustomerOrderId] = React.useState("")
    const [customerUserId, setCustomerUserId] = React.useState("")
    const [storeCode, setStoreCode] = React.useState("");

    const [draftPopUp, setDraftPopUp] = React.useState(false);
    const [orderNumber, setOrderNumber] = useState("")
    const [showToast, setShowToast] = useState(false);

    const [defaultLoad, setDefaultLoad] = useState(false);
    const [defaultCustomerId, setDefaultCustomerId] = useState("");
    const [userNoteForCheckout, setUserNoteForCheckout] = useState("");

    const [mainHeading, setMainHeading] = useState("");
    const [mainHeading2, setMainHeading2] = useState("");

    const [innerHeading, setInnerHeading] = useState("");
    const [btnDiscard, setbtnDiscard] = useState("");
    const [btnSave, setBtnSave] = useState("");
    const [buttonClickedName, setButtonClickedName] = useState(false);
    const [isNewCustomer, setSsNewCustomer] = useState(false);
    const [isInvoiceAddress, setIsInvoiceAddress] = useState(false);
    // const [confirmStatus, setConfirmStatus] = useState(true)
    // React.useEffect(()=>{

    // },[defaultCustomerId])
    React.useEffect(() => {
        if (orderDetails && orderDetails.data) {
            if (defaultLoad) {
                setDefaultCustomerId(orderDetails?.data.userId)
                setUserNoteForCheckout(orderDetails?.data.userNote)
            }
            setOrderDetail(orderDetails?.data)
            // getUserId(orderId)
            setDefaultLoad(false);
            setPaymentMode(orderDetails?.data.paymentMode)
            let address = JSON.parse(orderDetails?.data.shippingAddress);
            setAddressId(address?.userAddressId)
            getTaxInfoOfUser(orderDetails?.data.userId)

        } else {
            setOrderDetail([])
            setUserNoteForCheckout("")
        }
        getDropdownData("province", null)

    }, [orderDetails, addressUpdated])


    React.useEffect(() => {
        if (!userData) {
            history.push('/login')
        }

        if ((userData?.user?.role === 'Saleshop' || userData?.user?.role === 'Admin')) {
            let payload = {
                "storeCode": store,
                "flags": { "product": true }
            }
            setDefaultLoad(true);
            dispatch(createOrderAction.getOrderDetails(orderId, payload))

        }
    }, [])

    const getUserInfo = (customerCode) => {
        setCusotmerCode(customerCode);
    }
    const updateCustomerId = (customer) => {
        setDefaultCustomerId(customer.userId)
    }

    const getNewCustomerStatus = (newCustomerStatus) => {
        setSsNewCustomer(newCustomerStatus)
    }

    const getCustomerValues = (userId, addressId, orderId, storeCode, isValidate) => {
        setCustomerUserId(userId);
        setAddressId(addressId);
        setCustomerOrderId(orderId);
        setStoreCode(storeCode);
        setIsValidCustomerInfo(isValidate)
    }

    const getDeliveryDateAndTime = (date, time, isValid) => {
        setDeliveryDate(date);
        setDeliveryTime(time);
        setIsValidShippingInfo(isValid);
    }

    // useEffect(()=>{
    //     getTaxInfoOfUser(userData.user.userId)
    // },[])
    const handlePaymentModeChanges = (e) => {
        setPaymentMode(e.target.value)
    }
    const handleaddressIdChanges = (id) => {
        setAddressId(id)
    }
    const handleTaxAuthority = (e) => {
        setTaxAuthority(e.target.value)
    }
    const getBtnFlagStatus = () => {

        let flag = false
        let length = Object.keys(taxInvoiceData).length;
        for (let i = 0; i < length; i++) {
            if (isNull(taxInvoiceData[i].value) || taxInvoiceData[i].error) {
                flag = true
            }
        }
        // if (isNull(deliveryDate) || isNull(deliverTime)) {
        //     flag = true
        // }

        return flag
    }
    const handleTaxInvoiceChanges = (e, type) => {
        let temp = taxInvoiceData;
        let flag = false
        if (type == "companyName") {
            if (!isNull(e.target.value)) {
                temp[0].value = e.target.value;
                temp[0].error = false;
                flag = true
            } else {
                temp[0].value = e.target.value;
                temp[0].error = true;
                flag = false
            }
        } else if (type == "taxid") {
            if (!isNull(e.target.value)) {
                temp[1].value = e.target.value.replace(/[^0-9]/g, "");
                temp[1].error = false;
                flag = true
            } else {
                temp[1].value = e.target.value.replace(/[^0-9]/g, "");
                temp[1].error = true;
                flag = false
            }
        } else if (type == "phoneNumber") {
            if (!isNull(e.target.value)) {
                temp[2].value = e.target.value.replace(/[^0-9]/g, "");
                temp[2].error = false;
                flag = true
            } else {
                temp[2].value = e.target.value.replace(/[^0-9]/g, "");
                temp[2].error = true;
                flag = false
            }
        } else if (type == "address") {
            if (!isNull(e.target.value)) {
                temp[3].value = e.target.value;
                temp[3].error = false;
                flag = true
            } else {
                temp[3].value = e.target.value;
                temp[3].error = true;
                flag = false
            }
        } else if (type == "province") {
            if (!isNull(e.target.value)) {
                temp[4].value = e.target.value;
                temp[4].error = false;
                temp[5].value = "";
                temp[6].value = "";
                temp[7].value = "";
                flag = true
            } else {
                flag = false
            }
        } else if (type == "district") {
            if (!isNull(e.target.value)) {
                temp[5].value = e.target.value;
                temp[5].error = false;
                temp[6].value = "";
                temp[7].value = "";
                flag = false
            } else {
                temp[5].error = TbRulerOff;
                flag = false
            }
        } else if (type == "subdistrict") {

            if (!isNull(e.target.value)) {
                temp[6].value = e.target.value;
                temp[6].error = false;
                temp[7].value = "";
                flag = true
            } else {
                temp[6].error = true;
                flag = false
            }
        } else if (type == "postalCode") {
            temp[7].value = e.data.data.postalCode.postalCodeTH;
            temp[7].error = isNull(e.data.data.postalCode.postalCodeTH)
                ? true
                : false;
            // flag = isNull(e.data.data.postalCode.postalCodeTH)
            //     ? false
            //     : true;
        }
        let dumpVar = btnStatus
        if (dumpVar[1].required) {
            if (getBtnFlagStatus()) {
                dumpVar[1].enableFlag = false
            } else {
                dumpVar[1].enableFlag = true
            }
            setBtnStatus(dumpVar)
        } else {
            dumpVar[1].enableFlag = true
            setBtnStatus(dumpVar)
        }

        setTaxInvoiceData({ ...temp });
    }
    // const getUserId = (orderId) => {
    //     
    //     let payload = {
    //         storeCode:store
    //     };
    //     ApiProvider.post(ApiList.API_URL_FOR_GET_ORDER + orderId, payload).then((response) => {

    //         getTaxInfoOfUser(response.data.data.userId)
    //     })
    // }
    const getTaxInfoOfUser = (userid) => {
        let payload = {};
        ApiProvider.get(ApiList.API_URL_FOR_GET_USER_DETAILS + "/" + userid, payload, true).then((response) => {
            let defaultAddress = response.data.data.address.filter((item) => {
                return item.isInvoiceAddress && item;
            });
            if (!isNull(defaultAddress) && defaultAddress.length > 0) {

                let temp = taxInvoiceData;
                if (!isNull(response.data?.data?.taxInvoice) || response.data.data?.taxInvoice?.length > 0) {
                    temp[0].value = response.data.data.taxInvoice?.name;
                    temp[1].value = response.data.data.taxInvoice?.taxID;
                    temp[2].value = response.data.data.taxInvoice?.phone;
                }
                setIsInvoiceAddress(true)
                temp[3].value = defaultAddress[0]?.addressTH;
                temp[3].disabled = true;
                temp[4].value = defaultAddress[0]?.provinceTH;
                temp[4].disabled = true;
                temp[5].value = defaultAddress[0]?.districtTH;
                temp[5].disabled = true;
                temp[6].value = defaultAddress[0]?.subDistrictTH;
                temp[6].disabled = true;
                temp[7].value = defaultAddress[0]?.postalCodeTH;
                temp[7].disabled = true;
                setTaxInvoiceData({ ...temp });
                getDropdownData("district", defaultAddress[0]?.provinceTH);
                getDropdownData("subdistrict", defaultAddress[0]?.districtTH);
                getDropdownData("postalCode", defaultAddress[0]?.subDistrictTH);

            } else if (!isNull(response.data?.data?.taxInvoice) || response.data.data?.taxInvoice?.length > 0) {
                let taxInvoiceAddress = response.data.data.taxInvoice
                setIsInvoiceAddress(false)
                let temp = taxInvoiceData;
                temp[0].value = taxInvoiceAddress.name;
                temp[1].value = taxInvoiceAddress.taxID;
                temp[2].value = taxInvoiceAddress.phone;
                temp[3].value = taxInvoiceAddress?.addressTH;
                temp[3].disabled = false;
                temp[4].value = taxInvoiceAddress?.provinceTH;
                temp[4].disabled = false;
                temp[5].value = taxInvoiceAddress?.districtTH;
                temp[5].disabled = false;
                temp[6].value = taxInvoiceAddress?.subDistrictTH;
                temp[6].disabled = false;
                temp[7].value = taxInvoiceAddress?.postalCodeTH;
                temp[7].disabled = false;
                setTaxInvoiceData({ ...temp });
                getDropdownData("district", taxInvoiceAddress?.provinceTH);
                getDropdownData("subdistrict", taxInvoiceAddress?.districtTH);
                getDropdownData("postalCode", taxInvoiceAddress?.subDistrictTH);
            } else {
                setIsInvoiceAddress(false)
            }
            setShowInvoiceForm(false);
            setTaxInvoice(response.data.data.taxInvoice);
            setEditTaxInvoice(false);
        })
    };
    const handleAddInvoice = (type) => {
        if (type == "showInForm") {
            let temp = btnStatus
            temp[1].required = true
            let status = getBtnFlagStatus()
            temp[1].enableFlag = !status
            setBtnStatus(temp)
            setShowInvoiceForm(true)
        } else if (type == "showInFlag") {
            let temp = btnStatus
            temp[1].required = true
            let status = getBtnFlagStatus()
            temp[1].enableFlag = !status
            setBtnStatus(temp)
            setInvoiceFlag(true);
        } else if (type == "clearFields") {
            let temp = taxInvoiceData;
            temp[0].value = "";
            temp[1].value = "";
            temp[2].value = "";
            if (!isInvoiceAddress) {
                temp[3].value = "";
                temp[4].value = "";
                temp[5].value = "";
                temp[6].value = "";
                temp[7].value = "";
                temp[3].disabled = false;
                temp[4].disabled = false;
                temp[5].disabled = false;
                temp[6].disabled = false;
                // temp[7].disabled = false;
            }
            setTaxInvoiceData({ ...temp });
        }

    }
    const handleDelete = (op) => {
        if (op == "cancel") {
            setShowInvoiceForm(false);
            setEditTaxInvoice(false)
            return
        }
        let payload = {
            taxInvoiceID: taxInvoice?.taxInvoiceID,
            userId: orderDetails?.data?.userId,
        };
        ApiProvider.post(ApiList.API_URL_TO_REMOVE_TAX_INVOICE, payload).then((response) => {
            let payload = {
                "storeCode": store,
                "flags": { "product": true }
            }
            dispatch(createOrderAction.getOrderDetails(orderId, payload))
        }).catch((error) => {

        })
    }
    const handleEdit = async () => {
        setLoading(true)
        let temp = taxInvoiceData;
        // temp[0].value = taxInvoice.name;
        // temp[1].value = taxInvoice.taxID;
        // temp[2].value = taxInvoice.phone;
        // temp[3].value = taxInvoice.addressTH;
        // temp[4].value = taxInvoice.provinceTH;
        // temp[5].value = taxInvoice.districtTH;
        // temp[6].value = taxInvoice.subDistrictTH;
        // temp[7].value = taxInvoice.postalCodeTH;
        // setTaxInvoiceData({ ...temp });
        // await getDropdownData("province", taxInvoice.provinceTH);

        await getDropdownData("district", taxInvoice.provinceTH);
        await getDropdownData("subdistrict", taxInvoice.districtTH);
        await getDropdownData("postalCode", taxInvoice.subDistrictTH);
        setEditTaxInvoice(true);
        setInvoiceFlag(true);
        setTaxAuthority(taxInvoice.type);
        setLoading(false)
    };
    const getDropdownData = async (type, value) => {
        let payload = {}
        if (type == "district") {
            payload = {
                provinceTH: value,
            };
        }
        if (type == "subdistrict") {
            // debugger
            payload = {
                provinceTH: taxInvoiceData[4].value,
                districtTH: value,
            };
        }
        if (type == "postalCode") {
            payload = {
                provinceTH: taxInvoiceData[4].value,
                districtTH: taxInvoiceData[5].value,
                subDistrictTH: value,
            };
        }
        await ApiProvider.post(ApiList.API_URL_FOR_POSTCODE, payload).then((response) => {
            if (type == "province") {
                setProvinceDdOptions(response.data.data.province);
            } else if (type == "district") {
                setDistrictDdOptions(response.data.data.district);
            } else if (type == "subdistrict") {
                // debugger
                setSubDistrictDdOptions(response.data.data.subDistrict);
            } else if (type == "postalCode") {
                handleTaxInvoiceChanges(response, "postalCode")
            }
        }).catch((error) => {

        })
    }
    const handleOnchangeDd = (e, type) => {
        if (type == "province") {
            getDropdownData("district", e.target.value);
        } else if (type == "district") {
            getDropdownData("subdistrict", e.target.value);
        } else if (type == "subdistrict") {
            getDropdownData("postalCode", e.target.value);
        }
    }
    const handleSaveDraft = () => {
        let flag = false
        if (isNull(deliveryDate) || isNull(deliverTime)) {
            flag = true
            return
        }
        if (btnStatus[1].required) {
            let temp = taxInvoiceData
            if (temp[1].value.length < 10 || temp[1].value.length > 13) {
                flag = true;
                temp[1].error = true;
                temp[1].errorMessage =
                    "Please enter a valid tax id between 10 to 13 digits";
            }
            if (temp[2].value.length < 9 || temp[2].value.length > 10) {
                flag = true;
                temp[2].error = true;
                temp[2].errorMessage =
                    "Please enter a valid phone number between 9 to 10 digits";
            }
            if (temp[7].value.length != 5) {
                flag = true;
                temp[7].error = true;
                temp[7].errorMessage = "Please enter a valid 5 digit postal code";
            }
            if (flag) {
                setTaxInvoiceData({ ...temp });
                return
            }
        }
        // if(!confirmStatus){
        setConfirmationPopUp(true)
        setMainHeading("Save as draft!");
        setInnerHeading("Draft let you save your edits, so you can come back later");
        setbtnDiscard("Discard");
        setBtnSave("Save draft");
        //    return
        // }

    }
    const saveDraft = () => {
        let payload = {
            orderId: orderId,
            userId: customerUserId,
            userNote: productInfoUserNote ? productInfoUserNote : userNoteForCheckout,
            userAddressId: addressId,
            deliveryDate: deliveryDate,
            deliveryTime: deliverTime,
            paymentMode: paymentMode,
            storeCode: store,
            newCustomer: {
                phone: productInfoUserPhone,
                channel: productInfoUserChannel,
                note: '',
            }
        }

        setLoading(true)
        ApiProvider.post(ApiList.API_URL_FOR_SAVE_DRAFT_ORDER, payload).then((response) => {

            setDraftPopUp(true)
            if (response) {
                setShowToast(true)
                setMainHeading2("Draft saved!")
                setOrderNumber(orderDetails?.data.orderNo)
                // setOrderSubmitClicked(true)
                setLoading(false)
                // setTimeout(() => {
                //    setOpenAlert(false) 
                // }, 5000);
            } else {
                setLoading(false)
                // setTimeout(() => {
                //     setOpenAlert(false) 
                //  }, 5000);
            }

        })
    }
    const saveDraftWithoutTaxInvoice = () => {
        let payload1 = {
            userId: orderDetails?.data?.userId,
            type: taxAuthority,
            name: taxInvoiceData[0].value,
            taxID: taxInvoiceData[1].value,
            phone: taxInvoiceData[2].value,
            addressTH: taxInvoiceData[3].value,
            provinceTH: taxInvoiceData[4].value,
            districtTH: taxInvoiceData[5].value,
            subDistrictTH: taxInvoiceData[6].value,
            postalCodeTH: taxInvoiceData[7].value,
        };
        if (!isNull(taxInvoice)) {
            payload1.taxInvoiceID = taxInvoice.taxInvoiceID;
        }
        ApiProvider.post(ApiList.API_URL_TO_SAVE_TAX_INVOICE, payload1).then((response) => {
            // getTaxInfoOfUser(props.userDetail.data.userId);

            let payload = {
                orderId: orderId,
                userId: customerUserId,
                userNote: productInfoUserNote ? productInfoUserNote : userNoteForCheckout,
                userAddressId: addressId,
                deliveryDate: deliveryDate,
                deliveryTime: deliverTime,
                paymentMode: paymentMode,
                storeCode: store
            }

            setLoading(true)
            ApiProvider.post(ApiList.API_URL_FOR_SAVE_DRAFT_ORDER, payload).then((response) => {
                if (response) {
                    setDraftPopUp(true)
                    //    setOrderNumber(orderDetails?.data?.orderNumber)
                    // setOpenAlert(true)

                    setOrderNumber(orderDetails?.data.orderNo)
                    setShowToast(true)
                    // setOrderSubmitClicked(true)
                    setLoading(false)
                } else {
                    setLoading(false)
                }

            })
        })
    }
    const handleAlert = () => {
        setOpenAlert(false);
    };

    const submitOrder = () => {
        let flag = false
        if (btnStatus[1].required) {
            let temp = taxInvoiceData
            if (temp[1].value.length < 10 || temp[1].value.length > 13) {
                flag = true;
                temp[1].error = true;
                temp[1].errorMessage =
                    "Please enter a valid tax id between 10 to 13 digits";
            }
            if (temp[2].value.length < 9 || temp[2].value.length > 10) {
                flag = true;
                temp[2].error = true;
                temp[2].errorMessage =
                    "Please enter a valid phone number between 9 to 10 digits";
            }
            if (temp[7].value.length != 5) {
                flag = true;
                temp[7].error = true;
                temp[7].errorMessage = "Please enter a valid 5 digit postal code";
            }
            if (flag) {
                setTaxInvoiceData({ ...temp });
                return
            }
        }
        const payload = {
            orderId: orderId,
            userId: customerUserId,
            userNote: productInfoUserNote ? productInfoUserNote : userNoteForCheckout,
            userAddressId: addressId,
            deliveryDate: deliveryDate,
            deliveryTime: deliverTime,
            paymentMode: paymentMode,
            storeCode: store
        }
        setLoading(true)
        ApiProvider.post(ApiList.API_URL_FOR_SUBMIT_ORDER, payload).then((response) => {
            if (response) {
                setOpenAlert(true)
                setOrderSubmitClicked(true)
                setLoading(false)
            } else {
                setLoading(false)
            }

        })
    }

    const orderDiscard = () => {
        const payload = {
            orderId: orderId
        }

        setConfirmationPopUp(true)
        // setOrderNumber(orderNumber)
        // setOrderSubmitClicked(true)
        setButtonClickedName(true);
        setMainHeading("Discard draft?");
        setInnerHeading("Discarding a draft will permanently remove it from your order list.");
        setbtnDiscard("Yes, discard draft");
        setBtnSave("No, keep draft");

        // ApiProvider.post(ApiList.API_URL_FOR_DISCARD_ORDER, payload).then((response) => {
        //     setDraftPopUp(true)
        //     if (response) {
        //         setConfirmationPopUp(true)
        //         // setOrderNumber(orderNumber)
        //         // setOrderSubmitClicked(true)
        //         setMainHeading("Discard draft?");
        //         setInnerHeading("Discarding a draft will permanently remove it from your order list.");
        //         setbtnDiscard("Yes, discard draft");
        //         setBtnSave("No, keep draft");
        //         setLoading(false)
        //     } else {
        //         setLoading(false)
        //     }

        // })
    }
    React.useEffect(() => {
        if (orderSubmitClicked === true && openAlert === true) {
            setTimeout(() => {
                history.push("/orderDetails");
            }, 3000);
        }

    }, [orderSubmitClicked])
    const btnDiscardFn = () => {
        setConfirmationPopUp(false)
        let payload = {
            "orderId": [orderId],
            "status": "cancelled"
        };
        // setLoading(true)
        ApiProvider.post(ApiList.API_URL_TO_UPDATE_ORDER, payload).then((response) => {
            history.push("/orderDetails?tab=6");
        })

    }

    const draftDiscared = () => {
        setConfirmationPopUp(false)
        let payload = {
            "orderId": [orderId],
            "status": "cancelled"
        };
        // setLoading(true)
        ApiProvider.post(ApiList.API_URL_TO_UPDATE_ORDER, payload).then((response) => {
            if (response) {
                setShowToast(true)
                setMainHeading2("Draft discarded");
                setOrderNumber("This draft has been discarded.")
                setTimeout(() => {
                    history.push("/orderDetails?tab=6");
                }, 3000);
            }
        })

    }
    const btnSaveFn = () => {
        if (btnStatus[1].required) {
            saveDraftWithoutTaxInvoice()
        } else {
            saveDraft()
        }
        setConfirmationPopUp(false)
        // setConfirmStatus(true)
    }

    const btnKeepDraft = () => {
        setButtonClickedName(false);
        saveDraft();
    }
    const ClickAction = () => {
        setShowToast(false);
        // history.push("/orderDetails");
        history.push("/orderDetails?tab=5");
    };
    const isAddressUpdated = () => {
        setAddressUpdated(!addressUpdated)
    }

    return (
        <>
            {loading && (<Loading />)}
            <OrderEditModalPopUP
                image={mainHeading != "Save as draft!" ? ThankYouImage : saveAsDraft}
                buttonLabel1=""
                buttonLabel2=""
                mainHeading={mainHeading} // shahbaz put you text on condition here, like i did in another popup below
                // subHeading={"Draft let you save your edits, so you can come back later"} // shahbaz put you text on condition here
                innerHeading={innerHeading}
                open={confirmationPoPUP}
                btnDiscard={btnDiscard} // accoring btn names on condition
                btnSave={btnSave}
                btnDiscardFn={btnDiscardFn}
                btnSaveFn={btnSaveFn}
                btnKeepDraft={btnKeepDraft}
                buttonClickedName={buttonClickedName}
                draftDiscared={draftDiscared}
                // ClickAction1={handleAlert}
                ClickAction2={null}
            />
            <OrderEditModal
                image={ThankYouImage}
                buttonLabel1=""
                buttonLabel2=""
                mainHeading={"Order submitted!"}
                subHeading={"Your order has been submitted."}
                innerHeading={"Go to Order Management to complete this order"}
                open={openAlert}
                // ClickAction1={handleAlert}
                ClickAction2={null}
            />
            {showToast && (
                <ThankYouForPurchaseModal
                    open={showToast}
                    mainHeading={mainHeading2}
                    subHeading1={orderNumber}
                    // checkout={true}
                    // cart={false}
                    draft={true}
                    buttonLabel={"Done"}
                    buttonClickedName={buttonClickedName}
                    orderNumber={orderNumber}
                    ClickAction={ClickAction}
                />
            )}


            <CommonTableHeader />
            <Helmet>
                <title>Betagro Create Order</title>
            </Helmet>
            <div className='container-fluid' data-testid="create-order-screen-loading">
                <div className='row' data-testid="left-menu-section">
                    <HamburgerTableMenu page="createOrder" />
                    <div className='col-sm-9 col-sm-9 col-9 common-gray-background create-order-main-container overflow-hidden'>
                        <div className='create-order-inner-container'>
                            <div className='row'>
                                <div className='col-md-12 col-xl-12 p-0' data-testid="breadcrumb-section">
                                    <div className='d-flex flex-row'>
                                        <div>
                                            <h6 className='create-order-main-container-breadcrumb-heading' onClick={e => history.push('/orderDetails')}>Order List</h6>
                                        </div>
                                        <img src="/images/chevron-right.png" className='create-order-main-container-breadcrumb-image' />
                                        <h6 className='create-order-main-container-breadcrumb-heading' onClick={e => props?.location?.state?.from ? history.push('/orderDetails?tab=' + props.location.state.from) : null}>Draft</h6>
                                        <img src="/images/chevron-right.png" className='create-order-main-container-breadcrumb-image' data-testid="chevron-right-image" />
                                        <h6 className='create-order-main-container-breadcrumb-heading'>Order Details {orderDetail.orderNo}</h6>
                                    </div>
                                </div>
                                <div className='col-xll-12 col-xl-12 p-0'>
                                    <h6 className='create-order-order-details-heading'>Order Details</h6>
                                </div>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12 create-order-details-status-main-container'>
                                        <div>
                                            <div className='d-flex flex-row align-items-center'>
                                                <div>
                                                    <img src={Tag} className='my-order-details-status-image' />
                                                </div>
                                                <h6 className='my-order-details-status-order-number'>Order No. {orderDetail.orderNo}</h6>
                                                <img src={CalendarImage} className='my-order-details-status-calendar-image' />
                                                <h6 className='my-order-details-status-order-date'>Order date {orderDetail?.createdAt ? moment(orderDetail.createdAt).format("DD/MM/YYYY") : null}</h6>
                                                <div className='my-order-details-order-placed-status-button'>
                                                    <img src={OrderPlaceImage} className='my-order-details-order-placed-status-image' alt="order-place-image" />
                                                    <span className='my-order-details-order-placed-status-heading'>Draft</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CustomerInfo
                                    addressId={addressId}
                                    handleaddressIdChanges={handleaddressIdChanges}
                                    defaultCustomerId={defaultCustomerId}
                                    getUserInfo={getUserInfo}
                                    getCustomerValues={getCustomerValues}
                                    getNewCustomerStatus={getNewCustomerStatus}
                                    userNoteForCheckout={userNoteForCheckout}
                                    setProductInfoUserNote={setProductInfoUserNote}
                                    setProductInfoUserPhone={setProductInfoUserPhone}
                                    setProductInfoUserChannel={setProductInfoUserChannel}
                                    isAddressUpdated={isAddressUpdated}
                                    updateCustomerId={updateCustomerId}
                                />
                                <ProductDetails
                                    orderId={orderId}
                                    setProductInfoUserNote={setProductInfoUserNote}
                                    customerCode={customerCode}
                                    customerUserId={customerUserId}
                                />
                                <ShippingDetails getDeliveryDateAndTime={getDeliveryDateAndTime} orderDetail={orderDetail} />
                                <TaxInvoice
                                    paymentMode={paymentMode}
                                    handlePaymentModeChanges={handlePaymentModeChanges}
                                    taxInvoiceData={taxInvoiceData}
                                    handleTaxInvoiceChanges={handleTaxInvoiceChanges}
                                    taxAuthority={taxAuthority}
                                    handleTaxAuthority={handleTaxAuthority}
                                    showInvoiceForm={showInvoiceForm}
                                    taxInvoice={taxInvoice}
                                    handleEdit={handleEdit}
                                    editTaxInvoice={editTaxInvoice}
                                    getDropdownData={getDropdownData}
                                    handleOnchangeDd={handleOnchangeDd}
                                    provinceDdOptions={provinceDdOptions}
                                    districtDdOptions={districtDdOptions}
                                    subDistrictDdOptions={subDistrictDdOptions}
                                    handleAddInvoice={handleAddInvoice}
                                    handleDelete={handleDelete}
                                    invoiceFlag={invoiceFlag}
                                    addressUpdated={addressUpdated}

                                />
                                <div className="d-flex align-items-center justify-content-end mt-5" data-testid="action-button-section">
                                    <button onClick={orderDiscard}
                                        disabled={isValidShippingInfo ? false : true}
                                        className="createOrderCardGreyBtn mr-3">Discard draft
                                    </button>
                                    <button className="createOrderCardGreyBtn mr-3" onClick={() => {
                                        if (btnStatus[1].required) {
                                            orderDetail?.orderitems && orderDetail?.orderitems.length > 0 && btnStatus[1].enableFlag && handleSaveDraft()
                                        } else {
                                            orderDetail?.orderitems && orderDetail?.orderitems.length > 0 && handleSaveDraft()
                                        }
                                    }}>Save draft</button>
                                    <button
                                        className={isValidCustomerInfo && isValidShippingInfo && isValidTaxInfo && orderDetail?.orderitems && orderDetail?.orderitems.length > 0 && (btnStatus[1].required ? btnStatus[1].enableFlag : true) && !isNewCustomer ? "createOrderCardGreenBtn" : "createOrderCardDisabledBtn"
                                        }

                                        onClick={submitOrder}
                                        disabled={(isValidCustomerInfo && isValidShippingInfo && orderDetail?.orderitems && orderDetail?.orderitems.length > 0) && (btnStatus[1].required ? btnStatus[1].enableFlag : true) && !isNewCustomer ? false : true
                                        }
                                    >Create Sale Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CreateOrder