import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import React, { useCallback, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { orderActions, productActions } from "../../../_actions";
import { Base64, convertToCurrencyFormat } from '../../../_helpers/commanFunction';
import CalendarImage from '../../../images/calendar-icon.png';
import CancelledImage from '../../../images/cancelled-order.png';
import ComfirmedOrderImage from '../../../images/comfirmed-order.png';
import CompletedImage from '../../../images/completed.png';
import emptyTableData from '../../../images/empty-list.png';
import OrderPlaceImage from '../../../images/order-place.png';
import SearchImage from '../../../images/Search.png';
import ShippedImage from '../../../images/shipped.png';
import TagImage from '../../../images/tag.png';
import ThankYouImage from "../../../images/thank-you-puchase-image.png";
import ThankYouForPurchaseModal from '../../../ModalScreens/ThankYouforPurchase/ThankYouForPurchaseModal';

import _ from 'lodash';
import { REACT_APP_BASE_URL } from '../../../constant';
import blankImage from "../../../images/empty-image.png";
import Loading from "../../../SharedComponent/Loader/Loader";
// import { isMobile } from 'react-device-detect';

const ToReviewTab = (props) => {
  const userState = useSelector((state) => state.users);
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lancode = localStorage.getItem("lancode");
  var userData = JSON.parse(localStorage.getItem('user'))
  const [loading, setLoading] = useState(false);
  const isNull = (value) => {
    return value === "" || value === undefined || value === null;
  };
  var storeCode = !isNull(userData) ? userData.user.stores[0].storeCode : "";
  var token = !isNull(userData) ? userData.token : "";
  const store = userData ? userData.user.stores[0].storeCode : "";
  const activelistorderList = useSelector((state) => state.order.activelistorderList);
  const historylistorderList = useSelector((state) => state.order.historylistorderList);
  const torevieworderList = useSelector((state) => state.order.torevieworderList);
  const cartAddedDetails = useSelector(
    (state) => state.order.reOrderProduct
  );
  const [tableDataactivelist, setTableDataactivelist] = React.useState([]);
  const [tableDatahistorylist, setTableDatahistorylist] = React.useState([]);
  const [tableDatatoreview, setTableDatatoreview] = React.useState([]);
  const [totalCountactivelist, setTotalCountactivelist] = React.useState(0);
  const [totalCounthistorylist, setTotalCounthistorylist] = React.useState(0);
  const [totalCounttoreview, setTotalCounttoreview] = React.useState(0);
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [userQuery, setUserQuery] = React.useState("");

  const [mobileDatatoreview, setMobileDatatoreview] = React.useState([]);


  const [mainHeading, setMainHeading] = useState("");
  const [subHeading1, setSubHeading1] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const sendQuery = query => { searchItem(query) };
  const delayedQuery = useCallback(_.debounce(q => sendQuery(q), 500), []);

  React.useEffect(() => {
    setLoading(true);
    if (!userData) {
      history.push("/login");
    }

    let payload_activelist = {
      "storeCode": store,
      "flags": {
        "product": true
      }

    };
    let payload = {};
    let queryString = `limit=${parseInt(rowsPerPage)}&skip=${page}`;
    dispatch(orderActions.toReview("", payload_activelist, queryString));


  }, [page]);

  React.useEffect(() => {
    if (cartAddedDetails?.data)
      dispatch(productActions.getCartData("payload", {}))
  }, [cartAddedDetails])

  // console.log('ccc', cartAddedDetails);

  React.useEffect(() => {

    if (torevieworderList?.data) {
      if (torevieworderList.data && torevieworderList?.data.result.length > 0) {
        torevieworderList?.data.result.map(item => {
          item.ordercreatedat = new Date(item.createdAt).toLocaleDateString('en-IN')
          return item;
        });
        setTableDatatoreview(torevieworderList?.data);
      }
      setLoading(false);
      setTotalCounttoreview(torevieworderList?.data.totalCount);
    } else {
      setTableDatatoreview([]);
      setLoading(false);
      setTotalCounttoreview(0);
    }
  }, [torevieworderList]);

  React.useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /mobile|iphone|ipad|android/.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  React.useEffect(() => {
    if (isMobile == true) {
      let payload_activelist = {
        "storeCode": store,
        "flags": {
          "product": true
        }

      };
      let payload = {};
      // let queryString = `limit=${parseInt(rowsPerPage)}&skip=${page}`;        
      dispatch(orderActions.toReview("", payload_activelist, ""));
    }

  }, [isMobile]);

  React.useEffect(() => {
    if (isMobile == true) {
      if (torevieworderList?.data) {
        if (torevieworderList.data && torevieworderList?.data.result.length > 0) {
          torevieworderList?.data.result.map(item => {
            item.ordercreatedat = moment(item.createdAt).format("DD/MM/YYYY")
            return item;
          });
          setMobileDatatoreview(torevieworderList?.data);
        }
        setLoading(false);
        setTotalCounttoreview(torevieworderList?.data.totalCount);
      } else {
        setMobileDatatoreview([]);
        setLoading(false);
        setTotalCounttoreview(0);
      }
    }

  }, [torevieworderList, isMobile]);

  // const routeToOrderDetailsPage = (items, event) => {
  //   event.stopPropagation()
  //   history.push("/myorderdetails/" + items.orderId, { articaleCode: items.orderId, storeCode: items.storeCode });
  // }

  const routeToOrderDetailsPage = (items, event) => {
    event.stopPropagation()
    history.push("/myorderdetails/" + Base64.encode(items.orderId.toString()), { orderId: items.orderId, storeCode: items.storeCode });
  }

  const handleChangePage = (event, newPage) => {
    // setLoading(true);
    let pages = (newPage - 1) * rowsPerPage;
    const payload = {
      "storeCode": store,
      "flags": {
        "product": true
      }
    };

    setPage(pages);
    setCurrentPage(newPage)
    // dispatch(
    //     orderActions.toReview(
    //     "",
    //     payload,
    //     `limit=${parseInt(rowsPerPage)}&skip=${pages}`
    //   )
    // );
  };

  const searchItem = (query) => {
    //   setLoadingLoader(true)
    const payload = {
      "storeCode": store,
      "orderSearch": query,
      "flags": {
        "product": true
      }
    };
    try {
      dispatch(orderActions.toReview("", payload, `limit=${parseInt(rowsPerPage)}&skip=${page}`))
      // localStorage.setItem('keywordsearch', query);
      // setKeywordsearch(query);
    } catch (error) {
      // setLoadingLoader(false)
    }
  }

  const handleAlert = () => {
    setOpenAlert(false);
  };

  const reOrder = (orderId, event) => {
    event.stopPropagation()
    const payload = {
      "storeCode": store,
      "orderId": orderId
    }
    dispatch(orderActions.reOrderProduct("", payload))

    const payload1 = {}
    dispatch(productActions.getCartData("payload", payload1));

    setOpenAlert(true)
    setMainHeading(t("ProductAddedtoCart.label"))
    setSubHeading1(t("Thankyouforreorderingthisproduct!.label"))
  }

  const routeToProductDetailsPage = (items) => {
    history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
  }

  const totalPages =
    tableDatatoreview && tableDatatoreview.totalCount
    && Math.ceil(parseInt(tableDatatoreview.totalCount) / parseInt(rowsPerPage));

  return <>
    <Helmet>
      <title>Betagro My Order</title>
    </Helmet>
    {/* {loading && (<Loading />)} */}
    {(!tableDatatoreview.result && (!torevieworderList || torevieworderList.code !== 400)) && (<Loading />)}
    <ThankYouForPurchaseModal
      image={ThankYouImage}
      cart={true}
      buttonLabel={t("ok.label")}
      mainHeading="productAddedToCart.lable1"
      subHeading1="productAddedToCart.lable2"
      open={openAlert}
      ClickAction={handleAlert}
    />
    {/* search-bar-section start from here */}
    <div className='col-md-12 p-0 my-order-search-bar-margin-bottom'>
      <div className='my-order-tab-search-bar-main-container'>
        <input type="text"
          value={userQuery}
          onChange={(e) => {
            setUserQuery(e.target.value);
            delayedQuery(e.target.value);
          }}
          placeholder={t("Searchorder.label")}
          className='my-order-tab-search-bar-input' />
        <img src={SearchImage} className="search-by-order-number-input-icon" />
      </div>
    </div>
    {/* search-bar-section end */}
    {/* cards-section start from here */}
    {tableDatatoreview && tableDatatoreview.result && tableDatatoreview.result.map((items, index) => (
      <div className='col-md-12 p-0 my-order-tab-accordion-custom-container'>
        <div className='my-order-tab-accordion-cards'>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="1">
              <Accordion.Header>

                <div className='col-xl-6 col-md-6'>
                  <div className='my-order-search-order-number-main-container'>
                    <div className='d-flex flex-row'>
                      <img src={TagImage} className='my-order-search-order-tag-image' /> <h6 className='my-order-search-order-number-heading'>{t("OrderNo.label")}. {items.orderNo}</h6>
                      {items.status === "cancelled" && (
                        <div className='my-order-order-cancelled-status-container'>
                          <img src={CancelledImage} className='my-order-cancelled-status-image' />
                          <h6 className='my-order-cancelled-status-heading'>{t("Cancelled.label")}</h6>
                        </div>
                      )}
                      {items.status === "new" && (
                        <div className='my-order-order-status-inner-container'>
                          <img src={OrderPlaceImage} className='my-order-completed-status-image' />
                          <h6 className='my-order-order-heading'>Order Placed</h6>
                        </div>
                      )}
                      {items.status === "to_confirm" && (
                        <div className='my-order-order-status-inner-container'>
                          <img src={OrderPlaceImage} className='my-order-completed-status-image' />
                          <h6 className='my-order-order-heading'>Order Placed</h6>
                        </div>
                      )}
                      {items.status == 'confirmed' && (
                        <div className='my-order-order-status-confirmed-container'>
                          <img src={ComfirmedOrderImage} className='my-order-completed-status-image' />
                          <h6 className='my-order-confirmed-order-heading'>{t("Confirmed.label")}</h6>
                        </div>
                      )}
                      {items.status == 'shipped' && (
                        <div className='my-order-shipped-status-container'>
                          <img src={ShippedImage} className='my-order-completed-status-image' />
                          <h6 className='my-order-shipped-status-heading'>{t("Shipped.label")}</h6>
                        </div>
                      )}
                      {items.status == 'completed' && (
                        <div className='my-order-order-completed-status-container'>
                          <img src={CompletedImage} className='my-order-completed-status-image' />
                          <h6 className='my-order-completed-status-heading'>{t("Completed.label")}</h6>
                        </div>
                      )}
                    </div>
                    <div className='d-flex flex-row my-order-search-order-tag-margin'>
                      <img src={CalendarImage} className='my-order-search-calendar-image' /> <h6 className='my-order-search-order-date-heading'>{t("Orderdate.label")}<span className='my-order-search-order-date-right-heading'>{items.ordercreatedat}</span></h6>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 d-flex flex-row to-review-button-container'>
                  <div className='my-order-view-details-main-container'>
                    <button onClick={(event) => routeToOrderDetailsPage(items, event)} className={lancode === "en" ? 'my-order-view-details-button' : 'my-order-view-details-button-th'}>{t("ViewDetails.label")}</button>
                  </div>
                  <div className='my-order-view-details-main-container cursor-pointer-none'>
                    <button onClick={(event) => event.stopPropagation()} className={lancode === "en" ? 'my-order-view-details-button cursor-pointer-none' : 'my-order-view-details-button-th cursor-pointer-none'}>{t("Rate.label")}</button>
                  </div>
                  <div key={index} className='my-order-view-details-main-container'>
                    <button onClick={(event) => reOrder(items.orderId, event)} className='my-order-view-details-reorder-button'>{t("Reorder.label")}</button>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {items && items.orderitems && items.orderitems.map((item, index) => (
                  <>
                    {item.allocatedQtd != 0 && (
                      <>
                        <div className='my-order-gray-line' />
                        <div className='row'>
                          <div className='col-xll-8 col-xl-8 col-md-8'>
                            <div className='my-order-accordion-custom-main-container'>
                              <div className='my-order-accordion-custom-inner-container'>
                                <div className='d-flex flex-row my-order-accordion-custom-center'>
                                  <div className='my-order-accordion-custom-image-border'>
                                    <img
                                      onClick={() => routeToProductDetailsPage(item)}
                                      src={
                                        (item.product.picture && item.product.picture.split(",")[0] && item.product.picture.split(",")[0] != undefined && item.product.picture.split(",")[0] != '') ?
                                          (REACT_APP_BASE_URL +
                                            "/api/download?" +
                                            item.product.picture.split(",")[0]
                                            ? REACT_APP_BASE_URL +
                                            "/api/download?" +
                                            item.product.picture.split(",")[0]
                                            : blankImage)
                                          : blankImage
                                      }
                                      className='my-order-accordion-custom-image' />
                                  </div>

                                  {lancode === "en" && (
                                    <h6
                                      onClick={() => routeToProductDetailsPage(item)} className='my-order-accordion-custom-product-heading'>{item?.product?.consumerNameEN} {item?.product?.packSizeEN}</h6>
                                  )}
                                  {lancode === "th" && (
                                    <h6
                                      onClick={() => routeToProductDetailsPage(item)} className='my-order-accordion-custom-product-heading'>{item?.product?.consumerNameTH} {item?.product?.packSizeTH}</h6>
                                  )}


                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-xll-4 col-xl-4 col-md-4'>
                            <div className='my-order-customer-price-main-container'>
                              <h6 className='my-order-customer-price-heading'>฿ {convertToCurrencyFormat(item.price)}</h6>
                              {/* <h6 className='my-order-customer-price-inner-heading'>x{item.cartQuantity}</h6> */}
                              {item.allocatedQtd == null ? (
                                <h6 className='my-order-customer-price-inner-heading'>x{item.cartQuantity}</h6>
                              ) : (
                                <h6 className='my-order-customer-price-inner-heading'>x{item.allocatedQtd}</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  </>
                ))}
                <div className='my-order-gray-line' />
                <div className='row'>
                  <div className='col-xl-8'>

                  </div>
                  <div className='col-xl-4'>
                    <div className='my-order-customer-total-price-main-container d-flex flex-row'>
                      <h6 className='my-order-customer-total-main-container'>{t("Total.label")}</h6>
                      <h6 className='my-order-customer-total-price-heading'>฿ {convertToCurrencyFormat(items.totalOrderPrice)}</h6>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    ))}
    {/* Mobile-cards-section start from here */}
    <div className='row my-order-display-block-mobile'>
      <div className='col-md-12 col-sm-12 col-12'>
        {mobileDatatoreview && mobileDatatoreview.result && mobileDatatoreview.result.map((items, index) => (
          <>
            <div className='my-orders-search-cards-mobile-main-container'>
              <div className='my-orders-search-cards-mobile-inner-container'>
                <div className='row'>
                  <div className='col-xll-7 col-xl-7 col-md-7 col-sm-7 col-7'>
                    <img src={TagImage} className='my-orders-search-cards-tag-image' /><span className='my-orders-search-cards-order-number'>{t("OrderNo.label")}. {items.orderNo}</span>
                  </div>
                  <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 text-right pl-0'>
                    <img src={CalendarImage} className='my-orders-search-cards-calendar-image' /><span className='my-orders-search-cards-order-date'>{items.ordercreatedat}</span>
                  </div>
                  <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                    <div className='d-flex flex-row my-orders-search-cards-quantity-container'>
                      <h6 className='my-orders-search-cards-quantity-heading'>{t("Quantity.label")}:</h6>
                      <h6 className='my-orders-search-cards-quantity-counts'>{items.orderitems.length}</h6>
                    </div>
                  </div>
                  <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                    <div className='d-flex flex-row float-right my-orders-search-cards-quantity-container'>
                      <h6 className='my-orders-search-cards-total-heading'>{t("GrantTotalText.label")}:</h6>
                      <h6 className='my-orders-search-cards-total-counts'>฿ {convertToCurrencyFormat(items.totalOrderPrice)}</h6>
                    </div>
                  </div>
                  <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 my-orders-search-cards-button-section'>
                    {items.status == 'cancelled' && (
                      <div className='my-orders-search-cards-cancelled-button'>
                        <img src={CancelledImage} className='my-orders-search-cards-completed-button-image' />
                        <span className='my-orders-search-cards-cancelled-button-heading'>{t("Cancelled.label")}</span>
                      </div>
                    )}
                    {items.status == 'new' && (
                      <div className='my-orders-search-cards-placed-button'>
                        <img src={OrderPlaceImage} className='my-orders-search-cards-completed-button-image' />
                        <span className='my-orders-search-cards-placed-button-heading'>{t("OrderPlaced.label")}</span>
                      </div>
                    )}
                    {items.status == 'to_confirm' && (
                      <div className='my-orders-search-cards-placed-button'>
                        <img src={OrderPlaceImage} className='my-orders-search-cards-completed-button-image' />
                        <span className='my-orders-search-cards-placed-button-heading'>{t("OrderPlaced.label")}</span>
                      </div>
                    )}
                    {items.status == 'confirmed' && (
                      <div className='my-orders-search-cards-comfirmed-button'>
                        <img src={ComfirmedOrderImage} className='my-orders-search-cards-completed-button-image' />
                        <span className='my-orders-search-cards-comfirmed-button-heading'>{t("Confirmed.label")}</span>
                      </div>
                    )}
                    {items.status == 'shipped' && (
                      <div className='my-orders-search-cards-shipped-button'>
                        <img src={ShippedImage} className='my-orders-search-cards-completed-button-image' />
                        <span className='my-orders-search-cards-shipped-button-heading'>{t("Shipped.label")}</span>
                      </div>
                    )}
                    {items.status == 'completed' && (
                      <div className='my-orders-search-cards-completed-button'>
                        <img src={CompletedImage} className='my-orders-search-cards-completed-button-image' />
                        <span className='my-orders-search-cards-completed-button-heading'>{t("Completed.label")}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pl-0 my-orders-search-cards-button-section'>
                    <button onClick={(event) => routeToOrderDetailsPage(items, event)} className='my-orders-search-cards-view-details-button'>
                      {t("ViewDetails.label")}
                    </button>
                  </div>
                  <div className={lancode === "en" ? 'col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 pl-0 my-orders-search-cards-button-section' : 'col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 pl-0 pr-0 my-orders-search-cards-button-section'}>
                    <button onClick={(event) => reOrder(items.orderId, event)} className='my-orders-search-cards-reorder-button'>
                      {t("Reorder.label")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
        {/* <div className='my-orders-search-cards-mobile-main-container'>
                    <div className='my-orders-search-cards-mobile-inner-container'>
                        <div className='row'>
                            <div className='col-xll-7 col-xl-7 col-md-7 col-sm-7 col-7'>
                                <img src={TagImage} className='my-orders-search-cards-tag-image' /><span className='my-orders-search-cards-order-number'>Order No. 1234567890</span>
                            </div>
                            <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 text-right pl-0'>
                                <img src={CalendarImage} className='my-orders-search-cards-calendar-image' /><span className='my-orders-search-cards-order-date'>01/10/2022</span>
                            </div>
                            <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                <div className='d-flex flex-row my-orders-search-cards-quantity-container'>
                                    <h6 className='my-orders-search-cards-quantity-heading'>Quantity:</h6>
                                    <h6 className='my-orders-search-cards-quantity-counts'>3</h6>
                                </div>
                            </div>
                            <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                <div className='d-flex flex-row float-right my-orders-search-cards-quantity-container'>
                                    <h6 className='my-orders-search-cards-total-heading'>Total:</h6>
                                    <h6 className='my-orders-search-cards-total-counts'>฿ 2,000</h6>
                                </div>
                            </div>
                            <div className='col-xll-5 col-xl-5 col-md-5 col-sm-5 col-5 my-orders-search-cards-button-section'>
                                <div className='my-orders-search-cards-completed-button'>
                                    <img src={CompletedImage} className='my-orders-search-cards-completed-button-image' />
                                    <span className='my-orders-search-cards-completed-button-heading'>Completed</span>
                                </div>
                            </div>
                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 pl-0 my-orders-search-cards-button-section'>
                                <button className='my-orders-search-cards-view-details-button'>
                                    View details
                                </button>
                            </div>
                            <div className='col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 pl-0 my-orders-search-cards-button-section'>
                                <button className='my-orders-search-cards-reorder-button'>
                                    Reorder
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
    {/* Mobile-cards-section end */}
    {/* Pagination-section start from here */}
    {tableDatatoreview && tableDatatoreview.result && tableDatatoreview.result.length > 0 && (
      <div className="row pagination-main-container common-display-none-mobile">
        <div className="col-xll-6 col-xl-6 col-md-6 col-4"></div>
        <div className="col-xll-6 col-xl-6 col-md-6 col-12 mt-32">
          {tableDatatoreview.totalCount > 10 && <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={(event, page) => handleChangePage(event, page)}
            />
          </Stack>}
        </div>
      </div>
    )}
    {/* Pagination-section start from here */}
    {/* no-order-yet-section start from here */}
    {tableDatatoreview.length == 0 && (
      <div className='col-sm-12 col-md-12'>
        <div className='table-empty-data-main-container'>
          <img src={emptyTableData} className='table-empty-data-image' />
          <h6 className='table-empty-data-heading'>{t("Noorderyet.label")}</h6>
          <div className='my-cart-is-empty-button'>
            <Link to={"/allproducts"}> <span className='my-cart-is-empty-text'>{t("ContinueShopping.label")}</span></Link>
          </div>
        </div>
      </div>
    )}
    {/* no-order-yet-section end */}
  </>
}
export default ToReviewTab;