import React, { useState } from "react";
import { isMobile } from 'react-device-detect';
import LocationGreen from "./Assets/SVG/LocationGreen.svg";
import { ReactComponent as LocationGrey } from "./Assets/SVG/LocationGrey.svg";
import { ReactComponent as TelGrey } from "./Assets/SVG/TelGrey.svg";
import TelLightGrey from "./Assets/SVG/TelLightGrey.svg";
import "./StoreLocation.css";


const StoreLocation = (props) => {
  const lancode = localStorage.getItem("lancode");
  const [storelocationphonevalue, setStorelocationphonevalue] = useState(props.storeDetails.phone);
  let parts = storelocationphonevalue.match(/.{1,3}/g);
  return (
    <>
      <div className="Store-loc hidden-xs">
        {lancode === "en" && (
          <>
            <h4>
              <span>
                <LocationGrey />
              </span>
              {props.storeDetails.storeNameEN
                ? props.storeDetails.storeNameEN.split(" ")[2]
                : ""}
            </h4>
            <p>
              {props.storeDetails.addressEN}, {props.storeDetails.subDistrictEN}, {props.storeDetails.districtEN}, {props.storeDetails.provinceEN}{" "} {props.storeDetails.postalCodeEN}
            </p>

          </>
        )}

        {lancode === "th" && (
          <>
            <h4>
              <span>
                <LocationGrey />
              </span>
              {props.storeDetails.storeNameTH
                ? props.storeDetails.storeNameTH.split(" ")[1]
                : ""}
            </h4>
            <p>
              {props.storeDetails.addressTH}, {props.storeDetails.subDistrictTH}, {props.storeDetails.districtTH}, {props.storeDetails.provinceTH}{" "} {props.storeDetails.postalCodeTH}
            </p>

          </>
        )}
        <h4>
          {isMobile ?
            <a href={props.storeDetails.phone}>
              <span>
                <TelGrey />
              </span>
              {/* {props.storeDetails.phone}
*/} {parts && parts.length > 0 && parts.slice(0, 2).map((items, index) => (
                <span key={index} style={{ margin: "0" }}>{items}-</span>))}
              {parts && parts.length > 0 && parts.slice(2, parts.length).map((items, index) => (
                <span key={index} style={{ margin: "0" }}>{items}</span>))}
            </a>
            :
            <>
              <span>
                <TelGrey />
              </span>
              {/* {props.storeDetails.phone}
 */} {parts && parts.length > 0 && parts.slice(0, 2).map((items, index) => (
                <span key={index} style={{ margin: "0" }}>{items}-</span>))}
              {parts && parts.length > 0 && parts.slice(2, parts.length).map((items, index) => (
                <span key={index} style={{ margin: "0" }}>{items}</span>))}
            </>
          }

        </h4>
      </div>
      <div className="Store-loc-mob visible-xs">
        {lancode === "en" && (
          <>
            <div className="row2 align-items-center">
              <div className="col-6">
                <h4>
                  <img src={LocationGreen} />
                  {props.storeDetails.storeNameEN
                    ? props.storeDetails.storeNameEN.split(" ")[2]
                    : ""}
                </h4>
              </div>
              <div className="col-6">
                <p>
                  <a href={"tel:" + props.storeDetails.phone}>
                    <img src={TelLightGrey} />
                    {/*  {props.storeDetails.phone} */}
                    {parts && parts.slice(0, 2).map((items, index) => (
                      <span key={index} style={{ margin: "0" }}>{items}-</span>
                    ))}
                    {parts && parts.length > 0 && parts.slice(2, parts.length).map((items, index) => (
                      <span key={index} style={{ margin: "0" }}>{items}</span>))}

                  </a>
                </p>
              </div>
            </div>{" "}
          </>
        )}

        {lancode === "th" && (
          <>
            <div className="row d-flex align-items-center">
              <div className="col-6">
                <h4>
                  <img src={LocationGreen} />
                  {props.storeDetails.storeNameTH
                    ? props.storeDetails.storeNameTH.split(" ")[1]
                    : ""}
                </h4>
              </div>
              <div className="col-6">
                <p style={{ textAlign: "right" }}>
                  <a href={"tel:" + props.storeDetails.phone}>
                    <img src={TelLightGrey} />
                    {/*  {props.storeDetails.phone} */}
                    {parts && parts.slice(0, 2).map((items, index) => (
                      <span key={index} >{items}-</span>
                    ))}
                    {parts && parts.length > 0 && parts.slice(2, parts.length).map((items, index) => (
                      <span key={index} >{items}</span>))}

                  </a>
                </p>
              </div>
            </div>{" "}
          </>
        )}
      </div>
    </>
  );
};

export { StoreLocation as StoreLocation };

