import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Card from '@mui/joy/Card';
import { MDBBtn } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { IconContext } from "react-icons";
import { BsCart3 } from "react-icons/bs";
import { RiHeart3Fill } from 'react-icons/ri';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { IncDecCounter } from './IncDecCounter';
import { Quantityselect } from './Quantityselect';
import './carousel.css';
import { multiData } from './data';

let slidesToShow = 5;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <ArrowBackIos style={{ color: 'blue', fontSize: '30px' }} />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <ArrowForwardIos style={{ color: 'blue', fontSize: '30px' }} />
        </div>
      )}
    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

const MultiCarouselPage = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 5;
  } else {
    slidesToShow = 7;
  }

  return (
    <div style={{ margin: '10px' }} className='carousel'>
      <Slider {...carouselProperties}>
        {multiData.map((item) => (
          <Card1 item={item} />
        ))}
      </Slider>
    </div>
  );
};

const Card1 = ({ item }) => {
  return (
    <Card variant="outlined" sx={{ width: 150, backgroundColor: "white", border: "none", }}>

      <div className="card flip-z"> <div className="card-front"><img
        className='multi__image'
        src={item}
        alt=''
        style={{
          width: '100%',
          height: '90px',
          objectFit: 'contain',
          marginBottom: '5px',
        }}
      /></div>
        <div className="card-back"><img
          className='multi__image'
          src={item}
          alt=''
          style={{
            width: '100%',
            height: '90px',
            objectFit: 'contain',
            marginBottom: '5px',
          }}
        /></div></div>
      <table ><tr><td colSpan={2}>Betagro Title</td></tr>
        <tr><td colSpan={1} title="Click to add Quantity to add to Cart"> <Quantityselect /></td></tr>
        <tr><td><RiHeart3Fill className={'heart'} title="Click to add to WishList" /><IncDecCounter /><MDBBtn filled rounded color='success' size='sm' title="click to shop now and see all the categories"><IconContext.Provider value={{ color: "white", className: "global-class-name" }}><BsCart3 /></IconContext.Provider>Add To Cart</MDBBtn></td></tr>
      </table>
    </Card>
  );
};


export { MultiCarouselPage as MultiCarouselPage };

