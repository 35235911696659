import { myAccountConstant } from "../_constants";
import { myAccountService } from "../_services";

export const myAccountAction = {
  getOrderDetails
};


function getOrderDetails(orderId, payload,) {

  return (dispatch) => {
      dispatch(request());
      myAccountService.getOrderDetails(orderId,payload)
          .then((res) => {

              if (res.status === 200 && res.data) {
                  dispatch(success(res.data));
              } else if ( res.status === undefined && res.response.data.code === 400) {
                  dispatch(noDataFound(res.data));
              }
          })
          .catch((error) => dispatch(failure(error)));
  }
  function request() {
      return { type: myAccountConstant.GET_ORDER_DETAILS_REQUEST };
  }
  function success(data) {
      return { type: myAccountConstant.GET_ORDER_DETAILS_SUCCESS, data };
  }
  function noDataFound(data) {
      return { type: myAccountConstant.GET_ORDER_DETAILS_NODATA, data };
  }
  function failure(error) {
      return { type: myAccountConstant.GET_ORDER_DETAILS_FAILURE, error };

  }
}
