let url=""
let env=""
if(window.location.hostname=='localhost' || window.location.hostname=="localhost"){
    url="http://localhost:8080";
    env='local'
}
else if(window.location.hostname=='dev.betagro-foodmart.com'){
    url="https://dev.betagro-foodmart.com";
    env='dev'
}
else if(window.location.hostname=='qa.betagro-foodmart.com'){
    url="https://qa.betagro-foodmart.com";
    env='qa'
}
else if(window.location.hostname=='uat.betagro-foodmart.com'){
    url="https://uat.betagro-foodmart.com";
    env='uat'
}
else if(window.location.hostname=='www.betagro-foodmart.com' || window.location.hostname=="www.betagro-foodmart.com"){
    url="https://www.betagro-foodmart.com";
    env='prod'
}
else{
    url="https://bshop-digitization-api.azurewebsites.net";
    env='local'
}
const REACT_APP_BASE_URL = url
const ENVIRONMENT = env
export {REACT_APP_BASE_URL,ENVIRONMENT}
