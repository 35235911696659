import { history } from "../_helpers";

export const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

export  const convertToCurrencyFormat = (value = 0) => {
    value = value === "null" ? 0 : value;
  
    const currency = Intl.NumberFormat("en-us").format(Number(value).toFixed(2));
  
    let afterDecimal = currency.includes(".") ? currency.split(".")[1] : "00";
  
    if (afterDecimal.length === 1) {
  
      afterDecimal = `${afterDecimal}0`;
  
    }
  
    return `${currency.split(".")[0]}.${afterDecimal}`;
  };

  export  const Base64 = {

    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",


    encode: function(input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

        }

        return output;
    },


    decode: function(input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        while (i < input.length) {

            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }

        }

        output = Base64._utf8_decode(output);

        return output;

    },

    _utf8_encode: function(string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    },

    _utf8_decode: function(utftext) {
        var string = "";
        var i = 0;
        var c = 0;
        var c2 = 0
        var c1 = 0

        while (i < utftext.length) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        }

        return string;
    }

}

export const setUpCartandSpecialPrices = (count, temp) => {
    let price;
    count = (count)?parseInt(count):1;
        let packSize = 1;
        if(temp.packSize) {
        packSize = parseFloat(temp.packSize);
        }
    // if (isNull(temp.conditionAmount)) {
        if ((Number(temp.quantity1Scale1) <= (count*packSize) || (count*packSize) < 1 ) && Number(temp.quantity1Scale1) != 0) {
        price = Number(temp.price1Scale1)
    }
    if (Number(temp.quantity2Scale2) <= (count*packSize) && Number(temp.quantity2Scale2) != 0) {
        price = Number(temp.price2Scale2)
    }
    if (Number(temp.quantity3Scale3) <= (count*packSize) && Number(temp.quantity3Scale3) != 0) {
        price = Number(temp.price3Scale3)
    }
    if (Number(temp.quantity4Scale4) <= (count*packSize) && Number(temp.quantity4Scale4) != 0) {
        price = Number(temp.price4Scale4)
    }
    if (Number(temp.quantity5Scale5) <= (count*packSize) && Number(temp.quantity5Scale5) != 0) {
        price = Number(temp.price5Scale5)
    }
    // if (count < Number(temp.quantity5Scale5)) {
    //     price = Number(temp.price5Scale5)
    // }
    return price
    // }

}

export const isNull = (value) => {
    return value == "" || value == undefined || value == null
  }

  export const nospaces =(t) =>{
    if (t.match(/\s/g) || t.match(/\./g)) {
      return false;
    //   t.value = (t.replace(/\s/g, '').replace(/\./g, ''));
    }else{
        return true;
    }
  }

  export const customLogout =(t) =>{
    const userDetails = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : undefined;
    if(userDetails && userDetails.user){
        localStorage.removeItem("user");
        localStorage.removeItem("authorization");
        localStorage.removeItem("keepMeLoggedIn");
        localStorage.removeItem("phone");
        localStorage.removeItem("password");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("authorization");
        history.push("/login");
    }

  }