import React from 'react';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createOrderAction } from "../../../_actions";
import ApiList from "../../../_constants/const.api";
import { convertToCurrencyFormat, isNull } from '../../../_helpers/commanFunction';
import ApiProvider from "../../../_services/ApiProvider";
import { REACT_APP_BASE_URL } from "../../../constant";
import AddPlus from "../../../images/add-plus.png";
import ProductImage from '../../../images/cart-shopping-green.png';
import crossImage from '../../../images/delete-icon.png';
import blankImage from "../../../images/empty-image.png";
import minusIcon from '../../../images/my-cart-minus.png';
import minusPlus from '../../../images/my-cart-plus.png';
import Loading from "../../../SharedComponent/Loader/Loader";
import ProductInfoSearchInfo from './SearchBox';

const ProductDetails = (props) => {
    const dispatch = useDispatch();
    const [products, setProducts] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [userNote, setUserNote] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [subtotal, setSubtotal] = React.useState(0);
    const history = useHistory();
    const orderDetails = useSelector((state) => state.createOrder.orderDetails);
    const orderDetailsLoading = useSelector((state) => state.createOrder.orderDetailsLoading);
    const orderCartDetailsState = useSelector((state) => state.createOrder.orderCartDetails);
    const orderDetailsByIdState = useSelector((state) => state.createOrder.orderDetailsById);
    const orderDetailsLoadingState = useSelector((state) => state.createOrder.orderDetailsLoading);
    const userData = sessionStorage.getItem("user")
        ? JSON.parse(sessionStorage.getItem("user"))
        : undefined;



    const store = userData ? userData.user.stores[0].storeCode : "";

    const hideLoading = () => {
        setTimeout(() => {
            setLoading(false)
        }, 400);
    }

    React.useEffect(() => {
        if (props.customerUserId) {
            let payload = {
                "orderId": props.orderId,
                "userId": props.customerUserId
            }
            dispatch(createOrderAction.getOrderDetailsByOrderId(payload))
            hideLoading()
        } else {
            setTableData([])
            hideLoading()
        }

    }, [props.customerUserId])

    React.useEffect(() => {
        if (orderDetails && orderDetails.data) {
            setUserNote(orderDetails?.data.userNote)
        }

    }, [orderDetails])

    React.useEffect(() => {

        // setCartUpdated(false)
        if (orderDetailsByIdState && orderDetailsByIdState.data) {
            const newData = orderDetailsByIdState?.data.map(items => {
                if (isNull(items?.product?.conditionAmount)) {
                    items.currentUnitPrice = items.price
                } else {
                    items.currentUnitPrice = items?.product?.conditionAmount
                }
                return items
            })
            let priceArray = orderDetailsByIdState?.data.map(items => items.product.packSize !== "" ? Number(items.product.packSize) * items.currentUnitPrice * items.cartQuantity : items.currentUnitPrice * items.cartQuantity)

            priceArray && priceArray.length > 0 ? setSubtotal(priceArray.reduce(function (a, b) { return a + b; }, 0)) : setSubtotal(0)

            setTableData(newData)
            setLoading(false)
        } else {
            setTableData([])
            setLoading(false)
        }

    }, [orderDetailsByIdState])



    React.useEffect(() => {
        // setCartUpdated(false)
        if (orderCartDetailsState && orderCartDetailsState.data) {
            let payload = {
                "orderId": props.orderId,
                "userId": props.customerUserId
            }
            dispatch(createOrderAction.getOrderDetailsByOrderId(payload))
            hideLoading()

        } else {
            hideLoading()
        }

    }, [orderCartDetailsState])



    const routeToProductDetailsPage = (items) => {
        history.push("/productdetailscustomer/" + items.articaleCode, { articaleCode: items.articaleCode, storeCode: items.storeCode });
        // history.push("/productdetailscustomer", { articaleCode: items.articaleCode, storeCode: items.storeCode });                          // history.push("/productdetailscustomer")                  
    }
    const updateQuantity = (e, type, itemDetails) => {
        e.preventDefault()
        // getselectedCartId(JSON.parse(JSON.stringify(products)))
        let count = itemDetails.cartQuantity;
        if (type == "custom") {
            if (isNaN(e.target.value)) { e.target.value = itemDetails.cartQuantity; return false; }
            count = e.target.value
        } else {
            count = type == "add" ? count + 1 : count - 1
        }

        if (count && count !== "0") {
            setLoading(true)
            let payload = {
                orderId: itemDetails.orderId,
                orderItems: [
                    {
                        articaleCode: itemDetails.articaleCode,
                        cartQuantity: count
                    }
                ]
            }
            dispatch(createOrderAction.updateQty(payload))
        }
        if ((count == "0" || count < 1) && count !== "") {
            removeCart(e, itemDetails.orderId, itemDetails.articaleCode)
        }

    }

    const removeCart = (e, orderId, articaleCode) => {
        e.preventDefault()
        const payload = {
            "orderId": orderId,
            "articaleCode": articaleCode
        }

        setLoading(true)
        ApiProvider.post(ApiList.API_URL_TO_REMVOE_ORDER_ITES, payload).then((response) => {
            if (response) {
                let payload = {
                    "orderId": props.orderId,
                    "userId": props.customerUserId
                }
                dispatch(createOrderAction.getOrderDetailsByOrderId(payload))
            } else {
                setLoading(false)
            }

        })

    };
    const handleUserNote = (e) => {
        setUserNote(e.target.value)
        props.setProductInfoUserNote(e.target.value)
    }
    return (
        <>
            <Helmet>
                <title>Create Order</title>
            </Helmet>
            {loading && (<Loading />)}
            <div className='col-xll-12 col-xl-12 col-md-12 col-sm-12 col-12 create-order-box-margin p-0'>
                <div className='create-order-common-box-shadow'>
                    <div className='d-flex flex-row'>
                        <img src={ProductImage} className='create-order-top-heading-cart-shopping-image' />
                        <h6 className='create-order-box-shadow-top-heading'>Product Info</h6>
                    </div>
                    {/* search-bar-section start from here */}
                    <ProductInfoSearchInfo
                        orderId={props.orderId}
                        customerUserId={props.customerUserId}
                        customerCode={props.customerCode}
                        updateQuantity={updateQuantity}
                    />
                    {/* search-bar-section end */}
                    <div className='product-info-common-margin'>
                        {/* product-info-header section start from here */}
                        <div className='row product-info-inner-header-background'>
                            <div className='col-xll-3 col-xl-3 p-0'>
                                <h6 className='product-info-inner-header-main-heading'>Product</h6>
                            </div>
                            <div className='col-xll-3 col-xl-3'>
                                <h6 className='product-info-inner-header-main-heading'>Article Code</h6>
                            </div>
                            <div className='col-xll-2 col-xl-2'>
                                <h6 className='product-info-inner-header-main-heading'>Unit Price</h6>
                            </div>
                            <div className='col-xll-3 col-xl-2 text-center'>
                                <h6 className='product-info-inner-header-main-heading'>Quantity</h6>
                            </div>
                            <div className='col-xll-2 col-xl-2 text-center'>
                                <h6 className='product-info-inner-header-main-heading'>Total</h6>
                            </div>
                        </div>
                        {/* product-info-header section end here */}
                        {/* product-info-inner-data section satrt from here */}
                        {

                            tableData && tableData.length > 0
                                ? tableData.map((items, index) => {
                                    const isLast = items.length - 1 === index;
                                    return (
                                        <>
                                            <div className='row create-order-produt-info-alignment'>
                                                {/* Product image and heading */}
                                                <div className='col-xll-3 col-xl-3 col-md-3 col-sm-3 col-3 p-0'>
                                                    <div className='d-flex flex-row'>
                                                        <div className='create-order-product-info-image-border'>
                                                            {/* <img src={ProductNameImage} className='create-order-product-info-image' /> */}
                                                            <img onClick={() => routeToProductDetailsPage(items.product)} title=" Click to Product Details"
                                                                src={
                                                                    (items.product.picture && items.product.picture.split(",")[0] && items.product.picture.split(",")[0] != undefined && items.product.picture.split(",")[0] != '') ?
                                                                        (REACT_APP_BASE_URL +
                                                                            "/api/download?" +
                                                                            items.product.picture.split(",")[0]
                                                                            ? REACT_APP_BASE_URL +
                                                                            "/api/download?" +
                                                                            items.product.picture.split(",")[0]
                                                                            : blankImage)
                                                                        : blankImage}
                                                                className='create-order-product-info-left-images' />
                                                        </div>
                                                        <h6 className='create-order-product-custom-heading'>{items.product.consumerNameTH}</h6>


                                                    </div>
                                                </div>
                                                {/* Article code */}
                                                <div className='col-xll-3 col-xl-3 text-left'>
                                                    <h6 className='create-order-product-info-article-code'>{items.product.articaleCode}</h6>
                                                    <h6 className='create-order-product-info-article-code-inner'>{items.product.productNameTH}</h6>
                                                </div>
                                                {/* Unit Price */}
                                                <div className='col-xll-2 col-xl-2 text-left'>
                                                    <h6 className='create-order-product-info-unit-price'>฿ {convertToCurrencyFormat(items.currentUnitPrice)}</h6>
                                                </div>
                                                {/* Quantity */}
                                                <div className='col-xll-3 col-xl-2 text-left'>
                                                    <div className='d-flex flex-row my-cart-add-minus-background'>
                                                        <img src={minusIcon} className='my-cart-add-plus-image'
                                                            onClick={(e) => { updateQuantity(e, "minus", items) }}
                                                        />

                                                        <input type="text" key={items.cartQuantity} className='my-cart-input-group' defaultValue={items.cartQuantity} name={"qty" + index} onChange={(e) => {
                                                            updateQuantity(e, "custom", items)
                                                        }} />


                                                        <img src={minusPlus} className='my-cart-add-plus-image'
                                                            onClick={(e) => {
                                                                updateQuantity(e, "add", items)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Total */}
                                                <div className='col-xll-2 col-xl-2 text-left'>
                                                    <h6 className='create-order-product-info-unit-price text-center'>฿

                                                        {convertToCurrencyFormat(items.product.packSize !== "" ? Number(items.product.packSize) * items.currentUnitPrice * items.cartQuantity : items.currentUnitPrice * items.cartQuantity)}

                                                        {/* {convertToCurrencyFormat(items.totalPrice)} */}
                                                    </h6>
                                                    <img src={crossImage} className='create-order-product-info-cross-image'

                                                        onClick={(e) => { removeCart(e, items.orderId, items.articaleCode) }}
                                                    />
                                                </div>
                                                <div className='create-order-info-section-gray-line' />
                                            </div>
                                        </>)
                                }) :
                                <>
                                    {
                                        !orderDetailsLoadingState && !orderDetailsLoadingState ?
                                            <div className='create-order-add-product-main-container'>
                                                <img src={AddPlus} className='create-order-add-product-image' />
                                                <h6 className='create-order-add-product-heading'>Please add Product in Order</h6>
                                            </div>
                                            : null
                                    }

                                </>

                        }
                        {/* product-info-inner-data end */}

                        {/* add-product-section start from here */}

                        {/* add-product-section end */}
                        <div className='row create-order-total-amount-main-container p-0'>
                            {/* note-optional-section start from here */}
                            <div className='col-xll-7 col-xl-7 col-md-7 col-sm-7 col-7 p-0'>
                                <div className='d-flex flex-row create-order-offer-input-main-container'>
                                    <input type="text" className='create-order-offer-input-text' disabled />
                                    <div className='create-order-offer-apply-button-main-container'>
                                        <span className='create-order-offer-apply-button-text'>Apply</span>
                                    </div>
                                </div>
                                <div className='create-order-note-option-main-container'>
                                    <h6 className='create-order-note-option-heading'>Note (optional)</h6>
                                    <textarea htmlFor="note" for="note" maxlength='100' onChange={handleUserNote} value={userNote} name="userNote" className='create-order-note-textarea' placeholder="Enter Note" />
                                    <h6 className='create-order-note-textarea-counts'>{userNote?.length ? userNote?.length : "0"}/100</h6>
                                </div>
                            </div>
                            {/* note-optional-section end */}
                            <div className='col-xll-1 col-xl-1 col-md-1 col-sm-1 col-1' />
                            {/* total-amount-section start from here */}
                            <div className='col-xll-4 col-xl-4 col-md-4 col-sm-4 col-4 create-order-total-right-amount-main-container'>
                                <div className='row'>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                        <h6 className='create-order-left-main-heading'>Subtotal ({tableData?.length > 0 ? tableData?.length : null})</h6>
                                    </div>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6'>
                                        <h6 className='create-order-right-main-heading text-right'>฿ {convertToCurrencyFormat(subtotal)}</h6>
                                    </div>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6 common-margin-top-16'>
                                        <h6 className='create-order-left-main-heading'>Shipping fee</h6>
                                    </div>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6 common-margin-top-16'>
                                        <h6 className='create-order-right-main-heading text-right'>฿ 0.00</h6>
                                    </div>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6 common-margin-top-16'>
                                        <h6 className='create-order-left-main-heading'>Promotion</h6>
                                    </div>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6 common-margin-top-16'>
                                        <h6 className='create-order-right-main-heading text-right'>-฿ 00.00</h6>
                                    </div>
                                    <div className='create-order-total-amount-gray-line common-margin-top-16' />
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6 common-margin-top-16'>
                                        <h6 className='create-order-left-main-heading'>Total</h6>
                                    </div>
                                    <div className='col-xll-6 col-xl-6 col-md-6 col-sm-6 col-6 common-margin-top-16'>
                                        <h6 className='create-order-right-main-heading text-right'>฿ {convertToCurrencyFormat(subtotal)}</h6>
                                    </div>
                                </div>
                            </div>
                            {/* total-amount-section end */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDetails