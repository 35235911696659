import { productConstants } from "../_constants";
const initReducers = {
  getAllProducts: null,
  allProductsFilter: null,
  productDetail: null,
  cartDetails: null,
  whishlistDetails: null,
  cartAdded: null,
  cartRemove: null,
  UserList: null,
  taxResponse: null,
  userAddressSaveFromCheckout: null,
  productLoading: false,
  whishlistDetailsInformationList: null,
  whishlistDetailsInformationError: null,
  postuploadproductsList: null,
  postuploadproductsError: null,
  addWishlistDetails: null,
  wishListLoading: false

};
export function product(state = initReducers, action) {
  switch (action.type) {
    case productConstants.POST_UPLOAD_PRODUCTS_REQUEST_INFORMATION:
      return {
        ...state,
        postuploadproductsList: action.data,
        postuploadproductsError: action.error,
      };
      case productConstants.POST_UPLOAD_PRODUCTS_SUCCESS_INFORMATION:
      return {
        ...state,
        postuploadproductsList: action.data,
        postuploadproductsError: action.error,
      };
      case productConstants.POST_UPLOAD_PRODUCTS_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        postuploadproductsList: action.data,
        postuploadproductsError: action.error,
      };
      case productConstants.POST_UPLOAD_PRODUCTS_FAILURE_INFORMATION:
      return {
        ...state,
        postuploadproductsList: action.data,
        postuploadproductsError: action.error,
      };

    case productConstants.GET_WISH_LIST_REQUEST_INFORMATION:
      return {
        ...state,
        whishlistDetailsInformationList: action.data,
        whishlistDetailsInformationError: action.error,
      };
    case productConstants.GET_WISH_LIST_SUCCESS_INFORMATION:
      return {
        ...state,
        whishlistDetailsInformationList: action.data,
        whishlistDetailsInformationError: action.error,
      };
    case productConstants.GET_WISH_LIST_NODATA_FOUND_INFORMATION:
      return {
        ...state,
        whishlistDetailsInformationList: action.data,
        whishlistDetailsInformationError: action.error,
      };
    case productConstants.GET_WISH_LIST_FAILURE_INFORMATION:
      return {
        ...state,
        whishlistDetailsInformationList: action.data,
        whishlistDetailsInformationError: action.error,
      };
    case productConstants.PRODUCT_GET_SUCCESS:
      return {
        ...state,
        getAllProducts: action.data,
      };
    case productConstants.PRODUCT_GET_FAIL:
      return {
        ...state,
        getAllProducts: action.error,
      };
    case productConstants.PRODUCT_GET_FILTER_SUCCESS:
      return {
        ...state,
        allProductsFilter: action.data,
      };
    case productConstants.PRODUCT_GET_FILTER_FAIL:
      return {
        ...state,
        allProductsFilter: action.error,
      };
    case productConstants.GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetail: action.data,
      };
    case productConstants.GET_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        productDetail: action.data,
      };
    case productConstants.GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetail: action.data,
      };
    case productConstants.GET_PRODUCT_DETAILS_NO_DATA_FOUND:
      return {
        ...state,
        productDetail: null,
      };
    case productConstants.GET_CART_COUNT:
      return {
        ...state,
        cartDetails: action.data,
        productLoading: true
      };
    case productConstants.GET_CART_COUNT_SUCCESS:
      return {
        ...state,
        cartDetails: action.data,
        productLoading: false
      };
    case productConstants.GET_CART_COUNT_NODATA:
      return {
        ...state,
        cartDetails: null,
        productLoading: false
      };
    case productConstants.GET_CART_COUNT_FAILURE:
      return {
        ...state,
        cartDetails: action.error,
        productLoading: false
      };
    case productConstants.GET_WISH_LIST_REQUEST:
      return {
        ...state,
        whishlistDetails: action.data,
      };
    case productConstants.GET_WISH_LIST_FAILURE:
      return {
        ...state,
        whishlistDetails: action.data,
      };
    case productConstants.GET_WISH_LIST_NODATA_FOUND:
      return {
        ...state,
        whishlistDetails: null,
      };
    case productConstants.GET_WISH_LIST_SUCCESS:
      return {
        ...state,
        whishlistDetails: action.data,
      };

    case productConstants.ADD_PRODUCT_TO_CART_SUCCESS:
      return {
        ...state,
        cartAdded: action.data,
      };
    case productConstants.ADD_PRODUCT_TO_CART_FAILURE:
      return {
        ...state,
        cartAdded: action.error,
      };
    case productConstants.REMOVE_CART_SUCCESS:
      return {
        ...state,
        cartRemove: action.data,
      };
    case productConstants.REMOVE_CART_FAIL:
      return {
        ...state,
        cartRemove: action.error,
      };
    case productConstants.GETSALES_USERS_SUCCESS:
      return {
        ...state,
        UserList: action.data,
      };
    case productConstants.GETSALES_USERS_FAIL:
      return {
        ...state,
        UserList: action.error,
      };
    case productConstants.SAVE_TAX_INFO:
      return {
        ...state,
        taxResponse: action.data,
      };
    case productConstants.SAVE_TAX_INFO_SUCCESS:
      return {
        ...state,
        taxResponse: action.data,
      };
    case productConstants.SAVE_TAX_INFO_FAILURE:
      return {
        ...state,
        taxResponse: action.error,
      };

    case productConstants.SAVE_USERADDRESS_INFO:
      return {
        ...state,
        userAddressSaveFromCheckout: action.data,
      };
    case productConstants.SAVE_USERADDRESS_SUCCESS:
      return {
        ...state,
        userAddressSaveFromCheckout: action.data,
      };
    case productConstants.SAVE_USERADDRESS_FAILURE:
      return {
        ...state,
        userAddressSaveFromCheckout: action.error,
      };
      //
      case productConstants.ADD_PRODUCT_TO_WISHLIST:
        return {
          ...state,
          wishListLoading: true,
        };
      case productConstants.ADD_PRODUCT_TO_WISHLIST_SUCCESS:
        return {
          ...state,
          addWishlistDetails: action.data,
          wishListLoading: false,
        };
      case productConstants.ADD_PRODUCT_TO_WISHLIST_NOT_FOUND:
        return {
          ...state,
          addWishlistDetails: error,
          wishListLoading: false
        };
  
      case productConstants.ADD_PRODUCT_TO_WISHLIST_FAILURE:
        return {
          ...state,
          addWishlistDetails: null,
          wishListLoading: false
        };
    default:
      return state;
  }
}
