import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation, withTranslation } from "react-i18next";
import crossImage from '../images/cross-icon.png';
import filterImage from '../images/mobile-filter-icon.png';
import "./AllProductCards.css";

const Filters = (props) => {
  const { palette } = createTheme();
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState('');
  const theme = createTheme({
    palette: {

    }
  });
  const { t } = useTranslation();
  const [showHideClearText, setShowHideClearText] = useState("");
  const lancode = localStorage.getItem("lancode");
  const [openSortByDropDown, setOpenSortByDropDown] = useState(false);
  // const filterCount = props.filters && props.filters.brand && props.filters.brand.length + (props.filters && props.filters.specialProduct && props.filters.specialProduct.length) + (props.filters && props.filters.subCategorySlug && props.filters.subCategorySlug.length) + (props.filters && props.filters.channel && props.filters.channel.length)
  const filterCount = props.filters && props.filters.brand && props.filters.brand.length + (props.filters && props.filters.specialProduct && props.filters.specialProduct.length) + (props.filters && props.filters.subCategorySlug && props.filters.subCategorySlug.length) + (props.filters && props.filters.channel && props.filters.channel.length)
  const operation = (list1, list2, isUnion = false) =>
    list1.filter(a => isUnion === list2.some(b => a === b.subCategorySlug));

  // Following functions are to be used:
  const inBoth = (list1, list2) => operation(list1, list2, true),
    inFirstOnly = operation,
    inSecondOnly = (list1, list2) => inFirstOnly(list2, list1);

  const getCount = (arr => {
    return arr && arr.length ? arr.length : 0
  })

  const handleSelect = (event) => {
    // setSelectedValue(eventKey);
    props.handleValueWithRadio(event.target.value);
  };

  const handleSelected = (AccordionEventKey) => {
    setShowHideClearText(AccordionEventKey[1]);
  }

  return <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="col-sm-3 main-container-scroller-design">
          <div className="row stricky-top">
            <div className="col-sm-8 filter-count-container">
              <h6 className="filter-count-text">{t("filters.label")}&nbsp;{filterCount == 0 ? <span></span> : <span >({filterCount})</span>}</h6>
            </div>
            <div className="col-sm-4">
              <button className="location-button" onClick={e => props.resetFilter(e, undefined)}>  <h6 className="reset-filter-text" >{t("reset.label")}</h6></button>
            </div>
            <div className="col-sm-12 main-container-filter-accordion inner-container-scroller-design">
              <div className="gray-line-inner" />
              <Accordion defaultActiveKey={['0']} alwaysOpen onSelect={handleSelected}>

                {props.filterData && props.filterData.channel && props.filterData.channel.length > 0 && (
                  <Accordion.Item eventKey="20">
                    <Accordion.Header>
                      <div className="row">
                        <div className="col-sm-12 pl-3">
                          <div className="accordion-top-header">
                            <h6 className="brand-text-heading">{t("Channelf.label")}&nbsp;{props.filters.channel.length == 0 ? <span></span> : <span >({props.filters.channel.length})</span>}</h6>
                          </div>
                        </div>
                      </div>

                      {showHideClearText == 20 ? <h6 className="clear-text-heading" onClick={e => props.resetFilter(e, "channel")}>{t("clear.label")}</h6> : null}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        {props.filterData && props.filterData.channel && props.filterData.channel.map(channelFilter => {
                          return (
                            <div className="col-sm-12 inner-checkbox-container" key={channelFilter.titleEN}>
                              <Checkbox color="success" checked={props.filters.channel.includes(channelFilter.value) ? true : false} onChange={e => props.handleChange(e, "channel")} name={channelFilter.value} />
                              <span className={props.filters.channel.includes(channelFilter.value) ? 'active-heading-accodion' : 'inner-heading-accodion'}>
                                {lancode === "en" && (
                                  <span>
                                    {channelFilter.titleEN}
                                  </span>
                                )}
                                {lancode === "th" && (
                                  <span>
                                    {channelFilter.titleTH}
                                  </span>
                                )}
                              </span>
                            </div>
                          )
                        })
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {props.filterData && props.filterData.brand && props.filterData.brand.length > 0 && (
                  <>
                    <p className="gray-line-inner"></p>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="row">
                          <div className="col-sm-12 pl-3">
                            <div className="accordion-top-header">
                              <h6 className="brand-text-heading">{t("brand.label")}&nbsp;{props.filters.brand.length == 0 ? <span></span> : <span >({props.filters.brand.length})</span>}</h6>
                            </div>
                          </div>
                        </div>

                        {showHideClearText == 1 ? <h6 className="clear-text-heading" onClick={e => props.resetFilter(e, "brand")}>{t("clear.label")}</h6> : null}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          {props.filterData && props.filterData.brand && props.filterData.brand.map(brandFilter => {
                            return (
                              <div className="col-sm-12 inner-checkbox-container" key={brandFilter.titleEN}>
                                <Checkbox color="success" checked={props.filters.brand.includes(brandFilter.titleEN) ? true : false} onChange={e => props.handleChange(e, "brand")} name={brandFilter.titleEN} />
                                <span className={props.filters.brand.includes(brandFilter.titleEN) ? 'active-heading-accodion' : 'inner-heading-accodion'}>
                                  <span>
                                    {brandFilter.titleEN}
                                  </span>
                                  {/* {lancode === "th" && (
                                                                    <span>
                                                                        {brandFilter.titleTH}
                                                                    </span>
                                                                )} */}

                                </span>
                              </div>
                            )
                          })
                          }
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}

                {/* ----start special product---- */}
                {/* <p className="gray-line-inner"></p> */}
                {props.filterData && props.filterData.specialProduct && props.filterData.specialProduct.length > 0 && (
                  <><p className="gray-line-inner"></p>


                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="row">
                          <div className="col-sm-12 pl-3">
                            <div className="accordion-top-header">
                              <h6 className="brand-text-heading">{t("specialPro.label")}&nbsp;{props.filters.specialProduct.length == 0 ? <span></span> : <span >({props.filters.specialProduct.length})</span>}</h6>
                            </div>
                          </div>
                        </div>

                        {showHideClearText == 2 ? <h6 className="clear-text-heading" onClick={e => props.resetFilter(e, "specialProduct")}>{t("clear.label")}</h6> : null}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          {props.filterData && props.filterData.specialProduct && props.filterData.specialProduct.map(specialFilter => {
                            return (
                              <div className="col-sm-12 inner-checkbox-container" key={specialFilter.titleEN}>
                                <Checkbox color="success"
                                  onChange={e => props.handleChange(e, "specialProduct")}
                                  name={specialFilter.value}
                                  disabled={specialFilter.value === '7' || specialFilter.value === 7 || specialFilter.value === '2' || specialFilter.value === 2}
                                  checked={props.filters.specialProduct.includes(specialFilter.value.toString()) ? true : false}
                                />
                                <span className={props.filters.specialProduct.includes(specialFilter.titleEN) ? 'active-heading-accodion' : 'inner-heading-accodion'}>
                                  {lancode === "en" && (
                                    <span>
                                      {specialFilter.titleEN}
                                    </span>
                                  )}
                                  {lancode === "th" && (
                                    <span>
                                      {specialFilter.titleTH}
                                    </span>
                                  )}
                                </span>
                              </div>
                            )
                          })
                          }
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}



                {/* Nested-Accordion-section start from here  */}
                {props.filterData && props.filterData.category && props.filterData.category[0] && props.filterData.category[0].subCategory && props.filterData.category[0].subCategory.length > 0 && (
                  <>
                    <p className="gray-line-inner"></p>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div className="row">
                          <div className="col-sm-12 pl-3">
                            <div className="accordion-top-header">
                              <h6 className="brand-text-heading">
                                {t("Subcategory.label")}&nbsp;{props.filters.subCategorySlug.length == 0 ? <span></span> : <span >({props.filters.subCategorySlug.length})</span>}
                              </h6>
                            </div>
                          </div>
                        </div>

                        {showHideClearText == 3 ? <h6 className="clear-text-heading" onClick={e => props.resetFilter(e, "categorySlug")}>{t("clear.label")}</h6> : null}
                      </Accordion.Header>
                      <Accordion.Body>
                        {props.filterData && props.filterData.category && props.filterData.category[0].subCategory.map((categoryFilter, index) => {
                          return (
                            <div className="row nested-inner-container">
                              <div className="col-sm-12 inner-checkbox-container">
                                <Checkbox color="success"
                                  onChange={e => props.handleChangeCategory(e, "subCategorySlug", props.filterData.category[0].categorySlug)}
                                  name={categoryFilter.subCategorySlug}
                                  checked={props.filters.subCategorySlug.includes(categoryFilter.subCategorySlug) ? true : false}
                                />
                                <span className={props.filters.subCategorySlug.includes(categoryFilter.subCategorySlug) ? 'active-heading-accodion' : 'inner-heading-accodion'}>
                                  {lancode === "en" && (
                                    <span title={categoryFilter.subCategoryEN.length > 30 ? categoryFilter.subCategoryEN : ""}>
                                      {categoryFilter.subCategoryEN.length > 26
                                        ? categoryFilter.subCategoryEN.substring(0, 26) +
                                        "..."
                                        : categoryFilter.subCategoryEN}
                                    </span>
                                  )}
                                  {lancode === "th" && (
                                    <span title={categoryFilter.subCategoryEN.length > 30 ? categoryFilter.subCategoryEN : ""}>
                                      {categoryFilter.subCategoryTH.length > 26
                                        ? categoryFilter.subCategoryTH.substring(0, 26) +
                                        "..."
                                        : categoryFilter.subCategoryTH}
                                    </span>
                                  )}

                                </span>
                              </div>
                            </div>
                          )

                        })}


                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
                {/* Nested-Accordion-section end  */}
              </Accordion>
            </div>
          </div>
        </div>

        {/* Mobile-Filter-section start */}
        {/* Mobile Responsive section start from here */}
        <div className="container-fluid mobile-view-main-container p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 product-counts-main-mobile">
                {/* <h6 className="products-count-heading-mobile">All Products</h6> */}
                {/* <h6 className="products-count-paragraph-mobile">{props?.totalProduct} Products found</h6> */}
              </div>
              <div className="col-sm-10 col-10">
                <div className="now-rap-main-container">
                  {props.filterData && props.filterData.category && props.filterData.category[0].subCategory.map((categoryFilter, index) => {
                    return (
                      <>

                        <div className={props.filters.subCategorySlug.includes(categoryFilter.subCategorySlug) ? "checkbox-inner-container-active" : "checkbox-inner-container"}>
                          <Checkbox

                            onChange={e => props.handleChange(e, "subCategorySlug")}
                            name={categoryFilter.subCategorySlug}
                            checked={props.filters.subCategorySlug.includes(categoryFilter.subCategorySlug) ? true : false}
                            // onClick={(event) =>  event.stopPropagation()}
                            className="checkbox-design"
                          />
                          <span>
                            {lancode === "en" && (
                              <>{categoryFilter.subCategoryEN.length > 14
                                ? categoryFilter.subCategoryEN.substring(0, 14) +
                                "..."
                                : categoryFilter.subCategoryEN}</>
                            )}
                            {lancode === "th" && (
                              <>
                                {categoryFilter.subCategoryTH.length > 14
                                  ? categoryFilter.subCategoryTH.substring(0, 14) +
                                  "..."
                                  : categoryFilter.subCategoryTH}</>
                            )}
                          </span>
                        </div>

                      </>
                    )
                  })}


                </div>
              </div>
              <div className="col-sm-2 col-2">
                <div className="filter-image-main-container">
                  <img src={filterImage} className="filter-image" onClick={() => setOpenModal(true)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Responsive end */}
        {openModal ?
          <div className="open-modal-main-container">
            <div className="container">
              <h6 className="filter-modal-heading">{t("filter.label")}</h6>
              <img src={crossImage} className="cross-image" onClick={() => setOpenModal(false)} />
              <div className="sort-by-modal-container">
                <h6 className="sortby-modal-heading">{t("sortBy.label")}</h6>
                {/* <div className="row">
                <div className="col-sm-8 col-8">
                  <h6 className="sort-by-modal-inner-heading">Popular</h6>
                </div>
                <div className="col-sm-4 col-4 modal-checkbox">
                  <Checkbox />
                </div>
              </div> */}
                <RadioGroup onChange={handleSelect}>
                  <div className="row mobile-radio-button-main-container">
                    <div className="col-sm-8 col-8">
                      <h6 className="sort-by-modal-inner-heading">{t("priceHightoLow.label")}</h6>
                    </div>
                    <div className="col-sm-4 col-4 modal-checkbox p-0 m-0">
                      <FormControlLabel value="Price high to low" control={<Radio />} />
                    </div>
                  </div>
                  <div className="row mobile-radio-button-main-container">
                    <div className="col-sm-8 col-8">
                      <h6 className="sort-by-modal-inner-heading">{t("priceLowtoHigh.label")}</h6>
                    </div>
                    <div className="col-sm-4 col-4 modal-checkbox p-0 m-0">
                      <FormControlLabel value="Price low to high" control={<Radio />} />
                    </div>
                  </div>
                  <div className="row mobile-radio-button-main-container">
                    <div className="col-sm-8 col-8">
                      <h6 className="sort-by-modal-inner-heading">{t("priceAtoZ.label")}</h6>
                    </div>
                    <div className="col-sm-4 col-4 modal-checkbox p-0 m-0">
                      <FormControlLabel value="A to Z" control={<Radio />} />
                    </div>
                  </div>
                  <div className="row mobile-radio-button-main-container">
                    <div className="col-sm-8 col-8">
                      <h6 className="sort-by-modal-inner-heading">{t("priceZtoA.label")}</h6>
                    </div>
                    <div className="col-sm-4 col-4 modal-checkbox p-0 m-0">
                      <FormControlLabel value="Z to A" control={<Radio />} />
                    </div>
                  </div>
                </RadioGroup>
                <div className="row">
                  <div className="col-sm-12 col-12 accordion-modal-mobile-view">
                    <Accordion defaultActiveKey={['0']} alwaysOpen onSelect={handleSelected}>
                      {props.filterData && props.filterData.channel && props.filterData.channel.length > 0 && (
                        <Accordion.Item eventKey="30">
                          <Accordion.Header>
                            <div className="row mobile-accordion-top-header-relative">
                              <div className="col-sm-12">
                                <div className="accordion-top-header">
                                  <h6 className="brand-text-heading">{t("Channelf.label")}&nbsp;{props.filters.channel.length == 0 ? <span></span> : <span >({props.filters.channel.length})</span>}</h6>
                                </div>
                              </div>
                            </div>

                            {showHideClearText == 30 ? <h6 className="clear-text-heading-mobile" onClick={e => props.resetFilter(e, "channel")}>{t("clear.label")}</h6> : null}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              {props.filterData && props.filterData.channel && props.filterData.channel.map(channelFilter => {
                                return (
                                  <div className="row">
                                    <div className="col-sm-8 col-8 p-0 m-0" key={channelFilter.titleEN}>
                                      <span className="inner-heading-accodion">
                                        {lancode === "en" && (
                                          <span>
                                            {channelFilter.titleEN}
                                          </span>
                                        )}
                                        {lancode === "th" && (
                                          <span>
                                            {channelFilter.titleTH}
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                    <div className="col-sm-4 col-4 p-0 m-0 checkbox-float-right mobile-right-checkbox">
                                      <Checkbox color="success" checked={props.filters.channel.includes(channelFilter.value) ? true : false} onChange={e => props.handleChange(e, "channel")} name={channelFilter.value} />
                                    </div>
                                  </div>
                                )
                              })
                              }
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                      {props.filterData && props.filterData.brand && props.filterData.brand.length > 0 && (
                        <>
                          <p className="modal-gray-line"></p>
                          <Accordion.Item eventKey="31">
                            <Accordion.Header>
                              <div className="row mobile-accordion-top-header-relative">
                                <div className="col-sm-12">
                                  <div className="accordion-top-header">
                                    <h6 className="brand-text-heading">{t("brand.label")}&nbsp;{props.filters.brand.length == 0 ? <span></span> : <span >({props.filters.brand.length})</span>}</h6>
                                  </div>
                                </div>
                              </div>

                              {showHideClearText == 31 ? <h6 className="clear-text-heading-mobile" onClick={e => props.resetFilter(e, "brand")}>{t("clear.label")}</h6> : null}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                {props.filterData && props.filterData.brand && props.filterData.brand.map(brandFilter => {
                                  return (
                                    <div className="row">
                                      <div className="col-sm-8 col-8 p-0 m-0" key={brandFilter.titleEN}>
                                        <span className="inner-heading-accodion">
                                          {lancode === "en" && (
                                            <span>
                                              {brandFilter.titleEN}
                                            </span>
                                          )}
                                          {lancode === "th" && (
                                            <span>
                                              {brandFilter.titleTH}
                                            </span>
                                          )}

                                        </span>
                                      </div>
                                      <div className="col-sm-4 col-4 p-0 m-0 checkbox-float-right mobile-right-checkbox">
                                        <Checkbox color="success" checked={props.filters.brand.includes(brandFilter.titleEN) ? true : false} onChange={e => props.handleChange(e, "brand")} name={brandFilter.titleEN} />
                                      </div>
                                    </div>
                                  )
                                })
                                }
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      )}


                      {props.filterData && props.filterData.specialProduct && props.filterData.specialProduct.length > 0 && (
                        <>
                          <p className="modal-gray-line"></p>

                          <Accordion.Item eventKey="32">
                            <Accordion.Header>
                              <div className="row mobile-accordion-top-header-relative">
                                <div className="col-sm-12">
                                  <div className="accordion-top-header">
                                    <h6 className="brand-text-heading">{t("specialPro.label")}&nbsp;{props.filters.specialProduct.length == 0 ? <span></span> : <span >({props.filters.specialProduct.length})</span>}</h6>
                                  </div>
                                </div>
                              </div>

                              {showHideClearText == 32 ? <h6 className="clear-text-heading-mobile" onClick={e => props.resetFilter(e, "specialProduct")}>{t("clear.label")}</h6> : null}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                {props.filterData && props.filterData.specialProduct && props.filterData.specialProduct.map(specialFilter => {
                                  return (
                                    <div className="row">
                                      <div className="col-sm-8 col-8 m-0 p-0" key={specialFilter.titleEN}>
                                        <span className="inner-heading-accodion">
                                          {lancode === "en" && (
                                            <span>
                                              {specialFilter.titleEN}
                                            </span>
                                          )}
                                          {lancode === "th" && (
                                            <span>
                                              {specialFilter.titleTH}
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                      <div className="col-sm-4 col-4 p-0 m-0 checkbox-float-right mobile-right-checkbox">
                                        <Checkbox color="success"
                                          onChange={e => props.handleChange(e, "specialProduct")}
                                          name={specialFilter.value}
                                          disabled={specialFilter.value === '7' || specialFilter.value === 7 || specialFilter.value === '2' || specialFilter.value === 2}
                                          checked={props.filters.specialProduct.includes(specialFilter.value.toString()) ? true : false}
                                        />
                                      </div>
                                    </div>
                                  )
                                })
                                }
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      )}

                      <p className="modal-gray-line"></p>
                      <Accordion.Item eventKey="33">
                        <Accordion.Header>
                          <div className="row mobile-accordion-top-header-relative">
                            <div className="col-sm-12">
                              <div className="accordion-top-header">
                                <h6 className="brand-text-heading">
                                  {t("Subcategory.label")}&nbsp;
                                  {
                                    props.filters.subCategorySlug.length == 0 ? "" : <>({props.filters.subCategorySlug.length})</>

                                  }
                                </h6>
                              </div>
                            </div>
                          </div>

                          {showHideClearText == 33 ? <h6 className="clear-text-heading-mobile" onClick={e => props.resetFilter(e, "categorySlug")}>{t("clear.label")}</h6> : null}
                        </Accordion.Header>
                        <Accordion.Body>
                          {props.filterData && props.filterData.category && props.filterData.category[0].subCategory.map((categoryFilter, index) => {
                            return (

                              <div className="row nested-inner-container">
                                {/* {categoryFilter && categoryFilter.subCategory && categoryFilter.subCategory.map(subCatFilter => { */}
                                {/* return ( */}
                                <div className="row">

                                  <div className="col-sm-8 col-8 p-0 m-0">
                                    <span className="inner-heading-accodion">
                                      {lancode === "en" && (
                                        <span>
                                          {categoryFilter.subCategoryEN}
                                        </span>
                                      )}
                                      {lancode === "th" && (
                                        <span>
                                          {categoryFilter.subCategoryTH}
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                  <div className="col-sm-4 col-4 p-0 m-0 checkbox-float-right mobile-right-checkbox">
                                    <Checkbox color="success"
                                      onChange={e => props.handleChangeCategory(e, "subCategorySlug", props.filterData.category[0].categorySlug)}
                                      name={categoryFilter.subCategorySlug}
                                      checked={props.filters.subCategorySlug.includes(categoryFilter.subCategorySlug) ? true : false}
                                    />
                                  </div>
                                </div>

                                {/* ) */}
                                {/* })} */}
                              </div>
                              // <Accordion.Item className="nested-accordion-main-container">
                              //     <Accordion key={14} defaultActiveKey={index}>
                              //         <Accordion.Item eventKey="34">
                              //             <Accordion.Header >
                              //                 <Checkbox  color="success"
                              //                 //  onChange={e => props.handleChange(e, "categorySlug")}
                              //                  onChange={e => props.CategoryChange(e, "categorySlug",categoryFilter.subCategory)}
                              //                  name={categoryFilter.categorySlug}
                              //                  checked={props.filters.categorySlug.includes(categoryFilter.subCategory) ? true : false}                                                             
                              //                  onClick={(event) =>  event.stopPropagation()}
                              //                  /> 
                              //                 <span className="inner-heading-accodion">
                              //                     {lancode === "en" && (
                              //                         <>{categoryFilter.categoryEN}</>
                              //                     )}
                              //                     {lancode === "th" && (
                              //                         <>
                              //                             {categoryFilter.categoryTH}</>
                              //                     )}&nbsp;

                              //                     {
                              //                         getCount(inBoth(props.filters.subCategorySlug, categoryFilter.subCategory)) == 0 ? "":<>({getCount(inBoth(props.filters.subCategorySlug, categoryFilter.subCategory))})</>

                              //                     }

                              //                 </span>
                              //             </Accordion.Header>
                              //             <Accordion.Body>

                              //             </Accordion.Body>
                              //         </Accordion.Item>



                              //     </Accordion>
                              // </Accordion.Item>

                            )

                          })}


                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <button className="modal-reset-button" onClick={e => props.resetFilter(e, undefined)}>{t("reset.label")}</button>
                  </div>
                  <div className="col-sm-6 col-6">
                    <button className="modal-done-button" onClick={() => setOpenModal(false)}>{t("Confirm.label")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div> : ''}
        {/* Mobile-Filter-section end */}
      </ThemeProvider>
    </StyledEngineProvider></>;
}

export default withTranslation()(Filters);