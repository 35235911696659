import React from 'react';
import "./Loader.scss";

const Loading = (props) => {
  return (
   <>
      <div className="loading-overlay">
        <div className="spinner-border" role="status" style={{ width: "6rem", height: "6rem", color:'#61A60E', borderWidth: 6 }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
   </>
  );
}
export default Loading;