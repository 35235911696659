import { alertActions } from ".";
import { userConstants } from "../_constants";
import { history } from "../_helpers";
import { customLogout } from '../_helpers/commanFunction';
import { userService } from "../_services";
export const userActions = {
  login,
  logout,
  register,
  getAll,
  delete: _delete,
  firtTimeLogin,
  otpVerification,
  forgotOtpVerification,
  setPassword,
  getAllcustomerlist,
  getcustomerdetailsbyid,
  getcustomeraddressdetails,
  changePassword,
  updateUser,
  forgotPassword,
  unMountLogin

};


function changePassword(type, payload, queryString) {
  return (dispatch) => {
    dispatch(request());
    userService.changePassword(payload, queryString)
      .then((res) => {

        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  }
  function request() {
    return { type: userConstants.CHANGE_PASSWORD_USER__ALL };
  }
  function success(data) {
    return { type: userConstants.CHANGE_PASSWORD_USER__ALL_SUCCESS, data };
  }
  function noDataFound(data) {
    return { type: userConstants.CHANGE_PASSWORD_USER__ALL_NODATA, data };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_USER__ALL_FAILURE, error };

  }
}



function updateUser(type, payload, queryString) {
  return (dispatch) => {
    dispatch(request());
    userService.updateUser(payload, queryString)
      .then((res) => {

        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  }
  function request() {
    return { type: userConstants.UPDATE_USER__ALL };
  }
  function success(data) {
    return { type: userConstants.UPDATE_USER__ALL_SUCCESS, data };
  }
  function noDataFound(data) {
    return { type: userConstants.UPDATE_USER__ALL_NODATA, data };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER__ALL_FAILURE, error };

  }
}


function firtTimeLogin(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService.firtTimeLogin(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          if (type === 'phone') {
            dispatch(success(res.data));
          }
          if (type === 'email') {
            dispatch(successEmail(res.data));
          }


        } else {
          if (type === 'phone') {
            dispatch(failure(res));
          }
          if (type === 'email') {
            dispatch(failureEmail(res));
          }
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: userConstants.FIRST_TIME_LOGIN_REQUEST };
  }
  function success(data) {
    return { type: userConstants.FIRST_TIME_LOGIN_SUCESS, data };
  }
  function successEmail(data) {
    return { type: userConstants.FIRST_TIME_LOGIN_SUCESS_EMAIL, data };
  }

  function failure(error) {
    return { type: userConstants.FIRST_TIME_LOGIN_FAILURE, error };

  }
  function failureEmail(error) {
    return { type: userConstants.FIRST_TIME_LOGIN_FAILURE_EMAIL, error };
  }
}


function forgotPassword(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService.forgotPassword(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          if (type === 'phone') {
            dispatch(success(res.data));
          }
          if (type === 'email') {
            dispatch(successEmail(res.data));
          }


        } else {
          if (type === 'phone') {
            dispatch(failure(res));
          }
          if (type === 'email') {
            dispatch(failureEmail(res));
          }
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: userConstants.FORGOT_REQUEST };
  }
  function success(data) {
    return { type: userConstants.FORGOT_SUCESS, data };
  }
  function successEmail(data) {
    return { type: userConstants.FORGOT_SUCESS_EMAIL, data };
  }

  function failure(error) {
    return { type: userConstants.FORGOT_FAILURE, error };

  }
  function failureEmail(error) {
    return { type: userConstants.FORGOT_FAILURE_EMAIL, error };
  }
}


function setPassword(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService
      .setPassword(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
          // history.push("/loginresetpasswordsuccess");
        } else if (res?.response?.data?.code && res.response.data.code === 409) {
          customLogout()
        }
        else {
          dispatch(failure(res));
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: userConstants.CREATE_PASSWORD };
  }
  function success(data) {
    return { type: userConstants.CREATE_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CREATE_PASSWORD_FAILURE, error };
  }
}

function login(loginType, payload) {
  return (dispatch) => {
    dispatch(request());

    userService.login(payload).then(
      (user) => {
        if ((user && user.data && user.data.code === 400) || user.status === 400) {
          dispatch(failure(user));
          dispatch(alertActions.error(user));
        }
        else {
          dispatch(success(user));

          localStorage.setItem("authorization", user.data.data.token);
          localStorage.setItem("user", JSON.stringify(user.data.data));

          sessionStorage.setItem("authorization", user.data.data.token);
          sessionStorage.setItem("user", JSON.stringify(user.data.data));

          history.push("/allproducts");
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function unMountLogin(data) {
  return { type: userConstants.LOGIN_DEFAULT, data };
}



function logout(token) {
  userService.logout(token);
  localStorage.removeItem("authorization");
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      (user) => {
        dispatch(success());
        history.push("/login");
        dispatch(alertActions.success("Registration successful"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService.getAll(payload)
      .then((res) => {

        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  }
  function request() {
    return { type: userConstants.GET_USER_ALL };
  }
  function success(data) {
    return { type: userConstants.GET_USER_ALL_SUCCESS, data };
  }
  function noDataFound(data) {
    return { type: userConstants.GET_USER_ALL_NODATA, data };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_ALL_FAILURE, error };

  }
}


function getcustomerdetailsbyid(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService.getcustomerdetailsbyid(payload)
      .then((res) => {

        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  }
  function request() {
    return { type: userConstants.GET_CUSTOMER_DETAILS_ALL };
  }
  function success(data) {
    return { type: userConstants.GET_CUSTOMER_DETAILS_ALL_SUCCESS, data };
  }
  function noDataFound(data) {
    return { type: userConstants.GET_CUSTOMER_DETAILS_ALL_NODATA, data };
  }
  function failure(error) {
    return { type: userConstants.GET_CUSTOMER_DETAILS_ALL_FAILURE, error };

  }
}




function getcustomeraddressdetails(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService.getcustomeraddressdetails(payload)
      .then((res) => {

        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  }
  function request() {
    return { type: userConstants.GET_CUSTOMER_ADDRESS_DETAILS_ALL };
  }
  function success(data) {
    return { type: userConstants.GET_CUSTOMER_ADDRESS_DETAILS_ALL_SUCCESS, data };
  }
  function noDataFound(data) {
    return { type: userConstants.GET_CUSTOMER_ADDRESS_DETAILS_ALL_NODATA, data };
  }
  function failure(error) {
    return { type: userConstants.GET_CUSTOMER_ADDRESS_DETAILS_ALL_FAILURE, error };

  }
}




function getAllcustomerlist(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService.getAllcustomerlist(payload)
      .then((res) => {

        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
        } else if (res.status === undefined && res.code === 400) {
          dispatch(noDataFound(res.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  }
  function request() {
    return { type: userConstants.GET_USER_ALL_CUSTOMER };
  }
  function success(data) {
    return { type: userConstants.GET_USER_ALL_CUSTOMER_SUCCESS, data };
  }
  function noDataFound(data) {
    return { type: userConstants.GET_USER_ALL_CUSTOMER_NODATA, data };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_ALL_CUSTOMER_FAILURE, error };

  }
}




/* function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
} */

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(id, error));
      }
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

function forgotOtpVerification(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService.otpVerification(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
          history.push("/logincreatepassword?resetpassword=true");
        } else {
          dispatch(failure(res.response.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: userConstants.OTP_VERIFICATION };
  }
  function success(data) {
    return { type: userConstants.OTP_VERIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.OTP_VERIFICATION_FAILURE, error };

  }

}


function otpVerification(type, payload) {
  return (dispatch) => {
    dispatch(request());
    userService.otpVerification(payload)
      .then((res) => {
        if (res.status === 200 && res.data) {
          dispatch(success(res.data));
          history.push("/logincreatepassword");
        } else {
          dispatch(failure(res.response.data));
        }
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: userConstants.OTP_VERIFICATION };
  }
  function success(data) {
    return { type: userConstants.OTP_VERIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.OTP_VERIFICATION_FAILURE, error };

  }

}
