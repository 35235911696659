import { userConstants } from "../_constants";
const initReducers = {
  firstTimeLoginwithPhone: null,
  firstTimeLoginwithEmail: null,
  forgotwithPhone: null,
  forgotwithEmail: null,
  otpVerification: null,
  otpVerificationError: null,
  userLogin: null,
  createPassword: null,
  getAllusers: null,
  getAllusersError: null,
  getAlluserscustomer: null,
  getAlluserscustomerError: null,
  getcustomerdetails: null,
  getcustomerdetailsError: null,
  getcustomeraddressdetails: null,
  getcustomeraddressdetailsError: null,
  changepasswordList: null,
  changepasswordError: null,
  updateuserList: null,
  updateuserError: null,

};
export function users(state = initReducers, action) {
  switch (action.type) {
    case userConstants.UPDATE_USER__ALL:
      return {
        ...state,
        updateuserList: action.data,
        updateuserError: null,
      };
      case userConstants.UPDATE_USER__ALL_SUCCESS:
      return {
        ...state,
        updateuserList: action.data,
        updateuserError: null,
      };
      case userConstants.UPDATE_USER__ALL_NODATA:
      return {
        ...state,
        updateuserList: action.data,
        updateuserError: null,
      };
      case userConstants.UPDATE_USER__ALL_FAILURE:
      return {
        ...state,
        updateuserList: action.data,
        updateuserError: null,
      };
    case userConstants.CHANGE_PASSWORD_USER__ALL:
      return {
        ...state,
        changepasswordList: action.data,
        changepasswordError: null,
      };
    case userConstants.CHANGE_PASSWORD_USER__ALL_SUCCESS:
      return {
        ...state,
        changepasswordList: action.data,
        changepasswordError: null,
      };
    case userConstants.CHANGE_PASSWORD_USER__ALL_NODATA:
      return {
        ...state,
        changepasswordList: null,
        changepasswordError: null,
      };
    case userConstants.CHANGE_PASSWORD_USER__ALL_FAILURE:
      return {
        ...state,
        changepasswordList: action.data,
        changepasswordError: null,
      };
    case userConstants.FIRST_TIME_LOGIN_SUCESS:
      return {
        ...state,
        firstTimeLoginwithPhone: action.data,
        firstTimeLoginwithEmail: null,
      };
    case userConstants.FIRST_TIME_LOGIN_SUCESS_EMAIL:
      return {
        ...state,
        firstTimeLoginwithEmail: action.data,
        firstTimeLoginwithPhone: null,
      };
    case userConstants.FIRST_TIME_LOGIN_FAILURE:
      return {
        ...state,
        firstTimeLoginwithEmail: null,
        firstTimeLoginwithPhone: action.error,
      };
    case userConstants.FIRST_TIME_LOGIN_FAILURE_EMAIL:
      return {
        ...state,
        firstTimeLoginwithPhone: null,
        firstTimeLoginwithEmail: action.error,
      };
      //
      case userConstants.FORGOT_SUCESS:
      return {
        ...state,
        forgotwithPhone: action.data,
        forgotwithEmail: null,
      };
    case userConstants.FORGOT_SUCESS_EMAIL:
      return {
        ...state,
        forgotwithEmail: action.data,
        forgotwithPhone: null,
      };
    case userConstants.FORGOT_FAILURE:
      return {
        ...state,
        forgotwithEmail: null,
        forgotwithPhone: action.error,
      };
    case userConstants.FORGOT_FAILURE_EMAIL:
      return {
        ...state,
        forgotwithPhone: null,
        forgotwithEmail: action.error,
      };

      //
    case userConstants.OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        otpVerification: action.data,
        otpVerificationError: null,
      };
    case userConstants.OTP_VERIFICATION_FAILURE:
      return {
        ...state,
        otpVerificationError: null,
        otpVerificationError: action.error,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        userLogin: action.data,
      };
    case userConstants.LOGIN_DEFAULT:
      return {
        ...state,
        userLogin: null,        
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        userLogin: null,
        userLogin: action.error,
      };
    case userConstants.CREATE_PASSWORD:
      return {
        ...state,
        createPassword: action.data,
      };
    case userConstants.CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        createPassword: null,
        createPassword: action.error,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        userLogin: null,
      };
    case userConstants.GET_USER_ALL:
      return {
        ...state,
        getAllusers: action.data,
        getAllusersError: action.error,

      };
    case userConstants.GET_USER_ALL_SUCCESS:
      return {
        ...state,
        getAllusers: action.data,
        getAllusersError: action.error,
      };
    case userConstants.GET_USER_ALL_NODATA:
      return {
        ...state,
        getAllusers: null,
        getAllusersError: action.error,
      };
    case userConstants.GET_USER_ALL_FAILURE:
      return {
        ...state,
        getAllusers: null,
        getAllusersError: action.error,
      };
    case userConstants.GET_USER_ALL_CUSTOMER:
      return {
        ...state,
        getAlluserscustomer: action.data,
        getAlluserscustomerError: action.error,

      };
    case userConstants.GET_USER_ALL_CUSTOMER_SUCCESS:
      return {
        ...state,
        getAlluserscustomer: action.data,
        getAlluserscustomerError: action.error,
      };
    case userConstants.GET_USER_ALL_CUSTOMER_NODATA:
      return {
        ...state,
        getAlluserscustomer: null,
        getAlluserscustomerError: action.error,
      };
    case userConstants.GET_USER_ALL_CUSTOMER_FAILURE:
      return {
        ...state,
        getAlluserscustomer: null,
        getAlluserscustomerError: action.error,
      };

    case userConstants.GET_CUSTOMER_DETAILS_ALL:
      return {
        ...state,
        getcustomerdetails: action.data,
        getcustomerdetailsError: action.error,

      };
    case userConstants.GET_CUSTOMER_DETAILS_ALL_SUCCESS:
      return {
        ...state,
        getcustomerdetails: action.data,
        getcustomerdetailsError: action.error,

      };
    case userConstants.GET_CUSTOMER_DETAILS_ALL_NODATA:
      return {
        ...state,
        getcustomerdetails: action.data,
        getcustomerdetailsError: action.error,

      };
    case userConstants.GET_CUSTOMER_DETAILS_ALL_FAILURE:
      return {
        ...state,
        getcustomerdetails: action.data,
        getcustomerdetailsError: action.error,

      };
    case userConstants.GET_CUSTOMER_ADDRESS_DETAILS_ALL:
      return {
        ...state,
        getcustomeraddressdetails: action.data,
        getcustomeraddressdetailsError: action.error,

      };
    case userConstants.GET_CUSTOMER_ADDRESS_DETAILS_ALL_SUCCESS:
      return {
        ...state,
        getcustomeraddressdetails: action.data,
        getcustomeraddressdetailsError: action.error,
      };
    case userConstants.GET_CUSTOMER_ADDRESS_DETAILS_ALL_NODATA:
      return {
        ...state,
        getcustomeraddressdetails: action.data,
        getcustomeraddressdetailsError: action.error,
      };
    case userConstants.GET_CUSTOMER_ADDRESS_DETAILS_ALL_FAILURE:
      return {
        ...state,
        getcustomeraddressdetails: action.data,
        getcustomeraddressdetailsError: action.error,
      };





    default:
      return state;
  }
}
