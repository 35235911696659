import { authHeader } from "../_helpers";
import { Axios } from "./axiosProvider";
import { REACT_APP_BASE_URL } from "../constant"
export const postcodeService = {
  
  getAll,
  
};



function getAll(payload) {
  const token = localStorage.getItem("authorization");
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };
  return Axios.get(
    REACT_APP_BASE_URL + "/api/postcode/",
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
}



function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

